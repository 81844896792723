import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Alert, Button, Typography, Form, Select } from "antd"

import * as S from "../../styles/PageStyles"
import UserGroupsSelector from "../../common/UserGroupsSelector"

const { Text } = Typography

const userGroupUpdateOptions = [
	{ value: "add", label: "Add" },
	{ value: "replace", label: "Replace" },
	{ value: "lock", label: "Lock" }
]

const Divider = styled.div`
	height: 32px;
	width: 100%;
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
`

const TagImportedUsers = ({
	setImportStage,
	userGroups,
	setUserGroups,
	userGroupUpdateBehaviour,
	setUserGroupUpdateBehaviour,
	userGroupsInImport
}) => {
	return (
		<React.Fragment>
			<S.FormContainer>
				<S.FormTitle>Tag your users</S.FormTitle>

				<Form
					layout="vertical"
					size="large"
					initialValues={{
						update_behaviour: "add"
					}}
				>
					{userGroupsInImport ? (
						<>
							<Text>
								You are importing user groups with your users. Please tell us how you want us to update
								these user groups
							</Text>
							<Form.Item
								label="User group update behaviour"
								name="update_behaviour"
								rules={[{ required: true, message: "You need to select an option" }]}
								tooltip=""
							>
								<Select
									showArrow={true}
									options={userGroupUpdateOptions}
									onChange={(value) => setUserGroupUpdateBehaviour(value)}
								/>
							</Form.Item>
						</>
					) : (
						<>
							<Text>
								You can select user groups from the dropdown below to add to the users who are being
								added in this import
							</Text>
							<UserGroupsSelector disableRegistered onChange={(value) => setUserGroups(value)} />

							{userGroups.length > 0 && (
								<Form.Item
									label="User group update behaviour"
									name="update_behaviour"
									rules={[{ required: true, message: "You need to select an option" }]}
									tooltip=""
								>
									<Select
										showArrow={true}
										options={userGroupUpdateOptions}
										onChange={(value) => setUserGroupUpdateBehaviour(value)}
									/>
								</Form.Item>
							)}
						</>
					)}

					<Divider borderOnBottom={false} />

					{userGroupUpdateBehaviour === "add" && (
						<Alert
							message={
								"The ADD behaviour means that any users who you have already imported in this list will be added to the user groups you selected above, if they are also in other pre-existing groups this will not be affected"
							}
						/>
					)}

					{userGroupUpdateBehaviour === "replace" && (
						<Alert
							message={
								"The REPLACE behaviour means that any users who you have already imported in this list will be added to the user groups you selected above, but if they were in other groups they will be removed from these (this setting is potentially desctructive, so be careful!) "
							}
						/>
					)}

					{userGroupUpdateBehaviour === "lock" && (
						<Alert
							message={
								"The LOCK behaviour means that any users who you have already imported in this list will be have their current user groups locked, so will not be added to the user groups you selected above (unless they were already in them)"
							}
						/>
					)}

					<Divider borderOnBottom={false} />

					<Button type="primary" size="large" shape="round" onClick={() => setImportStage("complete")}>
						Next
					</Button>
				</Form>
			</S.FormContainer>
		</React.Fragment>
	)
}

TagImportedUsers.propTypes = {
	setImportStage: PropTypes.func.isRequired,
	userGroups: PropTypes.array.isRequired,
	setUserGroups: PropTypes.func.isRequired,
	userGroupUpdateBehaviour: PropTypes.string.isRequired,
	setUserGroupUpdateBehaviour: PropTypes.func.isRequired
}

export default TagImportedUsers
