import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Avatar } from "antd"

import { updateLessonCard } from "../../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../../redux/courses/courses.selectors"

import SurveyCard from "./SurveyCard"
import ButtonCard from "./ButtonCard"
import CallOutCard from "./CallOutCard"
import VideoCard from "./VideoCard"
import ImageCard from "./ImageCard"
import TextCard from "./TextCard"
import MultChoiceSurveyCard from "./MultChoiceSurveyCard"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { NumInputSurveyCard, TextInputSurveyCard } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"

const FlexContainer = styled.div<{ from: TeacherOrLearner | undefined }>`
	width: 100%;
	display: flex;
	flex-direction: ${(props) => (props.from === "learner" ? "row-reverse" : "row")};
`
const ContentContainer = styled.div`
	flex: 1;
	overflow: auto;
`

interface Props {
	card: LessonCard
	stage: any
	updateLessonCard: (updateParams: LessonCard) => void
}

const LessonCardContainer = ({ card, stage, updateLessonCard }: Props) => {
	let avatarImg = undefined
	if (stage && stage.teacherLearnerImgs) {
		avatarImg = card.from === "teacher" ? stage.teacherLearnerImgs.teacherImg : stage.teacherLearnerImgs.learnerImg
	}

	return (
		<FlexContainer from={card.from}>
			{!["multipleChoiceSurvey", "textInputSurvey", "numberInputSurvey"].includes(card.type) && (
				<Avatar
					src={avatarImg}
					size="small"
					style={{
						marginRight: card.from === "teacher" ? 6 : 0,
						marginLeft: card.from === "learner" ? 6 : 0
					}}
				/>
			)}
			<ContentContainer>
				{card.type === "text" && <TextCard card={card} updateLessonCard={updateLessonCard} />}
				{card.type === "image" && <ImageCard card={card} updateLessonCard={updateLessonCard} />}
				{card.type === "callOut" && <CallOutCard card={card} updateLessonCard={updateLessonCard} />}
				{card.type === "button" && <ButtonCard card={card} updateLessonCard={updateLessonCard} />}
				{card.type === "multipleChoiceSurvey" && (
					<MultChoiceSurveyCard card={card} updateLessonCard={updateLessonCard} />
				)}
				{["textInputSurvey", "numberInputSurvey"].includes(card.type) && (
					<SurveyCard
						card={card as TextInputSurveyCard | NumInputSurveyCard}
						updateLessonCard={updateLessonCard}
					/>
				)}
				{card.type === "video" && <VideoCard card={card} />}
			</ContentContainer>
		</FlexContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: LessonCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(LessonCardContainer)
