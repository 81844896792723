import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { Button } from "antd"

import * as S from "../../styles/PageStyles"
import CbLoading from "../../common/CbLoading"

const ParagraphText = styled.p`
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
	color: ${({ color }) => (color ? color : "black")};
`
const LogText = styled.p`
	font-size: 14px;
	margin-left: 12px;
	margin-top: 8px;
`
const BottomBtnsContainer = styled.div`
	display: flex;
	margin-top: 20px;
`

const ImportSummary = ({ importSummary, setReturnToUsersScreen }) => {
	return (
		<S.FormContainer>
			{!importSummary ? (
				<CbLoading loadingText="Importing users..." />
			) : (
				<>
					<S.FormTitle>Your import summary</S.FormTitle>

					{importSummary.process_success === false ? (
						<>
							<ParagraphText color="var(--dark-red)">
								<FontAwesomeIcon icon={faTimesCircle} /> Users could not be imported
							</ParagraphText>
							<LogText>{importSummary.response_message}</LogText>
						</>
					) : (
						<>
							{importSummary.data.usersAdded && importSummary.data.usersAdded.length > 0 ? (
								<>
									<ParagraphText color="var(--dark-green)">
										<FontAwesomeIcon icon={faCheckCircle} />{" "}
										<span style={{ fontWeight: 600 }}>
											{importSummary.data.usersAdded.length} users
										</span>{" "}
										were added
									</ParagraphText>
								</>
							) : null}
							{importSummary.data.usersUpdated && importSummary.data.usersUpdated.length > 0 ? (
								<>
									<ParagraphText color="var(--dark-green)">
										<FontAwesomeIcon icon={faCheckCircle} />{" "}
										<span style={{ fontWeight: 600 }}>
											{importSummary.data.usersUpdated.length} users
										</span>{" "}
										were updated
									</ParagraphText>
								</>
							) : null}
							{importSummary.data.failedImports && importSummary.data.failedImports.length > 0 ? (
								<>
									<ParagraphText color="var(--dark-red)">
										<FontAwesomeIcon icon={faTimesCircle} />{" "}
										<span style={{ fontWeight: 600 }}>
											{importSummary.data.failedImports.length} users
										</span>{" "}
										failed on import
									</ParagraphText>
									{importSummary.data.failedImports.map((item, index) => (
										<span key={index}>
											{item.phone_number && item.error_info ? (
												<LogText>
													• <b>{item.phone_number}</b> {item.error_info.message}
												</LogText>
											) : null}
										</span>
									))}
								</>
							) : null}
						</>
					)}

					<div style={{ height: "20px" }} />

					<BottomBtnsContainer>
						<Button type="primary" size="large" shape="round" onClick={() => setReturnToUsersScreen(true)}>
							View users
						</Button>
					</BottomBtnsContainer>
				</>
			)}
		</S.FormContainer>
	)
}

ImportSummary.propTypes = {
	importSummary: PropTypes.object,
	setReturnToUsersScreen: PropTypes.func.isRequired
}

export default ImportSummary
