export default class AudioRecorder {
	private stream: MediaStream | undefined
	private mediaRecorder: MediaRecorder | undefined
	private blobs: Blob[] = []

	start(): Promise<MediaStream> {
		if (!navigator.mediaDevices?.getUserMedia) {
			return Promise.reject(new Error("Can't find any media devices registered on the browser"))
		}

		return navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
			this.stream = stream
			this.mediaRecorder = new MediaRecorder(stream)
			this.mediaRecorder.addEventListener("dataavailable", (event) => {
				this.blobs.push(event.data)
			})

			this.mediaRecorder.start()
			return this.stream
		})
	}

	stop(): Promise<Blob> {
		return new Promise((resolve, reject) => {
			if (!this.mediaRecorder || !this.stream) {
				return reject(new Error("No media recorder or stream"))
			}
			let type = this.mediaRecorder.mimeType
			this.mediaRecorder.addEventListener("stop", () => {
				return resolve(new Blob(this.blobs, { type }))
			})

			this.cancel()
		})
	}

	cancel(): void {
		this.stopStream()
		this.reset()
	}

	private stopStream(): void {
		this.mediaRecorder?.stop()
		this.stream?.getTracks().forEach((track) => track.stop())
	}

	private reset(): void {
		this.blobs = []
		this.mediaRecorder = undefined
		this.stream = undefined
	}
}
