import React from "react"

import BasicTable from "../../common/BasicTable"

const DemographicsSummaryTable = ({ queryData }) => {
	const tableData = []
	const genders = ["female", "male"]
	genders.forEach((gender) => {
		const dataRow =
			queryData.filter((row) => row.sex === gender).length > 0
				? queryData.filter((row) => row.sex === gender)[0]
				: null
		tableData.push({
			gender,
			numActive: dataRow ? dataRow.numActive : 0,
			numRegistered: dataRow ? dataRow.numRegistered : 0,
			numStartedCourse: dataRow ? dataRow.numStartedCourse : 0,
			numCompletedCourse: dataRow ? dataRow.numCompletedCourse : 0
		})
	})
	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "Gender",
						accessor: "gender"
					},
					{
						Header: "Num active",
						accessor: "numActive"
					},
					{
						Header: "Num registered",
						accessor: "numRegistered"
					},
					{
						Header: "Num started a course",
						accessor: "numStartedCourse"
					},
					{
						Header: "Num completed a course",
						accessor: "numCompletedCourse"
					}
				]}
				data={tableData}
			/>
		</div>
	)
}

export default DemographicsSummaryTable
