// STYLED NUMBER FOR DASHBOARDS

import React from "react"
import styled from "styled-components"

const ValueContainer = styled.div`
	width: 100%;
	text-align: right;
	font-size: 40px;
	line-height: 48px;
	font-weight: 600;
`

const SingleValueChart = ({ children }) => <ValueContainer>{children}</ValueContainer>

export default SingleValueChart
