import React from "react"
import { Tooltip, ResponsiveContainer, Legend, BarChart, Bar, XAxis, YAxis } from "recharts"
import { Empty } from "antd"
import * as S from "../../styles/PageStyles"
import ChartWrapper from "./ChartWrapper"
import { chartColors } from "./colors"
import { tickFormatter } from "../../../util/formatters"

interface DataItem {
	x: string
	count: number
}

interface Props {
	data: DataItem[]
	dataKey: string
	series: string[]
	title: string
	tooltip: string
	showLegend?: boolean
	showYAxis: boolean
	height: number
}

const BaseBarChart = ({ data, dataKey, series, title, tooltip, showLegend, showYAxis, height }: Props) => {
	return (
		<ChartWrapper tooltip={tooltip} title={title}>
			<ResponsiveContainer width="100%" height={height}>
				{data.length > 0 ? (
					<BarChart data={data}>
						<XAxis dataKey={dataKey} />
						{showYAxis && <YAxis tickFormatter={tickFormatter} />}
						{series.map((series, index) => (
							<Bar key={index} stackId="a" dataKey={series} fill={chartColors[index]} />
						))}
						{showLegend && <Legend />}
						<Tooltip cursor={{ fill: "none" }} />
					</BarChart>
				) : (
					<S.FlexBoxColumnCentered>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
					</S.FlexBoxColumnCentered>
				)}
			</ResponsiveContainer>
		</ChartWrapper>
	)
}

export default BaseBarChart
