export const UserActionTypes = {
	SET_USER_SESSION_LOADING: "SET_USER_SESSION_LOADING",
	SET_USER_SESSION_READY: "SET_USER_SESSION_READY",
	SET_USER_FIREBASE_AUTH: "SET_USER_FIREBASE_AUTH",
	SET_USER_INFO: "SET_USER_INFO",
	SET_BASIC_USER_INFO: "SET_BASIC_USER_INFO",
	SET_USER_SESSION_ERROR: "SET_USER_SESSION_ERROR",
	CLEAR_USER_SESSION_ERROR: "CLEAR_USER_SESSION_ERROR",
	SET_NEW_ORG_INFO: "SET_NEW_ORG_INFO"
}
