// WRAPPER FOR CHARTS INCLUDING TOOPTIP

import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import * as S from "../../styles/PageStyles"
import TooltipIcon from "../../common/TooltipIcon"

const { Title } = Typography

const ChartWrapper = ({ tooltip, title, children }) => (
	<S.Card>
		<S.CardBody>
			{tooltip && (
				<S.CardInfoIcon>
					<TooltipIcon placement="bottomRight" tooltip={tooltip} />
				</S.CardInfoIcon>
			)}
			{title && <Title level={5}>{title}</Title>}
			{children}
		</S.CardBody>
	</S.Card>
)

ChartWrapper.propTypes = {
	tooltip: PropTypes.node,
	title: PropTypes.string,
	children: PropTypes.node.isRequired
}

export default ChartWrapper
