import React from "react"
import styled from "styled-components"
import { Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const { Title } = Typography

const ColumnSection = styled.div`
	border-bottom: solid 1px var(--light-grey);
	padding: 8px;
	display: flex;
`
const IconContainer = styled.div`
	width: 32px;
	height: 32px;
	font-size: 20px;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	color: black;
`

interface Props {
	faIcon?: any
	icon?: string
	title?: string | number
	subtitle: string
	isLast?: boolean
}

const InfographicSection = ({ faIcon, icon, title, subtitle, isLast }: Props) => {
	return (
		<ColumnSection style={{ borderBottomWidth: isLast ? 0 : 1 }}>
			<IconContainer>
				{faIcon && <FontAwesomeIcon icon={faIcon as IconProp} style={{ color: "#565656", fontSize: 20 }} />}
				{icon && <img src={icon} width={20} />}
			</IconContainer>
			<div>
				{title && (
					<Title
						level={5}
						style={{
							margin: 0,
							fontSize: 16,
							color: "#565656",
							lineHeight: 1.2
						}}
					>
						{title}
					</Title>
				)}
				<div
					style={{
						color: "grey",
						fontSize: 13
					}}
				>
					{subtitle}
				</div>
			</div>
		</ColumnSection>
	)
}

export default InfographicSection
