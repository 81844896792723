import React from "react"
import { Input, Select } from "antd"
import styled from "styled-components"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"

import { languageList } from "../../courseBuilder/course/sidepanels/languageList"
import PanelContainer from "../../courseBuilder/editor/panels/common/PanelContainer"
import CertificateBackgroundUpload from "../../courseBuilder/course/sidepanels/CertificateBackgroundUpload"

const Divider = styled.div`
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
	font-weight: bold;
`
const Container = styled.div`
	padding: 10px;
	background-color: #f0f2f4;
	border-bottom: solid 1px var(--light-grey);
`

interface Props {
	learningPath: LearningPath
	updateLearningPath: (learningPath: LearningPath) => void
}

const Settings = ({ learningPath, updateLearningPath }: Props) => {
	const updateLanguage = (val: string) => {
		let updatedLearningPath = {
			...learningPath
		}
		updatedLearningPath.sequences.forEach((sequence, index) => {
			updatedLearningPath.sequences[index].language = val
		})
		updateLearningPath(updatedLearningPath)
	}
	const updateCertificateImg = (val: string) => {
		let updatedLearningPath = {
			...learningPath
		}
		updatedLearningPath.sequences.forEach((sequence, index) => {
			updatedLearningPath.sequences[index].certificate.image = val
		})
		updateLearningPath(updatedLearningPath)
	}

	return (
		<>
			<Divider>Learning path name</Divider>
			<Container>
				<Input
					value={learningPath.internalName}
					style={{ width: "100%" }}
					onChange={(e) => updateLearningPath({ ...learningPath, internalName: e.target.value })}
					status={learningPath.internalName === "" ? "error" : undefined}
					placeholder="Enter internal name"
				/>
			</Container>
			{learningPath.sequences.length > 0 && (
				<>
					<PanelContainer title="Language">
						<Select
							showArrow={true}
							showSearch={true}
							style={{ width: "100%" }}
							options={languageList}
							value={learningPath.sequences[0].language}
							onChange={(value) => updateLanguage(value)}
							placeholder="Select language..."
							// @ts-ignore
							filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
						/>
					</PanelContainer>
					<PanelContainer title="Custom certificate">
						<CertificateBackgroundUpload
							initialImageUrl={learningPath.sequences[0].certificate.image}
							courseName={learningPath.sequences[0].displayName}
							setNewImage={(value) => {
								updateCertificateImg(value)
							}}
							title={undefined}
						/>
					</PanelContainer>
				</>
			)}
		</>
	)
}

export default Settings
