import React, { useState, useEffect } from "react"
import { sortDateArrayAsc } from "learnink-common/lib/tools/tools"
import BasicTable from "../../common/BasicTable"
import { LearningPathUserTableRow } from "./LearningPathProgress"
import CbLoading from "../../../common/CbLoading"

interface CohortTableRow {
	startWeek: string
	totalNumUsers: number
	numInProgress: number
	numCompletedOnTime: number
	numCompletedLate: number
	numOverdue: number
}

interface Props {
	data: LearningPathUserTableRow[]
}

const CohortTable = ({ data }: Props) => {
	const [tableData, setTableData] = useState<CohortTableRow[] | undefined>(undefined)

	useEffect(() => {
		const tableRowArray: CohortTableRow[] = []

		let startWeeks = [...new Set(data.filter((item) => item.startWeek).map((item) => item.startWeek))]
		startWeeks = sortDateArrayAsc(startWeeks)

		startWeeks.forEach((startWeek) => {
			const filteredData = data.filter((item) => item.startWeek === startWeek)
			tableRowArray.push({
				startWeek,
				totalNumUsers: filteredData.length,
				numInProgress: filteredData.filter((item) => item.status === "in progress").length,
				numCompletedOnTime: filteredData.filter((item) => item.status === "completed on time").length,
				numCompletedLate: filteredData.filter((item) => item.status === "completed late").length,
				numOverdue: filteredData.filter((item) => item.status === "overdue").length
			})
		})
		setTableData(tableRowArray)
	}, [data])

	if (!tableData) {
		return <CbLoading />
	}

	return (
		<BasicTable
			columns={[
				{
					Header: "Start week",
					accessor: "startWeek"
				},
				{
					Header: "Total users",
					accessor: "totalNumUsers"
				},
				{
					Header: "Num in progress",
					accessor: "numInProgress"
				},
				{
					Header: "Num completed on time",
					accessor: "numCompletedOnTime"
				},
				{
					Header: "Num completed late",
					accessor: "numCompletedLate"
				},
				{
					Header: "Num overdue",
					accessor: "numOverdue"
				}
			]}
			data={tableData}
		/>
	)
}

export default CohortTable
