// FORM TO UPDATE COURSE INFO OBJECT

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import styled from "styled-components"
import { LeftOutlined } from "@ant-design/icons"
import { Button, Form, Input, Space, Collapse, Select, Typography } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { selectCourseInfo, selectCourseId } from "../../../../redux/courses/courses.selectors"
import { updateCourseInfo } from "../../../../redux/courses/courses.actions"

import CbLoading from "../../../common/CbLoading"
import LpImageInput from "../../../common/LpImageInput"
import ArchiveCourse from "../../courses/modals/ArchiveCourse"
import DownloadCourse from "../../courses/modals/DownloadCourse"
import DuplicateCourse from "../../courses/modals/DuplicateCourse"
import CertificateBackgroundUpload from "./CertificateBackgroundUpload"
import UserGroupsSelector from "../../../common/UserGroupsSelector"

import { languageList } from "./languageList"

const { TextArea } = Input
const { Text, Title } = Typography
const { Panel } = Collapse

const scoreOptions = [
	{ value: 0, label: "0%" },
	{ value: 25, label: "25%" },
	{ value: 50, label: "50%" },
	{ value: 75, label: "75%" },
	{ value: 100, label: "100%" }
]

const footerOptions = [
	{ value: "none", label: "Never" },
	{ value: "on_course_complete", label: "If course complete" },
	{ value: "on_course_opened", label: "Always" }
]

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`

const CourseOptionsSidePanel = ({ orgId, courseId, courseInfo, updateCourseInfo, closemodal }) => {
	const footerButtonStatus =
		courseInfo && courseInfo.courseFooterButton && courseInfo.courseFooterButton.unlock_status
			? courseInfo.courseFooterButton.unlock_status
			: "none"
	// State
	const [panelView, setPanelView] = useState("main")
	const [optionsSwitch, setOptionsSwitch] = useState(null)
	const [imageUrl, setImageUrl] = useState(courseInfo.image)
	const [certificateBackground, setCertificateBackground] = useState(
		courseInfo && courseInfo.certificate_settings && courseInfo.certificate_settings.background_img
			? courseInfo.certificate_settings.background_img
			: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/certificate_background_default.png"
	)
	const [teacherImg, setTeacherImg] = useState(
		courseInfo && courseInfo.teacherLearnerImgs && courseInfo.teacherLearnerImgs.teacherImg
			? courseInfo.teacherLearnerImgs.teacherImg
			: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/default_teacher.png"
	)
	const [learnerImg, setLearnerImg] = useState(
		courseInfo && courseInfo.teacherLearnerImgs && courseInfo.teacherLearnerImgs.learnerImg
			? courseInfo.teacherLearnerImgs.learnerImg
			: "https://learnink-user-static.s3.eu-west-2.amazonaws.com/images/default_learner.png"
	)
	const [footerUnlockStatus, setFooterUnlockStatus] = useState(footerButtonStatus)
	const [loading, setLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [showSaveChanges, setShowSaveChanges] = useState(false)

	const handleCourseUpdate = (values) => {
		setLoading(true)
		const {
			name,
			target_learner,
			short_description,
			language,
			user_groups,
			minimum_accuracy,
			footer_button_unlock_status,
			footer_button_title,
			footer_button_url
		} = values
		const view_groups = user_groups.length === 1 && user_groups[0] === "registered" ? ["public"] : user_groups
		let courseFooterButton = { unlock_status: "none" }
		if (
			footer_button_unlock_status &&
			footer_button_unlock_status !== "none" &&
			footer_button_title &&
			footer_button_url
		) {
			courseFooterButton = {
				unlock_status: footer_button_unlock_status,
				title: footer_button_title,
				url: footer_button_url
			}
		}

		let newCourseInfo = {
			name,
			image: imageUrl,
			preview_url: courseInfo.preview_url,
			publish_url: courseInfo.publish_url,
			user_groups: { view_groups, access_groups: user_groups },
			target_learner,
			short_description,
			language,
			courseFooterButton,
			certificate_settings: {
				show_accuracy: false,
				background_img: certificateBackground,
				minimum_accuracy: minimum_accuracy ? minimum_accuracy : 0
			},
			teacherLearnerImgs: {
				teacherImg,
				learnerImg
			}
		}
		updateCourseInfo(courseId, newCourseInfo, orgId)

		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)
			closemodal()
		}, 3000)
	}

	// more options view
	const moreOptionsSwitchTo = (view) => {
		if (view === "archive") setOptionsSwitch("archive")
		else if (view === "duplicate") setOptionsSwitch("duplicate")
		else if (view === "download") setOptionsSwitch("download")
		else setOptionsSwitch(null)
	}

	if (redirect) return <Redirect to={`/org/${orgId}/create/courses`} />

	return (
		<ContentContainer>
			{panelView === "main" && (
				<>
					{loading ? (
						<CbLoading loadingText="Updating course..." padding="30px" />
					) : (
						<Form
							size="medium"
							autoComplete="off"
							onFinish={handleCourseUpdate}
							initialValues={{
								name: courseInfo.name,
								short_description: courseInfo.short_description,
								target_learner: courseInfo.target_learner,
								language: courseInfo.language,
								user_groups: courseInfo.user_groups.access_groups,
								minimum_accuracy:
									courseInfo &&
									courseInfo.certificate_settings &&
									courseInfo.certificate_settings.minimum_accuracy
										? courseInfo.certificate_settings.minimum_accuracy
										: 0,
								footer_button_unlock_status: footerButtonStatus,
								footer_button_title: courseInfo.courseFooterButton
									? courseInfo.courseFooterButton.title
									: null,
								footer_button_url: courseInfo.courseFooterButton
									? courseInfo.courseFooterButton.url
									: null
							}}
							layout="vertical"
							onValuesChange={() => setShowSaveChanges(true)}
						>
							<Form.Item
								label={<FormLabel>Course title</FormLabel>}
								name="name"
								rules={[
									{
										required: true,
										message: "You need to add a course title"
									}
								]}
								tooltip="This is what your learner will see when they open your course"
							>
								<Input placeholder="Enter course title..." />
							</Form.Item>
							<Form.Item
								label={<FormLabel>Target learner</FormLabel>}
								name="target_learner"
								rules={[
									{
										required: true,
										message: "You need to add a target learner"
									}
								]}
								tooltip="e.g. New sales agents"
							>
								<Input placeholder="Who are you targeting..." />
							</Form.Item>
							<Form.Item
								label={<FormLabel>Course description</FormLabel>}
								name="short_description"
								rules={[
									{
										required: true,
										message: "You need to add a course description"
									}
								]}
								tooltip="Add a short description to help explain what your course is about"
							>
								<TextArea placeholder="Enter a short description of the course..." rows={3} />
							</Form.Item>
							<Form.Item
								label={<FormLabel>Language</FormLabel>}
								name="language"
								tooltip="This is the language your course will be written in"
							>
								<Select
									showSearch
									showArrow={true}
									style={{ width: "100%" }}
									options={languageList}
									placeholder="Select a language..."
									filterOption={(input, option) =>
										option.label.toLowerCase().includes(input.toLowerCase())
									}
								/>
							</Form.Item>
							<Divider height="6px" />
							<LpImageInput
								id="course-image-upload"
								initialImageUrl={imageUrl}
								labelText="Course image"
								uploadImageMessage=""
								thumbPreviewShape="square"
								buttonText="Upload a new image"
								thumbSize={"50px"}
								clickableImage={true}
								uploadButtonAsText={true}
								setNewImage={(imageUrl) => {
									setImageUrl(imageUrl)
									setShowSaveChanges(true)
								}}
								labelFontSize="16px !important"
							/>
							<Divider height="16px" />
							<UserGroupsSelector
								tooltip="These are the groups that can access your course"
								labelFontSize="16px !important"
								required
							/>
							<Collapse ghost style={{ marginLeft: -12, marginRight: -12 }}>
								<Panel header="Advanced settings" key="1">
									<Form.Item
										label={<FormLabel>Minimum score</FormLabel>}
										name="minimum_accuracy"
										tooltip="This is the minimum score your user must get to pass an endline quiz"
									>
										<Select showArrow={true} options={scoreOptions} />
									</Form.Item>
									<CertificateBackgroundUpload
										title="Certificate image"
										initialImageUrl={certificateBackground}
										courseName={courseInfo.name}
										setNewImage={(value) => {
											setCertificateBackground(value)
											setShowSaveChanges(true)
										}}
									/>
									<Divider height={"14px"} />
									<LpImageInput
										id="teacher-image-upload"
										initialImageUrl={teacherImg}
										uploadImageMessage=""
										thumbPreviewShape="round"
										buttonText="Upload new image"
										thumbSize={"40px"}
										clickableImage={true}
										uploadButtonAsText={true}
										setNewImage={(imageUrl) => {
											setTeacherImg(imageUrl)
											setShowSaveChanges(true)
										}}
										labelText="Teacher avatar"
										labelFontSize="16px !important"
									/>
									<Divider height={"14px"} />
									<LpImageInput
										id="learner-image-upload"
										initialImageUrl={learnerImg}
										uploadImageMessage=""
										thumbPreviewShape="round"
										buttonText="Upload new image"
										thumbSize={"40px"}
										clickableImage={true}
										uploadButtonAsText={true}
										setNewImage={(imageUrl) => {
											setLearnerImg(imageUrl)
											setShowSaveChanges(true)
										}}
										labelText="Learner avatar"
										labelFontSize="16px !important"
									/>
									<Divider height={"14px"} />
									<Form.Item
										label={<FormLabel>Show course footer button</FormLabel>}
										name="footer_button_unlock_status"
										tooltip="This will show a button at the bottom of your course"
									>
										<Select
											showArrow={true}
											options={footerOptions}
											onChange={(value) => setFooterUnlockStatus(value)}
										/>
									</Form.Item>
									{footerUnlockStatus !== "none" && (
										<>
											<Form.Item
												label="Button title"
												name="footer_button_title"
												rules={[
													{
														required: true,
														message: "You need to add a title"
													}
												]}
												tooltip="This is the label on your button"
											>
												<Input placeholder="Press me" />
											</Form.Item>
											<Form.Item
												label="Button url"
												name="footer_button_url"
												validateTrigger="onBlur"
												rules={[
													{
														required: true,
														message: "You need to add a url"
													},
													{
														message: "You need to add a valid url",
														validator: (_, value) => {
															if (
																!/[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi.test(
																	value.trim()
																)
															) {
																return Promise.reject()
															} else {
																return Promise.resolve()
															}
														}
													}
												]}
												tooltip="This is the link for your button"
											>
												<Input />
											</Form.Item>
										</>
									)}
								</Panel>
							</Collapse>
							<Divider borderOnBottom={true} height="20px" />
							{showSaveChanges ? (
								<Form.Item>
									<ButtonContainer>
										<Space>
											<Button shape="round" size="large" onClick={closemodal}>
												Cancel
											</Button>
											<Button type="primary" shape="round" size="large" htmlType="submit">
												Save changes
											</Button>
										</Space>
									</ButtonContainer>
								</Form.Item>
							) : (
								<>
									<ButtonContainer>
										<Button shape="round" onClick={() => setPanelView("moreOptions")}>
											More options
										</Button>
									</ButtonContainer>
								</>
							)}
						</Form>
					)}
				</>
			)}

			{panelView === "moreOptions" && (
				<>
					{optionsSwitch === null ? (
						<>
							<Title level={5}>Duplicate course</Title>
							<Text type="secondary">
								Create a copy of this course in its current state in your courses folder
							</Text>
							<ButtonContainer style={{ justifyContent: "flex-start" }}>
								<Button shape="round" onClick={() => moreOptionsSwitchTo("duplicate")}>
									Duplicate
								</Button>
							</ButtonContainer>
							<Divider height={"34px"} />

							<Title level={5}>Download course</Title>
							<Text type="secondary">
								Keep a saved copy of your course in its current state in the course builder on file
							</Text>
							<ButtonContainer style={{ justifyContent: "flex-start" }}>
								<Button shape="round" onClick={() => moreOptionsSwitchTo("download")}>
									Download
								</Button>
							</ButtonContainer>
							<Divider height={"34px"} />

							<Title level={5}>Archive course</Title>
							<Text type="secondary">Move this course to your archive folder</Text>
							<ButtonContainer style={{ justifyContent: "flex-start" }}>
								<Button shape="round" onClick={() => moreOptionsSwitchTo("archive")}>
									Archive
								</Button>
							</ButtonContainer>
							<Divider borderOnBottom={true} height={"34px"} />

							<ButtonContainer>
								<Button shape="round" icon={<LeftOutlined />} onClick={() => setPanelView("main")}>
									Back to settings
								</Button>
							</ButtonContainer>
						</>
					) : (
						<>
							{optionsSwitch === "duplicate" && (
								<DuplicateCourse
									courseId={courseId}
									courseName={courseInfo.name}
									closemodal={closemodal}
									triggerRedirect={() => setRedirect(true)}
								/>
							)}
							{optionsSwitch === "download" && (
								<DownloadCourse courseId={courseId} closemodal={closemodal} />
							)}
							{optionsSwitch === "archive" && (
								<ArchiveCourse
									courseId={courseId}
									courseName={courseInfo.name}
									closemodal={closemodal}
									triggerRedirect={() => setRedirect(true)}
								/>
							)}
							<Divider borderOnBottom={true} height={"34px"} />

							<ButtonContainer>
								<Button shape="round" icon={<LeftOutlined />} onClick={() => moreOptionsSwitchTo(null)}>
									Back
								</Button>
							</ButtonContainer>
						</>
					)}
				</>
			)}
		</ContentContainer>
	)
}

CourseOptionsSidePanel.propTypes = {
	orgId: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	courseInfo: PropTypes.shape({
		name: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired,
		preview_url: PropTypes.string,
		publish_url: PropTypes.string,
		user_groups: PropTypes.shape({
			view_groups: PropTypes.array.isRequired,
			access_groups: PropTypes.array.isRequired
		}).isRequired,
		target_learner: PropTypes.string.isRequired,
		short_description: PropTypes.string.isRequired
	}).isRequired,
	updateCourseInfo: PropTypes.func.isRequired,
	closemodal: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	courseId: selectCourseId,
	courseInfo: selectCourseInfo
})

const mapDispatchToProps = (dispatch) => ({
	updateCourseInfo: (course_id, new_info, org_id) => dispatch(updateCourseInfo(course_id, new_info, org_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseOptionsSidePanel)
