import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import useUrlQueryString from "../../../hooks/useUrlQueryString"
import * as S from "../../../styles/PageStyles"
import { Table, Tag } from "antd"
import moment, { Moment } from "moment"

import { LearningPathUserCourse } from "../../../../../../domains/userAnalytics/UserAnalytics.model"
import { SLASH_DATE_FORMAT } from "learnink-common/lib/constants/constants"
import { ColumnsType } from "antd/lib/table"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"
import { statusColors } from "../../common/colors"
import { selectOrgCourseCards } from "../../../../redux/org/org.selectors"
import { CourseCards } from "../../../../util/model/OrgSelectors"
import CourseCompletionChart from "./CourseCompletionChart"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { UUID } from "learnink-common/lib/types/types"
import { calculateAverage } from "../../../../util/formatters"

interface Props {
	learningPath: LearningPath
	activityDateRange: string[]
	queryData: LearningPathUserCourse[]
	courseCards: CourseCards
}

export interface LearningPathUserTableRow {
	key: number
	customName: string
	status: "not started" | "in progress" | "complete"
	learningPathStartDate: string
	userId: string
	numCoursesStarted: number
	numCoursesCompleted: number
	coursesStarted: UUID[]
	coursesCompleted: UUID[]
	avScore: number | string
}

const filterDataByUserGroup = (queryData: LearningPathUserCourse[], userGroupsArr: string[]) => {
	let filteredByUserGroup = queryData
	if (userGroupsArr.length > 0) {
		filteredByUserGroup = filteredByUserGroup
			.filter((x) => x["userGroups"])
			// @ts-ignore
			.filter((x) => userGroupsArr.filter((value) => x["userGroups"].includes(value)).length > 0)
	}
	return filteredByUserGroup
}

const generateTableRows = (
	uniqueUserIds: string[],
	filteredByUserGroup: LearningPathUserCourse[],
	startMoment: Moment,
	endMoment: Moment
) => {
	const tableRows: LearningPathUserTableRow[] = []
	uniqueUserIds.forEach((userId, index) => {
		const userRows = filteredByUserGroup.filter((item) => item.userId === userId)
		const { learningPathStartDate, learningPathCompletionDate } = userRows[0]
		const coursesStarted = userRows.filter(
			(item) => moment(item.startedAt).diff(startMoment) > 0 && moment(item.startedAt).diff(endMoment) < 0
		)
		const coursesCompleted = userRows.filter(
			(item) => moment(item.completedAt).diff(startMoment) > 0 && moment(item.completedAt).diff(endMoment) < 0
		)
		const scores = coursesCompleted.filter((item) => item.avScore).map((item) => item.avScore)
		// @ts-ignore
		const avScore = scores.length > 0 ? Math.round(scores.reduce((a, b) => a + b, 0) / scores.length) : "--"
		const status =
			learningPathCompletionDate && moment(learningPathCompletionDate).diff(startMoment) < 0
				? "complete"
				: moment(learningPathStartDate).diff(endMoment) > 0
				? "not started"
				: "in progress"
		tableRows.push({
			key: index,
			userId: userId,
			status,
			learningPathStartDate: moment(userRows[0].learningPathStartDate).format(SLASH_DATE_FORMAT),
			customName: userRows[0].customName,
			numCoursesStarted: coursesStarted.length,
			numCoursesCompleted: coursesCompleted.length,
			coursesStarted: coursesStarted.map((item) => item.courseId),
			coursesCompleted: coursesCompleted.map((item) => item.courseId),
			avScore
		})
	})
	return tableRows
}

const LearningPathCourses = ({ learningPath, activityDateRange, queryData, courseCards }: Props) => {
	const [userGroups] = useUrlQueryString("userGroups")
	let userGroupsArr = typeof userGroups === "string" ? userGroups.split(",") : []
	const [tableData, setTableData] = useState<LearningPathUserTableRow[] | undefined>(undefined)

	const columns: ColumnsType<LearningPathUserTableRow> = [
		{
			title: "Custom name",
			dataIndex: "customName",
			key: "customName",
			render: (text: string, record: LearningPathUserTableRow) =>
				record.status === "in progress" ? <p>{text}</p> : <p style={{ opacity: 0.5 }}>{text}</p>
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text: string) => (
				// @ts-ignore
				<Tag style={{ opacity: text === "not started" ? 0.5 : 1 }} color={statusColors[text]}>
					{text}
				</Tag>
			)
		},
		{
			title: "Learning path start date",
			dataIndex: "learningPathStartDate",
			key: "learningPathStartDate",
			render: (text: string, record: LearningPathUserTableRow) =>
				record.status === "in progress" ? <p>{text}</p> : <p style={{ opacity: 0.5 }}>{text}</p>
		},
		{
			title: "Num courses started",
			dataIndex: "numCoursesStarted",
			key: "numCoursesStarted",
			render: (text: string, record: LearningPathUserTableRow) =>
				record.status === "in progress" ? <p>{text}</p> : <p style={{ opacity: 0.5 }}>{text}</p>
		},
		{
			title: "Num courses completed",
			dataIndex: "numCoursesCompleted",
			key: "numCoursesCompleted",
			render: (text: string, record: LearningPathUserTableRow) =>
				record.status === "in progress" ? <p>{text}</p> : <p style={{ opacity: 0.5 }}>{text}</p>
		},
		{
			title: "Av. score",
			dataIndex: "avScore",
			key: "avScore",
			render: (text: string, record: LearningPathUserTableRow) =>
				record.status === "in progress" ? <p>{text}</p> : <p style={{ opacity: 0.5 }}>{text}</p>
		}
	]

	useEffect(() => {
		if (queryData && activityDateRange) {
			const filteredByUserGroup = filterDataByUserGroup(queryData, userGroupsArr)
			const uniqueUserIds = [...new Set(filteredByUserGroup.map((x) => x.userId))]
			const startMoment = moment(activityDateRange[0]).startOf("day")
			const endMoment = moment(activityDateRange[1]).endOf("day")
			const tableRows = generateTableRows(uniqueUserIds, filteredByUserGroup, startMoment, endMoment)
			setTableData(tableRows)
		}
	}, [queryData, activityDateRange, userGroups])

	if (!tableData) {
		return <></>
	}

	return (
		<>
			<div className="row">
				<div className="col-sm-3">
					<ChartWrapper tooltip="" title="Total users">
						<SingleValueChart>
							{tableData.filter((row) => row.status === "in progress").length}
						</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-3">
					<ChartWrapper tooltip="" title="Num started a course">
						<SingleValueChart>
							{
								tableData.filter((row) => row.status === "in progress" && row.numCoursesStarted > 0)
									.length
							}
						</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-3">
					<ChartWrapper tooltip="" title="Num completed a course">
						<SingleValueChart>
							{
								tableData.filter((row) => row.status === "in progress" && row.numCoursesCompleted > 0)
									.length
							}
						</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-3">
					<ChartWrapper tooltip="" title="Av. score">
						<SingleValueChart>{calculateAverage(tableData, "avScore", 0)}%</SingleValueChart>
					</ChartWrapper>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<CourseCompletionChart learningPath={learningPath} data={tableData} />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<S.Card>
						<S.CardBody>
							<Table
								columns={columns}
								dataSource={tableData}
								expandable={{
									expandedRowRender: (record) => (
										<p style={{ margin: 0 }}>
											Courses completed:{" "}
											{record.coursesCompleted.map((courseName) => {
												return <Tag color="green">{courseCards[courseName].info.name}</Tag>
											})}
										</p>
									)
								}}
							/>
						</S.CardBody>
					</S.Card>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	courseCards: selectOrgCourseCards
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathCourses)
