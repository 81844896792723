import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const NavBar = styled.div`
	flex: 1;
	width: auto;
	height: ${({ screen_size }) => (screen_size === "real" ? "68px" : "48px")};
	padding: 12px;
	display: flex;
	flex-direction: horizontal;
	box-shadow: 0px 1px 2px #42424291;
	z-index: 0;
	background: white;
`
const OrgIcon = styled.img`
	width: ${({ screen_size }) => (screen_size === "real" ? "40px" : "24px")};
	height: ${({ screen_size }) => (screen_size === "real" ? "40px" : "24px")};
	border-radius: 8px;
	object-fit: cover;
	margin-top: ${({ screen_size }) => (screen_size === "real" ? "2px" : "1px")};
`
const OrgTitleContainer = styled.div`
	flex: 1;
	text-align: left;
	height: ${({ screen_size }) => (screen_size === "real" ? "40px" : "24px")};
	padding-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const OrgTitle = styled.div`
	font-size: ${({ screen_size }) => (screen_size === "real" ? "18px" : "14px")};
	font-weight: 600;
	overflow: auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: ${({ screen_size }) => (screen_size === "real" ? "280px" : "180px")};
`
const PageContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	z-index: 0;
	display: flex;
	flex-direction: column;
`
const FormContainer = styled.div`
	width: 100%;
	min-height: ${({ screen_size }) => (screen_size === "real" ? "300px" : "140px")};
	background-color: white;
	padding: 25px;
`
const BackgroundImgContainer = styled.div`
	width: 100%;
	background: url("${(props) =>
		props.cover_image_url
			? props.cover_image_url
			: "https://farminkweb.s3.eu-west-2.amazonaws.com/background_hills_light.svg"}");
	background-size: cover;
	background-position: bottom;
	position: relative;
	flex: 1;
	overflow: hidden;
`
const CurveEdgeLargeContainer = styled.div`
	position: absolute;
	bottom: -1px;
	left: 0px;
	width: 110%;
`
const CurveEdge = styled.div`
	width: 110%;
	position: absolute;
	bottom: -8px;
`
const FormSubTitle = styled.div`
	display: ${({ screen_size }) => (screen_size === "real" ? "block" : "none")};
	width: 100%;
	font-size: 15px;
	font-weight: 400;
	line-height: 17px;
	padding-bottom: 24px;
	color: var(--dark-grey);
	text-align: center;
`
const SubmitButton = styled.button`
	width: 100%;
	border-radius: 8px;
	height: ${({ screen_size }) => (screen_size === "real" ? "50px" : "40px")};
	font-size: ${({ screen_size }) => (screen_size === "real" ? "18px" : "14px")};
	font-weight: ${({ screen_size }) => (screen_size === "real" ? "auto" : "600")};
	background-color: ${({ primary_color }) => `${primary_color}`};
	color: white;
	cursor: pointer;
	box-shadow: inset 0px -3px 0px 3px rgba(0, 15, 12, 0.32);
	padding-bottom: 6px;
	margin-bottom: 6px;
`
const Container = styled.div`
	width: ${({ screen_size }) => (screen_size === "real" ? "372px" : "236px")};
	height: ${({ screen_size }) => (screen_size === "real" ? "630px" : "400px")};
	margin-bottom: ${({ screen_size }) => (screen_size === "real" ? "34px" : "20px")};
	display: flex;
	flex-direction: column;
`
const PhoneImage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url("https://farminkweb.s3.eu-west-2.amazonaws.com/mobile-background.svg");
	background-size: cover;
	width: ${({ screen_size }) => (screen_size === "real" ? "414px" : "264px")};
	height: ${({ screen_size }) => (screen_size === "real" ? "792px" : "504px")};
`

export function DemoAppOrgCover({
	cover_image_url,
	logo_image_url,
	org_name,
	primary_color,
	secondary_color,
	screen_size
}) {
	return (
		<React.Fragment>
			<PhoneImage screen_size={screen_size}>
				<Container screen_size={screen_size}>
					<NavBar screen_size={screen_size}>
						<OrgIcon
							src={
								logo_image_url
									? logo_image_url
									: "https://farminkweb.s3.eu-west-2.amazonaws.com/logo-default.svg"
							}
							screen_size={screen_size}
						/>
						<OrgTitleContainer screen_size={screen_size}>
							<OrgTitle screen_size={screen_size}>{org_name}</OrgTitle>
						</OrgTitleContainer>
					</NavBar>
					<PageContainer>
						<BackgroundImgContainer cover_image_url={cover_image_url}>
							<CurveEdgeLargeContainer>
								<svg width="413px" height="97px" viewBox="0 0 413 97">
									<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<g id="curveedge" fill={secondary_color} fillRule="nonzero">
											<path
												d="M413,97 L413,0 C158.512938,0 211.300052,77.0809022 0,78.6184788 C0,79.8677586 0,89.4357427 0,92.8631676 C0,95.1927155 0,96.0237098 0,96.5150491 C0,97.0063885 0,97 0,97 C0.00829660061,97 0.0158248446,97 0.0233573108,97 C49.7747679,97 283.716687,97 413,97 Z"
												id="Path"
											></path>
										</g>
									</g>
								</svg>
							</CurveEdgeLargeContainer>
							<CurveEdge>
								<svg height="65px" viewBox="0 0 490 62" width="490">
									<path
										d="m489.949601 61.7119697v-61.7119697c-301.485156 0-238.949437 56.181029-489.27190384 57v2.5085397 1.9451292.2583008h.02767088z"
										fill="#fff"
									/>
								</svg>
							</CurveEdge>
						</BackgroundImgContainer>
						<FormContainer screen_size={screen_size}>
							<div style={{ height: "10px" }} />
							<FormSubTitle screen_size={screen_size}>
								Get access to online courses and communities created by {org_name}. Press the button to
								get started
							</FormSubTitle>
							<SubmitButton primary_color={primary_color} screen_size={screen_size}>
								Register
							</SubmitButton>
							<SubmitButton primary_color={secondary_color} screen_size={screen_size}>
								Sign in
							</SubmitButton>
						</FormContainer>
					</PageContainer>
				</Container>
			</PhoneImage>
		</React.Fragment>
	)
}

DemoAppOrgCover.propTypes = {
	cover_image_url: PropTypes.string,
	logo_image_url: PropTypes.string,
	org_name: PropTypes.string,
	primary_color: PropTypes.string,
	secondary_color: PropTypes.string,
	screen_size: PropTypes.string
}

DemoAppOrgCover.defaultProps = {
	cover_image_url: "https://farminkweb.s3.eu-west-2.amazonaws.com/background_hills_light.svg",
	logo_image_url: "https://farminkweb.s3.eu-west-2.amazonaws.com/logo-default.svg",
	org_name: "Your Org",
	primary_color: "#FE9923",
	secondary_color: "#64C8C1",
	screen_size: "real"
}

export default DemoAppOrgCover
