import React from "react"
import styled from "styled-components"
import { Button, Typography } from "antd"

const { Title, Text } = Typography

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;
	height: 100%;
	padding: 40px 0px 40px 0px;
`

const BillingPortal = ({ activePlan }) => {
	return (
		<div
			className="row"
			style={{
				borderBottom: "1px solid var(--light-grey)",
				paddingBottom: 20
			}}
		>
			<div className="col-sm-8">
				<Column>
					<Title level={4}>Billing portal</Title>
					<Text>
						To update your payment details or download invoices sign in to our billing portal. You will need
						to use the email address associated with your account ({activePlan.stripe_customer_email})
					</Text>
				</Column>
			</div>
			<div className="col-sm-4">
				<Column>
					<a
						href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button type="primary" block shape="round" size="large">
							Open billing portal
						</Button>
					</a>
				</Column>
			</div>
		</div>
	)
}

export default BillingPortal
