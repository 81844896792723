import React from "react"
import { connect } from "react-redux"

import { updateQuestion } from "../../../../redux/courses/courses.actions"
import PanelContainer from "./common/PanelContainer"

// @ts-ignore
import Double from "../icons/double.svg"
// @ts-ignore
import Triple from "../icons/triple.svg"
// @ts-ignore
import Quadruple from "../icons/quadruple.svg"
// @ts-ignore
import New from "../icons/new.svg"
// @ts-ignore
import Old from "../icons/old.svg"
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"
import { QuestionAnswer } from "learnink-common/lib/domains/cards/questions/BaseQuestionCard.model"
import SegmentSetting from "./common/SegmentSetting"

interface Props {
	card: MultipleChoiceQuestionCard
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => void
}

function createQuestionAnswersArray(length: number): QuestionAnswer[] {
	return Array.from({ length }, (): QuestionAnswer => {
		return {
			text: "",
			correct: false
		}
	})
}

const MultChoiceQuestionEditor = ({ card, updateQuestion }: Props) => {
	const isBoolean = card.style && ["booleanImage"].includes(card.style)

	const updateNumQuizAnswers = (numAnswers: number) => {
		const answerOptions = card.answerOptions || []
		if (answerOptions.length < numAnswers) {
			const newIncorrectAnswers = createQuestionAnswersArray(numAnswers - answerOptions.length)
			updateQuestion({ ...card, answerOptions: [...answerOptions, ...newIncorrectAnswers] })
		} else {
			updateQuestion({
				...card,
				answerOptions: [
					...answerOptions.filter((item) => item.correct),
					...answerOptions.filter((item) => !item.correct).slice(0, numAnswers - 1)
				]
			})
		}
	}

	const updateTrueFalseCorrect = (val: "true" | "false") => {
		updateQuestion({
			...card,
			answerOptions: [
				{
					id: card.answerOptions?.[0].id,
					text: "true",
					correct: val === "true"
				},
				{
					id: card.answerOptions?.[1].id,
					text: "false",
					correct: val === "false"
				}
			]
		})
	}

	return (
		<>
			{!isBoolean && (
				<>
					<PanelContainer title="Num options">
						<SegmentSetting
							onChange={(val: 2 | 3 | 4) => updateNumQuizAnswers(val)}
							value={(card.answerOptions || []).length}
							id="editor_num_quiz_answers"
							options={[
								{
									label: "Two",
									value: 2,
									image: Double
								},
								{
									label: "Three",
									value: 3,
									image: Triple
								},
								{
									label: "Four",
									value: 4,
									image: Quadruple
								}
							]}
						/>
					</PanelContainer>
					<PanelContainer title="Style">
						<SegmentSetting
							onChange={(val: "multChoiceImage" | "legacy") => updateQuestion({ ...card, style: val })}
							value={card.style}
							id="editor_question_style"
							options={[
								{
									label: "New",
									value: "multChoiceImage",
									image: New
								},
								{
									label: "Old",
									value: "legacy",
									image: Old
								}
							]}
						/>
					</PanelContainer>
				</>
			)}
			{isBoolean && (
				<>
					<PanelContainer title="Correct answer">
						<SegmentSetting
							onChange={(val: "true" | "false") => updateTrueFalseCorrect(val)}
							value={(card.answerOptions || []).filter((item) => item.correct)[0].text}
							id="editor_correct_answer"
							options={[
								{
									label: "True",
									value: "true",
									faIcon: faThumbsUp
								},
								{
									label: "False",
									value: "false",
									faIcon: faThumbsDown
								}
							]}
						/>
					</PanelContainer>
				</>
			)}
		</>
	)
}

const mapDispatchToProps = (dispatch: any) => ({
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => dispatch(updateQuestion(updateParams))
})

export default connect(null, mapDispatchToProps)(MultChoiceQuestionEditor)
