import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import LpColorPicker from "./LpColorPicker"

const Box = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`
const Row = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
const TextContainer = styled.div`
	flex: 8;
	padding-left: 0px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`
const ColorsContainer = styled.div`
	flex: 1;
	margin-left: 20px;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
`
const FormLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	margin-top: 0px;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
`
const UploadText = styled.div`
	width: 100%;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 0px;
	max-width: 300px;
	@media (max-width: 590px) {
		font-size: 12px;
		line-height: 16px;
	}
`

const LpTwoColorInput = ({ initialColors, labelText, uploadMessage, handleColorChanges, disabled }) => {
	let initialPrimary = initialColors.primary_color ? initialColors.primary_color : "#FE9923"
	let initialSecondary = initialColors.secondary_color ? initialColors.secondary_color : "#64C8C1"

	const handlePrimaryChange = (hex) => {
		handleColorChanges("primary_color", hex)
	}
	const handleSecondaryChange = (hex) => {
		handleColorChanges("secondary_color", hex)
	}

	return (
		<Box>
			<Row>
				<TextContainer>
					<FormLabel>{labelText}</FormLabel>
					<UploadText>{uploadMessage}</UploadText>
				</TextContainer>
				<ColorsContainer>
					<LpColorPicker
						initialHex={initialPrimary}
						callbackColorChange={handlePrimaryChange}
						disabled={disabled}
						style={{}}
					/>
					<LpColorPicker
						initialHex={initialSecondary}
						callbackColorChange={handleSecondaryChange}
						disabled={disabled}
						style={{}}
					/>
				</ColorsContainer>
			</Row>
		</Box>
	)
}

LpTwoColorInput.propTypes = {
	initialColors: PropTypes.object,
	labelText: PropTypes.string,
	uploadMessage: PropTypes.string,
	disabled: PropTypes.bool,
	handleColorChanges: PropTypes.func.isRequired
}

LpTwoColorInput.defaultProps = {
	initialColors: {
		colorOne: "#FE9923",
		colorTwo: "#64C8C1"
	},
	labelText: "",
	uploadMessage: "",
	disabled: false
}

export default LpTwoColorInput
