import React, { useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import { setOrgTeam } from "../../redux/org/org.actions"
import { selectOrgId, selectOrgTeam } from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

const TableContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const TableInner = styled.table`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const TableHeader = styled.tr`
	border-bottom: solid 1px var(--light-grey);
	height: 50px;
`
const MemberNameHeader = styled.th`
	font-size: 15px;
	font-weight: 600;
	color: black;
	text-align: left;
`
const MemberEmailHeader = styled.th`
	font-size: 15px;
	font-weight: 600;
	color: black;
`
const MemberRoleHeader = styled.th`
	font-size: 15px;
	font-weight: 600;
	color: black;
	text-align: center;
`
const CommunityTableRow = styled.tr`
	border-bottom: solid 1px var(--light-grey);
	height: 60px;
`
const MemberAvatarCell = styled.td`
	width: 60px;
	padding-left: 4px;
`
const MemberAvatar = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 99%;
	background: ${(props) => (props.authed ? "var(--mid-green)" : "var(--light-grey)")};
	color: white;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 35px;
	font-weight: 600;
	text-align: center;
`
const MemberNameCell = styled.td`
	font-size: 15px;
	color: ${(props) => (props.authed ? "black" : "var(--mid-grey)")};
	font-weight: 600;
`
const MemberEmailCell = styled.td`
	text-align: left;
	font-size: 15px;
	font-weight: 400;
`
const MemberRoleCell = styled.td`
	text-align: center;
`
const MemberRoleText = styled.span`
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	background: var(--v-light-grey);
	color: black;
	text-transform: capitalize;
	padding: 4px 10px 4px 10px;
	border-radius: 12px;
`
const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: 1px;
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 62px;
	line-height: 62px;
	padding: 0px 20px 10px 20px;
`
const TitleText = styled.h2`
	color: black;
	font-size: 20px;
	line-height: 24px;
	padding: 0px 20px 5px 20px;
`
const SubtitleText = styled.p`
	color: var(--dark-grey);
	font-size: 14px;
	line-height: 20px;
	padding: 0px 20px 0px 20px;
`

const OrgTeam = ({ setOrgTeam, orgTeam, orgId }) => {
	// set org team data here
	useEffect(() => {
		setOrgTeam(orgId)
		/*
    return () => {
      // add cleanup?
    };
    */
	}, [orgId, setOrgTeam])

	if (orgTeam === null) return <div>loading...</div>

	return (
		<>
			<S.Container>
				<DashboardHeaderButton
					title={"Team"}
					buttonTitle={"Add team member"}
					buttonLink={"/org/" + orgId + "/invite"}
				/>
				<TableContainer>
					{orgTeam.length === 0 ? (
						<CourseListInner>
							<CourseListEmpty>
								<IconText>
									<FontAwesomeIcon icon={faExclamationTriangle} />
								</IconText>
								<TitleText>No team members found</TitleText>
								<SubtitleText>Something went wrong... try refreshing the page</SubtitleText>
							</CourseListEmpty>
						</CourseListInner>
					) : (
						<TableInner>
							<tbody>
								<TableHeader>
									<th></th>
									<MemberNameHeader>Name</MemberNameHeader>
									<MemberEmailHeader>Email</MemberEmailHeader>
									<MemberRoleHeader>Role</MemberRoleHeader>
									<th></th>
								</TableHeader>

								{orgTeam.map((member, index) => (
									<CommunityTableRow key={index}>
										<MemberAvatarCell>
											<MemberAvatar authed={member.authed}>{member.info.initials}</MemberAvatar>
										</MemberAvatarCell>
										<MemberNameCell authed={member.authed}>{member.info.name}</MemberNameCell>
										<MemberEmailCell>{member.info.email}</MemberEmailCell>
										<MemberRoleCell>
											<MemberRoleText>{member.role}</MemberRoleText>
										</MemberRoleCell>
									</CommunityTableRow>
								))}
							</tbody>
						</TableInner>
					)}
				</TableContainer>
			</S.Container>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgTeam: selectOrgTeam
})

const mapDispatchToProps = (dispatch) => ({
	setOrgTeam: (orgId) => dispatch(setOrgTeam(orgId))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrgTeam)
