// STYLED NUMBER FOR DASHBOARDS

import React from "react"
import SingleValueChart from "./SingleValueChart"
import ChartWrapper from "./ChartWrapper"

const SingleValueWrapper = ({ value, courseHasData, title, tooltip, sampleSizeValue }) => {
	return (
		<ChartWrapper tooltip={tooltip} title={title}>
			{courseHasData ? (
				<div>
					<SingleValueChart>{value}</SingleValueChart>
					{sampleSizeValue !== null && sampleSizeValue !== undefined && (
						<div style={{ position: "absolute", bottom: 10, left: 10 }}>N={sampleSizeValue}</div>
					)}
				</div>
			) : (
				<SingleValueChart>N/A</SingleValueChart>
			)}
		</ChartWrapper>
	)
}

export default SingleValueWrapper
