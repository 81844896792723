import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { Typography } from "antd"
import Answer from "./Answer"
import BooleanAnswer from "./BooleanAnswer"
import { updateQuestion } from "../../../../../redux/courses/courses.actions"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"

const { Paragraph } = Typography

const CardFlexWrapper = styled.div`
	width: 100%;
	margin: 4px;
	display: flex;
	flex-direction: column;
`
const QuestionTextContainer = styled.div`
	margin: 15px 5px;
`
const TipContainer = styled.div`
	margin-top: 12px;
	padding: 10px;
	border-radius: 4px;
	background: var(--v-light-grey);
`
const QuestionImg = styled.div`
	width: 100%;
	min-height: 150px;
	background-size: cover;
	background-position: center;
`

interface Props {
	card: MultipleChoiceQuestionCard
	updateQuestion?: (updateParams: MultipleChoiceQuestionCard) => void
	editable?: boolean
}

const QuestionCard = ({ card, updateQuestion, editable }: Props) => {
	const updateQuestionCard = (updateParams: MultipleChoiceQuestionCard) => {
		if (updateQuestion) {
			updateQuestion(updateParams)
		}
	}

	const updateAnswerOption = (index: number, value: string) => {
		const answerOptionsUpdated = card.answerOptions || []
		answerOptionsUpdated[index].text = value
		updateQuestionCard({ ...card, answerOptions: answerOptionsUpdated })
	}

	return (
		<CardFlexWrapper>
			<QuestionImg
				style={{
					backgroundImage: `url("${card.image}")`,
					borderRadius: !card.style || card.style === "legacy" ? 15 : 4
				}}
			/>
			<QuestionTextContainer>
				<Paragraph
					editable={
						editable
							? {
									onChange: (value) => updateQuestionCard({ ...card, text: value }),
									triggerType: ["text"],
									enterIcon: null
							  }
							: false
					}
					ellipsis={{ rows: 3 }}
					style={{
						lineHeight: 1.4,
						fontSize: 15,
						marginBottom: 0,
						width: "100%",
						fontWeight: 500,
						textAlign: !card.style || card.style === "legacy" ? "left" : "center"
					}}
				>
					{card.text ? (
						card.text
					) : (
						<span style={{ color: "var(--mid-grey)" }}>Enter your question text...</span>
					)}
				</Paragraph>
			</QuestionTextContainer>
			{card.style === "booleanImage" ? (
				<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<BooleanAnswer
						isTrue={true}
						isCorrect={(card.answerOptions || []).filter((item) => item.text === "true")[0].correct}
					/>
					<BooleanAnswer
						isTrue={false}
						isCorrect={(card.answerOptions || []).filter((item) => item.text === "false")[0].correct}
					/>
				</div>
			) : (
				<>
					{(card.answerOptions || []).map((ans, index) => (
						<Answer
							key={index}
							index={index}
							initialText={ans.text}
							placeholderText="Enter answer..."
							correct={ans.correct}
							updateAnswer={(value: string) => updateAnswerOption(index, value)}
							style={card.style}
							editable={editable}
						/>
					))}
				</>
			)}
			{editable && (
				<TipContainer>
					<Paragraph
						editable={{
							onChange: (value) => updateQuestionCard({ ...card, answerTip: value }),
							// @ts-ignore
							triggerType: "text",
							enterIcon: null
						}}
						ellipsis={{ rows: 5 }}
						style={{
							lineHeight: 1.3,
							marginBottom: 0
						}}
					>
						{card.answerTip ? (
							card.answerTip
						) : (
							<span style={{ color: "var(--mid-grey)" }}>Enter an optional answer tip...</span>
						)}
					</Paragraph>
				</TipContainer>
			)}
		</CardFlexWrapper>
	)
}

const mapDispatchToProps = (dispatch: any) => ({
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => dispatch(updateQuestion(updateParams))
})

export default connect(undefined, mapDispatchToProps)(QuestionCard)
