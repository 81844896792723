import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectStage } from "../../../redux/courses/courses.selectors"

import CallOutCardEditor from "./panels/CallOutCardEditor"
import ImageUploads from "./panels/ImageUploads"
import ImageLibrary from "./panels/ImageLibrary"
import TeacherLearnerSelect from "./panels/TeacherLearnerSelect"
import VideoEditor from "./panels/VideoEditor"
import SurveyEditor from "./panels/SurveyEditor"
import MultChoiceQuestionEditor from "./panels/MultChoiceQuestionEditor"
import ImageSettings from "./panels/ImageSettings"
import { SurveyCard } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"
import { CallOutCard } from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { ImageCard } from "learnink-common/lib/domains/cards/lessons/image/ImageCard.model"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"

interface Props {
	card: Card
	stage: any
}

const EditorContainer = ({ card, stage }: Props) => {
	if (typeof stage.editor.imageIndex == "number") {
		return (
			<>
				<ImageLibrary card={card as CallOutCard} />
				<ImageUploads type="icons" card={card as CallOutCard} />
			</>
		)
	}

	if (card.type === "multipleChoiceQuestion") {
		return (
			<>
				<MultChoiceQuestionEditor card={card} />
				<ImageUploads type="images" card={card as MultipleChoiceQuestionCard} />
			</>
		)
	}
	return (
		<>
			<TeacherLearnerSelect card={card} />
			{card.type === "image" && (
				<>
					<ImageSettings card={card} />
					<ImageUploads type="images" card={card as ImageCard} />
				</>
			)}
			{card.type === "callOut" && <CallOutCardEditor card={card} />}
			{card.type === "video" && <VideoEditor card={card} />}
			{["multipleChoiceSurvey", "textInputSurvey", "numberInputSurvey"].includes(card.type) && (
				<SurveyEditor card={card as SurveyCard} />
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

export default connect(mapStateToProps)(EditorContainer)
