import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Transition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import LpButtonAsDiv from "./LpButtonAsDiv"

const Overlay = styled.div`
	position: fixed;
	top: ${({ overlayStyles }) => (overlayStyles && overlayStyles.top ? overlayStyles.top : 0)};
	left: ${({ overlayStyles }) => (overlayStyles && overlayStyles.left ? overlayStyles.left : 0)};
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
`
const ModalContainer = styled.div`
	min-width: 490px;
	border-radius: 8px;
	background: white;
	position: relative;
	padding: 30px;
`
const ModalInnerContainer = styled.div`
	flex: 1;
	min-height: 100px;
`
const CloseButton = styled.div`
	font-size: 18px;
	color: black;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	background: white;
	border-radius: 99%;
	position: absolute;
	right: 20px;
	top: 20px;
	border: 1px solid var(--light-grey);
	cursor: pointer;
`
const TransitionContainer = styled.div`
	position: absolute;
	background: transparent;
	border: 0px solid green;
	transition: 150ms ease-in;
	opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
	display: ${({ state }) => (state === "exited" ? "none" : "inline-block")};
`

const DummyChildren = ({
	closemodal // you MUST add this prop to children
}) => {
	return (
		<>
			<p> Use the closemodal prop inside children to handle close</p>
			<button onClick={closemodal}>Close Modal</button>
		</>
	)
}

const GeModal = ({ initOpen, triggerEl, overlayStyles, children, unsetSidebarOverflow, setSidebarOverflow }) => {
	const node = useRef()

	const [isOpen, setIsOpen] = useState(initOpen)

	const openModal = () => {
		setIsOpen(true)
		if (unsetSidebarOverflow) {
			unsetSidebarOverflow() // this is ONLY in case modal is set on scrollable sidebar
		}
	}
	const closeModal = () => {
		setIsOpen(false)
		if (setSidebarOverflow) {
			setSidebarOverflow() // this is ONLY in case modal is set on scrollable sidebar
		}
	}

	useEffect(() => {
		// handle action based on whether user clicks inside modal or not
		const handleClickOutside = (e) => {
			if (e && e.target && e.target.id && e.target.id === "modal-overlay") {
				setIsOpen(false)
			}
			if (setSidebarOverflow) {
				setSidebarOverflow() // this is ONLY in case modal is set on scrollable sidebar
			}
		}

		// when open add the event listener
		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			// clear listener when modal is closed
			document.removeEventListener("mousedown", handleClickOutside)
		}
		// clear listener when component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isOpen, setSidebarOverflow])

	return (
		<>
			{/*
        Button used here as trigger for modal
        the trigger element should be a styled div
        of some kind
      */}
			<div onClick={openModal}>{triggerEl}</div>

			{/*
        Use Transition to animate the mounting and un unmounting of the 
        Modal element
      */}
			<Transition in={isOpen} timeout={{ enter: 25, exit: 150 }} mountOnEnter unmountOnExit>
				{(state) => (
					// state change: exited -> entering -> entered -> exiting -> exited
					<TransitionContainer state={state}>
						<Overlay id="modal-overlay" overlayStyles={overlayStyles}>
							{/*
                Note use of ref when we use handleClickOutside
                it allows us out fogure out whether click comes 
                from inside ModalContainer or not

                see example https://codesandbox.io/s/9o3lw5792w?file=/src/Dropdown.js:511-810
              */}
							<ModalContainer ref={node}>
								<CloseButton onClick={closeModal}>
									<FontAwesomeIcon icon={faTimes} />
								</CloseButton>

								<ModalInnerContainer>
									{React.cloneElement(children, { closemodal: closeModal })}
								</ModalInnerContainer>
							</ModalContainer>
						</Overlay>
					</TransitionContainer>
				)}
			</Transition>
		</>
	)
}

GeModal.propTypes = {
	initOpen: PropTypes.bool.isRequired,
	triggerEl: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,
	unsetSidebarOverflow: PropTypes.func, // optional for case where we have modal on sidebar
	setSidebarOverflow: PropTypes.func // optional for case where we have modal on sidebar
}

GeModal.defaultProps = {
	initOpen: false,
	triggerEl: <LpButtonAsDiv>Open Modal</LpButtonAsDiv>,
	children: <DummyChildren />,
	overlayStyles: {
		top: 0,
		left: 0
	}
}

export default GeModal
