import { TemplatesActionTypes } from "./templates.types"

const INITIAL_STATE = {
	courseTemplateList: []
}

const templatesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TemplatesActionTypes.SET_COURSE_TEMPLATE_LIST:
			return {
				...state,
				courseTemplateList: action.payload
			}
		default:
			return state
	}
}

export default templatesReducer
