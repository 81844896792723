export const OrgActionTypes = {
	SET_ORG_AUTH_INFO: "SET_ORG_AUTH_INFO",
	SET_ORG_DATA: "SET_ORG_DATA",
	CLEAR_ORG_DATA: "CLEAR_ORG_DATA",
	SET_ORG_TEAM: "SET_ORG_TEAM",
	SET_ORG_INFO: "SET_ORG_INFO",
	CREATE_NEW_COURSE: "CREATE_NEW_COURSE",
	UPDATE_COURSE_CARD_IN_ORG: "UPDATE_COURSE_CARD_IN_ORG",
	DELETE_COURSE_CARD_FOR_ORG: "DELETE_COURSE_CARD_FOR_ORG",
	ADD_IMAGE_TO_LIBRARY: "ADD_IMAGE_TO_LIBRARY",
	SET_USER_GROUPS: "SET_USER_GROUPS",
	SET_LEARNING_PATHS: "SET_LEARNING_PATHS",
	SET_SEQUENCES: "SET_SEQUENCES"
}
