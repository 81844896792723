import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import Confetti from "react-dom-confetti"
import { Alert, Button } from "antd"

import { selectOrgId } from "../../redux/org/org.selectors"
import { addTeamMembers } from "../../redux/user/user.actions"

import LpButton from "../common/LpButton"
import LpFormDropdown from "../common/LpFormDropdown"

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: white;
	padding: 4px;
	@media (max-width: 590px) {
		flex-direction: column;
	}
`
const FormContainer = styled.div`
	flex: 2;
	box-sizing: border-box;
	margin: 0 auto;
	-webkit-box-pack: start;
	justify-content: flex-start;
	margin: 0px 22px;
	display: flex;
	flex-direction: column;
	@media (max-width: 590px) {
		margin: 44px 0px;
	}
`
const ParagraphText = styled.p`
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	padding-bottom: 32px;
`
const AddEmailContainer = styled.div`
	display: flex;
`
const NameInputBox = styled.div`
	flex: 3;
	margin-right: 20px;
	display: flex;
	align-items: flex-end;
`
const EmailInputBox = styled.div`
	flex: 5;
	margin-right: 20px;
	display: flex;
	align-items: flex-end;
`
const SelectRoleBox = styled.div`
	flex: 3;
	margin-right: 20px;
`
const AddInviteBox = styled.div`
	flex: 2;
	display: flex;
	align-items: flex-end;
`
const DeleteInviteBox = styled.div`
	flex: 2;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`
const BottomButtomsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	flex-direction: row;
`
const Divider = styled.div`
	width: 100%;
	height: ${({ height }) => (height ? height : "30px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	margin-bottom: ${({ removeBottomMargin }) => (removeBottomMargin ? "0px" : "28px")};
`
const EmailTextLine = styled.div`
	display: flex;
	font-weight: 500;
	font-size: 15px;
	margin: 10px;
`
const ErrorBox = styled.div`
	display: flex;
	padding: 0px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`
const TextInputContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const TextInputLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
`
const TextInput = styled.input`
	margin: 0;
	box-shadow: rgb(213, 220, 227) 0px 0px 0px 1px inset;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 44px;
	width: 100%;
	display: block;
	padding-left: 16px !important;
	padding-right: 16px !important;
	font-size: 15px;
	line-height: 44px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-radius: 4px !important;
	border-width: 0px;
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	:focus {
		border: solid 1px var(--secondary-color) !important;
	}
`
const confettiConfig = {
	angle: "42",
	spread: "184",
	startVelocity: "50",
	elementCount: "53",
	dragFriction: "0.19",
	duration: "1250",
	stagger: "2",
	width: "10px",
	height: "10px",
	perspective: "500px",
	colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
}

const AddYourTeam = ({ orgId, addTeamMembers }) => {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [role, setRole] = useState({ value: "creator", label: "Creator" })
	const [invitations, setInvitations] = useState([])
	const [errorMsg, setErrorMsg] = useState(null)
	const [dummyLoading, setDummyLoading] = useState(false)
	const [confetti, setConfetti] = useState(false)
	const [redirect, setRedirect] = useState(false)

	let options = [{ value: "creator", label: "Creator" }]

	const handleNameChange = (event) => {
		setErrorMsg(null)
		setName(event.target.value.trim()) // trim string here
	}
	const handleEmailChange = (event) => {
		setErrorMsg(null)
		setEmail(event.target.value.trim()) // trim string here
	}
	const handleRoleChange = (val) => {
		setErrorMsg(null)
		setRole(val)
	}

	const handleCreateInvite = () => {
		if (!name) setErrorMsg("You must add a first name to the invitation")
		else if (!email) setErrorMsg("You must add an email to the invitation")
		else if (invitations.map((x) => x.email).includes(email)) setErrorMsg("You already entered this email")
		else if (!/.+@.+\.[A-Za-z]+$/.test(email)) setErrorMsg("You must enter a valid email to the invitation")
		else if (!role) setErrorMsg("You must set a role for the invitation")
		else {
			let updatedInvitations = [...invitations]
			updatedInvitations.push({
				name: name,
				email: email.toLowerCase(),
				role: role
			})
			setInvitations(updatedInvitations)
			setName("")
			setEmail("")
		}
	}

	const handleRemoveInvitation = (index) => {
		let newInvitations = [...invitations]
		newInvitations.splice(index, 1)
		setInvitations(newInvitations)
	}

	const handleCancel = () => {
		setInvitations([])
		setName("")
		setEmail("")
	}

	const handleCompleteClick = () => {
		// handle send invites action first
		addTeamMembers(invitations, orgId)

		// reset invitations
		setInvitations([])

		// do some dummy stuff
		setDummyLoading(true)
		setTimeout(() => {
			setDummyLoading(false)
			setConfetti(true)
		}, 2000)

		// then handle onboarding update OR redirect Or do nothing...
		setTimeout(() => {
			setRedirect(true)
		}, 3250)
	}

	if (redirect) return <Redirect to={`/org/${orgId}/team`} />

	return (
		<MainContainer>
			<FormContainer>
				<Divider removeBottomMargin height="10px" />

				<ParagraphText>
					Invite new members to join your workspace by entering their email address and role below.
				</ParagraphText>

				<AddEmailContainer>
					<NameInputBox>
						<TextInputContainer>
							<TextInputLabel>First Name</TextInputLabel>
							<TextInput placeholder="Enter name" onChange={handleNameChange} value={name} />
						</TextInputContainer>
					</NameInputBox>

					<EmailInputBox>
						<TextInputContainer>
							<TextInputLabel>Email</TextInputLabel>
							<TextInput placeholder="Enter email" onChange={handleEmailChange} value={email} />
						</TextInputContainer>
					</EmailInputBox>

					<SelectRoleBox>
						<LpFormDropdown
							labelText="Role"
							placeholder="Select"
							initialValue={{ value: "creator", label: "Creator" }}
							options={options}
							handleChange={handleRoleChange}
						/>
					</SelectRoleBox>
					<AddInviteBox>
						<LpButton width="100px" onClick={handleCreateInvite} type="outline">
							Add
						</LpButton>
					</AddInviteBox>
				</AddEmailContainer>

				{errorMsg && (
					<ErrorBox>
						<div style={{ width: "75%" }}>
							<Alert message={errorMsg} type="warning" />
						</div>
					</ErrorBox>
				)}

				<Divider borderOnBottom={true} />

				<>
					{invitations.map((item, index) => (
						<EmailTextLine key={index}>
							<div style={{ width: "10px" }} />
							<NameInputBox>{item.name}</NameInputBox>
							<EmailInputBox>{item.email}</EmailInputBox>
							<SelectRoleBox>{item.role.label}</SelectRoleBox>
							<DeleteInviteBox>
								<button onClick={() => handleRemoveInvitation(index)}>
									<FontAwesomeIcon icon={faTrash} />
								</button>
							</DeleteInviteBox>
						</EmailTextLine>
					))}
				</>

				{invitations.length > 0 && <Divider borderOnBottom={true} />}

				<Divider removeBottomMargin height="30px" />

				<BottomButtomsContainer>
					<Button
						size="large"
						shape="round"
						disabled={invitations.length === 0 || confetti}
						onClick={handleCancel}
					>
						Cancel
					</Button>
					<div style={{ width: "14px" }} />
					<Confetti active={confetti} config={confettiConfig} />
					<Button
						size="large"
						shape="round"
						type="primary"
						loading={dummyLoading}
						disabled={invitations.length === 0}
						onClick={handleCompleteClick}
					>
						{confetti ? "Invitations Sent!" : "Send Invitations"}
					</Button>
				</BottomButtomsContainer>
			</FormContainer>
		</MainContainer>
	)
}

AddYourTeam.propTypes = {
	addTeamMembers: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	addTeamMembers: (invitations, org_id) => dispatch(addTeamMembers(invitations, org_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddYourTeam)
