import React from "react"
import styled from "styled-components"

// taken from https://codesandbox.io/s/6v7n1vr8yn

const CheckBoxWrapper = styled.div`
	position: relative;
`
const CheckBoxLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	width: 312px;
	height: 54px;
	border-radius: 10px;
	border: solid 1px var(--dark-grey);
	background: var(--light-grey);
	cursor: pointer;
	&::after {
		content: "";
		display: block;
		border-radius: 8px;
		width: 142px;
		height: 42px;
		margin: 5px;
		background: var(--secondary-color-pale);
		border: solid 2px var(--secondary-color);
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
		transition: 0.2s;
	}
`
const DefaultText = styled.span`
	position: absolute;
	top: 16px;
	left: 44px;
	text-transform: uppercase;
	color: var(--mid-grey);
	font-size: 14px;
	font-weight: 800;
`
const AltText = styled.span`
	position: absolute;
	top: 16px;
	right: 34px;
	font-size: 14px;
	text-transform: uppercase;
	color: var(--mid-grey);
	font-weight: 800;
`
const CheckBox = styled.input`
	opacity: 0;
	z-index: 1;
	border-radius: 8px;
	width: 150px;
	height: 46px;
	&:checked + ${CheckBoxLabel} {
		background: var(--light-grey);
		&::after {
			content: "";
			display: block;
			border-radius: 8px;
			width: 142px;
			height: 42px;
			margin-left: 160px;
			transition: 0.2s;
		}
	}
`

const CbSliderButton = ({ defaultChecked, onChange, defaultText, altText }) => {
	return (
		<CheckBoxWrapper>
			<CheckBox id="checkbox" type="checkbox" defaultChecked={!defaultChecked} onChange={onChange} />
			<CheckBoxLabel htmlFor="checkbox">
				<DefaultText>{defaultText}</DefaultText>
				<AltText>{altText}</AltText>
			</CheckBoxLabel>
		</CheckBoxWrapper>
	)
}

export default CbSliderButton
