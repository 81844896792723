// COURSE FOLDER PAGE

import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FolderFilled } from "@ant-design/icons"

import {
	selectOrgCourses,
	selectOrgCourseCards,
	selectOrgId,
	selectOrgCourseFolders
} from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

import FolderCard from "../../components/courseBuilder/courses/FolderCard"
import CourseFolderBreadcrumbs from "../../components/courseBuilder/courses/CourseFolderBreadcrumbs"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import LpModal from "../../components/common/LpModal"
import CreateFolder from "../../components/courseBuilder/courses/modals/CreateFolder"
import CourseCard from "../../components/courseBuilder/courses/CourseCard"

const CourseListContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListInner = styled.div`
	height: 100%;
	display: table;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseCardContainer = styled.div`
	height: 220px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`
const FolderContainer = styled.div`
	height: 110px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`

const CourseFolder = ({
	orgId,
	courses,
	courseFolders: { folder_slugs, folder_data },
	courseCards,
	match: { params },
	location: { pathname }
}) => {
	const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
	const slug = params[0]
	const folderId = slug ? folder_slugs[`${slug}/`] : null

	if (folderId === undefined) {
		return null
	}

	const folder_name = folder_data.filter((item) => item.id === folderId)[0].name
	const childFolders = folder_data.filter((item) => item.course_folder_parent_id === folderId)
	const childCourses = courses
		.filter((courseId) => courseCards[courseId].course_folder_parent_id === folderId)
		.filter((item) => !courseCards[item].info.user_groups.access_groups.includes("archived"))
	const numItems = childCourses.length + childFolders.length

	return (
		<S.Container>
			<DashboardHeaderButton
				title={folder_name}
				buttonTitle={"Create course"}
				buttonLink={`/org/${orgId}/create/course-templates?folder=${folderId}`}
				secondaryButtonTitle={"Create folder"}
				secondaryButtonOnClick={() => setShowCreateFolderModal(true)}
			/>
			<CourseFolderBreadcrumbs rootPath={`/org/${orgId}/create/courses`} slug={slug} folder_data={folder_data} />
			<CourseListContainer>
				{numItems > 0 ? (
					<>
						<CourseListInner>
							{childFolders.map((folder, index) => (
								<FolderContainer key={index}>
									<FolderCard orgId={orgId} folder={folder} pathname={pathname} />
								</FolderContainer>
							))}
						</CourseListInner>
						<CourseListInner>
							{childCourses.map((courseId, index) => (
								<CourseCardContainer key={index}>
									<CourseCard courseId={courseId} index={index} />
								</CourseCardContainer>
							))}
						</CourseListInner>
					</>
				) : (
					<CourseListInner>
						<GeNoItemsFound
							icon={<FolderFilled style={{ fontSize: 60 }} />}
							titleText={"Empty folder"}
							subtitleText={"It looks like there's nothing in this folder"}
						/>
					</CourseListInner>
				)}
			</CourseListContainer>
			<LpModal isShowingModal={showCreateFolderModal} hideModal={() => setShowCreateFolderModal(false)}>
				<CreateFolder parentId={folderId} hideModal={() => setShowCreateFolderModal(false)} />
			</LpModal>
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	courses: selectOrgCourses,
	courseCards: selectOrgCourseCards,
	courseFolders: selectOrgCourseFolders,
	orgId: selectOrgId
})

export default connect(mapStateToProps)(CourseFolder)
