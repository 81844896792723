import React, { useState } from "react"
import styled from "styled-components"
import { Typography, Button, Alert } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId, selectOrgInfo } from "../../redux/org/org.selectors"
import { updateOrgInfo } from "../../redux/org/org.actions"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import CustomiseYourApp from "../../components/getStarted/CustomiseYourApp"
import * as S from "../../components/styles/PageStyles"

const { Title, Text } = Typography

const PageOuter = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const PageInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	margin-bottom: 40px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const Divider = styled.div`
	padding: 0;
	margin: 0;
	margin-top: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid var(--light-grey);
`
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	align-items: center;
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
const CardContainer = styled.div`
	width: 240px;
	background: ${(props) => (props.active ? "white" : "rgb(249, 250, 251)")};
	border: ${(props) => (props.active ? "solid 1px var(--secondary-color)" : "solid 1px var(--light-grey)")};
	display: block;
	border-radius: 8px;
	padding: 30px;
	margin-right: 20px;
	cursor: pointer;
	:hover {
		border-color: var(--secondary-color);
	}
`
const CardContainerInner = styled.div`
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: center;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
`
const RowStart = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding-top: 20px;
`
const IconContainer = styled.span`
	font-size: 28px;
	padding-bottom: 6px;
	color: ${(props) => (props.active ? "var(--secondary-color)" : "grey")};
`
const SaveChangesContainer = styled.div`
	width: 500px;
	padding-top: 20px;
`

const OrgSettings = ({ orgId, orgInfo, updateOrgInfo }) => {
	const [accessPrivate, setAccessPrivate] = useState(orgInfo.private ? true : false)

	const changeAccessSettings = (access_private) => {
		if (access_private !== accessPrivate) {
			setAccessPrivate(access_private)
		}
	}

	const handleSaveChanges = async () => {
		const new_org_info = {
			...orgInfo, // copy old state first, then update fields
			private: accessPrivate
		}
		await updateOrgInfo(orgId, new_org_info)
	}

	let showSaveChanges = false
	if (orgInfo.private && orgInfo.private !== accessPrivate) {
		showSaveChanges = true
	} else if (!orgInfo.private && accessPrivate === true) {
		showSaveChanges = true
	}

	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton title={"Org Settings"} />
				<PageOuter>
					<PageInner>
						<Row style={{ marginBottom: 40 }}>
							<Column>
								<Title level={4}>Access settings</Title>
								<RowStart>
									<CardContainer active={!accessPrivate} onClick={() => changeAccessSettings(false)}>
										<CardContainerInner>
											<IconContainer active={!accessPrivate}>
												<FontAwesomeIcon icon={faLockOpen} />
											</IconContainer>
											<Text>Users do not have to enter an access code to register</Text>
										</CardContainerInner>
									</CardContainer>
									<CardContainer active={accessPrivate} onClick={() => changeAccessSettings(true)}>
										<CardContainerInner>
											<IconContainer active={accessPrivate}>
												<FontAwesomeIcon icon={faLock} />
											</IconContainer>
											<Text>Users must enter an access code to register</Text>
										</CardContainerInner>
									</CardContainer>
								</RowStart>
								{showSaveChanges && (
									<SaveChangesContainer>
										<Alert
											type="warning"
											message="This will immediately update the registration process for all users. Make sure you're ready to do this!"
										/>
										<Button
											type="primary"
											shape="round"
											size="large"
											style={{ marginTop: 10 }}
											onClick={() => handleSaveChanges()}
										>
											Save changes
										</Button>
									</SaveChangesContainer>
								)}
							</Column>
						</Row>
						<Divider />
						<CustomiseYourApp />
					</PageInner>
				</PageOuter>
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgInfo: selectOrgInfo
})

const mapDispatchToProps = (dispatch) => ({
	updateOrgInfo: (org_id, new_org_info) => dispatch(updateOrgInfo(org_id, new_org_info))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrgSettings)
