import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import * as S from "../../components/styles/PageStyles"
import { selectOrgId, selectSequenceList } from "../../redux/org/org.selectors"

import SequenceCard from "../../components/sequenceBuilder/sequences/SequenceCard"

const CourseListContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseCardContainer = styled.div`
	height: 160px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`

const Sequences = ({ orgId, sequenceList }) => {
	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton title="Sequences" buttonTitle="Create sequence" />
				{sequenceList.length === 0 ? (
					<CourseListContainer>
						<CourseListInner>
							<GeNoItemsFound
								icon={<FontAwesomeIcon icon={faGraduationCap} />}
								titleText="No sequences found"
								subtitleText="Press the button to start creating your first sequence!"
							/>
						</CourseListInner>
					</CourseListContainer>
				) : (
					<>
						<CourseListContainer>
							<CourseListInner>
								{sequenceList
									.filter((item) => item.isActive)
									.map((sequence, index) => (
										<CourseCardContainer key={index}>
											<SequenceCard sequence={sequence} orgId={orgId} />
										</CourseCardContainer>
									))}
							</CourseListInner>
						</CourseListContainer>
					</>
				)}
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	sequenceList: selectSequenceList
})

export default connect(mapStateToProps)(Sequences)
