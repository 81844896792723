import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import CbLoading from "../../../common/CbLoading"
import BaseBarChart from "../../common/BaseBarChart"
import { selectOrgCourseCards } from "../../../../redux/org/org.selectors"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../../pages/learningPaths/utils/LearningPathContent"
import { CourseCards } from "../../../../util/model/OrgSelectors"
import { UUID } from "learnink-common/lib/types/types"

interface ChartRow {
	x: string
	count: number
}

interface UserCoursesCompleted {
	userId: string
	coursesCompleted: UUID[]
}

interface Props {
	learningPath: LearningPath
	data: UserCoursesCompleted[]
	courseCards: CourseCards
	showTotalUsers?: boolean
}

const CourseCompletionChart = ({ learningPath, data, courseCards, showTotalUsers }: Props) => {
	const [chartData, setChartData] = useState<ChartRow[] | undefined>(undefined)

	useEffect(() => {
		const courseIds = new LearningPathContent(learningPath).getCourseIds()
		const chartDataRows = data.length > 0 && showTotalUsers ? [{ x: "Total users", count: data.length }] : []
		courseIds.forEach((element) => {
			chartDataRows.push({
				x: courseCards[element]?.info?.name || "",
				count: data.filter((item) => item.coursesCompleted.includes(element)).length
			})
		})
		setChartData(chartDataRows)
	}, [data, courseCards, learningPath])

	if (!chartData) {
		return <CbLoading />
	}

	return (
		<BaseBarChart
			data={chartData}
			dataKey="x"
			series={["count"]}
			title="User course completion"
			tooltip="tooltip"
			showYAxis
			height={180}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	courseCards: selectOrgCourseCards
})

// @ts-ignore
export default connect(mapStateToProps)(CourseCompletionChart)
