import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import { Select, Space } from "antd"
import useUrlQueryString from "../../hooks/useUrlQueryString"
import styled from "styled-components"

import { selectSequenceList } from "../../../redux/org/org.selectors"

const Container = styled.div`
	display: flex;
	align-items: center;
	margin-right: 10px;
`

const SequenceFilter = ({ sequenceList }) => {
	const sequenceOptions = []
	sequenceList.forEach((sequence) => {
		if (sequence.isActive) {
			sequenceOptions.push({
				value: sequence.id,
				label: sequence.internalName
			})
		}
	})

	const [sequenceId, setSequenceId] = useUrlQueryString("sequenceId")

	return (
		<Container>
			<Space
				direction="vertical"
				style={{
					width: "100%",
					marginRight: "10px"
				}}
			>
				<Select
					showArrow={true}
					showSearch={false}
					style={{ width: "100%" }}
					value={sequenceId}
					options={sequenceOptions}
					onChange={(value) => setSequenceId(value)}
					placeholder="Select sequence..."
					maxTagCount="responsive"
				/>
			</Space>
		</Container>
	)
}

SequenceFilter.propTypes = {
	sequenceList: PropTypes.array.isRequired
}

const mapStateToProps = createStructuredSelector({
	sequenceList: selectSequenceList
})

export default connect(mapStateToProps)(SequenceFilter)
