import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const Button = styled.button<{ emptyText?: boolean }>`
	display: flex;
	max-width: 240px;
	margin: 2px 4px;
	padding: 4px 8px;
	border: ${({ emptyText }) => (emptyText === true ? "1px solid var(--dark-grey)" : "1px solid var(--mid-orange)")};
	border-radius: 18px;
	background: ${({ emptyText }) => (emptyText === true ? "white" : "var(--mid-orange)")};
	transition: background 0.1s;
	cursor: pointer;
	:hover,
	focus {
		${({ emptyText }) =>
			emptyText === true ? "background: var(--secondary-color-pale)" : "background: var(--dark-yellow)"};
	}
`
const ButtonText = styled.p<{ emptyText?: boolean }>`
	margin: 0;
	color: ${({ emptyText }) => (emptyText === true ? "var(--light-grey)" : "white")};
	background: none;
	font-size: 12px;
	font-weight: ${({ emptyText }) => (emptyText === true ? "400" : "600")};
	overflow: visible;
	overflow-wrap: normal;
	white-space: normal;
	text-align: left;
	line-height: 18px;
`
const TextArea = styled.textarea`
	max-width: 240px;
	padding: 14px 18px;
	border: 2px solid var(--secondary-color);
	border-radius: 18px;
	background: white;
	color: black;
	overflow: visible;
	overflow-wrap: normal;
	white-space: normal;
	font-size: 12px;
	font-weight: 600;
	overflow: visible;
	overflow-wrap: normal;
	white-space: normal;
	line-height: 18px !important;
`
const ButtonCross = styled.a`
	height: 20px;
	width: 20px;
	background: none;
	margin-left: 4px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
`

interface Props {
	initialText: string
	index: number
	setButtonText: any
	deleteButtonPress: any
}

const EditableButton = ({ initialText, index, setButtonText, deleteButtonPress }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [text, setText] = useState("")

	useEffect(() => {
		setText(initialText)
	}, [initialText])

	const handleClick = () => setIsOpen(true)
	const handleChange = (event: any) => setText(event.target.value)
	const submitTitle = () => {
		setButtonText(text, index)
		setIsOpen(false)
	}
	const revertTitle = () => {
		setText(initialText)
		setIsOpen(false)
	}
	const handleKeyDown = (event: any) => {
		if (event.keyCode === 13) {
			submitTitle()
		} else if (event.keyCode === 27) {
			revertTitle()
		}
	}
	const handleFocus = (event: any) => event.target.select()
	const handleCrossClick = (event: any) => {
		event.stopPropagation()
		deleteButtonPress(index)
	}

	const placeholderText = "Enter text..."

	return isOpen ? (
		<TextArea
			autoFocus
			onKeyDown={handleKeyDown}
			onChange={handleChange}
			onBlur={submitTitle}
			onFocus={handleFocus}
			rows={1}
			value={text}
		/>
	) : (
		<React.Fragment>
			{text !== "" ? (
				<Button onClick={handleClick} emptyText={false}>
					<ButtonText emptyText={false}>{text}</ButtonText>
					<ButtonCross onClick={handleCrossClick}>
						<FontAwesomeIcon icon={faTimes as IconProp} color="white" />
					</ButtonCross>
				</Button>
			) : (
				<Button onClick={handleClick} emptyText={true}>
					<ButtonText emptyText={true}>{placeholderText}</ButtonText>
					<ButtonCross onClick={handleCrossClick}>
						<FontAwesomeIcon icon={faTimes as IconProp} color="var(--dark-grey)" />
					</ButtonCross>
				</Button>
			)}
		</React.Fragment>
	)
}

export default EditableButton
