import React, { useState } from "react"
import { Modal, Select } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import * as S from "../../styles/PageStyles"
import {
	LearningPath,
	LearningPathOnCompletion,
	LearningPathType
} from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faPause, faSign } from "@fortawesome/free-solid-svg-icons"
import { selectLearningPaths } from "../../../redux/org/org.selectors"
import { LearningPathById } from "../../../util/model/OrgSelectors"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

const { Option } = Select

interface Props {
	learningPath: LearningPath
	onCancel: () => void
	updateLearningPath: (learningPath: LearningPath) => void
	learningPaths: LearningPath[]
	role: UserGroup
}

interface OptionRow {
	value: string
	label: string
	color: string
	type: LearningPathType | undefined
}

const EditLearningPathOnCompletion = ({ learningPath, onCancel, updateLearningPath, learningPaths, role }: Props) => {
	const [onCompletion, setOnCompletion] = useState<LearningPathOnCompletion>(learningPath.onCompletion)
	const learningPathIds = role.learningPaths
		.map((item) => item.learningPathId)
		.filter((item) => item !== learningPath.id)
	const filteredLearningPaths = learningPaths.filter((item) => learningPathIds.includes(item.id) && !item.isArchived)

	const options: OptionRow[] = []
	filteredLearningPaths.forEach((learningPath) => {
		options.push({
			value: learningPath.id,
			label: learningPath.internalName,
			color: new LearningPathContent(learningPath).getColor(),
			type: learningPath.type
		})
	})

	return (
		<Modal
			okText="Save"
			onCancel={onCancel}
			title="Edit on completion"
			open={true}
			centered
			width={500}
			onOk={() => updateLearningPath({ ...learningPath, onCompletion: onCompletion })}
		>
			<S.FlexBoxStartRow>
				<BigOnOffButton
					key="MOVE_TO_LEARNING_PATH"
					isOn={!!onCompletion.nextLearningPathId}
					color="blue"
					text="Move to learning path"
					faIcon={faSign}
					onClick={() => setOnCompletion({ nextLearningPathId: options[0].value })}
					disabled={options.length === 0}
					tooltip="You haven't created any other learning paths for this role"
				/>
				<BigOnOffButton
					key="WAIT"
					isOn={!onCompletion.nextLearningPathId}
					color="blue"
					text="Stay in learning path"
					faIcon={faPause}
					onClick={() => setOnCompletion({})}
				/>
			</S.FlexBoxStartRow>
			{onCompletion.nextLearningPathId && (
				<S.FlexBoxStartRow style={{ paddingTop: 20 }}>
					<S.FormLabel>Learning path: </S.FormLabel>
					<Select
						onChange={(value) => setOnCompletion({ nextLearningPathId: value })}
						style={{ width: "50%" }}
						value={onCompletion.nextLearningPathId}
						placeholder="Select learning path..."
					>
						{options.map((option, index) => (
							<Option value={option.value} key={index}>
								<span
									className="color-selector"
									style={{
										fontSize: 13,
										color: "white",
										background: option.color,
										padding: "3px 10px 3px 10px"
									}}
								>
									{option.label}
								</span>
							</Option>
						))}
					</Select>
				</S.FlexBoxStartRow>
			)}
		</Modal>
	)
}
const mapStateToProps = createStructuredSelector({
	learningPaths: selectLearningPaths
})

// @ts-ignore
export default connect(mapStateToProps)(EditLearningPathOnCompletion)
