import React, { useEffect } from "react"
import { connect } from "react-redux"

import Loading from "../../components/elements/Loading"

import { getLessonContent } from "../../redux/learn/learn.actions"
import PreviewFrame from "./PreviewFrame"
import { FrontendCardArray } from "learnink-common/lib/domains/cards/legacy/FrontendCard.model"

export function LessonPreview({
	org,
	moduleStageId,
	courses: {
		stage: { teacherLearnerImgs }
	},
	learn: { lessonPreviewData },
	ui: { loading },
	getLessonContent
}) {
	useEffect(() => {
		getLessonContent(moduleStageId)
	}, [getLessonContent, moduleStageId])

	if (loading === true) return <Loading />

	if (lessonPreviewData && lessonPreviewData.length > 0 && org && org.orgData) {
		const languageCodes = new FrontendCardArray(lessonPreviewData).getAvailableLanguages()
		return (
			<PreviewFrame
				type="lesson"
				lessonContent={lessonPreviewData}
				quizContent={null}
				activeOrgData={org.orgData}
				teacherLearnerImgs={teacherLearnerImgs}
				languageCodes={languageCodes}
			/>
		)
	} else {
		return <Loading />
	}
}

const mapStateToProps = (state) => {
	return {
		learn: state.learn,
		ui: state.ui,
		org: state.org,
		courses: state.courses
	}
}

const mapActionsToProps = {
	getLessonContent: getLessonContent
}

export default connect(mapStateToProps, mapActionsToProps)(LessonPreview)
