import NotifyButton from "./NotifyButton"
import { handleCreateOrderableHeader as header } from "./Header"
import { Tag } from "antd"
import * as S from "../../components/common/infiniteScrollTable/Table.styles"
import { ISO_8601_string } from "learnink-common/lib/types/types"
import { MemberThatCanOrderResults as Orderable } from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import _ from "lodash"
import React from "react"
import {
	ColumnDefinition,
	__User,
	REGISTRATION_STATUS_COLOURS,
	ColumnLabel,
	UserGroupColours,
	UserGroupLabels,
	UserIdsSelectedProps,
	LearningPathById
} from "./UserSearch.model"
import { DATE_TIME, toDateFormat } from "../../util/formatters"

const DATE_WIDTH = 140

export type ColumnDefinitionParams = UserIdsSelectedProps & {
	userGroupLabels: UserGroupLabels
	userGroupColours: UserGroupColours
	learningPathById: LearningPathById
	handleSelectedUsers: HandleSelectedUsers
	openDrawer: (user: __User) => void
	openMessageModal: (user: __User) => void
}

interface HandleSelectedUsers {
	addAll: () => void
	removeAll: () => void
	addUserId: (userId: string) => void
	removeUserId: (userId: string) => void
}

export function getColumnDefinitionsByLabel(props: ColumnDefinitionParams): Record<ColumnLabel, ColumnDefinition> {
	return {
		Checkbox: {
			buildHeader: () => {
				return <AllUserCheckbox {...props} />
			},
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			buildRow: (user) => {
				return <SelectedUserCheckBox user={user} {...props} />
			}
		},
		Notify: {
			buildHeader: () => <></>,
			buildRow: (user) => {
				return <NotifyButton user={user} openMessageModal={props.openMessageModal} />
			},
			width: 100,
			minWidth: 100,
			maxWidth: 100
		},
		"Full name": {
			buildHeader: header(Orderable.enum.full_name),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.firstName?.trim() + " " + user.lastName?.trim()}
					</UserSettings>
				)
			}
		},
		Status: {
			buildHeader: header(Orderable.enum.registration_status),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<Tag color={REGISTRATION_STATUS_COLOURS[user.registrationStatus]}>
							{user.registrationStatus}
						</Tag>
					</UserSettings>
				)
			}
		},
		Referrer: {
			buildHeader: header(Orderable.enum.share_request_sender_ids),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<S.MultiTagDiv>
							{_.uniqBy(user.shareRequestSenders, (sender) => sender.id).map((sender, idx) => (
								<Tag key={idx}>{sender.name}</Tag>
							))}
						</S.MultiTagDiv>
					</UserSettings>
				)
			}
		},
		"User groups": {
			buildHeader: header(Orderable.enum.user_groups),
			width: 260,
			minWidth: 100,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{getUserGroupTags(user.userGroups, props.userGroupLabels, props.userGroupColours)}
					</UserSettings>
				)
			}
		},
		"Learning path": {
			buildHeader: header(Orderable.enum.learningpath_id),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{getLearningPathTag(user.learningPathId, props.learningPathById)}
					</UserSettings>
				)
			}
		},
		"First name": {
			buildHeader: header(Orderable.enum.first_name),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<span>{user.firstName?.trim()}</span>
					</UserSettings>
				)
			}
		},
		"Last name": {
			buildHeader: header(Orderable.enum.last_name),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<span>{user.lastName?.trim()}</span>
					</UserSettings>
				)
			}
		},
		"Last active": {
			buildHeader: header(Orderable.enum.last_active),
			width: DATE_WIDTH,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<Date date={user.lastActive} />
					</UserSettings>
				)
			}
		},
		"Custom ID": {
			buildHeader: header(Orderable.enum.custom_id),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.customId || ""}
					</UserSettings>
				)
			}
		},
		"User ID": {
			buildHeader: header(Orderable.enum.user_id),
			width: 240,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<span style={{ ...S.Monospace, ...S.SmallFontSize }}>{user.userId}</span>
					</UserSettings>
				)
			}
		},
		"Phone number": {
			buildHeader: header(Orderable.enum.phone_number),
			width: 160,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<span style={S.Monospace}>{user.phoneNumber}</span>
					</UserSettings>
				)
			}
		},
		Sex: {
			buildHeader: header(Orderable.enum.sex),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.sex || ""}
					</UserSettings>
				)
			}
		},
		Country: {
			buildHeader: header(Orderable.enum.country),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.country || ""}
					</UserSettings>
				)
			}
		},
		Region: {
			buildHeader: header(Orderable.enum.region),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.region || ""}
					</UserSettings>
				)
			}
		},
		Age: {
			buildHeader: header(Orderable.enum.age_bracket),
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<span style={S.Monospace}>{user.ageBracket || ""}</span>
					</UserSettings>
				)
			}
		},
		"Notifications enabled": {
			buildHeader: header(Orderable.enum.app_downloaded),
			width: 170,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						{user.appDownloaded ? "true" : "false"}
					</UserSettings>
				)
			}
		},
		Registered: {
			buildHeader: header(Orderable.enum.registered_at),
			width: DATE_WIDTH,
			buildRow: (user) => {
				return (
					<UserSettings user={user} openDrawer={props.openDrawer}>
						<Date date={user.registeredAt} />
					</UserSettings>
				)
			}
		}
	}
}

function getUserGroupTags(
	userGroups: string[] | undefined,
	userGroupLabels: UserGroupLabels,
	userGroupColours: UserGroupColours
) {
	if (!userGroups) return ""

	const getColour = (userGroup: string) =>
		userGroupColours[userGroup] ?? userGroupColours[userGroupLabels[userGroup]] ?? "var(--mid-orange)"

	return (
		<S.MultiTagDiv>
			{userGroups.map((userGroup, index) => (
				<Tag key={index} color={getColour(userGroup)}>
					{userGroupLabels[userGroup] ?? userGroup}
				</Tag>
			))}
		</S.MultiTagDiv>
	)
}

function getLearningPathTag(learningPathId: string | undefined, learningPathById: LearningPathById) {
	const learningPath = learningPathById[learningPathId ?? ""]
	if (!learningPath) return ""

	return <Tag color={learningPath?.color ?? "green"}>{learningPath?.internalName}</Tag>
}

function Date({ date }: { date: ISO_8601_string | undefined }) {
	return <span style={{ ...S.Monospace, ...S.SmallFontSize }}>{toDateFormat(date, DATE_TIME, "null")}</span>
}

function SelectedUserCheckBox({
	user,
	allUsersSelected,
	selectedUserIds,
	unselectedUserIds,
	handleSelectedUsers
}: ColumnDefinitionParams & { user: __User }) {
	const isSelected =
		(allUsersSelected || selectedUserIds.includes(user.userId)) && !unselectedUserIds.includes(user.userId)

	const h = handleSelectedUsers
	const toggleSelected = () => {
		isSelected ? h.removeUserId(user.userId) : h.addUserId(user.userId)
	}

	return (
		<S.FlexboxCenterAlignText>
			<S.Checkbox type={"checkbox"} checked={isSelected} onChange={toggleSelected} />
		</S.FlexboxCenterAlignText>
	)
}

function AllUserCheckbox({ allUsersSelected, handleSelectedUsers }: ColumnDefinitionParams) {
	const h = handleSelectedUsers
	const toggleSelected = allUsersSelected ? h.removeAll : h.addAll
	return (
		<S.FlexboxCenterAlignText>
			<S.Checkbox type={"checkbox"} checked={allUsersSelected} onChange={toggleSelected} />
		</S.FlexboxCenterAlignText>
	)
}

function UserSettings({
	user,
	openDrawer,
	children
}: {
	user: __User
	openDrawer: (user: __User) => void
	children: React.ReactNode
}) {
	return (
		<S.FlexboxLeftAlignText onClick={() => openDrawer(user)}>
			<span>{children}</span>
		</S.FlexboxLeftAlignText>
	)
}
