import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { LearningPathUserCount } from "../../../../../domains/userAnalytics/UserAnalytics.model"
import BasePieChart from "../../analyse/common/BasePieChart"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import ChartWrapper from "../../analyse/common/ChartWrapper"
import { ColumnsType } from "antd/lib/table"
import { Button, Space, Table, Tag } from "antd"
import { LineChartOutlined } from "@ant-design/icons"
import { ShortId } from "learnink-common/lib/tools/randomCharacterGenerators/ShortIdGenerator"
import { fetchAnalytics } from "../../analyse/fetchAnalytics"

interface Props {
	orgId: string
	userGroupId: string
	learningPaths: LearningPath[]
	refreshDataCount: number
}

interface DataItem {
	x: string
	id: ShortId
	count: number
	color: string
}

const getUserCountByLearningPath = (data: LearningPathUserCount[], learningPaths: LearningPath[]) => {
	const users: DataItem[] = [
		{
			x: "No learning path",
			id: "NONE",
			count:
				data.filter((item) => item.learningPathId === "NONE").length > 0
					? data.filter((item) => item.learningPathId === "NONE")[0].count
					: 0,
			color: "var(--light-grey)"
		}
	]
	learningPaths.forEach((learningPath) => {
		if (data.filter((item: LearningPathUserCount) => item.learningPathId === learningPath.id).length > 0) {
			users.push({
				x: learningPath.internalName,
				id: learningPath.id,
				count: data.filter((item: LearningPathUserCount) => item.learningPathId === learningPath.id)[0].count,
				color: new LearningPathContent(learningPath).getColor()
			})
		}
	})
	return users
}

const LearningPathUserCharts = ({ orgId, userGroupId, learningPaths, refreshDataCount }: Props) => {
	const [usersByLearningPath, setUsersByLearningPath] = useState<DataItem[]>([])
	const [numUsers, setNumUsers] = useState<number | undefined>(undefined)

	const getLearningPathUserCount = async () => {
		const data = await fetchAnalytics("userAnalytics", orgId, `learning-path/active/count`, "all time", userGroupId)
		const userCount = data
			.map((item: LearningPathUserCount) => item.count)
			.reduce((a: number, b: number) => a + b, 0)
		setNumUsers(userCount)
		if (userCount) {
			setUsersByLearningPath(getUserCountByLearningPath(data, learningPaths))
		}
	}

	const columns: ColumnsType<DataItem> = [
		{
			title: "Active learning path",
			dataIndex: "x",
			key: "x",
			render: (text, record) => <Tag color={record.color}>{text}</Tag>
		},
		{
			title: "Users",
			dataIndex: "count",
			key: "count"
		},
		{
			title: "",
			key: "action",
			render: (_, record) => (
				<Space>
					<Link to={`/org/${orgId}/analyse/learning-paths/progress?id=${record.id}`}>
						<Button icon={<LineChartOutlined />} size="small">
							Analyse
						</Button>
					</Link>
				</Space>
			)
		}
	]

	useEffect(() => {
		if (userGroupId) {
			getLearningPathUserCount()
		}
	}, [userGroupId, refreshDataCount])

	if (!numUsers) {
		return <></>
	}

	return (
		<div className="row">
			<div className="col-sm-4">
				<BasePieChart
					data={usersByLearningPath}
					title="Users by active path"
					tooltip="Total user count by learning path"
					height={214}
				/>
			</div>
			<div className="col-sm-8">
				<ChartWrapper>
					<Table
						size="small"
						columns={columns}
						dataSource={usersByLearningPath.filter((item) => item.x !== "No learning path")}
						pagination={{ pageSize: 4 }}
						style={{ marginBottom: -14, height: 260 }}
					/>
				</ChartWrapper>
			</div>
		</div>
	)
}

export default LearningPathUserCharts
