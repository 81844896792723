// CONTAINER COMPONENT FOR SIGN IN AND OUT PAGES

import React from "react"
import styled from "styled-components"

import LpBackButton from "../../components/common/LpBackButton"
import RightContainerClouds from "../../components/common/RightContainerClouds"

const BackContainer = styled.div`
	display: flex;
	width: 100%;
	padding-left: 48px;
	justify-content: flex-start;
	@media (max-width: 850px) {
		padding-left: 22px;
	}
`
const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	box-sizing: border-box;
	background: white;
`
const LeftContainer = styled.div`
	flex: 1 1 50%;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
	@media (max-width: 850px) {
		padding-top: 22px;
		padding-bottom: 22px;
	}
`
const FormContainer = styled.div`
	display: flex;
	height: 100%;
	width: 90%;
	margin: 0 auto;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 100px;
	@media (max-width: 850px) {
		padding: 40px;
	}
`

const AuthContainer = ({ children, sentEmail }) => {
	return (
		<PageContainer>
			<LeftContainer>
				<BackContainer>
					<LpBackButton backlink={`/`} />
				</BackContainer>
				<FormContainer>{children}</FormContainer>
			</LeftContainer>
			<RightContainerClouds sentEmail={sentEmail} />
		</PageContainer>
	)
}

export default AuthContainer
