import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookOpen } from "@fortawesome/free-solid-svg-icons"

const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 120px;
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: ${({ hasBorder }) => (hasBorder ? "1px" : "0px")};
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 62px;
	line-height: 62px;
	padding: 0px 20px 10px 20px;
`
const TitleText = styled.h2`
	color: black;
	font-size: 20px;
	line-height: 24px;
	padding: 0px 20px 5px 20px;
`
const SubtitleText = styled.p`
	color: var(--dark-grey);
	font-size: 14px;
	line-height: 20px;
	padding: 0px 20px 0px 20px;
`
const Card = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin: ${({ cardMargin }) => (cardMargin ? cardMargin : "0px")};
`
const CardBody = styled.div`
	flex: 1 1 auto;
	min-height: 1px;
	padding: 14px;
	margin-bottom: ${({ cardBodyBottomMargin }) => (cardBodyBottomMargin ? cardBodyBottomMargin : "50px")};
`

const AnSelectItems = ({ icon, titleText, subtitleText, hasBorder, children, cardMargin, cardBodyBottomMargin }) => {
	return (
		<Card cardMargin={cardMargin}>
			<CardBody cardBodyBottomMargin={cardBodyBottomMargin}>
				<CourseListInner>
					<CourseListEmpty hasBorder={hasBorder}>
						<IconText>{icon}</IconText>
						<TitleText>{titleText}</TitleText>
						<SubtitleText>{subtitleText}</SubtitleText>
						{children}
					</CourseListEmpty>
				</CourseListInner>
			</CardBody>
		</Card>
	)
}

AnSelectItems.propTypes = {
	icon: PropTypes.object,
	titleText: PropTypes.string,
	subtitleText: PropTypes.string,
	hasBorder: PropTypes.bool,
	cardMargin: PropTypes.string,
	cardBodyBottomMargin: PropTypes.string,
	children: PropTypes.node
}

AnSelectItems.defaultProps = {
	icon: <FontAwesomeIcon icon={faBookOpen} />,
	titleText: "No data",
	subtitleText: "",
	hasBorder: true,
	children: null,
	cardBodyBottomMargin: "50px",
	cardMargin: "0px"
}

export default AnSelectItems
