import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"

import { selectOrgId } from "../../redux/org/org.selectors"
import TooltipIcon from "./TooltipIcon"

const ImageRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
const ImageText = styled.div`
	padding-left: 0px;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	justify-content: center;
`
const FormLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	margin-top: 0px;
	font-size: ${({ labelFontSize }) => (labelFontSize ? labelFontSize : "18px !important")};
	font-weight: 500;
	line-height: 1.3;
`
const UploadImageText = styled.div`
	display: ${({ uploadImageMessage }) => (uploadImageMessage === "" ? "none" : "block")};
	width: 100%;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 16px;
	@media (max-width: 590px) {
		font-size: 12px;
		line-height: 16px;
	}
`
const UploadImageButton = styled.button`
	font-size: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "11px" : "13px")};
	font-weight: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "500" : "600")};
	line-height: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "10px" : "30px")};
	height: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "18px" : "34px")};
	border-radius: 20px;
	padding: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "0px 3px 0px 3px" : "0px 15px 0px 15px")};
	max-width: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "fit-content" : "140px")};
	border: ${({ disabled, uploadButtonAsText }) =>
		disabled ? "solid 1px #E5E5E5" : uploadButtonAsText ? "none" : "solid 1px var(--secondary-color)"};
	background-color: ${({ disabled }) => (disabled ? "#E5E5E5" : "none")};
	color: ${({ disabled }) => (disabled ? "#B2B2B2" : "var(--secondary-color)")};
	text-transform: ${({ uploadButtonAsText }) => (uploadButtonAsText ? "none" : "uppercase")};
	:hover {
		background-color: var(--secondary-color);
		border: solid 1px var(--secondary-color);
		color: white;
	}
	:active {
		background-color: var(--secondary-color);
		border: solid 1px var(--secondary-color);
		color: white;
	}
`

const ImageContainer = styled.div`
	width: ${({ thumbPreviewShape, thumbSize }) =>
		thumbPreviewShape === "largeRectangle" ? "134px" : thumbSize ? thumbSize : "90px"};
	height: ${({ thumbPreviewShape, thumbSize }) =>
		thumbPreviewShape === "largeRectangle" ? "122px" : thumbSize ? thumbSize : "90px"};
	background: ${({ imageUrlOnComponent }) =>
		imageUrlOnComponent !== "" ? `url(${imageUrlOnComponent}) no-repeat` : "var(--v-light-grey)"};
	background-size: cover;
	border-radius: ${({ thumbPreviewShape }) => (thumbPreviewShape === "round" ? "99%" : "15px")};
	font-size: 30px;
	color: var(--mid-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	border: 1px solid var(--v-light-grey);
`
const ImageAltButtonContainer = styled.button`
	display: flex;
	flex-direction: column;
`

const LpImageInput = ({
	id,
	initialImageUrl,
	labelText,
	uploadImageMessage,
	thumbPreviewShape,
	thumbSize,
	uploadButtonAsText,
	setNewImage,
	buttonText,
	clickableImage,
	disabled,
	labelFontSize,
	orgId,
	tooltip
}) => {
	// For image initially passed via initialImageUrl
	const [imageUrlOnComponent, setImageUrlOnComponent] = useState(initialImageUrl)

	// to set image thumbnail based on local storage we need to store the created object Url
	const [localImage, setLocalImage] = useState(null)

	useEffect(() => {
		if (localImage) {
			// when we get a new local image trigger loading gif
			setImageUrlOnComponent(
				"https://farminkweb.s3.eu-west-2.amazonaws.com/f92aeaf9-1e24-4fc5-9b05-b3c350802dca.gif"
			)

			// send image data to API for S3 image creation
			// must send
			// should return image_url string
			const formData = new FormData()

			// can add image quality params here...
			formData.set("image_quality", "80")
			formData.set("image_width", "400")
			formData.set("orgId", orgId)
			formData.set("folderName", "images")

			// add image here
			formData.append("image", localImage.file.image, localImage.file.image.name)
			axios.post("/api/data/image", formData).then((res) => {
				if (res.data.image_url) {
					setImageUrlOnComponent(res.data.image_url) // set image on local comonent
					setNewImage(res.data.image_url) // we pass the S3 Url back to the parent component
				}
			})
		}
		// eslint-disable-next-line
	}, [localImage])

	// when the user clicks on the input
	const handleEditPicture = (e) => {
		e.preventDefault()
		const fileInput = document.getElementById(`imageInput-${id}`)
		fileInput.click()
	}
	// used to handle image, stored in state and then rendered as thumb
	const handleImageChange = (e) => {
		if (e.target.files[0] !== undefined) {
			const image = e.target.files[0] // for ONE image
			const src = window.URL.createObjectURL(image)
			setLocalImage({
				file: {
					image, // we want to send this bit to API via form
					src // this is how we can showcase the thumb
				}
			})
		}
	}

	return (
		<React.Fragment>
			<ImageRow>
				<input
					type="file"
					accept="image/*"
					name="img-loader-input"
					id={`imageInput-${id}`}
					hidden="hidden"
					onChange={handleImageChange}
				/>

				{labelText || uploadImageMessage ? (
					<ImageText>
						{labelText && (
							<FormLabel labelFontSize={labelFontSize}>
								{labelText} {tooltip && <TooltipIcon tooltip={tooltip} />}
							</FormLabel>
						)}
						<UploadImageText uploadImageMessage={uploadImageMessage}>{uploadImageMessage}</UploadImageText>
						<UploadImageButton
							onClick={handleEditPicture}
							disabled={disabled}
							uploadButtonAsText={uploadButtonAsText}
						>
							{buttonText}
						</UploadImageButton>
					</ImageText>
				) : null}

				{(!clickableImage || disabled) && (
					<ImageContainer
						imageUrlOnComponent={imageUrlOnComponent}
						thumbPreviewShape={thumbPreviewShape}
						thumbSize={thumbSize}
					>
						{imageUrlOnComponent !== "" ? null : <FontAwesomeIcon icon={faImage} />}
					</ImageContainer>
				)}

				{clickableImage && !disabled && (
					<ImageAltButtonContainer onClick={handleEditPicture}>
						<ImageContainer
							imageUrlOnComponent={imageUrlOnComponent}
							thumbPreviewShape={thumbPreviewShape}
							thumbSize={thumbSize}
						>
							{imageUrlOnComponent !== "" ? null : <FontAwesomeIcon icon={faImage} />}
						</ImageContainer>
					</ImageAltButtonContainer>
				)}
			</ImageRow>
		</React.Fragment>
	)
}

LpImageInput.propTypes = {
	id: PropTypes.string.isRequired,
	setNewImage: PropTypes.func.isRequired,
	initialImageUrl: PropTypes.string,
	labelText: PropTypes.string,
	buttonText: PropTypes.string,
	thumbSize: PropTypes.string,
	uploadImageMessage: PropTypes.string,
	uploadButtonAsText: PropTypes.bool,
	thumbPreviewShape: PropTypes.string,
	clickableImage: PropTypes.bool,
	disabled: PropTypes.bool
}

LpImageInput.defaultProps = {
	initialImageUrl: "",
	labelText: "",
	clickableImage: false,
	disabled: false,
	thumbSize: "90px",
	buttonText: "Upload",
	thumbPreviewShape: "square",
	uploadButtonAsText: false,
	uploadImageMessage: "Upload an image",
	setNewImage: () => console.log("FYI: you haven't added a setNewImage function on your parent component")
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(LpImageInput)
