import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import CbLoading from "../../components/common/CbLoading"

import * as S from "../../components/styles/PageStyles"
import {
	setOrgUserGroupsInfo,
	clearOrgUserGroupsInfo,
	createNewUserGroup
} from "../../redux/management/management.actions"
import { selectOrgUserGroupsInfo } from "../../redux/management/management.selectors"
import { selectOrgId, selectPremiumFeatureAccess, selectUserGroups } from "../../redux/org/org.selectors"
import UserGroupsTable, { UserGroupInfo } from "../../components/management/userGroups/UserGroupsTable"
import { ModalState } from "../../util/model/PageStates"
import CreateUserGroup from "../../components/management/userGroups/CreateUserGroup"
import InfoBanner from "../../components/common/InfoBanner"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { CreateOrUpdateParams } from "learnink-common/lib/domains/userGroups/UserGroupParams.model"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import { UserOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { PremiumFeatureAccess } from "../../redux/org/org.model"

interface Props {
	orgId: string
	userGroups: UserGroup[]
	orgUserGroupsInfo: UserGroupInfo[]
	setOrgUserGroupsInfo: (orgId: string, userGroups: UserGroup[]) => void
	clearOrgUserGroupsInfo: () => void
	createNewUserGroup: (orgId: string, createParams: CreateOrUpdateParams) => void
	premiumFeatureAccess: PremiumFeatureAccess
}

export interface FormValues {
	name: string
	description: string
	type: "role" | "tag"
}

const UserGroups = ({
	orgId,
	userGroups,
	orgUserGroupsInfo,
	setOrgUserGroupsInfo,
	clearOrgUserGroupsInfo,
	createNewUserGroup,
	premiumFeatureAccess
}: Props) => {
	const [modalState, setModalState] = useState<ModalState>("confirm")
	const [modalType, setModalType] = useState<"create" | "createRole" | "none">("none")

	useEffect(() => {
		setOrgUserGroupsInfo(orgId, userGroups)
		return () => {
			clearOrgUserGroupsInfo()
		}
	}, [userGroups, setOrgUserGroupsInfo, clearOrgUserGroupsInfo, orgId])

	const handleSubmit = async (values: FormValues) => {
		try {
			setModalState("loading")
			const { name, description, type } = values
			const createParams: CreateOrUpdateParams = {
				name: name.trim(),
				description: description,
				type: type || "tag",
				orgId: orgId
			}
			createNewUserGroup(orgId, createParams)
			setModalState("confirm")
			setModalType("none")
		} catch (err) {
			setModalState("error")
		}
	}

	if (!orgUserGroupsInfo) return <CbLoading fullpage={true} />

	return (
		<>
			<S.Container>
				<DashboardHeaderButton
					title="User groups"
					buttonTitle="Create user group"
					onClick={() => setModalType("create")}
				/>
				<S.MainContainer>
					{premiumFeatureAccess.learningPaths === "unlocked" && (
						<InfoBanner
							id="what_are_user_groups"
							videoId="what_are_user_groups"
							title="What are roles and tags?"
							text="Roles are tags are ways for you to label your users. Your user can be assigned multiple tags but can only be in one role. Press the play button for a short video tour"
						/>
					)}
					{userGroups.filter((item) => item.type === "role").length === 0 && (
						<div style={{ marginBottom: 40 }}>
							<GeNoItemsFound
								icon={<UserOutlined style={{ fontSize: 40 }} />}
								titleText="No roles found"
								subtitleText="You need to create at least one role in your organisation"
							>
								<Space direction="vertical" align="center" style={{ marginTop: 20 }}>
									<Button
										type="primary"
										shape="round"
										size="large"
										onClick={() => setModalType("createRole")}
									>
										Create a role
									</Button>
								</Space>
							</GeNoItemsFound>
						</div>
					)}
					<UserGroupsTable />
				</S.MainContainer>
			</S.Container>
			{modalType === "create" && (
				<CreateUserGroup
					handleSubmit={handleSubmit}
					modalState={modalState}
					closeModal={() => {
						setModalState("confirm")
						setModalType("none")
					}}
				/>
			)}
			{modalType === "createRole" && (
				<CreateUserGroup
					handleSubmit={handleSubmit}
					modalState={modalState}
					closeModal={() => {
						setModalState("confirm")
						setModalType("none")
					}}
					type="role"
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userGroups: selectUserGroups,
	orgUserGroupsInfo: selectOrgUserGroupsInfo,
	premiumFeatureAccess: selectPremiumFeatureAccess
})

const mapDispatchToProps = (dispatch: any) => ({
	setOrgUserGroupsInfo: (orgId: string, userGroups: UserGroup[]) => dispatch(setOrgUserGroupsInfo(orgId, userGroups)),
	clearOrgUserGroupsInfo: () => dispatch(clearOrgUserGroupsInfo()),
	createNewUserGroup: (orgId: string, createParams: CreateOrUpdateParams) =>
		dispatch(createNewUserGroup(orgId, createParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups)
