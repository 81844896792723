import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import PropTypes from "prop-types"

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
`
const ModalContainer = styled.div`
	z-index: 100;
	min-width: 490px;
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "800px")};
	border-radius: 8px;
	background: white;
	position: relative;
`
const ModalInnerContainer = styled.div`
	flex: 1;
	min-height: 100px;
`
const CloseButton = styled.button`
	font-size: 22px;
	font-weight: 600;
	color: black;
	width: 30px;
	height: 30px;
	background: white;
	border-radius: 99%;
	position: absolute;
	right: 20px;
	top: 20px;
	border: 1px solid var(--light-grey);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
`

const LpModal = ({ isShowingModal, hideModal, children, maxWidth }) => {
	const node = useRef()

	// Effect to handle action based on whether user clicks inside modal or not
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (e && e.target && e.target.id && e.target.id === "modal-overlay") {
				hideModal()
			}
		}
		// when open add the event listener
		if (isShowingModal) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			// clear listener when modal is closed
			document.removeEventListener("mousedown", handleClickOutside)
		}
		// clear listener when component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isShowingModal, hideModal])

	// inspired by https://upmostly.com/tutorials/modal-components-react-custom-hooks
	return (
		<>
			{isShowingModal
				? ReactDOM.createPortal(
						<ModalWrapper id="modal-overlay" aria-modal aria-hidden tabIndex={-1} role="dialog">
							<ModalContainer ref={node} maxWidth={maxWidth}>
								<CloseButton onClick={hideModal} type="button" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</CloseButton>

								<ModalInnerContainer>{children}</ModalInnerContainer>
							</ModalContainer>
						</ModalWrapper>,
						document.body
				  )
				: null}
		</>
	)
}

LpModal.propTypes = {
	isShowingModal: PropTypes.bool.isRequired,
	hideModal: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	maxWidth: PropTypes.string
}

export default LpModal
