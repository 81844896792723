import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Typography } from "antd"

import * as S from "../../styles/PageStyles"

const { Text } = Typography

const Divider = styled.div`
	height: 32px;
	width: 100%;
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
`
const UploadedFileSuccess = styled.div`
	color: var(--secondary-color);
	padding: 6px;
	font-size: 20px;
	font-weight: 500;
`

const UploadUserGroupsCsv = ({ setImportStage, localCsvFile, setLocalCsvFile }) => {
	// when the user clicks on the Add file button
	const handleUploadCsv = (e) => {
		e.preventDefault()
		const fileInput = document.getElementById(`csv-loader-input`)
		fileInput.click()
	}

	// used to handle csv file locally
	const handleCsvChange = (e) => {
		if (e.target.files[0] !== undefined) {
			const csv = e.target.files[0]
			setLocalCsvFile({
				file: {
					csv // we want to send this bit to API via form
				}
			})
		}
	}

	return (
		<React.Fragment>
			<S.FormContainer>
				<S.FormTitle>Upload your user groups</S.FormTitle>

				<input type="file" accept=".csv" id="csv-loader-input" hidden="hidden" onChange={handleCsvChange} />

				{localCsvFile && localCsvFile.file && localCsvFile.file.csv ? (
					<div>
						<UploadedFileSuccess>
							<FontAwesomeIcon icon={faCheckCircle} /> File uploaded
						</UploadedFileSuccess>

						<Text>
							Imported file <span style={{ fontWeight: 800 }}>{localCsvFile.file.csv.name}</span> (
							{Math.round(localCsvFile.file.csv.size / 1000)} KB)
						</Text>
						<Divider />
						<Button type="primary" shape="round" size="large" onClick={() => setImportStage("review")}>
							Next
						</Button>
					</div>
				) : (
					<div>
						<Text>Press the button below to import user groups from a .csv file</Text>
						<Divider />
						<Button type="primary" shape="round" size="large" onClick={handleUploadCsv}>
							Upload from file
						</Button>
					</div>
				)}
			</S.FormContainer>
		</React.Fragment>
	)
}

UploadUserGroupsCsv.propTypes = {
	setImportStage: PropTypes.func.isRequired,
	setLocalCsvFile: PropTypes.func.isRequired,
	localCsvFile: PropTypes.object
}

export default UploadUserGroupsCsv
