import { UserActionTypes } from "./user.types"

const INITIAL_STATE = {
	firebaseAuthInfo: null,
	userInfo: null,
	userSessionLoaded: false,
	userSessionLoadError: null
}

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UserActionTypes.SET_USER_FIREBASE_AUTH:
			return {
				...state,
				firebaseAuthInfo: action.payload
			}
		case UserActionTypes.SET_USER_INFO:
			return {
				...state,
				userInfo: action.payload
			}
		case UserActionTypes.SET_BASIC_USER_INFO:
			return {
				...state,
				userInfo: {
					...state.userInfo,
					info: action.payload
				}
			}
		case UserActionTypes.SET_USER_SESSION_LOADING:
			return {
				...state,
				userSessionLoaded: false
			}
		case UserActionTypes.SET_USER_SESSION_READY:
			return {
				...state,
				userSessionLoaded: true
			}
		case UserActionTypes.SET_USER_SESSION_ERROR:
			return {
				...state,
				userSessionLoadError: action.payload
			}
		case UserActionTypes.CLEAR_USER_SESSION_ERROR:
			return {
				...state,
				userSessionLoadError: null
			}
		case UserActionTypes.SET_NEW_ORG_INFO:
			let newUserOrgRoles = Object.assign({}, state.userInfo.userOrgRoles)
			newUserOrgRoles[action.payload.org_id] = { ...action.payload.new_role_object }
			return {
				...state,
				userInfo: {
					...state.userInfo,
					userOrgRoles: newUserOrgRoles
				}
			}
		default:
			return state
	}
}

export default userReducer
