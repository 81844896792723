import React from "react"
import { UpdatedSequences } from "../../../pages/learningPaths/utils/LearningPathContent"
import { Button, Modal, Result, Typography } from "antd"
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import { ModalState } from "../../../util/model/PageStates"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
const { Text, Paragraph } = Typography

interface Props {
	closeModal: () => void
	modalState: ModalState
	setModalState: React.Dispatch<React.SetStateAction<ModalState>>
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	handlePublish: () => void
	updatedSequences: UpdatedSequences
	learningPath: LearningPath
	originalLearningPath: LearningPath | undefined
}

type AnyObject = { [key: string]: any }

function findDifferences(obj1: AnyObject, obj2: AnyObject, prefix: string = ""): string[] {
	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)

	const allKeys = new Set([...keys1, ...keys2])
	const differences: string[] = []

	for (const key of allKeys) {
		const fullPath = prefix ? `${prefix}.${key}` : key

		if (
			typeof obj1[key] === "object" &&
			obj1[key] !== null &&
			typeof obj2[key] === "object" &&
			obj2[key] !== null
		) {
			differences.push(...findDifferences(obj1[key], obj2[key], fullPath))
		} else if (obj1[key] !== obj2[key]) {
			differences.push(fullPath)
		}
	}

	return differences
}

const PublishLearningPath = ({
	closeModal,
	modalState,
	setModalState,
	setModalType,
	handlePublish,
	updatedSequences,
	learningPath,
	originalLearningPath
}: Props) => {
	const createdSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "added")
	const updatedSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "updated")
	const deletedSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "deleted")
	const learningPathFieldsUpdated: string[] = findDifferences(
		{ ...originalLearningPath, sequences: [] },
		{ ...learningPath, sequences: [] }
	)

	const changes = (
		<div className="desc">
			<Paragraph>
				<Text
					strong
					style={{
						fontSize: 16
					}}
				>
					Changes to be saved:
				</Text>
			</Paragraph>
			{learningPathFieldsUpdated.length > 0 && (
				<Paragraph>
					<PlusCircleOutlined className="site-result-demo-error-icon" /> Updated learning path settings
				</Paragraph>
			)}
			{createdSequenceIds.length > 0 && (
				<Paragraph>
					<PlusCircleOutlined className="site-result-demo-error-icon" /> {createdSequenceIds.length} sequences
					added (
					{learningPath.sequences
						.filter((item) => createdSequenceIds.includes(item.id))
						.map((sequence: Sequence) => sequence.internalName)
						.join(", ")}
					)
				</Paragraph>
			)}
			{updatedSequenceIds.length > 0 && (
				<Paragraph>
					<PlusCircleOutlined className="site-result-demo-error-icon" /> {updatedSequenceIds.length} sequences
					updated (
					{learningPath.sequences
						.filter((item) => updatedSequenceIds.includes(item.id))
						.map((sequence: Sequence) => sequence.internalName)
						.join(", ")}
					)
				</Paragraph>
			)}
			{deletedSequenceIds.length > 0 && (
				<Paragraph>
					<CloseCircleOutlined className="site-result-demo-error-icon" /> {deletedSequenceIds.length}{" "}
					sequences deleted (
					{learningPath.sequences
						.filter((item) => deletedSequenceIds.includes(item.id))
						.map((sequence: Sequence) => sequence.internalName)
						.join(", ")}
					)
				</Paragraph>
			)}
		</div>
	)

	return (
		<Modal footer={null} open={true} centered onCancel={closeModal}>
			{modalState === "confirm" && (
				<Result
					title="Are you sure you're ready to save?"
					subTitle="Your changes will automatically be live once you press the save button"
					extra={[
						<Button onClick={handlePublish} shape="round" type="primary">
							Save
						</Button>,
						<Button onClick={closeModal} shape="round">
							Cancel
						</Button>
					]}
				>
					{changes}
				</Result>
			)}
			{modalState === "complete" && (
				<Result
					status="success"
					title="Your learning path has been updated"
					subTitle="Press below to share your learning path with your users"
					extra={[
						<Button
							type="primary"
							key="share"
							shape="round"
							size="large"
							onClick={() => {
								setModalState("confirm")
								setModalType("share")
							}}
						>
							Share learning path
						</Button>
					]}
				/>
			)}
			{modalState === "error" && (
				<Result
					status="error"
					title="Update failed"
					subTitle="Sorry we weren't able to save your learning path, please check your connection and try again"
				/>
			)}
		</Modal>
	)
}

export default PublishLearningPath
