import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { UserOutlined } from "@ant-design/icons"

import { selectOrgUserGroups } from "../../../redux/org/org.selectors"

import UserTableWrapper from "./UserTableWrapper"
import GeNoItemsFound from "../../common/GeNoItemsFound"

const UserTableQuery = ({
	orgId,
	registeredOn,
	unregisteredOn,
	archivedOn,
	filters,
	userList,
	removeUsersFromList,
	columns,
	actions,
	notifyButtonOn,
	hiddenColumns,
	refreshData,
	sidePanel
}) => {
	if (userList.length === 0 && !archivedOn) {
		return (
			<GeNoItemsFound
				icon={<UserOutlined style={{ fontSize: 40 }} />}
				titleText={"No users found"}
				subtitleText={""}
				children={
					<div style={{ marginTop: "14px" }}>
						<Link to={`/org/${orgId}/manage/import-users`}>
							<Button type="primary" size="large" shape="round">
								Import users
							</Button>
						</Link>
					</div>
				}
			/>
		)
	} else {
		// now filter user list data by table filters
		let data = userList
		if (!archivedOn) {
			data = data.filter((x) => x["registrationStatus"] !== "archived")
		}
		if (!registeredOn) {
			data = data.filter((x) => x["registrationStatus"] !== "registered")
		}
		if (!unregisteredOn) {
			data = data.filter((x) => x["registrationStatus"] !== "unregistered")
		}
		for (var i = 0; i < filters.length; i++) {
			const { member, operator, values } = filters[i]
			if (operator === "includes") {
				data = data
					.filter((x) => x[member] !== null)
					.filter((x) => values.filter((value) => x[member].includes(value)).length > 0)
			} else if (operator === "notIncludes") {
				data = data
					.filter((x) => x[member] !== null)
					.filter((x) => values.filter((value) => x[member].includes(value)).length === 0)
			} else if (operator === "equals") {
				data = data.filter((x) => x[member] === values[0])
			} else if (operator === "notEquals") {
				data = data.filter((x) => x[member] !== values[0])
			} else if (operator === "lt") {
				data = data.filter((x) => x[member] < values[0])
			} else if (operator === "gt") {
				data = data.filter((x) => x[member] > values[0])
			}
		}

		return (
			<UserTableWrapper
				data={data}
				archivedView={archivedOn}
				removeUsersFromList={removeUsersFromList}
				columns={columns}
				actions={actions}
				notifyButtonOn={notifyButtonOn}
				hiddenColumns={hiddenColumns}
				refreshData={refreshData}
				sidePanel={sidePanel}
			/>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(UserTableQuery)
