// DUPLICATE COURSE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography, Alert } from "antd"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"
import LpTextInput from "../../../common/LpTextInput"
import { selectOrgId } from "../../../../redux/org/org.selectors"
import { duplicateCourseWithId } from "../../../../redux/courses/courses.actions"

const { Text } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const DuplicateCourse = ({ closemodal, courseName, courseId, orgId, duplicateCourseWithId, triggerRedirect }) => {
	const [loading, setLoading] = useState(false)
	const [duplicateCourseName, setDuplicateCourseName] = useState(`${courseName} COPY`)
	const [errorMsg, setErrorMsg] = useState(null)

	const handleDuplicateAction = () => {
		if (!duplicateCourseName) {
			setErrorMsg("You can't have an empty course name!")
		} else {
			setLoading(true)
			// dummmy loading below...

			// handle update here
			duplicateCourseWithId(orgId, courseId, duplicateCourseName)

			setTimeout(() => {
				setLoading(false)
				if (triggerRedirect) {
					triggerRedirect()
				} else {
					closemodal()
				}
			}, 8000)
		}
	}

	const handleNameChange = (text) => {
		setDuplicateCourseName(text)
	}

	return (
		<ContentContainer>
			<GeTitle>Duplicate course</GeTitle>
			{loading ? (
				<>
					<CbLoading loadingText="Duplicating..." padding="30px" />
					Please wait a moment while we duplicate your course...
				</>
			) : (
				<>
					<Text>
						Confirm that you want to create a duplicate course by creating a title (we have suggested one
						below)
					</Text>

					<Divider />

					<LpTextInput
						labelText="New course title"
						initialText={`${courseName} COPY`}
						handleTextChange={handleNameChange}
						labelFontSize="16px"
					/>

					<Divider />

					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button
							type="primary"
							size="large"
							shape="round"
							onClick={handleDuplicateAction}
							disabled={!duplicateCourseName}
						>
							Duplicate course
						</Button>
					</ButtonsContainer>

					{errorMsg ? <Alert message={errorMsg} type="error" /> : null}
				</>
			)}
		</ContentContainer>
	)
}

PropTypes.DuplicateCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseName: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	duplicateCourseWithId: PropTypes.func.isRequired,
	triggerRedirect: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	duplicateCourseWithId: (org_id, course_id, new_course_title) =>
		dispatch(duplicateCourseWithId(org_id, course_id, new_course_title))
})

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateCourse)
