import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Tag, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import * as S from "../../styles/PageStyles"

const { Title } = Typography

const CourseContentContainer = styled.div`
	width: 100%;
	height: 105px;
	border-bottom: solid 1px var(--v-light-grey);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
const CourseContentTextContainer = styled.div`
	flex: 1;
	height: 110px;
	width: 200px;
	padding: 6px;
	padding-top: 18px;
	padding-right: 30px;
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
const CourseCardFooter = styled.div`
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	padding-right: 6px;
`
const HeaderMenuButton = styled.button`
	background: none;
	margin: 10px 15px;
	font-size: 22px;
	color: var(--light-grey);
`
const CourseCardContainer = styled.div`
	height: 160px;
	width: 100%;
	background: white;
	display: block;
	box-shadow: 1px 2px 2px var(--light-grey);
	border: solid 1px var(--light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
	position: relative;
`
const CardOverlayButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99%;
	width: 70px;
	height: 70px;
	text-transform: uppercase;
	background-color: var(--secondary-color);
	color: white;
	font-size: 24px;
	font-weight: 600;
	overflow: hidden;
	cursor: pointer;
	text-overflow: ellipsis;
	transition: 0.5s ease;
	:hover {
		background-color: var(--secondary-color-highlight);
	}
`
const CardOverlayButtonsContainer = styled.div`
	flex: 1;
	display: flex;
	opacity: 0;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 48px;
	padding-left: 16px;
	padding-right: 16px;
`
const CardOverlayContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	transition: 0.5s ease;
	background-color: rgba(0, 0, 0, 0);
	:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
	:hover ${CardOverlayButtonsContainer} {
		opacity: 1;
	}
	:hover ${HeaderMenuButton} {
		color: white;
	}
`
const SequenceStatus = styled.div<{ isActive: boolean }>`
	width: auto;
	font-size: 13px;
	border-radius: 5px;
	line-height: 30px;
	padding: 0px 6px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	border: ${({ isActive }) => (isActive ? "solid 1px var(--dark-green)" : "solid 1px var(--mid-grey)")};
	color: ${({ isActive }) => (isActive ? "var(--dark-green)" : "var(--mid-grey)")};
`
const SequenceImageContainer = styled.div`
	width: 100px;
	height: 130px;
	display: flex;
	padding-top: 20px;
	padding-left: 14px;
`
const SequenceImageBorder = styled.div`
	width: 84px;
	height: 84px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: var(--dark-blue);
	border-radius: 9999px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
`
const SequenceImage = styled.img`
	width: 80%;
	height: 80%;
	border-radius: 9999px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
`

interface Props {
	orgId: string
	sequence: Sequence
}

const SequenceCard = ({ orgId, sequence }: Props) => {
	return (
		<CourseCardContainer>
			<CardOverlayContainer>
				<CardOverlayButtonsContainer>
					<Link to={`/org/${orgId}/analyse/sequences/sequence?sequenceId=${sequence.id}`}>
						<CardOverlayButton>
							<FontAwesomeIcon icon={faChartLine as IconProp} />
						</CardOverlayButton>
					</Link>
				</CardOverlayButtonsContainer>
			</CardOverlayContainer>

			<CourseContentContainer>
				<SequenceImageContainer>
					<SequenceImageBorder>
						<SequenceImage
							src={
								sequence.image
									? sequence.image
									: "https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80"
							}
						/>
					</SequenceImageBorder>
				</SequenceImageContainer>

				<CourseContentTextContainer>
					<Title level={5} ellipsis={{ rows: 2 }}>
						{sequence.internalName}
					</Title>
					<S.FlexBoxStartRow>
						{sequence.classroom ? (
							<Tag color="purple">Classroom</Tag>
						) : (
							<Tag color="cyan">Self-learning</Tag>
						)}
						<Tag>{sequence.content.filter((item) => item.type === "course").length} courses</Tag>
					</S.FlexBoxStartRow>
				</CourseContentTextContainer>
			</CourseContentContainer>

			<CourseCardFooter>
				<SequenceStatus isActive={sequence.isActive}>Active</SequenceStatus>
			</CourseCardFooter>
		</CourseCardContainer>
	)
}

export default SequenceCard
