// MODAL TO ADD A TEMPLATE COURSE

import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { createStructuredSelector } from "reselect"
import { Button, Typography, Descriptions, Alert, Space, Form, Input, Select } from "antd"
import VideoPlayer from "../../stage/cards/VideoPlayer"

import { selectOrgId, selectOrgCourseFolders } from "../../../../redux/org/org.selectors"
import { createNewCourseFromTemplate } from "../../../../redux/courses/courses.actions"
import useUrlQueryString from "../../../hooks/useUrlQueryString"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"
import LpImageInput from "../../../common/LpImageInput"

import { languageList } from "../../course/sidepanels/languageList"

const { Title } = Typography
const { TextArea } = Input

const getFolderSlug = (folder_slugs, courseFolderParentId) => {
	let folderSlug = ""
	if (courseFolderParentId && Object.keys(folder_slugs).find((key) => folder_slugs[key] === courseFolderParentId)) {
		folderSlug = Object.keys(folder_slugs).find((key) => folder_slugs[key] === parseInt(courseFolderParentId, 10))
		folderSlug = `/${folderSlug.slice(0, -1)}`
	}
	return folderSlug
}

const ContentContainer = styled.div`
	width: 700px;
	padding: 30px;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`
const VideoContainer = styled.div`
	width: 100%;
	height: 360px;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`

const AddTemplateCourse = ({
	closemodal,
	createNewCourseFromTemplate,
	orgId,
	courseInfo,
	templateInfo,
	courseId,
	status,
	courseFolders: { folder_slugs }
}) => {
	const [loading, setLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [modalView, setModalView] = useState("course_info")
	const [imageUrl, setImageUrl] = useState(courseInfo.image)
	const [errorMsg, setErrorMsg] = useState(null)
	const [folderId] = useUrlQueryString("folder")
	const courseFolderParentId = folderId ? parseInt(folderId) : null
	const folderSlug = getFolderSlug(folder_slugs, courseFolderParentId)

	const handleAddTemplate = async (values) => {
		try {
			setLoading(true)
			const { name, target_learner, short_description, language } = values
			const info = {
				name,
				image: imageUrl,
				preview_url: null,
				publish_url: null,
				target_learner,
				short_description,
				user_groups: {
					view_groups: ["public"],
					access_groups: ["registered"]
				},
				language
			}
			await createNewCourseFromTemplate(orgId, info, courseId, courseFolderParentId)
			// dummmy loading below...
			setTimeout(() => {
				setLoading(false)
				setRedirect(true)
			}, 2000)
		} catch (err) {
			setLoading(false)
			setErrorMsg("Sorry we weren't able to add this template, please check your connection and try again")
		}
	}

	if (redirect) return <Redirect to={`/org/${orgId}/create/courses${folderSlug}`} />

	if (errorMsg)
		return (
			<ContentContainer>
				<GeTitle>Error adding course</GeTitle>
				<Alert message={errorMsg} type="error" />
			</ContentContainer>
		)

	if (modalView === "course_info") {
		return (
			<ContentContainer>
				<GeTitle>{courseInfo.name}</GeTitle>
				<Descriptions column={1}>
					<Descriptions.Item label="Description">{courseInfo.short_description}</Descriptions.Item>
					<Descriptions.Item label="Target learner">{courseInfo.target_learner}</Descriptions.Item>
					{templateInfo.timeToComplete && (
						<Descriptions.Item label="Time to complete">{templateInfo.timeToComplete}</Descriptions.Item>
					)}
				</Descriptions>
				<Divider />
				{templateInfo && templateInfo.videoType && templateInfo.videoId && (
					<>
						<Title level={5}>Watch the video</Title>
						<VideoContainer>
							<VideoPlayer videoType={templateInfo.videoType} videoId={templateInfo.videoId} />
						</VideoContainer>
					</>
				)}
				<Divider />
				{status === "locked" ? (
					<Alert
						message="This is a premium Learn.ink course template. You need to subscribe to premium templates to get access to this course"
						type="warning"
					/>
				) : (
					<ButtonContainer>
						<Space>
							<Button size="large" shape="round" onClick={closemodal}>
								Cancel
							</Button>
							<Button type="primary" size="large" shape="round" onClick={() => setModalView("edit_info")}>
								Select course
							</Button>
						</Space>
					</ButtonContainer>
				)}
			</ContentContainer>
		)
	}

	if (modalView === "edit_info") {
		return (
			<ContentContainer>
				<GeTitle>Customise course</GeTitle>
				{loading ? (
					<CbLoading loadingText="Adding course..." padding="30px" />
				) : (
					<Form
						size="medium"
						autoComplete="off"
						onFinish={handleAddTemplate}
						initialValues={{
							name: courseInfo.name,
							short_description: courseInfo.short_description,
							target_learner: courseInfo.target_learner,
							language: courseInfo.language
						}}
						layout="vertical"
					>
						<Form.Item
							label={<FormLabel>Course title</FormLabel>}
							name="name"
							rules={[
								{
									required: true,
									message: "You need to add a course title"
								}
							]}
							tooltip="This is what your learner will see when they open your course"
						>
							<Input placeholder="Enter course title..." />
						</Form.Item>
						<Form.Item
							label={<FormLabel>Target learner</FormLabel>}
							name="target_learner"
							rules={[
								{
									required: true,
									message: "You need to add a target learner"
								}
							]}
							tooltip="e.g. New sales agents"
						>
							<Input placeholder="Who are you targeting..." />
						</Form.Item>
						<Form.Item
							label={<FormLabel>Course description</FormLabel>}
							name="short_description"
							rules={[
								{
									required: true,
									message: "You need to add a course description"
								}
							]}
							tooltip="Add a short description to help explain what your course is about"
						>
							<TextArea placeholder="Enter a short description of the course..." rows={3} />
						</Form.Item>
						<Form.Item
							label={<FormLabel>Language</FormLabel>}
							name="language"
							tooltip="This is the language your course will be written in"
						>
							<Select
								showSearch
								showArrow={true}
								style={{ width: "100%" }}
								options={languageList}
								placeholder="Select a language..."
								filterOption={(input, option) =>
									option.label.toLowerCase().includes(input.toLowerCase())
								}
							/>
						</Form.Item>
						<Divider height="6px" />
						<LpImageInput
							id="course-image-upload"
							initialImageUrl={imageUrl}
							labelText="Course image"
							uploadImageMessage=""
							thumbPreviewShape="square"
							buttonText="Upload a new image"
							thumbSize={"50px"}
							clickableImage={true}
							uploadButtonAsText={true}
							setNewImage={(imageUrl) => {
								setImageUrl(imageUrl)
							}}
							labelFontSize="16px !important"
						/>
						<Divider borderOnBottom={true} height="20px" />
						<Divider height={"14px"} />
						<Form.Item>
							<ButtonContainer>
								<Space>
									<Button shape="round" size="large" onClick={closemodal}>
										Cancel
									</Button>
									<Button type="primary" shape="round" size="large" htmlType="submit">
										Add course
									</Button>
								</Space>
							</ButtonContainer>
						</Form.Item>
					</Form>
				)}
			</ContentContainer>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	courseFolders: selectOrgCourseFolders
})

const mapDispatchToProps = (dispatch) => ({
	createNewCourseFromTemplate: (org_id, course_info, template_course_id, course_folder_parent_id) =>
		dispatch(createNewCourseFromTemplate(org_id, course_info, template_course_id, course_folder_parent_id))
})

PropTypes.AddTemplateCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	createNewCourseFromTemplate: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateCourse)
