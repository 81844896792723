import React from "react"
import styled from "styled-components"
import { Button, Typography } from "antd"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const { Title, Text } = Typography

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;
	height: 100%;
	padding: 20px 0px 20px 0px;
`
const PricingColumnInner = styled.div`
	width: 100%;
	border: solid 1px var(--light-grey);
	border-radius: 10px;
	display: block;
	padding: 25px;
`
const ProductNameContainer = styled.div`
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	box-sizing: border-box;
	display: flex;
`
const ProductName = styled.div`
	flex: 1;
	padding-right: 10px;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.2;
	display: block;
`
const ProductInfoContainer = styled.div`
	padding-top: 10px;
	box-sizing: border-box;
`
const ProductInfoList = styled.div`
	margin-top: 16px;
	box-sizing: border-box;
`
const ProductListItem = styled.div`
	align-items: flex-start;
	margin-bottom: 8px;
	box-sizing: border-box;
	display: flex;
	font-size: 14px;
`

const SubscriptionStatus = ({ orgId, activePlan }) => {
	const { feature_access, status } = activePlan

	const handleContactUsButton = () => {
		window.Intercom("showNewMessage")
	}

	if (!activePlan.info.display_name) {
		return null
	}

	if (feature_access.legacy) {
		return (
			<div
				className="row"
				style={{
					borderBottom: "1px solid var(--light-grey)",
					paddingBottom: 20
				}}
			>
				<div className="col-sm-8">
					<Column>
						<Title level={4}>Your plan</Title>
						<Text>
							As one of our earliest Learn.ink users, your account is on legacy pricing. To talk to us
							about your plan press the button below
						</Text>
						<Button
							shape="round"
							size="large"
							style={{ marginTop: 20, width: 200 }}
							onClick={() => handleContactUsButton()}
						>
							Contact us
						</Button>
					</Column>
				</div>
				<div className="col-sm-4">
					<PricingColumnInner>
						<ProductNameContainer>
							<ProductName>{activePlan.info.display_name}</ProductName>
						</ProductNameContainer>
						<ProductInfoContainer>
							<ProductInfoList>
								{activePlan.info.feature_list.map((feature, index) => (
									<ProductListItem key={index}>
										<span style={{ color: "var(--mid-green)", marginRight: "8px" }}>
											<FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
										</span>
										{feature}
									</ProductListItem>
								))}
							</ProductInfoList>
						</ProductInfoContainer>
					</PricingColumnInner>
				</div>
			</div>
		)
	}

	if (feature_access.max_users) {
		return (
			<div
				className="row"
				style={{
					borderBottom: "1px solid var(--light-grey)",
					paddingBottom: 20
				}}
			>
				<div className="col-sm-8">
					<Column>
						<Title level={4}>Your plan</Title>
						{status === "non_stripe_subscription" ? (
							<>
								<Text>
									You have chosen to pay via invoice rather than through our Stripe billing portal.
									For questions about your subscription press the button below to talk to us
								</Text>
								<Button
									shape="round"
									size="large"
									style={{ marginTop: 20, width: 200 }}
									onClick={() => handleContactUsButton()}
								>
									Contact us
								</Button>
							</>
						) : (
							<>
								<Text>
									You are on the {activePlan.info.display_name} plan. Press the button if you'd like
									to upgrade
								</Text>
								<Link to={`/org/${orgId}/upgrade`}>
									<Button shape="round" size="large" style={{ marginTop: 20, width: 200 }}>
										Upgrade
									</Button>
								</Link>
							</>
						)}
					</Column>
				</div>
				<div className="col-sm-4">
					<PricingColumnInner>
						<ProductNameContainer>
							<ProductName>{activePlan.info.display_name}</ProductName>
						</ProductNameContainer>
						<ProductInfoContainer>
							<ProductInfoList>
								{activePlan.info.feature_list.map((feature, index) => (
									<ProductListItem key={index}>
										<span style={{ color: "var(--mid-green)", marginRight: "8px" }}>
											<FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
										</span>
										{feature}
									</ProductListItem>
								))}
							</ProductInfoList>
						</ProductInfoContainer>
					</PricingColumnInner>
				</div>
			</div>
		)
	}

	return (
		<div
			className="row"
			style={{
				borderBottom: "1px solid var(--light-grey)",
				paddingBottom: 20
			}}
		>
			<div className="col-sm-8">
				<Column>
					<Title level={4}>Your plan</Title>
					<Text>
						You are on our {activePlan.info.display_name} plan and will be billed at the end of each month
						based on your usage. For more information about your subscription press the button to talk to us
					</Text>
					<Button
						shape="round"
						size="large"
						style={{ marginTop: 20, width: 200 }}
						onClick={() => handleContactUsButton()}
					>
						Contact us
					</Button>
				</Column>
			</div>
			<div className="col-sm-4">
				<PricingColumnInner>
					<ProductNameContainer>
						<ProductName>{activePlan.info.display_name}</ProductName>
					</ProductNameContainer>
					<ProductInfoContainer>
						<ProductInfoList>
							{activePlan.info.feature_list.map((feature, index) => (
								<ProductListItem key={index}>
									<span style={{ color: "var(--mid-green)", marginRight: "8px" }}>
										<FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
									</span>
									{feature}
								</ProductListItem>
							))}
						</ProductInfoList>
					</ProductInfoContainer>
				</PricingColumnInner>
			</div>
		</div>
	)
}

export default SubscriptionStatus
