import React, { useState } from "react"
import { Button, Modal } from "antd"
import styled from "styled-components"
import { CaretRightFilled, QuestionCircleOutlined } from "@ant-design/icons"

const VideoThumbnail = styled.div<{ thumbnailImg: string }>`
	min-width: 140px;
	max-width: 140px;
	height: 80px;
	background-image: ${({ thumbnailImg }) => `url("${thumbnailImg}")`};
	background-size: contain;
`
const VideoOverlay = styled.div`
	width: 100%;
	height: 100%;
	transition: 0.5s ease;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	:hover {
		background: rgba(0, 0, 0, 0.3);
	}
`
const Bubble = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 999px;
	background-color: var(--secondary-color);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
	transition: 0.5s ease;
	:hover {
		background-color: var(--secondary-color-highlight);
	}
`

export type VideoId = "why_create_a_role" | "what_are_user_groups" | "what_is_a_learning_path" | "learning_path_editor"

interface Props {
	buttonType?: "default" | "thumbnail"
	buttonText?: string
	videoId: VideoId
	buttonSize?: "small" | "middle" | "large"
}

const videoSettings = {
	why_create_a_role: {
		url: "https://www.loom.com/embed/f560bea85b0a47bc8676ff75353c7bae",
		thumbnail: "https://cdn.loom.com/sessions/thumbnails/f560bea85b0a47bc8676ff75353c7bae-with-play.gif"
	},
	what_are_user_groups: {
		url: "https://www.loom.com/embed/7eab823663b74f318a7a1663b83c6592",
		thumbnail: "https://cdn.loom.com/sessions/thumbnails/7eab823663b74f318a7a1663b83c6592-with-play.gif"
	},
	what_is_a_learning_path: {
		url: "https://www.loom.com/embed/fca006b96c6e4ca282c822aea01e8e07",
		thumbnail: "https://cdn.loom.com/sessions/thumbnails/fca006b96c6e4ca282c822aea01e8e07-with-play.gif"
	},
	learning_path_editor: {
		url: "https://www.loom.com/embed/fe7ad4f4841540629d02d700095138d5",
		thumbnail: "https://cdn.loom.com/sessions/thumbnails/fe7ad4f4841540629d02d700095138d5-with-play.gif"
	} as const
}

const HelpVideoButton = ({ buttonType, buttonText, buttonSize, videoId }: Props) => {
	const [isModelOpen, setIsModalOpen] = useState<boolean>(false)
	return (
		<>
			{buttonType === "thumbnail" ? (
				<VideoThumbnail
					style={{
						height: buttonSize === "small" ? "65px" : "80px",
						minWidth: buttonSize === "small" ? "110px" : "140px",
						maxWidth: buttonSize === "small" ? "110px" : "140px"
					}}
					thumbnailImg={videoSettings[videoId].thumbnail}
					onClick={() => setIsModalOpen(true)}
				>
					<VideoOverlay>
						<Bubble>
							<CaretRightFilled style={{ fontSize: 26 }} />
						</Bubble>
					</VideoOverlay>
				</VideoThumbnail>
			) : (
				<Button
					shape="round"
					size={buttonSize || "middle"}
					icon={<QuestionCircleOutlined />}
					onClick={() => setIsModalOpen(true)}
				>
					{buttonText || "Show me how"}
				</Button>
			)}
			{isModelOpen && (
				<Modal
					open={isModelOpen}
					onCancel={() => setIsModalOpen(false)}
					centered
					width="70%"
					footer={[
						<Button key="ok" type="primary" onClick={() => setIsModalOpen(false)}>
							OK
						</Button>
					]}
				>
					<div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
						<iframe
							src={videoSettings[videoId].url}
							allowFullScreen
							style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
						/>
					</div>
				</Modal>
			)}
		</>
	)
}

export default HelpVideoButton
