import React from "react"
import matchSorter from "match-sorter"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { useAsyncDebounce } from "react-table"

const FilterContainer = styled.div`
	display: flex;
	margin: 4px 8px 12px 8px;
	align-items: center;
	justify-content: center;
	background-color: white;
`
const ColSearchInput = styled.input`
	box-shadow: rgb(213, 220, 227) 0px 0px 0px 1px inset;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 30px;
	width: 100%;
	display: block;
	padding-left: 16px !important;
	padding-right: 16px !important;
	font-size: 13px;
	line-height: 40px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-radius: 4px;
	border-width: 0px;
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	:focus {
		border: solid 1px var(--secondary-color);
	}
`
const DropSelect = styled.select`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	height: 30px;
	width: 100%;
	font-size: 13px;
	font-weight: 400;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid var(--v-light-grey);
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	padding-left: 8px;
	padding-right: 8px;

	:focus {
		border: solid 1px var(--secondary-color);
	}
`
const RangeSearchInput = styled.input`
	box-shadow: rgb(213, 220, 227) 0px 0px 0px 1px inset;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 30px;
	width: 60px;
	display: block;
	padding-left: 16px !important;
	font-size: 13px;
	line-height: 40px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-radius: 4px;
	border-width: 0px;
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	:focus {
		border: solid 1px var(--secondary-color);
	}
`
const RangeTextDiv = styled.div`
	margin: 6px;
	color: var(--light-grey);
	font-weight: 400;
	border-radius: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`
const SearchContainer = styled.div`
	display: flex;
	flex: 1;
`
const SearchIcon = styled.div`
	font-size: 15px;
	margin-left: 0px;
	margin-right: 8px;
	color: var(--mid-grey);
`
const SearchInput = styled.input`
	font-size: 15px;
	font-weight: 400;
	background: transparent;
	overflow: hidden;
	border: 0px;
	text-overflow: ellipsis;
	white-space: pre-wrap;
`

/*
TABLE FUNCTIONS
---------------
In the below there's a range of functions we use in the table, these come directly from examples
in the react-table docs, e.g see here: https://github.com/tannerlinsley/react-table/tree/master/docs/examples
*/

// checkbox stuff...

const IndeterminateCheckboxInner = ({ indeterminate, ...rest }, ref) => {
	const defaultRef = React.useRef()
	const resolvedRef = ref || defaultRef

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])

	return <input type="checkbox" ref={resolvedRef} {...rest} />
}

export const IndeterminateCheckbox = React.forwardRef(IndeterminateCheckboxInner)

// filters stuff...

// Define a default UI for filtering, this is the search filter
// across the entire table
export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
	const [value, setValue] = React.useState(globalFilter)
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<SearchContainer>
			<SearchIcon>
				<FontAwesomeIcon icon={faSearch} />
			</SearchIcon>
			<SearchInput
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value)
					onChange(e.target.value)
				}}
				placeholder={`Search...`}
			/>
		</SearchContainer>
	)
}

// Define a default UI for filtering, this is the search that
// works at the top of a given column...
export const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
	const count = preFilteredRows.length
	return (
		<FilterContainer>
			<ColSearchInput
				value={filterValue || ""}
				onChange={(e) => {
					setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
				}}
				placeholder={`Search ${count} records...`}
			/>
		</FilterContainer>
	)
}

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
	// Calculate the options for filtering
	// using the preFilteredRows
	let options = React.useMemo(() => {
		const options = new Set()

		preFilteredRows.forEach((row, index) => {
			options.add(row.values[id])
		})

		return [...options.values()]
	}, [id, preFilteredRows])

	return (
		<FilterContainer>
			<DropSelect
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined)
				}}
			>
				<option value="">All</option>
				{options.map((option, i) => (
					<option key={i} value={option}>
						{option}
					</option>
				))}
			</DropSelect>
		</FilterContainer>
	)
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
	// Calculate the min and max
	// using the preFilteredRows

	const [min, max] = React.useMemo(() => {
		let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		preFilteredRows.forEach((row) => {
			min = Math.min(row.values[id], min)
			max = Math.max(row.values[id], max)
		})
		return [min, max]
	}, [id, preFilteredRows])

	return (
		<div style={{ marginLeft: "6px" }}>
			<input
				type="range"
				min={min}
				max={max}
				value={filterValue || min}
				onChange={(e) => {
					setFilter(parseInt(e.target.value, 10))
				}}
			/>
			<button
				style={{
					fontSize: "12px",
					color: filterValue ? "var(--secondary-color)" : "var(--light-grey)"
				}}
				onClick={() => setFilter(undefined)}
			>
				Reset
			</button>
		</div>
	)
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export const NumberRangeColumnFilter = ({ column: { filterValue = [], preFilteredRows, setFilter, id } }) => {
	/*
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])
  */
	return (
		<FilterContainer>
			<RangeSearchInput
				value={filterValue[0] || ""}
				type="number"
				onChange={(e) => {
					const val = e.target.value
					setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
				}}
				placeholder={`Min`}
			/>
			<RangeTextDiv>to</RangeTextDiv>
			<RangeSearchInput
				value={filterValue[1] || ""}
				type="number"
				onChange={(e) => {
					const val = e.target.value
					setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
				}}
				placeholder={`Max`}
			/>
		</FilterContainer>
	)
}

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

export const arrayContainsFilterFn = (rows, id, filterValue) => {
	return rows.filter((row) => {
		const rowValue = row.values[id]
		if (filterValue.length > 0) {
			return rowValue.filter((value) => filterValue.includes(value)).length > 0
		} else {
			return true
		}
	})
}

export const multiSelectFilter = (rows, columnIds, filterValue) => {
	const filterKey = columnIds[0]
	return (filterValue || []).length === 0
		? rows
		: filterValue.includes("none")
		? rows.filter((row) => (row.values[filterKey] || []).length === 0)
		: rows.filter((row) => filterValue.filter((value) => (row.values[filterKey] || []).includes(value)).length > 0)
}

export const shareRequestFilter = (rows, columnIds, filterValue) => { // TODO - this is probably temp...
	const filterKey = columnIds[0]
	return (filterValue || []).length === 0
		? rows
		: filterValue.includes("none")
		? rows.filter((row) => (row.values[filterKey] || []).length === 0)
		: rows.filter(
				(row) =>
					filterValue.filter((value) => (row.values[filterKey] || []).map((v) => v.id).includes(value))
						.length > 0
		  )
}
