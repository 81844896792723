import React from "react"
import { Button, Form, Modal, Result } from "antd"
import CbLoading from "./CbLoading"
import { ModalState } from "../../util/model/PageStates"

interface Props {
	title: string
	isModalOpen: boolean
	closeModal: () => void
	modalState: ModalState
	handleSubmit: (values: any) => void
	initialValues?: any
	formElements: React.JSX.Element
	submitButtonTitle: string
	completeElements?: React.JSX.Element
	errorElements?: React.JSX.Element
	size?: "small" | "middle" | "large"
}

const FormModal = ({
	title,
	isModalOpen,
	closeModal,
	modalState,
	handleSubmit,
	initialValues,
	formElements,
	submitButtonTitle,
	completeElements,
	errorElements,
	size
}: Props) => {
	return (
		<Modal
			title={title}
			open={isModalOpen}
			onCancel={closeModal}
			footer={
				modalState === "confirm"
					? [
							<>
								<Button onClick={closeModal}>Cancel</Button>
								<Button form="myForm" key="submit" htmlType="submit" type="primary">
									{submitButtonTitle}
								</Button>
							</>
					  ]
					: [<Button onClick={closeModal}>OK</Button>]
			}
			centered
		>
			<div style={{ width: "100%", display: "flex", flexDirection: "column", padding: "0px 15px 0px 15px" }}>
				{modalState === "confirm" && (
					<Form
						id="myForm"
						onFinish={handleSubmit}
						autoComplete="off"
						layout="vertical"
						initialValues={initialValues || {}}
						size={size || "middle"}
					>
						{formElements}
					</Form>
				)}
				{modalState === "complete" && (
					<>
						{completeElements ? (
							completeElements
						) : (
							<Result status="success" title="Your changes have been updated!" />
						)}
					</>
				)}
				{modalState === "error" && (
					<>
						{errorElements ? (
							errorElements
						) : (
							<Result
								status="error"
								title="Update failed"
								subTitle="Sorry we weren't able to save your updates, please check your connection and try again"
							/>
						)}
					</>
				)}
				{modalState === "loading" && <CbLoading size={50} />}
			</div>
		</Modal>
	)
}

export default FormModal
