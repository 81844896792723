// 404 PAGE

import React from "react"
import { connect } from "react-redux"
import { appSignOut } from "../../redux/user/user.actions"
import styled from "styled-components"
import { Button, Typography } from "antd"

import Logo from "../../assets/images/logo_green.svg"
import Err404 from "../../assets/images/404.svg"

const { Title, Text } = Typography

const Container = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
`

const PageHeader = styled.div`
	height: 100px;
	width: 100%;
	display: table;
`

const LogoContainer = styled.div`
	height: 100px;
	width: 100%;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
`

const LogoImg = styled.img`
	height: 30px;
`

const PageWrap = styled.div`
	max-width: 720px;
	width: 90%;
	margin: 0 auto;
	padding-bottom: 80px;
	padding-top: 40px;
	text-align: center;
`

const ErrorImgContainer = styled.div`
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
`

const ErrorImg = styled.img`
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
`

const TextContainer = styled.div`
	width: 100%;
	padding-bottom: 20px;
`

const Page404 = ({ appSignOut }) => {
	return (
		<Container>
			<PageHeader>
				<LogoContainer>
					<LogoImg src={Logo} />
				</LogoContainer>
			</PageHeader>
			<PageWrap>
				<ErrorImgContainer>
					<ErrorImg src={Err404} />
				</ErrorImgContainer>
				<TextContainer>
					<Title level={2}>Page not found...</Title>
					<Text>The page you’re looking for could have been deleted or never have existed.</Text>
				</TextContainer>
				<Button size="large" onClick={() => appSignOut()} shape="round">
					Sign out
				</Button>
			</PageWrap>
		</Container>
	)
}

const mapDispatchToProps = (dispatch) => ({
	appSignOut: () => dispatch(appSignOut())
})

export default connect(null, mapDispatchToProps)(Page404)
