// COLUMN WITH HELP VIDEO

import React from "react"
import styled from "styled-components"
import { Alert, Button } from "antd"

const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
`

const CbCourseHelpColumn = () => {
	return (
		<ColumnWrapper>
			<Alert
				message="Need some tips?"
				description="Don't worry we've got you covered! Press on the button below to see our most popular guides and tutorials on how to make great courses"
				type="info"
				showIcon
			/>
			<Button style={{ marginTop: 10 }} shape="round" size="large" onClick={() => window.Intercom("show")}>
				Open the help docs
			</Button>
		</ColumnWrapper>
	)
}

export default CbCourseHelpColumn
