import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { AppPortal } from "svix-react"
import axios from "axios"
import "../../styles/svix.css"

import { selectOrgId } from "../../redux/org/org.selectors"
import CbLoading from "../../components/common/CbLoading"

const SvixCustomerPortal = ({ orgId }) => {
	const [appPortal, setAppPortal] = useState(null)

	const getAppPortal = async () => {
		try {
			const response = await axios.get(`/api/data/${orgId}/svix-portal`)
			setAppPortal(response.data)
		} catch (err) {
			console.log("Error", err) // FIX THIS - do error handling
		}
	}

	useEffect(() => {
		getAppPortal()
	}, [])

	if (!appPortal) {
		return <CbLoading fullpage />
	}

	return <AppPortal url={appPortal} />
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(SvixCustomerPortal)
