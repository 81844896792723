// LESSON CARD EDITOR

import React, { useState } from "react"
import styled from "styled-components"
import { Select } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import {
	StopOutlined,
	LeftCircleOutlined,
	RightCircleOutlined,
	UpCircleOutlined,
	DownCircleOutlined,
	CaretUpOutlined,
	CaretDownOutlined,
	FullscreenOutlined,
	MinusSquareOutlined
} from "@ant-design/icons"

import * as S from "../../../styles/PageStyles"
import { selectOrgInfo } from "../../../../redux/org/org.selectors"
import { updateLessonCard } from "../../../../redux/courses/courses.actions"

import LpColorPicker from "../../../common/LpColorPicker"
import PanelContainer from "./common/PanelContainer"

// @ts-ignore
import Clouds from "../icons/clouds.svg"
// @ts-ignore
import Confetti from "../icons/confetti.png"
// @ts-ignore
import BackgroundOneColor from "../icons/oneColor.svg"
// @ts-ignore
import Horizontal from "../icons/horizontal.svg"
// @ts-ignore
import Single from "../icons/single.svg"
// @ts-ignore
import Double from "../icons/double.svg"
// @ts-ignore
import Triple from "../icons/triple.svg"
// @ts-ignore
import Quadruple from "../icons/quadruple.svg"
// @ts-ignore
import Rounded from "../icons/rounded.svg"
// @ts-ignore
import Diagonal from "../icons/diagonal.svg"
// @ts-ignore
import Triangles from "../icons/triangles.svg"
// @ts-ignore
import Shapes from "../icons/shapes.svg"
// @ts-ignore
import Curved from "../icons/curved.svg"
import {
	BackgroundStyle,
	CallOutCard,
	IconPlacement,
	IconSize,
	Layout
} from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"
import SegmentSetting from "./common/SegmentSetting"

const CustomSegment = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	background-color: #eae8e8;
	padding: 1px;
`
const CustomSegmentContainer = styled.div<{ isActive?: boolean }>`
	padding: 5px;
	border-radius: 2px;
	box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
	background-color: ${({ isActive }) => (isActive ? "white" : "none")};
	width: 95px;
	height: 73px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	transition: 0.3s;
`
const SegmentImg = styled.img`
	width: 32px;
	height: 32px;
	padding: 4px;
`
const PanelExpand = styled.div`
	width: 100%;
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`

const backgroundStyles = [
	"none",
	"horizontal",
	"diagonal",
	"triangles",
	"curved",
	"shapes",
	"clouds",
	"confetti"
] as BackgroundStyle[]

const backgroundStyleMap = {
	none: {
		name: "Default",
		icon: BackgroundOneColor
	},
	horizontal: {
		name: "Horizontal",
		icon: Horizontal
	},
	clouds: {
		name: "Clouds",
		icon: Clouds
	},
	shapes: {
		name: "Shapes",
		icon: Shapes
	},
	curved: {
		name: "Curved",
		icon: Curved
	},
	diagonal: {
		name: "Diagonal",
		icon: Diagonal
	},
	triangles: {
		name: "Triangles",
		icon: Triangles
	},
	confetti: {
		name: "Confetti",
		icon: Confetti
	}
}

interface Props {
	card: CallOutCard
	orgInfo: any
	updateLessonCard: (updateParams: CallOutCard) => void
}

const CallOutCardEditor = ({ card, orgInfo, updateLessonCard }: Props) => {
	const [showTemplateSettings, setShowTemplateSettings] = useState(false)

	const updateNumListElements = (numElements: number) => {
		const iconPlacement = card.iconPlacement && card.iconPlacement !== "none" ? "left" : "none"

		const newCard = {
			...card,
			layout: "multiElementHorizontal" as Layout,
			iconPlacement: iconPlacement as IconPlacement,
			iconSize: "small" as IconSize
		}

		const elements = (card.elements || [])

		if (numElements <= elements.length) {
			newCard.elements = elements.slice(0, numElements)
			updateLessonCard(newCard)
		} else {
			const numAdditionalElements = numElements - elements.length
			const defaultElement = elements[elements.length - 1]
			const newElements = []
			for (var i = 0; i < numAdditionalElements; i++) {
				newElements.push(defaultElement)
			}
			newCard.elements = [...elements, ...newElements]
			updateLessonCard(newCard)
		}
	}

	const updateCallOutCardLayout = (layout: Layout) => {
		if (layout === "oneElement") {
			const iconPlacement = ["left", "right"].includes(card.iconPlacement || "") ? "top" : card.iconPlacement
			const newCard = {
				...card,
				layout,
				elements: (card.elements || []).slice(0, 1),
				iconPlacement
			}
			updateLessonCard(newCard)
		} else if (layout === "multiElementHorizontal") {
			updateNumListElements(3)
		}
	}

	return (
		<>
			<PanelContainer title="Layout">
				<SegmentSetting
					onChange={(val: "oneElement" | "multiElementHorizontal") => updateCallOutCardLayout(val)}
					value={card.layout}
					id="layout_select"
					options={[
						{
							label: "Basic",
							value: "oneElement",
							image: Single
						},
						{
							label: "List",
							value: "multiElementHorizontal",
							image: Triple
						}
					]}
				/>
				{card.layout === "multiElementHorizontal" && (
					<SegmentSetting
						onChange={(val: 2 | 3 | 4) => updateNumListElements(val)}
						value={(card.elements || []).length}
						id="editor_callout_list_style_select"
						style={{ marginTop: 10 }}
						options={[
							{
								label: "Two",
								value: 2,
								image: Double
							},
							{
								label: "Three",
								value: 3,
								image: Triple
							},
							{
								label: "Four",
								value: 4,
								image: Quadruple
							}
						]}
					/>
				)}
			</PanelContainer>
			<PanelContainer title="Icon">
				<SegmentSetting
					onChange={(val: "left" | "right" | "top" | "bottom") =>
						updateLessonCard({ ...card, iconPlacement: val })
					}
					value={card.iconPlacement}
					id="editor_icon_placement_select"
					options={[
						{
							label: "None",
							value: "none",
							icon: <StopOutlined style={S.editorIcon} />
						},
						{
							label: card.layout === "multiElementHorizontal" ? "Left" : "Top",
							value: card.layout === "multiElementHorizontal" ? "left" : "top",
							icon:
								card.layout === "multiElementHorizontal" ? (
									<LeftCircleOutlined style={S.editorIcon} />
								) : (
									<UpCircleOutlined style={S.editorIcon} />
								)
						},
						{
							label: card.layout === "multiElementHorizontal" ? "Right" : "Bottom",
							value: card.layout === "multiElementHorizontal" ? "right" : "bottom",
							icon:
								card.layout === "multiElementHorizontal" ? (
									<RightCircleOutlined style={S.editorIcon} />
								) : (
									<DownCircleOutlined style={S.editorIcon} />
								)
						}
					]}
				/>
			</PanelContainer>
			<PanelContainer title="Animation">
				<SegmentSetting
					onChange={(val: "popup" | "inline" | "off") => updateLessonCard({ ...card, animation: val })}
					value={card.animation}
					id="editor_animated_select"
					options={[
						{
							label: "Pop up",
							value: "popup",
							icon: <FullscreenOutlined style={S.editorIcon} />
						},
						{
							label: "Inline",
							value: "inline",
							icon: <MinusSquareOutlined style={S.editorIcon} />
						},
						{
							label: "Off",
							value: "off",
							icon: <StopOutlined style={S.editorIcon} />
						}
					]}
				/>
			</PanelContainer>
			<PanelExpand onClick={() => setShowTemplateSettings(!showTemplateSettings)}>
				Edit template style{" "}
				<div style={{ fontSize: 15 }}>{showTemplateSettings ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
			</PanelExpand>
			{showTemplateSettings && (
				<>
					<PanelContainer title="Background style">
						<CustomSegment id="editor_background_style_select">
							{backgroundStyles.map((backgroundStyle, index) => (
								<CustomSegmentContainer
									isActive={card.backgroundStyle === backgroundStyle}
									key={index}
									onClick={() => updateLessonCard({ ...card, backgroundStyle: backgroundStyle })}
								>
									<SegmentImg src={backgroundStyleMap[backgroundStyle].icon} />
									<div>{backgroundStyleMap[backgroundStyle].name}</div>
								</CustomSegmentContainer>
							))}
						</CustomSegment>
					</PanelContainer>
					<PanelContainer title="Colours">
						<div style={{ display: "flex" }}>
							<SegmentSetting
								onChange={(val: "custom" | "default") =>
									updateLessonCard({
										...card,
										primaryColor: val === "custom" ? orgInfo.theme.primary : undefined,
										secondaryColor: val === "custom" ? orgInfo.theme.secondary : undefined
									})
								}
								value={card.primaryColor ? "custom" : "default"}
								id="editor_background_color_select"
								options={[
									{
										label: "Default",
										value: "default",
										icon: (
											<div
												style={{
													borderRadius: 99,
													width: 28,
													height: 28,
													background: orgInfo.theme.primary
												}}
											/>
										)
									},
									{
										label: "Custom",
										value: "custom",
										icon: (
											<div
												style={{
													borderRadius: 99,
													width: 28,
													height: 28,
													background: `linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)`
												}}
											/>
										)
									}
								]}
							/>
							{card.primaryColor && (
								<>
									<LpColorPicker
										initialHex={card.primaryColor || orgInfo.theme.primary}
										callbackColorChange={(val) => updateLessonCard({ ...card, primaryColor: val })}
										style={{ width: 30, height: 30 }}
									/>
									{!["none", "clouds"].includes(card.backgroundStyle || "") && (
										<LpColorPicker
											initialHex={card.secondaryColor || orgInfo.theme.secondary}
											callbackColorChange={(val) =>
												updateLessonCard({ ...card, secondaryColor: val })
											}
											style={{ width: 30, height: 30 }}
										/>
									)}
								</>
							)}
						</div>
					</PanelContainer>
					<PanelContainer title="Shape">
						<SegmentSetting
							onChange={(val: "square" | "rounded") => updateLessonCard({ ...card, shape: val })}
							value={card.shape}
							id="editor_shape_select"
							options={[
								{
									label: "Square",
									value: "square",
									image: Single
								},
								{
									label: "Rounded",
									value: "rounded",
									image: Rounded
								}
							]}
						/>
					</PanelContainer>
					<PanelContainer title="Logo">
						<Select
							showArrow={true}
							style={{ width: 190 }}
							options={[
								{ value: "none", label: "None" },
								{ value: "topLeft", label: "Top left" },
								{ value: "topRight", label: "Top right" },
								{ value: "bottomLeft", label: "Bottom left" },
								{ value: "bottomRight", label: "Bottom right" }
							]}
							placeholder="Select a style..."
							value={card.logoPlacement}
							onChange={(val) => updateLessonCard({ ...card, logoPlacement: val })}
							id="editor_logo_placement_select"
						/>
					</PanelContainer>
				</>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgInfo: selectOrgInfo
})

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: CallOutCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallOutCardEditor)
