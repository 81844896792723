import { UiActionTypes } from "./ui.types"

const INITIAL_STATE = {
	loading: false
}

const uiReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UiActionTypes.LOADING_UI_START:
			return {
				...state,
				loading: true
			}
		case UiActionTypes.LOADING_UI_FINISH:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export default uiReducer
