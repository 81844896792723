import React from "react"
import { DownOutlined } from "@ant-design/icons"
import { Button, Dropdown, Typography, Space } from "antd"
import { Link } from "react-router-dom"
import styled from "styled-components"

const { Title } = Typography

const DashboardHeaderContainer = styled.div`
	display: block;
	margin-bottom: 30px;
	margin-top: 30px;
	box-sizing: border-box;
	width: 100%;
	min-width: 260px;
	@media (min-width: 590px) {
		width: ${({ smWidth }) => (smWidth ? smWidth : "520px")};
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: ${({ smWidth }) => (smWidth ? smWidth : "520px")};
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: ${({ laWidth }) => (laWidth ? laWidth : "780px")};
		max-width: 100%;
	}
`

const DashboardHeaderInner = styled.div`
	box-sizing: inherit;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding-bottom: 20px;
	-webkit-box-align: center;
	align-items: center;
	display: flex;
	border-bottom: 1px solid rgb(213, 220, 227);
	border-color: rgb(213, 220, 227);
`

const ButtonContainer = styled.div`
	position: relative;
	z-index: 10;
	box-sizing: border-box;
`

const DashboardHeaderButton = ({
	title,
	titleComponent,
	buttonTitle,
	buttonLink,
	secondaryButtonTitle,
	secondaryButtonOnClick,
	smWidth,
	laWidth,
	onClick,
	dropDown,
	menu
}) => {
	return (
		<DashboardHeaderContainer smWidth={smWidth} laWidth={laWidth}>
			<DashboardHeaderInner>
				{title && <Title level={3}>{title}</Title>}
				{titleComponent}
				{buttonTitle && buttonLink && (
					<ButtonContainer>
						<Space>
							{secondaryButtonTitle && secondaryButtonOnClick && (
								<Button shape="round" size="large" onClick={secondaryButtonOnClick}>
									{secondaryButtonTitle}
								</Button>
							)}
							<Link to={buttonLink}>
								<Button type="primary" shape="round" size="large">
									{buttonTitle}
								</Button>
							</Link>
						</Space>
					</ButtonContainer>
				)}
				{buttonTitle && onClick && (
					<ButtonContainer>
						<Button type="primary" shape="round" size="large" onClick={onClick}>
							{buttonTitle}
						</Button>
					</ButtonContainer>
				)}
				{buttonTitle && dropDown && (
					<Dropdown menu={{ items: menu }}>
						<Button shape="round">
							<Space>
								{buttonTitle}
								<DownOutlined />
							</Space>
						</Button>
					</Dropdown>
				)}
			</DashboardHeaderInner>
		</DashboardHeaderContainer>
	)
}

export default DashboardHeaderButton
