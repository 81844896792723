import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ColumnInstance, useBlockLayout, useResizeColumns, useTable } from "react-table"
import TableRow from "./TableRow"
import TableHeader from "./TableHeader"
import * as S from "./Table.styles"
import { FixedSizeList } from "react-window"
import scrollbarWidth from "../../../util/scrollbarWidth"
import InfiniteLoader from "react-window-infinite-loader"
import { Button } from "antd"

const TABLE_ID = "InfiniteScroll" + Math.random()
const DEFAULT_ROW_HEIGHT = 50
const LOADING_BUFFER = 5
const COLUMN_DEFAULTS = {
	minWidth: 30,
	width: 150,
	maxWidth: 400
}

interface Props<Item> {
	columns: any
	items: Item[]
	fetchMoreItems: () => void
	hasMore: boolean
	tableHeight: number
	rowHeight?: number
	hiddenColumns: string[]	//	Columns that start hidden by default
	fixedColumns?: any[]	//	Columns that can't be toggled
	leftOptions?: React.ReactNode
	rightOptions?: React.ReactNode
}

interface RenderRowProps {
	index: number
	style: any
}

export default function InfiniteScrollTable<Item>({
	columns,
	items,
	fetchMoreItems,
	hasMore,
	tableHeight,
	rowHeight = DEFAULT_ROW_HEIGHT,
	hiddenColumns,
	fixedColumns,
	leftOptions,
	rightOptions
}: Props<Item>) {
	const [columnListIsVisible, setColumnListIsVisible] = useState<boolean>(false)
	const [headerHeight, setHeaderHeight] = useState<number>(0)

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth, allColumns } =
		useTable(
			{ columns, data: items as object[], defaultColumn: COLUMN_DEFAULTS, initialState: { hiddenColumns } },
			useBlockLayout,
			useResizeColumns
		)

	const toggleColumnListIsVisible = () => setColumnListIsVisible(!columnListIsVisible)

	const ToggleColumns = allColumns.filter((column) => !(fixedColumns || []).includes(column.id)).map(Toggle)

	const RenderRow = useCallback(
		({ index, style }: RenderRowProps) => {
			return <TableRow prepareRow={prepareRow} row={rows[index]} style={style} />
		},
		[prepareRow, rows]
	)

	const scrollBarSize = useMemo(() => scrollbarWidth(), [])
	const isItemLoaded = (index: number) => !hasMore || index < items.length - LOADING_BUFFER
	const headerRef = useRef<HTMLDivElement>()

	useEffect(() => {
		setHeaderHeight(headerRef.current?.clientHeight || 0)
	}, [])

	const Options = (
		<S.OptionsContainerOuter>
			<S.OptionsContainerInner>
				<S.Flexbox>{leftOptions}</S.Flexbox>

				<S.Flexbox>
					<Button onClick={toggleColumnListIsVisible}>Change columns</Button>
					{rightOptions}
				</S.Flexbox>
			</S.OptionsContainerInner>

			{columnListIsVisible && <S.ToggleColumnsContainer>{ToggleColumns}</S.ToggleColumnsContainer>}
		</S.OptionsContainerOuter>
	)

	const Table = (
		<S.TableOverflowHolder id={TABLE_ID} style={{ height: tableHeight }}>
			<S.Table {...getTableProps()}>
				<TableHeader headerGroups={headerGroups} headerRef={headerRef} />
				<S.Tbody {...getTableBodyProps()}>
					<InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={fetchMoreItems} itemCount={rows.length}>
						{({ onItemsRendered, ref }) => (
							<FixedSizeList
								height={tableHeight - headerHeight - scrollBarSize}
								itemCount={rows.length}
								itemSize={rowHeight}
								width={totalColumnsWidth + scrollBarSize}
								onItemsRendered={onItemsRendered}
								ref={ref}
							>
								{RenderRow}
							</FixedSizeList>
						)}
					</InfiniteLoader>
				</S.Tbody>
			</S.Table>
		</S.TableOverflowHolder>
	)

	return (
		<>
			{Options}
			{Table}
		</>
	)
}

function Toggle(column: ColumnInstance) {
	return (
		<S.ToggleDiv key={column.id}>
			<S.Checkbox type="checkbox" {...column.getToggleHiddenProps()} /> {column.id}
		</S.ToggleDiv>
	)
}
