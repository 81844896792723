import React from "react"
import styled from "styled-components"

import { borderRadiusSettings } from "../styling"
import AutoSizeText from "../elements/AutoSizeText"
import Icon from "../elements/Icon"

const FlexColumnCentred = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const FlexColumnSpaceBetween = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	align-content: center;
`

const iconDimensions = {
	small: {
		width: 0.25,
		height: 0.18
	},
	medium: {
		width: 0.4,
		height: 0.25
	},
	large: {
		width: 0.4,
		height: 0.35
	}
}

const textboxDimensions = {
	small: {
		width: 0.6,
		height: 0.33
	},
	medium: {
		width: 0.6,
		height: 0.28
	},
	large: {
		width: 0.6,
		height: 0.18
	}
}

const getIconDimensions = (iconSize, width) => {
	const iconScale = iconDimensions[iconSize] || { width: 0, height: 0 }
	return { width: iconScale.width * width, height: iconScale.height * width }
}

const getTextboxDimensions = (iconSize, width) => {
	const textboxScale = textboxDimensions[iconSize] || {
		width: 0.6,
		height: 0.4
	}
	return {
		width: textboxScale.width * width,
		height: textboxScale.height * width
	}
}

const OneElement = ({
	cardId,
	shape,
	iconPlacement,
	iconSize,
	elements,
	width,
	height,
	editable,
	handleUpdateText
}) => {
	return (
		<FlexColumnCentred style={{ width, height, zIndex: 9 }}>
			<FlexColumnSpaceBetween
				style={{
					width: 0.75 * width,
					height: 0.65 * height
				}}
			>
				<FlexColumnCentred
					style={{
						width: 0.75 * width,
						height: 0.65 * height,
						borderRadius: borderRadiusSettings[shape] || 0,
						backgroundColor: "white"
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: iconPlacement === "bottom" ? "column-reverse" : "column",
							justifyContent: iconPlacement && iconPlacement !== "none" ? "space-between" : "center",
							alignContent: "center",
							alignItems: "center",
							width: 0.6 * width,
							height: 0.56 * width
						}}
					>
						{iconPlacement && iconPlacement !== "none" && (
							<Icon
								cardId={cardId}
								element={elements[0]}
								width={getIconDimensions(iconSize, width).width}
								height={getIconDimensions(iconSize, width).height}
								index={0}
							/>
						)}
						<AutoSizeText
							width={getTextboxDimensions(iconSize, width).width}
							height={getTextboxDimensions(iconSize, width).height}
							textAlign="center"
							text={elements[0].text}
							editable={editable}
							handleUpdateText={(text) => handleUpdateText(text, 0)}
							id="call_out_card_text_0"
						/>
					</div>
				</FlexColumnCentred>
			</FlexColumnSpaceBetween>
		</FlexColumnCentred>
	)
}

export default OneElement
