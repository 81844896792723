import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Select, Space, Tag } from "antd"

import { selectOrgUserGroupColorsMap } from "../../redux/org/org.selectors"

const UserGroupFilter = ({ column: { filterValue, setFilter, preFilteredRows, id }, userGroupColors }) => {
	let options = React.useMemo(() => {
		let options = [
			{
				value: "none",
				label: "none"
			}
		]

		const userGroups = preFilteredRows.map((item) => item.values.userGroupNames)
		const flattenArray = userGroups.flat(1)
		let uniqueUserGroups = [...new Set(flattenArray)]
		uniqueUserGroups.forEach((row) => {
			if (row !== "registered" && row !== null) {
				options.push({
					value: row,
					label: row
				})
			}
		})
		return options
	}, [preFilteredRows])

	const tagRender = (props) => {
		const { label, value, onClose } = props
		const onPreventMouseDown = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		return (
			<Tag
				color={userGroupColors[value] ? userGroupColors[value] : null}
				onMouseDown={onPreventMouseDown}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		)
	}

	return (
		<Space
			direction="vertical"
			style={{
				marginTop: 4,
				marginRight: 8,
				marginBottom: 12,
				marginLeft: 8,
				width: "calc(100% - 20px)"
			}}
		>
			<Select
				showSearch
				mode="multiple"
				showArrow
				style={{ width: "100%" }}
				value={filterValue}
				options={options}
				onChange={(e) => {
					setFilter(e)
				}}
				optionFilterProp="children"
				filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
				maxTagCount="responsive"
				tagRender={tagRender}
			/>
		</Space>
	)
}

const mapStateToProps = createStructuredSelector({
	userGroupColors: selectOrgUserGroupColorsMap
})

export default connect(mapStateToProps)(UserGroupFilter)
