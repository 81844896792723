import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const CourseStatus = styled.div`
	width: auto;
	font-size: 13px;
	border-radius: 5px;
	line-height: 30px;
	padding: 0px 6px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	color: ${({ isPreviewable, isPublished, isArchived }) => handleColor(isPreviewable, isPublished, isArchived)};
	border: ${({ isPreviewable, isPublished, isArchived }) =>
		`solid 1px ${handleColor(isPreviewable, isPublished, isArchived)}`};
`

const handleColor = (isPreviewable, isPublished, isArchived) => {
	if (isArchived) return "var(--dark-grey)"
	if (isPreviewable && !isPublished) return "var(--dark-blue)"
	if (isPublished) return "var(--dark-green)"
	else return "var(--mid-orange)"
}
const handleText = (isPreviewable, isPublished) => {
	if (isPreviewable && !isPublished) return "Preview Available"
	if (isPublished) return "Published"
	else return "In Development"
}

// for showing "In Development", "Preview Available" or "Published" text on course card...
const CbCourseStatusLabel = ({ isPreviewable, isPublished, isArchived }) => {
	if (isArchived) {
		return <CourseStatus isArchived={isArchived}>Archived</CourseStatus>
	} else {
		return (
			<CourseStatus isPreviewable={isPreviewable} isPublished={isPublished}>
				{handleText(isPreviewable, isPublished)}
			</CourseStatus>
		)
	}
}

CbCourseStatusLabel.propTypes = {
	isPreviewable: PropTypes.bool.isRequired,
	isPublished: PropTypes.bool.isRequired
}

CbCourseStatusLabel.defaultProps = {
	isPreviewable: false,
	isPublished: false
}

export default CbCourseStatusLabel
