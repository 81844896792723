import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input, Button, Collapse } from "antd"
import Picker from "emoji-picker-react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { faIcons, iconLibrary } from "../imageMap/iconMap"

import { selectStage } from "../../../../redux/courses/courses.selectors"
import { updateLessonCard } from "../../../../redux/courses/courses.actions"

import PanelContainer from "./common/PanelContainer"
import { CallOutCard } from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"
import { ImageCard } from "learnink-common/lib/domains/cards/lessons/image/ImageCard.model"
import SegmentSetting from "./common/SegmentSetting"

const { Search } = Input
const { Panel } = Collapse

const LibraryContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 260px;
	overflow-y: scroll;
	align-content: flex-start;
	padding: 0px;
	margin: -9px;
`
const IconContainer = styled.div`
	padding: 8px;
	:hover {
		background-color: var(--v-light-grey);
	}
`

interface Props {
	card: CallOutCard | ImageCard
	stage: any
	updateLessonCard: (updateParams: CallOutCard) => void
}

const ImageLibrary = ({ card, stage, updateLessonCard }: Props) => {
	const [searchText, setSearchText] = useState("")
	const [graphicsLibrary, setGraphicsLibrary] = useState([])
	const [graphicsPointer, setGraphicsPointer] = useState(30)

	const getGraphicsLibrary = async () => {
		const response = await fetch("https://learnink-image-library.s3.eu-west-2.amazonaws.com/graphicsLibrary.json")
		const data = await response.json()
		setGraphicsLibrary(data)
	}

	useEffect(() => {
		getGraphicsLibrary()
	}, [])

	const updateImage = (type: "imgUrl" | "iconId" | "emoji", value: string) => {
		if (card.type === "callOut") {
			let newElements = card.elements || []
			delete newElements[stage.editor.imageIndex].iconId
			delete newElements[stage.editor.imageIndex].imgUrl
			delete newElements[stage.editor.imageIndex].emoji
			newElements[stage.editor.imageIndex][type] = value
			updateLessonCard({ ...card, elements: newElements })
		}
	}

	return (
		<>
			{card.type === "callOut" && (card.elements || []).length === 1 && (
				<PanelContainer title="Icon size">
					<SegmentSetting
						onChange={(val: "small" | "medium" | "large") => updateLessonCard({ ...card, iconSize: val })}
						value={card.iconSize}
						id="editor_icon_size_select"
						options={[
							{
								label: "Small",
								value: "small",
								icon: (
									<div
										style={{
											borderRadius: 99,
											width: 12,
											height: 12,
											background: "#D9D9D9"
										}}
									/>
								)
							},
							{
								label: "Medium",
								value: "medium",
								icon: (
									<div
										style={{
											borderRadius: 99,
											width: 20,
											height: 20,
											background: "#D9D9D9"
										}}
									/>
								)
							},
							{
								label: "Large",
								value: "large",
								icon: (
									<div
										style={{
											borderRadius: 99,
											width: 28,
											height: 28,
											background: "#D9D9D9"
										}}
									/>
								)
							}
						]}
					/>
				</PanelContainer>
			)}

			<PanelContainer title="Icon library">
				<Collapse>
					<Panel header="Icons" key="icons">
						<LibraryContainer>
							{iconLibrary
								.filter((item) => item.type === "faIcon")
								.map((item, index) => (
									<IconContainer
										key={index}
										style={{ fontSize: 24, lineHeight: "24px" }}
										onClick={() => updateImage("iconId", item.iconId)}
									>
										{/* @ts-ignore */}
										<FontAwesomeIcon icon={faIcons[item.iconId]} />
									</IconContainer>
								))}
						</LibraryContainer>
					</Panel>
					<Panel header="Emojis" key="emojis">
						<>
							<LibraryContainer style={{ height: 330, overflowY: "hidden" }}>
								<Picker
									disableAutoFocus
									onEmojiClick={(event, emojiObject) => updateImage("emoji", emojiObject.emoji)}
								/>
							</LibraryContainer>
						</>
					</Panel>
					<Panel header="Graphics" key="graphics">
						<>
							<Search
								placeholder="Enter keywords to search..."
								onChange={(e) => setSearchText(e.target.value)}
								style={{ width: "100%", marginBottom: 20 }}
								id="editor_graphics_search"
							/>
							<LibraryContainer>
								{graphicsLibrary
									// @ts-ignore
									.filter((item) => item.type === "graphic")
									// @ts-ignore
									.filter((item) => item.tags.toString().includes(searchText))
									.slice(0, graphicsPointer)
									.map((item, index) => (
										<IconContainer
											key={index}
											// @ts-ignore
											onClick={() => updateImage("imgUrl", item.imgUrl)}
											id={`editor_graphics_item_${index}`}
										>
											<img
												style={{
													width: 50,
													height: 50
												}}
												// @ts-ignore
												src={item.imgUrl}
												// @ts-ignore
												alt={item.imgUrl}
											/>
										</IconContainer>
									))}
							</LibraryContainer>
							{graphicsPointer < graphicsLibrary.length && (
								<div
									style={{
										display: "flex",
										width: "100%",
										justifyContent: "center",
										paddingTop: 10
									}}
								>
									<Button
										onClick={() => setGraphicsPointer(graphicsPointer + 30)}
										shape="round"
										size="small"
									>
										Load more
									</Button>
								</div>
							)}
						</>
					</Panel>
				</Collapse>
			</PanelContainer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: CallOutCard | ImageCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageLibrary)
