import React from "react"
import styled from "styled-components"

const FixedRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 590px) {
		flex-direction: column;
	}
`
const ColumnCheckBoxesContainer = styled.div`
	display: flex;
	margin-top: 20px;
	width: 100%;
	flex-wrap: wrap;
`
const ColumnCheckBoxesAllToggle = styled.button`
	padding: 6px;
	font-size: 14px;
	color: var(--dark-grey);
	font-weight: 600;
`
const ColumnCheckBoxContainer = styled.div`
	padding: 6px;
	font-size: 14px;
	color: var(--mid-grey);
`

const ColumnFilter = ({ allColumns, setHiddenColumns }) => {
	return (
		<FixedRow>
			<ColumnCheckBoxesContainer>
				<ColumnCheckBoxesAllToggle
					onClick={() => {
						// if any column has been made invisible then show all
						if (allColumns.filter((column) => !column.isVisible).length > 0) {
							setHiddenColumns([]) // function shows all columns when provided empty array
						}
						// if all columns are selected hide all columns except selection, edit and userId fields
						else {
							setHiddenColumns(
								allColumns
									.filter((column) => column.id !== "selection" && column.id !== "edit")
									.map((column) => column.id)
							)
						}
					}}
				>
					{allColumns.filter((column) => !column.isVisible).length > 0 ? "Toggle All On" : "Toggle All Off"}
				</ColumnCheckBoxesAllToggle>

				{allColumns
					.filter((column) => column.id !== "selection" && column.id !== "edit")
					.map((column) => (
						<ColumnCheckBoxContainer key={column.id}>
							<label>
								<input type="checkbox" {...column.getToggleHiddenProps()} /> {column.Header}
							</label>
						</ColumnCheckBoxContainer>
					))}
			</ColumnCheckBoxesContainer>
		</FixedRow>
	)
}

export default ColumnFilter
