import React, { useEffect } from "react"
import { connect } from "react-redux"

import Loading from "../../components/elements/Loading"

import { getModuleQuizData } from "../../redux/learn/learn.actions"
import PreviewFrame from "./PreviewFrame"
import { FrontendCardArray } from "learnink-common/lib/domains/cards/legacy/FrontendCard.model"

export function QuizPreview({ org, moduleStageId, learn: { quizPreviewData }, ui: { loading }, getModuleQuizData }) {
	useEffect(() => {
		getModuleQuizData(moduleStageId)
	}, [getModuleQuizData, moduleStageId])

	if (loading === true) return <Loading />

	if (quizPreviewData) {
		const languageCodes = new FrontendCardArray(quizPreviewData).getAvailableLanguages()
		return (
			<PreviewFrame
				type="quiz"
				lessonContent={null}
				questionContent={quizPreviewData}
				activeOrgData={org.orgData}
				languageCodes={languageCodes}
			/>
		)
	} else {
		return <Loading />
	}
}

const mapStateToProps = (state) => {
	return {
		learn: state.learn,
		ui: state.ui,
		org: state.org
	}
}

const mapActionsToProps = {
	getModuleQuizData: getModuleQuizData
}

export default connect(mapStateToProps, mapActionsToProps)(QuizPreview)
