export const isDate = /([0-9]{4}-[0-9]{2}-[0-9]{2})/

export const isJsonString = (str) => {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}
