import React from "react"
import { Button, DatePicker, InputNumber, Select, Space, Tag } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { COLUMN_LABEL_BY_MEMBER, Filter, FilterOption, OPERATOR_LABEL, Option } from "./UserSearch.model"
import { Operator } from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { NonEmptyArray } from "zod-validation-error/dist/types/utils/NonEmptyArray"
import { CloseOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { operatorRequiresValue } from "./UserSearchFilters"
import moment from "moment"

const S = {
	ActiveFilterContainer: styled.div`
		border: solid 1px var(--light-grey);
		padding: 6px;
		display: inline-block;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	`,
	ActiveFilterInnerContainer: styled.div`
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	`
}

interface Props {
	filterOption: FilterOption
	setFilterOption: (filterOption: FilterOption) => void
	isFirstFilter: boolean
	setFilter: (filter: Filter) => void
	removeFilter: () => void
}

export default function ActiveFilter({ filterOption, isFirstFilter, setFilterOption, setFilter, removeFilter }: Props) {
	const canCreateFilter =
		filterOption?.member &&
		filterOption?.selectedOperator &&
		((filterOption?.values && filterOption?.values.length > 0) ||
			!operatorRequiresValue(filterOption?.selectedOperator))

	function toOption(value: string | number) {
		return { value: value.toString(), label: value.toString() }
	}

	const setListInput = (value: string) => {
		const values = value
			.replace(/\n/g, ",")
			.split(",")
			.map((item) => item.trim())
			.filter((item) => item.length > 0)
			.map(toOption)

		setFilterOption({ ...filterOption, values })
	}

	function setMultipleSelectInput(value: string): Option {
		const options = (filterOption.options || []).filter((option) => option.value === value)
		if (options.length > 1) throw new Error("Duplicated options...")
		if (options.length === 0) throw new Error("No options found...")
		return { value, label: options[0].label }
	}

	const FilterOperatorSelect = (
		<Select
			style={{ width: 160 }}
			defaultValue={filterOption.selectedOperator}
			options={getOperatorOptions(filterOption.operators)}
			onChange={(selectedOperator) => setFilterOption({ ...filterOption, selectedOperator })}
		/>
	)

	const FilterDatePicker = (
		<DatePicker
			defaultValue={filterOption.values?.[0]?.value ? moment(filterOption.values?.[0]?.value) : undefined}
			onChange={(date, dateString) => setFilterOption({ ...filterOption, values: [toOption(dateString)] })}
		/>
	)

	const FilterNumberInput = (
		<InputNumber
			onChange={(value) => {
				if (!value) return

				setFilterOption({
					...filterOption,
					values: [toOption(value)]
				})
			}}
		/>
	)

	const FilterListInput = (
		<TextArea
			onChange={(e) => {
				setListInput(e.target.value)
			}}
			defaultValue={filterOption.values?.[0]?.value as string}
			autoSize={{ minRows: 1, maxRows: 6 }}
		/>
	)

	const availableMultipleSelectFilters = (input: string, option: Option | undefined): boolean => {
		return (
			!!option &&
			!!option.value &&
			(option.label.toString().toLowerCase().includes(input.toLowerCase()) ||
				option.value.toString().toLowerCase().includes(input.toLowerCase()))
		)
	}

	const FilterMultipleSelect = (
		<Select
			showSearch
			mode="multiple"
			showArrow={true}
			style={{ width: 300 }}
			options={filterOption.options}
			onChange={(valueKeys: string[] | undefined) => {
				const values = valueKeys?.map(setMultipleSelectInput)
				setFilterOption({ ...filterOption, values })
			}}
			defaultValue={filterOption.values?.filter((o) => o).map((o) => o.value) as string[] | undefined}
			filterOption={availableMultipleSelectFilters}
		/>
	)

	function buildFilter(): Filter {
		return {
			member: filterOption.member,
			operator: filterOption.selectedOperator,
			values: operatorRequiresValue(filterOption.selectedOperator)
				? (filterOption.values?.map((v) => v.value) as NonEmptyArray<string>)
				: [""],
			inputType: filterOption.inputType,
			valueLabels: filterOption.values?.map((v) => v.label)
		} as Filter
	}

	const AddFilterButton = (
		<Button
			type="primary"
			onClick={() => {
				setFilter(buildFilter())
			}}
		>
			Add
		</Button>
	)

	const shouldShowValue = operatorRequiresValue(filterOption.selectedOperator)

	return (
		<Container>
			{isFirstFilter ? <Tag color="blue">WHERE</Tag> : <Tag color="volcano">AND</Tag>}

			{COLUMN_LABEL_BY_MEMBER[filterOption.member]}

			{filterOption.operators && FilterOperatorSelect}

			{shouldShowValue && filterOption.inputType === "datePicker" && FilterDatePicker}
			{shouldShowValue && filterOption.inputType === "numberInput" && FilterNumberInput}
			{shouldShowValue && filterOption.inputType === "stringInput" && FilterListInput}
			{shouldShowValue && filterOption.inputType === "selectMultiple" && FilterMultipleSelect}

			{canCreateFilter && AddFilterButton}

			<Button icon={<CloseOutlined />} onClick={removeFilter} />
		</Container>
	)
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<S.ActiveFilterContainer>
			<S.ActiveFilterInnerContainer>
				<Space>{children}</Space>
			</S.ActiveFilterInnerContainer>
		</S.ActiveFilterContainer>
	)
}

function getOperatorOptions(operators: Operator[]): Option[] {
	return (operators || []).map((operator) => ({
		value: operator,
		label: OPERATOR_LABEL[operator]
	}))
}
