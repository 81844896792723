import React, { useMemo } from "react"

import QuestionsSummaryTableWithPanels from "./questionsTable/QuestionsSummaryTableWithPanels"

const QuestionsDashInsights = ({ orgId, queryData, filteredQuestionContent }) => {
	const questionTableColumns = useMemo(
		() => [
			{
				Header: "Question id",
				accessor: "questionId"
			},
			{
				Header: "Question",
				accessor: "questionText",
				width: 500
			},
			{
				Header: "# baseline answers",
				accessor: "numBaselineAnswers"
			},
			{
				Header: "# baseline correct",
				accessor: "numBaselineCorrect"
			},
			{
				Header: "Av baseline accuracy",
				accessor: "baselineAccuracy"
			},
			{
				Header: "# endline answers",
				accessor: "numEndlineAnswers"
			},
			{
				Header: "# endline correct",
				accessor: "numEndlineCorrect"
			},
			{
				Header: "Av endline accuracy",
				accessor: "endlineAccuracy"
			}
		],
		[]
	)

	const tableData = []
	filteredQuestionContent.forEach((question) => {
		if (queryData.filter((item) => item.questionId === question.card_id).length > 0) {
			const questionData = queryData.filter((item) => item.questionId === question.card_id)
			const numBaselineAnswers = questionData
				.filter((item) => item.stage === "baseline")
				.map((item) => item.count)
				.reduce((partialSum, a) => partialSum + a, 0)
			const numBaselineCorrect = questionData
				.filter((item) => item.stage === "baseline" && item.isCorrect)
				.map((item) => item.count)
				.reduce((partialSum, a) => partialSum + a, 0)
			const baselineAccuracy =
				numBaselineAnswers > 0 ? `${Math.round((numBaselineCorrect * 100) / numBaselineAnswers)}%` : "--"
			const numEndlineAnswers = questionData
				.filter((item) => item.stage === "endline")
				.map((item) => item.count)
				.reduce((partialSum, a) => partialSum + a, 0)
			const numEndlineCorrect = questionData
				.filter((item) => item.stage === "endline" && item.isCorrect)
				.map((item) => item.count)
				.reduce((partialSum, a) => partialSum + a, 0)
			const endlineAccuracy =
				numEndlineAnswers > 0 ? `${Math.round((numEndlineCorrect * 100) / numEndlineAnswers)}%` : "--"

			tableData.push({
				questionId: question.card_id,
				questionText: question.content.question_text,
				numBaselineAnswers,
				numBaselineCorrect,
				baselineAccuracy,
				numEndlineAnswers,
				numEndlineCorrect,
				endlineAccuracy
			})
		} else {
			tableData.push({
				questionId: question.card_id,
				questionText: question.content.question_text,
				numBaselineAnswers: 0,
				numBaselineCorrect: 0,
				baselineAccuracy: "--",
				numEndlineAnswers: 0,
				numEndlineCorrect: 0,
				endlineAccuracy: "--"
			})
		}
	})
	return (
		<QuestionsSummaryTableWithPanels
			data={tableData}
			queryData={queryData}
			columns={questionTableColumns}
			filteredQuestionContent={filteredQuestionContent}
			tooltip="Select the questions you want to further inspect using the checkboxes next to them in the table"
		/>
	)
}

export default QuestionsDashInsights
