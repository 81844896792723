// MODAL TO CREATE NEW LESSON CARD

import { Avatar } from "antd"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 35px;
`
const TextBox = styled.div`
	border-radius: 0px 10px 10px 10px;
	background-color: white;
	border: 1.5px solid dodgerblue;
	padding: 7px;
	width: 90px;
	margin-left: 5px;
	font-size: 10px;
	line-height: 13px;
`

const Text = ({ teacherImg }) => {
	return (
		<Container>
			<Avatar src={teacherImg} size={20} />
			<TextBox>Your message goes here</TextBox>
		</Container>
	)
}

export default Text
