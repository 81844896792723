import React, { useState } from "react"
import styled from "styled-components"
import { Input, Popover, Typography } from "antd"
import Picker from "emoji-picker-react"
import { SmileOutlined } from "@ant-design/icons"
import { generateTextBoxStyle } from "./styles"
import { CardType } from "learnink-common/lib/domains/cards/Card.model"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"

const { TextArea } = Input
const { Text } = Typography

const SmileyContainer = styled.div`
	padding: 4px;
`
const TextBoxAsButton = styled.div`
	position: relative;
	width: 100%;
	cursor: pointer;
	transition: opacity 0.3s ease;
	:hover {
		opacity: 0.8;
	}
`

const emojiSkinToneMap = {
	tneutral: "SKIN_TONE_NEUTRAL",
	t1f3fb: "SKIN_TONE_LIGHT",
	t1f3fc: "SKIN_TONE_MEDIUM_LIGHT",
	t1f3fd: "SKIN_TONE_MEDIUM",
	t1f3fe: "SKIN_TONE_MEDIUM_DARK",
	t1f3ff: "SKIN_TONE_DARK"
}

interface Props {
	type?: CardType | "url"
	from?: TeacherOrLearner
	initialText: string
	setNewText: any
	placeholderText: string
	textAlign?: "left" | "center" | "right"
	enableEmojiPicker?: boolean
}

const EditableTextBox = ({
	type,
	from,
	initialText,
	setNewText,
	placeholderText,
	textAlign,
	enableEmojiPicker
}: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [text, setText] = useState(initialText)
	const [showEmojiPicker, setShowEmojiPicker] = useState(false)

	const focusElement = () => {
		// @ts-ignore
		document.getElementById("cardTextBox").focus()
		setIsOpen(true)
	}

	const submitTitle = (e: any) => {
		setNewText(text)
		if (e && e.relatedTarget && e.relatedTarget.id && e.relatedTarget.id === "emoji-picker") {
			focusElement()
			setShowEmojiPicker(true)
		} else if (
			e &&
			e.relatedTarget &&
			e.relatedTarget.id &&
			Object.keys(emojiSkinToneMap).includes(e.relatedTarget.id)
		) {
			focusElement()
		} else if (e && e.relatedTarget && e.relatedTarget.className === "emoji-search") {
			focusElement()
		} else if (e && e.relatedTarget && e.relatedTarget.type && e.relatedTarget.type === "button") {
			focusElement()
		} else {
			setTimeout(() => {
				setIsOpen(false)
				setShowEmojiPicker(false)
			}, 200)
		}
	}
	const revertTitle = () => {
		setText(initialText)
		setIsOpen(false)
	}
	const handleKeyDown = (event: any) => {
		if (event.keyCode === 13 && !event.shiftKey) {
			event.preventDefault()
			submitTitle(undefined)
		} else if (event.keyCode === 27) {
			revertTitle()
		}
	}

	if (isOpen)
		return (
			<div style={{ position: "relative", width: "100%" }}>
				<TextBoxAsButton
					style={{ ...generateTextBoxStyle(type, from), background: type === "image" ? "none" : "white" }}
				>
					<TextArea
						id="cardTextBox"
						onKeyDown={handleKeyDown}
						onChange={(e) => setText(e.target.value)}
						onBlur={(e) => submitTitle(e)}
						onFocus={(e) => e.target.setSelectionRange(text.length, text.length)}
						autoSize
						autoFocus
						placeholder={placeholderText}
						value={text}
						className="cardInput"
						style={{
							lineHeight: 1.4,
							margin: 0,
							width: "100%",
							whiteSpace: "pre-wrap",
							background: "transparent",
							borderWidth: 0,
							textAlign: textAlign || "left"
						}}
					>
						{text}
					</TextArea>
				</TextBoxAsButton>
				{enableEmojiPicker && (
					<SmileyContainer>
						<Popover
							content={
								<Picker
									disableAutoFocus
									onEmojiClick={(e, emojiObject) => setText(text + emojiObject.emoji)}
								/>
							}
							open={showEmojiPicker}
							trigger="click"
							placement="bottomLeft"
						>
							<button id="emoji-picker" onClick={() => null}>
								<SmileOutlined
									style={{
										color: showEmojiPicker ? "var(--secondary-color)" : "grey"
									}}
								/>
							</button>
						</Popover>
					</SmileyContainer>
				)}
			</div>
		)

	return (
		<TextBoxAsButton onClick={() => setIsOpen(true)} style={generateTextBoxStyle(type, from)}>
			<div
				style={{
					lineHeight: 1.4,
					margin: 0,
					whiteSpace: "pre-wrap",
					background: "transparent",
					borderWidth: 0,
					color: "black",
					padding: !type ? "0px" : "4px 11px 4px 11px",
					textAlign: textAlign || "left"
				}}
				onClick={() => setIsOpen(true)}
			>
				{text !== "" ? text : <Text type="secondary">{placeholderText}</Text>}
			</div>
		</TextBoxAsButton>
	)
}

export default EditableTextBox
