// BUTTON TO EXPORT TO CSV

import React, { useRef } from "react"
import { CSVLink } from "react-csv"
import { Button } from "antd"

const TableExportButton = ({ data, filename }) => {
	const csvDownloadRef = useRef(null) // used for refering to CSVLink component below...

	const handleDownload = () => {
		setTimeout(() => {
			csvDownloadRef.current.link.click()
		}, 200)
	}

	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				right: 0,
				padding: 6
			}}
		>
			<Button shape="round" size="small" onClick={handleDownload}>
				Export
			</Button>
			<CSVLink
				ref={csvDownloadRef}
				data={data}
				filename={`${filename}_${Date.now()}.csv`}
				style={{ display: "none" }}
				target="_blank"
			/>
		</div>
	)
}

export default TableExportButton
