// DRAGGABLE STAGE CARD
// FIX THIS - learning objective component not updating on edit

import React, { useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { Typography, Button, Dropdown, Menu } from "antd"

import { selectCourseId, selectObjectives, selectModuleId } from "../../../../redux/courses/courses.selectors"
import { selectOrgId } from "../../../../redux/org/org.selectors"
import { updateStageInfo } from "../../../../redux/courses/courses.actions"

import { MIN_LESSON_CARDS, MIN_QUESTION_CARDS } from "../../../../config/course.config"

import CbModalEditObjective from "../../stage/modals/CbModalEditObjective"
import MoveToModule from "../modals/MoveToModule"
import DeleteStage from "../modals/DeleteStage"
import TooltipIcon from "../../../common/TooltipIcon"
import LpModal from "../../../common/LpModal"

import LessonIcon from "../../../../assets/images/carousel_lesson.png"
import QuizIcon from "../../../../assets/images/carousel_quiz.png"

const { Paragraph, Title, Text } = Typography

const MainCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: var(--dark-grey);
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  border: ${(props) =>
		props.isDragging
			? "1px solid var(--secondary-color);"
			: props.isComplete
			? "1px solid var(--light-grey);"
			: "1px solid var(--dark-red);"}
  box-shadow: ${(props) =>
		props.isDragging
			? "none"
			: props.isComplete
			? "0px 2px 4px var(--v-light-grey);"
			: "0px 2px 4px var(--v-light-grey);"}
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
		props.isDragging ? "var(--secondary-color-pale)" : props.isComplete ? "white" : "var(--light-red)"}
`
// Header items styling
const CardHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
`
const HeaderHandle = styled.div`
	flex: 1 1 10%;
	background-color: none;
`
const HeaderMenuButton = styled.button`
	background: none;
	padding-left: 20px;
	padding-right: 3px;
	color: var(--light-grey);
`
// Contains different card content types
const UnderHeaderContainer = styled.div`
	width: 100%;
	padding: 5px;
	display: flex;
	flex-direction: column;
`
const StageCardContainer = styled.div`
	width: 100%;
	background: #fff;
	border-radius: 10px;
	border: solid 1px #d8d8d8;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.22);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin-bottom: 10px;
`
const StageCardHeader = styled.div`
	width: 100%;
	border-bottom: solid 1px #d8d8d8;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding: 15px;
`
const StageIcon = styled.img`
	width: 30px;
	height: 30px;
`
const StageText = styled.div`
	flex: 1;
	font-size: 16px;
	font-weight: 600;
	padding-left: 10px;
	padding-right: 10px;
`
const StageCardFooter = styled.div`
	padding: 15px;
`

const CbStageCard = ({ card, index, objectives, org_id, course_id, module_id, updateStageInfo }) => {
	const [showMoveModal, setShowMoveModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const handleEditStageName = (updatedText) => {
		if (updatedText.trim().length > 0) {
			let new_info = {
				...card.info,
				name: updatedText
			}
			updateStageInfo(card.id, new_info)
		}
	}

	const dropDown = (
		<Menu
			items={[
				{
					key: "move_to_module",
					label: <div onClick={() => setShowMoveModal(true)}>Move to module</div>
				},
				{
					key: "delete",
					label: <div onClick={() => setShowDeleteModal(true)}>Delete</div>
				}
			]}
		/>
	)

	let isComplete = false
	// NOTE: user cannot set empty stage name or learning objective be design
	if (card.lesson_card_ids.length >= MIN_LESSON_CARDS && card.question_card_ids.length >= MIN_QUESTION_CARDS)
		isComplete = true

	return (
		<>
			<Draggable draggableId={card.id} index={index}>
				{(provided, snapshot) => (
					<MainCardContainer
						{...provided.draggableProps}
						ref={provided.innerRef}
						isDragging={snapshot.isDragging}
						isComplete={isComplete}
					>
						<CardHeader>
							<HeaderHandle {...provided.dragHandleProps}>
								<FontAwesomeIcon icon={faArrowsAlt} color="var(--light-grey)" />
							</HeaderHandle>

							<Dropdown overlay={dropDown} placement="bottomRight">
								<HeaderMenuButton>
									<FontAwesomeIcon icon={faEllipsisV} />
								</HeaderMenuButton>
							</Dropdown>
						</CardHeader>

						<UnderHeaderContainer>
							<Title
								level={5}
								editable={{
									onChange: (value) => handleEditStageName(value),
									enterIcon: null
								}}
								style={{ marginTop: 0, width: "100%", textAlign: "center" }}
							>
								{card.info.name}
							</Title>
							<StageCardContainer>
								<StageCardHeader>
									<StageIcon src={LessonIcon} />
									<StageText>Lesson</StageText>
									<Link
										to={{
											pathname: `/org/${org_id}/create/course/${course_id}/stage/${card.id}`,
											query: `?view=lesson`
										}}
									>
										<Button shape="round" type="primary">
											Edit
										</Button>
									</Link>
								</StageCardHeader>
								<StageCardFooter>
									{card.lesson_card_ids.length > 0 ? (
										<Text type="secondary">{card.lesson_card_ids.length} cards added</Text>
									) : (
										<>
											<Text type="danger">{card.lesson_card_ids.length} cards added</Text>{" "}
											<TooltipIcon
												tooltip="You need at least 1 card to complete your lesson"
												error
											/>
										</>
									)}
								</StageCardFooter>
							</StageCardContainer>
							<StageCardContainer>
								<StageCardHeader>
									<StageIcon src={QuizIcon} />
									<StageText>Quiz</StageText>
									<Link
										to={{
											pathname: `/org/${org_id}/create/course/${course_id}/stage/${card.id}`,
											query: `?view=questions`
										}}
									>
										<Button shape="round" type="primary">
											Edit
										</Button>
									</Link>
								</StageCardHeader>
								<StageCardFooter>
									{card.question_card_ids.length > 4 ? (
										<Text type="secondary">{card.question_card_ids.length} questions added</Text>
									) : (
										<>
											<Text type="danger">{card.question_card_ids.length} questions added</Text>{" "}
											<TooltipIcon
												tooltip="You need at least 5 questions to complete your quiz"
												error
											/>
										</>
									)}
								</StageCardFooter>
							</StageCardContainer>

							<Text type="secondary">
								Learning objective{" "}
								<TooltipIcon tooltip="A learning objective is a brief, clear, specific statement of what your target learners will be able to do at the end of a stage" />
							</Text>
							<Paragraph style={{ lineHeight: 1.2 }}>
								{objectives[card.objective_id].content.text}{" "}
								<CbModalEditObjective
									currentStageId={card.id}
									currentObjectiveId={card.objective_id}
									source="coursePage"
								/>
							</Paragraph>
						</UnderHeaderContainer>
					</MainCardContainer>
				)}
			</Draggable>
			<LpModal isShowingModal={showMoveModal} hideModal={() => setShowMoveModal(!showMoveModal)}>
				<MoveToModule closemodal={() => setShowMoveModal(false)} stage_id={card.id} module_id={module_id} />
			</LpModal>
			<LpModal isShowingModal={showDeleteModal} hideModal={() => setShowDeleteModal(!showDeleteModal)}>
				<DeleteStage closemodal={() => setShowDeleteModal(false)} stage_id={card.id} module_id={module_id} />
			</LpModal>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	objectives: selectObjectives,
	org_id: selectOrgId,
	course_id: selectCourseId,
	module_id: selectModuleId
})

const mapDispatchToProps = (dispatch) => ({
	updateStageInfo: (stage_id, new_info) => dispatch(updateStageInfo(stage_id, new_info))
})

// TODO: finish proptypes here!
CbStageCard.propTypes = {
	objectives: PropTypes.shape(PropTypes.obj).isRequired,
	org_id: PropTypes.string.isRequired,
	course_id: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CbStageCard)
