import React from "react"
import styled from "styled-components"
import { Avatar, Segmented } from "antd"
import { SegmentedLabeledOption, SegmentedValue } from "antd/lib/segmented"
import * as S from "../../../../styles/PageStyles"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const SegmentContainer = styled.div`
	padding: 4px;
	width: 73px;
`
const SegmentImg = styled.img`
	width: 32px;
	height: 32px;
	padding: 4px;
`

interface SegmentOption {
	icon?: any
	image?: any
	faIcon?: any
	circleColor?: string
	label: string
	value: string | number | boolean
	disabled?: boolean
}

interface Props {
	onChange: any
	value: SegmentedValue | undefined
	id: string
	options: SegmentOption[]
	style?: any
	imageStyle?: any
}

const SegmentSetting = ({ onChange, value, id, options, style, imageStyle }: Props) => {
	return (
		<Segmented
			onChange={onChange}
			value={value}
			id={id}
			style={style}
			options={options.map((item) => {
				return {
					label: (
						<SegmentContainer>
							{item.image && <SegmentImg src={item.image} style={imageStyle || {}} />}
							{item.faIcon && <FontAwesomeIcon icon={item.faIcon as IconProp} style={S.editorIcon} />}
							{item.icon && item.icon}
							{item.circleColor && (
								<Avatar style={{ backgroundColor: item.circleColor }}>{item.value}</Avatar>
							)}
							<div>{item.label}</div>
						</SegmentContainer>
					),
					value: item.value,
					disabled: !!item.disabled
				} as SegmentedLabeledOption
			})}
		/>
	)
}

export default SegmentSetting
