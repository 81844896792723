import { ResourcesActionTypes } from "./resources.types"

const INITIAL_STATE = {
	resourcesList: []
}

const resourcesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ResourcesActionTypes.SET_RESOURCE_LIST:
			return {
				...state,
				resourcesList: action.payload
			}

		case ResourcesActionTypes.CLEAR_RESOURCE_LIST:
			return {
				...state,
				resourcesList: []
			}

		case ResourcesActionTypes.UPDATE_RESOURCE_ITEM:
			const newResourcesList = state.resourcesList.map((item) => {
				let newItem = item
				// update the item that matches group_id
				if (item.resource_id === action.payload.resource_id) {
					newItem = action.payload
				}
				return newItem
			})
			return {
				...state,
				resourcesList: newResourcesList
			}

		case ResourcesActionTypes.CREATE_NEW_RESOURCE:
			return {
				...state,
				resourcesList: [...state.resourcesList, action.payload]
			}

		case ResourcesActionTypes.DELETE_RESOURCE:
			let removeId = action.payload.resource_id
			return {
				...state,
				resourcesList: state.resourcesList.filter((item) => item.resource_id !== removeId)
			}

		default:
			return state
	}
}

export default resourcesReducer
