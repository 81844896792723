import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Alert, Button, Space } from "antd"

import { selectOrgId, selectOrgUserGroups } from "../../../redux/org/org.selectors"
import { archiveUsers, unarchiveUsers } from "../../../redux/management/management.actions"

import GeTitle from "../../common/GeTitle"
import CbLoading from "../../common/CbLoading"

const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
`
const LabelContainer = styled.div`
	width: 100%;
`
const FlexRowContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const LabelText = styled.h1`
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	line-height: 24px;
	margin-right: 6px;
`
const UserDataContainer = styled.div`
	max-height: 180px;
	min-height: 50px;
	overflow-y: auto;
	overflow-x: hidden;
	border: solid 1px var(--light-grey);
	margin-top: 10px;
	margin-bottom: 10px;
`
const UserText = styled.p`
	font-size: 14px;
	margin-left: 12px;
	margin-top: 8px;
`
const Divider = styled.div`
	height: 32px;
	width: 100%;
`

const ArchiveUsersModal = ({
	orgId,
	usersData,
	archive,
	cancelModal,
	archiveUsers,
	unarchiveUsers,
	orgUserGroups,
	removeUsersFromList,
	getUsers
}) => {
	const [uiStatus, setUiStatus] = useState("default")
	const [responseData, setResponseData] = useState(null)

	useEffect(getUsers, [])

	// function to send notifcation to user API
	const handleArchiveUsers = async () => {
		setUiStatus("loading")
		try {
			const userIds = usersData.map((item) => item.userId)
			const data = archive
				? await archiveUsers(orgId, userIds)
				: await unarchiveUsers(orgId, userIds, orgUserGroups)
			const numArchivedUsers = data.filter((item) => item.registrationStatus === "archived").length
			const numUnarchivedUsers = data.filter((item) => item.registrationStatus !== "archived").length
			const response = {
				success: archive ? numArchivedUsers : numUnarchivedUsers,
				failed: archive ? numUnarchivedUsers : numArchivedUsers
			}
			if (!archive) {
				removeUsersFromList(userIds)
			}
			setUiStatus("response")
			setResponseData(response)
		} catch (err) {
			setUiStatus("error")
		}
	}

	return (
		<ModalInner>
			<>
				{uiStatus === "default" && (
					<>
						<GeTitle underline={false}>{archive ? "Archive" : "Unarchive"}</GeTitle>
						<LabelContainer>
							<FlexRowContainer style={{ justifyContent: "flex-start" }}>
								<LabelText>
									{archive ? (
										<>
											Are you sure you want to archive the following{" "}
											<span style={{ color: "var(--secondary-color)" }}>
												{usersData.length} users
											</span>
											?
										</>
									) : (
										<>
											Are you sure you want to unarchive the following{" "}
											<span style={{ color: "var(--secondary-color)" }}>
												{usersData.length} users
											</span>
											?
										</>
									)}
								</LabelText>
							</FlexRowContainer>
							<UserDataContainer>
								{usersData.slice(0, 20).map((x, index) => (
									<UserText style={{ color: "var(--secondary-color)" }} key={index}>
										• {x.customName}
									</UserText>
								))}
								{usersData.length > 20 && (
									<UserText style={{ color: "var(--secondary-color)" }} key="additional_users">
										+ {usersData.length - 20} more
									</UserText>
								)}
							</UserDataContainer>
						</LabelContainer>
						<LabelContainer>
							<Alert
								message={
									archive
										? "These users will no longer have access to your organisation"
										: "These users will be regain access to your organisation and count toward your active users"
								}
								type="warning"
							/>
						</LabelContainer>

						<Divider />

						<FlexRowContainer>
							<Space>
								<Button shape="round" size="large" onClick={() => cancelModal()}>
									Cancel
								</Button>
								<Button shape="round" size="large" type="primary" onClick={() => handleArchiveUsers()}>
									Proceed
								</Button>
							</Space>
						</FlexRowContainer>
					</>
				)}

				{uiStatus === "loading" && (
					<>
						<GeTitle underline={false}>Updating...</GeTitle>
						<CbLoading size={40} />
					</>
				)}

				{uiStatus === "response" && (
					<>
						<GeTitle underline={false}>Summary</GeTitle>
						<Space>
							{responseData && responseData.success > 0 && (
								<Alert message={`${responseData.success} users updated`} type="success" />
							)}

							{responseData && responseData.failed > 0 && (
								<Alert message={`${responseData.failed} users failed to update`} type="error" />
							)}
						</Space>
					</>
				)}

				{uiStatus === "error" && (
					<>
						<GeTitle underline={false}>Error</GeTitle>
						<Alert type="error" message="Apologies, something went wrong while updating" />
					</>
				)}
			</>
		</ModalInner>
	)
}

ArchiveUsersModal.propTypes = {
	orgId: PropTypes.string.isRequired,
	usersData: PropTypes.array.isRequired,
	archive: PropTypes.bool.isRequired,
	cancelModal: PropTypes.func.isRequired,
	archiveUsers: PropTypes.func.isRequired,
	unarchiveUsers: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups
})

const mapDispatchToProps = (dispatch) => ({
	archiveUsers: (orgId, userIds, orgUserGroups) => dispatch(archiveUsers(orgId, userIds, orgUserGroups)),
	unarchiveUsers: (orgId, userIds, orgUserGroups) => dispatch(unarchiveUsers(orgId, userIds, orgUserGroups))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveUsersModal)
