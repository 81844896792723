import { Avatar } from "antd"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 30px;
`
const TextBox = styled.div`
	border-radius: 0px 10px 10px 10px;
	background-color: white;
	border: 1.5px solid dodgerblue;
	padding: 7px;
	width: 90px;
	margin-left: 5px;
	font-size: 10px;
	line-height: 13px;
`
const SurveyOptionContainer = styled.div`
	width: 90px;
	display: flex;
	justify-content: center;
`
const SurveyOption = styled.div`
	background: var(--primary-color);
	color: white;
	padding: 4px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 9px;
	line-height: 9px;
	border-radius: 8px;
	margin-left: 3px;
	margin-right: 3px;
	margin-top: 6px;
`

const Survey = ({ teacherImg }) => {
	return (
		<Container>
			<Avatar src={teacherImg} size={20} />
			<div>
				<TextBox>Ask a survey question?</TextBox>
				<SurveyOptionContainer>
					<SurveyOption>Yes</SurveyOption>
					<SurveyOption>No</SurveyOption>
				</SurveyOptionContainer>
			</div>
		</Container>
	)
}

export default Survey
