import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Button, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { RocketOutlined } from "@ant-design/icons"

import { selectSubscription } from "../../redux/subscription/subscription.selectors"
import { selectOrgId } from "../../redux/org/org.selectors"
import { selectUserInfo } from "../../redux/user/user.selectors"
import { createCheckoutSession } from "../../redux/subscription/subscription.actions"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import SubscriptionStatus from "../../components/subscription/SubscriptionStatus"
import BillingPortal from "../../components/subscription/BillingPortal"
import CbLoading from "../../components/common/CbLoading"
import * as S from "../../components/styles/PageStyles"

const { Title, Text } = Typography

const PageOuter = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: ${({ hasBorder }) => (hasBorder ? "1px" : "0px")};
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
	background: white;
	text-align: center;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 48px;
	line-height: 52px;
	padding: 0px 20px 10px 20px;
`
const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding-top: 20px;
`

const failedPaymentStatuses = [
	"stripe_subscription_incomplete",
	"stripe_subscription_past_due",
	"stripe_subscription_unpaid"
]

const Subscription = ({ orgId, subscription, userInfo, createCheckoutSession }) => {
	const { activePlan } = subscription
	const userEmail = userInfo.info.email

	const handleContactUsButton = () => {
		window.Intercom("showNewMessage")
	}

	if (!activePlan) {
		return <CbLoading />
	}

	let { status, stripe_customer_email } = activePlan

	const handleSelectPlan = async () => {
		try {
			const checkoutSession = await createCheckoutSession(orgId, activePlan.plan_id, null)
			if (checkoutSession) {
				window.location.assign(checkoutSession)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const paymentFailedElement = (
		<CourseListEmpty hasBorder>
			<IconText>
				<FontAwesomeIcon icon={faExclamationTriangle} />
			</IconText>
			<Title level={4}>Payment failed</Title>
			<Text type="secondary">
				It looks like there was a problem taking your payment. If you need help then press the button to contact
				us
			</Text>
			<ButtonBox>
				<Button shape="round" size="large" style={{ marginLeft: 6 }} onClick={handleContactUsButton}>
					Contact us
				</Button>
			</ButtonBox>
		</CourseListEmpty>
	)

	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton title={"Subscription"} />
				<PageOuter>
					<CourseListContainer>
						<CourseListInner>
							{status === "stripe_subscription_active" && (
								<>
									{userEmail === stripe_customer_email ? (
										<>
											<SubscriptionStatus orgId={orgId} activePlan={activePlan} />
											<BillingPortal activePlan={activePlan} />
										</>
									) : (
										<CourseListEmpty hasBorder>
											<IconText>
												<FontAwesomeIcon icon={faLock} />
											</IconText>
											<Title level={4}>No access</Title>
											<Text type="secondary">
												Subscription settings can only be accessed by the user with email{" "}
												{stripe_customer_email}
											</Text>
											<Text type="secondary">
												If you need help press the button to contact us
											</Text>
											<ButtonBox>
												<Button shape="round" onClick={() => handleContactUsButton()}>
													Contact us
												</Button>
											</ButtonBox>
										</CourseListEmpty>
									)}
								</>
							)}
							{status === "stripe_subscription_trialing" && (
								<>
									{userEmail === stripe_customer_email ? (
										<>
											<SubscriptionStatus orgId={orgId} activePlan={activePlan} />
											<BillingPortal activePlan={activePlan} />
										</>
									) : (
										<CourseListEmpty hasBorder>
											<IconText>
												<FontAwesomeIcon icon={faLock} />
											</IconText>
											<Title level={4}>No access</Title>
											<Text type="secondary">
												Subscription settings can only be accessed by the user with email{" "}
												{stripe_customer_email}
											</Text>
											<Text type="secondary">
												If you need help press the button to contact us
											</Text>
											<ButtonBox>
												<Button shape="round" onClick={() => handleContactUsButton()}>
													Contact us
												</Button>
											</ButtonBox>
										</CourseListEmpty>
									)}
								</>
							)}
							{failedPaymentStatuses.includes(status) && (
								<>
									{userEmail === stripe_customer_email ? (
										<CourseListEmpty hasBorder>
											<IconText>
												<FontAwesomeIcon icon={faExclamationTriangle} />
											</IconText>
											<Title level={4}>Payment failed</Title>
											<Text type="secondary">
												It looks like there was a problem taking your payment. Please sign in to
												the billing portal with your account email address (
												{stripe_customer_email}). If you need help then press the button to
												contact us
											</Text>
											<ButtonBox>
												<a
													href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
													target="_blank"
													rel="noopener noreferrer"
												>
													<Button
														type="primary"
														shape="round"
														size="large"
														style={{ marginRight: 6 }}
													>
														Open billing portal
													</Button>
												</a>
												<Button
													shape="round"
													size="large"
													onClick={() => handleContactUsButton()}
												>
													Contact us
												</Button>
											</ButtonBox>
										</CourseListEmpty>
									) : (
										paymentFailedElement
									)}
								</>
							)}
							{status === "non_stripe_subscription_past_due" && paymentFailedElement}
							{status === "non_stripe_subscription" && (
								<SubscriptionStatus orgId={orgId} activePlan={activePlan} />
							)}
							{status === "trial_subscription" && (
								<CourseListEmpty hasBorder>
									<IconText>
										<RocketOutlined />
									</IconText>
									<Title level={4}>Subscribe for lift off</Title>
									<Text type="secondary">
										We've set up this trial account so you can see what Learn.ink has to offer.
										<br></br>To get full access and avoid your account being closed subscribe now.
									</Text>
									<ButtonBox>
										<Button shape="round" size="large" type="primary" onClick={handleSelectPlan}>
											Subscribe now
										</Button>
									</ButtonBox>
								</CourseListEmpty>
							)}
						</CourseListInner>
					</CourseListContainer>
				</PageOuter>
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	subscription: selectSubscription,
	orgId: selectOrgId,
	userInfo: selectUserInfo
})

const mapDispatchToProps = (dispatch) => ({
	createCheckoutSession: (orgId, planId, coupon) => dispatch(createCheckoutSession(orgId, planId, coupon))
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
