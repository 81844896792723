import { createSelector } from "reselect"

const teamEmails = ["georgia@learn.ink", "adam@farm.ink", "adam@learn.ink", "finn@learn.ink", "kamau@learn.ink"]

const betaTesters = [...teamEmails, "joseph.muttundu@sunculture.com", "nasser.abdu@sunculture.com"]

const selectUser = (state) => state.user

// Initial App auth loading ready

export const selectUserSessionLoaded = createSelector([selectUser], (user) => user.userSessionLoaded)

export const selectUserSessionLoadError = createSelector([selectUser], (user) => user.userSessionLoadError)

// Authentication status and user info

export const selectfirebaseAuthInfo = createSelector([selectUser], (user) => user.firebaseAuthInfo)
export const selectUserInfo = createSelector([selectUser], (user) => user.userInfo)
export const selectUserInfoDetail = createSelector([selectUserInfo], (userInfo) => userInfo.info)
export const selectUserOrgRoles = createSelector([selectUserInfo], (userInfo) => userInfo.userOrgRoles)
export const selectUserOrgKeyArray = createSelector([selectUser], (user) => {
	if (user.userInfo && user.userInfo.userOrgRoles) {
		return Object.keys(user.userInfo.userOrgRoles)
	} else {
		return []
	}
})

export const selectIsUserAuthenticated = createSelector(
	[selectfirebaseAuthInfo],
	(firebaseAuthInfo) => !!firebaseAuthInfo
)
export const selectHasVerifiedEmail = createSelector(
	[selectfirebaseAuthInfo],
	(firebaseAuthInfo) => !!firebaseAuthInfo && !!firebaseAuthInfo.emailVerified
)
export const selectIsUserInOrg = createSelector(
	[selectUserInfo],
	(userInfo) => !!userInfo && !!userInfo.userOrgRoles && Object.keys(userInfo.userOrgRoles).length > 0 // user is in an org if they have a non empty userOrgRoles object
)

export const selectUserAuthStatus = createSelector(
	[selectIsUserAuthenticated, selectHasVerifiedEmail, selectIsUserInOrg],
	(isAuthenticated, hasVerifiedEmail, isInOrg) => {
		return {
			isAuthenticated,
			hasVerifiedEmail,
			isInOrg
		}
	}
)
export const selectHasAuthRouteAccess = createSelector(
	[selectUserAuthStatus],
	(status) => !!(status.isAuthenticated && status.hasVerifiedEmail && status.isInOrg)
)

export const selectIsUserAdmin = createSelector(
	[selectUserInfo],
	(userInfo) => userInfo && userInfo.info && userInfo.info.email && teamEmails.includes(userInfo.info.email)
)

export const selectIsBetaTester = createSelector(
	[selectUserInfo],
	(userInfo) => userInfo && userInfo.info && userInfo.info.email && betaTesters.includes(userInfo.info.email)
)
