import React from "react"
import { withRouter } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"

const CbBackButton = ({ backLink, history }) => {
	return (
		<React.Fragment>
			{backLink && backLink !== "" ? (
				<a>
					<FontAwesomeIcon onClick={() => history.push(backLink)} icon={faArrowLeft} />
				</a>
			) : (
				<a>
					<FontAwesomeIcon onClick={() => history.goBack()} icon={faArrowLeft} />
				</a>
			)}
		</React.Fragment>
	)
}

// TODO: update proptypes to be more precise here!
CbBackButton.propTypes = {
	backLink: PropTypes.string,
	history: PropTypes.object.isRequired
}

export default withRouter(CbBackButton)
