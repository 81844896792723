import React from "react"
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { chartColors } from "../../../common/colors"

import ChartWrapper from "../../../common/ChartWrapper"
import GeNoItemsFound from "../../../../common/GeNoItemsFound"
import CbLoading from "../../../../common/CbLoading"

const SequenceJourneyStages = ({ chartData, courseHasData, dataMax, title, tooltip }) => {
	if (!chartData) {
		return <CbLoading size={40} />
	}
	return (
		<ChartWrapper tooltip={tooltip} title={title}>
			<ResponsiveContainer width="99%" height={350}>
				{courseHasData ? (
					<BarChart width={600} height={300} margin={{ top: 20 }} data={chartData}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" />
						<YAxis type="number" allowDecimals={false} domain={[0, dataMax]} />
						<Tooltip />
						<Bar dataKey="count" fill={chartColors[1]} />
					</BarChart>
				) : (
					<div style={{ padding: 10, height: "100%" }}>
						<GeNoItemsFound
							titleText="No data"
							subtitleText="Please select a sequence and user group selection with usage data"
							icon={<FontAwesomeIcon icon={faExclamationCircle} />}
						/>
					</div>
				)}
			</ResponsiveContainer>
		</ChartWrapper>
	)
}

export default SequenceJourneyStages
