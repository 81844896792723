// COURSE TEMPLATES PAGE

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId } from "../../redux/org/org.selectors"
import { getCourseTemplateList } from "../../redux/templates/templates.actions"
import { selectCourseTemplateList } from "../../redux/templates/templates.selectors"
import * as S from "../../components/styles/PageStyles"

import TemplateCourseCard from "../../components/courseBuilder/courses/TemplateCourseCard"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import LpBackButton from "../../components/common/LpBackButton"
import TemplatesSubNav from "../../components/courseBuilder/courses/TemplatesSubNav"

const CourseListContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseCardContainer = styled.div`
	height: 220px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`

const CourseTemplates = ({ orgId, getCourseTemplateList, courseTemplateList }) => {
	const [filteredTemplates, setFilteredTemplates] = useState(courseTemplateList)

	useEffect(() => {
		getCourseTemplateList(orgId)
	}, [getCourseTemplateList, orgId])

	return (
		<S.Container>
			<S.BackContainer>
				<LpBackButton backlink={`/org/${orgId}/create/courses`} />
			</S.BackContainer>
			<DashboardHeaderButton title={"Select a template"} />
			{courseTemplateList ? (
				<>
					<TemplatesSubNav
						courseTemplateList={courseTemplateList}
						setFilteredTemplates={setFilteredTemplates}
					/>

					<CourseListContainer>
						<CourseListInner>
							<>
								{filteredTemplates.length > 0 ? (
									<>
										{filteredTemplates.map((course, index) => (
											<CourseCardContainer key={index}>
												<TemplateCourseCard course={course} />
											</CourseCardContainer>
										))}
									</>
								) : (
									<GeNoItemsFound
										icon={<FontAwesomeIcon icon={faGraduationCap} />}
										titleText={"No templates found"}
										subtitleText={"Try resetting your search filters!"}
									/>
								)}
							</>
						</CourseListInner>
					</CourseListContainer>
				</>
			) : null}
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	courseTemplateList: selectCourseTemplateList
})

const mapDispatchToProps = (dispatch) => ({
	getCourseTemplateList: (orgId) => dispatch(getCourseTemplateList(orgId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseTemplates)
