import React from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"

interface Props {
	tooltip: string
	placement?: TooltipPlacement
	error?: boolean
	helpArticleId?: string
}

const TooltipIcon = ({ tooltip, placement, error, helpArticleId }: Props) => {
	const openHelpDoc = () => {
		window.Intercom("showArticle", helpArticleId)
	}

	const helpArticleTip = (
		<>
			{tooltip}
			<span className="toolTipArticle" onClick={() => openHelpDoc()}>
				Click me to learn more
			</span>
		</>
	)

	if (error) {
		return (
			<span
				style={{
					color: "var(--dark-red)",
					fontSize: 14
				}}
			>
				<Tooltip placement={placement || "top"} title={helpArticleId ? helpArticleTip : tooltip}>
					<QuestionCircleOutlined />
				</Tooltip>
			</span>
		)
	}

	return (
		<span
			style={{
				color: "var(--mid-grey)",
				fontSize: 14
			}}
		>
			<Tooltip placement={placement || "top"} title={helpArticleId ? helpArticleTip : tooltip}>
				<QuestionCircleOutlined />
			</Tooltip>
		</span>
	)
}

export default TooltipIcon
