// DELETE COURSE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { deleteCourseWithId } from "../../../../redux/courses/courses.actions"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"
import LpTextInput from "../../../common/LpTextInput"

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const DeleteCourse = ({ closemodal, orgId, courseName, courseId, deleteCourseWithId }) => {
	const [loading, setLoading] = useState(false)
	const [deleteConfirmText, setDeleteConfirmText] = useState(null)

	// this ammounts to setting access_groups to "archive" AND publishing an update to course IF it's published...
	const handleDeleteCourse = () => {
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)

			// handle update here
			deleteCourseWithId(orgId, courseId)

			closemodal()
		}, 3000)
	}
	const handleDeleteTextChange = (text) => {
		setDeleteConfirmText(text)
	}

	return (
		<ContentContainer>
			<GeTitle>Delete Course</GeTitle>
			{loading ? (
				<>
					<CbLoading loadingText="Deleting..." padding="30px" />
				</>
			) : (
				<>
					<LpTextInput
						labelText="Course Name"
						initialText={courseName}
						disabled={true}
						labelFontSize="16px"
					/>
					<Divider height={"28px"} />
					<LpTextInput
						labelText="Type the course name shown above to delete"
						handleTextChange={handleDeleteTextChange}
						labelFontSize="16px"
					/>
					{deleteConfirmText === courseName && (
						<>
							<Divider height={"10px"} />
							<Alert
								message={
									"WARNING: This action cannot be reversed, all data associated with this course will be deleted forever!"
								}
								type="warning"
							/>
						</>
					)}
					<Divider height={"20px"} />
					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button
							type="primary"
							size="large"
							shape="round"
							onClick={handleDeleteCourse}
							disabled={deleteConfirmText !== courseName}
						>
							Confirm delete
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	deleteCourseWithId: (org_id, course_id) => dispatch(deleteCourseWithId(org_id, course_id))
})

PropTypes.DeleteCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseName: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	deleteCourseWithId: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCourse)
