import React, { useEffect, useState } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { AccessCode } from "learnink-common/lib/domains/accessCodes/AccessCode.model"
import { selectLearningPathById, selectOrgId } from "../../../redux/org/org.selectors"
import { LearningPath, LearningPathType } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { Alert, Select, Tag, Typography, Modal, Button, Space, Result } from "antd"
import GeNoItemsFound from "../../common/GeNoItemsFound"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faKey, faLink } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { LearningPathById } from "../../../util/model/OrgSelectors"
import { updateFirebaseToken } from "../../../redux/org/org.actions"
import BigOnOffButton from "../../common/BigOnOffButton"
import * as S from "../../styles/PageStyles"
import CbLinkCopyButton from "../../common/CbLinkCopyButton"
import { fetchAnalytics } from "../../analyse/fetchAnalytics"
import { LearningPathStatusCount } from "../../../../../domains/userAnalytics/UserAnalytics.model"
import { ActiveLearningPathStatus } from "learnink-common/lib/domains/userManagement/users/User.model"
import moment from "moment"
import { DASH_DATE_FORMAT } from "learnink-common/lib/constants/constants"

const { Text } = Typography
const { Option } = Select

const statusArr: ActiveLearningPathStatus[] = ["complete", "overdue", "inProgress", "any"]

const statusLabels = {
	complete: "complete",
	overdue: "overdue",
	inProgress: "in progress",
	any: "any"
}

interface Row {
	any: number
	inProgress: number
	complete: number
	overdue: number
}

interface StatusCount {
	[key: string]: Row
}

interface OptionRow {
	value: string
	label: string
	color: string
	type: LearningPathType | undefined
}

interface AlertData {
	message: string
	type: "info" | "warning" | "error" | "success"
}

interface Props {
	learningPath: LearningPath
	closeModal: () => void
	orgId: string
	learningPathById: LearningPathById
	refreshData?: () => void
	role: UserGroup
}

const ShareLearningPath = ({ learningPath, orgId, closeModal, learningPathById, refreshData, role }: Props) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [defaultAccessCode, setDefaultAccessCode] = useState<string | undefined>(undefined)
	const [selectedLearningPathId, setSelectedLearningPathId] = useState<string | undefined>(undefined)
	const [userStatus, setUserStatus] = useState<ActiveLearningPathStatus | undefined>(undefined)
	const [learningPathStatusCount, setLearningPathStatusCount] = useState<StatusCount | undefined>(undefined)
	const [alertData, setAlertData] = useState<AlertData>({
		message: "Select a learning path to move users from",
		type: "info"
	})
	const [moveButtonEnabled, setMoveButtonEnabled] = useState<boolean>(false)

	const [shareType, setShareType] = useState<"accessCode" | "link" | "editLearningPath" | undefined>(undefined)
	const currentTimeSeconds = Math.floor(new Date().getTime() / 1000)
	const linkExpiryTime = currentTimeSeconds + 86400

	let learningPathIds = role.learningPaths
		.map((item) => item.learningPathId)
		.filter((item) => item !== learningPath.id)
	const options: OptionRow[] = []
	learningPathIds.forEach((learningPathId) => {
		if (learningPathById[learningPathId]) {
			options.push({
				value: learningPathId,
				label: learningPathById[learningPathId].internalName,
				color: learningPathById[learningPathId].color,
				type: learningPathById[learningPathId].type
			})
		}
	})

	const getOrgAccessCodes = async () => {
		try {
			await updateFirebaseToken()
			const { data } = await axios.get(`/api/accessCode/${orgId}`)
			data.forEach((accessCode: AccessCode) => {
				if (
					accessCode.actionList.filter(
						(item) => item.field === "learningPath" && item.targetId === learningPath.id
					).length > 0 &&
					accessCode.actionList.filter((item) => item.field === "role" && item.targetId === role.id).length >
						0
				) {
					if (accessCode.actionList.length === 2) {
						setDefaultAccessCode(accessCode.accessCode)
					}
				}
			})
			setLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	const getLearningPathStatusCount = async () => {
		try {
			const data: LearningPathStatusCount[] = await fetchAnalytics(
				"userAnalytics",
				orgId,
				`learning-path/active/status/count`,
				"all time"
			)
			const statusCount: StatusCount = {}
			learningPathIds.forEach((learningPathId) => {
				if (data.filter((item) => item.learningPathId === learningPathId).length > 0) {
					const row = data.filter((item) => item.learningPathId === learningPathId)[0]
					statusCount[learningPathId] = {
						any: row.completedLate + row.completedOnTime + row.inProgress + row.overdue,
						inProgress: row.inProgress,
						complete: row.completedLate + row.completedOnTime,
						overdue: row.overdue
					}
				} else {
					statusCount[learningPathId] = {
						any: 0,
						inProgress: 0,
						complete: 0,
						overdue: 0
					}
				}
			})
			setLearningPathStatusCount(statusCount)
		} catch (err) {
			console.log(err)
		}
	}

	const generateAccessCode = async () => {
		try {
			setLoading(true)
			await updateFirebaseToken()
			await axios.post(`/api/accessCode/${orgId}`, {
				description: `Add to ${learningPathById[learningPath.id].internalName} path and ${role.name} role`,
				isActive: true,
				actionList: [
					{
						field: "role",
						action: "replace",
						targetId: role.id
					},
					{ field: "learningPath", action: "replace", targetId: learningPath.id }
				]
			})
			setTimeout(() => {
				getOrgAccessCodes()
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}

	const moveUsersToLearningPath = async () => {
		try {
			setLoading(true)
			setMoveButtonEnabled(false)
			await updateFirebaseToken()
			const response = await axios.post(`/api/userManagement/${orgId}/users/learning-path/replace`, {
				fromLearningPathId: selectedLearningPathId,
				toLearningPathId: learningPath.id,
				status: userStatus,
				learningPathStartDate: moment().format(DASH_DATE_FORMAT)
			})
			setLoading(false)
			setAlertData({
				message: `${response.data.length} users have been moved into the ${learningPath.internalName} learning path`,
				type: "success"
			})
			if (refreshData) {
				refreshData()
			}
		} catch (err) {
			console.log(err)
			setLoading(false)
			setAlertData({
				message: "Sorry we weren't able to move your users. Please check your connection and try again",
				type: "error"
			})
		}
	}

	const generateDefaultAccessCode = (shareType: "accessCode" | "link") => {
		setShareType(shareType)
		if (!defaultAccessCode) {
			generateAccessCode()
		}
	}

	useEffect(() => {
		getOrgAccessCodes()
		getLearningPathStatusCount()
	}, [])

	useEffect(() => {
		if (selectedLearningPathId && learningPathStatusCount && !userStatus) {
			if (learningPathStatusCount[selectedLearningPathId].any === 0) {
				setAlertData({ message: "There are no users currently active in this learning path", type: "error" })
			}
			if (learningPathStatusCount[selectedLearningPathId].any > 0) {
				setAlertData({
					message:
						"Select a user status (e.g. selecting complete will only move users who have completed the learning path)",
					type: "info"
				})
			}
		}
		if (selectedLearningPathId && learningPathStatusCount && userStatus) {
			if (learningPathStatusCount[selectedLearningPathId][userStatus] === 0) {
				setAlertData({
					message: "There are no users in your selection",
					type: "error"
				})
			}
			if (learningPathStatusCount[selectedLearningPathId][userStatus] > 0) {
				setAlertData({
					message: `This will move ${learningPathStatusCount[selectedLearningPathId][userStatus]} users into the ${learningPath.internalName} learning path. Are you sure you want to do this?`,
					type: "warning"
				})
				setMoveButtonEnabled(true)
			}
		}
	}, [selectedLearningPathId, learningPathStatusCount, userStatus])

	return (
		<Modal title="Share learning path" onCancel={closeModal} open={true} footer={null} width={640} centered>
			<S.FlexBoxStartRow style={{ marginTop: 10, marginBottom: 10 }}>
				<BigOnOffButton
					key="accessCode"
					isOn={shareType === "accessCode"}
					color="green"
					text="Share an access code"
					faIcon={faKey}
					onClick={() => generateDefaultAccessCode("accessCode")}
					width={180}
				/>
				<BigOnOffButton
					key="link"
					isOn={shareType === "link"}
					color="purple"
					text="Share via a link"
					faIcon={faLink}
					onClick={() => generateDefaultAccessCode("link")}
					width={180}
				/>
				<BigOnOffButton
					key="editLearningPath"
					isOn={shareType === "editLearningPath"}
					color="blue"
					text="Move existing users"
					faIcon={faArrowRight}
					onClick={() => setShareType("editLearningPath")}
					width={180}
				/>
			</S.FlexBoxStartRow>
			{!loading && shareType === "accessCode" && defaultAccessCode && (
				<GeNoItemsFound icon={<FontAwesomeIcon icon={faKey as IconProp} />} titleText="Share an access code">
					<Text type="secondary" style={{ textAlign: "center", marginBottom: 20 }}>
						Users who enter this access code will have their role set as{" "}
						<Tag color={role.color}>{role.name}</Tag>and learning path set to{" "}
						<Tag color={learningPathById[learningPath.id].color}>
							{learningPathById[learningPath.id].internalName}
						</Tag>
					</Text>
					<Text
						style={{
							border: "solid 1px var(--light-grey)",
							borderRadius: 10,
							padding: "10px 20px 10px 20px",
							fontSize: 17,
							fontWeight: "bold"
						}}
						copyable={{
							tooltips: ["Press to copy your access code", "Copied!"]
						}}
					>
						{defaultAccessCode}
					</Text>
				</GeNoItemsFound>
			)}
			{!loading && shareType === "link" && defaultAccessCode && (
				<GeNoItemsFound icon={<FontAwesomeIcon icon={faLink as IconProp} />} titleText="Share via a link">
					<Text type="secondary" style={{ textAlign: "center", marginBottom: 20 }}>
						Users who click on this link will have their role set as{" "}
						<Tag color={role.color}>{role.name}</Tag>and learning path set to{" "}
						<Tag color={learningPathById[learningPath.id].color}>
							{learningPathById[learningPath.id].internalName}
						</Tag>
					</Text>
					{/* @ts-ignore */}
					<CbLinkCopyButton
						contentToCopy={`${process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL}/${orgId}/learning?access=${defaultAccessCode}&ex=${linkExpiryTime}`}
						targetUrl={`${process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL}/${orgId}/learning?access=${defaultAccessCode}&ex=${linkExpiryTime}`}
						borderRadius="0px"
						withLinkButton
						showHelperMsg={false}
						color="var(--secondary-color)"
					/>
					<Alert style={{ marginTop: 20 }} type="warning" message="This link will expire after 24 hours" />
				</GeNoItemsFound>
			)}
			{shareType === "editLearningPath" && learningPathStatusCount && alertData.type !== "success" && (
				<GeNoItemsFound
					icon={<FontAwesomeIcon icon={faArrowRight as IconProp} />}
					titleText="Move existing users"
				>
					<S.FlexBoxStartRow style={{ paddingTop: 20 }}>
						<S.FormLabel labelFontSize="14px">Move from: </S.FormLabel>
						<Select
							onChange={(value) => setSelectedLearningPathId(value)}
							style={{ width: 180, marginRight: 8 }}
							value={selectedLearningPathId}
							placeholder="Select learning path..."
						>
							{options.map((option, index) => (
								<Option value={option.value} key={index}>
									<span
										className="color-selector"
										style={{
											fontSize: 13,
											color: "white",
											background: option.color,
											padding: "3px 10px 3px 10px"
										}}
									>
										{option.label}
									</span>
								</Option>
							))}
						</Select>
						<S.FormLabel labelFontSize="14px"> with status: </S.FormLabel>
						<Select
							onChange={(value) => setUserStatus(value)}
							style={{ width: 190 }}
							value={userStatus}
							placeholder="Select status..."
							disabled={!selectedLearningPathId}
							options={
								selectedLearningPathId
									? statusArr.map((item) => {
											return {
												value: item,
												label: `${statusLabels[item]} (${learningPathStatusCount[selectedLearningPathId][item]} users)`,
												disabled: learningPathStatusCount[selectedLearningPathId][item] === 0
											}
									  })
									: []
							}
						/>
					</S.FlexBoxStartRow>
					<Alert style={{ marginTop: 20 }} type={alertData.type} message={alertData.message} />
					<Space style={{ marginTop: 20, marginBottom: -10 }}>
						<Button shape="round" size="large" onClick={closeModal}>
							Cancel
						</Button>
						<Button
							shape="round"
							type="primary"
							size="large"
							disabled={!moveButtonEnabled}
							loading={loading}
							onClick={() => moveUsersToLearningPath()}
						>
							Move users
						</Button>
					</Space>
				</GeNoItemsFound>
			)}
			{shareType === "editLearningPath" && learningPathStatusCount && alertData.type === "success" && (
				<Result
					status="success"
					title="Update successful"
					subTitle={alertData.message}
					extra={[
						<Button type="primary" size="large" shape="round" onClick={closeModal}>
							OK
						</Button>
					]}
				/>
			)}
		</Modal>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPathById: selectLearningPathById
})

// @ts-ignore
export default connect(mapStateToProps)(ShareLearningPath)
