import {
	faMusic,
	faSearch,
	faHeart,
	faStar,
	faUser,
	faFilm,
	faTh,
	faThLarge,
	faThList,
	faCheck,
	faTimes,
	faHome,
	faRoad,
	faLock,
	faFire,
	faLeaf,
	faEye,
	faGift,
	faCalendar,
	faEdit,
	faBook,
	faBookmark,
	faCamera,
	faImage,
	faPencilAlt,
	faArrowsAlt,
	faStepBackward,
	faStepForward,
	faBackward,
	faForward,
	faChevronLeft,
	faChevronRight,
	faStop,
	faPlay,
	faPlusCircle,
	faMinusCircle,
	faTimesCircle,
	faCheckCircle,
	faQuestionCircle,
	faInfoCircle,
	faCrosshairs,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
	faArrowDown,
	faPlus,
	faMinus,
	faAsterisk,
	faExclamationTriangle,
	faExclamationCircle,
	faMapMarker,
	faPlane,
	faSignal,
	faFlag,
	faHeadphones,
	faVolumeOff,
	faVolumeDown,
	faVolumeUp,
	faTag,
	faTags,
	faTint,
	faBan,
} from "@fortawesome/free-solid-svg-icons"

export const faIcons = {
	music: faMusic,
	search: faSearch,
	heart: faHeart,
	star: faStar,
	user: faUser,
	film: faFilm,
	th: faTh,
	"th-large": faThLarge,
	"th-list": faThList,
	check: faCheck,
	times: faTimes,
	home: faHome,
	road: faRoad,
	lock: faLock,
	fire: faFire,
	leaf: faLeaf,
	eye: faEye,
	gift: faGift,
	calendar: faCalendar,
	edit: faEdit,
	book: faBook,
	bookmark: faBookmark,
	camera: faCamera,
	image: faImage,
	pencil: faPencilAlt,
	arrows: faArrowsAlt,
	"step-backward": faStepBackward,
	"step-forward": faStepForward,
	backward: faBackward,
	forward: faForward,
	"chevron-left": faChevronLeft,
	"chevron-right": faChevronRight,
	stop: faStop,
	play: faPlay,
	"plus-circle": faPlusCircle,
	"minus-circle": faMinusCircle,
	"times-circle": faTimesCircle,
	"check-circle": faCheckCircle,
	"question-circle": faQuestionCircle,
	"info-circle": faInfoCircle,
	crosshairs: faCrosshairs,
	"arrow-left": faArrowLeft,
	"arrow-right": faArrowRight,
	"arrow-up": faArrowUp,
	"arrow-down": faArrowDown,
	plus: faPlus,
	minus: faMinus,
	asterisk: faAsterisk,
	"exclamation-circle": faExclamationCircle,
	"exclamation-triangle": faExclamationTriangle,
	"map-marker": faMapMarker,
	plane: faPlane,
	signal: faSignal,
	flag: faFlag,
	headphones: faHeadphones,
	"volume-off": faVolumeOff,
	"volume-down": faVolumeDown,
	"volume-up": faVolumeUp,
	tag: faTag,
	tags: faTags,
	tint: faTint,
	ban: faBan,
}

export const iconLibrary = [
	{ iconId: "search", tags: ["search"], type: "faIcon" },
	{ iconId: "heart", tags: ["heart"], type: "faIcon" },
	{ iconId: "star", tags: ["star"], type: "faIcon" },
	{ iconId: "user", tags: ["user"], type: "faIcon" },
	{ iconId: "check", tags: ["check"], type: "faIcon" },
	{ iconId: "times", tags: ["times"], type: "faIcon" },
	{ iconId: "plus-circle", tags: ["plus-circle"], type: "faIcon" },
	{ iconId: "minus-circle", tags: ["minus-circle"], type: "faIcon" },
	{ iconId: "times-circle", tags: ["times-circle"], type: "faIcon" },
	{ iconId: "check-circle", tags: ["check-circle"], type: "faIcon" },
	{ iconId: "question-circle", tags: ["question-circle"], type: "faIcon" },
	{ iconId: "info-circle", tags: ["info-circle"], type: "faIcon" },
	{ iconId: "home", tags: ["home"], type: "faIcon" },
	{ iconId: "road", tags: ["road"], type: "faIcon" },
	{ iconId: "lock", tags: ["lock"], type: "faIcon" },
	{ iconId: "fire", tags: ["fire"], type: "faIcon" },
	{ iconId: "leaf", tags: ["leaf"], type: "faIcon" },
	{ iconId: "eye", tags: ["eye"], type: "faIcon" },
	{ iconId: "gift", tags: ["gift"], type: "faIcon" },
	{ iconId: "calendar", tags: ["calendar"], type: "faIcon" },
	{ iconId: "edit", tags: ["edit"], type: "faIcon" },
	{ iconId: "book", tags: ["book"], type: "faIcon" },
	{ iconId: "bookmark", tags: ["bookmark"], type: "faIcon" },
	{ iconId: "camera", tags: ["camera"], type: "faIcon" },
	{ iconId: "image", tags: ["image"], type: "faIcon" },
	{ iconId: "pencil", tags: ["pencil"], type: "faIcon" },
	{ iconId: "arrows", tags: ["arrows"], type: "faIcon" },
	{
		iconId: "exclamation-circle",
		tags: ["exclamation-circle"],
		type: "faIcon",
	},
	{
		iconId: "exclamation-triangle",
		tags: ["exclamation-triangle"],
		type: "faIcon",
	},
	{ iconId: "crosshairs", tags: ["crosshairs"], type: "faIcon" },
	{ iconId: "arrow-left", tags: ["arrow-left"], type: "faIcon" },
	{ iconId: "arrow-right", tags: ["arrow-right"], type: "faIcon" },
	{ iconId: "arrow-up", tags: ["arrow-up"], type: "faIcon" },
	{ iconId: "arrow-down", tags: ["arrow-down"], type: "faIcon" },
	{ iconId: "plus", tags: ["plus"], type: "faIcon" },
	{ iconId: "minus", tags: ["minus"], type: "faIcon" },
	{ iconId: "asterisk", tags: ["asterisk"], type: "faIcon" },
	{ iconId: "map-marker", tags: ["map-marker"], type: "faIcon" },
	{ iconId: "plane", tags: ["plane"], type: "faIcon" },
	{ iconId: "signal", tags: ["signal"], type: "faIcon" },
	{ iconId: "flag", tags: ["flag"], type: "faIcon" },
	{ iconId: "headphones", tags: ["headphones"], type: "faIcon" },
	{ iconId: "volume-off", tags: ["volume-off"], type: "faIcon" },
	{ iconId: "volume-up", tags: ["volume-up"], type: "faIcon" },
	{ iconId: "volume-down", tags: ["volume-down"], type: "faIcon" },
	{ iconId: "tag", tags: ["tag"], type: "faIcon" },
	{ iconId: "tags", tags: ["tags"], type: "faIcon" },
	{ iconId: "tint", tags: ["tint"], type: "faIcon" },
	{ iconId: "ban", tags: ["ban"], type: "faIcon" },
	{ iconId: "music", tags: ["music"], type: "faIcon" },
]
