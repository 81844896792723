import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { BarChartOutlined, QuestionCircleOutlined, FormOutlined } from "@ant-design/icons"
import { Menu } from "antd"
import styled from "styled-components"
import { useLocation, Link } from "react-router-dom"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import { selectOrgCourseCards } from "../../../redux/org/org.selectors"

const SubNav = styled.div`
	width: 100%;
	background: transparent;
	display: flex;
	flex-direction: column;
`

const switchLastPathInDir = (pathname, newDir) => {
	let pathArr = pathname.split("/")
	pathArr.pop()
	return pathArr.join("/") + "/" + newDir
}

// Main component
// =============
const CourseSubNav = ({ courseId, orgCourseCards }) => {
	const { pathname, search } = useLocation()
	const dateRange = search.split("dateRange=", 2)[1]
		? `&dateRange=${search.split("dateRange=", 2)[1].split("&", 1)[0]}`
		: ""
	const userGroups = search.split("userGroups=", 2)[1]
		? `&userGroups=${search.split("userGroups=", 2)[1].split("&", 1)[0]}`
		: ""
	const [fixedView] = useUrlQueryString("fixedView")
	const fixedViewParams = fixedView ? `&fixedView=true` : ``
	const defaultSearchParams = `?courseId=${courseId}${dateRange}${userGroups}${fixedViewParams}`

	const currentPath = pathname.split("/").pop()

	const items = [
		{
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "insights"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Course progress
				</Link>
			),
			key: "insights",
			icon: <BarChartOutlined />
		},
		{
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "questions"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Question answers
				</Link>
			),
			key: "questions",
			icon: <QuestionCircleOutlined />
		},
		{
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "surveys"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Survey answers
				</Link>
			),
			key: "surveys",
			icon: <FormOutlined />
		}
	]

	return (
		<>
			<div className="row" style={{ padding: 25, paddingTop: 10 }}>
				<h1 style={{ fontSize: 28, fontWeight: 600, paddingLeft: 4 }}>
					{orgCourseCards[courseId] ? orgCourseCards[courseId].info.name : ""}
				</h1>
			</div>
			<SubNav>
				<Menu
					selectedKeys={currentPath}
					mode="horizontal"
					items={items}
					style={{
						background: "transparent",
						fontSize: 18
					}}
				/>
			</SubNav>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(CourseSubNav)
