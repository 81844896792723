import { createSelector } from "reselect"

export const selectState = (state) => state

export const selectManagement = (state) => state.management

export const selectOrgUserGroupsInfo = createSelector([selectManagement], (management) => management.orgUserGroupsInfo)

export const selectUserList = createSelector([selectManagement], (management) => management.userList)

export const selectAnalyticsTableData = createSelector(
	[selectManagement],
	(management) => management.userAnalyticsTable
)
