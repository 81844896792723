import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectAnalyticsTableData } from "../../../../redux/management/management.selectors"
import useUrlQueryString from "../../../hooks/useUrlQueryString"
import * as S from "../../../styles/PageStyles"
import { CourseUser } from "learnink-common/lib/domains/userManagement/users/User.model"

import UserTableWrapper from "../../../management/userTable/UserTableWrapper"
import UserGroupSummaryTable from "./UserGroupSummaryTable"
import { userTableColumns } from "../../../management/userTable/UserTable.model"

const TableFilter = ({ userAnalyticsTable }) => {
	const [userGroups] = useUrlQueryString("userGroups")
	let userGroupsArr = userGroups ? userGroups.split(",") : []

	let data = userAnalyticsTable
	if (userGroupsArr.length > 0) {
		data = data
			.filter((x) => x["userGroups"] !== null)
			.filter((x) => userGroupsArr.filter((value) => x["userGroups"].includes(value)).length > 0)
	}

	return (
		<div className="row">
			<div className="col-sm-12">
				<UserGroupSummaryTable userGroupsArr={userGroupsArr} data={data} />
				<S.Card>
					<S.CardBody>
						<UserTableWrapper
							data={data}
							columns={userTableColumns.filter((item) =>
								[...Object.keys(CourseUser.shape), "userGroupNames"].includes(item.id)
							)}
							actions={["editUserGroups", "notify", "export"]}
							notifyButtonOn
							hiddenColumns={[
								"registrationStatus",
								"firstName",
								"lastName",
								"region",
								"country",
								"sex",
								"ageBracket",
								"userScore",
								"numEndlineAnswersCorrect",
								"numEndlineAnswers",
								"numBaselineAnswersCorrect",
								"numBaselineAnswers",
								"phoneNumber",
								"userGroups",
								"registeredAt",
								"certificateId",
								"lastActive",
								"startedAt",
								"shareRequestSenders"
							]}
							sidePanel="userProfile"
						/>
					</S.CardBody>
				</S.Card>
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	userAnalyticsTable: selectAnalyticsTableData
})

export default connect(mapStateToProps)(TableFilter)
