import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Alert } from "antd"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { ShortId } from "learnink-common/lib/tools/randomCharacterGenerators/ShortIdGenerator"
import { archiveLearningPath, unarchiveLearningPath } from "../../../redux/org/org.actions"
import { ModalState } from "../../../util/model/PageStates"
import FormModal from "../../common/FormModal"

interface Props {
	learningPath: LearningPath
	orgId: string
	archiveLearningPath: (orgId: string, learningPath: LearningPath) => ShortId
	unarchiveLearningPath: (orgId: string, learningPath: LearningPath) => ShortId
	onCancel: () => void
	action: "archive" | "unarchive"
}

const ArchiveUnarchive = ({
	learningPath,
	orgId,
	archiveLearningPath,
	unarchiveLearningPath,
	onCancel,
	action
}: Props) => {
	const [modalState, setModalState] = useState<ModalState>("confirm")

	const handleSubmit = async () => {
		setModalState("loading")
		try {
			action === "archive" ? archiveLearningPath(orgId, learningPath) : unarchiveLearningPath(orgId, learningPath)
			setModalState("complete")
		} catch (err) {
			setModalState("error")
		}
	}

	const formElements = (
		<Alert
			message={
				action === "archive"
					? "Are you sure you want to archive this learning path? Your users will have no access to the learning path while it is archived"
					: "Are you sure you want to unarchive this learning path?"
			}
			type="warning"
		/>
	)

	return (
		<FormModal
			title={action === "archive" ? "Archive" : "Unarchive"}
			isModalOpen={true}
			closeModal={onCancel}
			modalState={modalState}
			handleSubmit={handleSubmit}
			formElements={formElements}
			submitButtonTitle={"Confirm"}
			initialValues={{}}
			size="large"
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch: any) => ({
	archiveLearningPath: (orgId: string, learningPath: LearningPath) =>
		dispatch(archiveLearningPath(orgId, learningPath)),
	unarchiveLearningPath: (orgId: string, learningPath: LearningPath) =>
		dispatch(unarchiveLearningPath(orgId, learningPath))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveUnarchive)
