import React, { useState } from "react"
import { Modal } from "antd"
import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faCalendar, faStopwatch } from "@fortawesome/free-solid-svg-icons"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"

interface Props {
	learningPath: LearningPath
	onCancel: () => void
	updateLearningPath: (learningPath: LearningPath) => void
}

const EditLearningPathType = ({ learningPath, onCancel, updateLearningPath }: Props) => {
	const [learningPathType, setLearningPathType] = useState<"ACTIVATION" | "SEGMENT">(learningPath.type)

	const recalculateLearningPath = () => {
		if (learningPath.sequences.length === 0) {
			updateLearningPath({ ...learningPath, type: learningPathType })
		}
		if (learningPath.sequences.length > 0 && learningPathType === "ACTIVATION") {
			const deadlineDay = learningPath.sequences[learningPath.sequences.length - 1].deadlineDay
			const strictlyOrdered = learningPath.sequences[0].strictlyOrdered
			const updatedSequences: Sequence[] = []
			learningPath.sequences.forEach((sequence: Sequence, index: number) => {
				updatedSequences.push({
					...sequence,
					deadlineDay,
					strictlyOrdered,
					staggerProgress: false,
					unlockTrigger: index === 0 ? "start_learning_path" : "finish_previous",
					numDays: deadlineDay + 1,
					unlockDelayDays: 0
				})
			})
			const updatedLearningPath = {
				...learningPath,
				type: learningPathType,
				sequences: updatedSequences
			}
			updateLearningPath(updatedLearningPath)
		}
		if (learningPath.sequences.length > 0 && learningPathType === "SEGMENT") {
			const deadlineDay = learningPath.sequences[learningPath.sequences.length - 1].deadlineDay
			const strictlyOrdered = learningPath.sequences[0].strictlyOrdered
			const updatedSequences: Sequence[] = []
			const numDays = Math.ceil((deadlineDay + 1) / learningPath.sequences.length)
			learningPath.sequences.forEach((sequence: Sequence, index: number) => {
				updatedSequences.push({
					...sequence,
					deadlineDay: (index + 1) * (numDays - 1),
					strictlyOrdered,
					staggerProgress: false,
					unlockTrigger: index === 0 ? "start_learning_path" : "finish_previous",
					numDays: numDays,
					unlockDelayDays: 0
				})
			})
			const updatedLearningPath = {
				...learningPath,
				type: learningPathType,
				sequences: updatedSequences
			}
			updateLearningPath(updatedLearningPath)
		}
	}

	return (
		<Modal
			okText="Save"
			onCancel={onCancel}
			title="Edit type"
			open={true}
			centered
			width={500}
			onOk={() => recalculateLearningPath()}
		>
			<S.FlexBoxStartRow>
				<BigOnOffButton
					key="sequence"
					isOn={learningPathType === "ACTIVATION"}
					color="green"
					text="Basic: complete all courses by a deadline"
					faIcon={faCalendar}
					onClick={() => setLearningPathType("ACTIVATION")}
					width={200}
				/>
				<BigOnOffButton
					key="course"
					isOn={learningPathType === "SEGMENT"}
					color="blue"
					text="Advanced: stagger progress over time"
					faIcon={faStopwatch}
					onClick={() => setLearningPathType("SEGMENT")}
					width={200}
				/>
			</S.FlexBoxStartRow>
		</Modal>
	)
}

export default EditLearningPathType
