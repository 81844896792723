import { ManagementActionTypes } from "./management.types"

const INITIAL_STATE = {
	userList: null,
	userAnalyticsTable: null,
	orgUserGroupsInfo: null
}

const managementReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ManagementActionTypes.SET_ORG_USER_GROUPS_INFO:
			return {
				...state,
				orgUserGroupsInfo: action.payload
			}
		case ManagementActionTypes.CLEAR_ORG_USER_GROUPS_INFO:
			return {
				...state,
				orgUserGroupsInfo: null
			}
		case ManagementActionTypes.SET_USER_LIST:
			return {
				...state,
				userList: action.payload
			}
		case ManagementActionTypes.CLEAR_USER_LIST:
			return {
				...state,
				userList: null
			}
		case ManagementActionTypes.SET_USER_ANALYTICS_TABLE:
			return {
				...state,
				userAnalyticsTable: action.payload
			}
		case ManagementActionTypes.UPDATED_ONE_IN_USER_LIST:
			return {
				...state,
				userList: state.userList
					? state.userList.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (item.userId === action.payload.userId) {
								updatedItem.userGroupNames = action.payload.userGroupNames
								updatedItem.userGroups = action.payload.userGroups
								updatedItem.customId = action.payload.customId
								updatedItem.customName = action.payload.customName
							}
							return updatedItem
					  })
					: null,
				userAnalyticsTable: state.userAnalyticsTable
					? state.userAnalyticsTable.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (item.userId === action.payload.userId) {
								updatedItem.userGroupNames = action.payload.userGroupNames
								updatedItem.userGroups = action.payload.userGroups
								updatedItem.customId = action.payload.customId
								updatedItem.customName = action.payload.customName
							}
							return updatedItem
					  })
					: null
			}
		case ManagementActionTypes.BULK_EDIT_USER_GROUPS:
			return {
				...state,
				userList: state.userList
					? state.userList.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (action.payload.filter((item) => item.userId === updatedItem.userId).length > 0) {
								updatedItem.userGroupNames = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].userGroupNames
								updatedItem.userGroups = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].userGroups
							}
							return updatedItem
					  })
					: null,
				userAnalyticsTable: state.userAnalyticsTable
					? state.userAnalyticsTable.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (action.payload.filter((item) => item.userId === updatedItem.userId).length > 0) {
								updatedItem.userGroupNames = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].userGroupNames
								updatedItem.userGroups = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].userGroups
							}
							return updatedItem
					  })
					: null
			}

		case ManagementActionTypes.BULK_EDIT_LEARNING_PATH:
			return {
				...state,
				userList: state.userList
					? state.userList.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (action.payload.filter((item) => item.userId === updatedItem.userId).length > 0) {
								updatedItem.learningPathName = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].learningPathName
								updatedItem.learningPathId = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].learningPathId
							}
							return updatedItem
					  })
					: null,
				userAnalyticsTable: state.userAnalyticsTable
					? state.userAnalyticsTable.map((item) => {
							let updatedItem = Object.assign({}, item)
							if (action.payload.filter((item) => item.userId === updatedItem.userId).length > 0) {
								updatedItem.learningPathName = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].learningPathName
								updatedItem.learningPathId = action.payload.filter(
									(item) => item.userId === updatedItem.userId
								)[0].learningPathId
							}
							return updatedItem
					  })
					: null
			}

		case ManagementActionTypes.ADD_TO_USER_LIST:
			return {
				...state,
				userList: state.userList ? state.userList.concat(action.payload) : null
			}

		case ManagementActionTypes.REMOVE_FROM_USER_LIST:
			return {
				...state,
				userList: state.userList ? state.userList.filter((item) => !action.payload.includes(item.userId)) : null
			}

		default:
			return state
	}
}

export default managementReducer
