import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PanelContainer from "../editor/panels/common/PanelContainer"
import { Button, Select } from "antd"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { CardModel } from "../../../util/model/Cards"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"
import AudioFileInput from "./AudioFileInput"
import { createStructuredSelector } from "reselect"
import TextToSpeech from "./TextToSpeech"
import { selectPermissions, Permissions } from "../../../redux/permissions/permissions.selectors"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"
import { toFormattedLanguage as format, toLanguage3Code } from "../../../util/formatters"
import _ from "lodash"
import styled from "styled-components"
import { handleMouseClick } from "../../../util/handleMouseClick"
import { languageColors } from "../../styles/PageStyles"
import SegmentSetting from "../editor/panels/common/SegmentSetting"
import {
	AudioFilled,
	AudioOutlined,
	CaretDownOutlined,
	CaretUpOutlined,
	ThunderboltFilled,
	ThunderboltOutlined
} from "@ant-design/icons"
import * as S from "../../styles/PageStyles"
import { useApi } from "../../../util/useApi"
import Routes from "../../../util/Routes"
import { selectCourseId, selectStage } from "../../../redux/courses/courses.selectors"
import {
	TTS_ENABLED_LANGUAGES,
	getCurrentVoiceClipType,
	getFirstVoiceClipForLanguage,
	useSetTeacherLearnerVoiceProfiles,
	useSetVoiceClipType
} from "./useUpdateVoiceClipType"
import TextToSpeechSettings from "./TextToSpeechSettings"
import { UUID } from "learnink-common/lib/types/types"
import { selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { CourseCards } from "../../../util/model/OrgSelectors"

interface Props {
	orgId: string
	card: Card
	stage: any
	updateLessonCard: (updateParams: LessonCard) => void
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => void
	permissions: Permissions
	courseId: UUID
	courseCards: CourseCards
}

interface Option {
	value: string
	label: string
}

export interface VoiceProfile {
	id: string
	name: string
}

export type VoiceClipType = "textToSpeech" | "custom"

const LS = {
	SelectLanguage: styled.div`
		margin-top: 10px;
		div {
			width: 100%;
		}
	`,
	Divider: styled.div`
		width: 100%;
		border-bottom: solid 1px grey;
		height: 8px;
		margin-bottom: 10px;
	`,
	PanelExpand: styled.div`
		width: 100%;
		padding: 10px;
		border-bottom: solid 1px var(--light-grey);
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	`
}

const languageDropdownOptions = ["en", "sw"]

function getLanguageOptions(selectedLanguages: LanguageCode[]): Option[] {
	return _.chain(LanguageCode.options)
		.filter((item) => !selectedLanguages.includes(item))
		.filter((item) => languageDropdownOptions.includes(item))
		.sortBy(format)
		.map((l) => ({ value: l, label: format(l) }))
		.value()
}

const getStoredLanguages = (stage: any): LanguageCode[] => {
	const cards = [...Object.values(stage.lesson_cards), ...Object.values(stage.question_cards)] as Card[]
	// @ts-ignore
	return cards
		.flatMap((card) => card.voiceClips || [])
		.map((clip) => clip.languageCode)
		.filter(Boolean)
}

const VoicePanel = ({ card, permissions, stage, courseId, courseCards }: Props) => {
	const courseLanguage = toLanguage3Code(courseCards[courseId].info.language) || "en"
	const storedLanguages: LanguageCode[] = getStoredLanguages(stage)
	const uniqueLanguages = _.uniq([courseLanguage, ...storedLanguages])

	const [languages, setLanguages] = useState<LanguageCode[]>(uniqueLanguages)
	const [clickedLanguage, setClickedLanguage] = useState<LanguageCode | undefined>(undefined)
	const [languageCode, setLanguageCode] = useState<LanguageCode>(languages[0])
	const [showSelectLanguage, setShowSelectLanguage] = useState<boolean>(false)
	const [voiceClipType, setVoiceClipType] = useState<VoiceClipType>("textToSpeech")
	const [teacherVoiceProfileId, setTeacherVoiceProfileId] = useState<string | undefined>(undefined)
	const [learnerVoiceProfileId, setLearnerVoiceProfileId] = useState<string | undefined>(undefined)
	const [showGenAISettings, setShowGenAISettings] = useState<boolean>(false)

	const { setApiRequest: getDefaults, data: voiceProfileDefaults } = useApi<never, VoiceProfile[]>()

	useEffect(() => {
		getDefaults({ route: Routes.getVoiceProfileAIDefaults })
	}, [])

	const savedVoiceClip = getFirstVoiceClipForLanguage(card, languageCode)
	const savedVoiceClipType = getCurrentVoiceClipType(voiceProfileDefaults, savedVoiceClip)

	const getLanguageOptionId = (languageCode: LanguageCode) => `language-code-${languageCode}`

	const addLanguage = (languageCode: LanguageCode) => {
		if (!languages.includes(languageCode)) {
			const newLanguages = [...languages]
			newLanguages.push(languageCode)

			setLanguages(newLanguages)
			setClickedLanguage(languageCode)
		}
		setShowSelectLanguage(false)
	}

	const clickOnLanguageOption = () => {
		if (!clickedLanguage) return

		const element = document.getElementById(getLanguageOptionId(clickedLanguage))
		if (element) handleMouseClick(element)
	}

	useEffect(clickOnLanguageOption, [clickedLanguage])
	useSetVoiceClipType(voiceProfileDefaults, savedVoiceClip, languageCode, voiceClipType, setVoiceClipType)
	useSetTeacherLearnerVoiceProfiles(
		voiceProfileDefaults,
		savedVoiceClip,
		card,
		stage,
		teacherVoiceProfileId,
		setTeacherVoiceProfileId,
		learnerVoiceProfileId,
		setLearnerVoiceProfileId,
		languageCode
	)

	const LanguageOptions = (
		<SegmentSetting
			id="voiceLanguage"
			// @ts-ignore
			onChange={setLanguageCode}
			value={languageCode}
			options={languages.map((languageCode, key) => {
				const voiceClipStatus = new CardModel(card).getVoiceClipButtonStatus(languageCode)
				const backgroundColor =
					voiceClipStatus.status === "complete" ? languageColors(languageCode) : "var(--mid-grey)"

				return {
					label: format(languageCode),
					value: languageCode,
					circleColor: backgroundColor
				}
			})}
		/>
	)

	const SelectLanguage = (
		<LS.SelectLanguage>
			{!showSelectLanguage && (
				<Button
					type="ghost"
					size="middle"
					shape="default"
					onClick={() => {
						setShowSelectLanguage(true)
					}}
				>
					Add language...
				</Button>
			)}

			{showSelectLanguage && (
				<Select
					showSearch
					options={getLanguageOptions(languages)}
					placeholder="Type a language..."
					filterOption={(input, option) =>
						(option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
						(option?.value ?? "").toLowerCase().includes(input.toLowerCase())
					}
					onChange={addLanguage}
				/>
			)}
		</LS.SelectLanguage>
	)

	return (
		<>
			<PanelContainer title="Language">
				{LanguageOptions}
				{SelectLanguage}
			</PanelContainer>

			<PanelContainer title="Voice type">
				<SegmentSetting
					onChange={(val: VoiceClipType) => setVoiceClipType(val)}
					value={voiceClipType}
					id="voiceClipType"
					options={[
						{
							label: "AI",
							value: "textToSpeech",
							icon:
								savedVoiceClipType === "textToSpeech" ? (
									<ThunderboltFilled style={{ ...S.editorIcon, color: "var(--secondary-color)" }} />
								) : (
									<ThunderboltOutlined style={S.editorIcon} />
								),
							disabled: !TTS_ENABLED_LANGUAGES.includes(languageCode)
						},
						{
							label: "Manual",
							value: "custom",
							icon:
								savedVoiceClipType === "custom" ? (
									<AudioFilled style={{ ...S.editorIcon, color: "var(--secondary-color)" }} />
								) : (
									<AudioOutlined style={S.editorIcon} />
								)
						}
					]}
				/>
			</PanelContainer>
			{permissions.canView(Resource.enum.TEXT_TO_SPEECH) && voiceClipType === "textToSpeech" && (
				<TextToSpeech
					card={card}
					teacherVoiceProfileId={teacherVoiceProfileId || ""}
					learnerVoiceProfileId={learnerVoiceProfileId || ""}
					setTeacherVoiceProfileId={setTeacherVoiceProfileId}
					setLearnerVoiceProfileId={setLearnerVoiceProfileId}
					voiceProfileDefaults={voiceProfileDefaults}
					voiceClip={savedVoiceClip}
					languageCode={languageCode}
				/>
			)}
			{voiceClipType === "textToSpeech" && savedVoiceClipType !== "textToSpeech" ? (
				<></>
			) : (
				<PanelContainer title="Voice clips">
					{card.type === "multipleChoiceQuestion" && card.text && (
						<>
							<AudioFileInput
								key={card.type}
								textId={card.textId}
								voiceClip={savedVoiceClip}
								voiceClipType={voiceClipType}
								description={`Question: ${card.text}`}
								card={card}
								languageCode={languageCode}
								voiceProfileDefaults={voiceProfileDefaults}
							/>
							{(card.answerOptions || []).map((answerOption, index) => (
								<>
									<LS.Divider />
									<AudioFileInput
										textId={answerOption.textId}
										key={index}
										voiceClip={
											answerOption.voiceClips &&
											answerOption.voiceClips.filter(
												(item) =>
													item.textId === answerOption.textId &&
													item.languageCode === languageCode
											).length > 0
												? answerOption.voiceClips.filter(
														(item) =>
															item.textId === answerOption.textId &&
															item.languageCode === languageCode
												  )[0]
												: undefined
										}
										voiceClipType={voiceClipType}
										description={`Answer: ${answerOption.text}`}
										card={card}
										languageCode={languageCode}
										voiceProfileDefaults={voiceProfileDefaults}
									/>
								</>
							))}
						</>
					)}
					{/* @ts-ignore */}
					{card.message && (
						<AudioFileInput
							key={card.type}
							textId={card.textId}
							voiceClip={savedVoiceClip}
							voiceClipType={voiceClipType}
							// @ts-ignore
							description={card.message}
							card={card}
							languageCode={languageCode}
							voiceProfileDefaults={voiceProfileDefaults}
						/>
					)}
					{card.type === "callOut" && (
						<AudioFileInput
							key={card.type}
							textId={card.textId}
							voiceClip={savedVoiceClip}
							voiceClipType={voiceClipType}
							description={(card.elements || []).map((item) => item.text).join(",")}
							card={card}
							languageCode={languageCode}
							voiceProfileDefaults={voiceProfileDefaults}
						/>
					)}
					{card.type === "multipleChoiceSurvey" &&
						(card.surveyOptions || []).map((surveyOption, index) => (
							<>
								<LS.Divider />
								<AudioFileInput
									textId={surveyOption.textId}
									key={index}
									voiceClip={
										surveyOption.voiceClips &&
										surveyOption.voiceClips.filter(
											(item) =>
												item.textId === surveyOption.textId &&
												item.languageCode === languageCode
										).length > 0
											? surveyOption.voiceClips.filter(
													(item) =>
														item.textId === surveyOption.textId &&
														item.languageCode === languageCode
											  )[0]
											: undefined
									}
									voiceClipType={voiceClipType}
									description={`Option: ${surveyOption.text}`}
									card={card}
									languageCode={languageCode}
									voiceProfileDefaults={voiceProfileDefaults}
								/>
							</>
						))}
				</PanelContainer>
			)}
			{permissions.canView(Resource.enum.TEXT_TO_SPEECH) && voiceClipType === "textToSpeech" && (
				<>
					<LS.PanelExpand onClick={() => setShowGenAISettings(!showGenAISettings)}>
						Advanced settings{" "}
						<div style={{ fontSize: 15 }}>
							{showGenAISettings ? <CaretUpOutlined /> : <CaretDownOutlined />}
						</div>
					</LS.PanelExpand>
					{showGenAISettings && (
						<TextToSpeechSettings
							teacherVoiceProfileId={teacherVoiceProfileId || ""}
							learnerVoiceProfileId={learnerVoiceProfileId || ""}
							setTeacherVoiceProfileId={setTeacherVoiceProfileId}
							setLearnerVoiceProfileId={setLearnerVoiceProfileId}
							voiceProfileDefaults={voiceProfileDefaults}
							languageCode={languageCode}
						/>
					)}
				</>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	permissions: selectPermissions,
	stage: selectStage,
	courseId: selectCourseId,
	courseCards: selectOrgCourseCards
})

// @ts-ignore
export default connect(mapStateToProps)(VoicePanel)
