import React from "react"
import styled from "styled-components"
import { Tag } from "antd"
import { COLUMN_LABEL_BY_MEMBER, Filter, OPERATOR_LABEL } from "./UserSearch.model"
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { operatorRequiresValue } from "./UserSearchFilters"

const DivFullWidth = styled.div`
	width: 100%;
`

const S = {
	FilterContainer: styled.div`
		border: solid 1px var(--light-grey);
		border-radius: 8px;
		padding: 6px;
		margin-right: 6px;
		display: inline-block;
		margin-bottom: 6px;
	`,
	FilterInnerContainer: styled.div`
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		align-items: center;
	`
}

interface Props {
	filter: Filter
	isFirstFilter: boolean
	removeFilter: () => void
	setActive: () => void
}

export default function InactiveFilter({ filter, isFirstFilter, removeFilter, setActive }: Props) {
	const CommonValueView = (
		<>
			<Tag>{filter.values[0]}</Tag>
			{filter.values.length > 1 && <Tag>+{filter.values.length - 1}</Tag>}
		</>
	)

	const SelectMultipleValueView = (
		<>
			{(filter.valueLabels || filter.values).map((value, index) => (
				<div key={index}>
					<Tag>{value}</Tag>
				</div>
			))}
		</>
	)

	const shouldShowValue = operatorRequiresValue(filter.operator)

	return (
		<Container>
			<span style={{ marginRight: 10 }}>
				<EditOutlined onClick={setActive} />
			</span>

			{isFirstFilter ? <Tag color="blue">WHERE</Tag> : <Tag color="volcano">AND</Tag>}
			{COLUMN_LABEL_BY_MEMBER[filter.member]}

			<span style={{ marginLeft: 5, marginRight: 5 }}>{OPERATOR_LABEL[filter.operator]}</span>

			{shouldShowValue && filter.inputType !== "selectMultiple" && CommonValueView}
			{shouldShowValue && filter.inputType === "selectMultiple" && SelectMultipleValueView}

			<CloseOutlined onClick={removeFilter} />
		</Container>
	)
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<DivFullWidth>
			<S.FilterContainer>
				<S.FilterInnerContainer>{children}</S.FilterInnerContainer>
			</S.FilterContainer>
		</DivFullWidth>
	)
}
