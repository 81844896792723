import React from "react"
import styled from "styled-components"
import SingleSurveyCard from "./SingleSurveyCard"
import SingleSurveyChartAndTotal from "./SingleSurveyChartAndTotal"

const Panel = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	min-width: 0;
	width: 100%;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
`

const SingleSurveyPanel = ({ cardData, questionId, surveyContent }) => {
	return (
		<Panel>
			<SingleSurveyCard surveyContent={surveyContent.filter((item) => item.card_id === questionId)[0]} />

			<SingleSurveyChartAndTotal
				cardData={cardData}
				questionId={questionId}
				surveyContent={surveyContent.filter((item) => item.card_id === questionId)[0]}
			/>
		</Panel>
	)
}
export default SingleSurveyPanel
