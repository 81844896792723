import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import TimeFilter from "./TimeFilter"
import UserGroupFilter from "./UserGroupFilter"
import PublishedCourseFilter from "./PublishedCourseFilter"

import { selectOrgUserGroups, selectOrgCourseCards, selectSequenceList } from "../../../redux/org/org.selectors"

import SequenceFilter from "./SequenceFilter"
import TimeStartedFilter from "./TimeStartedFilter"

const DashboardNav = styled.div`
	min-width: calc(100vw - 230px);
	width: 100%;
	background: white;
	box-shadow: 0px 1px 2px #42424291;
	z-index: 25;
	display: flex;
	align-items: center;
	@media screen and (max-width: 850px) {
		padding-left: 50px;
		flex-wrap: wrap;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-right: 50px;
		position: relative;
	}
	@media screen and (min-width: 850px) {
		height: 60px;
		position: fixed;
	}
`

const FilterContainer = styled.div`
	@media screen and (max-width: 850px) {
		width: 100%;
		margin: 5px;
	}
	@media screen and (min-width: 850px) {
		max-width: 25%;
		flex: 1;
		margin: 10px;
	}
`

// Main component
// =============
const AnalysisNavWithFilters = ({
	filterSettings: {
		showTimeSinceFilter,
		showTimeStartedFilter,
		showUserGroupsFilter,
		showCourseFilter,
		showSequenceFilter
	},
	sequenceList
}) => {
	return (
		<DashboardNav>
			{showTimeSinceFilter && (
				<FilterContainer>
					<TimeFilter />
				</FilterContainer>
			)}

			{showTimeStartedFilter && (
				<FilterContainer>
					<TimeStartedFilter />
				</FilterContainer>
			)}

			{showUserGroupsFilter && (
				<FilterContainer>
					<UserGroupFilter />
				</FilterContainer>
			)}

			{showCourseFilter && (
				<FilterContainer>
					<PublishedCourseFilter />
				</FilterContainer>
			)}

			{showSequenceFilter && sequenceList.length > 0 && (
				<FilterContainer>
					<SequenceFilter />
				</FilterContainer>
			)}
		</DashboardNav>
	)
}

AnalysisNavWithFilters.propTypes = {
	filterSettings: PropTypes.shape({
		showTimeSinceFilter: PropTypes.bool,
		showUserGroupsFilter: PropTypes.bool,
		showCourseFilter: PropTypes.bool
	})
}

AnalysisNavWithFilters.defaultProps = {
	filterSettings: {
		showTimeSinceFilter: true,
		showUserGroupsFilter: true,
		showCourseFilter: true
	},
	orgUserGroups: {},
	orgCourseCards: {}
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups,
	orgCourseCards: selectOrgCourseCards,
	sequenceList: selectSequenceList
})

export default connect(mapStateToProps)(AnalysisNavWithFilters)
