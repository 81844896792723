// POPUP MODAL TEXT BOX

import React, { useState } from "react"
import Popup from "reactjs-popup"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Button, Form, Input, Typography } from "antd"

const { Title } = Typography

const EditLinkBtn = styled.button`
	flex: 1 20%;
	background-color: Transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	display: flex;
	justify-content: flex-end;
	padding-right: 4px;
	font-size: 14px;
	font-weight: 600;
	color: var(--mid-grey);
`
const ModalBox = styled.div`
	border-radius: 10px;
	background: white;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px 30px 6px 30px;
`
const CloseButton = styled.a`
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: 12px;
	top: 12px;
	font-size: 18px;
	color: var(--dark-grey);
	border-radius: 50%;
	background: none;
	border: 0px solid #cfcece;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

const CbModalTextBox = ({
	children,
	modalTitle,
	handleNewText, // function passed from parent
	type = "",
	buttonTitle,
	placeholder
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const openModal = () => setIsOpen(true)
	const closeModal = () => {
		setIsOpen(false)
	}

	const handleSubmit = (values) => {
		const { text } = values
		handleNewText(text)
		closeModal()
	}

	return (
		<React.Fragment>
			{type === "button" ? (
				<Button type="primary" size="large" shape="round" onClick={openModal}>
					{children}
				</Button>
			) : (
				<EditLinkBtn onClick={openModal}>{children}</EditLinkBtn>
			)}

			<Popup open={isOpen} closeOnDocumentClick onClose={closeModal} className="reactJsModal">
				<ModalBox>
					<CloseButton onClick={() => closeModal()}>
						<FontAwesomeIcon icon={faTimes} color="var(--dark-grey)" />
					</CloseButton>
					<Title level={5}>{modalTitle}</Title>
					<Form size="large" autoComplete="off" onFinish={handleSubmit} layout="vertical">
						<Form.Item name="text" rules={[{ required: true, message: "You can't leave this blank" }]}>
							<Input placeholder={placeholder} />
						</Form.Item>
						<Form.Item>
							<ButtonContainer>
								<Button size="large" shape="round" onClick={() => closeModal()}>
									Cancel
								</Button>
								<div style={{ height: "100%", width: 10 }}></div>
								<Button type="primary" htmlType="submit" size="large" shape="round">
									{buttonTitle}
								</Button>
							</ButtonContainer>
						</Form.Item>
					</Form>
				</ModalBox>
			</Popup>
		</React.Fragment>
	)
}

export default CbModalTextBox
