import React, { useMemo } from "react"
import { OrderBy } from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import InfiniteScrollTable from "../../components/common/infiniteScrollTable/InfiniteScrollTable"
import { CellDisplay, ColumnDefinition, __User, HeaderColumn, ColumnLabel } from "./UserSearch.model"
import { createStructuredSelector } from "reselect"
import {
	selectLearningPathById,
	selectOrgUserGroupColorsMap,
	selectOrgUserGroupNamesMap
} from "../../redux/org/org.selectors"
import { connect } from "react-redux"
import UserSearchStats from "./UserSearchStats"
import { ColumnDefinitionParams, getColumnDefinitionsByLabel } from "./UserSearchColumns"

type Props = ColumnDefinitionParams & {
	fetchMoreUsers: () => void
	users: __User[]
	userCount: number
	orderBy: OrderBy | undefined
	handleChangeOrderBy: (orderBy: OrderBy | undefined) => void
	height: number
	hiddenColumns: ColumnLabel[]
	leftOptions?: React.ReactNode
	rightOptions?: React.ReactNode
}

function UserSearchTable({
	fetchMoreUsers,
	users,
	userCount,
	orderBy,
	handleChangeOrderBy,
	height,
	hiddenColumns,
	rightOptions,
	userGroupLabels,
	userGroupColours,
	learningPathById,
	allUsersSelected,
	selectedUserIds,
	unselectedUserIds,
	handleSelectedUsers,
	openDrawer,
	openMessageModal
}: Props) {
	const columnDefinitionByLabel: Record<ColumnLabel, ColumnDefinition> = useMemo(() => {
		return getColumnDefinitionsByLabel({
			userGroupLabels,
			userGroupColours,
			learningPathById,
			allUsersSelected,
			selectedUserIds,
			unselectedUserIds,
			handleSelectedUsers,
			openDrawer,
			openMessageModal
		})
	}, [userGroupColours, allUsersSelected, selectedUserIds, unselectedUserIds])

	const userColumns: HeaderColumn[] = useMemo(() => {
		return ColumnLabel.options.map((label, index) => {
			const columnDefinition = columnDefinitionByLabel[label]
			const header: HeaderColumn = {
				Header: columnDefinition.buildHeader({ label, index, orderBy, handleChangeOrderBy }),
				accessor: label
			}

			if (columnDefinition.width) header.width = columnDefinition.width
			if (columnDefinition.minWidth) header.minWidth = columnDefinition.minWidth
			if (columnDefinition.maxWidth) header.maxWidth = columnDefinition.maxWidth

			return header
		})
	}, [orderBy, columnDefinitionByLabel, handleChangeOrderBy])

	const userItems = React.useMemo(() => {
		const getUserItems = (user: __User, index: number) => {
			const userData: Record<ColumnLabel, CellDisplay> = {} as Record<ColumnLabel, string>
			ColumnLabel.options.forEach((label: ColumnLabel) => {
				userData[label] = columnDefinitionByLabel[label].buildRow(user, index)
			})
			return userData
		}

		return users.map(getUserItems)
	}, [columnDefinitionByLabel, users])

	return (
		<InfiniteScrollTable
			columns={userColumns}
			hiddenColumns={hiddenColumns}
			items={userItems}
			fetchMoreItems={fetchMoreUsers}
			hasMore={userCount > users.length}
			tableHeight={height}
			leftOptions={<UserSearchStats {...{ userCount, allUsersSelected, unselectedUserIds, selectedUserIds }} />}
			rightOptions={rightOptions}
			fixedColumns={["Checkbox", "Notify"] as ColumnLabel[]}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	userGroupColours: selectOrgUserGroupColorsMap,
	userGroupLabels: selectOrgUserGroupNamesMap,
	learningPathById: selectLearningPathById
})

// @ts-ignore
export default connect(mapStateToProps)(UserSearchTable)
