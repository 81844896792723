import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Menu, Button, Select, Tag, Dropdown, DatePicker, Input, InputNumber, Space } from "antd"
import { CloseOutlined } from "@ant-design/icons"

import { selectOrgId, selectOrgUserGroups, selectOrgCourseCards } from "../../../redux/org/org.selectors"

const { TextArea } = Input

const Container = styled.div`
	width: 96%;
	text-align: left;
	padding-left: 1rem;
	padding-right: 1rem;
`
const FilterRow = styled.div`
	width: 100%;
`
const FilterContainer = styled.div`
	border: solid 1px var(--light-grey);
	border-radius: 8px;
	padding: 6px;
	margin-right: 6px;
	display: inline-block;
	margin-bottom: 6px;
`
const FilterInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
`
const ActiveFilterContainer = styled.div`
	border: solid 1px var(--light-grey);
	padding: 6px;
	display: inline-block;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
`
const ActiveFilterInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`

const members = [
	{
		value: "userGroups",
		label: "User groups"
	},
	{
		value: "appDownloaded",
		label: "Downloaded app"
	},
	{
		value: "lastActive",
		label: "Last active"
	},
	{
		value: "phoneNumber",
		label: "Phone number"
	},
	{
		value: "userId",
		label: "User id"
	},
	{
		value: "customId",
		label: "Custom id"
	},
	{
		value: "registeredAt",
		label: "Registration date"
	}
]

const booleanOperators = [
	{
		value: "equals",
		label: "="
	},
	{
		value: "notEquals",
		label: "doesn't equal"
	}
]

const includesOperators = [
	{
		value: "includes",
		label: "includes"
	},
	{
		value: "notIncludes",
		label: "does not include"
	}
]

const beforeAfterOperators = [
	{
		value: "lt",
		label: "before"
	},
	{
		value: "gt",
		label: "after"
	}
]

const trueFalseOptions = [
	{ value: true, label: "true" },
	{ value: false, label: "false" }
]

const TableFilter = ({ filters, filterMembers, removeFilter, setNewFilter, orgUserGroups, orgCourseCards }) => {
	const [activeFilter, setActiveFilter] = useState(null)

	const setFilter = () => {
		setNewFilter(activeFilter)
		setActiveFilter(null)
	}

	const setListInput = (value) => {
		const listFormatted = value.replace(/\n/g, ",")
		const listArray = listFormatted.split(",")
		const listArrayFiltered = listArray.map((item) => item.trim()).filter((item) => item.length > 0)
		setActiveFilter({
			...activeFilter,
			values: listArrayFiltered
		})
	}

	const userGroupOptions = []
	Object.keys(orgUserGroups).forEach((userGroupId) => {
		if (userGroupId !== "registered" && orgUserGroups[userGroupId] && orgUserGroups[userGroupId].user_group_info) {
			userGroupOptions.push({
				value: userGroupId,
				label: orgUserGroups[userGroupId].user_group_info.name
			})
		}
	})

	const publishedCourseOptions = []
	if (orgCourseCards) {
		Object.keys(orgCourseCards).forEach((courseId) => {
			if (
				orgCourseCards[courseId].info.publish_url !== null &&
				!orgCourseCards[courseId].info.user_groups.view_groups.includes("archived")
			) {
				publishedCourseOptions.push({
					value: courseId,
					label: orgCourseCards[courseId].info.name
				})
			}
		})
	}

	const memberOptions = {
		userGroups: {
			label: "User groups",
			operators: includesOperators,
			values: userGroupOptions
		},
		appDownloaded: {
			label: "Downloaded app",
			operators: booleanOperators,
			values: trueFalseOptions
		},
		lastActive: {
			label: "Last active",
			operators: beforeAfterOperators,
			values: "datePicker"
		},
		phoneNumber: {
			label: "Phone number",
			operators: includesOperators,
			values: "listInput"
		},
		userId: {
			label: "User id",
			operators: includesOperators,
			values: "listInput"
		},
		customId: {
			label: "Custom id",
			operators: includesOperators,
			values: "listInput"
		},
		registeredAt: {
			label: "Registration date",
			operators: beforeAfterOperators,
			values: "datePicker"
		}
	}

	const items = members.filter((item) => filterMembers.includes(item.value))

	const menuItems = items.map((item) => {
		const menuItem = {
			label: (
				<div
					onClick={() =>
						setActiveFilter({
							member: item.value,
							operator: memberOptions[item.value].operators
								? memberOptions[item.value].operators[0].value
								: "equals",
							values: null
						})
					}
				>
					{item.label}
				</div>
			),
			key: item.value
		}
		return menuItem
	})

	return (
		<Container>
			<FilterRow>
				{filters.map((item, index) => {
					const memberLabel = memberOptions[item.member].label
					const operatorLabel = memberOptions[item.member].operators
						? memberOptions[item.member].operators.filter((row) => row.value === item.operator)[0].label
						: ""
					return (
						<FilterContainer key={index}>
							<FilterInnerContainer>
								{index > 0 ? <Tag color="volcano">AND</Tag> : <Tag color="blue">WHERE</Tag>}
								{memberLabel} <span style={{ marginLeft: 5, marginRight: 5 }}>{operatorLabel}</span>
								{memberOptions[item.member].values === "datePicker" && <Tag>{item.values[0]}</Tag>}
								{memberOptions[item.member].values === "numInput" && <Tag>{item.values[0]}</Tag>}
								{memberOptions[item.member].values === "listInput" && (
									<>
										<Tag>{item.values[0]}</Tag>
										{item.values.length > 1 && <Tag>+{item.values.length - 1}</Tag>}
									</>
								)}
								{memberOptions[item.member].values !== "datePicker" &&
									memberOptions[item.member].values !== "numInput" &&
									memberOptions[item.member].values !== "listInput" && (
										<>
											{item.values.map((value, index) => (
												<div key={index}>
													{index > 0 && <Tag color="cyan">OR</Tag>}
													<Tag>
														{
															memberOptions[item.member].values.filter(
																(row) => row.value === value
															)[0].label
														}
													</Tag>
												</div>
											))}
										</>
									)}
								<CloseOutlined
									onClick={() => {
										removeFilter(item)
									}}
								/>
							</FilterInnerContainer>
						</FilterContainer>
					)
				})}
			</FilterRow>
			{activeFilter ? (
				<ActiveFilterContainer>
					<ActiveFilterInnerContainer>
						<Space>
							{filters.length > 0 ? <Tag color="volcano">AND</Tag> : <Tag color="blue">WHERE</Tag>}
							{memberOptions[activeFilter.member].label}
							{memberOptions[activeFilter.member].operators && (
								<Select
									style={{ width: 100 }}
									defaultValue={memberOptions[activeFilter.member].operators[0].value}
									options={memberOptions[activeFilter.member].operators}
									onChange={(operator) => setActiveFilter({ ...activeFilter, operator })}
								/>
							)}
							{memberOptions[activeFilter.member].values === "datePicker" && (
								<DatePicker
									onChange={(date, dateString) =>
										setActiveFilter({ ...activeFilter, values: [dateString] })
									}
								/>
							)}
							{memberOptions[activeFilter.member].values === "numInput" && (
								<InputNumber
									onChange={(value) =>
										setActiveFilter({
											...activeFilter,
											values: [value.toString()]
										})
									}
								/>
							)}
							{memberOptions[activeFilter.member].values === "listInput" && (
								<TextArea
									onChange={(e) => setListInput(e.target.value)}
									autoSize={{ minRows: 1, maxRows: 6 }}
								/>
							)}
							{memberOptions[activeFilter.member].values !== "datePicker" &&
								memberOptions[activeFilter.member].values !== "numInput" &&
								memberOptions[activeFilter.member].values !== "listInput" && (
									<Select
										showSearch
										mode="multiple"
										showArrow={true}
										style={{ width: 300 }}
										options={memberOptions[activeFilter.member].values}
										onChange={(values) => setActiveFilter({ ...activeFilter, values })}
										filterOption={(input, option) =>
											option.label.toLowerCase().includes(input.toLowerCase())
										}
									/>
								)}
							{activeFilter && activeFilter.member && activeFilter.operator && activeFilter.values && (
								<Button type="primary" onClick={() => setFilter()}>
									Add
								</Button>
							)}
							<Button onClick={() => setActiveFilter(null)}>X</Button>
						</Space>
					</ActiveFilterInnerContainer>
				</ActiveFilterContainer>
			) : (
				<Dropdown overlay={<Menu items={menuItems} />} trigger={["click"]}>
					<Button>Add filter</Button>
				</Dropdown>
			)}
		</Container>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups,
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(TableFilter)
