import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const CourseContentImgContainer = styled.div`
	width: 100px;
	height: 140px;
	padding: 10px;
`
const CourseContentImgBackground = styled.div`
	width: 85px;
	height: 115px;
	background: ${(props) => props.background};
	border-radius: 12px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
`
const CourseContentImg = styled.div`
	width: 85px;
	height: 100px;
	background: url("${(props) => props.image}");
	background-size: cover;
	background-position: center center;
	border-radius: 12px;
	margin-top: -116px;
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
`

const CbCourseCardImage = ({ backgroundColor, image }) => {
	return (
		<CourseContentImgContainer>
			<CourseContentImgBackground background={backgroundColor}></CourseContentImgBackground>
			<CourseContentImg image={image}></CourseContentImg>
		</CourseContentImgContainer>
	)
}

CbCourseCardImage.propTypes = {
	backgroundColor: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired
}

CbCourseCardImage.defaultProps = {
	backgroundColor: "var(--secondary-color)",
	image: "https://farminkweb.s3.eu-west-2.amazonaws.com/default-course-img.svg"
}

export default CbCourseCardImage
