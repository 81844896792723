import React, { useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import styled from "styled-components"
import { createStructuredSelector } from "reselect"
import { Button, Typography, Alert } from "antd"

import { signInWithEmailAndPassword } from "../../firebase/firebase.util"

import { selectIsUserAuthenticated } from "../../redux/user/user.selectors"

import LpTextInput from "../../components/common/LpTextInput"
import AuthContainer from "./AuthContainer"

const { Title } = Typography

const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`
const InputContainer = styled.div`
	text-align: left;
	margin-top: 20px;
	width: 100%;
`
const LinkContainer = styled.div`
	width: 100%;
	font-size: 16px;
	padding-top: 20px;
	color: var(--light-grey);
	a {
		font-weight: 500;
		:hover {
			color: var(--secondary-color);
		}
	}
	display: flex;
	justify-content: space-around;
`

const SignIn = ({ isUserAuthenticated }) => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [errorMsg, setErrorMsg] = useState(null)

	const handleEmailChange = (text) => {
		setEmail(text.trim())
		setErrorMsg(null)
	}

	const handlePasswordChange = (text) => {
		setPassword(text)
		setErrorMsg(null)
	}

	const handleEmailEnterAction = (e) => {
		console.log("Enter")
	}

	const handlePasswordEnterAction = (e) => {
		LoginUser()
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		LoginUser()
	}

	const LoginUser = () => {
		signInWithEmailAndPassword(email, password)
			.then(() => {
				console.log("firebase signin with email success...")
			})
			.catch((err) => {
				setErrorMsg(err)
			})
	}

	if (isUserAuthenticated === true) return <Redirect to={"/"} />

	return (
		<AuthContainer>
			<Title level={2} style={{ width: "100%" }}>
				Welcome back!
			</Title>
			<InputContainer>
				<LpTextInput
					autoFocus={true}
					inputType="email"
					labelText="Email address"
					placeholderText="Email"
					handleTextChange={handleEmailChange}
					handleEnterAction={handleEmailEnterAction}
				/>
			</InputContainer>
			<InputContainer>
				<LpTextInput
					autoFocus={true}
					inputType="password"
					labelText="Password"
					placeholderText="Password"
					handleTextChange={handlePasswordChange}
					handleEnterAction={handlePasswordEnterAction}
				/>
			</InputContainer>
			{errorMsg && <Alert message={errorMsg} type="error" style={{ marginTop: 20 }} />}
			<ButtonContainer>
				<Button type="primary" size="large" shape="round" block onClick={handleSubmit}>
					Sign in
				</Button>
			</ButtonContainer>
			<LinkContainer>
				<Link to={"/passwordreset"}>Forgot Password?</Link> | <Link to={"/signup"}>Need a magic link?</Link>
			</LinkContainer>
		</AuthContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	isUserAuthenticated: selectIsUserAuthenticated
})

export default connect(mapStateToProps)(SignIn)
