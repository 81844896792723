// scr/firebase/firebase.util.js

import firebase from "firebase/app"
import "firebase/auth"
import axios from "axios"

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APPLICATION_ID
}

firebase.initializeApp(config)

// for general auth
export const auth = firebase.auth()

// for Email Signup
export const createUserWithEmailAndPassword = (email, password, name) => {
	return auth
		.createUserWithEmailAndPassword(email, password) // https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#createuserwithemailandpassword
		.then((res) => {
			const user = res.user
			user.updateProfile({ displayName: name }).then(() => {
				user.sendEmailVerification({
					url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
				})
				// update DB with new user entry
				user.getIdToken().then((token) => {
					let instance = axios.create({
						baseUrl: "" // probably best to refer to as API_URL in future
					})
					instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
					instance
						.post("/api/user/create", {
							name: name
						})
						.then((res) => {
							console.log("res from createNewUser: ", res)
						})
				})
			})
		})
		.catch((error) => {
			let inputError = null
			const errorCode = error.code
			const errorMessage = error.message
			if (errorCode === "auth/weak-password") {
				inputError = "The password is too weak"
			} else if (errorCode === "auth/email-already-in-use") {
				inputError = "This email has already been used"
			} else if (errorCode === "auth/invalid-email") {
				inputError = "Sorry that doesn't look like a valid email"
			} else {
				inputError = errorMessage
			}
			throw inputError
		})
}

// for Email Signin
export const signInWithEmailAndPassword = (email, password) => {
	return auth
		.signInWithEmailAndPassword(email, password) // https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#signinwithemailandpassword
		.then((res) => {
			console.log("Signin success! ", res)
		})
		.catch((error) => {
			let inputError = null
			const errorCode = error.code
			const errorMessage = error.message
			if (errorCode === "auth/wrong-password") {
				inputError = "Wrong password"
			} else if (errorCode === "auth/user-not-found") {
				inputError = "We can't find this user"
			} else if (errorCode === "auth/invalid-email") {
				inputError = "Sorry that doesn't look like a valid email"
			} else {
				inputError = errorMessage
			}
			console.log("Error witn signInWithEmailAndPassword... ", error)
			throw inputError
		})
}

// for Email reset link
export const sendPasswordResetEmail = (email) => {
	return auth
		.sendPasswordResetEmail(email) // https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#sendpasswordresetemail
		.then(() => {
			console.log("Password reset success!")
		})
		.catch((error) => {
			let inputError = null
			const errorCode = error.code
			const errorMessage = error.message
			if (errorCode === "auth/user-not-found") {
				inputError = "We can't find this user"
			} else if (errorCode === "auth/invalid-email") {
				inputError = "Sorry that doesn't look like a valid email"
			} else {
				inputError = errorMessage
			}
			throw inputError
		})
}

// for Sign Out
export const signOut = () =>
	auth.signOut().then(
		() => {
			console.log("firebase auth service logged user out")
		},
		(err) => {
			console.log(err)
		}
	)

export const deleteUser = () => {
	// we don't use the firebase client SDK methods on purpose here
	// do it on the firebase server SDK instead
	// the delete action depends on headers being set with authorization
	axios
		.delete("/api/user/")
		.then(() => {
			console.log("user deleted forever!!...")
		})
		.catch((err) => {
			console.log(err)
		})
}

export const sendVerificationEmail = () => {
	axios
		.post("/api/user/sendemailverification")
		.then(() => {
			console.log("user sent email verification from server...")
		})
		.catch((err) => {
			console.log(err)
		})
}

export default firebase
