// COMPONENT TO SHOW WHEN SUBSCRIPTION PAYMENT IS CONFIRMED
import React from "react"
import { Result, Button } from "antd"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link } from "react-router-dom"

import { selectSubscription } from "../../redux/subscription/subscription.selectors"
import { selectOrgId } from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: 1px;
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
	background: white;
	text-align: center;
`

const SubscriptionSuccess = ({ orgId, subscription }) => {
	return (
		<S.Container>
			<CourseListInner>
				<CourseListEmpty>
					<Result
						status="success"
						title="Your Learn.ink subscription is live!"
						subTitle="It's time to get creating! Press the button to go to your console or check out our help center..."
						extra={[
							<Link
								to={{
									pathname: `/org/${orgId}/create/courses`
								}}
								key="courses"
							>
								<Button type="primary" key="console" size="large" shape="round">
									Go to console
								</Button>
							</Link>,
							<a href="https://help.learn.ink" target="_blank" rel="noopener noreferrer" key="help">
								<Button key="docs" size="large" shape="round">
									Open help center
								</Button>
							</a>
						]}
					/>
				</CourseListEmpty>
			</CourseListInner>
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	subscription: selectSubscription,
	orgId: selectOrgId
})

export default connect(mapStateToProps)(SubscriptionSuccess)
