import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import { UUID } from "learnink-common/lib/types/types"
import { CourseCards } from "../../../util/model/OrgSelectors"

export class SequenceContent {
	rawContent: Sequence

	constructor(rawContent: Sequence) {
		this.rawContent = rawContent || {}
	}

	getCourseModules(courseIds: UUID[], courseCards: CourseCards) {
		let moduleStageIds: UUID[] = []
		courseIds.forEach((courseId) => {
			moduleStageIds = [...moduleStageIds, ...courseCards[courseId].moduleStageIds]
		})
		return moduleStageIds
	}

	getNumModuleStages(courseCards: CourseCards) {
		const sequence = this.rawContent
		let moduleStageIds: UUID[] = []
		sequence.content.forEach((item) => {
			if (item.type === "course") {
				moduleStageIds = [...moduleStageIds, ...courseCards[item.targetId].moduleStageIds]
			}
		})
		return moduleStageIds.length
	}

	getTotalTrainingTimeMins(courseCards: CourseCards) {
		const trainingMins = this.getNumModuleStages(courseCards) * 12
		return trainingMins
	}

	addCourseToSequence(newCourseId: string, newCourseIndex: number) {
		this.rawContent.content.splice(newCourseIndex, 0, {
			targetId: newCourseId,
			type: "course"
		})
		return this.rawContent
	}

	removeCourseFromSequence(courseIndex: number) {
		this.rawContent.content.splice(courseIndex, 1)
		return this.rawContent
	}
}
