import React from "react"
import styled from "styled-components"
import * as S from "../../styles/PageStyles"

import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Radio } from "antd"
import Settings from "./Settings"
import Library from "./Library"
import Timeline from "./Timeline"
import { EditorColumnState, ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"

const NavContainer = styled.div`
	width: 100%;
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
`

interface Props {
	learningPath: LearningPath
	editorColumnState: EditorColumnState
	setEditorColumnState: React.Dispatch<React.SetStateAction<EditorColumnState>>
	setSequenceIndex: React.Dispatch<React.SetStateAction<number | undefined>>
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	updateLearningPath: (learningPath: LearningPath) => void
}

const EditorColumn = ({
	learningPath,
	editorColumnState,
	setEditorColumnState,
	setSequenceIndex,
	setModalType,
	updateLearningPath
}: Props) => {
	return (
		<S.BuilderColumn>
			<S.BuilderColumnInner style={{ backgroundColor: "var(--off-white)" }}>
				<NavContainer>
					<Radio.Group
						onChange={(e) => setEditorColumnState(e.target.value)}
						value={editorColumnState}
						buttonStyle="solid"
					>
						<Radio.Button value="timeline">Timeline</Radio.Button>
						<Radio.Button value="courseLibrary">Library</Radio.Button>
						<Radio.Button value="settings">Settings</Radio.Button>
					</Radio.Group>
				</NavContainer>
				{editorColumnState === "timeline" && (
					<Timeline
						learningPath={learningPath}
						setSequenceIndex={setSequenceIndex}
						setModalType={setModalType}
					/>
				)}
				{editorColumnState === "courseLibrary" && <Library learningPath={learningPath} />}
				{editorColumnState === "settings" && (
					<Settings learningPath={learningPath} updateLearningPath={updateLearningPath} />
				)}
			</S.BuilderColumnInner>
		</S.BuilderColumn>
	)
}

export default EditorColumn
