import React from "react"
import useUrlQueryString from "../../../hooks/useUrlQueryString"
import ChartWrapper from "../../common/ChartWrapper"
import { Select, Space } from "antd"

const ModuleQuestionSelectPanel = ({ moduleOptions, tooltip, title }) => {
	// pull key params from the URL query string
	const [moduleIds, setModuleIds] = useUrlQueryString("moduleIds")

	const handleModuleChange = (newValue) => {
		if (newValue.length > 0) {
			setModuleIds(newValue.join(","))
		} else {
			setModuleIds(null)
		}
	}

	const calcModuleIdsValue =
		moduleIds && moduleIds.split(",").length > 0
			? moduleIds.split(",").filter((i) => moduleOptions.map((j) => j.value).indexOf(i) !== -1)
			: []

	return (
		<ChartWrapper tooltip={tooltip} title={title}>
			<Space
				direction="vertical"
				style={{
					width: "100%"
				}}
			>
				<Select
					mode="multiple"
					showArrow={true}
					showSearch={false}
					style={{ width: "100%" }}
					value={calcModuleIdsValue}
					options={moduleOptions}
					onChange={handleModuleChange}
					placeholder="Select Module..."
					maxTagCount="responsive"
				/>
			</Space>
		</ChartWrapper>
	)
}

export default ModuleQuestionSelectPanel
