import React, { useEffect } from "react"
import { VoiceClipType, VoiceProfile } from "./VoicePanel"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { Card } from "learnink-common/lib/domains/cards/Card.model"

export const TTS_ENABLED_LANGUAGES = ["en", "eng", "fra", "por", "spa", "hin", "ind", "fil", "ara", "tam", "vie"]

export const getFirstVoiceClipForLanguage = (card: Card, languageCode: LanguageCode): VoiceClip | undefined => {
	const cardVoiceClips = card.voiceClips || []
	let voiceClip =
		cardVoiceClips.filter((item) => item.languageCode === languageCode).length > 0
			? cardVoiceClips.filter((item) => item.languageCode === languageCode)[0]
			: undefined
	return voiceClip
}

export const getCurrentVoiceClipType = (
	voiceProfileDefaults: VoiceProfile[] | undefined,
	voiceClip: VoiceClip | undefined
) => {
	const hasTextToSpeechVoice =
		voiceClip && voiceProfileDefaults && voiceProfileDefaults.map((item) => item.id).includes(voiceClip.voiceId)
	if (hasTextToSpeechVoice) return "textToSpeech"
	else if (voiceClip) return "custom"
	return undefined
}

export function useSetVoiceClipType(
	voiceProfileDefaults: VoiceProfile[] | undefined,
	voiceClip: VoiceClip | undefined,
	languageCode: LanguageCode,
	voiceClipType: VoiceClipType | undefined,
	setVoiceClipType: any
) {
	useEffect(() => {
		if (voiceProfileDefaults) {
			let currentVoiceClipType = getCurrentVoiceClipType(voiceProfileDefaults, voiceClip)
			if (currentVoiceClipType && voiceClipType !== currentVoiceClipType) {
				setVoiceClipType(currentVoiceClipType)
			}
			if (
				!currentVoiceClipType &&
				TTS_ENABLED_LANGUAGES.includes(languageCode) &&
				voiceClipType !== "textToSpeech"
			) {
				setVoiceClipType("textToSpeech")
			}
			if (!currentVoiceClipType && !TTS_ENABLED_LANGUAGES.includes(languageCode) && voiceClipType !== "custom") {
				setVoiceClipType("custom")
			}
		}
	}, [voiceProfileDefaults, languageCode, voiceClip])
}

const findFirstMatchingVoiceId = (
	lessonCards: LessonCard[],
	voiceIds: string[],
	from: "teacher" | "learner",
	languageCode: LanguageCode
): string | undefined => {
	const filteredLessonCards = lessonCards.filter(
		(item) =>
			item.from === from &&
			item.voiceClips &&
			item.voiceClips.filter((e) => e.languageCode === languageCode).length > 0
	)
	// Iterate through the lesson cards
	for (const card of filteredLessonCards) {
		if (card.voiceClips) {
			// Find the first voice clip with a matching voiceId in the voiceIds array
			const matchingClip = card.voiceClips.find((clip) => voiceIds.includes(clip.voiceId))
			if (matchingClip) {
				return matchingClip.voiceId // Return the first matching voiceId
			}
		}
	}
	return undefined // No matching voiceId found
}

const getVoiceProfilesFromClip = (
	voiceClip: VoiceClip | undefined,
	card: Card,
	voiceProfileDefaults: VoiceProfile[]
) => {
	let teacherVoiceId = undefined
	let learnerVoiceId = undefined
	if (
		voiceClip &&
		voiceProfileDefaults.map((item) => item.id).includes(voiceClip.voiceId) &&
		// @ts-ignore
		card.from !== "learner"
	) {
		return { teacherVoiceId: voiceClip.voiceId, learnerVoiceId }
	}
	if (
		voiceClip &&
		voiceProfileDefaults.map((item) => item.id).includes(voiceClip.voiceId) &&
		// @ts-ignore
		card.from === "learner"
	) {
		return { teacherVoiceId, learnerVoiceId: voiceClip.voiceId }
	}
	return { teacherVoiceId, learnerVoiceId }
}

export function useSetTeacherLearnerVoiceProfiles(
	voiceProfileDefaults: VoiceProfile[] | undefined,
	voiceClip: VoiceClip | undefined,
	card: Card,
	stage: any,
	teacherVoiceProfileId: string | undefined,
	setTeacherVoiceProfileId: any,
	learnerVoiceProfileId: string | undefined,
	setLearnerVoiceProfileId: any,
	languageCode: LanguageCode
) {
	useEffect(() => {
		if (voiceProfileDefaults) {
			let { teacherVoiceId, learnerVoiceId } = getVoiceProfilesFromClip(voiceClip, card, voiceProfileDefaults)
			const lessonCards: LessonCard[] = Object.values(stage.lesson_cards)
			const defaultVoiceProfileIds = voiceProfileDefaults.map((item) => item.id)
			if (!teacherVoiceId) {
				teacherVoiceId =
					findFirstMatchingVoiceId(lessonCards, defaultVoiceProfileIds, "teacher", languageCode) ||
					defaultVoiceProfileIds[0]
			}
			if (!learnerVoiceId) {
				learnerVoiceId =
					findFirstMatchingVoiceId(lessonCards, defaultVoiceProfileIds, "learner", languageCode) ||
					defaultVoiceProfileIds[1]
			}
			if (teacherVoiceId !== teacherVoiceProfileId) {
				setTeacherVoiceProfileId(teacherVoiceId)
			}
			if (learnerVoiceId !== learnerVoiceProfileId) {
				setLearnerVoiceProfileId(learnerVoiceId)
			}
		}
	}, [voiceProfileDefaults, card])
}
