import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { updateLessonCard } from "../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../redux/courses/courses.selectors"

import PanelContainer from "./common/PanelContainer"
import SegmentSetting from "./common/SegmentSetting"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"

interface Props {
	card: LessonCard
	stage: any
	updateLessonCard: (updateParams: LessonCard) => void
}

const TeacherLearnerSelect = ({ card, stage, updateLessonCard }: Props) => {
	return (
		<PanelContainer title="From">
			<SegmentSetting
				onChange={(val: "teacher" | "learner") => updateLessonCard({ ...card, from: val })}
				value={card.from}
				id="editor_teacher_learner_select"
				imageStyle={{ borderRadius: 99 }}
				options={[
					{
						label: "Teacher",
						value: "teacher",
						image: stage.teacherLearnerImgs.teacherImg
					},
					{
						label: "Learner",
						value: "learner",
						image: stage.teacherLearnerImgs.learnerImg
					}
				]}
			/>
		</PanelContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: LessonCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(TeacherLearnerSelect)
