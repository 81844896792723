import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Tag, Table, Drawer, Input, Button, Checkbox, Space } from "antd"
import { ColumnsType } from "antd/lib/table"
import { selectOrgUserGroupsInfo } from "../../../redux/management/management.selectors"
import UserGroupSidePanel from "./UserGroupSidePanel"
import { UUID } from "learnink-common/lib/types/types"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { EditOutlined } from "@ant-design/icons"
import { selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { Link } from "react-router-dom"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { PremiumFeatureAccess } from "../../../redux/org/org.model"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

const { Search } = Input

export interface UserGroupInfo extends UserGroup {
	userCount: number
	courseCount: number
	resourceCount: number
	courseIds: UUID[]
	courseNames: string[]
	resourceIds: UUID[]
	resourceNames: string[]
	accessCode: string
	accessCodes: string[]
	accessCodeCount: number
}

interface Props {
	orgId: string
	orgUserGroupsInfo: UserGroupInfo[]
	premiumFeatureAccess: PremiumFeatureAccess
	isAdmin: boolean
}

const columnOptions = [
	{ label: "Name", value: "name", disabled: true },
	{ label: "Description", value: "description" },
	{ label: "Users", value: "userCount" },
	{ label: "Courses", value: "courseCount" },
	{ label: "Access codes", value: "accessCodeCount" },
	{ label: "Learning paths", value: "learningPathCount" }
]

const UserGroupsTable = ({ orgId, orgUserGroupsInfo, premiumFeatureAccess, isAdmin }: Props) => {
	const [showDrawer, setShowDrawer] = useState<boolean>(false)
	const [selectedRow, setSelectedRow] = useState<UserGroupInfo | undefined>(undefined)
	const [searchInput, setSearchInput] = useState<string | undefined>(undefined)
	const [filteredData, setFilteredData] = useState<any>([])
	const [visibleColumns, setVisibleColumns] = useState<CheckboxValueType[]>([
		"name",
		"userCount",
		"learningPathCount",
		"action"
	])
	const [showColumnSelector, setShowColumnSelector] = useState<boolean>(false)

	let showRoles = false
	if (premiumFeatureAccess.learningPaths === "unlocked") {
		showRoles = true
	}
	if (premiumFeatureAccess.learningPaths === "hidden" && isAdmin) {
		showRoles = true
	}

	const columns: ColumnsType<UserGroupInfo> = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: string, record: UserGroupInfo) =>
				record.id === "folder" ? (
					<p style={{ fontWeight: "bold" }}>{text}</p>
				) : (
					<Tag color={record.color}>{text}</Tag>
				)
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: string, record: UserGroupInfo) =>
				record.id === "folder" ? <p style={{ color: "var(--mid-grey)", fontStyle: "italic" }}>{text}</p> : text
		},
		{
			title: "Users",
			dataIndex: "userCount",
			key: "userCount",
			width: "14%"
		},
		{
			title: "Courses",
			dataIndex: "courseCount",
			key: "courseCount"
		},
		{
			title: "Access codes",
			dataIndex: "accessCodeCount",
			key: "accessCodeCount"
		},
		{
			title: "Learning paths",
			dataIndex: "learningPathCount",
			key: "learningPathCount",
			width: "18%",
			render: (text: string, record: UserGroupInfo) =>
				record.type === "role" ? (
					<Space>
						{text}
						<Link to={`/org/${orgId}/create/learning-paths?userGroupId=${record.id}`}>
							<Button size="small">View</Button>
						</Link>
					</Space>
				) : (
					<></>
				)
		},
		{
			title: "",
			key: "action",
			width: "10%",
			render: (_: string, record: UserGroupInfo) =>
				record.id === "folder" ? (
					<></>
				) : (
					<Button
						icon={<EditOutlined />}
						size="small"
						onClick={() => {
							setSelectedRow(record)
							setShowDrawer(true)
						}}
					>
						Edit
					</Button>
				)
		}
	].filter((item) => visibleColumns.includes(item.key))

	useEffect(() => {
		let filteredData = orgUserGroupsInfo
		if (searchInput) {
			filteredData = orgUserGroupsInfo.filter((item) =>
				item.name.toLowerCase().includes(searchInput.toLowerCase())
			)
		}
		if (showRoles) {
			const treeData = [
				{
					name: "Roles",
					key: "roles",
					description: "Required: All users must have one role",
					id: "folder",
					children: filteredData.filter((item) => item.type === "role")
				},
				{
					name: "Tags",
					key: "tags",
					description: "Optional: Users can have multiple tags",
					id: "folder",
					children: filteredData.filter((item) => item.type !== "role")
				}
			]
			setFilteredData(treeData)
		} else {
			setFilteredData(filteredData)
		}
	}, [searchInput])

	return (
		<>
			<div className="row">
				<div className="col-sm-4" style={{ paddingBottom: 20 }}>
					<Search
						placeholder="Search..."
						onChange={(e) => setSearchInput(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
				<div className="col-sm-8" style={{ paddingBottom: 20, display: "flex", justifyContent: "flex-end" }}>
					<Button onClick={() => setShowColumnSelector(!showColumnSelector)}>Change columns</Button>
				</div>
				{showColumnSelector && (
					<div className="col-sm-12" style={{ paddingBottom: 20 }}>
						<Checkbox.Group
							options={columnOptions}
							value={visibleColumns}
							onChange={(val) => setVisibleColumns(val)}
						/>
					</div>
				)}
			</div>
			<Table
				columns={
					premiumFeatureAccess.learningPaths === "hidden" && !isAdmin
						? columns.filter((item) => item.key !== "learningPathCount")
						: columns
				}
				dataSource={filteredData}
				expandable={{ defaultExpandedRowKeys: ["roles"], expandRowByClick: true, indentSize: 6 }}
			/>
			<Drawer
				title="Settings"
				placement="right"
				onClose={() => {
					setShowDrawer(false)
				}}
				open={showDrawer}
			>
				{selectedRow && (
					<UserGroupSidePanel
						closemodal={() => {
							setShowDrawer(false)
						}}
						userGroupData={selectedRow}
					/>
				)}
			</Drawer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroupsInfo: selectOrgUserGroupsInfo,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	isAdmin: selectIsUserAdmin
})

// @ts-ignore
export default connect(mapStateToProps)(UserGroupsTable)
