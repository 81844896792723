import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import styled from "styled-components"
import { Button, Typography } from "antd"

const { Title } = Typography

const CardContainer = styled.div`
	height: 150px;
	width: 100%;
	background: ${(props) => (props.active ? "white" : "rgb(249, 250, 251)")};
	border: ${(props) => (props.active ? "solid 1px var(--secondary-color)" : "solid 1px var(--light-grey)")};
	display: block;
	border-radius: 8px;
	padding: 30px;
`
const CardContainerInner = styled.div`
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	height: 100%;
	box-sizing: border-box;
	display: flex;
`
const ButtonContainer = styled.div`
	height: 40px;
	width: 100%;
`

const OrgCard = ({ targetOrgId, orgName, isActiveOrg }) => {
	const [redirect, setRedirect] = useState(false)
	const handleOrgSwitch = (e) => {
		e.preventDefault()

		if (isActiveOrg) {
			setRedirect(true)
		} else {
			// Note: we use a window location JS method here
			// because it forces the whole app to refresh, and we want to
			// clear out any old state related to the last org, e.g. in
			// courses, onboarding, etc.
			window.location = `${process.env.REACT_APP_URL}/org/${targetOrgId}`
		}
	}

	if (redirect) return <Redirect to={`/org/${targetOrgId}`} />

	return (
		<CardContainer active={isActiveOrg}>
			<CardContainerInner>
				<Title level={5}>{orgName}</Title>
				<ButtonContainer>
					<Button
						type={isActiveOrg ? "default" : "primary"}
						size="large"
						block
						shape="round"
						onClick={handleOrgSwitch}
					>
						{isActiveOrg ? "Active" : "Select"}
					</Button>
				</ButtonContainer>
			</CardContainerInner>
		</CardContainer>
	)
}

export default OrgCard
