// MODAL TO DELETE FOLDER

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Button, Space, Alert, Typography } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
	selectOrgCourses,
	selectOrgCourseCards,
	selectOrgId,
	selectOrgCourseFolders
} from "../../../../redux/org/org.selectors"

import { deleteCourseFolder } from "../../../../redux/courses/courses.actions"

const { Title, Text } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 20px;
`

const DeleteFolder = ({
	folderId,
	orgId,
	courses,
	courseFolders: { folder_data },
	courseCards,
	closeModal,
	deleteCourseFolder
}) => {
	const childFolders = folder_data.filter((item) => item.course_folder_parent_id === folderId)
	const childCourses = courses.filter((courseId) => courseCards[courseId].course_folder_parent_id === folderId)
	const numItems = childCourses.length + childFolders.length

	const deleteFolder = async () => {
		try {
			await deleteCourseFolder(folderId, orgId)
			closeModal()
		} catch (err) {
			console.log(err)
			closeModal()
		}
	}

	return (
		<ContentContainer>
			<Title level={4}>Delete folder</Title>
			{numItems > 0 ? (
				<Alert
					message="You cannot delete this folder as there are other courses and/or folders inside it. Make sure this folder is empty before trying to delete it"
					type="warning"
				/>
			) : (
				<>
					<Text>Press the button to delete your folder...</Text>
					<ButtonContainer>
						<Space>
							<Button shape="round" size="large" onClick={closeModal}>
								Cancel
							</Button>
							<Button type="primary" shape="round" size="large" onClick={() => deleteFolder()}>
								Confirm
							</Button>
						</Space>
					</ButtonContainer>
				</>
			)}
		</ContentContainer>
	)
}

PropTypes.DeleteFolder = {
	closeModal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	folder_name: PropTypes.string.isRequired,
	folder_color: PropTypes.string.isRequired,
	updateCourseFolder: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	courses: selectOrgCourses,
	courseCards: selectOrgCourseCards,
	courseFolders: selectOrgCourseFolders,
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	deleteCourseFolder: (folder_id, org_id) => dispatch(deleteCourseFolder(folder_id, org_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFolder)
