// FORM ITEM TO UPLOAD A CUSTOM CERTIFICATE BACKGROUND

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import moment from "moment"
import { Typography, Button } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"

import LpModal from "../../../common/LpModal"
import { UploadOutlined } from "@ant-design/icons"

const { Title } = Typography

const ImageRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
const ImageText = styled.div`
	padding-left: 0px;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	justify-content: flex-start;
`
const FormLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	margin-top: 0px;
	font-size: 16px !important;
	font-weight: 500;
	line-height: 1.3;
`

const ImageContainer = styled.div`
	width: 90px;
	height: 90px;
	background: ${({ imageUrlOnComponent }) =>
		imageUrlOnComponent !== "" ? `url(${imageUrlOnComponent}) no-repeat` : "var(--v-light-grey)"};
	background-size: cover;
	font-size: 30px;
	color: var(--mid-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	border: 1px solid var(--v-light-grey);
`
const ModalBox = styled.div`
	border-radius: 8px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;
`
const PreviewImageContainer = styled.div`
	width: 438px;
	height: 624px;
	background: ${({ imageUrlOnComponent }) =>
		imageUrlOnComponent !== "" ? `url(${imageUrlOnComponent}) no-repeat` : "var(--v-light-grey)"};
	background-size: cover;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border: solid 1px black;
`
const CertificateContentContainer = styled.div`
	margin-top: 50%;
	height: 50%;
	width: 80%;
	text-align: center;
`
const HeaderText = styled.p`
	font-size: 13px;
	padding-top: 28px;
`
const TitleText = styled.p`
	font-size: 18px;
	padding-top: 16px;
	line-height: 1.5em;
`

const CertificateBackgroundUpload = ({ initialImageUrl, setNewImage, courseName, orgId, title }) => {
	// For image initally passed via initialImageUrl
	const [imageUrlOnComponent, setImageUrlOnComponent] = useState(initialImageUrl)

	// to set image thumbnail based on local storage we need to store the created object Url
	const [localImage, setLocalImage] = useState(null)
	const [showCertificateModal, setShowCertificateModal] = useState(false)

	useEffect(() => {
		if (localImage) {
			setImageUrlOnComponent(
				"https://farminkweb.s3.eu-west-2.amazonaws.com/f92aeaf9-1e24-4fc5-9b05-b3c350802dca.gif"
			)
			const formData = new FormData()

			// can add image quality params here...
			formData.set("image_quality", "100")
			formData.set("image_width", "1654")
			formData.set("orgId", orgId)
			formData.set("folderName", "certificateImages")

			formData.append("image", localImage.file.image, localImage.file.image.name)
			axios.post("/api/data/image", formData).then((res) => {
				if (res.data.image_url) {
					setImageUrlOnComponent(res.data.image_url) // set image on local comonent
					setNewImage(res.data.image_url) // we pass the S3 Url back to the parent component
				}
			})
		}
	}, [localImage])

	// when the user clicks on the input
	const handleEditPicture = (e) => {
		e.preventDefault()
		const fileInput = document.getElementById(`imageInput-certificate-image-upload`)
		fileInput.click()
	}
	// used to handle image, stored in state and then rendered as thumb
	const handleImageChange = (e) => {
		if (e.target.files[0] !== undefined) {
			const image = e.target.files[0] // for ONE image
			const src = window.URL.createObjectURL(image)
			setLocalImage({
				file: {
					image, // we want to send this bit to API via form
					src // this is how we can showcase the thumb
				}
			})
		}
	}

	return (
		<ImageRow>
			<input
				type="file"
				accept="image/*"
				name="img-loader-input"
				id={`imageInput-certificate-image-upload`}
				hidden="hidden"
				onChange={handleImageChange}
			/>

			<ImageText>
				{title && <FormLabel>{title}</FormLabel>}
				<Button icon={<UploadOutlined />} onClick={handleEditPicture} style={{ width: 110 }}>
					Upload
				</Button>
			</ImageText>

			<ImageContainer onClick={() => setShowCertificateModal(true)} imageUrlOnComponent={imageUrlOnComponent}>
				{imageUrlOnComponent !== "" ? null : <FontAwesomeIcon icon={faImage} />}
			</ImageContainer>

			<LpModal
				isShowingModal={showCertificateModal}
				hideModal={() => setShowCertificateModal(!showCertificateModal)}
			>
				<ModalBox>
					<Title level={5}>Here is a preview of your certificate...</Title>
					<PreviewImageContainer imageUrlOnComponent={imageUrlOnComponent}>
						<CertificateContentContainer>
							<HeaderText>This is to acknowledge that</HeaderText>
							<TitleText>Learner Name Here</TitleText>
							<HeaderText>has completed</HeaderText>
							<TitleText>{courseName}</TitleText>
							<HeaderText>This certificate was earned on</HeaderText>
							<TitleText>{moment().format("DD MMM YYYY")}</TitleText>
						</CertificateContentContainer>
					</PreviewImageContainer>
				</ModalBox>
			</LpModal>
		</ImageRow>
	)
}

CertificateBackgroundUpload.propTypes = {
	setNewImage: PropTypes.func.isRequired,
	initialImageUrl: PropTypes.string
}

CertificateBackgroundUpload.defaultProps = {
	initialImageUrl: "",
	setNewImage: () => console.log("FYI: you haven't added a setNewImage function on your parent component")
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(CertificateBackgroundUpload)
