import React, { useEffect } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import axios from "axios"
import styled from "styled-components"
import posthog from "posthog-js"

import CbLoading from "./components/common/CbLoading"
import SignUp from "./pages/auth/SignUp"
import SignIn from "./pages/auth/SignIn"
import VerifyEmail from "./pages/auth/VerifyEmail"
import Signout from "./pages/auth/Signout"
import PasswordReset from "./pages/auth/PasswordReset"
import CreateOrg from "./pages/landing/CreateOrg"
import Page404 from "./pages/auth/Page404"
import OrgApp from "./pages/app/OrgApp"
import { OrgPermissions } from "learnink-common/lib/domains/permissions/Permission.model"

import { auth } from "./firebase/firebase.util"

import { setFirebaseAuth, setUserInfo, setUserSessionReady, setUserSessionError } from "./redux/user/user.actions"
import {
	selectUserInfo,
	selectUserSessionLoaded,
	selectUserSessionLoadError,
	selectUserAuthStatus,
	selectUserOrgKeyArray
} from "./redux/user/user.selectors"

declare global {
	interface Window {
		Intercom: any
	}
}

const AppContainer = styled.div`
	width: 100%;
	height: 100%;
	background: white;
`

type Timestamp = string

interface UserInfoWellDefinedPart {
	user_id: string
	email_verified: string
	info: {
		name: string
		email: string
	}
	created_at: Timestamp
	updated_at: Timestamp
	permissions: OrgPermissions
}

export type UserInfo = UserInfoWellDefinedPart & { [key: string]: any }

interface FirebaseAuth {
	emailVerified: boolean
	metadata: firebase.auth.UserMetadata
	user_id: string
	auth_token: string
}

interface AppProps {
	setFirebaseAuth: (firebaseAuth?: FirebaseAuth) => void
	setUserInfo: (extendedUserInfo: UserInfo) => void
	setUserSessionReady: (isReady: boolean) => void
	setUserSessionError: (error: Error) => void
	userSessionLoadError: Error
	userSessionLoaded: boolean
	userInfo: UserInfo
	userAuthStatus: { isAuthenticated: boolean; hasVerifiedEmail: boolean; isInOrg: boolean }
	userOrgKeyArray: string[]
}
const App = ({
	setFirebaseAuth,
	setUserInfo,
	setUserSessionReady,
	setUserSessionError,
	userSessionLoadError,
	userSessionLoaded,
	userInfo,
	userAuthStatus: { isAuthenticated, hasVerifiedEmail, isInOrg },
	userOrgKeyArray
}: AppProps) => {
	// POSTHOG
	// =======
	// init posthog here
	useEffect(() => {
		posthog.init(process.env.REACT_APP_POSTHOG_TOKEN || "", {
			api_host: process.env.REACT_APP_POSTHOG_API_HOST
		})
	}, [])

	// log events with user info
	useEffect(() => {
		if (
			userInfo &&
			userInfo.info &&
			userInfo.user_id &&
			userInfo.created_at &&
			userInfo.userOrgRoles &&
			userInfo.info.email &&
			userInfo.info.name
		) {
			// identify with unique id
			posthog.identify(userInfo.user_id)
			// add extra user data
			posthog.people.set({
				email: userInfo.info.email,
				user_id: userInfo.user_id,
				name: userInfo.info.name,
				created_at: userInfo.created_at,
				userOrgRoles: userInfo.userOrgRoles
			})
		}
	}, [userInfo])

	// log the page views
	const { pathname } = useLocation()

	useEffect(() => {
		posthog.capture("$pageview")
	}, [pathname])

	// FIREBASE AUTH SUBSCRIPTION
	// ==========================

	// this effect listens for changes to firebase user state, if we detect a state change
	// e.g. login/ logout/ loading component then we use the code below to set up the
	// appropriate underlying user auth
	useEffect(() => {
		const unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
			setUserSessionReady(false)
			// if firebase user not signed in
			if (!user) {
				setFirebaseAuth()
				setUserSessionReady(true)
			}
			// if firebase user is signed in
			if (user) {
				user.getIdToken().then((token) => {
					// 1. set headers with auth token from firebase
					axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
					// 2. get user info from postgres DB (via REST API)
					axios
						.get("/api/user")
						.then((res) => {
							// SPECIAL CASE: where the user has verified their email on firebase
							// but we haven't changed emailVerified flag on DB yet...
							if (res.data.email_verified === false && user.emailVerified) {
								axios.post("/api/user/emailverified", { user_id: user.uid }).then(() => {
									res.data.email_verified = true
									setFirebaseAuth({
										auth_token: token,
										user_id: user.uid,
										emailVerified: user.emailVerified,
										metadata: user.metadata
									})
									setUserInfo({ ...res.data })

									setUserSessionReady(true)
								})
							}
							// 3. In normal case set the firebase auth info and user info on redux
							else {
								setFirebaseAuth({
									auth_token: token,
									user_id: user.uid,
									emailVerified: user.emailVerified,
									metadata: user.metadata
								})
								setUserInfo({ ...res.data })
								setUserSessionReady(true)
							}
						})
						.catch((err) => {
							setUserSessionError(err)
						})
				})
			}
		})

		// cleanup, i.e. unsubscribeFromAuth on unmount...
		return () => unsubscribeFromAuth()
	}, [setFirebaseAuth, setUserInfo, setUserSessionReady, setUserSessionError])

	// FIREBASE AUTH SUBSCRIPTION REFRESH
	// ====================================

	// the effect below is designed to fire every 15 minutes and keep the user logged
	// in with a valid token
	useEffect(() => {
		let interval: any = null

		interval = setInterval(() => {
			const user = auth.currentUser
			if (user) {
				user.getIdToken()
					.then((token) => {
						axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
						setFirebaseAuth({
							auth_token: token,
							user_id: user.uid,
							emailVerified: user.emailVerified,
							metadata: user.metadata
						})
					})
					.catch((err) => {
						setUserSessionError(err)
					})
			}
		}, 900000) // Token set to refresh every 15 minutes = 900000

		// cleanup, i.e. clear interval on unmount
		return () => clearInterval(interval)
	}, [setFirebaseAuth, setUserSessionError])

	// load intercom here...
	useEffect(() => {
		window.Intercom("boot", {
			api_base: "https://api-iam.intercom.io",
			app_id: "vs6eapg9"
		})
	}, [])

	if (userSessionLoadError) return <Page404 />
	// don't try the main return until user session is loaded (is false by default)
	if (!userSessionLoaded) {
		return <CbLoading fullpage={true} />
	}

	return (
		<AppContainer>
			<Switch>
				<Route exact path="/">
					{!isAuthenticated && <Redirect to="/signin" />}
					{isAuthenticated && !hasVerifiedEmail && <Redirect to="/signup" />}
					{isAuthenticated && hasVerifiedEmail && !isInOrg && <Redirect to="/create-org" />}
					{isAuthenticated && hasVerifiedEmail && isInOrg && <Redirect to={"/org/" + userOrgKeyArray[0]} />}
				</Route>
				<Route exact path="/verifyemail" component={VerifyEmail} />
				<Route exact path="/create-org" component={CreateOrg} />
				<Route exact path="/signup" component={SignUp} />
				<Route exact path="/signin" component={SignIn} />
				<Route exact path="/signout" component={Signout} />
				<Route exact path="/passwordreset" component={PasswordReset} />
				{/* @ts-ignore */}
				<Route path="/org/:org_id" component={OrgApp} />
				<Route path="*" component={Page404} />
			</Switch>
		</AppContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	userInfo: selectUserInfo,
	userSessionLoaded: selectUserSessionLoaded,
	userSessionLoadError: selectUserSessionLoadError,
	userAuthStatus: selectUserAuthStatus,
	userOrgKeyArray: selectUserOrgKeyArray
})

const mapDispatchToProps = (dispatch: any) => ({
	setFirebaseAuth: (authData: FirebaseAuth) => dispatch(setFirebaseAuth(authData)),
	setUserInfo: (userInfo: UserInfo) => dispatch(setUserInfo(userInfo)),
	setUserSessionReady: (isReady: boolean) => dispatch(setUserSessionReady(isReady)),
	setUserSessionError: (error: Error) => dispatch(setUserSessionError(error))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(App)
