import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link } from "react-router-dom"
import { Button, Space } from "antd"

import { selectLearningPaths, selectOrgId, selectRoleTypeUserGroups } from "../../../redux/org/org.selectors"

import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"

import * as S from "../../styles/PageStyles"
import { ThemeColors } from "../../../util/model/OrgSelectors"

import GeNoItemsFound from "../../common/GeNoItemsFound"
import { UserOutlined } from "@ant-design/icons"
import LearningPathStatusTable from "../charts/learning-path/LearningPathStatusTable"

interface Props {
	orgId: string
	themeColors: ThemeColors
	roleUserGroups: UserGroup[]
	learningPaths: LearningPath[]
}

const LearningPaths = ({ orgId, roleUserGroups }: Props) => {
	if (roleUserGroups.length === 0) {
		return (
			<S.Container>
				<S.MainContainer>
					<GeNoItemsFound
						icon={<UserOutlined style={{ fontSize: 40 }} />}
						titleText="Create a role to build a learning path"
						subtitleText="You need to create at least one role in order to start making learning paths"
					>
						<Space direction="vertical" align="center" style={{ marginTop: 20 }}>
							<Link to={`/org/${orgId}/manage/user-groups`}>
								<Button type="primary" shape="round" size="large">
									Create a role
								</Button>
							</Link>
						</Space>
					</GeNoItemsFound>
				</S.MainContainer>
			</S.Container>
		)
	}

	return <LearningPathStatusTable />
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	roleUserGroups: selectRoleTypeUserGroups,
	learningPaths: selectLearningPaths
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPaths)
