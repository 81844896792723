import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const MainContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const FormLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	font-size: ${({ labelFontSize }) => (labelFontSize ? labelFontSize : "18px")};
	font-weight: 500;
	line-height: 1.5;
`
const FormInput = styled.input`
	margin: 0;
	box-shadow: rgb(213, 220, 227) 0px 0px 0px 1px inset;
	background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "transparent")};
	color: rgb(62, 72, 84);
	height: 44px;
	width: 100%;
	display: block;
	padding-left: 16px !important;
	padding-right: 16px !important;
	font-size: 15px;
	line-height: 44px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-radius: 4px !important;
	border-width: 0px;
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	:focus {
		border: solid 1px var(--secondary-color) !important;
	}
`

const LpTextInput = ({
	autoFocus,
	labelText,
	placeholderText,
	handleTextChange,
	handleEnterAction,
	initialText,
	disabled,
	inputType,
	backgroundColor,
	labelFontSize
}) => {
	const [text, setText] = useState(initialText)
	const handleChange = (event) => {
		setText(event.target.value) // this is just for local presentation
		handleTextChange(event.target.value) // pass text up component here...
	}
	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			handleEnterAction()
		}
	}

	return (
		<MainContainer>
			{labelText && <FormLabel labelFontSize={labelFontSize}>{labelText}</FormLabel>}

			{autoFocus ? (
				<FormInput
					autoFocus
					placeholder={placeholderText}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					value={text}
					disabled={disabled}
					type={inputType}
					backgroundColor={backgroundColor}
				/>
			) : (
				<FormInput
					placeholder={placeholderText}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					value={text}
					disabled={disabled}
					type={inputType}
					backgroundColor={backgroundColor}
				/>
			)}
		</MainContainer>
	)
}

LpTextInput.propTypes = {
	autoFocus: PropTypes.bool,
	labelText: PropTypes.string.isRequired,
	initialText: PropTypes.string,
	placeholderText: PropTypes.string,
	handleTextChange: PropTypes.func.isRequired,
	handleEnterAction: PropTypes.func,
	disabled: PropTypes.bool,
	inputType: PropTypes.string,
	backgroundColor: PropTypes.string
}

LpTextInput.defaultProps = {
	autoFocus: false,
	labelText: "",
	initialText: "",
	placeholderText: "Enter text...",
	handleTextChange: () => console.log("FYI: you haven't added a setNewText function on your parent component"),
	handleEnterAction: () => console.log("user pressed ENTER"),
	disabled: false,
	inputType: "text",
	backgroundColor: null
}

export default LpTextInput
