import React, { useState } from "react"
import { Radio } from "antd"
import styled from "styled-components"

import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"

import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import ContentColumnEdit from "./ContentColumnEdit"
import ContentColumnPreview from "./ContentColumnPreview"

const NavContainer = styled.div`
	width: 100%;
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
`

interface Props {
	learningPath: LearningPath
	setSequenceIndex: React.Dispatch<React.SetStateAction<number | undefined>>
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	updateSequence: (sequence: Sequence) => void
}

const ContentColumn = ({ learningPath, setSequenceIndex, updateSequence, setModalType }: Props) => {
	const [view, setView] = useState<"edit" | "preview">("edit")

	return (
		<S.BuilderColumn>
			<S.BuilderColumnInner style={{ backgroundColor: "var(--off-white)" }}>
				<NavContainer>
					<Radio.Group onChange={(e) => setView(e.target.value)} value={view} buttonStyle="solid">
						<Radio.Button value="edit">Edit</Radio.Button>
						<Radio.Button value="preview" disabled={learningPath.sequences.length === 0}>
							Preview
						</Radio.Button>
					</Radio.Group>
				</NavContainer>
				{view === "edit" && (
					<ContentColumnEdit
						learningPath={learningPath}
						setSequenceIndex={setSequenceIndex}
						updateSequence={updateSequence}
						setModalType={setModalType}
					/>
				)}
				{view === "preview" && <ContentColumnPreview learningPath={learningPath} />}
			</S.BuilderColumnInner>
		</S.BuilderColumn>
	)
}

export default ContentColumn
