import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import LpBackButton from "../../components/common/LpBackButton"

import UploadUserGroupsCsv from "../../components/management/importUserGroups/UploadUserGroupsCsv"
import ReviewImportedUserGroups from "../../components/management/importUserGroups/ReviewImportedUserGroups"
import ImportSummary from "../../components/management/importUserGroups/ImportSummary"
import * as S from "../../components/styles/PageStyles"

import { selectOrgId } from "../../redux/org/org.selectors"

const ImportUserGroups = ({ orgId }) => {
	// handle stage of import UI
	const [importStage, setImportStage] = useState("import") // 'import' -> 'review' -> 'summary'

	// handle upload of CSV file
	const [localCsvFile, setLocalCsvFile] = useState(null) // where we store csv file
	const [csvCheckResponse, setCsvCheckResponse] = useState(null) // where we store cleaned data res from API

	// when the user adds a csv automatically trigger check via API
	useEffect(() => {
		if (localCsvFile && localCsvFile.file.csv && localCsvFile.file.csv.name) {
			const formData = new FormData()
			// append csv to form
			formData.append("csv", localCsvFile.file.csv, localCsvFile.file.csv.name)
			// check the data via backend
			axios
				.post(`/api/data/${orgId}/import-user-groups/check-csv`, formData)
				.then((res) => {
					setCsvCheckResponse(res.data)
				})
				.catch((err) => {
					console.log("err: ", err)
				})
		}
	}, [localCsvFile, orgId])

	const [importSummary, setImportSummary] = useState(null)

	const handleImportRequest = () => {
		// store data to send to API
		const formData = new FormData()
		formData.set("passToImport", true) // this tells API to try and import this data after check
		formData.append("csv", localCsvFile.file.csv, localCsvFile.file.csv.name)

		axios
			.post(`/api/data/${orgId}/import-user-groups-from-csv/`, formData)
			.then((res) => {
				console.log("Import summary", res.data)
				setImportSummary(res.data)
				setImportStage("summary")
			})
			.catch((err) => {
				console.log("err: ", err)
			})
	}

	return (
		<S.PageContainer>
			{/* import user list via csv */}
			{importStage === "import" && (
				<>
					<S.BackContainer>
						<LpBackButton backlink={`/org/${orgId}/manage/user-groups/create`} />
					</S.BackContainer>

					<UploadUserGroupsCsv
						setImportStage={setImportStage}
						localCsvFile={localCsvFile}
						setLocalCsvFile={setLocalCsvFile}
					/>
				</>
			)}

			{/* tag the users with user group(s) */}
			{importStage === "review" && (
				<>
					<S.BackContainer>
						<LpBackButton backOnClick={() => setImportStage("import")} />
					</S.BackContainer>

					<ReviewImportedUserGroups
						setImportStage={setImportStage}
						setLocalCsvFile={setLocalCsvFile}
						csvCheckResponse={csvCheckResponse}
						handleImportRequest={handleImportRequest}
					/>
				</>
			)}

			{/* see summary of import action */}
			{importStage === "summary" && <ImportSummary importSummary={importSummary} orgId={orgId} />}
		</S.PageContainer>
	)
}

ImportUserGroups.propTypes = {
	orgId: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(ImportUserGroups)
