import React from "react"
import { Empty, Typography } from "antd"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { selectThemeColors } from "../../../redux/org/org.selectors"

import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"

// @ts-ignore
import CertificateImg from "./certificate_incomplete.png"
import InfoBanner from "../../common/InfoBanner"
import { ThemeColors } from "../../../util/model/OrgSelectors"
import CourseCard from "./CourseCard"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

const { Title } = Typography

const CardContainer = styled.div`
	width: 100%;
	background: white;
	position: relative;
	background-color: #eef8fe;
	overflow: hidden;
`
const SequenceHeader = styled.div`
	width: 100%;
	padding: 14px 14px 10px 10px;
	position: relative;
	transition: 0.5s ease;
	background-size: cover !important;
	background: white;
`
const UnitTitle = styled.div`
	font-size: 12px;
	font-weight: bold;
	flex: 1;
	color: white;
`
const SequenceTitle = styled.div`
	font-size: 17px;
	font-weight: bold;
	color: white;
`
const CertificateContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	position: relative;
	padding: 0px 0px 25px 0px;
`
const CertificateImage = styled.img`
	width: 100px;
	height: 40px;
	z-index: 2;
`
const CertificateCard = styled.div`
	width: 70%;
	padding: 16px;
	margin-top: -6%;
	border-radius: 20px;
	border: solid 2px #c5d9e6;
	z-index: 1;
	background-color: #dbedf5;
	color: #808d98;
	font-weight: bold;
	font-size: 13px;
	text-align: center;
`

interface Props {
	learningPath: LearningPath
	themeColors: ThemeColors
}

const ContentColumnPreview = ({ learningPath, themeColors }: Props) => {
	const courseStatuses = new LearningPathContent(learningPath).getCourseStatuses()
	return (
		<>
			{learningPath.sequences.length === 0 ? (
				<S.FlexBoxColumnCentered
					style={{
						padding: "5px 10px 10px 10px",
						justifyContent: "flex-start"
					}}
				>
					<InfoBanner
						id="learning_path_editor"
						buttonSize="small"
						videoId="learning_path_editor"
						text="Want a tour? Check out the help video"
					/>
					<Empty
						description={
							<>
								<Title level={5} style={{ color: "var(--mid-grey)", marginBottom: 4 }}>
									No sequences
								</Title>
								<span style={{ color: "var(--mid-grey)" }}>
									Create a sequence to preview your learning path
								</span>
							</>
						}
					/>
				</S.FlexBoxColumnCentered>
			) : (
				<>
					{learningPath.sequences.map((sequence: Sequence, sequenceIndex: number) => (
						<CardContainer key={sequenceIndex}>
							<SequenceHeader
								style={{
									backgroundColor:
										sequenceIndex === 0 ? themeColors.ORG_THEME.backgroundColor : "#c6d8e6"
								}}
							>
								<UnitTitle>STAGE {sequenceIndex + 1}</UnitTitle>
								<SequenceTitle>{sequence.displayName}</SequenceTitle>
							</SequenceHeader>
							<div style={{ padding: "10px 5px 10px 5px" }}>
								{sequence.content.map((course, courseIndex) => (
									<CourseCard
										key={courseIndex}
										courseId={course.targetId}
										courseIndex={courseIndex}
										margin={12}
										status={courseStatuses[course.targetId].status}
										unlockDay={courseStatuses[course.targetId].unlockDay}
									/>
								))}
							</div>
							<CertificateContainer>
								<CertificateImage src={CertificateImg} />
								<CertificateCard>Certificate</CertificateCard>
							</CertificateContainer>
						</CardContainer>
					))}
				</>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	themeColors: selectThemeColors
})

// @ts-ignore
export default connect(mapStateToProps)(ContentColumnPreview)
