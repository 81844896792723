import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Table, Input } from "antd"

// @ts-ignore
import { Droppable, Draggable } from "react-beautiful-dnd"
import { selectOrgCourseFolders, selectOrgCourseTree } from "../../../redux/org/org.selectors"
import type { ColumnsType } from "antd/es/table"
import { FolderFilled, FolderOpenFilled } from "@ant-design/icons"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import CourseCard from "../content/CourseCard"
import { CourseFolders, CourseTree, OrgCourseTree } from "../../../util/model/OrgSelectors"

const { Search } = Input

const getFolderStyle = (record: CourseTree, courseFolders: CourseFolders) => {
	return {
		fontSize: 18,
		color: courseFolders.folder_data.filter((item: any) => item.id === record.value)[0].settings.folder_color,
		marginRight: 10
	}
}

interface Props {
	learningPath: LearningPath
	courseTree: OrgCourseTree
	courseFolders: CourseFolders
}

function getAllKeys(tree: CourseTree[]) {
	let keys: string[] = []

	for (let node of tree) {
		keys.push(node.key)

		if (node.children) {
			keys = keys.concat(getAllKeys(node.children))
		}
	}

	return keys
}

function searchTree(tree: CourseTree[], searchString: string) {
	let result: CourseTree[] = []
	const searchStrLowercase = searchString.toLowerCase()

	for (let node of tree) {
		let matchedNode: any = undefined

		if (node.title.toLowerCase().includes(searchStrLowercase)) {
			matchedNode = Object.assign({}, node)
			delete matchedNode.children // Remove children to avoid potential duplication
		}

		if (node.children) {
			const childResult = searchTree(node.children, searchString)
			if (childResult.length) {
				if (!matchedNode) {
					matchedNode = Object.assign({}, node) // If node wasn't already cloned, clone it now
				}
				matchedNode.children = childResult
			}
		}

		if (matchedNode) {
			result.push(matchedNode)
		}
	}

	return result
}

const Library = ({ learningPath, courseTree, courseFolders }: Props) => {
	const [tableTree, setTableTree] = useState<CourseTree[]>(courseTree.children)
	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
	const [searchInput, setSearchInput] = useState<string | undefined>(undefined)
	const existingCourseIds = new LearningPathContent(learningPath).getCourseIds()

	const toggleRowExpand = (rowKey: string) => {
		const rowKeys = expandedRowKeys.includes(rowKey)
			? expandedRowKeys.filter((item) => item !== rowKey)
			: [...expandedRowKeys, rowKey]
		setExpandedRowKeys(rowKeys)
	}

	useEffect(() => {
		if (searchInput) {
			const searchResults = searchTree(courseTree.children, searchInput)
			const keys = getAllKeys(searchResults)
			setTableTree(searchResults)
			setExpandedRowKeys(keys)
		} else {
			setTableTree(courseTree.children)
			setExpandedRowKeys([])
		}
	}, [searchInput])

	const columns: ColumnsType<CourseTree> = [
		{
			title: "My courses",
			dataIndex: "title",
			key: "name",
			render: (cell, row, index) => (
				<>
					{row.selectable ? (
						<>
							{existingCourseIds.includes(row.value) ? (
								<div style={{ opacity: 0.5, cursor: "pointer" }}>{cell}</div>
							) : (
								<Draggable key={row.value} draggableId={row.value} index={index}>
									{(provided: any, snapshot: any) => (
										<>
											{snapshot.isDragging ? (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<CourseCard
														courseId={row.value}
														showModuleBubbles
														status="unlocked"
													/>
												</div>
											) : (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													{cell}
												</div>
											)}
											{provided.placeholder}
										</>
									)}
								</Draggable>
							)}
						</>
					) : (
						<span style={{ cursor: "pointer" }}>{cell}</span>
					)}
				</>
			)
		}
	]

	return (
		<>
			<Droppable droppableId="library" type="course">
				{(provided: any, snapshot: any) => (
					<div ref={provided.innerRef}>
						<Search
							placeholder="Search..."
							onChange={(e) => setSearchInput(e.target.value)}
							style={{ width: "100%" }}
						/>
						<Table
							showHeader={false}
							columns={columns}
							dataSource={tableTree}
							size="small"
							pagination={false}
							onRow={(row) => {
								return {
									// @ts-ignore
									onClick: () => toggleRowExpand(row.key)
								}
							}}
							expandable={{
								expandedRowKeys: expandedRowKeys,
								// @ts-ignore
								onExpand: (record, row) => toggleRowExpand(row.key),
								expandIcon: ({ expanded, onExpand, record }) =>
									record.selectable === false ? (
										expanded ? (
											<FolderOpenFilled
												style={getFolderStyle(record, courseFolders)}
												onClick={(e) => onExpand(record, e)}
											/>
										) : (
											<FolderFilled
												style={getFolderStyle(record, courseFolders)}
												onClick={(e) => onExpand(record, e)}
											/>
										)
									) : (
										<></>
									)
							}}
						/>
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	courseTree: selectOrgCourseTree,
	courseFolders: selectOrgCourseFolders
})

// @ts-ignore
export default connect(mapStateToProps)(Library)
