import React from "react"
import DashboardHeaderButtonJs from "./DashboardHeaderButtonJs"

interface Props {
	title?: string
	titleComponent?: React.JSX.Element
	buttonTitle?: string
	buttonLink?: string
	secondaryButtonTitle?: string
	secondaryButtonOnClick?: string
	smWidth?: string
	laWidth?: string
	onClick?: () => void
	dropDown?: boolean
	menu?: any
}

export default function DashboardHeaderButton(props: Props): React.JSX.Element {
	// @ts-ignore
	return DashboardHeaderButtonJs(props)
}
