import { LearnActionTypes } from "./learn.types"
import { UiActionTypes } from "../ui/ui.types"
import axios from "axios"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const getLessonContent = (module_stage_id) => (dispatch) => {
	dispatch({ type: UiActionTypes.LOADING_UI_START })
	updateFirebaseToken()
	axios
		.all([axios.get("/api/preview/lesson/" + module_stage_id)])
		.then(
			axios.spread((lessonData) => {
				dispatch({
					type: LearnActionTypes.SET_LESSON_CONTENT,
					payload: lessonData.data
				})
				dispatch({ type: UiActionTypes.LOADING_UI_FINISH })
			})
		)
		.catch((err) => {
			console.log(err)
			dispatch({ type: UiActionTypes.LOADING_UI_FINISH })
		})
}

export const getModuleQuizData = (module_stage_id) => (dispatch) => {
	dispatch({ type: UiActionTypes.LOADING_UI_START })
	updateFirebaseToken()
	axios
		.all([axios.get("/api/preview/quiz/" + module_stage_id)])
		.then(
			axios.spread((quizData) => {
				dispatch({
					type: LearnActionTypes.SET_QUIZ_CONTENT,
					payload: quizData.data
				})
				dispatch({ type: UiActionTypes.LOADING_UI_FINISH })
			})
		)
		.catch((err) => {
			console.log(err)
			dispatch({ type: UiActionTypes.LOADING_UI_FINISH })
		})
}
