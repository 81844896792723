// PLANS PAGE FOR USERS WHO DO NOT HAVE AN ACTIVE SUBSCRIPTION

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Page404 from "../auth/Page404"
import { Typography } from "antd"

import { setOrgSubscription, setPlanList } from "../../redux/subscription/subscription.actions"
import { selectSubscription } from "../../redux/subscription/subscription.selectors"
import { setOrgData } from "../../redux/org/org.actions"
import { selectOrg } from "../../redux/org/org.selectors"

import SliderButton from "../../components/common/SliderButton"
import UpgradePlan from "../../components/subscription/UpgradePlan"
import CbLoading from "../../components/common/CbLoading"

const { Title } = Typography

const HeroContainer = styled.div`
	min-height: 100vh;
	height: 100%;
	flex: 1;
	background-color: white;
	padding-top: 140px;
`
const HeroInner = styled.div`
	width: 90%;
	max-width: 900px;
	margin: 0 auto;
	overflow: auto;
	@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
		width: 80%;
	}
`
const TitleRow = styled.div`
	text-align: center;
	position: relative;
	padding: 0px 3% 0px 3%;
`
const SubtitleText = styled.h2`
	font-size: 18px;
	line-height: 22px;
	font-weight: 400;
	padding-bottom: 30px;
	color: var(--dark-grey);
	@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
		font-size: 16px;
		line-height: 20px;
	}
`
const SelectorContainer = styled.div`
	align-items: flex-start;
	width: 100%;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	box-sizing: border-box;
	display: flex;
	margin-bottom: 0px;
	@media screen and (min-width: 590px) {
		align-items: flex-end;
		flex-direction: row;
	}
`
const OrgContainer = styled.div`
	flex: 1;
	text-align: left;
	margin-bottom: 20px;
	@media screen and (min-width: 590px) {
		padding-right: 20px;
		margin-bottom: 0px;
	}
`
const SliderContainer = styled.div`
	width: 250px;
	margin: 0 auto;
`
const PricingRow = styled.div`
	width: 100%;
`

const Upgrade = ({ org, subscription: { activePlan, planList }, setPlanList }) => {
	const [timePeriod, setTimePeriod] = useState(activePlan.info.billing_period)

	const onSliderChange = (sliderValue) => {
		if (sliderValue === "monthly") {
			setTimePeriod("month")
		} else {
			setTimePeriod("year")
		}
	}

	useEffect(() => {
		setPlanList()
	}, [setPlanList])

	if (!activePlan || !planList) {
		return <CbLoading />
	}

	if (!activePlan.stripe_customer_email) {
		return <Page404 />
	}

	return (
		<HeroContainer>
			<HeroInner>
				<TitleRow>
					<Title level={2}>Upgrade your plan</Title>
					<SubtitleText>
						Get the most out of your Learn.ink experience. Check out our subscription plans
					</SubtitleText>
					<SelectorContainer>
						<OrgContainer>
							<p style={{ fontSize: "19px", lineHeight: "20px" }}>
								Organisation:{" "}
								<span style={{ fontWeight: "600", color: "var(--secondary-color)" }}>
									{org.orgData.info.org_name}
								</span>
							</p>
						</OrgContainer>
						<SliderContainer>
							<SliderButton
								defaultValue={activePlan.info.billing_period === "year" ? "right" : "left"}
								valueLeft={"monthly"}
								valueRight={"annually"}
								onSliderChange={onSliderChange}
							/>
						</SliderContainer>
					</SelectorContainer>
				</TitleRow>
				<PricingRow>
					{planList.map((plan, index) => (
						<UpgradePlan
							key={index}
							plan={plan[timePeriod]}
							timePeriod={timePeriod}
							activePlan={activePlan}
						/>
					))}
				</PricingRow>
			</HeroInner>
		</HeroContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	org: selectOrg,
	subscription: selectSubscription
})

const mapDispatchToProps = (dispatch) => ({
	setOrgData: (orgId) => dispatch(setOrgData(orgId)),
	setOrgSubscription: (orgId) => dispatch(setOrgSubscription(orgId)),
	setPlanList: (orgId) => dispatch(setPlanList(orgId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade)
