import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { ResponsiveContainer } from "recharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { getSequenceJourneyTable } from "../../../../redux/analytics/analytics.actions"
import {
	selectOrgCourseCards,
	selectOrgId,
	selectOrgUserGroups,
	selectSequenceList
} from "../../../../redux/org/org.selectors"

import CbLoading from "../../../common/CbLoading"
import TableFilter from "./OneOffSequence/TableFilter"
import ChartWrapper from "../../common/ChartWrapper"
import GeNoItemsFound from "../../../common/GeNoItemsFound"

const SequenceJourneyQuery = ({
	sequenceId,
	dateRange,
	orgId,
	orgUserGroups,
	sequenceList,
	courseCards,
	getSequenceJourneyTable
}) => {
	const [pageState, setPageState] = useState("loading")

	const getTableData = async () => {
		try {
			if (sequenceList && sequenceList.filter((item) => item.id === sequenceId).length > 0) {
				const numCourses = sequenceList.filter((item) => item.id === sequenceId)[0].content.length
				await getSequenceJourneyTable(orgId, sequenceId, dateRange, orgUserGroups, numCourses)
				setPageState("loaded")
			} else {
				setPageState("error")
			}
		} catch (err) {
			setPageState("error")
		}
	}

	useEffect(() => {
		setPageState("loading")
		getTableData()
	}, [getSequenceJourneyTable, orgId, sequenceId, dateRange])

	if (pageState === "loading") {
		return <CbLoading />
	}
	if (pageState === "error") {
		return (
			<ChartWrapper title="Sequence courses completed">
				<ResponsiveContainer width="99%" height={350}>
					<GeNoItemsFound
						titleText="No data"
						subtitleText="We couldn't find this sequence, you may have followed an old or broken link"
						icon={<FontAwesomeIcon icon={faExclamationCircle} />}
					/>
				</ResponsiveContainer>
			</ChartWrapper>
		)
	}

	if (pageState === "loaded" && sequenceList && sequenceList.filter((item) => item.id === sequenceId).length > 0) {
		return (
			<TableFilter
				sequenceInfo={sequenceList.filter((item) => item.id === sequenceId)[0]}
				courseCards={courseCards}
			/>
		)
	} else {
		return <CbLoading />
	}
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups,
	courseCards: selectOrgCourseCards,
	sequenceList: selectSequenceList
})

const mapDispatchToProps = (dispatch) => ({
	getSequenceJourneyTable: (orgId, sequenceId, dateRange, orgUserGroups, numCourses) =>
		dispatch(getSequenceJourneyTable(orgId, sequenceId, dateRange, orgUserGroups, numCourses))
})

export default connect(mapStateToProps, mapDispatchToProps)(SequenceJourneyQuery)
