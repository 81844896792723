import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
`
const QuestionCardContainer = styled.div`
	background: white;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	min-height: 300px;
	width: 300px;
`
const SurveyQuestion = styled.div`
	width: 100%;
	padding: 12px 18px;
	border: 1px solid var(--dark-grey);
	border-radius: 0px 30px 30px 30px;
	background: var(--secondary-color);
	color: black;
	overflow: visible;
	overflow-wrap: normal;
	white-space: normal;
	font-size: 14px;
	font-weight: 400;
	overflow: visible;
	overflow-wrap: normal;
	white-space: normal;
	line-height: 16px !important;
	resize: vertical;
`
const SurveyAnswersContainer = styled.div`
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: center;
	margin: 10px;
`
const SurveyAnsResponse = styled.div`
  display: flex;
  max-width: 240px;
  margin: 2px 4px;
  padding: 4px 8px;
  border: 1px solid var(--mid-orange);
  border-radius: 18px;
  background: var(--mid-orange);
  color: white;
  font-size: 12px;
  font-weight: 600;
  overflow: visible;
  overflow-wrap: normal;
  white-space: normal;
  text-align: left;
  line-height: 18px;
}
`
const CustomAnswerBox = styled.div`
  display: flex;
  margin: 20px 0px;
  padding: 12px 18px;
  border: 1px solid var(--light-grey);
  border-radius: 30px;
  background: var(--off-white);
  color: var(--dark-grey);;
  font-size: 14px;
  font-weight: 400;
  overflow: visible;
  overflow-wrap: normal;
  white-space: normal;
  text-align: left;
  line-height: 16px;
}
`

const SingleSurveyCard = ({ surveyContent }) => {
	return (
		<Wrapper>
			<QuestionCardContainer>
				<SurveyQuestion>{surveyContent.content.message}</SurveyQuestion>
				{surveyContent.content.survey_type === "multiple_choice" ? (
					<SurveyAnswersContainer>
						{surveyContent.content.response_array.map((answer, index) => (
							<SurveyAnsResponse key={index}>{answer.answer_text}</SurveyAnsResponse>
						))}
					</SurveyAnswersContainer>
				) : (
					<CustomAnswerBox>User provided a custom answer...</CustomAnswerBox>
				)}
			</QuestionCardContainer>
		</Wrapper>
	)
}

export default SingleSurveyCard
