import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Alert, Button, Space } from "antd"

interface Props {
	orgId: string
}

const WeveMadeChangesBox = ({ orgId }: Props) => {
	const [showBox, setShowBox] = useState<boolean>(true)

	if (!showBox) return <></>

	const message = <></>

	const description = (
		<>
			<div>We are introducing changes to this screen</div>
			<div>
				Until we fully roll-out, you can go to the old version
				<Link to={`/org/${orgId}/legacy/manage/users`}>
					{" "}
					<u>here</u>
				</Link>
			</div>
		</>
	)

	const TourButton = (
		<Button
			size={"small"}
			type={"primary"}
			href={"https://help.learn.ink/en/articles/9948688-filtering-users"}
			target={"_blank"}
		>
			Take a tour
		</Button>
	)

	const FeedbackButton = (
		<Button
			size={"small"}
			type="ghost"
			href={"https://docs.google.com/forms/d/1FsKwflzFvDBfkWsJ81HDF1_H4tuaSd1zX7lpBL2iMiU"}
			target={"_blank"}
		>
			Give feedback
		</Button>
	)

	const actions = (
		<Space direction="vertical">
			{TourButton} {FeedbackButton}
		</Space>
	)

	return (
		<Alert
			style={{ marginTop: "15px" }}
			type={"info"}
			showIcon
			message={message}
			description={description}
			action={actions}
			closable
			onClose={() => setShowBox(false)}
		/>
	)
}

export default WeveMadeChangesBox
