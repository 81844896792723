import { CoursesActionTypes } from "./courses.types"

const INITIAL_STATE = {
	course: null,
	objectives: null,
	stage: null,
	pageParams: {
		org_id: null,
		course_id: null,
		module_id: null,
		stage_id: null,
		lesson_view: true
	}
}

const coursesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CoursesActionTypes.CLEAR_COURSE_DATA:
			return {
				...state,
				course: null
			}

		case CoursesActionTypes.CLEAR_OBJECTIVES_DATA:
			return {
				...state,
				objectives: null
			}

		case CoursesActionTypes.CLEAR_STAGE_DATA:
			return {
				...state,
				stage: null
			}

		case CoursesActionTypes.CLEAR_PAGE_PARAMS_DATA:
			return {
				...state,
				pageParams: null
			}

		case CoursesActionTypes.SET_PAGE_PARAMS:
			return {
				...state,
				pageParams: {
					...state.pageParams,
					...action.payload
				}
			}

		case CoursesActionTypes.SET_COURSE_DATA:
			return {
				...state,
				course: action.payload
			}

		case CoursesActionTypes.UPDATE_COURSE_INFO:
			return {
				...state,
				course: {
					...state.course,
					info: action.payload.info
				}
			}

		case CoursesActionTypes.CREATE_NEW_MODULE_CARD:
			// update ids array
			let createModule_new_module_ids = [...state.course.module_ids]
			createModule_new_module_ids.push(action.payload.id)
			// and add module card
			let createModule_new_module_cards = Object.assign({}, state.course.module_cards)
			createModule_new_module_cards[action.payload.id] = {
				...action.payload
			}
			return {
				...state,
				course: {
					...state.course,
					module_ids: createModule_new_module_ids,
					module_cards: createModule_new_module_cards
				}
			}

		case CoursesActionTypes.DELETE_MODULE_CARD:
			// remove module_id from module_card
			let deleteModule_new_module_cards = Object.assign({}, state.course.module_cards)
			let stage_ids_to_remove = [...deleteModule_new_module_cards[action.payload.deleted_module_id].stage_ids]
			delete deleteModule_new_module_cards[action.payload.deleted_module_id]
			// also use the stage_ids from module card, and remnove these fromn stage_cards
			let deleteModule_new_stage_cards = Object.assign({}, state.course.stage_cards)
			stage_ids_to_remove.forEach((stage_id) => {
				delete deleteModule_new_stage_cards[stage_id]
			})
			return {
				...state,
				pageParams: {
					...state.pageParams,
					module_id: null
				},
				course: {
					...state.course,
					module_ids: action.payload.module_ids,
					module_cards: deleteModule_new_module_cards,
					stage_cards: deleteModule_new_stage_cards
				}
			}

		case CoursesActionTypes.UPDATE_MODULE_CARD:
			return {
				...state,
				course: {
					...state.course,
					module_cards: {
						...state.course.module_cards,
						[action.payload.module_id]: {
							id: action.payload.module_id,
							info: action.payload.info,
							stage_ids: action.payload.module_stage_ids
						}
					}
				}
			}

		case CoursesActionTypes.CREATE_NEW_STAGE_CARD_IN_MODULE:
			// We need to update stage_ids on right module card
			let newModuleCard = Object.assign({}, state.course.module_cards[action.payload.module_id])
			newModuleCard.stage_ids.push(action.payload.id) // push the new stage_id to the array here
			// then update BOTH the module card and stage card...
			return {
				...state,
				course: {
					...state.course,
					module_cards: {
						...state.course.module_cards,
						[action.payload.module_id]: newModuleCard
					},
					stage_cards: {
						...state.course.stage_cards,
						[action.payload.id]: {
							id: action.payload.id,
							info: action.payload.info,
							objective_id: action.payload.objective_id,
							lesson_card_ids: action.payload.lesson_card_ids,
							question_card_ids: action.payload.question_card_ids
						}
					}
				}
			}

		case CoursesActionTypes.UPDATE_STAGE_CARD:
			return {
				...state,
				course: {
					...state.course,
					stage_cards: {
						...state.course.stage_cards,
						[action.payload.stage_id]: {
							id: action.payload.stage_id,
							info: action.payload.info,
							objective_id: action.payload.objective_id,
							lesson_card_ids: action.payload.lesson_card_ids,
							question_card_ids: action.payload.question_card_ids
						}
					}
				}
			}

		case CoursesActionTypes.DELETE_STAGE_CARD:
			return {
				...state,
				course: {
					...state.course,
					module_cards: {
						...state.course.module_cards,
						[action.payload.module_id]: {
							id: action.payload.module_id,
							info: action.payload.info,
							stage_ids: action.payload.module_stage_ids
						}
					}
				}
			}

		case CoursesActionTypes.MOVE_STAGE_CARD_TO_NEW_MODULE:
			let new_module_cards_after_move = Object.assign({}, state.course.module_cards)
			new_module_cards_after_move[action.payload.old_module_id] = {
				...new_module_cards_after_move[action.payload.old_module_id],
				stage_ids: new_module_cards_after_move[action.payload.old_module_id].stage_ids.filter(
					(id) => id !== action.payload.stage_id
				)
			}
			new_module_cards_after_move[action.payload.new_module_id] = {
				...new_module_cards_after_move[action.payload.new_module_id],
				stage_ids: [
					...new_module_cards_after_move[action.payload.new_module_id].stage_ids,
					action.payload.stage_id
				]
			}
			return {
				...state,
				course: {
					...state.course,
					module_cards: new_module_cards_after_move
				}
			}

		case CoursesActionTypes.COURSE_UPDATE_MODULES_COLUMN:
			return {
				...state,
				course: {
					...state.course,
					module_ids: action.payload
				}
			}

		case CoursesActionTypes.MODULE_UPDATE_STAGES_COLUMN:
			let new_module_cards = Object.assign({}, state.course.module_cards)
			new_module_cards[action.payload.module_id] = {
				...new_module_cards[action.payload.module_id],
				stage_ids: action.payload.new_stage_ids
			}
			return {
				...state,
				course: {
					...state.course,
					module_cards: new_module_cards
				}
			}

		case CoursesActionTypes.SET_STAGE_DATA:
			return {
				...state,
				stage: {
					...action.payload,
					editor: {
						activeCardId: null,
						menu: "archive"
					}
				}
			}

		case CoursesActionTypes.UPDATE_CARD_EDITOR_SETTINGS:
			return {
				...state,
				stage: {
					...state.stage,
					editor: action.payload
				}
			}

		case CoursesActionTypes.SET_OBJECTIVES_DATA:
			return {
				...state,
				objectives: action.payload
			}

		case CoursesActionTypes.STAGE_UPDATE_SAME_COLUMN:
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						...action.payload
					}
				}
			}

		case CoursesActionTypes.STAGE_UPDATE_NEW_COLUMN:
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						...action.payload.newStartCol,
						...action.payload.newEndCol
					}
				}
			}

		case CoursesActionTypes.UPDATE_LESSON_CARD_CONTENT:
			let lessonCardIds = [...state.stage.stage_columns.lesson_data.card_ids]
			const lessonCardIndex = lessonCardIds.indexOf(action.payload.originalCard.id)
			lessonCardIds[lessonCardIndex] = action.payload.updatedCard.id
			let new_lesson_cards = Object.assign({}, state.stage.lesson_cards)
			new_lesson_cards[action.payload.updatedCard.id] = action.payload.updatedCard
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						lesson_data: {
							...state.stage.stage_columns.lesson_data,
							card_ids: lessonCardIds
						}
					},
					lesson_cards: new_lesson_cards
				}
			}

		case CoursesActionTypes.UPDATE_QUESTION_CARD_CONTENT:
			let questionCardIds = [...state.stage.stage_columns.questions_data.card_ids]
			const questionCardIndex = questionCardIds.indexOf(action.payload.originalCard.id)
			questionCardIds[questionCardIndex] = action.payload.updatedCard.id
			let new_question_cards = Object.assign({}, state.stage.question_cards)
			new_question_cards[action.payload.updatedCard.id] = action.payload.updatedCard
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						questions_data: {
							...state.stage.stage_columns.questions_data,
							card_ids: questionCardIds
						}
					},
					question_cards: new_question_cards
				}
			}

		case CoursesActionTypes.CREATE_NEW_LESSON_CARD:
			// update the lesson card array
			let onCardCreate_lesson_card_ids = Array.from(state.stage.stage_columns.lesson_data.card_ids)
			let lesson_card_index =
				action.payload.index !== null && action.payload.index !== undefined
					? action.payload.index + 1
					: onCardCreate_lesson_card_ids.length
			onCardCreate_lesson_card_ids.splice(lesson_card_index, 0, action.payload.card.id)
			// update your lesson cards object
			let onCardCreate_lesson_cards = Object.assign({}, state.stage.lesson_cards)
			onCardCreate_lesson_cards[action.payload.card.id] = action.payload.card
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						lesson_data: {
							...state.stage.stage_columns.lesson_data,
							card_ids: onCardCreate_lesson_card_ids // add your new card id to the lesson col
						}
					},
					lesson_cards: onCardCreate_lesson_cards // add the card data
				}
			}

		case CoursesActionTypes.CREATE_NEW_QUESTION_CARD:
			// update the questions card array
			let onCardCreate_question_card_ids = Array.from(state.stage.stage_columns.questions_data.card_ids)
			let question_card_index =
				action.payload.index !== null && action.payload.index !== undefined
					? action.payload.index + 1
					: onCardCreate_question_card_ids.length
			onCardCreate_question_card_ids.splice(question_card_index, 0, action.payload.card.id)
			// update your questions cards object
			let onCardCreate_question_cards = Object.assign({}, state.stage.question_cards)
			onCardCreate_question_cards[action.payload.card.id] = action.payload.card
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						questions_data: {
							...state.stage.stage_columns.questions_data,
							card_ids: onCardCreate_question_card_ids // add your new card id to the question col
						}
					},
					question_cards: onCardCreate_question_cards // add the card data
				}
			}

		case CoursesActionTypes.DELETE_STAGE_CONTENT_CARD:
			// determine which master card object to look at based on card type
			const cards_obj_ref = `${action.payload.card_type}_cards`
			// delete card_id from array in appropriate column
			let onDeleteCardIds = [...state.stage.stage_columns[action.payload.col_id].card_ids]
			onDeleteCardIds.splice(action.payload.index, 1)
			// delete card key from master card object
			let onDeleteCards = Object.assign({}, state.stage[cards_obj_ref])
			delete onDeleteCards[action.payload.card_id]
			// update the state
			return {
				...state,
				stage: {
					...state.stage,
					stage_columns: {
						...state.stage.stage_columns,
						[action.payload.col_id]: {
							...state.stage.stage_columns[action.payload.col_id],
							card_ids: onDeleteCardIds // removed card_id from array
						}
					},
					[cards_obj_ref]: onDeleteCards // deleted card from card object
				}
			}

		case CoursesActionTypes.UPDATE_OBJECTIVE:
			return {
				...state,
				objectives: {
					...state.objectives,
					[action.payload.objective_id]: {
						...action.payload
					}
				}
			}

		case CoursesActionTypes.CREATE_OBJECTIVE:
			return {
				...state,
				objectives: {
					...state.objectives,
					[action.payload.objective_id]: {
						...action.payload
					}
				},
				stage: {
					...state.stage,
					objective_id: action.payload.objective_id
				}
			}

		case CoursesActionTypes.SWITCH_STAGE_OBJECTIVE:
			return {
				...state,
				stage: {
					...state.stage,
					objective_id: action.payload.objective_id
				}
			}

		case CoursesActionTypes.SWITCH_STAGE_CARD_OBJECTIVE:
			let switchedObj_stage_cards = Object.assign({}, state.course.stage_cards)
			switchedObj_stage_cards[action.payload.stage_id].objective_id = action.payload.objective_id
			return {
				...state,
				course: {
					...state.course,
					stage_cards: switchedObj_stage_cards
				}
			}

		default:
			return state
	}
}

export default coursesReducer
