export const ManagementActionTypes = {
	SET_ORG_USER_GROUPS_INFO: "SET_ORG_USER_GROUPS_INFO",
	CLEAR_ORG_USER_GROUPS_INFO: "CLEAR_ORG_USER_GROUPS_INFO",
	SET_USER_LIST: "SET_USER_LIST",
	CLEAR_USER_LIST: "CLEAR_USER_LIST",
	UPDATED_ONE_IN_USER_LIST: "UPDATED_ONE_IN_USER_LIST",
	BULK_EDIT_USER_GROUPS: "BULK_EDIT_USER_GROUPS",
	BULK_EDIT_LEARNING_PATH: "BULK_EDIT_LEARNING_PATH",
	ADD_TO_USER_LIST: "ADD_TO_USER_LIST",
	REMOVE_FROM_USER_LIST: "REMOVE_FROM_USER_LIST",
	SET_USER_ANALYTICS_TABLE: "SET_USER_ANALYTICS_TABLE"
}
