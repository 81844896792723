import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { getCourseJourneyTable } from "../../../../redux/analytics/analytics.actions"
import { selectOrgId, selectOrgUserGroups } from "../../../../redux/org/org.selectors"

import CbLoading from "../../../common/CbLoading"
import TableFilter from "./TableFilter"

const CourseJourneyQuery = ({ courseId, dateRange, orgId, orgUserGroups, getCourseJourneyTable }) => {
	const [tableLoaded, setTableLoaded] = useState(false)

	const getTableData = async () => {
		await getCourseJourneyTable(orgId, courseId, dateRange, orgUserGroups)
		setTableLoaded(true)
	}

	useEffect(() => {
		setTableLoaded(false)
		getTableData()
	}, [getCourseJourneyTable, orgId, courseId, dateRange])

	if (!tableLoaded) {
		return <CbLoading />
	}

	return <TableFilter />
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups
})

const mapDispatchToProps = (dispatch) => ({
	getCourseJourneyTable: (orgId, courseId, dateRange, orgUserGroups) =>
		dispatch(getCourseJourneyTable(orgId, courseId, dateRange, orgUserGroups))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseJourneyQuery)
