import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import axios from "axios"
import Confetti from "react-confetti"
import { Button, Typography, Alert } from "antd"

import LpBackButton from "../../components/common/LpBackButton"
import LpTextInput from "../../components/common/LpTextInput"
import OrgCard from "../../components/elements/OrgCard"

import { checkOrgUrlAvailable, userCreateNewOrg } from "../../redux/user/user.actions"
import { selectUserAuthStatus, selectUserOrgKeyArray, selectUserOrgRoles } from "../../redux/user/user.selectors"

const { Title, Text } = Typography

const BackContainer = styled.div`
	display: flex;
	width: 100%;
	padding-left: 48px;
	justify-content: flex-start;
	@media (max-width: 850px) {
		padding-left: 22px;
	}
`
const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	box-sizing: border-box;
	background: white;
`
const LeftContainer = styled.div`
	flex: 1 1 50%;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
	@media (max-width: 850px) {
		padding-top: 22px;
		padding-bottom: 22px;
	}
`
const RightContainer = styled.div`
	flex: 1 1 50%;
	width: 100%;
	flex-direction: column;
	padding-top: 168px;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	background: var(--secondary-color);
	@media (max-width: 850px) {
		display: none;
	}
`
const FormContainer = styled.div`
	display: flex;
	height: 100%;
	width: 90%;
	margin: 0 auto;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 100px;
	@media (max-width: 850px) {
		padding: 40px;
	}
`
const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`
const TinyText = styled.div`
	width: 100%;
	text-align: left;
	margin-top: 16px;
	color: var(--dark-grey);
	display: block;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.2;
`
const TinyLink = styled.a`
	color: var(--secondary-color);
	font-size: 14px;
`
const OrgCardsBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
`
const OrgCardContainer = styled.div`
	width: 220px;
	padding: 10px;
`

const UrlInputBox = styled.div`
	display: flex;
	width: 100%;
	margin: 6px 0px;
`
const UrlInputPrefix = styled.div`
	width: 115px;
	background-color: var(--secondary-color);
	color: white;
	font-weight: 600;
	font-size: 15px;
	border-radius: 4px 0px 0px 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 8px 0px 18px;
`
const FormInput = styled.input`
	flex: 1;
	margin: 0;
	box-shadow: rgb(213, 220, 227) 0px 0px 0px 1px inset;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 44px;
	width: 100%;
	display: block;
	padding-left: 16px !important;
	padding-right: 16px !important;
	font-size: 15px;
	line-height: 44px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-radius: 0px 4px 4px 0px !important;
	border-top: solid 1px var(--v-light-grey) !important;
	border-bottom: solid 1px var(--v-light-grey) !important;
	border-right: solid 1px var(--v-light-grey) !important;
	border-left: 0px !important;
	transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	border-left: 0px;
	:focus {
		border-top: solid 1px var(--secondary-color) !important;
		border-bottom: solid 1px var(--secondary-color) !important;
		border-right: solid 1px var(--secondary-color) !important;
		border-left: 0px !important;
	}
`

const CreateOrg = ({
	userAuthStatus: { isAuthenticated, hasVerifiedEmail },
	userOrgKeyArray,
	userCreateNewOrg,
	userOrgRoles
}) => {
	const [formView, setFormView] = useState("orgName") // we default to creating a new org...
	const [orgName, setOrgName] = useState("")
	const [orgUrl, setOrgUrl] = useState("")
	const [errorMsg, setErrorMsg] = useState(null)
	const [redirectToOrg, setRedirectToOrg] = useState(false)

	// in case user is in an org set the form view to check if they
	// want to go to their existing org!
	useEffect(() => {
		if (userOrgKeyArray.length > 0) {
			setFormView("existingOrgs")
		}
	}, [userOrgKeyArray])

	useEffect(() => {
		// if user has set the orgUrl and the orgUrl is in their key
		/// array then org has been created successfully
		if (!!orgUrl && userOrgKeyArray.includes(orgUrl)) {
			setFormView("orgComplete")
		}
	}, [userOrgKeyArray, orgUrl])

	// for handling case if
	const handleExistingOrgBtn = () => setFormView("orgName")

	// For handling Org Name in form
	const handleNameChange = (text) => {
		setErrorMsg(null)
		setOrgName(text)
	}
	const handleNameEnter = () => {
		setErrorMsg(null)
		handleNewName(orgName)
	}
	const handleNameSubmit = (e) => {
		e.preventDefault()
		setErrorMsg(null)
		handleNewName(orgName)
	}
	const handleNewName = (orgName) => {
		if (orgName.trim() === "") setErrorMsg("you must add an organisation name")
		else setFormView("orgUrl")
	}

	/*
  // For handling Org Url in form and creating the new org action
  const handleUrlChange = text => {
    setErrorMsg(null);
    setOrgUrl(text.trim().toLowerCase()); // take out spaces and caps from suggested url
  }
  const handleUrlEnter = () => {
    setErrorMsg(null);
    handleCreateOrg(orgName, orgUrl);
  }
  */

	// For handling Org Url in form and creating the new org action
	const handleUrlChange = (event) => {
		setErrorMsg(null)
		setOrgUrl(event.target.value.trim().toLowerCase()) // take out spaces and caps from suggested url
	}
	const handleUrlEnter = (event) => {
		if (event.keyCode === 13) {
			setErrorMsg(null)
			handleCreateOrg(orgName, orgUrl)
		}
	}

	const handleUrlSubmit = (e) => {
		e.preventDefault()
		setErrorMsg(null)
		handleCreateOrg(orgName, orgUrl)
	}

	const handleCreateOrg = async (orgName, orgUrl) => {
		// check non empty
		if (orgUrl === "") setErrorMsg("you must add a url")
		// regex tests for strings without spaces, or any chars other than letters, numbers and dashes
		else if (/[^a-z0-9-]+/g.test(orgUrl))
			setErrorMsg("Use only use numbers and dashes without spaces. e.g. my-company")
		else {
			try {
				// first check url id is available
				const check = await axios.post(`/api/org/url-available-check`, {
					user_asked_for_id: orgUrl
				})
				// if check passes then create new org with details...
				if (check.data.code === "OK") {
					userCreateNewOrg(orgName, orgUrl)
					// if this action successulf we'll see a new org key pushed to redux state
					// we handle the update in the useEffect already declared...
				} else if (check.data.code === "RETRY") {
					// ask for url that hasn't been used, i.e. id we have available in db
					setErrorMsg(check.data.message)
				}
			} catch (err) {
				console.log(err)
			}
		}
	}

	// for handling final redirect
	const handleRedirect = () => setRedirectToOrg(true)

	if (isAuthenticated === false || hasVerifiedEmail === false) return <Redirect to={"/"} />
	if (redirectToOrg === true) return <Redirect to={`/org/${orgUrl}`} />

	return (
		<React.Fragment>
			<PageContainer>
				{formView === "orgName" && (
					<React.Fragment>
						<LeftContainer>
							<BackContainer>
								<LpBackButton backlink={`/`} />
							</BackContainer>
							<FormContainer>
								<Title level={2} style={{ width: "100%" }}>
									What's the name of your organisation?
								</Title>
								<Title level={5} style={{ width: "100%" }}>
									We’ll use this name in your web app so your users know it’s you!
								</Title>
								<LpTextInput
									autoFocus={true}
									placeholderText="E.g. Acme Inc"
									handleTextChange={handleNameChange}
									handleEnterAction={handleNameEnter}
								/>
								<ButtonContainer>
									<Button type="primary" size="large" shape="round" block onClick={handleNameSubmit}>
										Next
									</Button>
								</ButtonContainer>
								{errorMsg && <Alert message={errorMsg} type="error" style={{ marginTop: 20 }} />}
								<TinyText>
									By continuing you agree to our{" "}
									<TinyLink
										href="https://learnink-docs.s3.eu-west-2.amazonaws.com/Learn.ink-CustomerTermsofService-Aug22.pdf"
										target="_blank"
									>
										Customer Terms of Service
									</TinyLink>
									,{" "}
									<TinyLink href="https://learn.ink/privacy" target="_blank">
										Privacy Policy
									</TinyLink>{" "}
									and{" "}
									<TinyLink href="https://learn.ink/aup" target="_blank">
										Acceptable Use Policy
									</TinyLink>
								</TinyText>
							</FormContainer>
						</LeftContainer>
						<RightContainer>
							<img
								src="https://farminkweb.s3.eu-west-2.amazonaws.com/org-name.svg"
								alt="Org Name Mobile"
							/>
						</RightContainer>
					</React.Fragment>
				)}

				{formView === "orgUrl" && (
					<React.Fragment>
						<LeftContainer>
							<BackContainer>
								<LpBackButton backOnClick={() => setFormView("orgName")} />
							</BackContainer>
							<FormContainer>
								<React.Fragment>
									<Title level={2} style={{ width: "100%" }}>
										What would you like your app url to be?
									</Title>
									<Title level={5} style={{ width: "100%" }}>
										We’ll use this to give you and your community a little piece of the internet!
									</Title>
									<UrlInputBox>
										<UrlInputPrefix>m.learn.ink/</UrlInputPrefix>
										<FormInput
											autoFocus
											placeholder="your-chosen-url"
											onChange={handleUrlChange}
											onKeyDown={handleUrlEnter}
										/>
									</UrlInputBox>
									<ButtonContainer>
										<Button
											type="primary"
											size="large"
											shape="round"
											block
											onClick={handleUrlSubmit}
										>
											Next
										</Button>
									</ButtonContainer>
									{errorMsg && <Alert message={errorMsg} type="error" style={{ marginTop: 20 }} />}
								</React.Fragment>
							</FormContainer>
						</LeftContainer>
						<RightContainer>
							<img src="https://farminkweb.s3.eu-west-2.amazonaws.com/org-url.svg" alt="Org Url Mobile" />
						</RightContainer>
					</React.Fragment>
				)}

				{formView === "orgComplete" && (
					<React.Fragment>
						<LeftContainer>
							<FormContainer>
								<React.Fragment>
									<Title level={2} style={{ width: "100%", color: "var(--secondary-color)" }}>
										Tada!
									</Title>
									<Title level={2} style={{ width: "100%" }}>
										Your web app is live and ready to go…
									</Title>
									<Title level={5} style={{ width: "100%" }}>
										Want to get it ready to make a meaningful impact in the community you care
										about? We’re pretty excited to see what you can make!
									</Title>
									<ButtonContainer>
										<Button
											type="primary"
											size="large"
											shape="round"
											block
											onClick={handleRedirect}
										>
											Customize your app
										</Button>
									</ButtonContainer>
								</React.Fragment>
							</FormContainer>
						</LeftContainer>
						<RightContainer>
							<Confetti numberOfPieces={50} gravity={0.15} initialVelocityY={-5} />
							<img
								src="https://farminkweb.s3.eu-west-2.amazonaws.com/org-tada.svg"
								alt="Org Url Mobile"
							/>
						</RightContainer>
					</React.Fragment>
				)}

				{formView === "existingOrgs" && (
					<React.Fragment>
						<LeftContainer>
							<FormContainer>
								<Title level={2} style={{ width: "100%" }}>
									Login to an existing organisation?
								</Title>
								<Text style={{ width: "100%" }}>
									Looks like you are part of the organisations below. Press the 'Select' button to
									access orgs you are already a member of.
								</Text>
								<br></br>
								<OrgCardsBox>
									{userOrgKeyArray.map((targetOrgId) => (
										<OrgCardContainer key={targetOrgId}>
											<OrgCard
												targetOrgId={targetOrgId}
												orgName={userOrgRoles[targetOrgId].org_name}
												role={userOrgRoles[targetOrgId].role}
												activeOrgId={false}
											/>
										</OrgCardContainer>
									))}
								</OrgCardsBox>
								<Text style={{ width: "100%", marginTop: 30 }}>
									Otherwise press 'Create New' button below to create a new organisation
								</Text>
								<ButtonContainer>
									<Button
										type="primary"
										size="large"
										shape="round"
										block
										onClick={handleExistingOrgBtn}
									>
										Create new
									</Button>
								</ButtonContainer>
							</FormContainer>
						</LeftContainer>
						<RightContainer>
							<img src="https://farminkweb.s3.eu-west-2.amazonaws.com/org-url.svg" alt="Org Url Mobile" />
						</RightContainer>
					</React.Fragment>
				)}
			</PageContainer>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	userAuthStatus: selectUserAuthStatus,
	userOrgKeyArray: selectUserOrgKeyArray,
	userOrgRoles: selectUserOrgRoles
})

const mapDispatchToProps = (dispatch) => ({
	checkOrgUrlAvailable: (org_url) => dispatch(checkOrgUrlAvailable(org_url)),
	userCreateNewOrg: (new_org_name, new_org_id) => dispatch(userCreateNewOrg(new_org_name, new_org_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrg)
