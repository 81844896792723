import React from "react"
import { withRouter } from "react-router"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const BackButton = styled.button`
	cursor: pointer;
	text-align: center;
	font-size: 13px;
	line-height: 40px;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	border-radius: 99%;
	border: solid 1px var(--light-grey);
	text-decoration: none;
	color: black;
	:hover {
		background: var(--dark-grey);
		color: white;
		border: solid 1px var(--dark-grey);
	}
`

const LpBackButton = ({ backlink, backOnClick, history }) => {
	return (
		<React.Fragment>
			{backlink && backlink !== "" && (
				<BackButton onClick={() => history.push(backlink)}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</BackButton>
			)}
			{backOnClick && (
				<BackButton onClick={backOnClick}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</BackButton>
			)}
			{!backlink && !backOnClick && (
				<BackButton onClick={() => history.goBack()}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</BackButton>
			)}
		</React.Fragment>
	)
}

LpBackButton.propTypes = {
	backlink: PropTypes.string,
	backOnClick: PropTypes.func,
	history: PropTypes.object.isRequired
}

export default withRouter(LpBackButton)
