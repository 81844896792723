import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgInfo } from "../../../../../../../redux/org/org.selectors"

import Clouds from "../images/overlay_clouds.png"
import Confetti from "../images/overlay_confetti.png"
import { Shapes, RightAngleTriangle } from "./Shapes"

const ImageBackground = styled.div`
	background: url("${(props) =>
		props.url ? props.url : "https://farminkweb.s3.eu-west-2.amazonaws.com/background_hills_light.svg"}");
	background-size: cover;
	overflow: hidden;
`

const Background = ({ backgroundStyle, primaryColor, secondaryColor, width, height, orgInfo }) => {
	return (
		<>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					backgroundColor: primaryColor || orgInfo.theme.primary
				}}
			>
				{backgroundStyle === "horizontal" && (
					<div
						style={{
							width: height * 3,
							height: height * 3,
							borderRadius: 999,
							position: "absolute",
							top: height * 0.45,
							left: -height,
							backgroundColor: secondaryColor || orgInfo.theme.secondary
						}}
					/>
				)}
				{backgroundStyle === "diagonal" && (
					<RightAngleTriangle
						color={secondaryColor || orgInfo.theme.secondary}
						size={height}
						position={{ bottom: 0, right: 0 }}
					/>
				)}
				{backgroundStyle === "curved" && (
					<Shapes
						primaryColor="rgba(255,255,255,0.5)"
						secondaryColor={secondaryColor || orgInfo.theme.secondary}
						height={height}
						borderRadius={20}
						figures={[
							{
								name: "circle",
								position: { bottom: -(height / 1.2), left: -(height / 1.2) },
								size: height * 1.2
							},
							{
								name: "circle",
								position: { top: -(height / 1.2), right: -(height / 1.2) },
								size: height * 1.2
							}
						]}
					/>
				)}
				{backgroundStyle === "shapes" && (
					<Shapes
						primaryColor="rgba(255,255,255,0.5)"
						secondaryColor={secondaryColor || orgInfo.theme.secondary}
						height={height}
						borderRadius={20}
						figures={[
							{
								name: "donut",
								position: { top: -(height / 6), left: -(height / 6) },
								size: height / 1.8
							},
							{
								name: "donut",
								position: { bottom: -(height / 8), right: -(height / 4) },
								size: height / 2
							},
							{ name: "donut", position: { top: height / 10, right: height / 15 }, size: height / 2.5 },
							{
								name: "donut",
								position: { bottom: -(height / 8), left: -(height / 4) },
								size: height / 2.5
							}
						]}
					/>
				)}
				{backgroundStyle === "triangles" && (
					<Shapes
						primaryColor="rgba(255,255,255,0.5)"
						secondaryColor={secondaryColor || orgInfo.theme.secondary}
						height={height}
						borderRadius={20}
						figures={[
							{
								name: "triangle",
								position: { bottom: 0, left: -(height / 1.5) },
								size: height
							},
							{
								name: "triangle",
								position: { bottom: 0, right: -(height / 2) },
								size: height
							}
						]}
					/>
				)}
				{backgroundStyle === "clouds" && <ImageBackground style={{ width, height }} url={Clouds} />}
				{backgroundStyle === "confetti" && <ImageBackground style={{ width, height }} url={Confetti} />}
			</div>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgInfo: selectOrgInfo
})

export default connect(mapStateToProps)(Background)
