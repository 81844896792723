import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import axios from "axios"

import { selectLearningPathById, selectOrgId, selectRoleTypeUserGroups } from "../../../redux/org/org.selectors"
import { bulkEditLearningPath } from "../../../redux/management/management.actions"

import LearningPathSelector from "../../common/LearningPathSelector"
import { User } from "learnink-common/lib/domains/userManagement/users/User.model"
import FormModal from "../../common/FormModal"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { ModalState } from "../../../util/model/PageStates"
import { LearningPathById } from "../../../util/model/OrgSelectors"
import { ISO_8601_string, NonEmptyArray } from "learnink-common/lib/types/types"
import { updateFirebaseToken } from "../../../redux/org/org.actions"

interface Props {
	orgId: string
	usersData: User[]
	closeModal: () => void
	bulkEditLearningPath: (data: User[], learningPathById: LearningPathById) => void
	learningPathById: LearningPathById
	roleUserGroups: UserGroup[],
	getUsers: () => void
	refreshUsers?: (userIds: string[]) => void
}

const EditLearningPathModal = ({
	orgId,
	usersData,
	closeModal,
	bulkEditLearningPath,
	learningPathById,
	roleUserGroups,
	getUsers,
	refreshUsers
}: Props) => {
	const [modalState, setModalState] = useState<ModalState>("loading")
	const [message, setMessage] = useState<string | undefined>(undefined)
	const [filteredLearningPathIds, setFilteredLearningPathIds] = useState<string[]>([])

	useEffect(getUsers, [])

	const handleSubmit = async (values: { learningPathId: string; learningPathStartDate: ISO_8601_string }) => {
		try {
			setModalState("loading")
			const { learningPathId, learningPathStartDate } = values
			await updateFirebaseToken()
			const { data } =
				learningPathId === "NONE"
					? await axios.post(`/api/userManagement/${orgId}/users/learning-path/remove`, {
							userIds: usersData.map((item) => item.userId)
					  })
					: await axios.post(`/api/userManagement/${orgId}/users/learning-path/${learningPathId}`, {
							userIds: usersData.map((item) => item.userId),
							learningPathStartDate: learningPathStartDate
					  })
			setMessage(`${data.length} users updated`)
			setModalState("complete")

			//	Update users in infinite scroll
			refreshUsers?.(usersData.map((item) => item.userId) as NonEmptyArray<string>)
			setTimeout(() => {
				bulkEditLearningPath(data, learningPathById)
			}, 1000)
		} catch (err) {
			setMessage("Something went wrong, please try again")
			setModalState("error")
		}
	}

	useEffect(() => {
		// First check if the users are in the same role
		const allUserGroups = usersData.map((item) => item.userGroups).flat()
		const uniqueUserGroups = [...new Set(allUserGroups.filter((item) => item))]
		const roleUserGroupIds = roleUserGroups.map((item) => item.id)
		// @ts-ignore
		const uniqueRoleIds = uniqueUserGroups.filter((item) => roleUserGroupIds.includes(item))

		const hasError = uniqueRoleIds.length !== 1
		if (hasError) {
			const errorMessage =
				uniqueRoleIds.length === 0
					? "The users you selected are not in a valid role"
					: "You've selected users in multiple roles"
			setModalState("error")
			setMessage(errorMessage)
			return
		}
		const learningPathIds = roleUserGroups
			.filter((item: UserGroup) => item.id === uniqueRoleIds[0])[0]
			.learningPaths.map((item) => item.learningPathId)
			.filter((learningPathId) => learningPathById[learningPathId])
		setFilteredLearningPathIds([...learningPathIds, "NONE"])
		setModalState("confirm")
	}, [])

	return (
		<FormModal
			title="Edit user learning path"
			isModalOpen={true}
			closeModal={closeModal}
			modalState={modalState}
			handleSubmit={handleSubmit}
			formElements={<LearningPathSelector filteredLearningPathIds={filteredLearningPathIds} />}
			submitButtonTitle="Save"
			completeElements={<p>{message}</p>}
			errorElements={<p>{message}</p>}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPathById: selectLearningPathById,
	roleUserGroups: selectRoleTypeUserGroups
})

const mapDispatchToProps = (dispatch: any) => ({
	bulkEditLearningPath: (data: User[], learningPathById: LearningPathById) =>
		dispatch(bulkEditLearningPath(data, learningPathById))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(EditLearningPathModal)
