import { Breadcrumb } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const DashboardHeaderContainer = styled.div`
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: 100%;
	}
`

const CourseFolderBreadcrumbs = ({ rootPath, slug, folder_data }) => {
	const slugArray = slug.split("/")
	return (
		<DashboardHeaderContainer>
			<Breadcrumb>
				<Breadcrumb.Item>
					<Link to={rootPath}>Courses</Link>
				</Breadcrumb.Item>
				{slugArray.map((v, index) => (
					<Breadcrumb.Item key={index}>
						<Link to={rootPath + "/" + slugArray.slice(0, index + 1).join("/")}>
							{folder_data.filter((item) => item.slug === v)[0].name}
						</Link>
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
		</DashboardHeaderContainer>
	)
}

export default CourseFolderBreadcrumbs
