import { ManagementActionTypes } from "../management/management.types"
import { fetchAnalytics } from "../../components/analyse/fetchAnalytics"

export const getCourseJourneyTable = (orgId, courseId, dateRange, orgUserGroups) => async (dispatch) => {
	try {
		const data = await fetchAnalytics("userManagement", orgId, `users/course/${courseId}`, dateRange)
		data.forEach((row) => {
			row.userGroupNames = row.userGroups.map((item) =>
				orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
			)
		})
		dispatch({
			type: ManagementActionTypes.SET_USER_ANALYTICS_TABLE,
			payload: data
		})
	} catch (err) {
		console.log(err)
	}
}

export const getSequenceJourneyTable =
	(orgId, sequenceId, dateRange, orgUserGroups, numCourses) => async (dispatch) => {
		try {
			const data = await fetchAnalytics("userManagement", orgId, `users/sequence/${sequenceId}`, dateRange)
			data.forEach((row) => {
				row.userGroupNames = row.userGroups.map((item) =>
					orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
				)
				row.hoursToComplete = isNaN(parseFloat(row.hoursToComplete)) ? null : parseFloat(row.hoursToComplete)
				row.numCoursesCompleted = row.status === "complete" ? numCourses : row.numCoursesCompleted
			})
			dispatch({
				type: ManagementActionTypes.SET_USER_ANALYTICS_TABLE,
				payload: data
			})
		} catch (err) {
			console.log(err)
		}
	}
