// @ts-ignore
import numeral from "numeral"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { LanguageByLanguageCode } from "learnink-common/lib/constants/language/LanguageByLanguageCode"
import moment from "moment"

interface HoursAndMinutes {
	hours: number
	minutes: number
}

export function toHoursAndMinutes(totalMinutes: number) {
	const hours = Math.floor(totalMinutes / 60)
	const minutes = totalMinutes % 60

	return { hours, minutes } as HoursAndMinutes
}

export function toHoursAndMinutesShortText(totalMinutes: number) {
	const totalTrainingTime = toHoursAndMinutes(totalMinutes)
	return `~ ${totalTrainingTime.hours} hr ${totalTrainingTime.minutes} mins`
}

export function tickFormatter(number: number) {
	return numeral(number).format("0,0")
}

export function generateRandomElementFromArray(array: any) {
	return array[Math.floor(Math.random() * array.length)]
}

export const calculateAverage = (tableData: any[], field: string, numDecimalPlaces: number) => {
	const values = tableData.filter((item) => item[field] && !isNaN(item[field])).map((item) => item[field])
	if (values.length === 0) {
		return "--"
	}
	// @ts-ignore
	const average = values.reduce((a, b) => a + b, 0) / values.length
	if (numDecimalPlaces === 0) {
		return Math.round(average)
	}
	return Math.round(average * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces)
}

export const toLanguage3Code = (languageCode: LanguageCode): LanguageCode | undefined => {
	if (languageCode.length === 3) return languageCode
	if (["en", "sw"].includes(languageCode)) return languageCode
	for (const [language3Code, data] of Object.entries(LanguageByLanguageCode)) {
		if (data.language2Code === languageCode) {
			return language3Code as LanguageCode
		}
	}
	return undefined
}

export const toFormattedLanguage = (languageCode: LanguageCode): string => {
	const language3Code = toLanguage3Code(languageCode)
	if (!language3Code) return "Unknown"
	const language = LanguageByLanguageCode[language3Code]?.language || "Unknown"
	const separator = ";"
	return language.includes(separator) ? language.split(separator)[0] : language
}

export const DATE = "YYYY-MM-DD"
export const DATE_TIME = "YYYY-MM-DD HH:mm"
export const TIME = "HH:mm"

export function toDateFormat(
	isoString: string | undefined,
	format: string,
	fallback?: string | undefined
): string | undefined {
	try {
		if (!isoString) return fallback
		return moment(isoString).format(format)
	} catch (e: unknown) {
		console.log(e)
		return fallback
	}
}
