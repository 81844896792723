import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { createStructuredSelector } from "reselect"
import { Select, Space, Tag } from "antd"
import useUrlQueryString from "../../hooks/useUrlQueryString"

import { selectOrgUserGroups } from "../../../redux/org/org.selectors"

const PlaceholderRegTag = styled.div`
	background-color: var(--dark-grey);
	width: fit-content;
	display: flex;
	justify-content: center;
	align-content: center;
	padding: 2px 8px;
	color: white;
	font-size: 12px;
	border-radius: 2px;
`

const UserGroupFilter = ({ orgUserGroups }) => {
	const options = []
	Object.keys(orgUserGroups).forEach((userGroupId) => {
		if (userGroupId !== "registered" && orgUserGroups[userGroupId] && orgUserGroups[userGroupId].user_group_info) {
			options.push({
				value: userGroupId,
				label: orgUserGroups[userGroupId].user_group_info.name
			})
		}
	})

	const [userGroups, setUserGroups] = useUrlQueryString("userGroups")

	const calcTagValue =
		userGroups && userGroups.split(",").length > 0
			? userGroups.split(",").filter((tag) => Object.keys(orgUserGroups).indexOf(tag) !== -1)
			: []

	const handleTagsChange = (newValue) => {
		if (newValue.length > 0) {
			setUserGroups(newValue.join(","))
		} else {
			setUserGroups(null)
		}
	}

	const tagRender = (props) => {
		const { label, value, closable, onClose } = props
		const onPreventMouseDown = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		return (
			<Tag
				color={orgUserGroups[value].color}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		)
	}

	return (
		<Space
			direction="vertical"
			style={{
				width: "100%"
			}}
		>
			<Select
				showSearch
				mode="multiple"
				showArrow={true}
				style={{ width: "100%" }}
				value={calcTagValue}
				options={options}
				onChange={handleTagsChange}
				placeholder={<PlaceholderRegTag>Registered</PlaceholderRegTag>}
				maxTagCount="responsive"
				tagRender={tagRender}
				optionFilterProp="children"
				filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
			/>
		</Space>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(UserGroupFilter)
