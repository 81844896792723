// TOP NAV FOR COURSE BUILDER

import React, { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button, Space, Drawer } from "antd"
import { ShareAltOutlined, LineChartOutlined, SettingOutlined } from "@ant-design/icons"

import { selectCourseInfo, selectCourseId, selectCompletionData } from "../../../../redux/courses/courses.selectors"
import { selectOrgId, selectOrgCourseCards, selectOrgCourseFolders } from "../../../../redux/org/org.selectors"

import CbBackButton from "../../../common/CbBackButton"
import GeModal from "../../../common/GeModal"
import ShareCourse from "../../courses/modals/ShareCourse"
import CourseOptionsSidePanel from "../sidepanels/CourseOptionsSidePanel"
import PublishCourse from "../modals/PublishCourse"

const NavContainer = styled.div`
	position: fixed;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	background: white;
	box-shadow: 0px 1px 2px #42424291;
	z-index: 150;
	height: 44px;
	width: 100%;
	top: 0px;
	@media screen and (min-width: 850px) {
		width: calc(100% - 230px);
	}
`
const LeftPart = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-start;
	font-size: 16px;
	margin-left: 10px;
`
const TitleText = styled.h2`
	display: none;
	font-size: 18px;
	font-weight: 600;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-left: 10px;
	@media (min-width: 425px) {
		display: flex;
	}
`
const RightPart = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	margin: 6px;
	align-items: center;
`

const CbCourseTopNav = ({
	courseInfo,
	courseId,
	orgId,
	completionData: { percent_complete },
	courseCards,
	courseFolders: { folder_data }
}) => {
	const [showSettings, setShowSettings] = useState(false)

	let slug = ""
	let { course_folder_parent_id } = courseCards[courseId]
	while (course_folder_parent_id !== null) {
		slug = "/" + folder_data.filter((item) => item.id === course_folder_parent_id)[0].slug + slug
		course_folder_parent_id = folder_data.filter((item) => item.id === course_folder_parent_id)[0]
			.course_folder_parent_id
	}

	return (
		<>
			<NavContainer>
				<LeftPart>
					<CbBackButton backLink={`/org/${orgId}/create/courses${slug}`} />
					<TitleText>{courseInfo.name}</TitleText>
				</LeftPart>
				<RightPart>
					<Space>
						{courseInfo.preview_url && !courseInfo.publish_url && (
							<GeModal
								triggerEl={<Button type="primary" shape="circle" icon={<ShareAltOutlined />} />}
								children={
									<ShareCourse
										courseType="preview"
										previewUrl={courseInfo.preview_url}
										publishUrl={courseInfo.publish_url}
										accessGroups={courseInfo.user_groups.access_groups}
									/>
								}
							/>
						)}
						{courseInfo.publish_url && (
							<GeModal
								triggerEl={<Button type="primary" shape="circle" icon={<ShareAltOutlined />} />}
								children={
									<ShareCourse
										courseType="publish"
										previewUrl={courseInfo.preview_url}
										publishUrl={courseInfo.publish_url}
										accessGroups={courseInfo.user_groups.access_groups}
									/>
								}
							/>
						)}

						{courseInfo.publish_url && (
							<Link to={`/org/${orgId}/analyse/courses/insights?courseId=${courseId}&dateRange`}>
								<Button type="primary" shape="circle" icon={<LineChartOutlined />} />
							</Link>
						)}

						<Button
							onClick={() => setShowSettings(true)}
							type="primary"
							shape="circle"
							icon={<SettingOutlined />}
						/>

						<GeModal
							triggerEl={
								<Button type={percent_complete === 100 ? "primary" : "default"} shape="round">
									Publish
								</Button>
							}
							children={<PublishCourse courseId={courseId} />}
						/>
					</Space>
				</RightPart>
			</NavContainer>
			<Drawer
				title="Course settings"
				placement="right"
				onClose={() => setShowSettings(false)}
				open={showSettings}
			>
				<CourseOptionsSidePanel closemodal={() => setShowSettings(false)} />
			</Drawer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	courseInfo: selectCourseInfo,
	courseId: selectCourseId,
	orgId: selectOrgId,
	completionData: selectCompletionData,
	courseFolders: selectOrgCourseFolders,
	courseCards: selectOrgCourseCards
})

CbCourseTopNav.propTypes = {
	courseInfo: PropTypes.object.isRequired,
	courseId: PropTypes.string.isRequired,
	orgId: PropTypes.string.isRequired,
	completionData: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withRouter(CbCourseTopNav))
