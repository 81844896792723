import {
	AscendingOrDescending,
	OrderBy
} from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import { MemberThatCanOrderResults } from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import React from "react"
import { ColumnLabel } from "./UserSearch.model"
import styled from "styled-components"
import * as S from "../../components/common/infiniteScrollTable/Table.styles"

export interface HeaderProps {
	label: ColumnLabel
	index?: number
	orderBy?: OrderBy
	handleChangeOrderBy: (orderBy: OrderBy | undefined) => void
}

interface OrderableHeaderProps extends HeaderProps {
	orderByMember: MemberThatCanOrderResults
}

const _S = {
	OrderableHeader: styled(S.TableHeadInner)`
		.hoverable-icon {
			display: none;
		}
		&:hover {
			.hoverable-icon {
				 {
					color: rgba(0, 0, 0, 0.5);
					display: inline;
				}
			}
		}
	`
}

function OrderableHeader({ label, orderBy, orderByMember, handleChangeOrderBy }: OrderableHeaderProps) {
	const isCurrentHeader = orderBy?.member === orderByMember

	const cycleThroughOrdering = () => {
		if (!isCurrentHeader) {
			return handleChangeOrderBy({
				member: orderByMember,
				ascOrDesc: AscendingOrDescending.options[0]
			})
		}

		const currentIndex = AscendingOrDescending.options.indexOf(orderBy.ascOrDesc)
		const currentIndexIsLastOption = currentIndex === AscendingOrDescending.options.length - 1

		if (currentIndexIsLastOption) {
			return handleChangeOrderBy(undefined)
		}

		return handleChangeOrderBy({
			member: orderByMember,
			ascOrDesc: AscendingOrDescending.options[currentIndex + 1]
		})
	}

	const getOrderByIcon = (orderBy: OrderBy): React.ReactNode => {
		if (!orderBy || !orderBy?.ascOrDesc) return ""
		return orderBy?.ascOrDesc === "desc" ? " ▼" : " ▲"
	}

	return (
		<_S.OrderableHeader onClick={cycleThroughOrdering}>
			{label}{" "}
			<span>{isCurrentHeader ? getOrderByIcon(orderBy) : <span className={"hoverable-icon"}> ▲▼</span>}</span>
		</_S.OrderableHeader>
	)
}

export function StaticHeader({ label }: HeaderProps) {
	return <S.TableHeadInner>{label}</S.TableHeadInner>
}

export function handleCreateOrderableHeader(orderByMember: MemberThatCanOrderResults) {
	return (props: HeaderProps) => {
		return OrderableHeader({ ...props, orderByMember })
	}
}
