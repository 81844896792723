// MODAL TO EDIT LEARNING OBJECTIVE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Popup from "reactjs-popup"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { EditOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select } from "antd"

import { selectObjectives, selectCourseId } from "../../../../redux/courses/courses.selectors"
import { updateObjective, createObjective, switchObjective } from "../../../../redux/courses/courses.actions"

const { TextArea } = Input

const ModalBox = styled.div`
	border-radius: 10px;
	background: white;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 30px 6px 30px;
`
const CloseButton = styled.a`
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: 12px;
	top: 12px;
	font-size: 18px;
	color: var(--dark-grey);
	border-radius: 50%;
	background: none;
	border: 0px solid #cfcece;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
// for frist drop down
const initialOptions = [
	{ value: "edit", label: "Edit current objective" },
	{ value: "select", label: "Select another objective" },
	{ value: "create", label: "Create new objective" }
]

const CbModalEditObjective = ({
	course_id,
	currentStageId,
	currentObjectiveId,
	objectives,
	updateObjective,
	createObjective,
	switchObjective,
	source
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [optionType, setOptionType] = useState(null) // choose base option

	//for select learning objective dropdown
	const objectiveOptions = Object.keys(objectives).map((key) =>
		Object.assign({ value: key, label: objectives[key].content.text })
	)

	const openModal = () => setIsOpen(true)
	const closeModal = () => {
		setOptionType(null)
		setIsOpen(false)
	}

	const handleConfirmClick = (values) => {
		const { option_type, new_text, edit_text, objective_id } = values
		if (option_type === "edit") {
			updateObjective(currentObjectiveId, edit_text)
		} else if (option_type === "select") {
			switchObjective(currentStageId, objective_id, source)
		} else if (option_type === "create") {
			createObjective(course_id, currentStageId, new_text)
		}
		closeModal()
	}

	return (
		<React.Fragment>
			<EditOutlined onClick={openModal} style={{ color: "var(--secondary-color)" }} />

			<Popup open={isOpen} closeOnDocumentClick onClose={closeModal} className="reactJsModal">
				<ModalBox>
					<CloseButton onClick={() => closeModal()}>
						<FontAwesomeIcon icon={faTimes} color="var(--dark-grey)" />
					</CloseButton>
					<Form
						size="large"
						autoComplete="off"
						onFinish={handleConfirmClick}
						layout="vertical"
						initialValues={{
							edit_text: objectives[currentObjectiveId].content.text
						}}
					>
						<Form.Item
							label={<FormLabel>Learning objective</FormLabel>}
							name="option_type"
							rules={[{ required: true, message: "You need to select an option" }]}
						>
							<Select
								showArrow={true}
								style={{ width: "100%" }}
								options={initialOptions}
								placeholder="Select option..."
								onChange={(value) => setOptionType(value)}
							/>
						</Form.Item>
						{optionType === "create" && (
							<Form.Item
								name="new_text"
								rules={[{ required: true, message: "You can't leave this blank" }]}
							>
								<TextArea autoSize placeholder="Enter your learning objective here..." />
							</Form.Item>
						)}
						{optionType === "edit" && (
							<Form.Item
								name="edit_text"
								rules={[{ required: true, message: "You can't leave this blank" }]}
							>
								<TextArea placeholder="Enter your learning objective here..." autoSize />
							</Form.Item>
						)}
						{optionType === "select" && (
							<Form.Item
								name="objective_id"
								rules={[
									{
										required: true,
										message: "You need to select a learning objective"
									}
								]}
							>
								<Select
									showArrow={true}
									style={{ width: "100%" }}
									options={objectiveOptions}
									placeholder="Select learning objective..."
								/>
							</Form.Item>
						)}
						<Form.Item>
							<ButtonContainer>
								<Button size="large" shape="round" onClick={() => closeModal()}>
									Cancel
								</Button>
								<div style={{ height: "100%", width: 10 }}></div>
								<Button type="primary" htmlType="submit" size="large" shape="round">
									Update
								</Button>
							</ButtonContainer>
						</Form.Item>
					</Form>
				</ModalBox>
			</Popup>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	course_id: selectCourseId,
	objectives: selectObjectives
})

const mapDispatchToProps = (dispatch) => ({
	updateObjective: (objective_id, new_text) => dispatch(updateObjective(objective_id, new_text)),
	createObjective: (course_id, stage_id, new_text) => dispatch(createObjective(course_id, stage_id, new_text)),
	switchObjective: (stage_id, new_objective_id, source) =>
		dispatch(switchObjective(stage_id, new_objective_id, source))
})

export default connect(mapStateToProps, mapDispatchToProps)(CbModalEditObjective)
