import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StandardTitle = styled.div`
	margin-bottom: 22px;
	padding-bottom: ${({ underline }) => (underline ? "24px" : "0px")};
	box-sizing: border-box;
	border-bottom: ${({ underline }) => (underline ? "1px solid rgb(213, 220, 227)" : "0px")};
	border-color: rgb(213, 220, 227);
	color: black;
	display: block;
	font-size: 25px;
	font-weight: 600;
	line-height: 1.2;
`
const MediumTitle = styled.div`
	margin-bottom: 12px;
	padding-bottom: ${({ underline }) => (underline ? "14px" : "0px")};
	box-sizing: border-box;
	border-bottom: ${({ underline }) => (underline ? "1px solid rgb(213, 220, 227)" : "0px")};
	border-color: rgb(213, 220, 227);
	color: black;
	display: block;
	font-size: 20px;
	font-weight: 500;
	line-height: 1;
`
const SmallTitle = styled.div`
	margin-bottom: 8px;
	padding-bottom: ${({ underline }) => (underline ? "14px" : "0px")};
	box-sizing: border-box;
	border-bottom: ${({ underline }) => (underline ? "1px solid rgb(213, 220, 227)" : "0px")};
	border-color: rgb(213, 220, 227);
	color: black;
	display: block;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
`

const GeTitle = ({ children, underline, type }) => {
	if (type === "medium") {
		return <MediumTitle underline={underline}>{children}</MediumTitle>
	}
	if (type === "small") {
		return <SmallTitle underline={underline}>{children}</SmallTitle>
	} else {
		return <StandardTitle underline={underline}>{children}</StandardTitle>
	}
}

GeTitle.propTypes = {
	children: PropTypes.node.isRequired,
	underline: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired
}

GeTitle.defaultProps = {
	children: null,
	underline: true,
	type: "standard"
}

export default GeTitle
