import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { fetchAnalytics } from "../fetchAnalytics"
import { selectOrgId } from "../../../redux/org/org.selectors"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import CbLoading from "../../common/CbLoading"
import GeNoItemsFound from "../../common/GeNoItemsFound"

const ChartQuery = ({ orgId, route, params, children }) => {
	const [dateRange] = useUrlQueryString("dateRange")
	const [userGroups] = useUrlQueryString("userGroups")
	const [pageState, setPageState] = useState("loading")
	const [queryData, setQueryData] = useState([])
	let userGroupsArr = userGroups ? userGroups.split(",") : ["registered"]

	const getTableData = async () => {
		try {
			const data = await fetchAnalytics("userAnalytics", orgId, route, dateRange, userGroups, params)
			setQueryData(data)
			setPageState("loaded")
		} catch (err) {
			setQueryData([])
			setPageState("error")
		}
	}

	// Update data whenever date range changes
	useEffect(() => {
		if (dateRange || userGroups) {
			setPageState("loading")
			getTableData()
		}
	}, [dateRange, userGroups, params])

	if (pageState === "loaded" && queryData && queryData.length > 0) {
		return <>{React.cloneElement(children, { queryData, userGroupsArr })}</>
	} else {
		return (
			<div className="row" style={{ padding: 15 }}>
				<div
					style={{
						border: "solid 1px var(--v-light-grey)",
						width: "100%",
						backgroundColor: "white",
						borderRadius: 8,
						padding: 44,
						position: "relative"
					}}
				>
					{pageState === "loading" && <CbLoading size={40} />}
					{pageState === "error" && (
						<GeNoItemsFound
							titleText="Slow query"
							subtitleText="It looks like this query failed to load in time. Please check your connection or select a shorter date range"
							icon={<FontAwesomeIcon icon={faExclamationCircle} />}
						/>
					)}
					{pageState === "loaded" && queryData.length === 0 && (
						<GeNoItemsFound
							titleText="No data"
							subtitleText="We couldn't find any data, try changing your date range and/or user group filter"
							icon={<FontAwesomeIcon icon={faExclamationCircle} />}
						/>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(ChartQuery)
