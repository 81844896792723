import { connect } from "react-redux"
import PanelContainer from "../editor/panels/common/PanelContainer"
import { Button, Form, Select } from "antd"
import { FormLabel } from "../../styles/PageStyles"
import TooltipIcon from "../../common/TooltipIcon"
import React, { useState } from "react"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { ThunderboltOutlined } from "@ant-design/icons"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { getCurrentVoiceClipType } from "./useUpdateVoiceClipType"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"

interface VoiceProfile {
	id: string
	name: string
}

type VoiceProfileType = "teacher" | "learner"

interface Option {
	value: string
	label: string
}

interface Props {
	card: Card
	orgId: string
	stageId: string
	teacherVoiceProfileId: string
	learnerVoiceProfileId: string
	setTeacherVoiceProfileId: any
	setLearnerVoiceProfileId: any
	voiceProfileDefaults: VoiceProfile[] | undefined
	voiceClip: VoiceClip | undefined
	languageCode: LanguageCode
}

interface VoiceClipRequestParams {
	orgId: string
	languageCode: LanguageCode
	cardIds?: string[]
	teacherVoiceProfileId: string
	learnerVoiceProfileId: string
}

const TextToSpeech = ({
	card,
	orgId,
	stageId,
	teacherVoiceProfileId,
	learnerVoiceProfileId,
	setTeacherVoiceProfileId,
	setLearnerVoiceProfileId,
	voiceProfileDefaults,
	voiceClip,
	languageCode
}: Props) => {
	const [isCardProcessing, setIsCardProcessing] = useState<boolean>(false)
	const cardId = card.id
	const savedVoiceClipType = getCurrentVoiceClipType(voiceProfileDefaults, voiceClip)

	const { setApiRequest: requestVoiceClips } = useApi<VoiceClipRequestParams, string>()

	const handleRequestVoiceClips = (cardId?: string) => {
		setIsCardProcessing(true)
		requestVoiceClips({
			route: Routes.requestVoiceClips,
			urlParams: { stageId },
			payload: getRequestParams(cardId),
			onSuccess: (res) => {
				console.log(res)
			}
		})
	}

	const getVoiceOptions = (): Option[] => {
		return (voiceProfileDefaults || [])?.map(({ id, name }) => ({
			value: id,
			label: name
		}))
	}

	const getRequestParams = (cardId?: string): VoiceClipRequestParams => {
		return {
			orgId,
			languageCode,
			teacherVoiceProfileId,
			learnerVoiceProfileId,
			cardIds: cardId ? [cardId] : undefined
		}
	}

	const VoiceSelect = ({ type }: { type: VoiceProfileType }) => {
		const label = `Voice`
		const value = type === "teacher" ? teacherVoiceProfileId : learnerVoiceProfileId
		const onChange = type === "teacher" ? setTeacherVoiceProfileId : setLearnerVoiceProfileId

		return (
			<Form.Item
				label={
					<FormLabel labelFontSize="14px">
						{label} <TooltipIcon tooltip="Choose from a list of available AI voices" />
					</FormLabel>
				}
			>
				<Select
					showArrow
					value={value}
					options={getVoiceOptions()}
					placeholder="Please select..."
					onChange={onChange}
				/>
			</Form.Item>
		)
	}

	return (
		<PanelContainer title="AI generated audio">
			{(card as LessonCard).from === "learner" ? <VoiceSelect type="learner" /> : <VoiceSelect type="teacher" />}
			<Button
				icon={<ThunderboltOutlined />}
				type={voiceClip ? "default" : "primary"}
				shape="round"
				onClick={() => handleRequestVoiceClips(cardId)}
				loading={isCardProcessing}
			>
				{savedVoiceClipType === "textToSpeech" ? "Regenerate" : "AI generate"}
			</Button>
		</PanelContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

// @ts-ignore
export default connect(mapStateToProps)(TextToSpeech)
