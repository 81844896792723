import React, { useRef, useEffect, useState } from "react"
import { Select, DatePicker, Button } from "antd"
import moment from "moment"
import useUrlQueryString from "../../hooks/useUrlQueryString"
import { isJsonString } from "./timeUtils"

const DatePickerDropdown = ({
	rangeDateTo,
	rangeDateFrom,
	dateRangeBackClick,
	onClickOutside,
	onApplyClick,
	onDateFromChange,
	onDateToChange
}) => {
	const dropdownRef = useRef()
	let [calendarOpen, setCalendarOpen] = useState(false)

	useEffect(() => {
		let handleOnClickOutside = (event) => {
			if (!dropdownRef.current.contains(event.target) && !calendarOpen) {
				onClickOutside()
			}
		}

		document.addEventListener("mousedown", handleOnClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleOnClickOutside)
		}
	}, [calendarOpen, onClickOutside])

	return (
		<div ref={dropdownRef}>
			<button
				style={{
					margin: "0 1rem",
					color: "rgba(0, 0, 0, 0.2)",
					fontWeight: 700
				}}
				onClick={dateRangeBackClick}
			>
				&lt;
			</button>
			<hr style={{ margin: "0.5rem 0" }} />
			<div style={{ padding: "0 1rem" }}>
				<label className="secondary">From date</label>
				<br />
				<DatePicker.RangePicker
					defaultValue={[
						rangeDateFrom ? (moment.isMoment(rangeDateFrom) ? rangeDateFrom : moment(rangeDateFrom)) : null,
						rangeDateTo ? (moment.isMoment(rangeDateTo) ? rangeDateTo : moment(rangeDateTo)) : null
					]}
					onOpenChange={(open) => {
						setCalendarOpen(open)
					}}
					onChange={(dates) => {
						if (dates && dates.length === 2) {
							onDateFromChange(dates[0])
							onDateToChange(dates[1])
						}
					}}
					popupStyle={{ zIndex: 999999 }}
				/>
				<br />
				<Button
					type="default"
					disabled={!rangeDateTo || !rangeDateFrom}
					style={{ marginTop: "1rem", marginBottom: "1rem" }}
					onClick={onApplyClick}
				>
					Apply filter
				</Button>
			</div>
		</div>
	)
}

const TimeStartedFilter = () => {
	const [dateRange, setDateRange] = useUrlQueryString("dateRange")

	// catch case where no dateRange set in query-string
	useEffect(() => {
		if (dateRange === undefined) {
			setDateRange("from 28 days ago to now")
		}
	}, [dateRange, setDateRange])

	const [dateRangeOpen, setDateRangeOpen] = useState(false)
	const [open, setOpen] = useState(false)
	const [rangeDateFrom, setRangeDateFrom] = useState(
		dateRange && isJsonString(dateRange) ? JSON.parse(dateRange)[0] : null
	) // WARNING: you should check this is both an array of length > 2 AND a date!
	const [rangeDateTo, setRangeDateTo] = useState(
		dateRange && isJsonString(dateRange) ? JSON.parse(dateRange)[1] : null
	) // WARNING: you should check this is both an array of length > 2 AND a date!

	// note: was using value same as label before, e.g. value: 'Last 7 days"
	// but this doesn't include 'today' to fix this we change to 'from 7 days ago to now'
	const dateOptions = [
		{ value: "today", label: "Started today" },
		{ value: "yesterday", label: "Started yesterday" },
		{ value: "from 7 days ago to now", label: "Started in last 7 days" },
		{ value: "from 14 days ago to now", label: "Started in last 14 days" },
		{ value: "from 28 days ago to now", label: "Started in last 28 days" },
		{ value: "from 90 days ago to now", label: "Started in last 90 days" },
		{ value: "all time", label: "Started on any date" }
	]

	// open the dropdown (unless dateRange view is open)
	const onClick = () => {
		if (dateRangeOpen) {
			return
		}
		setOpen(!open)
	}
	// for closing date range when clicking outside menu
	const onClickOutside = () => {
		setOpen(false)
		setDateRangeOpen(false)
	}
	// for close time selection when clicking outside menu
	const onBlur = () => {
		if (dateRangeOpen) {
			return
		}
		onClickOutside()
	}
	// for Apply click in DateRange view
	const onApplyClick = () => {
		onClickOutside()
		setDateRange(
			JSON.stringify([moment(rangeDateFrom).format("YYYY-MM-DD"), moment(rangeDateTo).format("YYYY-MM-DD")])
		)
	}
	// for arrow to navigate back from DateRange
	const dateRangeBackClick = (e) => {
		e.preventDefault()
		setOpen(true)
		setDateRangeOpen(false)
		document.getElementById("daterange_selector").focus()
	}

	const onChange = (value) => {
		if (value === "Date range") {
			if (open) {
				setOpen(false)
				setDateRangeOpen(true)
			}
		} else {
			setDateRange(value)
		}
	}

	return (
		<>
			<Select
				data-attr="date-filter"
				id="daterange_selector"
				value={dateRange && isJsonString(dateRange) ? JSON.parse(dateRange).join(" - ") : dateRange}
				onClick={onClick}
				style={{ width: "100%" }}
				onChange={onChange}
				onBlur={onBlur}
				dropdownMatchSelectWidth={false}
				open={open || dateRangeOpen}
				dropdownRender={(menu) => {
					if (dateRangeOpen) {
						return (
							<DatePickerDropdown
								onClickOutside={onClickOutside}
								dateRangeBackClick={dateRangeBackClick}
								onApplyClick={onApplyClick}
								onDateFromChange={(date) => setRangeDateFrom(date)}
								onDateToChange={(date) => setRangeDateTo(date)}
								rangeDateFrom={rangeDateFrom}
								rangeDateTo={rangeDateTo}
							/>
						)
					} else if (open) {
						return menu
					}
				}}
			>
				{dateOptions.map((item) => {
					return (
						<Select.Option key={item.label} value={item.value}>
							{item.label}
						</Select.Option>
					)
				})}
				<Select.Option key={"Date range"} value={"Date range"}>
					{"Custom date range"}
				</Select.Option>
			</Select>
		</>
	)
}

export default TimeStartedFilter
