import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { updateCardEditorSettings } from "../../../../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../../../../redux/courses/courses.selectors"

import { faIcons } from "../../../../../editor/imageMap/iconMap"

const IconContainer = styled.div`
border: ${(props) => (props.isActive ? "2px solid var(--primary-color);" : "0px;")}
  :hover {
    background-color: var(--v-light-grey);
  }
`

const Icon = ({ cardId, element, width, height, index, stage, updateCardEditorSettings }) => {
	const handleClick = (e) => {
		e.stopPropagation()
		updateCardEditorSettings({
			menu: "editor",
			activeCardId: cardId,
			imageIndex: index
		})
	}
	return (
		<IconContainer
			id={`call_out_card_icon_${index}`}
			onClick={handleClick}
			isActive={stage.editor.imageIndex === index && stage.editor.activeCardId === cardId}
		>
			{element.imgUrl && (
				<div
					style={{
						width,
						height,
						backgroundSize: "contain",
						backgroundImage: `url(${element.imgUrl})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center"
					}}
				/>
			)}
			{element.iconId && (
				<FontAwesomeIcon
					style={{
						fontSize: height,
						color: element.iconColor || "black",
						lineHeight: `${height}px`
					}}
					icon={faIcons[element.iconId]}
				/>
			)}
			{element.emoji && (
				<span
					style={{
						fontSize: height,
						lineHeight: `${height}px`
					}}
				>
					{element.emoji}
				</span>
			)}
		</IconContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch) => ({
	updateCardEditorSettings: (editor) => dispatch(updateCardEditorSettings(editor))
})

export default connect(mapStateToProps, mapDispatchToProps)(Icon)
