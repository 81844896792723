/* Simulate a mouse click programmatically */

const mouseEvents = ["mousedown", "click", "mouseup"]
export const handleMouseClick = (element: HTMLElement) => {
	mouseEvents.forEach((event) => {
		element.dispatchEvent(
			new MouseEvent(event, {
				view: window,
				bubbles: true,
				cancelable: true,
				buttons: 1
			})
		)
	})
}