import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgId } from "../../../redux/org/org.selectors"
import { bulkDeleteUserState } from "../../../redux/management/management.actions"

import { FirebaseUserId, User } from "learnink-common/lib/domains/userManagement/users/User.model"
import FormModal from "../../common/FormModal"
import { ModalState } from "../../../util/model/PageStates"
import { Alert } from "antd"
import { useApi } from "../../../util/useApi"
import Routes from "../../../util/Routes"

interface Props {
	orgId: string
	usersData: User[]
	closeModal: () => void
	bulkDeleteUserState: (data: string[]) => void
	getUsers: () => void
}

interface DeleteUserParams {
	userIds: FirebaseUserId[]
}

const checkForErrors = (usersData: User[]): string | undefined => {
	if (usersData.length === 0) {
		return "You haven't selected any users to delete"
	}
	if (usersData.length > 100) {
		return "You cannot delete more than 100 users at one time. Please select fewer users and try again"
	}
	const numInvalidUsers = usersData.filter((item) =>
		["registered", "archived"].includes(item.registrationStatus)
	).length
	if (numInvalidUsers > 0) {
		return `You have selected ${numInvalidUsers} users who are already registered. You can only delete unregistered users`
	}
	return undefined
}

const DeleteUsersModel = ({ orgId, usersData, closeModal, bulkDeleteUserState, getUsers }: Props) => {
	const [modalState, setModalState] = useState<ModalState>("loading")
	const [message, setMessage] = useState<string | undefined>(undefined)

	const { setApiRequest, isLoading } = useApi<DeleteUserParams, FirebaseUserId[]>()

	useEffect(getUsers, [])

	const handleSubmit = async () => {
		setApiRequest({
			route: Routes.userDelete,
			urlParams: { orgId },
			payload: { userIds: usersData.map((x) => x.userId) },
			onSuccess: (data) => {
				setMessage(`${data.length} users deleted`)
				setModalState("complete")
				setTimeout(() => {
					bulkDeleteUserState(data)
				}, 1000)
			},
			onError: (error) => {
				setMessage("Something went wrong, please try again")
				setModalState("error")
				console.log({ error })
			}
		})
	}

	useEffect(() => {
		if (isLoading) {
			setModalState("loading")
		}
	}, [isLoading])

	useEffect(() => {
		let errorMessage = checkForErrors(usersData)
		if (errorMessage) {
			setMessage(errorMessage)
			setModalState("error")
		} else {
			setMessage(
				`You have selected ${usersData.length} users for deletion. Are you sure you want to do this? This action can't be undone`
			)
			setModalState("confirm")
		}
	}, [])

	return (
		<FormModal
			title="Delete users"
			isModalOpen={true}
			closeModal={closeModal}
			modalState={modalState}
			handleSubmit={handleSubmit}
			formElements={<Alert message={message} type="warning" />}
			submitButtonTitle="Delete users"
			completeElements={<Alert message={message} type="info" />}
			errorElements={<Alert message={message} type="error" />}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch: any) => ({
	bulkDeleteUserState: (data: string[]) => dispatch(bulkDeleteUserState(data))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(DeleteUsersModel)
