import { connect } from "react-redux"
import PanelContainer from "../editor/panels/common/PanelContainer"
import { Alert, Button, Form, Modal, Select } from "antd"
import { FormLabel } from "../../styles/PageStyles"
import TooltipIcon from "../../common/TooltipIcon"
import React, { useState } from "react"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"
import { ThunderboltOutlined } from "@ant-design/icons"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"

interface VoiceProfile {
	id: string
	name: string
}

type VoiceProfileType = "teacher" | "learner"

interface Option {
	value: string
	label: string
}

interface Props {
	orgId: string
	stageId: string
	teacherVoiceProfileId: string
	learnerVoiceProfileId: string
	setTeacherVoiceProfileId: any
	setLearnerVoiceProfileId: any
	voiceProfileDefaults: VoiceProfile[] | undefined
	languageCode: LanguageCode
}

interface VoiceClipRequestParams {
	orgId: string
	languageCode: LanguageCode
	cardIds?: string[]
	teacherVoiceProfileId: string
	learnerVoiceProfileId: string
}

const TextToSpeech = ({
	orgId,
	stageId,
	teacherVoiceProfileId,
	learnerVoiceProfileId,
	setTeacherVoiceProfileId,
	setLearnerVoiceProfileId,
	voiceProfileDefaults,
	languageCode
}: Props) => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

	const { setApiRequest: requestVoiceClips } = useApi<VoiceClipRequestParams, string>()

	const handleRequestVoiceClips = () => {
		requestVoiceClips({
			route: Routes.requestVoiceClips,
			urlParams: { stageId },
			payload: getRequestParams(),
			onSuccess: (res) => {
				console.log(res)
				setModalIsOpen(false)
			}
		})
	}

	const getVoiceOptions = (): Option[] => {
		return (voiceProfileDefaults || [])?.map(({ id, name }) => ({
			value: id,
			label: name
		}))
	}

	const getRequestParams = (): VoiceClipRequestParams => {
		return {
			orgId,
			languageCode,
			teacherVoiceProfileId,
			learnerVoiceProfileId
		}
	}

	const VoiceSelect = ({ type }: { type: VoiceProfileType }) => {
		const label = `${type === "teacher" ? "Teacher" : "Learner"} voice`
		const value = type === "teacher" ? teacherVoiceProfileId : learnerVoiceProfileId
		const onChange = type === "teacher" ? setTeacherVoiceProfileId : setLearnerVoiceProfileId

		return (
			<Form.Item
				label={
					<FormLabel labelFontSize="14px">
						{label} <TooltipIcon tooltip="Choose from a list of available AI voices" />
					</FormLabel>
				}
			>
				<Select
					showArrow
					value={value}
					options={getVoiceOptions()}
					placeholder="Please select..."
					onChange={onChange}
				/>
			</Form.Item>
		)
	}

	return (
		<PanelContainer>
			<VoiceSelect type="teacher" />
			<VoiceSelect type="learner" />
			<Button icon={<ThunderboltOutlined />} shape="round" onClick={() => setModalIsOpen(true)}>
				Generate all
			</Button>
			<Modal
				open={modalIsOpen}
				centered
				title="Generate audio for all cards"
				onCancel={() => setModalIsOpen(false)}
				onOk={() => handleRequestVoiceClips()}
				okText="Confirm"
			>
				<Alert
					message="Are you sure you want to do this? This will generate AI voice clips for all cards and will overwrite any voice clips you've already created"
					type="warning"
				/>
			</Modal>
		</PanelContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

// @ts-ignore
export default connect(mapStateToProps)(TextToSpeech)
