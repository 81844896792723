// MODULES COLUMN

import React, { Component } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Droppable } from "react-beautiful-dnd"
import { Alert, Typography } from "antd"

import { selectCourseId, selectModuleId } from "../../../../redux/courses/courses.selectors"
import { createNewModule } from "../../../../redux/courses/courses.actions"

import CbModuleCard from "../cards/CbModuleCard"
import CbModalTextBox from "../../../common/CbModalTextBox"

const { Title } = Typography

const Container = styled.div`
	padding: 10px;
`
const CardsList = styled.div`
	transition: background-color 0.2s ease;
	background-color: var(--off-white);
	flex-grow: 1;
	min-height: calc(100% - 44px);
`
const ButtonContainer = styled.div`
	flex: 1;
	margin-top: 20px;
	display: flex;
	justify-content: center;
`
const EmptyColumnNote = styled.div`
	display: flex;
	justify-content: center;
`

class CbCourseModulesColumn extends Component {
	componentDidUpdate = (prevProps) => {
		// Scroll to bottom of list if a new card has been added
		if (this.props.cards[this.props.cards.length - 2] === prevProps.cards[prevProps.cards.length - 1]) {
			this.scrollToBottom()
		}
	}

	scrollToBottom = () => {
		this.listEnd.scrollIntoView()
	}

	handleCreateModuleWithText = (newText) => {
		this.props.createNewModule(this.props.course_id, newText)
	}

	render() {
		const { droppableId, title, cards, module_id, selectModuleId } = this.props
		return (
			<Container>
				<Title level={5}>{title}</Title>

				<Droppable droppableId={droppableId}>
					{(provided, snapshot) => (
						<CardsList
							ref={provided.innerRef}
							{...provided.droppableProps}
							isDraggingOver={snapshot.isDraggingOver}
						>
							{cards.length === 0 && (
								<EmptyColumnNote>
									<Alert
										type="info"
										message="Add your first module to this course with the ADD MODULE
                    button below"
									/>
								</EmptyColumnNote>
							)}
							{cards.map((card, index) => (
								<CbModuleCard
									key={card.id}
									card={card}
									index={index}
									isSelected={card.id === module_id}
									droppableId={droppableId}
									selectModuleId={selectModuleId}
								/>
							))}
							{provided.placeholder}

							<div
								style={{ float: "left", clear: "both" }}
								ref={(el) => {
									this.listEnd = el
								}}
							/>

							<ButtonContainer>
								<CbModalTextBox
									type="button"
									modalTitle="Create new module"
									handleNewText={this.handleCreateModuleWithText}
									clearOnSubmit={true}
									buttonTitle="Add module"
									placeholder="Enter module title..."
								>
									Add module
								</CbModalTextBox>
							</ButtonContainer>
						</CardsList>
					)}
				</Droppable>
			</Container>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	course_id: selectCourseId,
	module_id: selectModuleId
})

const mapDispatchToProps = (dispatch) => ({
	createNewModule: (course_id, new_module_name) => dispatch(createNewModule(course_id, new_module_name))
})

export default connect(mapStateToProps, mapDispatchToProps)(CbCourseModulesColumn)
