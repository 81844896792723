import { createSelector } from "reselect"
import { LearningPathContent } from "../../pages/learningPaths/utils/LearningPathContent"
import { defaultPremiumFeatureAccess } from "./org.model"

export const selectOrg = (state) => state.org

export const selectOrgData = createSelector([selectOrg], (org) => org.orgData)

export const selectOrgCourses = createSelector([selectOrgData], (orgData) => orgData.course_ids)

export const selectOrgCourseCards = createSelector([selectOrgData], (orgData) => orgData.course_cards)

export const selectOrgCourseFolders = createSelector([selectOrgData], (orgData) => orgData.course_folders)

export const selectOrgInfo = createSelector([selectOrgData], (orgData) => orgData.info)

export const selectUserGroups = createSelector([selectOrgData], (orgData) => orgData.userGroups)

export const selectRoleTypeUserGroups = createSelector([selectOrgData], (orgData) => {
	return orgData.userGroups.filter((item) => item.type === "role")
})

/** @deprecated - Required to support older components */
export const selectOrgUserGroups = createSelector([selectOrgData], (orgData) => {
	let orgUserGroups = {}
	orgData.userGroups.forEach((userGroup) => {
		orgUserGroups[userGroup.id] = {
			user_group_info: {
				name: userGroup.name,
				description: userGroup.description
			},
			access_code: userGroup.accessCode,
			type: userGroup.type,
			color: userGroup.color,
			learningPaths: userGroup.learningPaths
		}
	})
	return orgUserGroups
})

export const selectOrgUserGroupNamesMap = createSelector([selectOrgUserGroups], (userGroups) => {
	let userGroupsNamesMap = {}
	for (const key of Object.keys(userGroups)) {
		userGroupsNamesMap[key] = userGroups[key].user_group_info.name
	}

	return userGroupsNamesMap
})

export const selectOrgUserGroupColorsMap = createSelector([selectOrgUserGroups], (userGroups) => {
	let userGroupColors = {}

	for (const key of Object.keys(userGroups)) {
		let name = userGroups[key].user_group_info.name
		let color = userGroups[key].color
		userGroupColors[name] = color
	}

	return userGroupColors
})

export const selectOrgName = createSelector([selectOrgInfo], (info) => info.org_name)

export const selectOrgImg = createSelector([selectOrgInfo], (info) => info.logo_image_url)

export const selectOrgId = createSelector([selectOrgData], (data) => data.org_id)

export const selectOrgTeam = createSelector([selectOrg], (user) => user.orgTeam)

export const selectLearningPaths = createSelector([selectOrgData], (orgData) => orgData.learningPaths)

export const selectLearningPathById = createSelector([selectLearningPaths], (learningPaths) => {
	let learningPathById = {
		NONE: {
			internalName: "None",
			color: "var(--light-grey)",
			type: undefined,
			startAt: undefined,
			status: undefined
		}
	}
	learningPaths.forEach((learningPath) => {
		learningPathById[learningPath.id] = {
			internalName: learningPath.internalName,
			color: new LearningPathContent(learningPath).getColor(),
			type: learningPath.type,
			startAt: learningPath.startAt,
			status: new LearningPathContent(learningPath).getStatus()
		}
	})
	return learningPathById
})

export const selectThemeColors = createSelector([selectOrgData], (orgData) => {
	return {
		ORG_THEME: { backgroundColor: orgData.info.theme.primary, textColor: "white" },
		GOLD: { backgroundColor: "#F6CA45", textColor: "#C27B2F" },
		SILVER: { backgroundColor: "#CBD4DB", textColor: "#657582" },
		BRONZE: { backgroundColor: "#DB9A61", textColor: "#8D5829" }
	}
})

export const selectSequenceList = createSelector([selectOrgData], (orgData) => orgData.sequences)

export const selectSequenceNameById = createSelector([selectSequenceList], (sequenceList) => {
	let sequenceNameById = {}
	sequenceList.forEach((sequence) => {
		sequenceNameById[sequence.id] = sequence.internalName
	})
	return sequenceNameById
})

function treeifyCourseFolders(nodes) {
	var indexed_nodes = {},
		tree_roots = []
	for (var i = 0; i < nodes.length; i += 1) {
		indexed_nodes[nodes[i].id] = nodes[i]
	}
	for (var i = 0; i < nodes.length; i += 1) {
		var { course_folder_parent_id, id, name, children } = nodes[i]
		if (course_folder_parent_id === null) {
			tree_roots.push({ value: id, title: name, children })
		} else if (indexed_nodes[course_folder_parent_id]) {
			indexed_nodes[course_folder_parent_id].children.push({
				value: id,
				title: name,
				children
			})
		}
	}
	return tree_roots
}

export const selectOrgCourseFolderTree = (state) => [
	{
		value: 0,
		title: "Courses",
		children: treeifyCourseFolders(
			state.org.orgData.course_folders.folder_data.map((item) => ({ ...item, children: [] }))
		)
	}
]

function treeifyCourses(nodes, courses) {
	var indexed_nodes = {},
		tree_roots = []
	for (var i = 0; i < nodes.length; i += 1) {
		indexed_nodes[nodes[i].id] = nodes[i]
	}
	for (var i = 0; i < nodes.length; i += 1) {
		var { course_folder_parent_id, id, name, children } = nodes[i]
		Object.keys(courses).forEach((courseId) => {
			if (
				courses[courseId].course_folder_parent_id === id &&
				courses[courseId].info.publish_url !== null &&
				!courses[courseId].info.user_groups.view_groups.includes("archived")
			) {
				children.push({
					key: courseId,
					value: courseId,
					title: courses[courseId].info.name,
					style: { color: "var(--secondary-color)" },
					selectable: true
				})
			}
		})
		if (course_folder_parent_id === null) {
			tree_roots.push({
				key: id,
				value: id,
				title: name,
				children,
				selectable: false,
				style: { fontWeight: "bold" }
			})
		} else if (indexed_nodes[course_folder_parent_id]) {
			indexed_nodes[course_folder_parent_id].children.push({
				key: id,
				value: id,
				title: name,
				children,
				selectable: false,
				style: { fontWeight: "bold" }
			})
		}
	}
	Object.keys(courses).forEach((courseId) => {
		if (
			courses[courseId].course_folder_parent_id === null &&
			courses[courseId].info.publish_url !== null &&
			!courses[courseId].info.user_groups.view_groups.includes("archived")
		) {
			tree_roots.push({
				key: courseId,
				value: courseId,
				title: courses[courseId].info.name,
				style: { color: "var(--secondary-color)" },
				selectable: true
			})
		}
	})
	return tree_roots
}

export const selectOrgCourseTree = createSelector([selectOrgData], (orgData) => {
	return {
		value: 0,
		title: "Courses",
		selectable: false,
		children: treeifyCourses(
			orgData.course_folders.folder_data.map((item) => ({ ...item, children: [] })),
			orgData.course_cards
		),
		style: { fontWeight: "bold" }
	}
})

export const selectPremiumFeatureAccess = createSelector([selectOrg], (org) => {
	if (org && org.orgData && org.orgData.feature_access && org.orgData.feature_access.premiumFeatureAccess) {
		return { ...defaultPremiumFeatureAccess, ...org.orgData.feature_access.premiumFeatureAccess }
	}
	return defaultPremiumFeatureAccess
})
