import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../../redux/org/org.selectors"

import BasicTable from "../../../common/BasicTable"

const UserGroupSummaryTable = ({ userGroupsArr, data, orgUserGroupsNames }) => {
	const tableData = []
	if (userGroupsArr.length > 0) {
		userGroupsArr.forEach((userGroup) => {
			const userGroupName = orgUserGroupsNames[userGroup]
			const filteredData = data.filter((item) => item.userGroups.includes(userGroup))
			const rowsWithScore = filteredData.filter((item) => item.averageScore !== null)
			const averageScore =
				rowsWithScore
					.map((item) => parseInt(item.averageScore, 10))
					.reduce((partialSum, a) => partialSum + a, 0) / rowsWithScore.length
			const rowsWithHoursToComplete = filteredData.filter(
				(item) => item.hoursToComplete !== null && item.hoursToComplete < 12
			)
			const averageHoursToComplete =
				rowsWithHoursToComplete
					.map((item) => parseInt(item.hoursToComplete, 10))
					.reduce((partialSum, a) => partialSum + a, 0) / rowsWithHoursToComplete.length
			const numStartedSequence = filteredData.length
			const numCompletedSequence = filteredData.filter((item) => item.completedAt !== null).length
			const conversion =
				numStartedSequence > 0 ? Math.round((numCompletedSequence / numStartedSequence) * 100) : null
			tableData.push({
				userGroupName,
				numStartedSequence,
				numCompletedSequence,
				conversion,
				averageScore: averageScore ? Math.round(averageScore) : null,
				averageHoursToComplete: averageHoursToComplete ? averageHoursToComplete.toFixed(1) : null
			})
		})
	} else {
		const rowsWithScore = data.filter((item) => item.averageScore !== null)
		const averageScore =
			rowsWithScore.map((item) => parseInt(item.averageScore, 10)).reduce((partialSum, a) => partialSum + a, 0) /
			rowsWithScore.length
		const rowsWithHoursToComplete = data.filter(
			(item) => item.hoursToComplete !== null && item.hoursToComplete < 12
		)
		const averageHoursToComplete =
			rowsWithHoursToComplete
				.map((item) => parseInt(item.hoursToComplete, 10))
				.reduce((partialSum, a) => partialSum + a, 0) / rowsWithHoursToComplete.length
		const numStartedSequence = data.length
		const numCompletedSequence = data.filter((item) => item.completedAt !== null).length
		const conversion = numStartedSequence > 0 ? Math.round((numCompletedSequence / numStartedSequence) * 100) : null
		tableData.push({
			userGroupName: orgUserGroupsNames["registered"],
			numStartedSequence: data.length,
			numCompletedSequence: data.filter((item) => item.completedAt !== null).length,
			conversion,
			averageScore: averageScore ? Math.round(averageScore) : null,
			averageHoursToComplete: averageHoursToComplete ? averageHoursToComplete.toFixed(1) : null
		})
	}

	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "User group",
						accessor: "userGroupName"
					},
					{
						Header: "Num started",
						accessor: "numStartedSequence"
					},
					{
						Header: "Num completed",
						accessor: "numCompletedSequence"
					},
					{
						Header: "% conversion",
						accessor: "conversion"
					},
					{
						Header: "Av. final score",
						accessor: "averageScore"
					},
					{
						Header: "Av. hours to complete",
						accessor: "averageHoursToComplete"
					}
				]}
				data={tableData}
			/>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsNames: selectOrgUserGroupNamesMap
})

export default connect(mapStateToProps)(UserGroupSummaryTable)
