// MODAL TO MOVE A STAGE TO ANOTHER MODULE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert, Select, Typography, Space } from "antd"

import { moveStageToNewModule } from "../../../../redux/courses/courses.actions"
import { selectCourseModuleCards } from "../../../../redux/courses/courses.selectors"

import CbLoading from "../../../common/CbLoading"

const { Text, Title } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
	padding: 30px;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const MoveToModule = ({ closemodal, stage_id, module_id, moduleCards, moveStageToNewModule }) => {
	const [loading, setLoading] = useState(false)
	const [selectedModuleId, setSelectedModuleId] = useState(null)
	const [errorMsg, setErrorMsg] = useState(null)

	// handlers for move module option
	let moduleOptions = Object.keys(moduleCards).map((key) => {
		return {
			value: key, // module_id
			label: moduleCards[key].info.name // name
		}
	})
	moduleOptions = moduleOptions.filter((item) => item.value !== module_id)
	const handleSelectNewModule = (val) => {
		setSelectedModuleId(val)
		setErrorMsg(null)
	}
	const handleMoveToNewModuleClick = () => {
		if (selectedModuleId) {
			setLoading(true)
			const old_module_id = module_id
			const new_module_id = selectedModuleId
			setTimeout(() => {
				moveStageToNewModule(stage_id, old_module_id, new_module_id)
				setSelectedModuleId(null)
				setErrorMsg(null)
				setLoading(false)
				closemodal()
			}, 2000)
		} else {
			setErrorMsg("You must select a module")
		}
	}

	return (
		<ContentContainer>
			<Title level={4}>Move to module</Title>
			{loading ? (
				<>
					<CbLoading padding="30px" />
				</>
			) : (
				<>
					<Text>Select the module you'd like to move this stage to</Text>
					<Divider height={"10px"} />
					<Select
						showArrow={true}
						style={{ width: "100%" }}
						options={moduleOptions}
						placeholder="Select module..."
						onChange={(value) => handleSelectNewModule(value)}
						size="large"
					/>
					<Divider height={"10px"} />
					{errorMsg && <Alert message={errorMsg} type="error" />}
					<Divider height={"20px"} />
					<ButtonsContainer>
						<Space>
							<Button size="large" shape="round" onClick={closemodal}>
								Cancel
							</Button>
							<Button type="primary" size="large" shape="round" onClick={handleMoveToNewModuleClick}>
								Confirm
							</Button>
						</Space>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	moduleCards: selectCourseModuleCards
})

const mapDispatchToProps = (dispatch) => ({
	moveStageToNewModule: (stage_id, old_module_id, new_module_id) =>
		dispatch(moveStageToNewModule(stage_id, old_module_id, new_module_id))
})

PropTypes.ArchiveCourse = {
	closemodal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveToModule)
