// MODAL FOR TRANSLATING A COURSE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography, TreeSelect } from "antd"

import { selectOrgId, selectOrgCourseFolderTree } from "../../../../redux/org/org.selectors"
import { updateCourseFolderParentId, updateCourseParentId } from "../../../../redux/courses/courses.actions"

const { Title } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const MoveToFolder = ({
	closemodal,
	id,
	type,
	courseFolderTree,
	updateCourseParentId,
	updateCourseFolderParentId,
	orgId
}) => {
	const [selectedFolder, setSelectedFolder] = useState(null)

	const handleMoveCourse = async () => {
		try {
			if (type === "course") {
				await updateCourseParentId(id, selectedFolder, orgId)
				closemodal()
			} else {
				await updateCourseFolderParentId(id, selectedFolder, orgId)
				closemodal()
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<ContentContainer>
			<Title level={4}>Move to folder</Title>
			<TreeSelect
				style={{ width: "100%" }}
				value={selectedFolder}
				dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
				treeData={courseFolderTree}
				placeholder="Please select"
				treeDefaultExpandAll
				onChange={(value) => setSelectedFolder(value)}
			/>

			<Divider />

			<ButtonsContainer>
				<Button size="large" shape="round" onClick={closemodal}>
					Cancel
				</Button>
				<div style={{ width: "20px" }} />
				<Button
					type="primary"
					size="large"
					shape="round"
					onClick={() => handleMoveCourse()}
					disabled={selectedFolder === null}
				>
					Move
				</Button>
			</ButtonsContainer>
		</ContentContainer>
	)
}

PropTypes.MoveToFolder = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	courseFolderTree: selectOrgCourseFolderTree
})

const mapDispatchToProps = (dispatch) => ({
	updateCourseParentId: (id, course_folder_parent_id, org_id) =>
		dispatch(updateCourseParentId(id, course_folder_parent_id, org_id)),
	updateCourseFolderParentId: (id, course_folder_parent_id, org_id) =>
		dispatch(updateCourseFolderParentId(id, course_folder_parent_id, org_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveToFolder)
