import React from "react"
import { HeaderGroup } from "react-table"
import * as S from "./Table.styles"

interface Props<Item extends object> {
	headerGroups: HeaderGroup<Item>[]
	headerRef: React.RefObject<any>
}

const Header = "Header"

export default function TableHeader<Item extends object>({ headerGroups, headerRef }: Props<Item>) {
	return (
		<S.Thead ref={headerRef}>
			{headerGroups.map((headerGroup: HeaderGroup<Item>) => (
				<S.Tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column) => {
						return (
							<S.Th {...column.getHeaderProps()}>
								{column.render(Header)}
								<S.TableResizer
									{...column.getResizerProps()}
									isResizing={column.isResizing}
								></S.TableResizer>
							</S.Th>
						)
					})}
				</S.Tr>
			))}
		</S.Thead>
	)
}
