import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import * as S from "../styles/PageStyles"
import { Tag, Tooltip } from "antd"

const BigButton = styled.div`
	border: solid 1px var(--light-grey);
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding: 8px;
	margin-right: 16px;
	cursor: pointer;
	transition: 0.5s ease;
	font-size: 13px;
	height: 60px;
`
const Bubble = styled.div`
	width: 28px;
	height: 28px;
	font-size: 14px;
	border-radius: 999px;
	border-style: solid;
	border-width: 1px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
`

interface Props {
	isOn: boolean
	faIcon?: any
	color?: string
	text: string
	onClick: () => void
	showOnOff?: boolean
	disabled?: boolean
	width?: number
	tooltip?: string
}

const BigOnOffButton = ({ isOn, faIcon, color, text, onClick, showOnOff, disabled, width, tooltip }: Props) => {
	if (disabled) {
		return (
			<Tooltip title={tooltip}>
				<BigButton
					style={{
						borderColor: "var(--light-grey)",
						width: width || 210,
						opacity: 0.8
					}}
				>
					{faIcon && (
						<Bubble
							style={{
								background: "var(--v-light-grey)",
								borderColor: "var(--light-grey)"
							}}
						>
							<FontAwesomeIcon icon={faIcon as IconProp} color="grey" />
						</Bubble>
					)}
					<div
						style={{
							flex: 1,
							padding: "0 10px 0 10px",
							color: "grey",
							textAlign: showOnOff || faIcon ? "left" : "center"
						}}
					>
						{text}
					</div>
					{showOnOff && (
						<Tag color={"var(--light-grey)"} style={{ margin: 0, fontWeight: "bold" }}>
							OFF
						</Tag>
					)}
				</BigButton>
			</Tooltip>
		)
	}

	return (
		<BigButton
			onClick={onClick}
			style={{
				borderColor: isOn ? "var(--secondary-color)" : "var(--light-grey)",
				background: isOn ? "var(--secondary-color-pale)" : "white",
				width: width || 210
			}}
		>
			{faIcon && color && (
				<Bubble
					style={{
						background: S.antdTagColors[color] ? S.antdTagColors[color].background : color,
						borderColor: S.antdTagColors[color] ? S.antdTagColors[color].borderColor : color
					}}
				>
					<FontAwesomeIcon
						icon={faIcon as IconProp}
						color={S.antdTagColors[color] ? S.antdTagColors[color].color : "white"}
					/>
				</Bubble>
			)}
			<div style={{ flex: 1, padding: "0 10px 0 10px", textAlign: showOnOff || faIcon ? "left" : "center" }}>
				{text}
			</div>
			{showOnOff && (
				<Tag
					color={isOn ? "var(--secondary-color)" : "var(--light-grey)"}
					style={{ margin: 0, fontWeight: "bold" }}
				>
					{isOn ? "ON" : "OFF"}
				</Tag>
			)}
		</BigButton>
	)
}

export default BigOnOffButton
