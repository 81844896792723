import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import LpButton from "../../components/common/LpButton"
import { appSignOut } from "../../redux/user/user.actions"

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
`

function Signout({ appSignOut }) {
	const handleSignout = () => {
		appSignOut()
		setTimeout(() => {
			window.location.assign(process.env.REACT_APP_URL)
		}, 1000)
	}

	return (
		<Container>
			<LpButton width="260px" onClick={handleSignout}>
				Signout
			</LpButton>
		</Container>
	)
}

const mapDispatchToProps = (dispatch) => ({
	appSignOut: () => dispatch(appSignOut())
})

export default connect(null, mapDispatchToProps)(Signout)
