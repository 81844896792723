import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import OrgCard from "../../components/elements/OrgCard"

import { selectOrgId } from "../../redux/org/org.selectors"
import { selectUserOrgRoles } from "../../redux/user/user.selectors"
import * as S from "../../components/styles/PageStyles"

const OuterContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const InnerContainer = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const OrgCardContainer = styled.div`
	height: 220px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 50%;
	}
	@media (min-width: 1110px) {
		width: 33.3%;
	}
`

const SwitchOrg = ({ orgId, userOrgRoles }) => {
	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton title={"Switch organisation"} />
				<OuterContainer>
					<InnerContainer>
						{Object.keys(userOrgRoles).map((targetOrgId, index) => (
							<OrgCardContainer key={index}>
								<OrgCard
									targetOrgId={targetOrgId}
									orgName={userOrgRoles[targetOrgId].org_name}
									role={userOrgRoles[targetOrgId].role}
									isActiveOrg={targetOrgId === orgId}
								/>
							</OrgCardContainer>
						))}
					</InnerContainer>
				</OuterContainer>
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userOrgRoles: selectUserOrgRoles
})

export default connect(mapStateToProps)(SwitchOrg)
