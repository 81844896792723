import React, { useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import styled from "styled-components"

const FormLabel = styled.label`
	box-sizing: inherit;
	color: black;
	display: block;
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
`

const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderRadius: "4px",
		boxShadow: "none !important",
		border: state.isFocused ? "1px solid var(--secondary-color)" : "1px solid var(--v-light-grey)",
		"&:hover": {
			border: "1px solid var(--secondary-color)"
		}
	}),
	valueContainer: (provided, state) => ({
		...provided,
		background: "none",
		color: "black",
		width: "100%",
		height: state.selectProps.defaultHeight ? null : "44px",
		fontSize: "15px",
		fontWeight: "400",
		marginLeft: "4px"
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none"
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		color: "var(--dark-grey)"
	}),
	menu: (provided, state) => ({
		...provided,
		borderRadius: "4px",
		boxShadow: "0px 4px 6px var(--v-light-grey)"
	}),
	option: (provided, state) => ({
		...provided,
		marginLeft: "4px",
		fontWeight: "500",
		background: state.isFocused ? "none" : "none",
		color: state.isFocused ? "var(--secondary-color)" : "var(--dark-grey)"
	})
}

const LpFormDropdown = ({
	initialValue,
	options,
	placeholder,
	disabled,
	handleChange,
	labelText,
	isSearchable,
	defaultHeight
}) => {
	const [value, setValue] = useState(initialValue)
	const handleLocalChange = (val) => {
		setValue(val)
		handleChange(val) // pass to parent handleChange here!
	}

	return (
		<React.Fragment>
			{labelText && <FormLabel>{labelText}</FormLabel>}

			<Select
				value={value}
				onChange={handleLocalChange}
				options={options}
				placeholder={placeholder}
				styles={customStyles}
				isDisabled={disabled}
				isSearchable={isSearchable}
				defaultHeight={defaultHeight}
			/>
		</React.Fragment>
	)
}

LpFormDropdown.propTypes = {
	initialValue: PropTypes.object,
	options: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
	isSearchable: PropTypes.bool,
	labelText: PropTypes.string,
	defaultHeight: PropTypes.bool
}

LpFormDropdown.defaultProps = {
	initialValue: null,
	placeholder: "Select an option",
	disabled: false,
	isSearchable: true,
	options: [],
	labelText: null,
	defaultHeight: false
}

export default LpFormDropdown
