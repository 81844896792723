import React from "react"
import styled from "styled-components"

import SingleQuestionData from "./SingleQuestionData"
import QuestionCard from "../../../courseBuilder/stage/cards/questionCards/QuestionCard"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"
import { QuestionStyle, Type } from "learnink-common/lib/domains/cards/questions/BaseQuestionCard.model"
import { FrontendMultChoiceQuestionCard } from "learnink-common/lib/domains/cards/legacy/FrontendCard.model"

const Panel = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	min-width: 0;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid var(--v-light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
`
const LeftSection = styled.div`
	width: 360px;
	padding: 16px;
`
const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const legacyToModel = (card: FrontendMultChoiceQuestionCard): MultipleChoiceQuestionCard => {
	const { content } = card
	const multipleChoiceCard: MultipleChoiceQuestionCard = {
		id: card.card_id,
		type: Type.enum.multipleChoiceQuestion,
		text: content?.question_text,
		image: content?.question_image_url,
		answerTip: content?.answer_tip_text,
		style: content?.style || QuestionStyle.enum.legacy,
		answerOptions: content?.answer_data.map((item) => ({
			id: item.question_ans_id,
			text: item.answer_text,
			correct: item.correct
		}))
	}
	return multipleChoiceCard
}

interface Props {
	singleQuestionContent: FrontendMultChoiceQuestionCard
	accuracyData: any
	questionData: any
}

const SingleQuestionPanel = ({ singleQuestionContent, accuracyData, questionData }: Props) => {
	return (
		<Panel>
			<LeftSection>
				<QuestionCard card={legacyToModel(singleQuestionContent)} />
			</LeftSection>
			<RightSection>
				<SingleQuestionData
					singleQuestionContent={singleQuestionContent}
					accuracyData={accuracyData}
					questionData={questionData}
				/>
			</RightSection>
		</Panel>
	)
}

export default SingleQuestionPanel
