import { LearnActionTypes } from "./learn.types"

const initialState = {
	lessonPreviewData: {},
	quizPreviewData: []
}

export default function (state = initialState, action) {
	switch (action.type) {
		case LearnActionTypes.SET_LESSON_CONTENT:
			return {
				...state,
				lessonPreviewData: action.payload
			}
		case LearnActionTypes.SET_QUIZ_CONTENT:
			return {
				...state,
				quizPreviewData: action.payload
			}
		default:
			return state
	}
}
