// PRICING PLAN COMPONENT

import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Button } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgId } from "../../redux/org/org.selectors"
import { createCheckoutSession } from "../../redux/subscription/subscription.actions"

const PricingColumn = styled.div`
	width: 33%;
	float: left;
	padding: 20px 1% 2% 1%;
	@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
		width: 50%;
	}
	@media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 1) {
		width: 100%;
	}
`
const PricingColumnInner = styled.div`
	width: 100%;
	border: solid 1px var(--light-grey);
	border-radius: 10px;
	display: block;
	padding: 25px;
	min-height: 350px;
`
const ProductNameContainer = styled.div`
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	justify-content: space-between;
	box-sizing: border-box;
	display: flex;
`
const ProductName = styled.div`
	flex: 1;
	padding-right: 10px;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.2;
	display: block;
`
const ProductFlag = styled.div`
	height: 20px;
	background-color: var(--secondary-color);
	color: rgb(255, 255, 255);
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
	font-size: 11px;
	box-sizing: border-box;
	font-weight: 500;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 10px;
	border-width: 1px;
	border-style: solid;
	border-image: initial;
	border-color: rgb(31, 182, 255);
`
const ProductPriceContainer = styled.div`
	-webkit-box-align: center;
	align-items: center;
	min-height: 62px;
	margin-top: 16px;
	margin-bottom: 16px;
	box-sizing: border-box;
	display: flex;
`
const OriginalPriceContainer = styled.div`
	color: rgb(170, 181, 197);
	display: block;
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 1.5;
	text-decoration: line-through;
`
const CurrentPriceContainer = styled.div`
	color: rgb(12, 16, 20);
	display: inline;
	font-size: 28px;
	font-weight: 600;
	line-height: 1.15;
`
const CurrentPricePeriod = styled.div`
	display: inline;
	font-size: 18px;
	line-height: 1.5;
`
const ProductInfoContainer = styled.div`
	margin-top: 10px;
	padding-top: 10px;
	box-sizing: border-box;
`
const ProductInfoList = styled.div`
	margin-top: 16px;
	box-sizing: border-box;
`
const ProductListItem = styled.div`
	align-items: flex-start;
	margin-bottom: 8px;
	box-sizing: border-box;
	display: flex;
	font-size: 14px;
`
const EnterprisePrice = styled.div`
	width: 100%;
	font-size: 18px;
	line-height: 1.5;
`

const currencies = {
	GBP: "£",
	USD: "$"
}

function PricingPlan({ plan, timePeriod, orgId, createCheckoutSession }) {
	const handleSelectPlan = async () => {
		try {
			const checkoutSession = await createCheckoutSession(orgId, plan.plan_id, null)
			if (checkoutSession) {
				window.location.assign(checkoutSession)
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<PricingColumn>
			<PricingColumnInner>
				<ProductNameContainer>
					<ProductName>{plan.info.display_name}</ProductName>
					{plan.info.original_price && (
						<ProductFlag>
							You save{" "}
							{Math.floor(
								((plan.info.original_price - plan.info.price) * 100) / plan.info.original_price
							)}
							%
						</ProductFlag>
					)}
				</ProductNameContainer>
				<ProductPriceContainer>
					<div>
						{plan.info.original_price && (
							<OriginalPriceContainer>
								{currencies[plan.info.currency]}
								{plan.info.original_price}/{timePeriod}
							</OriginalPriceContainer>
						)}
						{plan.info.price > 0 && (
							<React.Fragment>
								<CurrentPriceContainer>
									{currencies[plan.info.currency]}
									{plan.info.price}
								</CurrentPriceContainer>
								<CurrentPricePeriod>/{timePeriod}</CurrentPricePeriod>
							</React.Fragment>
						)}
						{!plan.info.price && plan.info.min_price && (
							<React.Fragment>
								<EnterprisePrice>
									from{" "}
									<b>
										{currencies[plan.info.currency]}
										{plan.info.min_price}
									</b>
									/{plan.info.billing_period}
								</EnterprisePrice>
								{plan.info.price_calculator && (
									<a
										href={plan.info.price_calculator}
										target="_blank"
										rel="noopener noreferrer"
										style={{ fontWeight: "bold", color: "#00c9b9" }}
									>
										Calculate your price
									</a>
								)}
							</React.Fragment>
						)}
					</div>
				</ProductPriceContainer>
				<Button type="primary" size="large" shape="round" onClick={() => handleSelectPlan()}>
					Select plan
				</Button>
				<ProductInfoContainer>
					<ProductInfoList>
						{plan.info.feature_list.map((feature, index) => (
							<ProductListItem key={index}>
								<span style={{ color: "var(--mid-green)", marginRight: "8px" }}>
									<FontAwesomeIcon icon={faCheckCircle} />
								</span>
								{feature}
							</ProductListItem>
						))}
					</ProductInfoList>
				</ProductInfoContainer>
			</PricingColumnInner>
		</PricingColumn>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	createCheckoutSession: (orgId, planId, coupon) => dispatch(createCheckoutSession(orgId, planId, coupon))
})

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlan)
