// EDIT RESOURCE FORM

import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Form, Input, Alert, Space } from "antd"

import { selectOrgId } from "../../redux/org/org.selectors"
import { updateResourceItem, deleteResourceItem } from "../../redux/resources/resources.actions"

import CbLoading from "../common/CbLoading"
import UserGroupsSelector from "../common/UserGroupsSelector"
import LpImageInput from "../../components/common/LpImageInput"

const { TextArea } = Input

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
`
const ArchiveGroupText = styled.button`
	color: red;
	margin-top: 26px;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
`

const EditResource = ({ orgId, resourceData, updateResourceItem, deleteResourceItem, closemodal }) => {
	const [panelView, setPanelView] = useState("main")
	const [image, setImage] = useState(resourceData.image_url)
	const [loading, setLoading] = useState(false)
	const [showSaveChanges, setShowSaveChanges] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [reload, setReload] = useState(null)

	const handleResourceUpdate = async (values) => {
		try {
			setLoading(true)
			setErrorMsg(null)
			const { title, description, url, user_groups } = values
			let updatedInfo = {
				title: title.trim(),
				description: description.trim(),
				url: url.trim(),
				image_url: image,
				type: resourceData.type,
				user_groups: {
					view_groups: user_groups,
					access_groups: user_groups
				}
			}
			// handle update here
			await updateResourceItem(orgId, resourceData.resource_id, updatedInfo)
			// dummmy loading below...
			setTimeout(() => {
				setLoading(false)
				closemodal()
			}, 3000)
		} catch (err) {
			setTimeout(() => {
				setErrorMsg("Sorry we couldn't update this resource, please try again")
				setLoading(false)
			}, 2000)
		}
	}

	// we delete the resource from db...
	const handleDeleteResource = async () => {
		try {
			setLoading(true)
			const response = await deleteResourceItem(orgId, resourceData.resource_id)
			if (response && response.status & (response.status === "ok")) {
				setLoading(false)
				closemodal()
			} else {
				setErrorMsg("Sorry we couldn't delete this resource, please try again")
				setLoading(false)
			}
		} catch (err) {
			setTimeout(() => {
				setErrorMsg("Sorry we couldn't delete this resource, please try again")
				setLoading(false)
			}, 2000)
		}
	}

	useEffect(() => {
		setReload(true)
		setPanelView("main")
		setShowSaveChanges(false)
		setErrorMsg(null)
		setTimeout(() => {
			setReload(false)
		}, 100)
	}, [resourceData])

	if (reload) {
		return null
	}

	return (
		<ContentContainer>
			{panelView === "main" && (
				<>
					{loading ? (
						<CbLoading loadingText="Saving resource..." padding="30px" />
					) : (
						<>
							<Form
								size="medium"
								autoComplete="off"
								onFinish={handleResourceUpdate}
								initialValues={{
									title: resourceData.title,
									description: resourceData.description,
									url: resourceData.url,
									user_groups: resourceData.access_groups
								}}
								layout="vertical"
								onValuesChange={() => setShowSaveChanges(true)}
							>
								<Form.Item
									label={<FormLabel>Title</FormLabel>}
									name="title"
									rules={[
										{
											required: true,
											message: "You need to add a resource title"
										}
									]}
									tooltip="This is what your learner will see when they open your course"
								>
									<Input placeholder="Enter resource title..." />
								</Form.Item>
								<Form.Item
									label={<FormLabel>Description</FormLabel>}
									name="description"
									rules={[
										{
											required: true,
											message: "You need to add a resource description"
										}
									]}
									tooltip="Add a short description to help explain what your resource is"
								>
									<TextArea placeholder="Enter a short description of the resource..." rows={2} />
								</Form.Item>
								<Form.Item
									label={<FormLabel>Url</FormLabel>}
									name="url"
									validateTrigger="onBlur"
									rules={[
										{
											message: "You need to add a valid url",
											validator: (_, value) => {
												if (
													!/[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi.test(
														value.trim()
													)
												) {
													return Promise.reject()
												} else {
													return Promise.resolve()
												}
											}
										}
									]}
									tooltip="This is what your learner will see when they open your course"
								>
									<Input placeholder="Enter resource url..." />
								</Form.Item>
								<UserGroupsSelector
									tooltip="These are the groups that can access your resource"
									labelFontSize="16px !important"
									required
								/>
								<Divider height="10px" />
								<LpImageInput
									id="resource-image-upload"
									initialImageUrl={image}
									labelText="Resource icon"
									uploadImageMessage=""
									thumbPreviewShape="round"
									buttonText="Upload a new image"
									thumbSize={"50px"}
									clickableImage={true}
									uploadButtonAsText={true}
									setNewImage={(imageUrl) => setImage(imageUrl)}
									labelFontSize="16px !important"
								/>
								{errorMsg && (
									<>
										<Divider />
										<Alert message={errorMsg} type="error" />
									</>
								)}
								<Divider borderOnBottom={true} />
								<Form.Item>
									<ButtonContainer>
										<Space>
											<Button shape="round" onClick={closemodal}>
												Cancel
											</Button>
											<Button
												type="primary"
												htmlType="submit"
												shape="round"
												block
												disabled={!showSaveChanges}
											>
												Save changes
											</Button>
										</Space>
									</ButtonContainer>
								</Form.Item>
							</Form>
							<ArchiveGroupText onClick={() => setPanelView("delete")}>
								Need to delete this resource?
							</ArchiveGroupText>
						</>
					)}
				</>
			)}

			{panelView === "delete" && (
				<>
					{loading ? (
						<>
							<CbLoading loadingText="Deleting..." padding="30px" />
						</>
					) : (
						<>
							{errorMsg ? (
								<Alert message={errorMsg} type="error" />
							) : (
								<Alert
									message="WARNING: you cannot undo this action, proceed with caution!"
									type="warning"
								/>
							)}
							<Divider height={"28px"} />
							<Space>
								<Button shape="round" onClick={() => setPanelView("main")}>
									Cancel
								</Button>
								<Button type="primary" shape="round" onClick={handleDeleteResource}>
									Confirm delete
								</Button>
							</Space>
						</>
					)}
				</>
			)}
		</ContentContainer>
	)
}

EditResource.propTypes = {
	orgId: PropTypes.string.isRequired,
	resourceData: PropTypes.shape({
		resource_id: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		image_url: PropTypes.string.isRequired
	}).isRequired,
	updateResourceItem: PropTypes.func.isRequired,
	deleteResourceItem: PropTypes.func.isRequired,
	closemodal: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	updateResourceItem: (orgId, resourceId, newResourceInfo) =>
		dispatch(updateResourceItem(orgId, resourceId, newResourceInfo)),
	deleteResourceItem: (orgId, resourceId) => dispatch(deleteResourceItem(orgId, resourceId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditResource)
