import React, { useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import * as QueryString from "query-string"
import PropTypes from "prop-types"
//import CbNav from '../../components/courseBuilder/CbNav';
import CbStageTopNav from "../../components/courseBuilder/stage/topnav/CbStageTopNav"
import CbStageDragDropBase from "../../components/courseBuilder/stage/CbStageDragDropBase"
import CbLoading from "../../components/common/CbLoading"
import { setStageData, setPageParams, clearStagePageData } from "../../redux/courses/courses.actions"
import { selectStage, selectObjectives } from "../../redux/courses/courses.selectors"

const Layout = styled.div`
	border: 1px solid white;
	height: 100vh;
	position: relative;
`
const Content = styled.div`
	border: 1px hidden white;
	margin-top: 44px;
	height: calc(100vh - 44px);
	overflow: auto;
`
// this container is designed to handle both mobile and desktop views
const Container = styled.div`
	@media (min-width: 425px) {
		width: 100%;
	}
	@media (min-width: 768px) {
		-webkit-box-align: center;
		align-items: center;
		flex-direction: column;
		-webkit-box-pack: start;
		justify-content: flex-start;
		width: 100%;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 0px;
		box-sizing: border-box;
		display: flex;
	}
`

const StagePage = ({
	match: {
		params: { org_id, course_id, stage_id }
	},
	location: { query },
	setStageData,
	setPageParams,
	clearStagePageData,
	objectives,
	stage
}) => {
	useEffect(() => {
		setStageData(stage_id, course_id, org_id)

		const urlParams = QueryString.parse(query)
		const lessonViewFromUrl = urlParams.view && urlParams.view === "questions" ? false : true // default case is true, i.e. normally get lesson view
		setPageParams({ lesson_view: lessonViewFromUrl })

		// cleanup
		return () => {
			clearStagePageData()
		}
	}, [course_id, stage_id, org_id, query, setStageData, setPageParams, clearStagePageData])

	// TODO: you should really find a better way of handling ui loading than this!!
	if (!(objectives && stage && stage.stage_id && stage.objective_id && stage.info && stage.stage_columns))
		return <CbLoading fullpage={true} />

	return (
		<Layout>
			<CbStageTopNav
				title={stage.info.name}
				page="stage"
				backLink={`/org/${org_id}/create/course/${course_id}`}
			/>
			<Container>
				<Content>
					<CbStageDragDropBase />
				</Content>
			</Container>
		</Layout>
	)
}

const mapStateToProps = createStructuredSelector({
	objectives: selectObjectives,
	stage: selectStage
})

const mapDispatchToProps = (dispatch) => ({
	setStageData: (stage_id, course_id, org_id) => dispatch(setStageData(stage_id, course_id, org_id)),
	setPageParams: (paramsObj) => dispatch(setPageParams(paramsObj)),
	clearStagePageData: () => dispatch(clearStagePageData())
})

StagePage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			org_id: PropTypes.string.isRequired,
			course_id: PropTypes.string.isRequired,
			stage_id: PropTypes.string.isRequired
		}).isRequired
	}),
	location: PropTypes.shape({
		query: PropTypes.string
	}).isRequired,
	setStageData: PropTypes.func.isRequired,
	setPageParams: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(StagePage)
