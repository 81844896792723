import React, { useState } from "react"
import styled from "styled-components"

const SliderContainer = styled.div`
	position: relative;
	border-radius: 20px;
	height: 40px;
	border: solid 1px var(--light-grey);
	padding: 3px;
`

const SliderInner = styled.div`
	width: 50%;
	height: 100%;
	float: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const SliderInnerText = styled.div`
	width: 100%;
	text-align: center;
	font-size: 13px;
	text-transform: uppercase;
`

const Slider = styled.button`
	width: 50%;
	color: white;
	font-size: 13px;
	text-transform: uppercase;
	height: calc(100% - 6px);
	border-radius: 20px;
	background: var(--secondary-color);
	position: absolute;
	left: ${(props) => (props.sliderSetting === "left" ? "3px" : "calc(50% - 3px)")};
	transition: left 0.4s ease;
	font-weight: 600;
`

const SliderButton = ({ defaultValue, valueLeft, valueRight, onSliderChange }) => {
	const [sliderSetting, setSliderSetting] = useState(defaultValue)

	const toggleSlider = (e) => {
		if (sliderSetting === "left") {
			setSliderSetting("right")
			onSliderChange(valueRight)
		} else {
			setSliderSetting("left")
			onSliderChange(valueLeft)
		}
	}

	return (
		<SliderContainer onClick={toggleSlider}>
			<SliderInner>
				<SliderInnerText>{valueLeft}</SliderInnerText>
			</SliderInner>
			<SliderInner>
				<SliderInnerText>{valueRight}</SliderInnerText>
			</SliderInner>
			<Slider sliderSetting={sliderSetting}>
				{sliderSetting === "right" ? (
					<React.Fragment>{valueRight}</React.Fragment>
				) : (
					<React.Fragment>{valueLeft}</React.Fragment>
				)}
			</Slider>
		</SliderContainer>
	)
}

export default SliderButton
