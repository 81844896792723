import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"

import { selectOrgInfo } from "../../../../../../../redux/org/org.selectors"

const Image = styled.img`
	position: absolute;
	border-radius: 9999px;
	z-index: 10;
`

const size = 0.13
const offset = 0.026

const Logo = ({ logoPlacement, width, orgInfo }) => {
	const position = `${offset * width}px`

	return (
		<>
			{logoPlacement === "topLeft" && (
				<Image
					src={orgInfo.logo_image_url}
					style={{
						width: size * width,
						height: size * width,
						top: position,
						left: position
					}}
				/>
			)}
			{logoPlacement === "topRight" && (
				<Image
					src={orgInfo.logo_image_url}
					style={{
						width: size * width,
						height: size * width,
						top: position,
						right: position
					}}
				/>
			)}
			{logoPlacement === "bottomLeft" && (
				<Image
					src={orgInfo.logo_image_url}
					style={{
						width: size * width,
						height: size * width,
						bottom: position,
						left: position
					}}
				/>
			)}
			{logoPlacement === "bottomRight" && (
				<Image
					src={orgInfo.logo_image_url}
					style={{
						width: size * width,
						height: size * width,
						bottom: position,
						right: position
					}}
				/>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgInfo: selectOrgInfo
})

export default connect(mapStateToProps)(Logo)
