import React from "react"
import { Row } from "react-table"
import * as S from "./Table.styles"

interface Props<Item extends object> {
	prepareRow: (row: Row<Item>) => void
	row: Row<Item>
	style?: any
}

const Cell = "Cell"

export default function TableRow<Item extends object>({ row, prepareRow, style }: Props<Item>) {
	prepareRow(row)

	return (
		<S.TbodyTr {...row.getRowProps({ style })}>
			{row.cells.map((cell) => {
				return <S.Td {...cell.getCellProps()}>{cell.render(Cell)}</S.Td>
			})}
		</S.TbodyTr>
	)
}
