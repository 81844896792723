import React from "react"

import BasicTable from "../../common/BasicTable"

const CourseSummaryTable = ({ orgCourseCards, queryData }) => {
	const tableData = []
	queryData.forEach((row) => {
		const courseInfo = orgCourseCards[row.courseId]
		if (courseInfo && courseInfo.info && !courseInfo.info.user_groups.access_groups.includes("archived")) {
			tableData.push({
				courseId: row.courseId,
				courseName: courseInfo.info.name,
				courseImg: courseInfo.info.image,
				numStarted: row.numStarted,
				numCompleted: row.numCompleted,
				baselineAccuracy: row.baselineAccuracy ? row.baselineAccuracy : "--",
				endlineAccuracy: row.endlineAccuracy ? row.endlineAccuracy : "--",
				improvement: row.baselineAccuracy
					? Math.round(((row.endlineAccuracy - row.baselineAccuracy) * 100) / row.baselineAccuracy)
					: "--"
			})
		}
	})

	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "Course name",
						accessor: "courseName"
					},
					{
						Header: "Num started",
						accessor: "numStarted"
					},
					{
						Header: "Num completed",
						accessor: "numCompleted"
					},
					{
						Header: "Baseline accuracy",
						accessor: "baselineAccuracy"
					},
					{
						Header: "Endline accuracy",
						accessor: "endlineAccuracy"
					},
					{
						Header: "% improvement",
						accessor: "improvement"
					}
				]}
				data={tableData}
			/>
		</div>
	)
}

export default CourseSummaryTable
