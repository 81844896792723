import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import * as S from "../../../../styles/PageStyles"
import { selectAnalyticsTableData } from "../../../../../redux/management/management.selectors"
import useUrlQueryString from "../../../../hooks/useUrlQueryString"
import { SequenceUser } from "learnink-common/lib/domains/userManagement/users/User.model"
import { userTableColumns } from "../../../../management/userTable/UserTable.model"

import UserGroupSummaryTable from "./UserGroupSummaryTable"
import SequenceLatestActivity from "./SequenceLatestActivity"
import SequenceJourneyStages from "./SequenceJourneyStages"
import UserTableWrapper from "../../../../management/userTable/UserTableWrapper"

import { SelectColumnFilter } from "../../../../tables/tableFunctions"

const TableFilter = ({ sequenceInfo, courseCards, userAnalyticsTable }) => {
	const [userGroups] = useUrlQueryString("userGroups")
	let userGroupsArr = userGroups ? userGroups.split(",") : []

	let data = userAnalyticsTable
	if (userGroupsArr.length > 0) {
		data = data
			.filter((x) => x["userGroups"] !== null)
			.filter((x) => userGroupsArr.filter((value) => x["userGroups"].includes(value)).length > 0)
	}

	data.forEach((row) => {
		const courseIds = sequenceInfo.content.filter((item) => item.type === "course").map((item) => item.targetId)
		for (var j = 0; j < courseIds.length; j++) {
			row[courseIds[j]] =
				row.status === "complete"
					? "complete"
					: row.coursesCompleted && row.coursesCompleted.includes(courseIds[j])
					? "complete"
					: "incomplete"
		}
	})

	let sequenceStages = [
		{
			name: "Sequence started",
			count: data.length
		}
	]
	let courseColumns = []
	for (var x = 0; x < sequenceInfo.content.length; x++) {
		const courseId = sequenceInfo.content[x].targetId
		const numCompleted = data.filter(
			(item) => item.coursesCompleted !== null && item.coursesCompleted.includes(courseId)
		).length
		sequenceStages.push({
			name: courseCards[courseId]?.info?.name,
			count: numCompleted
		})
		courseColumns.push({
			Header: courseCards[courseId]?.info?.name,
			accessor: courseId,
			Filter: SelectColumnFilter,
			filter: "equals"
		})
	}

	return (
		<>
			<div className="row" style={{ padding: 15, paddingTop: 10 }}>
				<h1 style={{ fontSize: 28, fontWeight: 600, paddingLeft: 4 }}>{sequenceInfo.internalName}</h1>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<SequenceLatestActivity data={data} />
				</div>
				<div className="col-sm-8">
					<SequenceJourneyStages
						chartData={sequenceStages}
						courseHasData={data.length > 0}
						dataMax={0}
						title="Sequence courses completed"
						tooltip={
							<span>
								Shows the number of users (who were added to the sequence in the selected timeframe) who
								have completed each of the courses
							</span>
						}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<UserGroupSummaryTable userGroupsArr={userGroupsArr} data={data} />
					<S.Card>
						<S.CardBody>
							<UserTableWrapper
								data={data}
								columns={[
									...userTableColumns.filter((item) =>
										[...Object.keys(SequenceUser.shape), "userGroupNames", "status"].includes(
											item.id
										)
									),
									...courseColumns
								]}
								actions={["editUserGroups", "notify", "export"]}
								notifyButtonOn
								hiddenColumns={[
									"firstName",
									"lastName",
									"startedAt",
									"phoneNumber",
									"customId",
									"country",
									"region",
									"ageBracket",
									"sex",
									"lastActive",
									"registeredAt",
									"shareRequestSenders",
									...courseColumns.map((item) => item.accessor)
								]}
								sidePanel="userProfile"
							/>
						</S.CardBody>
					</S.Card>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	userAnalyticsTable: selectAnalyticsTableData
})

export default connect(mapStateToProps)(TableFilter)
