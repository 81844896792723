import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { UUID } from "learnink-common/lib/types/types"

import { selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { CourseCards } from "../../../util/model/OrgSelectors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Tooltip } from "antd"
import { UnlockStatus } from "../../../pages/learningPaths/utils/LearningPathContent"

const ModuleContainer = styled.div<{ isArchived: boolean }>`
	height: 50px;
	border-radius: 40px;
	border-style: solid;
	border-width: 2px;
	opacity: ${({ isArchived }) => (isArchived ? "0.5" : "1")};
	position: relative;
`
const ModuleInner = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding: 0 7px 0 7px;
	position: relative;
`
const ModuleImage = styled.img`
	resize-mode: cover;
	width: 33px;
	height: 33px;
	border-radius: 999px;
	overflow: hidden;
	border: solid 2px #cee3ef;
`
const LockIcon = styled.div`
	width: 33px;
	height: 33px;
	border-radius: 999px;
	border: solid 2px #cee3ef;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
`
const ModuleTitle = styled.div`
	flex: 1;
	padding: 0 10px 0 10px;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
`
const ModuleBubbleRow = styled.div`
	position: absolute;
	width: 100%;
	height: 20px;
	display: flex;
	justify-content: space-between;
	margin-top: -10px;
`
const ModuleBubble = styled.div`
	background-color: white;
	border: solid 2px #cee3ef;
	border-radius: 9999px;
	height: 18px;
	width: 18px;
	font-size: 9px;
	text-align: center;
	color: #cee3ef;
`
const CloseButton = styled.div`
	opacity: 0;
	width: 18px;
	height: 18px;
	text-align: center;
	border-radius: 999px;
	background: var(--dark-red);
	color: white;
	position: absolute;
	top: -5px;
	right: -5px;
	font-size: 11px;
	transition: 0.5s ease;
	cursor: pointer;
	${ModuleContainer}:hover & {
		opacity: 1;
	}
`

interface Props {
	courseId: UUID
	courseCards: CourseCards
	deleteCourse?: (courseIndex: number, sequenceIndex: number) => void
	courseIndex?: number
	sequenceIndex?: number
	margin?: number
	showModuleBubbles?: boolean
	status: UnlockStatus
	unlockDay?: number
}

const colors = {
	active: {
		buttonBackground: "white",
		buttonBorder: "#A2D700",
		buttonTitle: "#565656"
	},
	unlocked: {
		buttonBackground: "white",
		buttonBorder: "#cee3ef",
		buttonTitle: "#565656"
	},
	locked: {
		buttonBackground: "#dbedf5",
		buttonBorder: "#c5d9e6",
		buttonTitle: "#737373"
	},
	time_locked: {
		buttonBackground: "#dbedf5",
		buttonBorder: "#c5d9e6",
		buttonTitle: "#737373"
	}
}

const CourseCard = ({
	courseId,
	courseCards,
	deleteCourse,
	courseIndex,
	sequenceIndex,
	margin,
	showModuleBubbles,
	status,
	unlockDay
}: Props) => {
	return (
		<ModuleContainer
			isArchived={courseCards[courseId].info.user_groups.access_groups.includes("archived")}
			style={{
				margin: margin || 0,
				backgroundColor: colors[status].buttonBackground,
				borderColor: colors[status].buttonBorder
			}}
		>
			<ModuleInner>
				{deleteCourse && (
					// @ts-ignore
					<CloseButton onClick={() => deleteCourse(courseIndex, sequenceIndex)}>x</CloseButton>
				)}
				{["active", "unlocked", "complete"].includes(status) ? (
					<ModuleImage src={courseCards[courseId].info.image} />
				) : (
					<LockIcon>
						<FontAwesomeIcon icon={faLock as IconProp} color="#b9ccdb" />
					</LockIcon>
				)}
				<ModuleTitle style={{ color: colors[status].buttonTitle }}>
					{courseCards[courseId].info.name}
				</ModuleTitle>
				{["locked", "time_locked"].includes(status) && (
					<Tooltip
						title={
							status === "time_locked" && unlockDay
								? `Unlocked on day ${unlockDay}`
								: "Complete previous to unlock"
						}
						placement="bottom"
						color="#a0b2c0"
					>
						<div style={{ width: "100%", position: "absolute", height: 37, top: 0, left: 0 }}></div>
					</Tooltip>
				)}
			</ModuleInner>
			<ModuleBubbleRow>
				<p></p>
				{showModuleBubbles &&
					courseCards[courseId].moduleStageIds.map(() => (
						<ModuleBubble>
							<FontAwesomeIcon icon={faCheck as IconProp} />
						</ModuleBubble>
					))}
			</ModuleBubbleRow>
		</ModuleContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	courseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(CourseCard)
