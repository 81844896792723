// FORM TO ADD NEW RESOURCE
import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Form, Input, Alert } from "antd"

import { selectOrgId } from "../../redux/org/org.selectors"
import { createResourceItem } from "../../redux/resources/resources.actions"
import * as S from "../../components/styles/PageStyles"

import LpBackButton from "../../components/common/LpBackButton"
import LpImageInput from "../../components/common/LpImageInput"
import CbLoading from "../../components/common/CbLoading"
import UserGroupsSelector from "../../components/common/UserGroupsSelector"

const { TextArea } = Input

const LoadingContainer = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`
const FormLabel = styled.label`
	color: black;
	font-size: 18px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`

const AddResource = ({ orgId, createResourceItem }) => {
	const [image, setImage] = useState("https://farminkweb.s3.amazonaws.com/89f35e44-5508-4144-bde0-125704a0cc03.png")
	const [dummyLoading, setDummyLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const handleSubmit = async (values) => {
		try {
			const { title, description, url, user_groups } = values
			setDummyLoading(true)
			setErrorMsg(null)

			const newResourceInfo = {
				type: "default",
				title: title.trim(),
				description: description.trim(),
				image_url: image,
				url: url.trim(),
				user_groups: {
					view_groups: user_groups,
					access_groups: user_groups
				}
			}
			await createResourceItem(orgId, newResourceInfo)

			setTimeout(() => {
				setDummyLoading(false)
				setRedirect(true)
			}, 5000)
		} catch (err) {
			setTimeout(() => {
				setErrorMsg("Sorry we couldn't create this resource, please try again")
				setDummyLoading(false)
			}, 2000)
		}
	}

	if (redirect) return <Redirect to={`/org/${orgId}/create/resources`} />

	if (dummyLoading === true)
		return (
			<LoadingContainer>
				<CbLoading loadingText="Creating resource..." padding="30px" />
			</LoadingContainer>
		)

	return (
		<S.PageContainer>
			<S.BackContainer>
				<LpBackButton backlink={`/org/${orgId}/create/resources`} />
			</S.BackContainer>

			<S.FormContainer>
				<S.FormTitle>Add resource</S.FormTitle>

				<Form
					size="large"
					autoComplete="off"
					onFinish={handleSubmit}
					initialValues={{ user_groups: ["registered"] }}
					layout="vertical"
				>
					<Form.Item
						label={<FormLabel>Title</FormLabel>}
						name="title"
						rules={[{ required: true, message: "You need to add a resource title" }]}
						tooltip="This is what your learner will see when they open your course"
					>
						<Input placeholder="Enter resource title..." />
					</Form.Item>
					<Form.Item
						label={<FormLabel>Description</FormLabel>}
						name="description"
						rules={[
							{
								required: true,
								message: "You need to add a resource description"
							}
						]}
						tooltip="Add a short description to help explain what your resource is"
					>
						<TextArea placeholder="Enter a short description of the resource..." rows={2} />
					</Form.Item>
					<Form.Item
						label={<FormLabel>Url</FormLabel>}
						name="url"
						validateTrigger="onBlur"
						rules={[
							{
								message: "You need to add a valid url",
								validator: (_, value) => {
									if (
										!/[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi.test(
											value.trim()
										) // FIX THIS - the trim is throwing an issue - Cannot read properties of undefined (reading 'trim')
									) {
										return Promise.reject()
									} else {
										return Promise.resolve()
									}
								}
							}
						]}
						tooltip="This is what your learner will see when they open your course"
					>
						<Input placeholder="Enter resource url..." />
					</Form.Item>
					<UserGroupsSelector tooltip="These are the groups that can access your resource" required />
					<Divider height="10px" />
					<LpImageInput
						id="resource-image-upload"
						labelText="Image"
						uploadImageMessage="Upload an image for your resource (optional)"
						thumbPreviewShape="square"
						setNewImage={(imageUrl) => setImage(imageUrl)}
					/>
					{errorMsg && (
						<>
							<Divider />
							<Alert message={errorMsg} type="error" />
						</>
					)}
					<Divider borderOnBottom={true} />
					<Form.Item>
						<ButtonContainer>
							<Button type="primary" htmlType="submit" size="large" shape="round" block>
								Add new resource
							</Button>
						</ButtonContainer>
					</Form.Item>
				</Form>
			</S.FormContainer>
		</S.PageContainer>
	)
}

AddResource.propTypes = {
	orgId: PropTypes.string.isRequired,
	createResourceItem: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	createResourceItem: (orgId, newResourceInfo) => dispatch(createResourceItem(orgId, newResourceInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddResource)
