import { UserActionTypes } from "./user.types"
import { signOut } from "../../firebase/firebase.util"
import { setOrgSubscription } from "../subscription/subscription.actions"
import axios from "axios"
import posthog from "posthog-js"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const setFirebaseAuth = (authData) => ({
	type: UserActionTypes.SET_USER_FIREBASE_AUTH,
	payload: authData
})

export const setUserInfo = (userInfo) => (dispatch) => {
	dispatch({
		type: UserActionTypes.SET_USER_INFO,
		payload: {
			...userInfo
		}
	})
}

export const setUserSessionReady = (isReady) => {
	if (isReady === true)
		return (dispatch) =>
			dispatch({
				type: UserActionTypes.SET_USER_SESSION_READY
			})
	else
		return (dispatch) =>
			dispatch({
				type: UserActionTypes.SET_USER_SESSION_LOADING
			})
}

export const setUserSessionError = (error) => ({
	type: UserActionTypes.SET_USER_SESSION_ERROR,
	payload: error
})

export const clearUserSessionError = () => ({
	type: UserActionTypes.CLEAR_USER_SESSION_ERROR
})

export const appSignOut = () => {
	return (dispatch) => {
		signOut().then(() => {
			dispatch(clearUserSessionError())
			window.Intercom("shutdown")
			posthog.reset()
		})
	}
}

export const checkOrgUrlAvailable = (org_url) => {
	// we just see if the org exists
	updateFirebaseToken()
	axios
		.post(`/api/org/url-available-check`, {
			user_asked_for_id: org_url
		})
		.then((res) => {
			return res
		})
		.catch((err) => {
			return err
		})
}

export const userCreateNewOrg = (new_org_name, new_org_id) => (dispatch) => {
	updateFirebaseToken()
	axios
		.post(`/api/org/create`, {
			new_org_name: new_org_name,
			new_org_id: new_org_id
		})
		.then((res) => {
			// 1, set a new org payment subscription for org
			dispatch(setOrgSubscription(new_org_id))
			// 3. assign the user the roles for their new org
			dispatch(setNewOrgRoleData(res.data.newRoleData))
		})
		.catch((err) => {
			console.log(err)
		})
}

export const setNewOrgRoleData = (newRoleData) => ({
	type: UserActionTypes.SET_NEW_ORG_INFO,
	payload: {
		org_id: newRoleData.org_id,
		new_role_object: {
			role: newRoleData.role,
			org_name: newRoleData.org_name,
			feature_access: newRoleData.feature_access
		}
	}
})

export const updateBasicUserInfo = (newBasicUserInfo) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/user/basic-info`, {
			newBasicUserInfo: newBasicUserInfo
		})
		if (res.data && res.data.info) {
			dispatch({
				type: UserActionTypes.SET_BASIC_USER_INFO,
				payload: res.data.info
			})
			return res.data.info
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		throw err
	}
}

export const addTeamMembers = (invitations, org_id) => (dispatch) => {
	updateFirebaseToken()
	axios
		.post(`/api/user/add-team-members`, {
			invitations: invitations,
			org_id: org_id
		})
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.log(err)
		})
}
