import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
const ReactClickCopy = styled.form`
	display: flex;
	position: relative;
`
const TextInput = styled.input`
	width: 100%;
	height: auto;
	resize: none;
	border: none;
	font-size: 16px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: ${({ textAlign }) => textAlign};
	margin: 2px 8px;
`
const ButtonDefault = styled.button`
	flex: 7;
	background: white;
	border-radius: ${({ borderRadius, withLinkButton }) => {
		if (withLinkButton === false) return `${borderRadius}`
		else return `${borderRadius} 0px 0px ${borderRadius}`
	}};
	border: 1px solid ${({ color }) => color};
	color: #42a5f5;
	font-size: 16px;
	cursor: pointer;
	padding: 5px;
`
const LinkButton = styled.a`
	flex: 1 40px;
	background: white;
	border-radius: 0px 32px 32px 0px;
	background-color: ${({ color }) => color};
	border-radius: ${({ borderRadius }) => `0px ${borderRadius} ${borderRadius} 0px`};
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	padding-right: 8px;
`
const PopoverBgOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`
const Popover = styled.div`
	position: absolute;
	overflow: visible;
	top: -8px;
	left: 50%;
	transform: translate(-50%, -100%);
	background: white;
	border-radius: 12px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
	font-size: 14px;
	text-align: center;
	margin-right: 30px;
	padding: 1em;
	&::before {
		content: "";
		width: 8px;
		height: 8px;
		position: absolute;
		bottom: -4px;
		left: calc(50% - 5px);
		background: white;
		transform: rotate(45deg);
		z-index: -1;
	}
`
const MessageText = styled.p`
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	margin-top: 6px;
`

const CbLinkCopyButton = ({
	contentToCopy,
	targetUrl,
	showHelperMsg,
	color,
	borderRadius,
	onCopy,
	render,
	withLinkButton,
	textAlign
}) => {
	const [popoverVisible, setPopoverVisible] = useState(false)
	const [popoverTimeout, setPopoverTimeout] = useState(null)
	const inputRef = React.useRef()

	const copyToClipboard = (e) => {
		inputRef.current.select()
		document.execCommand("copy")
		if (onCopy) {
			onCopy()
		} else {
			togglePopover()
		}
	}

	const togglePopover = () => {
		if (popoverVisible) {
			if (popoverTimeout) {
				setPopoverTimeout(null)
			}
			setPopoverVisible(false)
		} else {
			setPopoverVisible(true)
			setPopoverTimeout(() => {
				setTimeout(() => {
					setPopoverVisible(false)
				}, 2000)
			})
		}
	}

	return (
		<Container>
			<ReactClickCopy>
				<Popover
					style={{
						display: popoverVisible ? "block" : "none"
					}}
				>
					Copied to Clipboard
				</Popover>
				<PopoverBgOverlay
					style={{
						visibility: popoverVisible ? "visible" : "hidden"
					}}
					onClick={() => togglePopover()}
				/>

				{render ? (
					render({ copy: copyToClipboard })
				) : (
					<React.Fragment>
						<ButtonDefault
							type="button"
							onClick={copyToClipboard}
							color={color}
							borderRadius={borderRadius}
							withLinkButton={withLinkButton}
						>
							<TextInput ref={inputRef} value={contentToCopy} textAlign={textAlign} readOnly />
						</ButtonDefault>
						{withLinkButton && (
							<LinkButton href={targetUrl} target="_blank" color={color} borderRadius={borderRadius}>
								View
							</LinkButton>
						)}
					</React.Fragment>
				)}
			</ReactClickCopy>
			{showHelperMsg && (
				<MessageText>
					<span role="img" aria-label="hand">
						☝🏾
					</span>{" "}
					Click text to copy
				</MessageText>
			)}
		</Container>
	)
}

CbLinkCopyButton.propTypes = {
	contentToCopy: PropTypes.string.isRequired,
	targetUrl: PropTypes.string,
	showHelperMsg: PropTypes.bool,
	color: PropTypes.string,
	borderRadius: PropTypes.string,
	withLinkButton: PropTypes.bool,
	textAlign: PropTypes.string
}

CbLinkCopyButton.defaultProps = {
	showHelperMsg: true,
	color: "var(--secondary-color)",
	borderRadius: "32px",
	withLinkButton: true,
	targetUrl: "",
	textAlign: "left"
}

export default CbLinkCopyButton
