import styled from "styled-components"

export interface UserSearchStatsProps {
	userCount: number
	allUsersSelected: boolean
	selectedUserIds: string[]
	unselectedUserIds: string[]
}

interface EmphasisProps {
	noneSelected: boolean
}

type SelectedState = "ALL" | "SOME" | "NONE"

const S = {
	Container: styled.div`
		margin-top: 15px;
	`,
	CountBubble: styled.span`
		border-radius: 16px;
		padding: 4px 8px;
		background-color: var(--secondary-color);
		color: white;
	  	margin-right: 5px;
	`,
	Emphasis: styled.span<EmphasisProps>`
		color: ${(props) => (props.noneSelected ? "black" : "var(--secondary-color)")};
		font-weight: bold;
	`
}

export default function UserSearchStats({
	userCount,
	allUsersSelected,
	selectedUserIds,
	unselectedUserIds
}: UserSearchStatsProps) {
	const someUsersSelected = allUsersSelected || selectedUserIds.length > 0

	const usersSelectedDisplayMap: { [key in SelectedState]: number } = {
		ALL: userCount - unselectedUserIds.length,
		SOME: selectedUserIds.length,
		NONE: userCount
	}

	const selectedState: SelectedState = allUsersSelected ? "ALL" : someUsersSelected ? "SOME" : "NONE"
	const usersSelectedDisplay = usersSelectedDisplayMap[selectedState]

	const pluralisedUsers = `user${usersSelectedDisplay !== 1 ? "s" : ""}`
	const userCountText = (
		<>
			<S.CountBubble>{usersSelectedDisplay}</S.CountBubble> {pluralisedUsers}{" "}
			{someUsersSelected ? "selected" : "showing"}
		</>
	)

	return (
		<S.Container>
			<S.Emphasis noneSelected={selectedState === "NONE"}>{userCountText}</S.Emphasis>
		</S.Container>
	)
}
