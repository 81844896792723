import React from "react"
import styled from "styled-components"
import EditableTextBox from "../common/EditableTextBox"
import { NumInputSurveyCard, TextInputSurveyCard } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"

const McSurveyContainer = styled.div`
	display: flex;
	flex-direction: column;
`
const ExtraTextContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px;
	font-size: 14px;
	font-weight: 500;
	color: var(--mid-grey);
`

interface Props {
	card: TextInputSurveyCard | NumInputSurveyCard
	updateLessonCard: (updateParams: TextInputSurveyCard | NumInputSurveyCard) => void
}

const SurveyCard = ({ card, updateLessonCard }: Props) => {
	const title = card.type === "textInputSurvey" ? "free text" : "numerical"
	return (
		<McSurveyContainer>
			<EditableTextBox
				type={card.type}
				from={card.from}
				initialText={card.message || ""}
				setNewText={(val: string) => updateLessonCard({ ...card, message: val })}
				placeholderText="Enter text..."
				enableEmojiPicker
			/>
			<ExtraTextContainer>
				user can enter <span style={{ fontWeight: "bold", padding: "0px 4px" }}>{title}</span> answer
			</ExtraTextContainer>
		</McSurveyContainer>
	)
}

export default SurveyCard
