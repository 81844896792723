import React from "react"
import { Link } from "react-router-dom"
import { Button, Space, Typography } from "antd"
import { BarChartOutlined, ShareAltOutlined } from "@ant-design/icons"

import * as S from "../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import HelpVideoButton from "../common/HelpVideoButton"
import { ModalTypes } from "../../pages/learningPaths/LearningPathBuilder"
import CbBackButton from "../common/CbBackButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const { Title } = Typography

interface NavProps {
	orgId: string
	learningPath: LearningPath
	unsavedChanges: boolean
	learningPathWarnings: string[]
	handleUndoButton: () => void
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
}

const Nav = ({
	orgId,
	learningPath,
	unsavedChanges,
	learningPathWarnings,
	handleUndoButton,
	setModalType
}: NavProps) => {
	return (
		<S.NavContainer>
			{unsavedChanges ? (
				<a>
					<FontAwesomeIcon onClick={() => setModalType("unsavedChanges")} icon={faArrowLeft as IconProp} />
				</a>
			) : (
				<CbBackButton />
			)}
			<S.NavLeft>
				<Title level={4} style={{ margin: 0, marginLeft: 10 }}>
					{learningPath.internalName}
				</Title>
			</S.NavLeft>
			<S.NavRight>
				<Space>
					{!unsavedChanges && (
						<>
							<Button shape="round" icon={<ShareAltOutlined />} onClick={() => setModalType("share")}>
								Share
							</Button>
							<Link to={`/org/${orgId}/analyse/learning-paths/progress?id=${learningPath.id}`}>
								<Button shape="round" icon={<BarChartOutlined />}>
									Analyse
								</Button>
							</Link>
						</>
					)}
					{unsavedChanges && (
						<>
							<Button shape="round" onClick={handleUndoButton} disabled={!unsavedChanges}>
								Undo unsaved changes
							</Button>
							<Button
								type="primary"
								shape="round"
								onClick={() => setModalType("save")}
								disabled={learningPathWarnings.length > 0}
							>
								Save
							</Button>
						</>
					)}
					<HelpVideoButton videoId="learning_path_editor" buttonText="Help" />
				</Space>
			</S.NavRight>
		</S.NavContainer>
	)
}

export default Nav
