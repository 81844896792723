import React from "react"
import styled from "styled-components"

const RightContainer = styled.div`
	flex: 1 1 50%;
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	background: #acd2ea;
	@media (max-width: 850px) {
		display: none;
	}
`

const RightContainerInner = styled.div`
	width: 100%;
	position: absolute;
	height: 100%;
`

const PlaneContainer = styled.div`
	width: 200px;
	margin: 0px auto;
	height: 100vh;
	margin-top: calc(50vh - 100px);
	position: relative;
	z-index: 10;
`

const Clouds = styled.div`
	position: absolute;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	transform: translateZ(0);
`

function RightContainerClouds({ sentEmail }) {
	return (
		<RightContainer>
			<RightContainerInner>
				{sentEmail && (
					<PlaneContainer className="plane-container">
						<svg
							version="1.1"
							x="0px"
							y="0px"
							width="1131.53px"
							height="379.304px"
							viewBox="0 0 1131.53 379.304"
							enableBackground="new 0 0 1131.53 379.304"
							className="plane"
						>
							<polygon
								fill="#D8D8D8"
								points="72.008,0 274.113,140.173 274.113,301.804 390.796,221.102 601.682,367.302 1131.53,0.223  "
							/>
							<polygon
								fill="#C4C4C3"
								points="1131.53,0.223 274.113,140.173 274.113,301.804 390.796,221.102   "
							/>
						</svg>
					</PlaneContainer>
				)}
				<Clouds>
					<svg
						version="1.1"
						x="0px"
						y="0px"
						width="762px"
						height="331px"
						viewBox="0 0 762 331"
						className="cloud big front slowest"
					>
						<path
							fill="#FFFFFF"
							d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                        c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                        C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                        S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
						/>
					</svg>
					<svg
						version="1.1"
						x="0px"
						y="0px"
						width="762px"
						height="331px"
						viewBox="0 0 762 331"
						className="cloud distant smaller"
					>
						<path
							fill="#FFFFFF"
							d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                        c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                        C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                        S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
						/>
					</svg>

					<svg
						version="1.1"
						x="0px"
						y="0px"
						width="762px"
						height="331px"
						viewBox="0 0 762 331"
						className="cloud small slow"
					>
						<path
							fill="#FFFFFF"
							d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                        c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                        C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                        S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
						/>
					</svg>

					<svg
						version="1.1"
						x="0px"
						y="0px"
						width="762px"
						height="331px"
						viewBox="0 0 762 331"
						className="cloud distant super-slow massive"
					>
						<path
							fill="#FFFFFF"
							d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                        c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                        C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                        S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
						/>
					</svg>

					<svg
						version="1.1"
						x="0px"
						y="0px"
						width="762px"
						height="331px"
						viewBox="0 0 762 331"
						className="cloud slower"
					>
						<path
							fill="#FFFFFF"
							d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
                        c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
                        C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
                        S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
						/>
					</svg>
				</Clouds>
			</RightContainerInner>
		</RightContainer>
	)
}

export default RightContainerClouds
