// FORM TO CREATE A NEW MODULE STAGE

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import Popup from "reactjs-popup"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Button, Form, Input, Select, Collapse } from "antd"

import { selectObjectives, selectCourseId, selectModuleId } from "../../../../redux/courses/courses.selectors"
import { createNewStage } from "../../../../redux/courses/courses.actions"

import TooltipIcon from "../../../common/TooltipIcon"
import { useApi } from "../../../../util/useApi"
import Routes from "../../../../util/Routes"
import { selectIsUserAdmin } from "../../../../redux/user/user.selectors"
import { selectOrgId } from "../../../../redux/org/org.selectors"

const { TextArea } = Input

const ModalBox = styled.div`
	border-radius: 10px;
	background: white;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 30px 6px 30px;
`
const CloseButton = styled.a`
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: 12px;
	top: 12px;
	font-size: 18px;
	color: var(--dark-grey);
	border-radius: 50%;
	background: none;
	border: 0px solid #cfcece;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

// for first drop down
const initialOptions = [
	{ value: "select", label: "Select an existing objective" },
	{ value: "create", label: "Create new objective" }
]

const CbModalAddStage = ({ course_id, module_id, objectives, createNewStage, isUserAdmin, orgId }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [optionType, setOptionType] = useState(null) // choose base option

	const { setApiRequest: getDefaults, data: defaults } = useApi()

	useEffect(() => {
		getDefaults({ route: Routes.getCreateStageDefaults })
	}, [])

	//for select learning objective dropdown
	const objectiveOptions = Object.keys(objectives).map((key) =>
		Object.assign({ value: key, label: objectives[key].content.text })
	)

	const openModal = () => setIsOpen(true)
	const closeModal = () => {
		setOptionType(null)
		setIsOpen(false)
	}

	const handleConfirmClick = (values) => {
		const { lesson_name, option_type, new_text, objective_id, prompts, model } = values

		const isNewObjective = option_type !== "select"
		const objective_payload = isNewObjective ? { new_text } : { objective_id }

		const file = document.getElementById("stageSourceFile")
		const fileExists = file && file.files && !!file.files[0]

		let stageSourceFile
		if (fileExists) {
			stageSourceFile = file.files[0]
		}

		const resolvedDefaults = (defaults || {})
		const resolvedPrompts = prompts || JSON.stringify(resolvedDefaults.prompts)
		const resolvedModel = (model || resolvedDefaults.models || [undefined])[0]

		createNewStage(course_id, module_id, lesson_name, objective_payload, stageSourceFile, resolvedPrompts, resolvedModel, orgId)
		closeModal()
	}

	return (
		<React.Fragment>
			<Button type="primary" size="large" shape="round" onClick={openModal}>
				Add stage
			</Button>

			<Popup open={isOpen} closeOnDocumentClick onClose={closeModal} className="reactJsModal">
				<ModalBox>
					<CloseButton onClick={() => closeModal()}>
						<FontAwesomeIcon icon={faTimes} color="var(--dark-grey)" />
					</CloseButton>
					<Form
						size="large"
						autoComplete="off"
						onFinish={handleConfirmClick}
						layout="vertical"
						encType="multipart/form-data"
					>
						<Form.Item
							label={<FormLabel>Stage name</FormLabel>}
							name="lesson_name"
							rules={[{ required: true, message: "You can't leave this blank" }]}
						>
							<Input placeholder="My new stage..." />
						</Form.Item>
						<Form.Item
							label={
								<FormLabel>
									Learning objective{" "}
									<TooltipIcon tooltip="A learning objective is a brief, clear, specific statement of what your target learners will be able to do at the end of a stage" />
								</FormLabel>
							}
							name="option_type"
							rules={[{ required: true, message: "You need to select an option" }]}
						>
							<Select
								showArrow={true}
								style={{ width: "100%" }}
								options={initialOptions}
								placeholder="Select option..."
								onChange={(value) => setOptionType(value)}
							/>
						</Form.Item>
						{optionType === "create" && (
							<Form.Item
								name="new_text"
								rules={[{ required: true, message: "You can't leave this blank" }]}
							>
								<TextArea placeholder="Enter your learning objective here..." />
							</Form.Item>
						)}
						{optionType === "select" && (
							<Form.Item
								name="objective_id"
								rules={[
									{
										required: true,
										message: "You need to select a learning objective"
									}
								]}
							>
								<Select
									showArrow={true}
									style={{ width: "100%" }}
									options={objectiveOptions}
									placeholder="Select learning objective..."
								/>
							</Form.Item>
						)}

						{isUserAdmin && <ContentParserOptions defaults={defaults} />}

						<Form.Item>
							<ButtonContainer>
								<Button size="large" shape="round" onClick={() => closeModal()}>
									Cancel
								</Button>
								<div style={{ height: "100%", width: 10 }}></div>
								<Button type="primary" htmlType="submit" size="large" shape="round">
									Create stage
								</Button>
							</ButtonContainer>
						</Form.Item>
					</Form>
				</ModalBox>
			</Popup>
		</React.Fragment>
	)
}

function ContentParserOptions({ defaults }) {
	const modelOptions = ((defaults || []).models || []).map((model) => {
		return { value: model, label: model }
	})

	const hasModelOptions = modelOptions.length > 0
	const hasPrompts = defaults && !!defaults.prompts

	const [selectedModel, setSelectedModel] = useState(hasModelOptions ? modelOptions[0].value : undefined)
	const [currentPrompts, setCurrentPrompts] = useState(
		hasPrompts ? JSON.stringify(defaults.prompts, null, 4) : undefined
	)

	return (
		<>
			<Form.Item
				name="stageSourceFile"
				label={
					<FormLabel>
						{" "}
						Stage content (optional){" "}
						<TooltipIcon tooltip="Our AI will try to turn your own content into a stage automatically!" />
					</FormLabel>
				}
			>
				<Input type="file" />
			</Form.Item>

			<Form.Item>
				<Collapse>
					<Collapse.Panel header="Advanced options" key="1">
						{hasModelOptions && (
							<Form.Item
								label={
									<FormLabel>
										GPT model <TooltipIcon tooltip="Choose from a list of available AI models" />
									</FormLabel>
								}
								name="model"
							>
								<Select
									showArrow
									defaultValue={selectedModel}
									options={modelOptions}
									onChange={setSelectedModel}
								/>
							</Form.Item>
						)}

						{hasPrompts && (
							<Form.Item
								label={
									<FormLabel>
										Prompt JSON{" "}
										<TooltipIcon tooltip="Tweak with the prompts... For now, best to copy this, edit outside and paste back.." />
									</FormLabel>
								}
								name="prompts"
							>
								<TextArea
									size="large"
									defaultValue={currentPrompts}
									onChange={(e) => setCurrentPrompts(e.target.value)}
								/>
							</Form.Item>
						)}
					</Collapse.Panel>
				</Collapse>
			</Form.Item>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	course_id: selectCourseId,
	objectives: selectObjectives,
	module_id: selectModuleId,
	isUserAdmin: selectIsUserAdmin,
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	createNewStage: (course_id, module_id, new_stage_name, objective_payload, stageSourceFile, prompts, model, orgId) =>
		dispatch(
			createNewStage(course_id, module_id, new_stage_name, objective_payload, stageSourceFile, prompts, model, orgId)
		)
})

export default connect(mapStateToProps, mapDispatchToProps)(CbModalAddStage)
