import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import AnalysisNavWithFilters from "../../components/analyse/nav/AnalysisNavWithFilters"
import ActivityDash from "../../components/analyse/dashboards/ActivityDash"
import DemographicsDash from "../../components/analyse/dashboards/DemographicsDash"
import QuestionsDash from "../../components/analyse/dashboards/QuestionsDash"
import SurveysDash from "../../components/analyse/dashboards/SurveysDash"
import CourseJourneyDash from "../../components/analyse/dashboards/CourseJourneyDash"
import CourseSummaryDash from "../../components/analyse/dashboards/CourseSummaryDash"
import SequenceSummaryDash from "../../components/analyse/dashboards/SequenceSummaryDash"
import SequenceJourneyDash from "../../components/analyse/dashboards/SequenceJourneyDash"

import FeatureNoAccess from "../landing/FeatureNoAccess"
import LearningPathDash from "../../components/analyse/dashboards/LearningPathDash"
import LearningPathSummaryDash from "../../components/analyse/dashboards/LearningPathSummaryDash"
import { selectPremiumFeatureAccess } from "../../redux/org/org.selectors"

const DashboardOuter = styled.div`
	width: 100%;
	min-height: 100vh;
	height: 100%;
	background: #f6f9fc;
	@media screen and (max-width: 850px) {
		padding: 20px 10px 40px 10px;
	}
	@media screen and (min-width: 850px) {
		padding: 80px 10px 40px 10px;
	}
`

const AnalysePage = ({ match: { params }, premiumFeatureAccess }) => {
	const [analyticsView, setAnalyticsView] = useState(params.view || "activity")
	const [analyticsSubView, setAnalyticsSubView] = useState(params.subview || null)

	useEffect(() => {
		if (params.view) {
			setAnalyticsView(params.view)
		}
		if (params.subview) {
			setAnalyticsSubView(params.subview)
		} else {
			setAnalyticsSubView(null)
		}
	}, [params.view, params.subview])

	const dashContent = (analyticsView, analyticsSubView) => {
		switch (analyticsView) {
			case "activity":
				return <ActivityDash />
			case "demographics":
				return <DemographicsDash />
			case "sequences":
				if (premiumFeatureAccess.sequences === "locked") {
					return <FeatureNoAccess />
				} else {
					if (analyticsSubView === "sequence") return <SequenceJourneyDash />
					else return <SequenceSummaryDash />
				}
			case "learning-paths":
				if (premiumFeatureAccess.learningPaths === "locked") {
					return <FeatureNoAccess />
				} else {
					if (analyticsSubView) return <LearningPathDash view={analyticsSubView} />
					else return <LearningPathSummaryDash />
				}
			case "courses":
				if (analyticsSubView === "insights") return <CourseJourneyDash />
				else if (analyticsSubView === "questions") return <QuestionsDash />
				else if (analyticsSubView === "surveys") return <SurveysDash type="course" />
				else return <CourseSummaryDash />
			default:
				return <ActivityDash />
		}
	}

	const dashNavSettings = (analyticsView, analyticsSubView) => {
		let baseFilterSettings = {
			showTimeSinceFilter: true,
			showUserGroupsFilter: true,
			showCourseFilter: false
		}
		switch (analyticsView) {
			case "activity":
				return baseFilterSettings
			case "demographics":
				return baseFilterSettings
			case "sequences":
				if (analyticsSubView === "sequence")
					return {
						...baseFilterSettings,
						showTimeSinceFilter: false,
						showTimeStartedFilter: true,
						showSequenceFilter: true
					}
				else
					return {
						...baseFilterSettings,
						showTimeSinceFilter: false,
						showTimeStartedFilter: true
					}
			case "learning-paths":
				return {
					...baseFilterSettings,
					showTimeSinceFilter: false,
					showTimeStartedFilter: true
				}
			case "courses":
				if (analyticsSubView === "insights")
					return {
						...baseFilterSettings,
						showTimeSinceFilter: false,
						showTimeStartedFilter: true,
						showCourseFilter: true
					}
				else if (analyticsSubView === "questions")
					return {
						...baseFilterSettings,
						showCourseFilter: true
					}
				else if (analyticsSubView === "surveys")
					return {
						...baseFilterSettings,
						showCourseFilter: true
					}
				else
					return {
						...baseFilterSettings,
						showTimeSinceFilter: false,
						showTimeStartedFilter: true
					}
			default:
				return baseFilterSettings
		}
	}

	return (
		<>
			<AnalysisNavWithFilters filterSettings={dashNavSettings(analyticsView, analyticsSubView)} />
			<DashboardOuter>{dashContent(analyticsView, analyticsSubView)}</DashboardOuter>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	premiumFeatureAccess: selectPremiumFeatureAccess
})

export default connect(mapStateToProps)(AnalysePage)
