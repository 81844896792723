import React from "react"
import styled from "styled-components"
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar, Cell } from "recharts"

import ChartWrapper from "../../common/ChartWrapper"

const Row = styled.div`
	display: flex;
	width: 100%;
	margin-top: 20px;
	margin-right: 20px;
`
const ValueWrapper = styled.div`
	flex: 1;
	padding: 0px 15px;
`
const ValueContainer = styled.div`
	width: 100%;
	text-align: right;
	font-size: 30px;
	line-height: 38px;
	font-weight: 500;
`
const OuterContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`
const ChartContainer = styled.div`
	flex: 1;
	padding: 0px 15px;
`

const SingleQuestionData = ({ singleQuestionContent, accuracyData, questionData }) => {
	const data = []
	singleQuestionContent.content.answer_data.forEach((element) => {
		data.push({
			answerText: element.answer_text,
			numBaselineAnswers:
				questionData.filter((item) => item.answerId === element.question_ans_id && item.stage === "baseline")
					.length > 0
					? questionData.filter(
							(item) => item.answerId === element.question_ans_id && item.stage === "baseline"
					  )[0].count
					: 0,
			numEndlineAnswers:
				questionData.filter((item) => item.answerId === element.question_ans_id && item.stage === "endline")
					.length > 0
					? questionData.filter(
							(item) => item.answerId === element.question_ans_id && item.stage === "endline"
					  )[0].count
					: 0,
			isCorrect: element.correct
		})
	})

	return (
		<>
			<Row>
				<ValueWrapper>
					<ChartWrapper
						title="Average baseline accuracy"
						tooltip="This is the average accuracy scored for the baseline when your users answered this question"
						height="50px"
					>
						<ValueContainer>{accuracyData.baselineAccuracy}</ValueContainer>
						<div style={{ height: "20px" }}>N = {accuracyData.numBaselineAnswers}</div>
					</ChartWrapper>
				</ValueWrapper>

				<ValueWrapper>
					<ChartWrapper
						title="Average endline accuracy"
						tooltip="This is the average accuracy scored for the endline when your users answered this question"
					>
						<ValueContainer>{accuracyData.endlineAccuracy}</ValueContainer>
						<div style={{ height: "20px" }}>N = {accuracyData.numEndlineAnswers}</div>
					</ChartWrapper>
				</ValueWrapper>
			</Row>
			<OuterContainer>
				<ChartContainer>
					<ChartWrapper title="Baseline answer responses">
						<ResponsiveContainer width="99%" height={216}>
							<BarChart
								margin={{
									top: 10,
									right: 30,
									left: 10,
									bottom: 10
								}}
								data={data}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="answerText" />
								<YAxis />
								<Tooltip />
								<Bar dataKey="numBaselineAnswers">
									{data.map((entry, index) => (
										<Cell
											key={index}
											fill={entry.isCorrect === true ? "var(--mid-green)" : "var(--mid-red)"}
										/>
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</ChartWrapper>
				</ChartContainer>

				<ChartContainer>
					<ChartWrapper title="Endline answer responses">
						<ResponsiveContainer width="99%" height={216}>
							<BarChart
								margin={{
									top: 10,
									right: 30,
									left: 10,
									bottom: 10
								}}
								data={data}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="answerText" />
								<YAxis />
								<Tooltip />
								<Bar dataKey="numEndlineAnswers">
									{data.map((entry, index) => (
										<Cell
											key={index}
											fill={entry.isCorrect === true ? "var(--mid-green)" : "var(--mid-red)"}
										/>
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</ChartWrapper>
				</ChartContainer>
			</OuterContainer>
		</>
	)
}

export default SingleQuestionData
