import React from "react"
import * as S from "../../styles/PageStyles"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"

import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { CourseCards } from "../../../util/model/OrgSelectors"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import { chartColors } from "../../analyse/common/colors"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

const Card = styled.div`
	border-radius: 5px;
	box-shadow: 0px 2px 4px var(--v-light-grey);
	padding: 10px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	transition: 0.5s ease;
	cursor: pointer;
	opacity: 0.8;
	width: 228px;
	:hover {
		opacity: 1;
	}
`

interface TimelineItem {
	name: string
	numDays: number
	sequenceIndex: number
}

interface Props {
	learningPath: LearningPath
	courseCards: CourseCards
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	setSequenceIndex: React.Dispatch<React.SetStateAction<number | undefined>>
}

const Timeline = ({ learningPath, courseCards, setModalType, setSequenceIndex }: Props) => {
	const endDate =
		learningPath.sequences.length > 0 ? learningPath.sequences[learningPath.sequences.length - 1].deadlineDay : 0
	const unlockTrigger = new LearningPathContent(learningPath).getUnlockTrigger()
	const timeline: TimelineItem[] = []

	if (learningPath.type === "SEGMENT") {
		learningPath.sequences.forEach((sequence, sequenceIndex) => {
			if (sequence.staggerProgress && sequence.daysPerCourse) {
				const courses = sequence.content.filter((item) => item.type === "course")
				courses.map((course) => {
					timeline.push({
						name: courseCards[course.targetId].info.name,
						// @ts-ignore
						numDays: sequence.daysPerCourse,
						sequenceIndex
					})
				})
			} else {
				const numDays =
					sequenceIndex === 0
						? sequence.numDays
						: sequence.deadlineDay - learningPath.sequences[sequenceIndex - 1].deadlineDay
				timeline.push({
					name: sequence.internalName,
					numDays,
					sequenceIndex
				})
			}
		})
	}
	if (learningPath.type === "ACTIVATION" && learningPath.sequences.length > 0) {
		timeline.push({
			name: learningPath.internalName,
			numDays: endDate + 1,
			sequenceIndex: 0
		})
	}

	return (
		<div style={{ position: "relative" }}>
			{Array(endDate + 1)
				.fill(true)
				.map((day, index) => (
					<S.FlexBoxStartRow
						key={index}
						style={{
							width: "100%",
							height: 34,
							borderBottom: "solid 1px var(--light-grey)"
						}}
					>
						<div
							style={{
								width: 60,
								borderRight: "solid 1px var(--light-grey)",
								textAlign: "center",
								fontSize: 12,
								marginRight: 6
							}}
						>
							Day {index + 1}
						</div>
						<div style={{ width: 240 }}></div>
					</S.FlexBoxStartRow>
				))}
			<div style={{ position: "absolute", top: 0, left: 69 }}>
				{timeline.map((item, index) => (
					<Card
						key={index}
						style={{
							height: unlockTrigger === "finish_previous" ? item.numDays * 34 : item.numDays * 34 - 10,
							marginTop: unlockTrigger === "finish_previous" ? 0 : 5,
							marginBottom: unlockTrigger === "finish_previous" ? 0 : 10,
							background: learningPath.sequences[item.sequenceIndex].classroom
								? "#531dab"
								: chartColors[item.sequenceIndex % chartColors.length]
						}}
						onClick={() => {
							setSequenceIndex(item.sequenceIndex)
							setModalType(
								learningPath.type === "ACTIVATION" ? "editLearningPathTimeline" : "editSequenceTimeline"
							)
						}}
					>
						<p
							style={{
								fontWeight: "bold",
								color: "white"
							}}
						>
							{item.name}
						</p>
					</Card>
				))}
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	courseCards: selectOrgCourseCards
})

// @ts-ignore
export default connect(mapStateToProps)(Timeline)
