// COURSE BUILDER FOOTER BUTTON

import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Button } from "antd"

import { selectLessonView } from "../../../../redux/courses/courses.selectors"
import SelectCardType from "../modals/SelectCardType"

const FooterContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 70px;
	border-top: solid 1px var(--light-grey);
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
`

interface Props {
	lesson_view?: boolean
}

const Footer = ({ lesson_view }: Props) => {
	const [showModal, setShowModal] = useState(false)
	const buttonText = lesson_view ? "Add lesson card" : "Add question card"

	return (
		<FooterContainer>
			<div style={{ width: 300 }}>
				<Button onClick={() => setShowModal(true)} block size="large" shape="round" type="primary">
					{buttonText}
				</Button>
			</div>
			{showModal && (
				<SelectCardType
					lessonOrQuestion={lesson_view ? "lesson" : "question"}
					closeModal={() => setShowModal(false)}
				/>
			)}
		</FooterContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	lesson_view: selectLessonView
})

export default connect(mapStateToProps)(Footer)
