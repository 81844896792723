import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link } from "react-router-dom"
import { Breadcrumb, Button, Space } from "antd"

import { selectLearningPaths, selectOrgId, selectRoleTypeUserGroups } from "../../redux/org/org.selectors"
import { createLearningPath } from "../../redux/org/org.actions"

import {
	LearningPath,
	LearningPathTheme,
	LearningPathType
} from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { CreateParams } from "learnink-common/lib/domains/learningPaths/LearningPathParams.model"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"

import useUrlQueryString from "../../components/hooks/useUrlQueryString"
import * as S from "../../components/styles/PageStyles"
import { ThemeColors } from "../../util/model/OrgSelectors"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import CreateLearningPath from "../../components/learningPathBuilder/modals/CreateLearningPath"
import { ModalState } from "../../util/model/PageStates"
import { UserOutlined } from "@ant-design/icons"
import { ISO_8601_string } from "learnink-common/lib/types/types"
import LearningPathUserCharts from "../../components/learningPathBuilder/roles/LearningPathUserCharts"
import RoleContainer from "../../components/learningPathBuilder/roles/RoleContainer"
import InfoBanner from "../../components/common/InfoBanner"
import LearningPathSection from "../../components/learningPathBuilder/roles/LearningPathSection"
import ArchiveUnarchive from "../../components/learningPathBuilder/modals/ArchiveUnarchive"
import AlertModal from "../../components/common/AlertModal"
import ShareLearningPath from "../../components/learningPathBuilder/modals/ShareLearningPath"

interface Props {
	orgId: string
	themeColors: ThemeColors
	roleUserGroups: UserGroup[]
	learningPaths: LearningPath[]
	createLearningPath: (orgId: string, createParams: CreateParams, userGroup: UserGroup) => LearningPath
}

const LearningPaths = ({ orgId, roleUserGroups, learningPaths, createLearningPath }: Props) => {
	const [userGroupId, setUserGroupId] = useUrlQueryString("userGroupId")
	const [modalType, setModalType] = useState<"create" | "archive" | "unarchive" | "share" | "none">("none")
	const [modalState, setModalState] = useState<ModalState>("confirm")
	const [selectedLearningPathId, setSelectedLearningPathId] = useState<string | undefined>(undefined)
	const [refreshDataCount, setRefreshDataCount] = useState<number>(0)

	const selectedUserGroup = userGroupId && roleUserGroups.filter((item: UserGroup) => item.id === userGroupId)?.[0]
	let filteredLearningPathIds: string[] = []
	let filteredLearningPaths: LearningPath[] = []
	if (selectedUserGroup) {
		filteredLearningPathIds = selectedUserGroup.learningPaths.map((item) => item.learningPathId)
		filteredLearningPaths = learningPaths.filter((item) => (filteredLearningPathIds || []).includes(item.id))
	}
	const connectedLearningPaths: LearningPath[] = []
	if (selectedLearningPathId) {
		learningPaths.forEach((learningPath) => {
			if (
				learningPath.onCompletion &&
				learningPath.onCompletion.nextLearningPathId &&
				learningPath.onCompletion.nextLearningPathId === selectedLearningPathId
			) {
				connectedLearningPaths.push(learningPath)
			}
		})
	}

	const selectRole = (id: string) => {
		// @ts-ignore
		setUserGroupId(id)
	}

	const closeModal = () => {
		setModalState("confirm")
		setModalType("none")
		setSelectedLearningPathId(undefined)
	}

	const create = async (
		type: LearningPathType,
		internalName: string,
		theme: LearningPathTheme,
		startAt: ISO_8601_string | undefined
	) => {
		setModalState("loading")
		try {
			if (selectedUserGroup) {
				const learningPathResult = await createLearningPath(
					orgId,
					{
						orgId: orgId,
						internalName: internalName,
						displayName: internalName,
						theme: theme,
						type: type,
						startAt: startAt,
						sequenceIds: [],
						onCompletion: {}
					},
					selectedUserGroup
				)
				setSelectedLearningPathId(learningPathResult.id)
				setModalState("complete")
			}
		} catch (err) {
			setModalState("error")
		}
	}

	if (roleUserGroups.length === 0) {
		return (
			<S.Container>
				<DashboardHeaderButton title="Learning paths" />
				<S.MainContainer>
					<InfoBanner
						id="why_create_a_role"
						videoId="why_create_a_role"
						title="Why do you need roles for learning paths?"
						text="Each learning path is assigned to a specific role e.g. sales agent. Why? Press the play button for a short video explainer"
					/>
					<GeNoItemsFound
						icon={<UserOutlined style={{ fontSize: 40 }} />}
						titleText="Create a role to build a learning path"
						subtitleText="You need to create at least one role in order to start making learning paths"
					>
						<Space direction="vertical" align="center" style={{ marginTop: 20 }}>
							<Link to={`/org/${orgId}/manage/user-groups`}>
								<Button type="primary" shape="round" size="large">
									Create a role
								</Button>
							</Link>
						</Space>
					</GeNoItemsFound>
				</S.MainContainer>
			</S.Container>
		)
	}

	if (!selectedUserGroup) {
		return (
			<S.Container>
				<DashboardHeaderButton title="Learning paths" />
				<S.MainContainer style={{ display: "table" }}>
					{roleUserGroups.map((item) => (
						<RoleContainer
							roleLearningPaths={learningPaths.filter((learningPath) =>
								item.learningPaths.map((element) => element.learningPathId).includes(learningPath.id)
							)}
							key={item.id}
							userGroup={item}
							selectRole={selectRole}
						/>
					))}
				</S.MainContainer>
			</S.Container>
		)
	}

	return (
		<S.Container>
			<S.BackContainer />
			<DashboardHeaderButton
				titleComponent={
					<Breadcrumb style={{ fontSize: 24, marginBottom: 12 }}>
						<Breadcrumb.Item key="root">
							<Link to={`/org/${orgId}/create/learning-paths`}>
								<span style={{ fontWeight: 600, lineHeight: 1.35 }}>Learning paths</span>
							</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item key={selectedUserGroup.id}>
							<span style={{ fontWeight: 600, lineHeight: 1.35 }}>{selectedUserGroup.name}</span>
						</Breadcrumb.Item>
					</Breadcrumb>
				}
				buttonTitle="Create"
				onClick={() => setModalType("create")}
			/>
			<S.MainContainer>
				<InfoBanner
					id="what_is_a_learning_path"
					videoId="what_is_a_learning_path"
					title="What is a learning path?"
					text="Learning paths enable you to create an ordered sequences of courses to be completed within a specific timeframe. Press the play button to learn more"
				/>
				<LearningPathUserCharts
					orgId={orgId}
					userGroupId={selectedUserGroup.id}
					learningPaths={filteredLearningPaths}
					refreshDataCount={refreshDataCount}
				/>
				<LearningPathSection
					learningPaths={filteredLearningPaths}
					setModalType={setModalType}
					setSelectedLearningPathId={setSelectedLearningPathId}
					roleId={selectedUserGroup.id}
				/>
			</S.MainContainer>
			{userGroupId && ["create"].includes(modalType) && (
				<CreateLearningPath
					isModalOpen={true}
					setIsModalOpen={closeModal}
					modalState={modalState}
					handleSubmit={create}
					selectedLearningPathId={selectedLearningPathId}
				/>
			)}
			{modalType === "archive" && selectedLearningPathId && connectedLearningPaths.length > 0 && (
				<AlertModal
					title="Archiving error"
					message={`You cannot archive this learning path as it is connected to another learning path (${connectedLearningPaths
						.map((item) => item.internalName)
						.join(",")}). Disconnect this learning path before archiving`}
					closeModal={closeModal}
					isModalOpen
					type="error"
				/>
			)}
			{modalType === "archive" && selectedLearningPathId && connectedLearningPaths.length === 0 && (
				<ArchiveUnarchive
					learningPath={learningPaths.filter((item) => item.id === selectedLearningPathId)[0]}
					onCancel={closeModal}
					action="archive"
				/>
			)}
			{modalType === "unarchive" && selectedLearningPathId && (
				<ArchiveUnarchive
					learningPath={learningPaths.filter((item) => item.id === selectedLearningPathId)[0]}
					onCancel={closeModal}
					action="unarchive"
				/>
			)}
			{modalType === "share" && selectedLearningPathId && (
				<ShareLearningPath
					learningPath={learningPaths.filter((item) => item.id === selectedLearningPathId)[0]}
					closeModal={closeModal}
					refreshData={() => setRefreshDataCount(refreshDataCount + 1)}
					role={selectedUserGroup}
				/>
			)}
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	roleUserGroups: selectRoleTypeUserGroups,
	learningPaths: selectLearningPaths
})

const mapDispatchToProps = (dispatch: any) => ({
	createLearningPath: (orgId: string, createParams: CreateParams, userGroupId: string) =>
		dispatch(createLearningPath(orgId, createParams, userGroupId))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(LearningPaths)
