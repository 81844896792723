// DASHBOARD SHOWING USER PROGRESS THROUGH A SEQUENCE

import React from "react"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff

import useUrlQueryString from "../../hooks/useUrlQueryString"
import SequenceJourneyQuery from "../charts/sequence-journey-dash/SequenceJourneyQuery"

const SequenceJourneyDash = () => {
	const [sequenceId] = useUrlQueryString("sequenceId")
	const [dateRange] = useUrlQueryString("dateRange")

	return (
		<div className="container-fluid">
			<SequenceJourneyQuery sequenceId={sequenceId} dateRange={dateRange} />
		</div>
	)
}

export default SequenceJourneyDash
