import { createSelector } from "reselect"
import { MIN_LESSON_CARDS, MIN_QUESTION_CARDS } from "../../config/course.config"

export const selectCourses = (state) => state.courses

// Page Params selectors
export const selectPageParams = createSelector([selectCourses], (courses) => courses.pageParams)
export const selectCourseId = createSelector([selectPageParams], (params) => params.course_id)
export const selectModuleId = createSelector([selectPageParams], (params) => params.module_id)
export const selectLessonView = createSelector([selectPageParams], (params) => params.lesson_view)

// Course selectors

export const selectCourse = createSelector([selectCourses], (courses) => courses.course)
export const selectCourseInfo = createSelector([selectCourse], (course) => course.info)
export const selectCourseStageCards = createSelector([selectCourse], (course) => course.stage_cards)
export const selectCourseModuleCards = createSelector([selectCourse], (course) => course.module_cards)
export const selectCompletionData = createSelector([selectCourse], (course) => {
	let modules_count = 0
	let modules_without_stages = 0
	let stage_lessons_unfinished = 0
	let stage_questions_unfinished = 0
	let total_items = 0
	let items_unfinished = 0
	let percent_complete = 0

	modules_count = course.module_ids.length

	for (const id of Object.keys(course.module_cards)) {
		if (course.module_cards[id].stage_ids.length === 0) modules_without_stages++
	}

	for (const id of Object.keys(course.stage_cards)) {
		if (course.stage_cards[id].lesson_card_ids.length < MIN_LESSON_CARDS) stage_lessons_unfinished++
		if (course.stage_cards[id].question_card_ids.length < MIN_QUESTION_CARDS) stage_questions_unfinished++
	}

	total_items = Object.keys(course.stage_cards).length * 2 + course.module_ids.length
	items_unfinished = modules_without_stages + stage_lessons_unfinished + stage_questions_unfinished

	if (modules_count === 0) {
		percent_complete = 0
	} else if (items_unfinished === 0) {
		percent_complete = 100
	} else {
		percent_complete = 100 - Math.round((items_unfinished / total_items) * 100)
	}

	return {
		modules_count,
		modules_without_stages,
		stage_lessons_unfinished,
		stage_questions_unfinished,
		total_items,
		items_unfinished,
		percent_complete
	}
})

// Stage Selectors

export const selectStage = createSelector([selectCourses], (courses) => courses.stage)
export const selectCurrentStageId = createSelector([selectStage], (stage) => stage.stage_id)
export const selectStageItemsReady = createSelector([selectStage], (stage) => {
	if (
		stage &&
		stage.stage_columns &&
		stage.stage_columns.lesson_data &&
		stage.stage_columns.questions_data &&
		stage.stage_columns.lesson_data.card_ids &&
		stage.stage_columns.questions_data.card_ids
	) {
		return {
			lessonPreviewReady: stage.stage_columns.lesson_data.card_ids.length >= MIN_LESSON_CARDS,
			questionsPreviewReady: stage.stage_columns.questions_data.card_ids.length >= MIN_QUESTION_CARDS
		}
	} else {
		return null
	}
})

// Objectives selectors

export const selectObjectives = createSelector([selectCourses], (courses) => courses.objectives)
