// MODAL TO COPY A MODULE TO ANOTHER COURSE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert, Select, Typography, Space } from "antd"

import { duplicateModuleToNewCourse } from "../../../../redux/courses/courses.actions"
import { selectCourseId } from "../../../../redux/courses/courses.selectors"
import { selectOrgId, selectOrgCourseCards } from "../../../../redux/org/org.selectors"

import CbLoading from "../../../common/CbLoading"

const { Text, Title } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
	padding: 30px;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const CopyModuleToCourse = ({
	closemodal,
	org_id,
	course_id,
	module_card,
	duplicateModuleToNewCourse,
	orgCourseCards
}) => {
	const [loading, setLoading] = useState(false)
	const [selectedCourseId, setSelectedCourseId] = useState(null)
	const [errorMsg, setErrorMsg] = useState(null)

	let courseOptions = Object.keys(orgCourseCards).map((key) => {
		return {
			value: key, // course_id
			archived: orgCourseCards[key].info.user_groups.access_groups.includes("archived") ? true : false,
			label: orgCourseCards[key].info.name // name
		}
	})
	courseOptions = courseOptions.filter((item) => item.archived === false)

	const handleSelectNewCourse = (val) => {
		setSelectedCourseId(val)
		setErrorMsg(null)
	}

	const handleDuplicateToNewCourseClick = () => {
		if (selectedCourseId) {
			setLoading(true)
			const old_course_id = course_id
			const new_course_id = selectedCourseId
			// we need to get all objectives associated with modules's stages
			// and also copy these to new course
			/*
      const module_stages_objective_ids = module_card.stage_ids.map(stage_id => {
        return courseStageCards[stage_id].objective_id
      })
      */
			setTimeout(() => {
				duplicateModuleToNewCourse(module_card.id, old_course_id, new_course_id, org_id)
				setSelectedCourseId(null)
				setErrorMsg(null)
				setLoading(false)
				closemodal()
			}, 2000)
		} else {
			setErrorMsg("You must select a course to duplicate this module to")
		}
	}

	return (
		<ContentContainer>
			<Title level={4}>Duplicate to course</Title>
			{loading ? (
				<>
					<CbLoading padding="30px" />
				</>
			) : (
				<>
					<Text>Select the course you'd like to duplicate this module to</Text>
					<Divider height={"10px"} />
					<Select
						showArrow={true}
						style={{ width: "100%" }}
						options={courseOptions}
						placeholder="Select course..."
						onChange={(value) => handleSelectNewCourse(value)}
						size="large"
					/>
					<Divider height={"10px"} />
					{errorMsg && <Alert message={errorMsg} type="error" />}
					<Divider height={"20px"} />
					<ButtonsContainer>
						<Space>
							<Button size="large" shape="round" onClick={closemodal}>
								Cancel
							</Button>
							<Button type="primary" size="large" shape="round" onClick={handleDuplicateToNewCourseClick}>
								Confirm
							</Button>
						</Space>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	org_id: selectOrgId,
	course_id: selectCourseId,
	orgCourseCards: selectOrgCourseCards
})

const mapDispatchToProps = (dispatch) => ({
	duplicateModuleToNewCourse: (module_id, old_course_id, new_course_id, org_id) =>
		dispatch(duplicateModuleToNewCourse(module_id, old_course_id, new_course_id, org_id))
})

PropTypes.ArchiveCourse = {
	closemodal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyModuleToCourse)
