import React from "react"
import CbLoadingJs from "./CbLoadingJs"

interface Props {
	loadingText?: string,
	padding?: string,
	size?: number,
	fullpage?: boolean
}

export default function CbLoading(props: Props): React.JSX.Element {
	// @ts-ignore
	return CbLoadingJs(props)
}