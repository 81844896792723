import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Link } from "react-router-dom"
import moment from "moment"

import { selectActivePlan } from "../../redux/subscription/subscription.selectors"
import { selectOrgId } from "../../redux/org/org.selectors"

const UpgradeBandOuter = styled.div`
	position: fixed;
	align-items: center;
	justify-content: center;
	min-height: 35px;
	width: 100%;
	background-color: var(--dark-red);
	display: flex;
	@media screen and (min-width: 850px) {
		width: calc(100% - 230px);
	}
	z-index: 1;
`

const UpgradeBandInner = styled.div`
	justify-content: center;
	display: flex;
	flex: 1 1 100%;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.5;
	color: white;
`

const SubscriptionWarningBand = ({ activePlan, orgId }) => {
	if (activePlan) {
		if (activePlan.status === "stripe_subscription_incomplete") {
			return (
				<Link to={`/org/${orgId}/subscription`}>
					<UpgradeBandOuter>
						<UpgradeBandInner>
							Your subscription payment failed, you may soon lose access to your account. Press here to
							fix it
						</UpgradeBandInner>
					</UpgradeBandOuter>
				</Link>
			)
		} else if (["stripe_subscription_past_due", "non_stripe_subscription_past_due"].includes(activePlan.status)) {
			return (
				<Link to={`/org/${orgId}/subscription`}>
					<UpgradeBandOuter>
						<UpgradeBandInner>
							Your subscription payment failed, you may soon lose access to your account. Press here to
							fix it
						</UpgradeBandInner>
					</UpgradeBandOuter>
				</Link>
			)
		} else if (activePlan.status === "stripe_subscription_unpaid") {
			return (
				<Link to={`/org/${orgId}/subscription`}>
					<UpgradeBandOuter>
						<UpgradeBandInner>
							Your subscription payment failed, you may soon lose access to your account. Press here to
							fix it
						</UpgradeBandInner>
					</UpgradeBandOuter>
				</Link>
			)
		} else if (activePlan.status === "trial_subscription") {
			return (
				<Link to={`/org/${orgId}/subscription`}>
					<UpgradeBandOuter>
						<UpgradeBandInner>
							Your trial account will expire on the {moment(activePlan.expires).format("Do MMM YY")}.
							Press here to activate
						</UpgradeBandInner>
					</UpgradeBandOuter>
				</Link>
			)
		} else if (activePlan.cancel_at) {
			return (
				<UpgradeBandOuter
					style={{
						backgroundColor: "white",
						borderBottom: "solid 1px var(--light-grey)"
					}}
				>
					<UpgradeBandInner style={{ color: "black" }}>
						Your subscription will expire on {moment(activePlan.cancel_at * 1000).format("LL")}
					</UpgradeBandInner>
				</UpgradeBandOuter>
			)
		} else {
			return null
		}
	} else {
		return null
	}
}

SubscriptionWarningBand.propTypes = {
	activePlan: PropTypes.object
}

SubscriptionWarningBand.defaultProps = {
	activePlan: {
		status: "other"
	}
}

const mapStateToProps = createStructuredSelector({
	activePlan: selectActivePlan,
	orgId: selectOrgId
})

export default connect(mapStateToProps)(SubscriptionWarningBand)
