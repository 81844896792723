import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { FolderFilled } from "@ant-design/icons"
import { Typography, Space, Dropdown, Menu } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

import LpModal from "../../common/LpModal"
import MoveToFolder from "./modals/MoveToFolder"
import RenameFolder from "./modals/RenameFolder"
import DeleteFolder from "./modals/DeleteFolder"

const { Title } = Typography

const CourseCardContainer = styled.div`
	height: 100px;
	width: 100%;
	background: white;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	box-shadow: 1px 2px 2px var(--light-grey);
	border: solid 1px var(--light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
	position: relative;
	padding: 20px;
	transition: background 0.5s;
	:hover {
		background: var(--secondary-color-pale);
	}
`
const HeaderMenuButton = styled.button`
	font-size: 22px;
	color: var(--light-grey);
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px 10px 0px 10px;
`
const ModalContainer = styled.div`
	min-width: 490px;
	border-radius: 8px;
	background: white;
	position: relative;
	padding: 30px;
`

const FolderCard = ({ orgId, pathname, folder: { id, name, slug, settings } }) => {
	const [modalType, setModalType] = useState(null)

	const path = pathname.split(orgId, 2)[1].replace("/create/courses", "")

	const showModal = (e, type) => {
		e.preventDefault()
		setModalType(type)
	}

	const dropDown = (
		<Menu
			items={[
				{
					key: "rename_folder",
					label: <div onClick={(e) => showModal(e, "rename")}>Edit</div>
				},
				{
					key: "move_to_folder",
					label: <div onClick={(e) => showModal(e, "move")}>Move...</div>
				},
				{
					key: "delete",
					label: <div onClick={(e) => showModal(e, "delete")}>Delete</div>
				}
			]}
		/>
	)

	return (
		<>
			<Link to={`/org/${orgId}/create/courses${path}/${slug}`}>
				<CourseCardContainer>
					<Space>
						<FolderFilled
							style={{
								fontSize: 32,
								color: settings.folder_color ? settings.folder_color : "var(--secondary-color)"
							}}
						/>
						<Title level={5} style={{ marginBottom: 0 }}>
							{name}
						</Title>
					</Space>
					<Dropdown overlay={dropDown} placement="bottomRight">
						<HeaderMenuButton>
							<FontAwesomeIcon icon={faEllipsisV} />
						</HeaderMenuButton>
					</Dropdown>
				</CourseCardContainer>
			</Link>
			<LpModal isShowingModal={modalType !== null} hideModal={() => setModalType(null)}>
				<ModalContainer>
					{modalType === "rename" && (
						<RenameFolder
							folder_name={name}
							folder_id={id}
							folder_color={settings.folder_color}
							closeModal={() => setModalType(null)}
						/>
					)}
					{modalType === "move" && (
						<MoveToFolder id={id} type="folder" closemodal={() => setModalType(null)} />
					)}
					{modalType === "delete" && <DeleteFolder folderId={id} closeModal={() => setModalType(null)} />}
				</ModalContainer>
			</LpModal>
		</>
	)
}

export default FolderCard
