import React from "react"
import styled from "styled-components"

import { borderRadiusSettings } from "../styling"
import AutoSizeText from "../elements/AutoSizeText"
import Icon from "../elements/Icon"

const FlexColumnCentred = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const FlexColumnSpaceBetween = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	align-content: center;
`

const outerContainerDimensions = {
	2: {
		width: 0.75,
		height: 0.65
	},
	3: {
		width: 0.8,
		height: 0.92
	},
	4: {
		width: 0.84,
		height: 1.2
	}
}

const cardDimensions = {
	2: {
		width: 0.75,
		height: 0.3
	},
	3: {
		width: 0.8,
		height: 0.28
	},
	4: {
		width: 0.84,
		height: 0.27
	}
}

const innerContainerDimensions = {
	2: {
		width: 0.66,
		height: 0.21
	},
	3: {
		width: 0.7,
		height: 0.19
	},
	4: {
		width: 0.74,
		height: 0.19
	}
}

const iconDimensions = {
	2: {
		width: 0.12,
		height: 0.12
	},
	3: {
		width: 0.12,
		height: 0.12
	},
	4: {
		width: 0.12,
		height: 0.12
	}
}

const textboxDimensionsNoIcon = {
	2: {
		width: 0.66,
		height: 0.21
	},
	3: {
		width: 0.7,
		height: 0.19
	},
	4: {
		width: 0.74,
		height: 0.19
	}
}

const textboxDimensionsIcon = {
	2: {
		width: 0.5,
		height: 0.21
	},
	3: {
		width: 0.55,
		height: 0.19
	},
	4: {
		width: 0.58,
		height: 0.19
	}
}

const getIconDimensions = (numElements, width) => {
	const iconScale = iconDimensions[numElements]
	return { width: iconScale.width * width, height: iconScale.height * width }
}

const getTextboxDimensions = (iconSize, numElements, width) => {
	const textboxScale =
		iconSize && iconSize !== "none" ? textboxDimensionsIcon[numElements] : textboxDimensionsNoIcon[numElements]
	return {
		width: textboxScale.width * width,
		height: textboxScale.height * width
	}
}

const MultiElementHorizontal = ({
	cardId,
	shape,
	iconPlacement,
	iconSize,
	elements,
	width,
	height,
	editable,
	handleUpdateText
}) => {
	return (
		<FlexColumnCentred style={{ width, height, zIndex: 9 }}>
			<FlexColumnSpaceBetween
				style={{
					width: outerContainerDimensions[elements.length].width * width,
					height: outerContainerDimensions[elements.length].height * width
				}}
			>
				{elements.map((element, index) => (
					<FlexColumnCentred
						key={index}
						style={{
							width: cardDimensions[elements.length].width * width,
							height: cardDimensions[elements.length].height * width,
							borderRadius: borderRadiusSettings[shape] || 0,
							backgroundColor: "white"
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: iconPlacement === "right" ? "row-reverse" : "row",
								justifyContent: iconPlacement && iconPlacement !== "none" ? "space-between" : "center",
								alignContent: "center",
								alignItems: "center",
								width: innerContainerDimensions[elements.length].width * width,
								height: innerContainerDimensions[elements.length].height * width
							}}
						>
							{iconPlacement && iconPlacement !== "none" && (
								<Icon
									cardId={cardId}
									element={element}
									width={getIconDimensions(elements.length, width).width}
									height={getIconDimensions(elements.length, width).height}
									index={index}
								/>
							)}
							<AutoSizeText
								width={getTextboxDimensions(iconSize, elements.length, width).width}
								height={getTextboxDimensions(iconSize, elements.length, width).height}
								textAlign="left"
								text={element.text}
								editable={editable}
								handleUpdateText={(text) => handleUpdateText(text, index)}
								id={`call_out_card_text_${index}`}
							/>
						</div>
					</FlexColumnCentred>
				))}
			</FlexColumnSpaceBetween>
		</FlexColumnCentred>
	)
}

export default MultiElementHorizontal
