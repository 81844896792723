import React, { useState } from "react"
// @ts-ignore
import { Draggable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { Dropdown, Menu } from "antd"

import {
	deleteStageContentCard,
	duplicateStageContentCard,
	updateCardEditorSettings
} from "../../../../redux/courses/courses.actions"

import { selectStage } from "../../../../redux/courses/courses.selectors"

import TooltipIcon from "../../../common/TooltipIcon"
import SelectCardType from "../modals/SelectCardType"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { UUID } from "learnink-common/lib/types/types"
import LessonCardContainer from "./lessonCards/LessonCardContainer"
import QuestionCard from "./questionCards/QuestionCard"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { CardModel } from "../../../../util/model/Cards"
import ReadAloudButton from "./common/ReadAloudButton"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"

const AddNewCardButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 2px;
`

const AddNewCardButton = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	border-radius: 999px;
	background: white;
	border: solid 1px var(--light-grey);
	color: var(--dark-grey);
	cursor: pointer;
	box-shadow: 0px 2px 4px var(--v-light-grey);
	:hover {
		background: var(--primary-color);
		color: white;
		border: solid 1px var(--primary-color);
	}
	:focus {
		background: var(--primary-color);
		color: white;
		border: solid 1px var(--primary-color);
	}
`

const MainCardContainer = styled.div<{
	isArchived?: boolean
	isDragging?: boolean
	isActive?: boolean
	isComplete?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  color: var(--dark-grey);
  border-radius: 10px;
  padding: 8px;
  margin-bottom: ${(props) => (props.isArchived ? "10px;" : "0px;")}
  border: ${(props) =>
		props.isDragging
			? "1px solid var(--secondary-color);"
			: props.isActive
			? "2px solid var(--secondary-color);"
			: props.isComplete
			? "1px solid var(--light-grey);"
			: "1px solid var(--dark-red);"}
  box-shadow: ${(props) =>
		props.isDragging
			? "none"
			: props.isComplete
			? "0px 2px 4px var(--v-light-grey);"
			: "0px 2px 4px var(--v-light-grey);"}
  transition: background-color 0.2s ease;
  cursor: pointer;
  background-color: ${(props) => (props.isDragging ? "white" : props.isComplete ? "white" : "var(--light-red)")}
`
const CardHeader = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;
`
const HeaderHandle = styled.div`
	width: 30px;
	background-color: none;
`
const HeaderTitle = styled.div`
	flex: 1;
	padding: 1px 4px;
	font-size: 12px;
	font-weight: 600;
	margin: 0px;
	color: var(--black);
	text-transform: capitalize;
`
const HeaderMenuButton = styled.button`
	background: none;
	padding-left: 10px;
	padding-right: 4px;
	cursor: pointer;
`
const UnderHeaderContainer = styled.div`
	width: 100%;
	padding: 5px;
	display: flex;
`

interface Props {
	card: Card
	index: number
	col_id: "lesson_data" | "lesson_archive" | "questions_data" | "questions_archive"
	stage: any
	deleteStageContentCard: any
	duplicateStageContentCard: any
	updateCardEditorSettings: any
}

const StageCardContainer = ({
	card,
	index,
	col_id,
	stage,
	deleteStageContentCard,
	duplicateStageContentCard,
	updateCardEditorSettings
}: Props) => {
	const [showModal, setShowModal] = useState<boolean>(false)
	const lessonOrQuestion = card.type === "multipleChoiceQuestion" ? "question" : "lesson"
	const isArchived = ["lesson_archive", "questions_archive"].includes(col_id)
	const errors = new CardModel(card).findErrors()
	const isComplete = errors.length === 0

	const cardVoiceClips = card.voiceClips || []
	const storedLanguages: LanguageCode[] = cardVoiceClips
		.map((clip) => clip.languageCode as LanguageCode)
		.filter((l) => l)

	const addCardMenu = (
		<Menu
			items={[
				{
					key: "duplicate_above",
					label: <div onClick={() => duplicateStageContentCard(card, index)}>Duplicate above</div>
				},
				{
					key: "create",
					label: <div onClick={() => setShowModal(true)}>Create new</div>
				}
			]}
		/>
	)

	const settingsMenu = (
		<Menu
			items={[
				{
					key: "delete",
					label: (
						<div onClick={() => deleteStageContentCard(card.id, lessonOrQuestion, index, col_id)}>
							Delete
						</div>
					)
				}
			]}
		/>
	)

	return (
		<Draggable draggableId={card.id} index={index}>
			{(provided: any, snapshot: any) => (
				<>
					<MainCardContainer
						{...provided.draggableProps}
						ref={provided.innerRef}
						isDragging={snapshot.isDragging}
						isComplete={isComplete}
						isActive={
							card.id === stage.editor.activeCardId &&
							!stage.editor.imageIndex &&
							stage.editor.imageIndex !== 0
						}
						isArchived={isArchived}
						type={card.type}
						onClick={() =>
							updateCardEditorSettings(
								isArchived
									? { menu: "archive" }
									: {
											activeCardId: card.id,
											menu: stage.editor.menu === "archive" ? "editor" : stage.editor.menu
									  }
							)
						}
					>
						<CardHeader>
							<HeaderHandle {...provided.dragHandleProps}>
								<FontAwesomeIcon icon={faArrowsAlt as IconProp} color="var(--light-grey)" />
							</HeaderHandle>

							{/* @ts-ignore */}
							<HeaderTitle>{card.from || "Question"}</HeaderTitle>

							{storedLanguages.map((languageCode) => (
								<ReadAloudButton key={languageCode} card={card} languageCode={languageCode} />
							))}

							{errors.length > 0 && <TooltipIcon tooltip={errors[0]} error />}

							<Dropdown overlay={settingsMenu} placement="bottomRight">
								<HeaderMenuButton>
									<FontAwesomeIcon icon={faEllipsisV as IconProp} color="var(--light-grey)" />
								</HeaderMenuButton>
							</Dropdown>
						</CardHeader>

						<UnderHeaderContainer>
							{card.type === "multipleChoiceQuestion" ? (
								<QuestionCard card={card} editable />
							) : (
								<LessonCardContainer card={card} />
							)}
						</UnderHeaderContainer>
					</MainCardContainer>
					{!isArchived && (
						<>
							<AddNewCardButtonContainer>
								<Dropdown overlay={addCardMenu} placement="bottom">
									<AddNewCardButton>+</AddNewCardButton>
								</Dropdown>
							</AddNewCardButtonContainer>
							{showModal && (
								<SelectCardType
									lessonOrQuestion={lessonOrQuestion}
									closeModal={() => setShowModal(false)}
									index={index}
								/>
							)}
						</>
					)}
				</>
			)}
		</Draggable>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	deleteStageContentCard: (card_id: UUID, card_type: "question" | "lesson", index: number, col_id: string) =>
		dispatch(deleteStageContentCard(card_id, card_type, index, col_id)),
	duplicateStageContentCard: (card: Card, index: number) => dispatch(duplicateStageContentCard(card, index)),
	updateCardEditorSettings: (editor: any) => dispatch(updateCardEditorSettings(editor))
})

export default connect(mapStateToProps, mapDispatchToProps)(StageCardContainer)
