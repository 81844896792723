import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"

import BasicTable from "../../common/BasicTable"
import { LearningPathUserTableRow } from "./LearningPathProgress"
import { OrgUserGroupNamesMap } from "../../../../util/model/OrgSelectors"
import CbLoading from "../../../common/CbLoading"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"

interface UserGroupTableRow {
	userGroupName: string
	totalNumUsers: number
	numInProgress: number
	numCompletedOnTime: number
	numCompletedLate: number
	numOverdue: number
}

interface Props {
	userGroupsArr: string[]
	data: LearningPathUserTableRow[]
	orgUserGroupNamesMap: OrgUserGroupNamesMap
}

const UserGroupSummaryTable = ({ userGroupsArr, data, orgUserGroupNamesMap }: Props) => {
	const [tableData, setTableData] = useState<UserGroupTableRow[] | undefined>(undefined)

	useEffect(() => {
		const tableRowArray: UserGroupTableRow[] = []
		userGroupsArr.forEach((userGroup) => {
			const userGroupName = orgUserGroupNamesMap[userGroup]
			const filteredData = data.filter((item) => item.userGroups && item.userGroups.includes(userGroup))
			tableRowArray.push({
				userGroupName,
				totalNumUsers: filteredData.length,
				numInProgress: filteredData.filter((item) => item.status === "in progress").length,
				numCompletedOnTime: filteredData.filter((item) => item.status === "completed on time").length,
				numCompletedLate: filteredData.filter((item) => item.status === "completed late").length,
				numOverdue: filteredData.filter((item) => item.status === "overdue").length
			})
		})
		setTableData(tableRowArray)
	}, [data, userGroupsArr, orgUserGroupNamesMap])

	if (!tableData) {
		return <CbLoading />
	}

	if (tableData.length > 0) {
		return (
			<div className="row" style={{ padding: 15 }}>
				<BasicTable
					columns={[
						{
							Header: "User group",
							accessor: "userGroupName"
						},
						{
							Header: "Total users",
							accessor: "totalNumUsers"
						},
						{
							Header: "Num in progress",
							accessor: "numInProgress"
						},
						{
							Header: "Num completed on time",
							accessor: "numCompletedOnTime"
						},
						{
							Header: "Num completed late",
							accessor: "numCompletedLate"
						},
						{
							Header: "Num overdue",
							accessor: "numOverdue"
						}
					]}
					data={tableData}
				/>
			</div>
		)
	}

	return (
		<div className="row" style={{ paddingTop: 20 }}>
			<div className="col-sm-3">
				<ChartWrapper tooltip="" title="In progress">
					<SingleValueChart>{data.filter((item) => item.status === "in progress").length}</SingleValueChart>
				</ChartWrapper>
			</div>
			<div className="col-sm-3">
				<ChartWrapper tooltip="" title="Completed on time">
					<SingleValueChart>
						{data.filter((item) => item.status === "completed on time").length}
					</SingleValueChart>
				</ChartWrapper>
			</div>
			<div className="col-sm-3">
				<ChartWrapper tooltip="" title="Completed late">
					<SingleValueChart>
						{data.filter((item) => item.status === "completed late").length}
					</SingleValueChart>
				</ChartWrapper>
			</div>
			<div className="col-sm-3">
				<ChartWrapper tooltip="" title="Overdue">
					<SingleValueChart>{data.filter((item) => item.status === "overdue").length}</SingleValueChart>
				</ChartWrapper>
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupNamesMap: selectOrgUserGroupNamesMap
})

// @ts-ignore
export default connect(mapStateToProps)(UserGroupSummaryTable)
