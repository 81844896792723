// MODAL TO UPLOAD A VIDEO FROM MUX

import React, { useState } from "react"
import axios from "axios"
import * as UpChunk from "@mux/upchunk"
import { v4 as uuid } from "uuid"
import { Button } from "antd"

const VideoUpload = ({ handleVideoUpload }) => {
	const [inputRef, setInputRef] = useState(null)
	const [pageState, setPageState] = useState("default")
	const [progress, setProgress] = useState(0)
	const [statusMessage, setStatusMessage] = useState(null)
	const [uploadId, setUploadId] = useState(null)

	const getMuxPlaybackId = async () => {
		try {
			const response = await axios.post("/api/data/get-mux-playback-id", {
				upload_id: uploadId
			})
			const playbackID = response.data
			const videoThumbnail = `https://image.mux.com/${playbackID}/thumbnail.png?width=300`
			handleVideoUpload("mux", playbackID, videoThumbnail)
			setStatusMessage("Video uploaded")
		} catch (err) {
			setStatusMessage("Video upload failed")
		}
	}

	const createInputRef = (data) => {
		setInputRef(data)
		const upload_id = uuid()
		setUploadId(upload_id)
	}

	const handleUpload = async () => {
		try {
			setPageState("uploading")
			const response = await axios.post("/api/data/mux-upload-url", {
				upload_id: uploadId
			})

			const upload = UpChunk.createUpload({
				endpoint: response.data.url,
				file: inputRef.files[0],
				chunkSize: 5120 // Uploads the file in ~5mb chunks
			})

			// Subscribe to events
			upload.on("error", (error) => {
				setStatusMessage(error.detail)
			})

			upload.on("progress", (progress) => {
				setProgress(progress.detail)
			})

			upload.on("success", () => {
				setPageState("success")
				setStatusMessage("Encoding video...")
				getMuxPlaybackId()
			})
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			{pageState === "default" && (
				<>
					<input
						onChange={(e) => createInputRef(e.target)}
						type="file"
						id="file-upload"
						name="file-upload"
						accept="video/mp4"
					/>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignContent: "center",
							alignItems: "center",
							paddingTop: 20,
							marginBottom: 4
						}}
					>
						<Button
							type="primary"
							shape="round"
							size="medium"
							disabled={inputRef === null}
							onClick={handleUpload}
						>
							Upload video
						</Button>
					</div>
				</>
			)}
			{pageState === "uploading" && <progress value={progress} max="100" />}
			{statusMessage && <em>{statusMessage}</em>}
		</>
	)
}

export default VideoUpload
