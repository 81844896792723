// ARCHIVE COURSE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert } from "antd"

import { selectLearningPaths, selectOrgId } from "../../../../redux/org/org.selectors"
import { archiveCourseWithId } from "../../../../redux/courses/courses.actions"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"
import LpTextInput from "../../../common/LpTextInput"

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const ArchiveCourse = ({
	closemodal,
	orgId,
	courseName,
	courseId,
	archiveCourseWithId,
	triggerRedirect,
	learningPaths
}) => {
	const [loading, setLoading] = useState(false)
	const [archiveConfirmText, setArchiveConfirmText] = useState(null)

	// this ammounts to setting access_groups to "archive" AND publishing an update to course IF it's published...
	const handleArchiveCourse = () => {
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)

			// handle update here
			archiveCourseWithId(orgId, courseId)

			if (triggerRedirect) {
				triggerRedirect()
			} else {
				closemodal()
			}
		}, 3000)
	}
	const handleArchiveConfirmTextChange = (text) => {
		setArchiveConfirmText(text)
	}

	const learningPathsContainingCourse = []
	learningPaths
		.filter((item) => !item.isArchived)
		.forEach((learningPath) => {
			learningPath.sequences.forEach((sequence) => {
				if (sequence.content.filter((item) => item.targetId === courseId).length > 0) {
					learningPathsContainingCourse.push(learningPath.internalName)
				}
			})
		})

	if (learningPathsContainingCourse.length > 0) {
		return (
			<ContentContainer>
				<GeTitle>Archive course</GeTitle>
				<Alert
					message={`You cannot archive this course as it is included in ${
						learningPathsContainingCourse.length
					} learning paths (${learningPathsContainingCourse.join(
						", "
					)}). Delete this course from the learning paths before archiving`}
					type="warning"
				/>
			</ContentContainer>
		)
	}
	return (
		<ContentContainer>
			<GeTitle>Archive course</GeTitle>
			{loading ? (
				<CbLoading loadingText="Archiving..." padding="30px" />
			) : (
				<>
					<LpTextInput
						labelText="Course name"
						initialText={courseName}
						disabled={true}
						labelFontSize="16px"
					/>
					<Divider height={"28px"} />
					<LpTextInput
						labelText="Type the course name shown above to archive"
						handleTextChange={handleArchiveConfirmTextChange}
						labelFontSize="16px"
					/>
					{archiveConfirmText === courseName && (
						<>
							<Divider height={"10px"} />
							<Alert
								message={
									"WARNING: This course will no longer be viewable or accessible to any of your users, existing user group references will also be removed!"
								}
								type="error"
							/>
						</>
					)}
					<Divider height={"20px"} />
					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button
							type="primary"
							size="large"
							shape="round"
							onClick={handleArchiveCourse}
							disabled={archiveConfirmText !== courseName}
						>
							Confirm archive
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPaths: selectLearningPaths
})

const mapDispatchToProps = (dispatch) => ({
	archiveCourseWithId: (org_id, course_id) => dispatch(archiveCourseWithId(org_id, course_id))
})

PropTypes.ArchiveCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseName: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	archiveCourseWithId: PropTypes.func.isRequired,
	triggerRedirect: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveCourse)
