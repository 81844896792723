export const chartColors = ["#5ad45a", "#00b7c7", "#0d88e6", "#1a53ff", "#4421af", "#7c1158", "#b30000"]

export const alphabetColors = {
	a: "#74C8C3",
	b: "#FE9923",
	c: "#68BFFB",
	d: "#B9E13F",
	e: "#FF7F6C",
	f: "#D6753D",
	g: "#FFB100",
	h: "#8A74C8",
	i: "#C874C8",
	j: "#8EC874",
	k: "#7496C8",
	l: "#345AB1",
	m: "#A85B8B",
	n: "#2A778D",
	o: "#109618",
	p: "#990099",
	q: "#0099C6",
	r: "#DD4477",
	s: "#66AA00",
	t: "#B82E2E",
	u: "#316395",
	v: "#994499",
	w: "#22AA99",
	x: "#AAAA11",
	y: "#6633CC",
	z: "#743411"
}

export const statusColors = {
	registered: "green",
	unregistered: "blue",
	complete: "green",
	incomplete: "blue",
	"in progress": "blue",
	"completed on time": "green",
	"completed late": "cyan",
	overdue: "gold",
	"not started": undefined
}

export const folderColors = [
	"#27c940",
	"#FE9923",
	"#68BFFB",
	"#B9E13F",
	"#FF7F6C",
	"#D6753D",
	"#FFB100",
	"#8A74C8",
	"#C874C8"
]
