import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import useUrlQueryString from "../../../hooks/useUrlQueryString"

import { selectLearningPaths, selectOrgId, selectRoleTypeUserGroups } from "../../../../redux/org/org.selectors"
import { fetchAnalytics } from "../../fetchAnalytics"
import CbLoading from "../../../common/CbLoading"
import BasicTable from "../../common/BasicTable"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../../pages/learningPaths/utils/LearningPathContent"
import { chartColors } from "../../common/colors"
import { UserGroup } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"

interface Props {
	orgId: string
	learningPaths: LearningPath[]
	roleUserGroups: UserGroup[]
}

interface StatusTable {
	learningPathId: string
	inProgress: number
	completedOnTime: number
	completedLate: number
	overdue: number
}

const getTotal = (tableData: StatusTable[], field: "numStarted" | "numCompleted" | "numOverdue") => {
	if (field === "numStarted") {
		const total =
			tableData.map((item) => item.inProgress).reduce((a, b) => a + b, 0) +
			tableData.map((item) => item.completedOnTime).reduce((a, b) => a + b, 0) +
			tableData.map((item) => item.completedLate).reduce((a, b) => a + b, 0) +
			tableData.map((item) => item.overdue).reduce((a, b) => a + b, 0)
		return total
	}
	if (field === "numCompleted") {
		const total =
			tableData.map((item) => item.completedOnTime).reduce((a, b) => a + b, 0) +
			tableData.map((item) => item.completedLate).reduce((a, b) => a + b, 0)
		return total
	}
	if (field === "numOverdue") {
		const total = tableData.map((item) => item.overdue).reduce((a, b) => a + b, 0)
		return total
	}
}

const LearningPathStatusTable = ({ orgId, learningPaths, roleUserGroups }: Props) => {
	const [dateRange] = useUrlQueryString("dateRange")
	const [userGroups] = useUrlQueryString("userGroups")
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [tableData, setTableData] = useState<StatusTable[] | undefined>(undefined)

	const getLearningPathUserTableData = async () => {
		try {
			const data = await fetchAnalytics(
				"userAnalytics",
				orgId,
				`learning-path/status/count`,
				// @ts-ignore
				dateRange,
				userGroups
			)
			setTableData(data)
			setIsLoading(false)
		} catch (err) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (dateRange) {
			getLearningPathUserTableData()
		}
	}, [dateRange, userGroups])

	if (isLoading) {
		return <CbLoading />
	}

	if (!tableData) {
		return <div>Error</div>
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-4">
					<ChartWrapper tooltip="" title="Total started">
						<SingleValueChart>{getTotal(tableData, "numStarted")}</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-4">
					<ChartWrapper tooltip="" title="Total completed">
						<SingleValueChart>{getTotal(tableData, "numCompleted")}</SingleValueChart>
					</ChartWrapper>
				</div>
				<div className="col-sm-4">
					<ChartWrapper tooltip="" title="Total overdue">
						<SingleValueChart>{getTotal(tableData, "numOverdue")}</SingleValueChart>
					</ChartWrapper>
				</div>
			</div>
			<div className="row" style={{ padding: 15 }}>
				<BasicTable
					columns={[
						{
							Header: "Learning path",
							accessor: "learningPath"
						},
						{
							Header: "Role",
							accessor: "userGroupNames"
						},
						{
							Header: "Num started",
							accessor: "numStarted"
						},
						{
							Header: "Num completed",
							accessor: "numCompleted"
						},
						{
							Header: "Num overdue",
							accessor: "numOverdue"
						},
						{
							Header: "",
							accessor: "link"
						}
					]}
					data={tableData.map((item) => {
						const learningPath = learningPaths.filter(
							(learningPaths) => learningPaths.id === item.learningPathId
						)[0]
						return {
							learningPath: learningPath.internalName,
							color:
								new LearningPathContent(learningPath).getColor() || chartColors[0 % chartColors.length],
							userGroupNames: new LearningPathContent(learningPath).getLearningPathRoles(roleUserGroups),
							numStarted: item.inProgress + item.completedLate + item.completedOnTime + item.overdue,
							numCompleted: item.completedOnTime + item.completedLate,
							numOverdue: item.overdue,
							link: `/org/${orgId}/analyse/learning-paths/progress?id=${item.learningPathId}&dateRange=${dateRange}`
						}
					})}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPaths: selectLearningPaths,
	roleUserGroups: selectRoleTypeUserGroups
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathStatusTable)
