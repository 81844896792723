// MODAL TO VIDEO CARD FOR COURSE BUILDER

import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Form, Button, Segmented, Input } from "antd"
import { UploadOutlined } from "@ant-design/icons"

import { selectIsUserAdmin } from "../../../../redux/user/user.selectors"
import { updateLessonCard } from "../../../../redux/courses/courses.actions"

import YouTube from "../icons/youtube.png"
import Vimeo from "../icons/vimeo.png"
import VideoUpload from "./VideoUpload"
import PanelContainer from "./common/PanelContainer"

const SegmentContainer = styled.div`
	padding: 4px;
	width: 73px;
`
const SegmentImg = styled.img`
	width: 32px;
	height: 32px;
	padding: 4px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

// VALIDATORS
// ---------

// see this GIST https://gist.github.com/takien/4077195 (example links for testing here https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486)
function getYouTubeId(url) {
	const urlParts = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	const parsedId = urlParts[2] ? urlParts[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line

	return parsedId
}

// taken from https://stackoverflow.com/questions/10488943/easy-way-to-get-vimeo-id-from-a-vimeo-url#answer-29637580
function getVimeoId(url) {
	const parsedId = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3]
	return parsedId
}

const VideoEditor = ({ card, isUserAdmin, updateLessonCard }) => {
	// State & Hooks
	// -------------
	const [videoType, setVideoType] = useState(card.videoType && card.videoType !== "" ? card.videoType : "youtube")
	const [disabledSave, setDisabledSave] = useState(true)
	const [form] = Form.useForm()

	const updateVideoUrl = (values) => {
		if (videoType === "youtube" && values.youtubeUrl) {
			const videoId = getYouTubeId(values.youtubeUrl)
			const videoThumbnail = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
			updateLessonCard({ ...card, videoType: videoType, videoId: videoId, videoThumbnail: videoThumbnail })
		} else if (videoType === "vimeo" && values.vimeoUrl) {
			const videoId = getVimeoId(values.vimeoUrl)
			fetch(`https://vimeo.com/api/v2/video/${videoId}.json`) // see https://codepen.io/isramv/pen/gOpabXg
				.then((response) => {
					return response.text()
				})
				.then((data) => {
					const { thumbnail_large } = JSON.parse(data)[0]
					const videoThumbnail = thumbnail_large
					updateLessonCard({
						...card,
						videoType: videoType,
						videoId: videoId,
						videoThumbnail: videoThumbnail
					})
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const handleFormChange = (val) => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length)
		setDisabledSave(hasErrors)
	}

	const handleVideoUpload = (videoType, videoId, videoThumbnail) => {
		updateLessonCard({
			...card,
			videoType: videoType,
			videoId: videoId,
			videoThumbnail: videoThumbnail
		})
	}

	const segmentOptions = [
		{
			label: (
				<SegmentContainer>
					<SegmentImg src={YouTube} />
					<div>YouTube</div>
				</SegmentContainer>
			),
			value: "youtube"
		},
		{
			label: (
				<SegmentContainer>
					<SegmentImg src={Vimeo} />
					<div>Vimeo</div>
				</SegmentContainer>
			),
			value: "vimeo"
		}
	]
	if (isUserAdmin) {
		segmentOptions.push({
			label: (
				<SegmentContainer>
					<UploadOutlined
						style={{
							fontSize: 20,
							color: "#B8B8B8",
							paddingTop: 4
						}}
					/>
					<div>Upload</div>
				</SegmentContainer>
			),
			value: "mux"
		})
	}

	// Component
	// ---------

	return (
		<>
			<PanelContainer title="Video type">
				<Segmented
					onChange={(val) => setVideoType(val)}
					defaultValue={videoType}
					options={segmentOptions}
					id="editor_video_type_select"
				/>
				<div style={{ marginTop: 12 }}>
					{["youtube", "vimeo"].includes(videoType) && (
						<Form
							size="large"
							autoComplete="off"
							initialValues={{
								youtubeUrl:
									card.videoType === "youtube"
										? `https://www.youtube.com/watch?v=${card.videoId}`
										: null,
								vimeoUrl:
									card.videoType === "vimeo" ? `https://player.vimeo.com/video/${card.videoId}` : null
							}}
							layout="vertical"
							onFieldsChange={(val) => handleFormChange(val)}
							form={form}
							onFinish={updateVideoUrl}
						>
							{videoType === "youtube" && (
								<Form.Item
									name="youtubeUrl"
									style={{ marginBottom: 10 }}
									rules={[
										{
											message: "You need to add a valid url",
											validator: (_, value) => {
												if (
													!/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
														value.trim()
													)
												) {
													return Promise.reject()
												} else {
													return Promise.resolve()
												}
											}
										}
									]}
								>
									<Input />
								</Form.Item>
							)}
							{videoType === "vimeo" && (
								<Form.Item
									name="vimeoUrl"
									style={{ marginBottom: 10 }}
									rules={[
										{
											message: "You need to add a valid url",
											validator: (_, value) => {
												if (
													!/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(
														value.trim()
													)
												) {
													return Promise.reject()
												} else {
													return Promise.resolve()
												}
											}
										}
									]}
								>
									<Input />
								</Form.Item>
							)}
							<Form.Item style={{ marginBottom: 0 }}>
								<ButtonContainer>
									<Button
										type="primary"
										shape="round"
										size="medium"
										htmlType="submit"
										disabled={disabledSave}
									>
										Replace video
									</Button>
								</ButtonContainer>
							</Form.Item>
						</Form>
					)}
					{videoType === "mux" && <VideoUpload handleVideoUpload={handleVideoUpload} />}
				</div>
			</PanelContainer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	isUserAdmin: selectIsUserAdmin
})

const mapDispatchToProps = (dispatch) => ({
	updateLessonCard: (updateParams) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoEditor)
