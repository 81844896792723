import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { updateLessonCard } from "../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../redux/courses/courses.selectors"

import * as S from "../../../styles/PageStyles"
import SegmentSetting from "./common/SegmentSetting"
import { faExpandArrowsAlt, faWindowMaximize } from "@fortawesome/free-solid-svg-icons"
import PanelContainer from "./common/PanelContainer"
import { FullscreenOutlined, StopOutlined } from "@ant-design/icons"
import { ImageCard } from "learnink-common/lib/domains/cards/lessons/image/ImageCard.model"

interface Props {
	card: ImageCard
	updateLessonCard: (updateParams: ImageCard) => void
}

const ImageSettings = ({ card, updateLessonCard }: Props) => {
	return (
		<>
			<PanelContainer title="Animation">
				<SegmentSetting
					onChange={(val: "popup" | "off") =>
						updateLessonCard({
							...card,
							imageSettings: {
								...card.imageSettings,
								animation: val
							}
						})
					}
					value={card.imageSettings?.animation === "popup" ? "popup" : "off"}
					id="animation"
					options={[
						{
							label: "Pop up",
							value: "popup",
							icon: <FullscreenOutlined style={S.editorIcon} />
						},
						{
							label: "Off",
							value: "off",
							icon: <StopOutlined style={S.editorIcon} />
						}
					]}
					style={{ marginBottom: 10 }}
				/>
				{card.imageSettings?.animation === "popup" && (
					<SegmentSetting
						onChange={(val: "fullScreen" | "fitScreen") =>
							updateLessonCard({
								...card,
								imageSettings: {
									...card.imageSettings,
									popupLayout: val
								}
							})
						}
						value={card.imageSettings?.popupLayout || "fitScreen"}
						id="popupLayout"
						options={[
							{ label: "Full screen", value: "fullScreen", faIcon: faExpandArrowsAlt },
							{ label: "Fit screen", value: "fitScreen", faIcon: faWindowMaximize }
						]}
					/>
				)}
			</PanelContainer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: ImageCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageSettings)
