import React from "react"
import styled from "styled-components"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

import EditableButton from "../common/EditableButton"
import EditableTextBox from "../common/EditableTextBox"
import { MultipleChoiceSurveyCard, SurveyOption } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"

const McSurveyContainer = styled.div`
	display: flex;
	flex-direction: column;
`
const AnswersContainer = styled.div`
	margin: 8px 0px;
	flex: 1;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`
const AddItemContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`

interface Props {
	card: MultipleChoiceSurveyCard
	updateLessonCard: (updateParams: MultipleChoiceSurveyCard) => void
}

const MultChoiceSurveyCardComponent = ({ card, updateLessonCard }: Props) => {
	const handleUpdateSurveyOptionText = (newText: string, index: number) => {
		const newSurveyOptions = card.surveyOptions ? [...card.surveyOptions] : []
		newSurveyOptions[index] = { ...newSurveyOptions[index], text: newText }
		updateLessonCard({
			...card,
			surveyOptions: newSurveyOptions
		})
	}

	const handleDeleteSurveyOption = (index: number) => {
		const newSurveyOptions = card.surveyOptions ? [...card.surveyOptions] : []
		newSurveyOptions.splice(index, 1)
		updateLessonCard({ ...card, surveyOptions: newSurveyOptions })
	}

	const handleAddSurveyOption = () => {
		const newSurveyOptions: SurveyOption[] = card.surveyOptions ? [...card.surveyOptions] : []
		newSurveyOptions.push({ text: "" } as SurveyOption)
		updateLessonCard({ ...card, surveyOptions: newSurveyOptions })
	}

	return (
		<McSurveyContainer>
			<EditableTextBox
				type={card.type}
				from={card.from}
				initialText={card.message || ""}
				setNewText={(val: string) => updateLessonCard({ ...card, message: val })}
				placeholderText="Enter text..."
				enableEmojiPicker
			/>
			<AnswersContainer>
				{card.surveyOptions &&
					card.surveyOptions.map((item, index) => (
						<EditableButton
							key={item.id}
							initialText={item.text}
							index={index}
							setButtonText={handleUpdateSurveyOptionText}
							deleteButtonPress={handleDeleteSurveyOption}
						/>
					))}
			</AnswersContainer>
			<AddItemContainer>
				<Button
					size="small"
					shape="round"
					icon={<PlusOutlined />}
					onClick={handleAddSurveyOption}
					id="survey_card_add_option_button"
				>
					Add option
				</Button>
			</AddItemContainer>
		</McSurveyContainer>
	)
}

export default MultChoiceSurveyCardComponent
