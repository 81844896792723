import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import axios from "axios"
import { Button, Form, Select, Typography, TreeSelect } from "antd"

import Icon from "../../../assets/images/icon.png"
import { selectOrgId, selectOrgInfo, selectOrgCourseTree } from "../../../redux/org/org.selectors"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"
import CbLoading from "../../common/CbLoading"

import TooltipIcon from "../../common/TooltipIcon"
const { Title } = Typography

const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
`
const NotificationContainer = styled.div`
	width: 100%;
	border-style: solid;
	border-width: 1px;
	border-color: var(--light-grey);
	border-radius: 5px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 12px;
	padding-bottom: 8px;
	margin-top: 10px;
	margin-bottom: 26px;
	background-color: white;
`
const FlexRowContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const MessageTitleInput = styled.input`
	margin: 0;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 44px;
	width: 100%;
	display: block;
	padding-top: 5px !important;
	padding-bottom: 2px !important;
	font-size: 16px !important;
	line-height: 44px;
	font-weight: 600 !important;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-width: 0px;
`
const MessageBodyInput = styled.textarea`
	margin: 0;
	background-color: transparent;
	color: rgb(62, 72, 84);
	height: 54px;
	width: 100%;
	display: block;
	padding: 0;
	padding-top: 2px !important;
	padding-bottom: 0px !important;
	font-size: 16px !important;
	line-height: 18px;
	font-weight: 400;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-width: 0px;
`
const HeaderText = styled.p`
	font-size: 14px;
	font-weight: 600;
	margin-left: 6px;
	color: var(--dark-grey);
`
const DescriptionText = styled.p`
	width: 100%;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
`
const UserDataContainer = styled.div`
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
	border: solid 1px var(--light-grey);
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
`
const UserText = styled.p`
	font-size: 14px;
	margin-left: 12px;
	margin-top: 8px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`

const SendUserNotificationModal = ({ orgId, orgInfo, usersData, cancelModal, courseTree, isUserAdmin, getUsers }) => {
	const [messageTitle, setMessageTitle] = useState("Hi! From " + orgInfo.org_name)
	// eslint-disable-next-line
	const [messageBody, setMessageBody] = useState(
		"Hi ${first_name} it's time to complete your training. ⏰ Take 5 minutes now to complete the course"
	)
	const [sendStatus, setSendStatus] = useState(null)
	const [sendStatusData, setSendStatusData] = useState(null)
	const [route, setRoute] = useState("Home")

	// update status to control modal view
	const showSendStatus = (status) => {
		setTimeout(() => {
			setSendStatus(status)
		}, 1000)
	}

	useEffect(getUsers, [])

	// function to send notification to user API
	const sendMessage = async (values) => {
		setSendStatus("sending")
		try {
			const body = {
				org_id: orgId,
				user_ids: usersData.map((x) => x.userId),
				message_title: messageTitle,
				message_body: messageBody
			}
			if (values.route === "Course" && values.courseId) {
				body.route = "Course"
				body.params = { courseId: values.courseId }
			}
			const { data } = await axios.post("/api/notifications/send", body)
			if (data && data.notificationsSent && data.notificationsFailed) {
				setSendStatusData(data)
				showSendStatus("sent")
			} else {
				showSendStatus("error")
			}
		} catch (err) {
			console.error(err)
			showSendStatus("error")
		}
	}

	return (
		<ModalInner>
			<>
				{sendStatus === null && (
					<>
						<Title level={3} style={{ marginBottom: 10 }}>
							Send a notification
						</Title>
						<Form
							autoComplete="off"
							onFinish={sendMessage}
							layout="vertical"
							size="large"
							style={{ width: "100%" }}
						>
							<Form.Item
								label={
									<FormLabel>
										Direct user to...{" "}
										<TooltipIcon tooltip="This is where your user will be taken to when they click on your notification" />
									</FormLabel>
								}
								name="route"
								rules={[{ required: true, message: "You can't leave this blank" }]}
							>
								<Select
									showArrow={true}
									style={{ width: "100%" }}
									value={route}
									options={[
										{ label: "Continue learning", value: "MyLearning" },
										{
											label: "A specific course (coming soon!)",
											value: "Course",
											disabled: !isUserAdmin
										}
									]}
									onChange={(value) => setRoute(value)}
									placeholder="Select an option..."
								/>
							</Form.Item>
							{route === "Course" && (
								<Form.Item
									label={
										<FormLabel>
											Select course{" "}
											<TooltipIcon tooltip="Select which course you want your user to be directed to" />
										</FormLabel>
									}
									name="courseId"
									rules={[{ required: true, message: "You can't leave this blank" }]}
								>
									<TreeSelect
										showSearch
										showArrow
										style={{ width: "100%" }}
										dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
										treeData={courseTree}
										placeholder="Select an option..."
										treeLine
										filterOption={(input, option) =>
											option.label.toLowerCase().includes(input.toLowerCase())
										}
									/>
								</Form.Item>
							)}
							<FormLabel>
								Edit your message...{" "}
								<TooltipIcon tooltip="Press below to edit the title and body of your notification" />
							</FormLabel>
							<NotificationContainer>
								<FlexRowContainer style={{ justifyContent: "flex-start" }}>
									<img src={Icon} alt={"learnink icon"} width={20} height={20} />
									<HeaderText>Learn.ink</HeaderText>
								</FlexRowContainer>
								<MessageTitleInput
									onChange={(e) => setMessageTitle(e.target.value)}
									value={messageTitle}
									type="text"
									maxLength="40"
								/>
								<MessageBodyInput
									onChange={(e) => setMessageBody(e.target.value)}
									value={messageBody}
									type="text"
									maxLength="132"
								/>
							</NotificationContainer>
							<Form.Item style={{ marginBottom: 0 }}>
								<ButtonContainer>
									<Button size="large" shape="round" onClick={cancelModal}>
										Cancel
									</Button>
									<div style={{ height: "100%", width: 10 }}></div>
									<Button type="primary" htmlType="submit" size="large" shape="round">
										Send
									</Button>
								</ButtonContainer>
							</Form.Item>
						</Form>
					</>
				)}
				{sendStatus === "sending" && (
					<>
						<Title level={3} style={{ marginBottom: 10 }}>
							Sending to {usersData.length} users...
						</Title>
						<CbLoading size={40} />
					</>
				)}
				{sendStatus === "sent" && (
					<>
						<Title level={3} style={{ marginBottom: 10 }}>
							Summary
						</Title>
						<DescriptionText
							style={{
								color: "var(--secondary-color)",
								fontWeight: "600"
							}}
						>
							{usersData.length - sendStatusData.notificationsFailed.length} messages sent,{" "}
							<span style={{ color: "var(--dark-red)" }}>
								{sendStatusData.notificationsFailed.length} messages failed
							</span>
						</DescriptionText>
						{sendStatusData.notificationsFailed.length > 0 && (
							<UserDataContainer>
								{sendStatusData.notificationsFailed.map((x, index) => (
									<UserText style={{ color: "var(--dark-red)" }} key={index}>
										•{" "}
										{usersData.filter((item) => item.userId === x.user_id).length > 0
											? usersData.filter((item) => item.userId === x.user_id)[0].customName
											: x.user_id}{" "}
										({x.error_info.message})
									</UserText>
								))}
							</UserDataContainer>
						)}
					</>
				)}
				{sendStatus === "error" && (
					<>
						<Title level={3} style={{ marginBottom: 10 }}>
							Error sending notifications
						</Title>
						<DescriptionText>
							Apologies, something went wrong while sending. Please try again later.
						</DescriptionText>
					</>
				)}
			</>
		</ModalInner>
	)
}

SendUserNotificationModal.propTypes = {
	orgId: PropTypes.string.isRequired,
	orgInfo: PropTypes.object.isRequired,
	usersData: PropTypes.array.isRequired,
	cancelModal: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgInfo: selectOrgInfo,
	courseTree: selectOrgCourseTree,
	isUserAdmin: selectIsUserAdmin
})

export default connect(mapStateToProps)(SendUserNotificationModal)
