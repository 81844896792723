export type PremiumFeature = "webhook" | "sequences" | "learningPaths"

export type Access = "hidden" | "locked" | "unlocked"

export type PremiumFeatureAccess = {
	[key in PremiumFeature]: Access
}

export const defaultPremiumFeatureAccess = {
	webhook: "hidden",
	sequences: "hidden",
	learningPaths: "locked"
}

export interface FeatureAccess {
	template_groups?: string[]
	premiumFeatureAccess?: PremiumFeatureAccess
}
