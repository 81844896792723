import { CardType } from "learnink-common/lib/domains/cards/Card.model"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"

export const generateTextBoxStyle = (type: CardType | "url" | undefined, from: TeacherOrLearner | undefined) => {
	if (!type) {
		return {
			borderRadius: "0px",
			border: "none",
			padding: "0px",
			background: "white"
		}
	}
	if (["multipleChoiceSurvey", "textInputSurvey", "numberInputSurvey", "text"].includes(type)) {
		if (from === "teacher") {
			return {
				borderRadius: "0px 18px 18px 18px",
				border: "1px solid var(--chat-bubble-left-border)",
				padding: "4px 4px",
				background: "var(--chat-bubble-left)"
			}
		}
		if (from === "learner") {
			return {
				borderRadius: "18px 0px 18px 18px",
				border: "1px solid var(--chat-bubble-right-border)",
				padding: "4px 4px",
				background: "var(--chat-bubble-right)"
			}
		}
	}
	if (type === "url") {
		if (from === "teacher") {
			return {
				borderRadius: "0px 18px 0px 0px",
				border: "1px solid var(--chat-bubble-left-border)",
				borderBottomWidth: 0,
				padding: "4px 4px",
				background: "var(--chat-bubble-left)"
			}
		}
		if (from === "learner") {
			return {
				borderRadius: "18px 0px 0px 0px",
				border: "1px solid var(--chat-bubble-right-border)",
				borderBottomWidth: 0,
				padding: "4px 4px",
				background: "var(--chat-bubble-right)"
			}
		}
	}
	if (type === "button") {
		if (from === "teacher") {
			return {
				borderRadius: "0px 0px 18px 18px",
				border: "1px solid var(--chat-bubble-left-border)",
				padding: "4px",
				background: "white",
				fontWeight: "bold"
			}
		}
		if (from === "learner") {
			return {
				borderRadius: "18px 0px 0px 0px",
				border: "1px solid var(--chat-bubble-right-border)",
				padding: "4px",
				background: "white",
				fontWeight: "bold"
			}
		}
	}
	if (type === "image") {
		return {
			borderRadius: "0px",
			border: "none",
			background: "none"
		}
	}
}
