// COURSE LIST PAGE

import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap, faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { Input, Button, Space } from "antd"

import {
	selectOrgCourses,
	selectOrgCourseCards,
	selectOrgId,
	selectOrgCourseFolders
} from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

import CourseCard from "../../components/courseBuilder/courses/CourseCard"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import FolderCard from "../../components/courseBuilder/courses/FolderCard"
import LpModal from "../../components/common/LpModal"
import CreateFolder from "../../components/courseBuilder/courses/modals/CreateFolder"
import LpBackButton from "../../components/common/LpBackButton"

const { Search } = Input

const CourseListContainer = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const CourseListInner = styled.div`
	height: 100%;
	display: table;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseCardContainer = styled.div`
	height: 220px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`
const FolderContainer = styled.div`
	height: 110px;
	width: 100%;
	float: left;
	padding: 0px 20px 0 0px;
	margin-bottom: 20px;
	@media (min-width: 850px) {
		width: 380px;
	}
	@media (min-width: 1110px) {
		width: 380px;
	}
`
const SubNavContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	box-sizing: border-box;
	width: 100%;
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: 100%;
	}
`
const SearchContainer = styled.div`
	flex: 1;
	margin: 4px;
	display: flex;
	flex-direction: row;
`
const MoreOptionsContainer = styled.div`
	flex: 1;
	margin: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`
const MoreOptionsItem = styled.div`
	margin: 4px 12px;
	color: var(--dark-grey);
	font-size: 13px;
	:hover {
		cursor: pointer;
		color: var(--secondary-color);
	}
`

const Courses = ({ orgId, courses, courseCards, courseFolders: { folder_data }, location: { pathname } }) => {
	const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
	const [archiveView, setArchiveView] = useState(false)
	const [searchText, setSearchText] = useState("")

	const childFolders = folder_data.filter((item) => item.course_folder_parent_id === null)
	const childCourses = courses
		.filter((item) => !courseCards[item].info.user_groups.access_groups.includes("archived"))
		.filter((courseId) => courseCards[courseId].course_folder_parent_id === null)
	const archivedCourses = courses.filter((item) =>
		courseCards[item].info.user_groups.access_groups.includes("archived")
	)
	const filteredCourses = courses
		.filter((item) => !courseCards[item].info.user_groups.access_groups.includes("archived"))
		.filter((item) => courseCards[item].info.name.toLowerCase().includes(searchText.toLowerCase()))

	if (archiveView) {
		return (
			<S.Container>
				<S.BackContainer>
					<LpBackButton backOnClick={() => setArchiveView(false)} />
				</S.BackContainer>
				<DashboardHeaderButton title={"Archived courses"} />
				<CourseListContainer>
					{archivedCourses.length > 0 ? (
						<CourseListInner>
							{archivedCourses.map((courseId, index) => (
								<CourseCardContainer key={index}>
									<CourseCard courseId={courseId} index={index} />
								</CourseCardContainer>
							))}
						</CourseListInner>
					) : (
						<CourseListInner>
							<GeNoItemsFound
								icon={<FontAwesomeIcon icon={faGraduationCap} />}
								titleText={"No archived courses"}
								subtitleText={"You haven't archived any courses..."}
							/>
						</CourseListInner>
					)}
				</CourseListContainer>
			</S.Container>
		)
	}

	return (
		<S.Container>
			<DashboardHeaderButton
				title={"Courses"}
				buttonTitle={"Create course"}
				buttonLink={`/org/${orgId}/create/course-templates`}
				secondaryButtonTitle={"Create folder"}
				secondaryButtonOnClick={() => setShowCreateFolderModal(true)}
			/>
			<SubNavContainer>
				<SearchContainer>
					<Space>
						<Search
							placeholder="Search courses..."
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							style={{ flex: 1 }}
						/>
						{searchText.length > 0 && (
							<Button type="primary" onClick={() => setSearchText("")}>
								Cancel
							</Button>
						)}
					</Space>
				</SearchContainer>

				<MoreOptionsContainer>
					<MoreOptionsItem onClick={() => setArchiveView(true)}>Archived courses</MoreOptionsItem>
					<Link to={`/org/${orgId}/create/courses/advanced-options`}>
						<MoreOptionsItem>
							<FontAwesomeIcon icon={faEllipsisV} />
						</MoreOptionsItem>
					</Link>
				</MoreOptionsContainer>
			</SubNavContainer>
			{searchText.length > 0 ? (
				<CourseListContainer>
					<CourseListInner></CourseListInner>
					<CourseListInner>
						{filteredCourses.map((courseId, index) => (
							<CourseCardContainer key={index}>
								<CourseCard courseId={courseId} index={index} />
							</CourseCardContainer>
						))}
					</CourseListInner>
				</CourseListContainer>
			) : (
				<>
					{childFolders.length === 0 && childCourses.length === 0 ? (
						<CourseListContainer>
							<CourseListInner>
								<GeNoItemsFound
									icon={<FontAwesomeIcon icon={faGraduationCap} />}
									titleText={"No courses found"}
									subtitleText={"Press the button to start creating your first course!"}
								/>
							</CourseListInner>
						</CourseListContainer>
					) : (
						<CourseListContainer>
							<CourseListInner>
								{childFolders.map((folder, index) => (
									<FolderContainer key={index}>
										<FolderCard orgId={orgId} folder={folder} pathname={pathname} />
									</FolderContainer>
								))}
							</CourseListInner>
							<CourseListInner>
								{childCourses.map((courseId, index) => (
									<CourseCardContainer key={index}>
										<CourseCard courseId={courseId} index={index} />
									</CourseCardContainer>
								))}
							</CourseListInner>
						</CourseListContainer>
					)}
				</>
			)}
			<LpModal isShowingModal={showCreateFolderModal} hideModal={() => setShowCreateFolderModal(false)}>
				<CreateFolder parentId={0} hideModal={() => setShowCreateFolderModal(false)} />
			</LpModal>
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	courses: selectOrgCourses,
	courseCards: selectOrgCourseCards,
	courseFolders: selectOrgCourseFolders,
	orgId: selectOrgId
})

export default connect(mapStateToProps)(Courses)
