import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons"

interface Props {
	fileUrl: string
}

const PlayAudioButton = ({ fileUrl }: Props) => {
	const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined)
	const [audioPlaying, setAudioPlaying] = useState<boolean>(false)

	// Cache-busting: add a query string to the URL to force fetching the latest file
	const getAudioUrlWithCacheBusting = (url: string) => {
		return `${url}?t=${new Date().getTime()}`
	}

	useEffect(() => {
		setAudioPlaying(false)
		const newAudio = new Audio(getAudioUrlWithCacheBusting(fileUrl))

		const handleError = () => {
			console.error("Error loading audio file:", fileUrl)
		}

		const handleEnded = () => {
			setAudioPlaying(false) // Set audioPlaying to false when audio ends
		}

		newAudio.addEventListener("error", handleError)
		newAudio.addEventListener("ended", handleEnded) // Add the ended event listener

		setAudio(newAudio)

		return () => {
			newAudio.pause()
			newAudio.removeEventListener("error", handleError)
			newAudio.removeEventListener("ended", handleEnded) // Remove the ended event listener
		}
	}, [fileUrl])

	const toggleAudio = () => {
		if (!audio) return

		if (audioPlaying) {
			audio.pause()
			audio.currentTime = 0
		} else {
			// Update the audio source with a cache-busting URL before playing
			audio.src = getAudioUrlWithCacheBusting(fileUrl)
			audio.play().catch((error) => console.error("Error playing audio:", error))
		}
		setAudioPlaying(!audioPlaying)
	}

	return (
		<Button
			icon={audioPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
			type="primary"
			onClick={toggleAudio}
			shape="circle"
		/>
	)
}

export default PlayAudioButton
