import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookOpen } from "@fortawesome/free-solid-svg-icons"

const { Title, Text } = Typography

const CourseListInner = styled.div`
	height: 100%;
	padding-top: 20px;
	box-sizing: border-box;
	width: 100%;
	min-width: 120px;
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: ${({ hasBorder }) => (hasBorder ? "1px" : "0px")};
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
	background: white;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 38px;
	line-height: 52px;
	padding: 0px 20px 10px 20px;
`

const GeNoItemsFound = ({ icon, titleText, subtitleText, hasBorder, children }) => {
	return (
		<CourseListInner>
			<CourseListEmpty hasBorder={hasBorder}>
				<IconText>{icon}</IconText>
				<Title level={4}>{titleText}</Title>
				{subtitleText && (
					<Text type="secondary" style={{ textAlign: "center" }}>
						{subtitleText}
					</Text>
				)}
				{children}
			</CourseListEmpty>
		</CourseListInner>
	)
}

GeNoItemsFound.propTypes = {
	icon: PropTypes.object,
	titleText: PropTypes.string,
	subtitleText: PropTypes.string,
	hasBorder: PropTypes.bool,
	children: PropTypes.node
}

GeNoItemsFound.defaultProps = {
	icon: <FontAwesomeIcon icon={faBookOpen} />,
	titleText: "No data",
	hasBorder: true,
	children: null
}

export default GeNoItemsFound
