import { Select, Space } from "antd"
import React from "react"
import _ from "lodash"

interface Props {
	column: {
		filterValue: string
		setFilter: (filter: string) => void
		preFilteredRows: any[]
		id: string
	}
}

export default function ShareRequestFilter({ column: { filterValue, setFilter, preFilteredRows } }: Props) {
	const options = React.useMemo(() => {
		const emptyOption = { value: "none", label: "none" }

		const senders = preFilteredRows
			.filter((row) => row.values.shareRequestSenders)
			.flatMap((row) => row.values.shareRequestSenders)

		const uniqueSenders = _.uniqBy(senders, (sender) => sender.id)
		const senderOptions = uniqueSenders.map((row) => ({ value: row.id, label: row.name }))

		return [emptyOption, ...senderOptions]

	}, [preFilteredRows])

	return (
		<Space
			direction="vertical"
			style={{
				marginTop: 4,
				marginRight: 8,
				marginBottom: 12,
				marginLeft: 8,
				width: "calc(100% - 20px)"
			}}
		>
			<Select
				showSearch
				mode="multiple"
				showArrow
				style={{ width: "100%" }}
				value={filterValue}
				options={options}
				onChange={setFilter}
				optionFilterProp="children"
				maxTagCount="responsive"
			/>
		</Space>
	)
}
