import { OrgActionTypes } from "./org.types"
import { setOrgSubscription } from "../subscription/subscription.actions"
import axios from "axios"
import posthog from "posthog-js"
import { auth } from "../../firebase/firebase.util"

export const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const setOrgData = (org_id) => (dispatch) => {
	// we handle fetching subscription state here
	dispatch(setOrgSubscription(org_id))

	updateFirebaseToken()
	// then get data for org
	axios
		.get(`/api/org/${org_id}`)
		.then((res) => {
			// set the org data here
			dispatch({
				type: OrgActionTypes.SET_ORG_DATA,
				payload: {
					...res.data
				}
			})
		})
		.catch((err) => {
			console.log(err)
		})
}

export const clearOrgData = () => (dispatch) => {
	dispatch({ type: OrgActionTypes.CLEAR_ORG_DATA })
	posthog.unregister("org_id")
}

export const setOrgTeam = (org_id) => (dispatch) => {
	updateFirebaseToken()
	axios
		.get(`/api/org/team/${org_id}`)
		.then((res) => {
			dispatch({
				type: OrgActionTypes.SET_ORG_TEAM,
				payload: res.data
			})
		})
		.catch((err) => {
			console.log(err)
		})
}

// update org info
export const updateOrgInfo = (org_id, new_org_info) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/org/${org_id}/info`, {
			new_org_info: new_org_info
		})
		dispatch({
			type: OrgActionTypes.SET_ORG_INFO,
			payload: res.data
		})
	} catch (err) {
		console.log(err)
	}
}

export const addImageToLibrary = (type, imageId) => async (dispatch) => {
	try {
		dispatch({
			type: OrgActionTypes.ADD_IMAGE_TO_LIBRARY,
			payload: {
				type,
				imageId
			}
		})
	} catch (err) {
		console.log(err)
	}
}

export const updateUserGroup = (orgId, userGroup) => async (dispatch) => {
	try {
		updateFirebaseToken()
		await axios.post(`/api/userGroup/${orgId}/${userGroup.id}`, userGroup)
		const userGroupsResult = await axios.get(`/api/userGroup/${orgId}`)
		dispatch({
			type: OrgActionTypes.SET_USER_GROUPS,
			payload: userGroupsResult.data.filter((item) => item.type === "role")
		})
	} catch (err) {
		console.log(err)
		throw err
	}
}

export const createLearningPath = (orgId, createParams, userGroup) => async (dispatch) => {
	updateFirebaseToken()
	// Create learning path API
	const createResponse = await axios.post(`/api/learningPath/${orgId}`, createParams)
	if (createResponse.data) {
		// Pull learning paths data
		const orgLearningPathsResult = await axios.get(`/api/learningPath/${orgId}`)
		// Connect learning path to user group
		userGroup.learningPaths.push({
			learningPathId: createResponse.data.id,
			isDefault: false
		})
		await axios.post(`/api/userGroup/${orgId}/${userGroup.id}`, userGroup)
		const userGroupsResult = await axios.get(`/api/userGroup/${orgId}`)
		dispatch({
			type: OrgActionTypes.SET_LEARNING_PATHS,
			payload: orgLearningPathsResult.data
		})
		dispatch({
			type: OrgActionTypes.SET_USER_GROUPS,
			payload: userGroupsResult.data.filter((item) => item.type === "role")
		})
		return createResponse.data
	} else {
		console.log("didn't get res.data...")
	}
}

export const publishLearningPath = (orgId, learningPath, updatedSequences) => async (dispatch) => {
	updateFirebaseToken()
	const createdSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "added")
	// Create new sequences in the backend
	const createdSequences =
		createdSequenceIds.length > 0
			? await Promise.all(
					learningPath.sequences
						.filter((sequence) => createdSequenceIds.includes(sequence.id))
						.map((sequence) => axios.post(`/api/sequence/${orgId}`, { ...sequence, id: undefined }))
			  )
			: []
	// Update existing sequences in the backend
	const updatedSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "updated")
	if (updatedSequenceIds.length > 0) {
		await Promise.all(
			learningPath.sequences
				.filter((sequence) => updatedSequenceIds.includes(sequence.id))
				.map((sequence) => axios.post(`/api/sequence/${orgId}/${sequence.id}`, sequence))
		)
	}
	// Set deleted sequences as inactive
	const deletedSequenceIds = Object.keys(updatedSequences).filter((item) => updatedSequences[item] === "deleted")
	if (deletedSequenceIds.length > 0) {
		await axios.post(`/api/sequence/${orgId}/inactive`, { orgId: orgId, sequenceIds: deletedSequenceIds })
	}
	// Formulate sequence ids for learning path
	const sequenceIds = []
	learningPath.sequences.forEach((sequence) => {
		if (createdSequenceIds.indexOf(sequence.id) >= 0) {
			const index = createdSequenceIds.indexOf(sequence.id)
			sequenceIds.push(createdSequences[index].data.id)
		} else if (updatedSequences[sequence.id] !== "deleted") {
			sequenceIds.push(sequence.id)
		}
	})
	const learningPathData = {
		internalName: learningPath.internalName,
		displayName: learningPath.displayName,
		theme: learningPath.theme,
		type: learningPath.type,
		sequenceIds: sequenceIds,
		onCompletion: learningPath.onCompletion
	}
	// Update learning path in backend
	await axios.post(`/api/learningPath/${orgId}/${learningPath.id}`, learningPathData)
	dispatch(setOrgData(orgId))
}

export const archiveLearningPath = (orgId, learningPath) => async (dispatch) => {
	updateFirebaseToken()
	// TO DO SET USER LEARNING PATHS TO INACTIVE
	if (learningPath.sequences.length > 0) {
		await axios.post(`/api/sequence/${orgId}/inactive`, {
			orgId: orgId,
			sequenceIds: learningPath.sequences.map((item) => item.id)
		})
	}
	await axios.put(`/api/learningPath/${orgId}/${learningPath.id}/archive`)
	dispatch(setOrgData(orgId))
}

export const unarchiveLearningPath = (orgId, learningPath) => async (dispatch) => {
	updateFirebaseToken()
	if (learningPath.sequences.length > 0) {
		await axios.post(`/api/sequence/${orgId}/active`, {
			orgId: orgId,
			sequenceIds: learningPath.sequences.map((item) => item.id)
		})
	}
	await axios.put(`/api/learningPath/${orgId}/${learningPath.id}/unarchive`)
	dispatch(setOrgData(orgId))
}
