// DASHBOARD SHOWING SUMMARY OF USER DATA ACROSS SEQUENCES

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { selectSequenceList } from "../../../redux/org/org.selectors"

import SequenceSummaryTable from "../charts/sequence-summary-dash/SequenceSummaryTable"
import UserGroupSummaryTable from "../charts/sequence-summary-dash/UserGroupSummaryTable"
import GeNoItemsFound from "../../common/GeNoItemsFound"
import ChartQuery from "../common/ChartQuery"

const SequenceSummaryDash = ({ sequenceList }) => {
	if (sequenceList.length > 0) {
		return (
			<div className="container-fluid">
				<ChartQuery route="sequences/user-group">
					<UserGroupSummaryTable />
				</ChartQuery>
				<ChartQuery route="sequences/sequence">
					<SequenceSummaryTable sequenceList={sequenceList} />
				</ChartQuery>
			</div>
		)
	} else
		return (
			<GeNoItemsFound
				titleText="No data"
				subtitleText="It looks like you haven't created any sequences yet"
				icon={<FontAwesomeIcon icon={faExclamationCircle} />}
			/>
		)
}

const mapStateToProps = createStructuredSelector({
	sequenceList: selectSequenceList
})

export default connect(mapStateToProps)(SequenceSummaryDash)
