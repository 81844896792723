// MODAL TO CREATE NEW LESSON CARD

import { Avatar } from "antd"
import React from "react"
import styled from "styled-components"

import CallOutDemo from "../cardTypeImages/callOut.png"

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 18px;
`
const Image = styled.div`
	border-radius: 0px 10px 10px 10px;
	background-color: white;
	border: 1.5px solid dodgerblue;
	width: 80px;
	height: 80px;
	margin-left: 5px;
	background-size: cover;
`

const Text = ({ teacherImg }) => {
	return (
		<Container>
			<Avatar src={teacherImg} size={20} />
			<Image style={{ backgroundImage: `url("${CallOutDemo}")` }} />
		</Container>
	)
}

export default Text
