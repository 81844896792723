import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import * as S from "../styles/PageStyles"
import { Form, Select } from "antd"
import { selectLearningPathById } from "../../redux/org/org.selectors"
import { ShortId } from "learnink-common/lib/tools/randomCharacterGenerators/ShortIdGenerator"
import moment from "moment"
import { LearningPathById } from "../../util/model/OrgSelectors"
import { LearningPathType } from "learnink-common/lib/domains/learningPaths/LearningPath.model"

const { Option } = Select

interface OptionRow {
	value: string
	label: string
	color: string
	type: LearningPathType | undefined
}

interface Props {
	filteredLearningPathIds?: string[]
	learningPathById: LearningPathById
	labelFontSize?: string
	tooltip?: string
	label?: string
}

const LearningPathSelector = ({ filteredLearningPathIds, learningPathById, labelFontSize, tooltip, label }: Props) => {
	const dateSelectorOptions = [
		{
			value: moment().startOf("day").toISOString(),
			label: "Today"
		},
		{
			value: moment().add(1, "day").startOf("day").toISOString(),
			label: "Tomorrow"
		}
	]
	const [learningPathId, setLearningPathId] = useState<ShortId | undefined>(undefined)

	const learningPathIds = filteredLearningPathIds || Object.keys(learningPathById)
	const options: OptionRow[] = []
	learningPathIds.forEach((learningPathId) => {
		if (learningPathById[learningPathId]) {
			options.push({
				value: learningPathId,
				label: learningPathById[learningPathId].internalName,
				color: learningPathById[learningPathId].color,
				type: learningPathById[learningPathId].type
			})
		}
	})

	return (
		<>
			<Form.Item
				label={
					<S.FormLabel labelFontSize={labelFontSize}>{label ? label : "Move to learning path"}</S.FormLabel>
				}
				name="learningPathId"
				key="learningPathId"
				rules={[{ required: true, message: "You need to select a learning path" }]}
				tooltip={tooltip || "Select a learning path"}
			>
				<Select onChange={(val) => setLearningPathId(val)} value={learningPathId}>
					{options.map((option, index) => (
						<Option value={option.value} key={index}>
							<span
								className="color-selector"
								style={{
									fontSize: 13,
									color: "white",
									background: option.color,
									padding: "3px 10px 3px 10px"
								}}
							>
								{option.label}
							</span>
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label={<S.FormLabel labelFontSize={labelFontSize}>Select start time</S.FormLabel>}
				name="learningPathStartDate"
				key="learningPathStartDate"
				rules={[{ required: true, message: "You need to select a start date" }]}
				tooltip={"Select which date you want your users to start the path on"}
			>
				<Select options={dateSelectorOptions} placeholder="Select start time" />
			</Form.Item>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	learningPathById: selectLearningPathById
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathSelector)
