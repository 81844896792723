// MODAL TO EDIT FOLDER

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button, Space, Form, Input, Select } from "antd"
import PropTypes from "prop-types"

import { folderColors } from "../../../analyse/common/colors"
import { updateCourseFolder } from "../../../../redux/courses/courses.actions"
import { selectOrgId } from "../../../../redux/org/org.selectors"

const { Option } = Select

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`

const RenameFolder = ({ closeModal, orgId, folder_id, folder_name, folder_color, updateCourseFolder }) => {
	const handleSubmit = async (values) => {
		try {
			await updateCourseFolder(orgId, folder_id, {
				folder_name: values.folder_name,
				folder_color: values.folder_color
			})
			closeModal()
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<Form
			autoComplete="off"
			onFinish={handleSubmit}
			layout="vertical"
			initialValues={{
				folder_name,
				folder_color
			}}
		>
			<Form.Item
				name="folder_name"
				rules={[
					{
						required: true,
						message: "You need to add a folder name"
					}
				]}
				label={<FormLabel>Folder name</FormLabel>}
				tooltip="Make sure you use something descriptive!"
			>
				<Input placeholder="Enter folder name..." />
			</Form.Item>
			<Form.Item label={<FormLabel>Folder colour</FormLabel>} name="folder_color">
				<Select style={{ width: 100 }}>
					{folderColors.map((value, index) => (
						<Option value={value} key={index}>
							<span
								className="color-selector"
								style={{
									fontSize: 12,
									color: value,
									background: value,
									padding: "2px 10px 2px 10px"
								}}
							>
								color
							</span>
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item style={{ marginBottom: 0 }}>
				<ButtonContainer>
					<Space>
						<Button shape="round" size="large" onClick={closeModal}>
							Cancel
						</Button>
						<Button type="primary" shape="round" size="large" htmlType="submit">
							Confirm
						</Button>
					</Space>
				</ButtonContainer>
			</Form.Item>
		</Form>
	)
}

PropTypes.RenameFolder = {
	closeModal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	folder_name: PropTypes.string.isRequired,
	folder_color: PropTypes.string.isRequired,
	updateCourseFolder: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	updateCourseFolder: (org_id, folder_id, folder_data) => dispatch(updateCourseFolder(org_id, folder_id, folder_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RenameFolder)
