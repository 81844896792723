import React from "react"

import BasePieChart from "../../common/BasePieChart"
import BaseBarChart from "../../common/BaseBarChart"
import { chartColors } from "../../common/colors"

const ActiveUserCharts = ({ queryData }) => {
	const activeUsersFemale = queryData.map((item) => item.female).reduce((partialSum, a) => partialSum + a, 0)
	const activeUsersMale = queryData.map((item) => item.male).reduce((partialSum, a) => partialSum + a, 0)
	const pieChartData =
		queryData.length > 0
			? [
					{
						x: "female",
						count: activeUsersFemale,
						color: chartColors[0 % chartColors.length]
					},
					{
						x: "male",
						count: activeUsersMale,
						color: chartColors[1 % chartColors.length]
					}
			  ]
			: []
	return (
		<div className="row">
			<div className="col-sm-4">
				<BasePieChart
					data={pieChartData}
					title="Active users"
					tooltip="Users who were active within the selected timeframe broken down by sex"
					showLegend
					height={250}
				/>
			</div>
			<div className="col-sm-8">
				<BaseBarChart
					data={queryData}
					dataKey="ageBracket"
					series={["female", "male"]}
					title="Active users by age"
					tooltip="Users who were active within the selected timeframe broken down by sex"
					showLegend
					showYAxis
					height={250}
				/>
			</div>
		</div>
	)
}

export default ActiveUserCharts
