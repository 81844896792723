// STANDARD COURSE CARD

import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Typography } from "antd"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faEllipsisV, faShare, faChartLine } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId, selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

import CbCourseStatusLabel from "../../common/CbCourseStatusLabel"
import CbCourseCardImage from "../../common/CbCourseCardImage"
import GeModal from "../../common/GeModal"
import GeDropdown from "../../common/GeDropdown"
import ShareCourse from "./modals/ShareCourse"
import ArchiveCourse from "./modals/ArchiveCourse"
import UnarchiveCourse from "./modals/UnarchiveCourse"
import DownloadCourse from "./modals/DownloadCourse"
import DuplicateCourse from "./modals/DuplicateCourse"
import DeleteCourse from "./modals/DeleteCourse"
import TranslateCourse from "./modals/TranslateCourse"
import MoveToFolder from "./modals/MoveToFolder"
import UserGroupsTags from "../../common/UserGroupsTags"
import LpModal from "../../common/LpModal"

const { Title, Paragraph } = Typography

const CourseContentContainer = styled.div`
	width: 100%;
	height: 145px;
	border-bottom: solid 1px var(--v-light-grey);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
const CourseContentTextContainer = styled.div`
	flex: 1;
	height: 150px;
	width: 200px;
	padding: 6px;
	padding-top: 18px;
	padding-right: 30px;
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
const CourseCardFooter = styled.div`
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	padding-right: 6px;
`
const HeaderMenuButton = styled.button`
	background: none;
	margin: 10px 15px;
	font-size: 22px;
	color: var(--light-grey);
`
const CourseCardContainer = styled.div`
	height: 200px;
	width: 100%;
	background: white;
	display: block;
	box-shadow: 1px 2px 2px var(--light-grey);
	border: solid 1px var(--light-grey);
	border-radius: 8px;
	margin-bottom: 20px;
	position: relative;
`
const CardOverlayButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99%;
	width: 70px;
	height: 70px;
	text-transform: uppercase;
	background-color: var(--secondary-color);
	color: white;
	font-size: 24px;
	font-weight: 600;
	overflow: hidden;
	cursor: pointer;
	text-overflow: ellipsis;
	transition: 0.5s ease;
	:hover {
		background-color: var(--secondary-color-highlight);
	}
`
const CardTextOverlayButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 38px;
	background-color: var(--secondary-color);
	color: white;
	font-size: 16px;
	font-weight: 500;
	overflow: hidden;
	cursor: pointer;
	padding: 10px 38px;
	text-overflow: ellipsis;
	transition: 0.5s ease;
	:hover {
		background-color: var(--secondary-color-highlight);
	}
`
const CardOverlayTopMenuContainer = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row-reverse;
`
const CardOverlayButtonsContainer = styled.div`
	flex: 1;
	display: flex;
	opacity: 0;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 48px;
	padding-left: 16px;
	padding-right: 16px;
`
const CardOverlayContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	transition: 0.5s ease;
	background-color: rgba(0, 0, 0, 0);
	:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
	:hover ${CardOverlayButtonsContainer} {
		opacity: 1;
	}
	:hover ${HeaderMenuButton} {
		color: white;
	}
`

const CourseCard = ({ orgId, courseCards, courseId, isUserAdmin }) => {
	const [showModal, setShowModal] = useState(false)
	// pull out key values
	const course_name = courseCards[courseId].info.name
	const short_description = courseCards[courseId].info.short_description
	const course_image = courseCards[courseId].info.image
	const preview_url = courseCards[courseId].info.preview_url
	const publish_url = courseCards[courseId].info.publish_url
	const user_groups = courseCards[courseId].info.user_groups
	const access_groups = user_groups && user_groups.access_groups ? user_groups.access_groups : ["registered"]

	const courseIsArchived = access_groups.includes("archived")

	const dropdownTrigger = (
		<HeaderMenuButton>
			<FontAwesomeIcon icon={faEllipsisV} />
		</HeaderMenuButton>
	)
	const dropdownArray = [
		<GeModal
			triggerEl={<div>Duplicate</div>}
			children={<DuplicateCourse courseId={courseId} courseName={course_name} />}
		/>,
		<GeModal triggerEl={<div>Download</div>} children={<DownloadCourse courseId={courseId} />} />,
		<GeModal
			triggerEl={<div>Archive</div>}
			children={<ArchiveCourse courseId={courseId} courseName={course_name} />}
		/>,
		<GeModal triggerEl={<div>Move...</div>} children={<MoveToFolder id={courseId} type="course" />} />
	]

	// SETTING TRANSLATOR BUTTON TO ONLY BE VIEWABLE FOR CORE TEAM MEMBERS
	if (isUserAdmin) {
		dropdownArray.push(
			<GeModal triggerEl={<div>Translate</div>} children={<TranslateCourse courseId={courseId} />} />
		)
	}

	const dropdownArchiveArray = [
		<GeModal
			triggerEl={<div>Delete</div>}
			children={<DeleteCourse courseId={courseId} courseName={course_name} />}
		/>
	]

	return (
		<>
			<CourseCardContainer>
				<CardOverlayContainer>
					{!courseIsArchived ? (
						<>
							<CardOverlayTopMenuContainer>
								<GeDropdown dropDownTrigger={dropdownTrigger} dropdownElements={dropdownArray} />
							</CardOverlayTopMenuContainer>

							<CardOverlayButtonsContainer>
								<Link to={`/org/${orgId}/create/course/${courseId}`}>
									<CardOverlayButton>
										<FontAwesomeIcon icon={faPencilAlt} />
									</CardOverlayButton>
								</Link>
								{!publish_url && preview_url && (
									<CardOverlayButton onClick={() => setShowModal(true)}>
										<FontAwesomeIcon icon={faShare} />
									</CardOverlayButton>
								)}
								{publish_url && (
									<CardOverlayButton onClick={() => setShowModal(true)}>
										<FontAwesomeIcon icon={faShare} />
									</CardOverlayButton>
								)}
								{publish_url && (
									<Link to={`/org/${orgId}/analyse/courses/insights?courseId=${courseId}`}>
										<CardOverlayButton>
											<FontAwesomeIcon icon={faChartLine} />
										</CardOverlayButton>
									</Link>
								)}
							</CardOverlayButtonsContainer>
						</>
					) : (
						<>
							<CardOverlayTopMenuContainer>
								<GeDropdown dropDownTrigger={dropdownTrigger} dropdownElements={dropdownArchiveArray} />
							</CardOverlayTopMenuContainer>
							<CardOverlayButtonsContainer>
								<GeModal
									triggerEl={<CardTextOverlayButton>Unarchive</CardTextOverlayButton>}
									children={<UnarchiveCourse courseId={courseId} courseName={course_name} />}
								/>
							</CardOverlayButtonsContainer>
						</>
					)}
				</CardOverlayContainer>

				{/* This is normal visible card */}
				<CourseContentContainer>
					<CbCourseCardImage backgroundColor={"var(--secondary-color)"} image={course_image} />

					<CourseContentTextContainer>
						<Title level={5} ellipsis={{ rows: 2 }} style={{ marginBottom: 5 }}>
							{course_name}
						</Title>
						<Paragraph type="secondary" ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
							{short_description}
						</Paragraph>
					</CourseContentTextContainer>
				</CourseContentContainer>

				<CourseCardFooter>
					<CbCourseStatusLabel
						isPreviewable={!!preview_url}
						isPublished={!!publish_url}
						isArchived={courseIsArchived}
					/>
					{!courseIsArchived && (
						<div>
							<UserGroupsTags limit={1} selectedUserGroups={access_groups} />
						</div>
					)}
				</CourseCardFooter>
			</CourseCardContainer>
			<LpModal isShowingModal={showModal} hideModal={() => setShowModal(false)} maxWidth="600px">
				<div style={{ padding: 30 }}>
					<ShareCourse
						courseType={publish_url ? "publish" : "preview"}
						previewUrl={preview_url}
						publishUrl={publish_url}
						accessGroups={access_groups}
					/>
				</div>
			</LpModal>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	courseCards: selectOrgCourseCards,
	isUserAdmin: selectIsUserAdmin
})

PropTypes.CourseCard = {
	orgId: PropTypes.string.isRequired,
	courseCards: PropTypes.object.isRequired,
	courseId: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired
}

export default connect(mapStateToProps)(CourseCard)
