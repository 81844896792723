import React, { useEffect, useState } from "react"
import { Button, Dropdown, Menu } from "antd"
import {
	InputType,
	Filter,
	FilterOption,
	Option,
	UserGroupLabels,
	FilterableMember,
	COLUMN_LABEL_BY_MEMBER,
	IGNORED_USER_GROUPS
} from "./UserSearch.model"
import ActiveFilter from "./ActiveFilter"
import { FilterFilled } from "@ant-design/icons"
import InactiveFilter from "./InactiveFilter"
import { createStructuredSelector } from "reselect"
import { selectLearningPaths, selectOrgId, selectOrgUserGroupNamesMap } from "../../redux/org/org.selectors"
import { connect } from "react-redux"
import { AgeBracket, RegistrationStatus, Sex } from "learnink-common/lib/domains/userManagement/users/User.model"
import { UserOptions } from "learnink-common/lib/domains/userManagement/userSearch/UserSearchParams.model"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import {
	Member,
	AgeBracketFilter,
	DateFilter,
	RegistrationStatusFilter,
	SexFilter,
	StringArrayFilter,
	StringFilter,
	Operator,
	BooleanFilter
} from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { EnumValues, Values } from "zod"
import { useApi } from "../../util/useApi"
import Routes from "../../util/Routes"
import { DEFAULT_FILTERS } from "./UserSearchPage"

interface Props {
	orgId: string
	setFilters: (filters: Filter[]) => void
	filters: Filter[]
	userGroups: UserGroupLabels
	learningPaths: LearningPath[] | undefined
}

function UserSearchFilters({ orgId, setFilters, filters, userGroups, learningPaths }: Props) {
	const [filterOptions, setFilterOptions] = useState<FilterOption[]>([])
	const [currentActiveFilterIndex, setCurrentActiveFilterIndex] = useState<number | undefined>(undefined)

	const { setApiRequest: optionsRequest, data: apiOptions } = useApi<undefined, UserOptions>()

	useEffect(() => {
		optionsRequest({ route: Routes.userSearchOptions, urlParams: { orgId } })
	}, [])

	const toFilterOption = (filter: Filter): FilterOption => {
		const filterOption = DEFAULT_FILTER_OPTION_BY_MEMBER[filter.member]
		filterOption.selectedOperator = filter.operator

		filterOption.values = filter.values.map((value, index) => ({
			value: value,
			label: filter.valueLabels?.[index]?.toString() ?? value
		}))
		return filterOption
	}

	/** We try and parse the filters, but if they're corrupt, we set to the defaults */
	const handleSafeSetFilterOptions = () => {
		try {
			setFilterOptions(filters.map(toFilterOption))
		} catch (e) {
			setFilters(DEFAULT_FILTERS)
		}
	}

	useEffect(handleSafeSetFilterOptions, [filters])

	const options = {
		status: RegistrationStatus.options.map(valueToOption),
		sex: Sex.options.map(valueToOption),
		age: AgeBracket.options.map(valueToOption),
		country: (apiOptions?.countries || []).filter((x) => x).map(valueToOption),
		region: (apiOptions?.regions || []).filter((x) => x).map(valueToOption),
		learningPath: (learningPaths || []).map((l) => ({ value: l.id, label: l.internalName })),
		downloadedApp: [{ value: "true", label: "is downloaded" }], // We don't actually map this to "true"/"false" in the backend, but "exists" or "not exists" from the operator, so the boolean value is unused
		sender: (apiOptions?.shareRequestSenders || []).map(({ id, name }) => ({ value: id, label: name })),
		userGroup: Object.entries(userGroups)
			.filter(([key]) => !IGNORED_USER_GROUPS.includes(key))
			.map(([key, value]) => ({ value: key, label: value }))
	}

	const DEFAULT_FILTER_OPTION_BY_MEMBER: { [key in FilterableMember]: FilterOption } = {
		full_name: getFilterOption(Member.enum.full_name, StringFilter, "stringInput"),
		registration_status: getFilterOption(
			Member.enum.registration_status,
			RegistrationStatusFilter,
			"selectMultiple",
			options.status
		),
		share_request_sender_ids: getFilterOption(
			Member.enum.share_request_sender_ids,
			StringArrayFilter,
			"selectMultiple",
			options.sender
		),
		user_groups: getFilterOption(Member.enum.user_groups, StringArrayFilter, "selectMultiple", options.userGroup),
		learningpath_id: getFilterOption(
			Member.enum.learningpath_id,
			StringFilter,
			"selectMultiple",
			options.learningPath
		),
		first_name: getFilterOption(Member.enum.first_name, StringFilter, "stringInput"),
		last_name: getFilterOption(Member.enum.last_name, StringFilter, "stringInput"),
		last_active: getFilterOption(Member.enum.last_active, DateFilter, "datePicker"),
		custom_id: getFilterOption(Member.enum.custom_id, StringFilter, "stringInput"),
		user_id: getFilterOption(Member.enum.user_id, StringFilter, "stringInput"),
		phone_number: getFilterOption(Member.enum.phone_number, StringFilter, "stringInput"),
		sex: getFilterOption(Member.enum.sex, SexFilter, "selectMultiple", options.sex),
		country: getFilterOption(Member.enum.country, StringFilter, "selectMultiple", options.country),
		region: getFilterOption(Member.enum.region, StringFilter, "selectMultiple", options.region),
		age_bracket: getFilterOption(Member.enum.age_bracket, AgeBracketFilter, "selectMultiple", options.age),
		app_downloaded: getFilterOption(
			Member.enum.app_downloaded,
			BooleanFilter,
			"selectMultiple",
			options.downloadedApp
		),
		registered_at: getFilterOption(Member.enum.registered_at, DateFilter, "datePicker")
	}

	const AddNewFilterButton = () => {
		const ColumnFilterButton = ({ member }: { member: FilterableMember }) => {
			return (
				<div
					onClick={() => {
						const newFilterOptions = [...filterOptions]
						newFilterOptions.push(DEFAULT_FILTER_OPTION_BY_MEMBER[member])
						setFilterOptions(newFilterOptions)
						setCurrentActiveFilterIndex((filters || []).length)
					}}
				>
					{COLUMN_LABEL_BY_MEMBER[member]}
				</div>
			)
		}

		const filterMenu = FilterableMember.options.map((member) => ({
			label: <ColumnFilterButton member={member} />,
			key: member.replaceAll(" ", "-")
		}))

		return (
			<Dropdown overlay={<Menu items={filterMenu} />} trigger={["click"]}>
				<Button icon={<FilterFilled />}>Add filter</Button>
			</Dropdown>
		)
	}

	const setFilter = (index: number) => {
		return (filter: Filter) => {
			const newFilters = [...filters]
			newFilters[index] = filter
			setFilters(newFilters)
			setCurrentActiveFilterIndex(undefined)
		}
	}

	const removeFilter = (index: number) => {
		return () => {
			const newFilters = [...filters]
			newFilters.splice(index, 1)
			setFilters(newFilters)
			setCurrentActiveFilterIndex(undefined)
		}
	}

	const setFilterOption = (index: number) => {
		return (filterOption: FilterOption) => {
			const newFilterOptions = [...filterOptions]
			newFilterOptions[index] = filterOption
			setFilterOptions(newFilterOptions)
		}
	}

	return (
		<>
			{filterOptions.map((f, index) => {
				if (currentActiveFilterIndex === index) {
					return (
						<ActiveFilter
							key={"active_" + index}
							filterOption={f}
							isFirstFilter={index === 0}
							setFilterOption={setFilterOption(index)}
							setFilter={setFilter(index)}
							removeFilter={removeFilter(index)}
						/>
					)
				}

				if (filters[index]) {
					return (
						<InactiveFilter
							key={"inactive_" + index}
							filter={filters[index]}
							isFirstFilter={index === 0}
							removeFilter={removeFilter(index)}
							setActive={() => setCurrentActiveFilterIndex(index)}
						/>
					)
				}

				return <></>
			})}

			{currentActiveFilterIndex === undefined && <AddNewFilterButton />}
		</>
	)
}

/** Just some Zod typing for the things we are going to use, so we don't have to type the whole thing... */
interface FilterSchema {
	shape: {
		member: {
			enum: Values<EnumValues> // If we could type this completely so that FILTER_BY_COLUMN_LABEL is properly typed, that would be nice...
		}
		operator: {
			options: Operator[]
		}
	}
}

function getFilterOption(
	key: Member,
	filterSchema: FilterSchema,
	inputType: InputType,
	options?: Option[]
): FilterOption {
	const member = filterSchema.shape.member.enum[key]
	if (!member)
		throw new Error(
			`No member found for filter, backend definition may have changed. key: ${key} filter: ${filterSchema}`
		)

	const operators = filterSchema.shape.operator.options.filter(
		(o) => !IGNORED_INPUT_TYPE_OPERATORS[inputType].includes(o)
	)
	return {
		member: member as FilterableMember,
		operators: operators,
		selectedOperator: operators[0],
		inputType,
		options
	}
}

const IGNORED_INPUT_TYPE_OPERATORS: { [key in InputType]: Operator[] } = {
	datePicker: [Operator.enum.in, Operator.enum.notIn],
	numberInput: [Operator.enum.in, Operator.enum.notIn],
	stringInput: [Operator.enum.in, Operator.enum.notIn],
	selectMultiple: []
}

function valueToOption(value: string): Option {
	return { value, label: value }
}

export function operatorRequiresValue(operator: Operator): boolean {
	return !(
		[Operator.enum.hasValue, Operator.enum.hasNoValue, Operator.enum.empty, Operator.enum.notEmpty] as Operator[]
	).includes(operator)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userGroups: selectOrgUserGroupNamesMap,
	learningPaths: selectLearningPaths
})

// @ts-ignore
export default connect(mapStateToProps)(UserSearchFilters)
