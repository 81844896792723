import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VideoCameraAddOutlined } from "@ant-design/icons"

import { faPlay } from "@fortawesome/free-solid-svg-icons"

import LpModal from "../../../../common/LpModal"
import useLpModal from "../../../../hooks/useLpModal"
import VideoPlayer from "../VideoPlayer"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"
import { VideoCard } from "learnink-common/lib/domains/cards/lessons/video/VideoCard.model"

const ImageContainer = styled.div<{ from?: TeacherOrLearner }>`
	position: relative;
	width: 100%;
	min-height: 140px;
	height: auto;
	padding: 8px 8px;
	border: ${({ from }) =>
		from === "teacher" ? "1px solid var(--chat-bubble-left-border)" : "1px solid var(--chat-bubble-right-border)"};
	border-radius: ${({ from }) => (from === "teacher" ? "0px 30px 30px 30px" : "30px 0px 30px 30px")};
	background: ${({ from }) => (from === "teacher" ? "var(--chat-bubble-left)" : "var(--chat-bubble-right)")};
	font-size: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
`
const Image = styled.img`
	border-radius: 22px;
	width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
	border: 0;
`
const PlayVideoButton = styled.button`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	background-color: var(--secondary-color);
	opacity: 0.7;
	border-radius: 50%;
	display: inline-block;
	color: white;
	padding-left: 6px;
	font-size: 34px;
	font-weight: 800;
	line-height: 19px;
	cursor: pointer;
`
const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	width: 100%;
`

interface Props {
	card: VideoCard
}

const VideoCardComponent = ({ card }: Props) => {
	const { isShowingModal, toggleModal } = useLpModal()

	return (
		<ImageContainer from={card.from}>
			{card.videoId ? (
				<>
					<Image src={card.videoThumbnail} alt="card image" />
					<PlayVideoButton onClick={toggleModal}>
						<FontAwesomeIcon icon={faPlay as IconProp} color="white" />
					</PlayVideoButton>

					<LpModal isShowingModal={isShowingModal} hideModal={toggleModal}>
						<ModalInner>
							<VideoPlayer videoType={card.videoType} videoId={card.videoId} />
						</ModalInner>
					</LpModal>
				</>
			) : (
				<VideoCameraAddOutlined />
			)}
		</ImageContainer>
	)
}

export default VideoCardComponent
