import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Form, InputNumber, Space } from "antd"
import * as S from "../../styles/PageStyles"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import FormModal from "../../common/FormModal"
import { selectOrgImg } from "../../../redux/org/org.selectors"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"

interface Props {
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	handleSubmit: (learningPath: LearningPath) => void
	learningPath: LearningPath
}

const EditLearningPathTimeline = ({ setModalType, handleSubmit, learningPath }: Props) => {
	const [strictlyOrdered, setStrictlyOrdered] = useState<boolean>(learningPath.sequences[0].strictlyOrdered)

	const updateLearningPath = (val: { numDays: number }) => {
		const updatedSequences: Sequence[] = []
		learningPath.sequences.forEach((sequence: Sequence, index: number) => {
			updatedSequences.push({
				...sequence,
				deadlineDay: val.numDays - 1,
				strictlyOrdered: strictlyOrdered,
				staggerProgress: false,
				unlockTrigger: index === 0 ? "start_learning_path" : "finish_previous",
				numDays: val.numDays,
				unlockDelayDays: 0
			})
		})
		const updatedLearningPath = {
			...learningPath,
			sequences: updatedSequences
		}
		handleSubmit(updatedLearningPath)
	}

	const formElements = (
		<>
			<S.FlexBoxStartRow>
				<Space>
					<S.FormLabel>Complete learning path in</S.FormLabel>
					<Form.Item name="numDays" key="numDays">
						<InputNumber min={1} style={{ marginTop: 22 }} />
					</Form.Item>
					<S.FormLabel>days</S.FormLabel>
				</Space>
			</S.FlexBoxStartRow>
			<S.FlexBoxStartRow style={{ marginTop: 10, marginBottom: 10 }}>
				<BigOnOffButton
					key="sequence"
					isOn={strictlyOrdered}
					color="orange"
					text="Lock course until previous is complete"
					faIcon={faLock}
					onClick={() => setStrictlyOrdered(true)}
					width={200}
				/>
				<BigOnOffButton
					key="course"
					isOn={!strictlyOrdered}
					color="orange"
					text="Unlock all courses at start"
					faIcon={faUnlock}
					onClick={() => setStrictlyOrdered(false)}
					width={200}
				/>
			</S.FlexBoxStartRow>
		</>
	)

	return (
		<FormModal
			title="Edit schedule"
			isModalOpen={true}
			closeModal={() => setModalType("none")}
			modalState="confirm"
			handleSubmit={updateLearningPath}
			formElements={formElements}
			submitButtonTitle="Save"
			initialValues={{
				numDays: learningPath.sequences[learningPath.sequences.length - 1].deadlineDay + 1
			}}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgImage: selectOrgImg
})

export default connect(mapStateToProps)(EditLearningPathTimeline)
