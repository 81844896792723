import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import axios from "axios"
import { Alert, Button, Space, Form, Select, Typography } from "antd"

import { selectOrgId, selectOrgUserGroupNamesMap } from "../../../redux/org/org.selectors"
import { bulkEditUserGroupState } from "../../../redux/management/management.actions"

import UserGroupsSelector from "../../common/UserGroupsSelector"
import CbLoading from "../../common/CbLoading"

const { Title } = Typography

const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 20px;
`

const options = [
	{
		value: "add",
		label: "Add user group"
	},
	{
		value: "remove",
		label: "Remove user group"
	},
	{
		value: "replace",
		label: "Replace user group"
	}
]

const EditUserGroupsModal = ({
	orgId,
	usersData,
	userGroupsNamesMap,
	cancelModal,
	bulkEditUserGroupState,
	getUsers,
	refreshUsers
}) => {
	const [selectedAction, setSelectedAction] = useState(null)
	const [selectedGroups, setSelectedGroups] = useState(null)
	const [message, setMessage] = useState(null)
	const [buttonDisabled, setButtonDisabled] = useState(true)
	const [uiStatus, setUiStatus] = useState("default")
	const [responseData, setResponseData] = useState(null)

	// update status to control modal view
	const delayUiUpdateWith = (status) => {
		setTimeout(() => {
			setUiStatus(status)
		}, 300)
	}

	const delayUserListUpdate = (data) => {
		setTimeout(() => {
			bulkEditUserGroupState(data, userGroupsNamesMap)
		}, 2000)
	}

	useEffect(getUsers, [])

	// function to send notifcation to user API
	const handleSubmit = async () => {
		setUiStatus("loading")
		try {
			const { data } = await axios.put(`/api/management/${orgId}/users/user-groups`, {
				action: selectedAction,
				user_groups: selectedGroups,
				user_ids: usersData.map((x) => x.userId)
			})
			if (data) {
				setResponseData(data)
				delayUiUpdateWith("response")

				// force org data refresh to keep user list in sync
				delayUserListUpdate(data)

				//	Update users in infinite scroll
				refreshUsers?.(usersData.map((x) => x.userId))
			} else {
				delayUiUpdateWith("error")
			}
		} catch (err) {
			delayUiUpdateWith("error")
		}
	}

	const checkAction = (action, userGroups) => {
		if (action === "add") {
			// check how many users don't already have this group
			const actionableUsers = usersData.filter((item) => !userGroups.every((v) => item.userGroups.includes(v)))
			if (actionableUsers.length > 0) {
				setMessage(`This action will add the selected user groups to ${actionableUsers.length} users`)
				setButtonDisabled(false)
			} else {
				setMessage(`All ${usersData.length} selected users are already in these user groups`)
				setButtonDisabled(true)
			}
		} else if (action === "remove") {
			const actionableUsers = usersData.filter((item) =>
				item.userGroups.some((userGroup) => userGroups.includes(userGroup))
			)
			if (actionableUsers.length > 0) {
				setMessage(`This action will remove the selected user groups from ${actionableUsers.length} users`)
				setButtonDisabled(false)
			} else {
				setMessage(`None of the ${usersData.length} selected users are in these user groups`)
				setButtonDisabled(true)
			}
		} else if (action === "replace") {
			const defaultGroup = ["registered"]
			let formattedUserGroups = [...userGroups, ...defaultGroup]
			formattedUserGroups = [...new Set(formattedUserGroups)]
			const actionableUsers = usersData.filter(
				(item) => item.userGroupNames.sort().join(",") !== formattedUserGroups.sort().join(",")
			)
			if (actionableUsers.length > 0) {
				setMessage(
					`This action will replace all assigned user groups with the selected user groups for ${actionableUsers.length} users`
				)
				setButtonDisabled(false)
			} else {
				setMessage(
					`All of the user groups for the ${usersData.length} selected users are the same as those selected`
				)
				setButtonDisabled(true)
			}
		}
	}

	const handleFormValuesChange = (value) => {
		if (value.optionType) {
			setSelectedAction(value.optionType)
			if (selectedGroups) {
				checkAction(value.optionType, selectedGroups)
			} else {
				setMessage(null)
				setButtonDisabled(true)
			}
		}
		if (value.user_groups) {
			setSelectedGroups(value.user_groups)
			if (selectedAction) {
				checkAction(selectedAction, value.user_groups)
			} else {
				setMessage(null)
				setButtonDisabled(true)
			}
		}
	}

	return (
		<ModalInner>
			{uiStatus === "loading" && <CbLoading />}
			{uiStatus === "error" && (
				<Alert
					type="error"
					message="Sorry we weren't able to update your user groups, please try again later"
				/>
			)}
			{uiStatus === "response" && <Alert type="info" message={responseData.length + " users updated"} />}
			{uiStatus === "default" && (
				<>
					<Title level={3} style={{ marginBottom: 20 }}>
						Edit user groups
					</Title>
					<Form
						onFinish={handleSubmit}
						size="large"
						layout="vertical"
						onValuesChange={(value) => handleFormValuesChange(value)}
					>
						<Form.Item
							label={<FormLabel>Select action</FormLabel>}
							name="optionType"
							rules={[{ required: true, message: "You need to select an option" }]}
							tooltip="Select add to add this group to all users, select remove to remove this group and select replace to replace all users groups with this group"
						>
							<Select
								showArrow={true}
								style={{ width: "100%" }}
								options={options}
								placeholder="Select action..."
							/>
						</Form.Item>
						<UserGroupsSelector
							tooltip="Select user groups"
							labelFontSize="16px !important"
							disableRegistered
							required
						/>
						{message && <Alert message={message} type="warning" />}
						<Form.Item>
							<ButtonContainer>
								<Space>
									<Button size="large" shape="round" onClick={() => cancelModal()}>
										Cancel
									</Button>
									<Button
										type="primary"
										htmlType="submit"
										size="large"
										shape="round"
										disabled={buttonDisabled}
									>
										Confirm changes
									</Button>
								</Space>
							</ButtonContainer>
						</Form.Item>
					</Form>
				</>
			)}
		</ModalInner>
	)
}

EditUserGroupsModal.propTypes = {
	orgId: PropTypes.string.isRequired,
	usersData: PropTypes.array.isRequired,
	cancelModal: PropTypes.func.isRequired,
	bulkEditUserGroupState: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
	refreshUsers: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userGroupsNamesMap: selectOrgUserGroupNamesMap
})

const mapDispatchToProps = (dispatch) => ({
	bulkEditUserGroupState: (data, userGroupsNamesMap) => dispatch(bulkEditUserGroupState(data, userGroupsNamesMap))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroupsModal)
