import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import { Select, Space } from "antd"
import useUrlQueryString from "../../hooks/useUrlQueryString"
import styled from "styled-components"

import { selectOrgCourseCards } from "../../../redux/org/org.selectors"

const Container = styled.div`
	display: flex;
	align-items: center;
`

const PublishedCourseFilter = ({ orgCourseCards }) => {
	const publishedCourseOptions = []
	Object.keys(orgCourseCards).forEach((courseId) => {
		if (
			orgCourseCards[courseId].info.publish_url !== null &&
			!orgCourseCards[courseId].info.user_groups.view_groups.includes("archived")
		) {
			publishedCourseOptions.push({
				value: courseId,
				label: orgCourseCards[courseId].info.name
			})
		}
	})

	const [courseId, setCourseId] = useUrlQueryString("courseId")

	return (
		<Container>
			<Space
				direction="vertical"
				style={{
					width: "100%",
					marginRight: "10px"
				}}
			>
				<Select
					showArrow={true}
					showSearch={false}
					style={{ width: "100%" }}
					value={courseId}
					options={publishedCourseOptions}
					onChange={(value) => setCourseId(value)}
					placeholder="Select course..."
					maxTagCount="responsive"
				/>
			</Space>
		</Container>
	)
}

PublishedCourseFilter.propTypes = {
	orgCourseCards: PropTypes.object.isRequired
}

PublishedCourseFilter.defaultProps = {
	orgCourseCards: {}
}

const mapStateToProps = createStructuredSelector({
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(PublishedCourseFilter)
