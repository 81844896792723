import React from "react"
import styled from "styled-components"
// @ts-ignore
import { Droppable, Draggable } from "react-beautiful-dnd"
import * as S from "../../styles/PageStyles"

import { SequenceContent } from "learnink-common/lib/domains/sequences/Sequence.model"
import CourseCard from "./CourseCard"
import { UUID } from "learnink-common/lib/types/types"

const EmptyBox = styled.div`
	width: 75%;
	padding: 16px;
	border: dashed 1px var(--light-grey);
	text-align: center;
	margin-top: 25px;
	margin-bottom: 10px;
`

interface Props {
	content: SequenceContent[]
	sequenceId: UUID
	deleteCourse: (courseIndex: number, sequenceIndex: number) => void
	sequenceIndex: number
}

const getListStyle = (isDraggingOver: boolean) => ({
	background: isDraggingOver ? "rgba(255,255,255,0.8)" : "transparent",
	width: "100%",
	paddingTop: 10,
	paddingBottom: 10
})

const CourseDragDrop = ({ content, sequenceId, deleteCourse, sequenceIndex }: Props) => {
	return (
		<Droppable droppableId={sequenceId} type="course">
			{(provided: any, snapshot: any) => (
				<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
					{content.map((item, index) => (
						<Draggable key={item.targetId} draggableId={item.targetId} index={index}>
							{(provided: any, snapshot: any) => (
								<div>
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										<CourseCard
											key={index}
											courseId={item.targetId}
											deleteCourse={deleteCourse}
											courseIndex={index}
											sequenceIndex={sequenceIndex}
											margin={12}
											showModuleBubbles
											status="unlocked"
										/>
									</div>
								</div>
							)}
						</Draggable>
					))}
					{content.length === 0 && (
						<S.FlexBoxColumnCentered>
							<EmptyBox>Drag courses from the library to add here...</EmptyBox>
						</S.FlexBoxColumnCentered>
					)}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}

export default CourseDragDrop
