// MODAL FOR UNARCHIVING A COURSE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { unarchiveCourseWithId } from "../../../../redux/courses/courses.actions"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const UnarchiveCourseModal = ({ closemodal, orgId, courseName, courseId, unarchiveCourseWithId }) => {
	const [loading, setLoading] = useState(false)

	// this ammounts to setting access_groups to "archive" AND publishing an update to course IF it's published...
	const handleUnarchiveCourse = () => {
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)

			// handle update here
			unarchiveCourseWithId(orgId, courseId)

			closemodal()
		}, 3000)
	}

	return (
		<ContentContainer>
			<GeTitle>Unarchive '{courseName}'</GeTitle>
			{loading ? (
				<>
					<CbLoading loadingText="Unarchiving..." padding="30px" />
					Please note: we will refresh the page after this operation...
				</>
			) : (
				<>
					<Alert
						message={
							"WARNING: when unarchiving user access groups are reset to default 'registered' setting so ALL users will see this unarchived course, you can change this in course settings after unarchiving"
						}
						type="warning"
					/>
					<Divider />
					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button type="primary" size="large" shape="round" onClick={handleUnarchiveCourse}>
							Confirm unarchive
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	unarchiveCourseWithId: (org_id, course_id) => dispatch(unarchiveCourseWithId(org_id, course_id))
})

PropTypes.UnarchiveCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseName: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	unarchiveCourseWithId: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(UnarchiveCourseModal)
