import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Form, InputNumber, Select, Space } from "antd"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"
import * as S from "../../styles/PageStyles"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import FormModal from "../../common/FormModal"
import { selectOrgImg } from "../../../redux/org/org.selectors"
import BigOnOffButton from "../../common/BigOnOffButton"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"

interface Props {
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	handleSubmit: (sequence: Sequence) => void
	sequence: Sequence
	sequenceIndex: number
	learningPath: LearningPath
}

const EditSequenceTimeline = ({ sequence, setModalType, handleSubmit, sequenceIndex, learningPath }: Props) => {
	const [staggerProgress, setStaggerProgress] = useState<boolean>(sequence.staggerProgress)
	const [strictlyOrdered, setStrictlyOrdered] = useState<boolean>(sequence.strictlyOrdered)
	const unlockTrigger = new LearningPathContent(learningPath).getUnlockTrigger()

	const handleUpdateClassroomSequence = () => {
		const updatedSequence: Sequence = {
			...sequence,
			strictlyOrdered
		}
		handleSubmit(updatedSequence)
	}

	const handleUpdateSelfLearningSequence = (values: any) => {
		const unlockDelayDays =
			sequenceIndex === 0 || unlockTrigger === "finish_previous"
				? 0
				: learningPath.sequences[sequenceIndex - 1].deadlineDay + 1
		const updatedSequence: Sequence = {
			...sequence,
			goal: "custom",
			staggerProgress,
			strictlyOrdered,
			unlockDelayDays,
			unlockTrigger
		}
		if (staggerProgress) {
			const { daysPerCourse } = values
			const numCourses = sequence.content.filter((item) => item.type === "course").length
			const numDays = daysPerCourse * numCourses
			updatedSequence.numDays = numDays
			updatedSequence.daysPerCourse = daysPerCourse
		} else {
			const { numDaysPerSequence } = values
			const startDay =
				sequenceIndex === 0
					? 0
					: unlockTrigger === "start_learning_path"
					? unlockDelayDays
					: learningPath.sequences[sequenceIndex - 1].deadlineDay
			const deadlineDay = startDay + numDaysPerSequence - 1
			updatedSequence.deadlineDay = deadlineDay
			updatedSequence.numDays = deadlineDay - startDay + 1
		}
		handleSubmit(updatedSequence)
	}

	const classroomFormElements = (
		<S.FlexBoxStartRow style={{ marginTop: 10, marginBottom: 10 }}>
			<BigOnOffButton
				key="sequence"
				isOn={strictlyOrdered}
				color="orange"
				text="Lock course until previous is complete"
				faIcon={faUnlock}
				onClick={() => setStrictlyOrdered(true)}
				width={200}
			/>
			<BigOnOffButton
				key="course"
				isOn={!strictlyOrdered}
				color="orange"
				text="Unlock all courses at start"
				faIcon={faUnlock}
				onClick={() => setStrictlyOrdered(false)}
				width={200}
			/>
		</S.FlexBoxStartRow>
	)

	const selfLearningFormElements = (
		<>
			{unlockTrigger === "finish_previous" ? (
				<>
					<S.FlexBoxStartRow>
						<Space>
							<S.FormLabel>Complete sequence in</S.FormLabel>
							<Form.Item
								name="numDaysPerSequence"
								rules={[
									{
										required: true,
										message: "Number of days must be 1 or more"
									}
								]}
								key="numDaysPerSequence"
							>
								<InputNumber min={1} style={{ marginTop: 22 }} />
							</Form.Item>
							<S.FormLabel>days</S.FormLabel>
						</Space>
					</S.FlexBoxStartRow>
				</>
			) : (
				<>
					<S.FlexBoxStartRow>
						<Space>
							<S.FormLabel>Complete</S.FormLabel>
							<Select
								value={staggerProgress}
								style={{ width: 140, fontSize: 14 }}
								options={[
									{ value: false, label: "sequence" },
									{ value: true, label: "each course" }
								]}
								onChange={(val) => setStaggerProgress(val)}
								placeholder="Select..."
							/>
							<S.FormLabel>in</S.FormLabel>
							{staggerProgress ? (
								<Form.Item
									name="daysPerCourse"
									rules={[
										{
											required: true,
											message: "Number of days must be 1 or more"
										}
									]}
									key="daysPerCourse"
								>
									<InputNumber min={1} style={{ marginTop: 22 }} />
								</Form.Item>
							) : (
								<Form.Item
									name="numDaysPerSequence"
									rules={[
										{
											required: true,
											message: "Number of days must be 1 or more"
										}
									]}
									key="numDaysPerSequence"
								>
									<InputNumber min={1} style={{ marginTop: 22 }} />
								</Form.Item>
							)}
							<S.FormLabel>days</S.FormLabel>
						</Space>
					</S.FlexBoxStartRow>
				</>
			)}
			{!staggerProgress && (
				<S.FlexBoxStartRow style={{ marginTop: 10, marginBottom: 10 }}>
					<BigOnOffButton
						key="sequence"
						isOn={strictlyOrdered}
						color="orange"
						text="Lock course until previous is complete"
						faIcon={faLock}
						onClick={() => setStrictlyOrdered(true)}
						width={200}
					/>
					<BigOnOffButton
						key="course"
						isOn={!strictlyOrdered}
						color="orange"
						text="Unlock all courses at start"
						faIcon={faUnlock}
						onClick={() => setStrictlyOrdered(false)}
						width={200}
					/>
				</S.FlexBoxStartRow>
			)}
		</>
	)

	return (
		<FormModal
			title="Edit schedule"
			isModalOpen={true}
			closeModal={() => setModalType("none")}
			modalState="confirm"
			handleSubmit={sequence.classroom ? handleUpdateClassroomSequence : handleUpdateSelfLearningSequence}
			formElements={sequence.classroom ? classroomFormElements : selfLearningFormElements}
			submitButtonTitle="Save"
			initialValues={{
				daysPerCourse: sequence.daysPerCourse,
				numDaysPerSequence:
					sequenceIndex === 0
						? sequence.deadlineDay + 1
						: unlockTrigger === "start_learning_path"
						? sequence.deadlineDay - sequence.unlockDelayDays + 1
						: sequence.deadlineDay - learningPath.sequences[sequenceIndex - 1].deadlineDay
			}}
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgImage: selectOrgImg
})

export default connect(mapStateToProps)(EditSequenceTimeline)
