import { Button, Modal } from "antd"
import * as S from "../../components/styles/PageStyles"
import React from "react"
import styled from "styled-components"
import { __User, ModalType } from "./UserSearch.model"

interface CertificateContainerProps {
	imageUrlOnComponent: string
}

const _S = {
	CertificateContainer: styled.div<CertificateContainerProps>`
		width: 263px;
		height: 374px;
		background: ${({ imageUrlOnComponent }) =>
			imageUrlOnComponent !== "" ? `url(${imageUrlOnComponent}) no-repeat` : "var(--v-light-grey)"};
		background-size: cover;
		border: solid 1px grey;
	`
}

interface Props {
	orgId: string
	user: __User
	setModalType: (modalType: ModalType | undefined) => void
}

export default function UserCertificateModal({ orgId, user, setModalType }: Props) {
	return (
		<Modal
			centered
			onCancel={() => setModalType(undefined)}
			open={true}
			footer={[
				<Button
					key="open"
					target="_blank"
					href={`https://certificates.learn.ink/${user.certificateId}?org=${orgId}`}
				>
					Open link
				</Button>,
				<Button key="download" href={`https://certificates.learn.ink/${user.certificateId}.pdf`}>
					Download PDF
				</Button>,
				<Button
					key="link"
					target="_blank"
					href={`https://wa.me/?text=https://certificates.learn.ink/${user.certificateId}?org=${orgId}`}
				>
					Share on WhatsApp
				</Button>
			]}
		>
			<S.FlexBoxColumnCentered>
				<_S.CertificateContainer
					imageUrlOnComponent={`"https://certificates.learn.ink/${user.certificateId}.png"`}
				/>
			</S.FlexBoxColumnCentered>
		</Modal>
	)
}
