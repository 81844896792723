import React, { useEffect, useState } from "react"
import { defaultHandleError, ExpectedJsonError, StringMap } from "./types"
import { Route } from "./Routes"
import { auth } from "../firebase/firebase.util"

export interface ApiRequest<RequestType, ResponseType, ErrorType = ApiError | string> {
	route: Route
	urlParams?: StringMap
	formData?: FormData /** In multipart upload, we expect a formData object in place of a 'payload...' **/
	payload?: RequestType
	onSuccess?: (jsonResult: ResponseType, response: Response) => void
	onError?: (jsonResult: ErrorType, response: Response) => void
}

export interface HttpError {
	httpStatus: number
	statusMessage: string
}

export interface ApiError {
	httpError?: HttpError
	formErrors?: StringMap
}

export interface UseApiState<RequestType, ResponseType, ErrorType = ApiError | string | undefined> {
	data?: ResponseType
	isLoading: boolean
	setApiRequest: React.Dispatch<React.SetStateAction<ApiRequest<RequestType, ResponseType, ErrorType> | undefined>>
	error: ErrorType | undefined
	abortController: AbortController | undefined
}

export const useApi = <RequestType, ResponseType, ErrorType = ApiError | string | undefined>(): UseApiState<
	RequestType,
	ResponseType,
	ErrorType
> => {
	const [data, setData] = useState<ResponseType>()
	const [error, setError] = useState<ErrorType | undefined>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [apiRequest, setApiRequest] = useState<ApiRequest<RequestType, ResponseType, ErrorType> | undefined>()
	const [abortController, setAbortController] = useState<AbortController | undefined>(undefined)

	const handleResponse = async (response: Response) => {
		let json: unknown
		let rawText
		try {
			rawText = await response.text()
			if (rawText) {
				json = JSON.parse(rawText)
			}
		} catch (e: unknown) {
			throw new ExpectedJsonError((e as Error)?.message)
		}

		if (response.ok) {
			setData(json as ResponseType)
			setError(undefined)
			if (apiRequest?.onSuccess) {
				apiRequest.onSuccess(json as ResponseType, response)
			}
		} else {
			setData(undefined)
			setError(json as ErrorType)
			if (apiRequest?.onError) {
				apiRequest.onError(json as ErrorType, response)
			}
		}
	}

	// eslint-disable-next-line no-shadow
	const apiRequestIsValid = (apiRequest: ApiRequest<RequestType, ResponseType, ErrorType> | undefined): boolean => {
		return !!apiRequest && !!apiRequest.route
	}

	useEffect(() => {
		if (!apiRequestIsValid(apiRequest)) {
			return
		}

		const request = <ApiRequest<RequestType, ResponseType>>apiRequest

		const fetchRequest = async () => {
			setIsLoading(true)
			setAbortController(new AbortController())

			let contentType
			if (!request.route.isMultipart) {
				contentType = { "Content-Type": "application/json" }
			}

			let authHeader
			if (!request.route.noAuthHeader) {
				const token = await auth.currentUser?.getIdToken()
				authHeader = { Authorization: `Bearer ${token}` }
			}

			await fetch(request.route.url(request.urlParams || {}), {
				headers: { ...contentType, ...authHeader },
				method: request.route.method || "GET",
				body: request.formData ?? JSON.stringify(request.payload || undefined),
				signal: abortController?.signal
			})
				.then(handleResponse)
				.catch(defaultHandleError)
				.finally(() => {
					setIsLoading(false)
				})
		}

		// eslint-disable-next-line no-void
		void fetchRequest()
	}, [apiRequest])

	return {
		data,
		isLoading,
		setApiRequest,
		error,
		abortController
	}
}
