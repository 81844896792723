import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Radio } from "antd"
import { Resource } from "learnink-common/lib/domains/permissions/Permission.model"

import { updateCardEditorSettings } from "../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../redux/courses/courses.selectors"
import { selectPermissions } from "../../../../redux/permissions/permissions.selectors"

const NavContainer = styled.div`
	width: 100%;
	padding: 10px;
	border-bottom: solid 1px var(--light-grey);
`

const CbColumnNav = ({ stage, updateCardEditorSettings, permissions }) => {

	const canViewVoicePanel = permissions.canViewSome([Resource.enum.VOICE_CLIP_RECORDING, Resource.enum.TEXT_TO_SPEECH])
	return (
		<NavContainer>
			<Radio.Group
				onChange={(e) =>
					updateCardEditorSettings({
						...stage.editor,
						menu: e.target.value
					})
				}
				value={stage.editor.menu}
				buttonStyle="solid"
			>
				<Radio.Button value="archive">Archive</Radio.Button>
				<Radio.Button value="editor" disabled={!stage.editor.activeCardId}>
					Editor
				</Radio.Button>
				{canViewVoicePanel && (
					<Radio.Button value="voice" disabled={!stage.editor.activeCardId}>
						Voice
					</Radio.Button>
				)}
			</Radio.Group>
		</NavContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	stage: selectStage,
	permissions: selectPermissions
})

const mapDispatchToProps = (dispatch) => ({
	updateCardEditorSettings: (editor) => dispatch(updateCardEditorSettings(editor))
})

export default connect(mapStateToProps, mapDispatchToProps)(CbColumnNav)
