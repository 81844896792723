import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import useUrlQueryString from "../../../hooks/useUrlQueryString"
import { Alert, DatePicker, Space } from "antd"
import moment from "moment"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { fetchAnalytics, formatDateRange } from "../../fetchAnalytics"
import CbLoading from "../../../common/CbLoading"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathUserCourse } from "../../../../../../domains/userAnalytics/UserAnalytics.model"
import { RangePickerProps } from "antd/lib/date-picker"
import ChartWrapper from "../../common/ChartWrapper"
import LearningPathCourses from "./LearningPathCourses"

const { RangePicker } = DatePicker

interface Props {
	orgId: string
	learningPath: LearningPath
}

const LearningPathActivity = ({ orgId, learningPath }: Props) => {
	const [activityDateRange, setActivityDateRange] = useState<string[] | undefined>(undefined)
	const [dateRange] = useUrlQueryString("dateRange")
	const [queryData, setQueryData] = useState<LearningPathUserCourse[] | undefined>(undefined)

	// @ts-ignore
	const dateRangeStart = dateRange ? formatDateRange(dateRange).startDate : undefined

	const disabledDate: RangePickerProps["disabledDate"] = (current) => {
		const isBeforeStart = current < moment(dateRangeStart)
		const isAfterNow = current > moment()
		return isBeforeStart || isAfterNow
	}

	const getQueryData = async () => {
		try {
			const data = await fetchAnalytics(
				"userAnalytics",
				orgId,
				`learning-path/${learningPath.id}/courses`,
				// @ts-ignore
				dateRange
			)
			setQueryData(data)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (activityDateRange) {
			getQueryData()
		}
	}, [dateRange, learningPath])

	useEffect(() => {
		if (!queryData && activityDateRange) {
			getQueryData()
		}
	}, [activityDateRange])

	return (
		<>
			<div className="row">
				<div className="col-sm-12" style={{ paddingTop: 15 }}>
					<Alert
						message="You are seeing this view as part of our user testing group. This view may change as we incorporate user feedback"
						style={{ marginBottom: 10, textAlign: "center" }}
					/>
					<ChartWrapper
						tooltip="Select the date range you want to inspect for courses started and completed"
						title="Select date range to view activity"
					>
						<Space
							direction="vertical"
							style={{
								width: "100%"
							}}
						>
							<RangePicker
								disabledDate={disabledDate}
								onChange={(val, string) => setActivityDateRange(string)}
							/>
						</Space>
					</ChartWrapper>
				</div>
			</div>
			{queryData && activityDateRange && (
				<LearningPathCourses
					learningPath={learningPath}
					activityDateRange={activityDateRange}
					queryData={queryData}
				/>
			)}
			{!queryData && activityDateRange && <CbLoading />}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathActivity)
