// COMPONENT TO SHOW WHEN USER DOESN'T HAVE ACCESS TO FEATURE

import React from "react"
import styled from "styled-components"
import { Typography } from "antd"
import { RocketOutlined } from "@ant-design/icons"

import * as S from "../../components/styles/PageStyles"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"

const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: 1px;
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
	background: white;
	text-align: center;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 48px;
	line-height: 52px;
	padding: 0px 20px 10px 20px;
`

const { Title, Text } = Typography

const FeatureNoAccess = ({ featureTitle }) => {
	return (
		<S.Container>
			{featureTitle && <DashboardHeaderButton title={featureTitle} />}
			<CourseListInner>
				<CourseListEmpty>
					<IconText>
						<RocketOutlined />
					</IconText>
					<Title level={4}>Upgrade to get access</Title>
					<Text type="secondary">Contact us to find out more about how you can upgrade and get access</Text>
				</CourseListEmpty>
			</CourseListInner>
		</S.Container>
	)
}

export default FeatureNoAccess
