// DRAG AND DROP CANVAS FOR COURSE BUILDER

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { DragDropContext } from "react-beautiful-dnd"
import { Alert, Typography } from "antd"

import {
	selectStage,
	selectObjectives,
	selectLessonView,
	selectStageItemsReady
} from "../../../redux/courses/courses.selectors"
import {
	updateStageColsFromDrag,
	setPageParams,
	updateCardEditorSettings
} from "../../../redux/courses/courses.actions"

import CbStageColumn from "./columns/CbStageColumn"
import CbSliderButton from "../../common/CbSliderButton"
import CbModalEditObjective from "./modals/CbModalEditObjective"
import Footer from "./footer/Footer"
import TooltipIcon from "../../common/TooltipIcon"
import EditorContainer from "../editor/EditorContainer"
import CbColumnNav from "./columns/CbColumnNav"
import VoicePanel from "../voice/VoicePanel"

const { Paragraph, Text } = Typography

// allow room for head & foot and for x-scroll
const BaseContainer = styled.div`
	display: block;
	background-color: white;
	width: 100%;
	height: calc(100vh - 64px);
	overflow-x: scroll;
`
// create a fixed width container (example screen change for iPhone 5 with 320px screen)
const XaxisOverflowContainer = styled.div`
	display: flex;
	height: 100%;
	width: 996px;
	@media only screen and (max-width: 320px) {
		width: 888px;
	}
`
// create fixed column width here (example screen change for iPhone 5 with 320px screen)
const ColumnBaseContainer = styled.div`
	display: block;
	width: 330px;
	padding: 6px;
	height: calc(100% - 8px);
	@media only screen and (max-width: 320px) {
		width: 290px;
	}
`
// magic way to hack the droppable area issue, copied from card-wrapper class here https://github.com/markusenglund/react-kanban/blob/master/src/app/components/List/List.scss
// ⚠️👇 DO not change this container!! 👇⚠️
const YaxisOverflowColumnContainer = styled.div`
	height: calc(100% - 45px);
	border: 1px solid lightgrey;
	border-radius: 4px;
	width: 100%;
	margin: 0px 3px 6px 3px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--off-white);
	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(255, 255, 255, 0.2);
	}
`

// For Left hand column only
const LeftColumnContainer = styled.div`
  display: flex
  flex-direction: column;
  height: calc(100% - 30px);
  border: 0px solid red;
  border-radius: 4px;
  width: 100%;
  margin: 0px 3px 6px 3px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: none;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
`
const LeftColumnSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`
const WarningMsgWrapper = styled.div`
	flex: 1;
	margin: 0px 12px 6px 12px;
`
const LearningObjWrapper = styled.div`
	flex: 1;
	margin: 6px 12px;
`
const ColumnTitleContainer = styled.div`
	width: 100%;
	padding: 13px;
	border-bottom: solid 1px var(--light-grey);
	font-size: 16px;
	font-weight: bold;
`

const CbStageDragDropBase = ({
	stage,
	objectives,
	updateStageColsFromDrag,
	lesson_view,
	setPageParams,
	stageItemsReady,
	updateCardEditorSettings
}) => {
	const onDragEnd = (result) => {
		document.body.style.color = "inherit"
		document.body.style.backgroundColor = "inherit"

		updateStageColsFromDrag(stage.stage_columns, result)
	}
	const toggleView = () => {
		setPageParams({ lesson_view: !lesson_view })
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<BaseContainer>
				<XaxisOverflowContainer>
					<ColumnBaseContainer>
						<LeftColumnContainer onClick={() => updateCardEditorSettings({ menu: "archive" })}>
							<LeftColumnSection>
								<CbSliderButton
									defaultChecked={lesson_view}
									onChange={toggleView}
									defaultText="Lesson"
									altText="Questions"
								/>
							</LeftColumnSection>
							<LeftColumnSection>
								<LearningObjWrapper>
									<Text type="secondary">
										Learning objective{" "}
										<TooltipIcon tooltip="A learning objective is a brief, clear, specific statement of what your target learners will be able to do at the end of a stage" />
									</Text>
									<Paragraph style={{ lineHeight: 1.2 }}>
										{objectives[stage.objective_id].content.text}{" "}
										<CbModalEditObjective
											currentObjectiveId={stage.objective_id}
											currentStageId={stage.stage_id}
											source="stagePage"
										/>
									</Paragraph>
								</LearningObjWrapper>
							</LeftColumnSection>
							<LeftColumnSection>
								{!stageItemsReady.lessonPreviewReady && lesson_view && (
									<WarningMsgWrapper>
										<Alert
											message="Complete a minimum of 1 lesson card to enable preview"
											type="error"
										/>
									</WarningMsgWrapper>
								)}
								{!stageItemsReady.questionsPreviewReady && !lesson_view && (
									<WarningMsgWrapper>
										<Alert
											message="Complete a minimum of 5 question cards to enable preview"
											type="error"
										/>
									</WarningMsgWrapper>
								)}
							</LeftColumnSection>
						</LeftColumnContainer>
					</ColumnBaseContainer>

					{lesson_view ? (
						<React.Fragment>
							<ColumnBaseContainer>
								<YaxisOverflowColumnContainer>
									<ColumnTitleContainer>Lesson content</ColumnTitleContainer>
									<CbStageColumn
										key="lesson_data"
										droppableId="lesson_data"
										column={stage.stage_columns.lesson_data}
										cards={stage.stage_columns.lesson_data.card_ids.map(
											(id) => stage.lesson_cards[id]
										)}
									/>
								</YaxisOverflowColumnContainer>
							</ColumnBaseContainer>
							<ColumnBaseContainer>
								<YaxisOverflowColumnContainer>
									<CbColumnNav />
									{stage.editor.menu === "archive" && (
										<CbStageColumn
											key="lesson_archive"
											droppableId="lesson_archive"
											column={stage.stage_columns.lesson_archive}
											cards={stage.stage_columns.lesson_archive.card_ids.map(
												(id) => stage.lesson_cards[id]
											)}
										/>
									)}
									{stage.editor.menu === "editor" &&
										stage.editor.activeCardId &&
										stage.lesson_cards[stage.editor.activeCardId] && (
											<EditorContainer card={stage.lesson_cards[stage.editor.activeCardId]} />
										)}
									{stage.editor.menu === "voice" &&
										stage.editor.activeCardId &&
										stage.lesson_cards[stage.editor.activeCardId] && (
											<VoicePanel card={stage.lesson_cards[stage.editor.activeCardId]} />
										)}
								</YaxisOverflowColumnContainer>
							</ColumnBaseContainer>
						</React.Fragment>
					) : (
						<React.Fragment>
							<ColumnBaseContainer>
								<YaxisOverflowColumnContainer>
									<ColumnTitleContainer>Questions</ColumnTitleContainer>
									<CbStageColumn
										key="questions_data"
										droppableId="questions_data"
										column={stage.stage_columns.questions_data}
										cards={stage.stage_columns.questions_data.card_ids.map(
											(id) => stage.question_cards[id]
										)}
									/>
								</YaxisOverflowColumnContainer>
							</ColumnBaseContainer>
							<ColumnBaseContainer>
								<YaxisOverflowColumnContainer>
									<CbColumnNav />
									{stage.editor.menu === "archive" && (
										<CbStageColumn
											key="questions_archive"
											droppableId="questions_archive"
											column={stage.stage_columns.questions_archive}
											cards={stage.stage_columns.questions_archive.card_ids.map(
												(id) => stage.question_cards[id]
											)}
										/>
									)}
									{stage.editor.menu === "editor" &&
										stage.editor.activeCardId &&
										stage.question_cards[stage.editor.activeCardId] && (
											<EditorContainer card={stage.question_cards[stage.editor.activeCardId]} />
										)}
									{stage.editor.menu === "voice" &&
										stage.editor.activeCardId &&
										stage.question_cards[stage.editor.activeCardId] && (
											<VoicePanel card={stage.question_cards[stage.editor.activeCardId]} />
										)}
								</YaxisOverflowColumnContainer>
							</ColumnBaseContainer>
						</React.Fragment>
					)}
				</XaxisOverflowContainer>
			</BaseContainer>

			<Footer />
		</DragDropContext>
	)
}

const mapStateToProps = createStructuredSelector({
	objectives: selectObjectives,
	stage: selectStage,
	lesson_view: selectLessonView,
	stageItemsReady: selectStageItemsReady
})

const mapDispatchToProps = (dispatch) => ({
	updateStageColsFromDrag: (stage_columns, result) => dispatch(updateStageColsFromDrag(stage_columns, result)),
	setPageParams: (paramsObj) => dispatch(setPageParams(paramsObj)),
	updateCardEditorSettings: (editor) => dispatch(updateCardEditorSettings(editor))
})

// TODO: update proptypes to be more precise here!
CbStageDragDropBase.propTypes = {
	objectives: PropTypes.shape(PropTypes.object.isRequired).isRequired,
	stage: PropTypes.shape({
		stage_id: PropTypes.string.isRequired,
		objective_id: PropTypes.string.isRequired,
		info: PropTypes.shape({
			name: PropTypes.string.isRequired
		}).isRequired,
		stage_columns: PropTypes.shape({
			lesson_data: PropTypes.shape({
				card_ids: PropTypes.arrayOf(PropTypes.string).isRequired
			}).isRequired,
			lesson_archive: PropTypes.shape({
				card_ids: PropTypes.arrayOf(PropTypes.string).isRequired
			}).isRequired,
			questions_data: PropTypes.shape({
				card_ids: PropTypes.arrayOf(PropTypes.string).isRequired
			}).isRequired,
			questions_archive: PropTypes.shape({
				card_ids: PropTypes.arrayOf(PropTypes.string).isRequired
			}).isRequired
		}).isRequired,
		lesson_cards: PropTypes.object.isRequired,
		question_cards: PropTypes.object.isRequired
	}).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CbStageDragDropBase)
