import React from "react"
import { connect } from "react-redux"
import { FontSizeOutlined, InsertRowAboveOutlined, NumberOutlined } from "@ant-design/icons"

import * as S from "../../../styles/PageStyles"
import { updateLessonCard } from "../../../../redux/courses/courses.actions"

import PanelContainer from "./common/PanelContainer"
import { SurveyCard } from "learnink-common/lib/domains/cards/lessons/survey/SurveyCard.model"
import SegmentSetting from "./common/SegmentSetting"

interface Props {
	card: SurveyCard
	updateLessonCard: (updateParams: SurveyCard) => void
}

const SurveyEditor = ({ card, updateLessonCard }: Props) => {
	return (
		<PanelContainer title="Survey type">
			<SegmentSetting
				onChange={(val: "multipleChoiceSurvey" | "textInputSurvey" | "numberInputSurvey") =>
					updateLessonCard({
						...card,
						type: val,
						// @ts-ignore
						surveyOptions: val === "multipleChoiceSurvey" ? [] : undefined
					})
				}
				value={card.type}
				id="surveyTypeSelect"
				options={[
					{
						label: "Mult. choice",
						value: "multipleChoiceSurvey",
						icon: <InsertRowAboveOutlined style={S.editorIcon} />
					},
					{
						label: "Free text",
						value: "textInputSurvey",
						icon: <FontSizeOutlined style={S.editorIcon} />
					},
					{
						label: "Num input",
						value: "numberInputSurvey",
						icon: <NumberOutlined style={S.editorIcon} />
					}
				]}
			/>
		</PanelContainer>
	)
}

const mapDispatchToProps = (dispatch: any) => ({
	updateLessonCard: (updateParams: SurveyCard) => dispatch(updateLessonCard(updateParams))
})

export default connect(null, mapDispatchToProps)(SurveyEditor)
