import React from "react"
import EditableTextBox from "../common/EditableTextBox"
import { TextCard } from "learnink-common/lib/domains/cards/lessons/text/TextCard.model"

interface Props {
	card: TextCard
	updateLessonCard: (updateParams: TextCard) => void
}

const TextCardComponent = ({ card, updateLessonCard }: Props) => {
	return (
		<EditableTextBox
			type="text"
			from={card.from}
			initialText={card.message || ""}
			setNewText={(val: string) => updateLessonCard({ ...card, message: val })}
			placeholderText="Enter text..."
			enableEmojiPicker
		/>
	)
}

export default TextCardComponent
