import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import * as S from "../../components/styles/PageStyles"
import LpBackButton from "../../components/common/LpBackButton"
import AddYourTeam from "../../components/getStarted/AddYourTeam"

const FormContainer = styled.div`
	max-width: 780px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
`

const AddTeamMembers = ({ match: { params } }) => {
	const org_id = params.org_id
	return (
		<>
			<S.PageContainer>
				<S.BackContainer>
					<LpBackButton backlink={`/org/${org_id}/team`} />
				</S.BackContainer>

				<FormContainer>
					<S.FormTitle>Add your team</S.FormTitle>

					<AddYourTeam isComplete={false} forOnboarding={false} />
				</FormContainer>
			</S.PageContainer>
		</>
	)
}

AddTeamMembers.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			org_id: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

export default AddTeamMembers
