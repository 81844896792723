// SIDE PANEL TO EDIT ACCESS CODE

import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Alert, Button, Space } from "antd"
import axios from "axios"

import { selectOrgId } from "../../../redux/org/org.selectors"

import CbLoading from "../../common/CbLoading"
import LpTextInput from "../../common/LpTextInput"
import AccessCodeForm from "./AccessCodeForm"

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ErrorBox = styled.div`
	display: flex;
	padding: 0px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`
const DeleteGroupText = styled.button`
	color: red;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
const AccessCodeRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 20px;
	width: 100%;
`
const AccessCode = styled.div`
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	color: black;
	padding: 0 7px;
	font-size: 16px;
	line-height: 30px;
	display: inline-block;
`

const AccessCodeSidePanel = ({ orgId, accessCodeData, closemodal, refreshData }) => {
	const [panelView, setPanelView] = useState("main")
	const [loading, setLoading] = useState(false)
	const [deleteConfirmText, setDeleteConfirmText] = useState(null)
	const [delErrorMsg, setDelErrorMsg] = useState(null)
	const [reload, setReload] = useState(null)
	const [accessCode, setAccessCode] = useState(accessCodeData.accessCode)

	const refreshAccessCodes = () => {
		refreshData()
		setTimeout(() => {
			setLoading(false)
			setPanelView("main")
			closemodal()
		}, 1000)
	}

	const handleSubmit = async (accessCodeData) => {
		try {
			setLoading(true)
			await axios.put(`/api/accessCode/${orgId}/${accessCode}`, accessCodeData)
			refreshAccessCodes()
		} catch (err) {
			setLoading(false)
		}
	}

	const handleAccessCodeDelete = async () => {
		setLoading(true)
		try {
			await axios.delete(`/api/accessCode/${orgId}/${accessCode}`)
			refreshAccessCodes()
		} catch (err) {
			setLoading(false)
		}
	}

	const handleAccessCodeRegen = async () => {
		setLoading(true)
		try {
			const { data } = await axios.get(`/api/accessCode/${orgId}/${accessCode}/regenerate`)
			setAccessCode(data[0].accessCode)
			setTimeout(() => {
				setLoading(false)
				setPanelView("main")
			}, 1000)
		} catch (err) {
			setLoading(false)
		}
	}

	useEffect(() => {
		setReload(true)
		setAccessCode(accessCodeData.accessCode)
		setPanelView("main")
		setDeleteConfirmText(null)
		setDelErrorMsg(null)
		setTimeout(() => {
			setReload(false)
		}, 100)
	}, [accessCodeData])

	if (reload) {
		return null
	}

	return (
		<>
			<ContentContainer>
				{panelView === "main" && (
					<>
						{loading ? (
							<CbLoading loadingText="Updating access code..." padding="30px" />
						) : (
							<>
								<FormLabel>Access code</FormLabel>
								<AccessCodeRow>
									<AccessCode>{accessCode}</AccessCode>
									<Button shape="round" onClick={() => setPanelView("code")}>
										Regenerate
									</Button>
								</AccessCodeRow>
								<AccessCodeForm
									handleSubmit={handleSubmit}
									accessCodeData={accessCodeData}
									submitAction="update"
									cancel={closemodal}
								/>
								<Divider />
								<DeleteGroupText onClick={() => setPanelView("delete")}>
									Need to delete this access code?
								</DeleteGroupText>
								{delErrorMsg && (
									<ErrorBox>
										<Alert message={delErrorMsg} type="warning" />
									</ErrorBox>
								)}
							</>
						)}
					</>
				)}

				{panelView === "delete" && (
					<>
						{loading ? (
							<CbLoading loadingText="Deleting access code..." padding="30px" />
						) : (
							<>
								<LpTextInput
									labelText="Access code"
									initialText={accessCode}
									disabled={true}
									labelFontSize="16px"
								/>

								<Divider />

								<LpTextInput
									labelText="Type the access code shown above to delete"
									handleTextChange={(value) => setDeleteConfirmText(value)}
									labelFontSize="16px"
								/>

								{deleteConfirmText === accessCode ? (
									<ErrorBox>
										<Alert
											message={"WARNING: This action cannot be reversed, proceed with caution!"}
											type="warning"
										/>
									</ErrorBox>
								) : null}
								<Divider />
								<ButtonContainer>
									<Space>
										<Button shape="round" onClick={() => setPanelView("main")}>
											Cancel
										</Button>
										<Button
											shape="round"
											disabled={deleteConfirmText !== accessCode}
											onClick={handleAccessCodeDelete}
										>
											Confirm delete
										</Button>
									</Space>
								</ButtonContainer>
							</>
						)}
					</>
				)}

				{panelView === "code" && (
					<>
						{loading ? (
							<>
								<CbLoading loadingText="Regenerating code..." padding="30px" />
							</>
						) : (
							<>
								<Alert
									message={
										"If you regenerate this code then the previous one will no longer work. Make sure you're ready to do this!"
									}
									type="warning"
								/>
								<Divider />
								<ButtonContainer>
									<Space>
										<Button shape="round" onClick={() => setPanelView("main")}>
											Cancel
										</Button>
										<Button type="primary" shape="round" onClick={handleAccessCodeRegen}>
											Regenerate code
										</Button>
									</Space>
								</ButtonContainer>
							</>
						)}
					</>
				)}
			</ContentContainer>
		</>
	)
}

AccessCodeSidePanel.propTypes = {
	orgId: PropTypes.string.isRequired,
	accessCodeData: PropTypes.object.isRequired,
	closemodal: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(AccessCodeSidePanel)
