// STAGES COLUMN

import React, { Component } from "react"
import { Droppable } from "react-beautiful-dnd"
import { Alert, Typography } from "antd"
import styled from "styled-components"

import CbStageCard from "../cards/CbStageCard"
import CbModalAddStage from "../modals/CbModalAddStage"

const { Title } = Typography

const Container = styled.div`
	padding: 10px;
`
const CardsList = styled.div`
	transition: background-color 0.2s ease;
	background-color: var(--off-white);
	flex-grow: 1;
	min-height: calc(100% - 44px);
`
const ButtonContainer = styled.div`
	flex: 1;
	margin-top: 20px;
	display: flex;
	justify-content: center;
`
const EmptyColumnNote = styled.div`
	display: flex;
	justify-content: center;
`

class CbModuleStagesColumn extends Component {
	componentDidUpdate = (prevProps) => {
		// Scroll to bottom of list if a new card has been added
		if (
			this.props.cards.length > 2 &&
			this.props.cards[this.props.cards.length - 2] === prevProps.cards[prevProps.cards.length - 1]
		) {
			this.scrollToBottom()
		}
	}

	scrollToBottom = () => {
		this.listEnd.scrollIntoView()
	}

	render() {
		const { droppableId, title, cards } = this.props

		return (
			<Container>
				<Title level={5}>{title}</Title>

				<Droppable droppableId={droppableId}>
					{(provided, snapshot) => (
						<CardsList
							ref={provided.innerRef}
							{...provided.droppableProps}
							isDraggingOver={snapshot.isDraggingOver}
						>
							{cards.length === 0 && (
								<EmptyColumnNote>
									<Alert
										message="Add your first Stage to this module with the ADD STAGE
                    button below"
									/>
								</EmptyColumnNote>
							)}
							{cards.map((card, index) => (
								<CbStageCard key={card.id} card={card} index={index} droppableId={droppableId} />
							))}
							{provided.placeholder}

							<div
								style={{ float: "left", clear: "both" }}
								ref={(el) => {
									this.listEnd = el
								}}
							/>

							<ButtonContainer>
								<CbModalAddStage />
							</ButtonContainer>
						</CardsList>
					)}
				</Droppable>
			</Container>
		)
	}
}

export default CbModuleStagesColumn
