import React from "react"
import LessonPreview from "./LessonPreview"
import QuizPreview from "./QuizPreview"

const PreviewSkeleton = ({ type, org_id, stage_id }) => {
	if (type === "lesson") {
		return <LessonPreview moduleStageId={stage_id} orgId={org_id} />
	} else if (type === "quiz") {
		return <QuizPreview moduleStageId={stage_id} orgId={org_id} />
	} else {
		return null
	}
}

export default PreviewSkeleton
