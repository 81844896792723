import React from "react"
import OneElement from "./cardLayouts/OneElement"
import MultiElementHorizontal from "./cardLayouts/MultiElementHorizontal"

const Layout = ({
	cardId,
	shape,
	iconPlacement,
	iconSize,
	layout,
	elements,
	width,
	height,
	editable,
	handleUpdateText
}) => {
	if (layout === "oneElement")
		return (
			<OneElement
				cardId={cardId}
				shape={shape}
				iconPlacement={iconPlacement}
				iconSize={iconSize}
				elements={elements}
				width={width}
				height={height}
				editable={editable}
				handleUpdateText={handleUpdateText}
			/>
		)
	else if (layout === "multiElementHorizontal") {
		return (
			<MultiElementHorizontal
				cardId={cardId}
				shape={shape}
				iconPlacement={iconPlacement}
				iconSize={iconSize}
				elements={elements}
				width={width}
				height={height}
				editable={editable}
				handleUpdateText={handleUpdateText}
			/>
		)
	} else return null
}

export default Layout
