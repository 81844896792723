import React from "react"

const RightAngleTriangle = ({ size, color, position }) => {
	let style = {
		triangle: {
			position: "absolute",
			...position,
			width: 0,
			height: 0,
			backgroundColor: "transparent",
			borderStyle: "solid",
			borderLeftWidth: size,
			borderBottomWidth: size,
			borderLeftColor: "transparent",
			borderBottomColor: color
		}
	}
	return <div style={style.triangle} />
}

const Circle = ({ size, color, position }) => {
	let style = {
		circle: {
			position: "absolute",
			...position,
			width: size,
			height: size,
			borderRadius: size / 2,
			backgroundColor: color
		}
	}
	return <div style={style.circle} />
}

const Donut = ({ size, color, position }) => {
	let style = {
		donut: {
			position: "absolute",
			...position,
			width: size,
			height: size,
			borderRadius: size / 2,
			borderWidth: size / 4,
			borderColor: color,
			borderStyle: "solid"
		}
	}
	return <div style={style.donut} />
}

const Triangle = ({ size, color, position }) => {
	let style = {
		triangle: {
			position: "absolute",
			...position,
			width: 0,
			height: 0,
			backgroundColor: "transparent",
			borderStyle: "solid",
			borderLeftWidth: size / 2,
			borderRightWidth: size / 2,
			borderBottomWidth: size,
			borderTopWidth: 0,
			borderLeftColor: "transparent",
			borderRightColor: "transparent",
			borderBottomColor: color
		}
	}
	return <div style={style.triangle} />
}

const DiamondNarrow = ({ size, color, position }) => {
	let style = {
		diamondNarrow: {
			position: "absolute",
			...position
		},
		diamondNarrowTop: {
			width: 0,
			height: 0,
			borderTopWidth: 0,
			borderTopColor: "transparent",
			borderLeftColor: "transparent",
			borderLeftWidth: size / 2,
			borderRightColor: "transparent",
			borderRightWidth: size / 2,
			borderBottomColor: color,
			borderBottomWidth: size / 1.42
		},
		diamondNarrowBottom: {
			width: 0,
			height: 0,
			borderTopWidth: size / 1.42,
			borderTopColor: color,
			borderLeftColor: "transparent",
			borderLeftWidth: size / 2,
			borderRightColor: "transparent",
			borderRightWidth: size / 2,
			borderBottomColor: "transparent",
			borderBottomWidth: 0
		}
	}
	return (
		<div style={style.diamondNarrow}>
			<div style={style.diamondNarrowTop} />
			<div style={style.diamondNarrowBottom} />
		</div>
	)
}

const CutDiamond = ({ size, color, position }) => {
	let style = {
		cutDiamond: { position: "absolute", ...position },
		cutDiamondTop: {
			width: size,
			height: 0,
			borderTopWidth: 0,
			borderTopColor: "transparent",
			borderLeftColor: "transparent",
			borderLeftWidth: size / 4,
			borderRightColor: "transparent",
			borderRightWidth: size / 4,
			borderBottomColor: color,
			borderBottomWidth: size / 4
		},
		cutDiamondBottom: {
			width: 0,
			height: 0,
			borderTopWidth: size / 1.42,
			borderTopColor: color,
			borderLeftColor: "transparent",
			borderLeftWidth: size / 2,
			borderRightColor: "transparent",
			borderRightWidth: size / 2,
			borderBottomColor: "transparent",
			borderBottomWidth: 0
		}
	}
	return (
		<div style={style.cutDiamond}>
			<div style={style.cutDiamondTop} />
			<div style={style.cutDiamondBottom} />
		</div>
	)
}

const Shapes = ({ primaryColor, secondaryColor, height, figures, style }) => {
	const config = {
		primaryColor: primaryColor || "#416DF8",
		secondaryColor: secondaryColor || "#2F53D5",
		height: height / 3.5,
		sizefigure: 100,
		figures: figures || [
			{ name: "circle", position: "center", size: 60 },
			{ name: "donut", position: "flex-start", axis: "top", size: 80 },
			{ name: "circle", position: "center", axis: "right", size: 100 }
		]
	}

	const arrFigures = []
	const buildFigures = () => {
		config.figures.forEach((e, i) => {
			const sizefigure = e.size || config.sizefigure
			const position = e.position || { top: 0, left: 0 }

			if (e.name === "circle") {
				arrFigures.push(<Circle key={i} size={sizefigure} color={config.secondaryColor} position={position} />)
			}
			if (e.name === "donut") {
				arrFigures.push(<Donut key={i} size={sizefigure} color={config.secondaryColor} position={position} />)
			}
			if (e.name === "triangle") {
				arrFigures.push(
					<Triangle key={i} size={sizefigure} color={config.secondaryColor} position={position} />
				)
			}
			if (e.name === "diamondNarrow") {
				arrFigures.push(
					<DiamondNarrow key={i} size={sizefigure} color={config.secondaryColor} position={position} />
				)
			}
			if (e.name === "cutDiamond") {
				arrFigures.push(
					<CutDiamond key={i} size={sizefigure} color={config.secondaryColor} position={position} />
				)
			}
		})

		return arrFigures
	}

	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				flex: 1,
				flexDirection: "row",
				justifyContent: "space-between",
				height,
				...style
			}}
		>
			<>{buildFigures()}</>
		</div>
	)
}

export { Shapes, RightAngleTriangle }
