import styled from "styled-components"

const HEADER_COLOUR = "#e7f6e9"
const CELL_COLOR = "#495a6f"
const HEADER_OUTLINE = "2px solid #eb9486"
const CELL_HOVER_BACKGROUND_COLOR = "#e7f6e9"

export const TableOverflowHolder = styled.div`
	overflow: scroll;
`

export const Table = styled.div`
	border-collapse: separate;
	margin: 0;
`

export const Tr = styled.div`
	text-align: left;
`

export const Thead = styled.div`
	& ${Tr} {
		color: #0a6325;
	}
`

export const Tbody = styled.div``

export const Th = styled.div`
	height: 40px;
	padding: 0 0;
	overflow: hidden;
	text-align: left;
	border-bottom: ${HEADER_OUTLINE};
	background-color: ${HEADER_COLOUR};
	font-weight: bold;
	cursor: pointer;
`

export const TableHeadInner = styled.div`
	width: 300px; //	This is just to allow overflow from the Th, it can be any large-ish value
	height: 100%;
	padding: 10px;
`

export const TbodyTr = styled(Tr)`
	color: ${CELL_COLOR};
	cursor: pointer;
	&:nth-of-type(even) {
		background-color: #fafafa;
	}
	&:nth-of-type(odd) {
		background-color: #f5f6f9;
	}
	&:hover {
		background: ${CELL_HOVER_BACKGROUND_COLOR};
	}
`

export const Td = styled.div`
	padding: 0;
	overflow: hidden;
`

interface TableResizerProps {
	isResizing: boolean
}

const headerHoverHighlightColour = (props: TableResizerProps) => {
	return props.isResizing ? HEADER_OUTLINE : "var(--secondary-color)"
}

export const TableResizer = styled.div<TableResizerProps>`
	right: 0;
	width: 10px;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	${"" /* prevents from scrolling while dragging on touch devices */}
	touch-action: none;
	border-right: solid 2px rgb(189 221 206 / 77%);
	transition: 0.2s ease;
	:hover,
	:focus {
		border-right: solid 6px ${headerHoverHighlightColour};
	}
`

export const Monospace = { fontFamily: "monospace" }
export const Italic = { fontStyle: "italic" }
export const SmallFontSize = { fontSize: "0.9em" }

export const MultiTagDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 50px;
`

export const OptionsContainerOuter = styled.div`
	padding: 15px;
`

export const OptionsContainerInner = styled.div`
	display: flex;
	justify-content: space-between;
`

export const Flexbox = styled.div`
	display: flex;
	gap: 10px;
`

export const FlexboxLeftAlignText = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`

export const FlexboxCenterAlignText = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ToggleColumnsContainer = styled.div`
	margin-top: 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px 10px;
`

export const ToggleDiv = styled.div`
	background-color: #fafafa;
	border: solid 1px #cfcfcf;
	border-radius: 2px;
	padding: 4px 8px;
`

export const Checkbox = styled.input`
	position: relative;
	top: 2px;
`
