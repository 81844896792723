// RESOURCES PAGE
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgId } from "../../redux/org/org.selectors"
import { getResourceListForOrg, clearResourcesList } from "../../redux/resources/resources.actions"
import * as S from "../../components/styles/PageStyles"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import ResourcesTableQueryWrapper from "../../components/resources/ResourcesTableQueryWrapper"

const Resources = ({ orgId, getResourceListForOrg, clearResourcesList }) => {
	useEffect(() => {
		getResourceListForOrg(orgId) // fetch all resources on page load

		return () => {
			clearResourcesList() // cleanup on exit
		}
	}, [getResourceListForOrg, clearResourcesList, orgId])

	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton
					title={"Resources"}
					buttonTitle={"Add resource"}
					buttonLink={"/org/" + orgId + "/create/new-resource"}
				/>

				<ResourcesTableQueryWrapper />
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})
const mapDispatchToProps = (dispatch) => ({
	getResourceListForOrg: (orgId) => dispatch(getResourceListForOrg(orgId)),
	clearResourcesList: () => dispatch(clearResourcesList())
})

export default connect(mapStateToProps, mapDispatchToProps)(Resources)
