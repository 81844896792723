import React from "react"
import styled from "styled-components"
import { Typography } from "antd"

import EditableTextBox from "../common/EditableTextBox"
import { ButtonCard } from "learnink-common/lib/domains/cards/lessons/button/ButtonCard.model"

const { Paragraph } = Typography

const BelowCardMessage = styled.div`
	height: 20px;
	margin-top: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
`
const BottomPadding = styled.div`
	height: 6px;
`

interface Props {
	card: ButtonCard
	updateLessonCard: (updateParams: ButtonCard) => void
}

const ButtonCardComponent = ({ card, updateLessonCard }: Props) => {
	return (
		<React.Fragment>
			<EditableTextBox
				type="url"
				from={card.from}
				initialText={card.message || ""}
				placeholderText="Enter your message here..."
				setNewText={(val: string) => updateLessonCard({ ...card, message: val })}
			/>
			<EditableTextBox
				type="button"
				from={card.from}
				initialText={card.buttonText || ""}
				placeholderText="Enter button text..."
				setNewText={(val: string) => updateLessonCard({ ...card, buttonText: val })}
				textAlign="center"
			/>
			<BelowCardMessage>Button link:</BelowCardMessage>
			<Paragraph
				editable={{
					onChange: (val) => updateLessonCard({ ...card, url: val }),
					enterIcon: null
				}}
				ellipsis={{ rows: 1 }}
				style={{ width: "100%", color: "blue" }}
			>
				{card.url ? card.url : "https://example.com"}
			</Paragraph>
			<BottomPadding />
		</React.Fragment>
	)
}

export default ButtonCardComponent
