import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Tag, Table, Drawer, Input, Button, Checkbox, Space, Select } from "antd"
import { CSVLink } from "react-csv"
import { ColumnsType } from "antd/lib/table"
import { selectOrgUserGroupsInfo } from "../../../redux/management/management.selectors"
import AccessCodeSidePanel from "./AccessCodeSidePanel"
import { EditOutlined } from "@ant-design/icons"
import { selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { ActionListObject } from "learnink-common/lib/domains/accessCodes/AccessCode.model"
import { selectLearningPathById, selectOrgUserGroupColorsMap } from "../../../redux/org/org.selectors"
import { LearningPathById, OrgUserGroupColorsMap } from "../../../util/model/OrgSelectors"
import { PremiumFeatureAccess } from "../../../redux/org/org.model"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

const { Search } = Input

export interface AccessCodeTableRow {
	accessCode: string
	description: string
	userGroups: string[]
	userGroupNames: string[]
	learningPathId: string
	learningPathName: string
	assignmentIds: string[]
	assignmentNames: string[]
	status: "active" | "deactivated"
	isActive: boolean
	actionList: ActionListObject[]
}

interface Props {
	accessCodes: AccessCodeTableRow[]
	premiumFeatureAccess: PremiumFeatureAccess
	refreshData: () => void
	userGroupColors: OrgUserGroupColorsMap
	learningPathById: LearningPathById
	isAdmin: boolean
}

const AccessCodesTable = ({
	accessCodes,
	refreshData,
	premiumFeatureAccess,
	userGroupColors,
	learningPathById,
	isAdmin
}: Props) => {
	const [showDrawer, setShowDrawer] = useState<boolean>(false)
	const [selectedRow, setSelectedRow] = useState<AccessCodeTableRow | undefined>(undefined)
	const [searchInput, setSearchInput] = useState<string | undefined>(undefined)
	const [filteredData, setFilteredData] = useState<AccessCodeTableRow[]>([])
	const [dataToDownload, setDataToDownload] = useState<any[]>([])
	const [showDeactivatedCodes, setShowDeactivatedCodes] = useState<boolean>(false)
	const [visibleColumns, setVisibleColumns] = useState<CheckboxValueType[]>([])
	const [showColumnSelector, setShowColumnSelector] = useState<boolean>(false)

	const columnOptions: any[] = [
		{ label: "Access code", value: "accessCode", disabled: true },
		{ label: "Description", value: "description" },
		{ label: "Add to user groups", value: "userGroupNames" }
	]
	if (premiumFeatureAccess.learningPaths !== "hidden" || isAdmin) {
		columnOptions.push({ label: "Add to learning path", value: "learningPathName" })
	}
	if (premiumFeatureAccess.sequences !== "hidden" || isAdmin) {
		columnOptions.push({ label: "Add to sequence", value: "assignmentNames" })
	}

	const columns: ColumnsType<AccessCodeTableRow> = [
		{
			title: "Access code",
			dataIndex: "accessCode",
			key: "accessCode",
			render: (text: string, record: AccessCodeTableRow) =>
				record.isActive ? <Tag>{text}</Tag> : <Tag color="red">{text}</Tag>
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description"
		},
		{
			title: "Add to user groups",
			dataIndex: "userGroupNames",
			key: "userGroupNames",
			// @ts-ignore
			render: (_, { userGroupNames }) => (
				<>
					{userGroupNames.map((userGroupName: string) => {
						return (
							<Tag color={userGroupColors[userGroupName]} key={userGroupName}>
								{userGroupName}
							</Tag>
						)
					})}
				</>
			)
		},
		{
			title: "Add to learning path",
			dataIndex: "learningPathName",
			key: "learningPathName",
			render: (text: string, record: AccessCodeTableRow) =>
				text ? (
					<Tag
						style={{ opacity: premiumFeatureAccess.learningPaths === "hidden" ? 0.5 : 1 }}
						color={learningPathById[record.learningPathId].color}
					>
						{text}
					</Tag>
				) : (
					""
				)
		},
		{
			title: "Add to sequence",
			dataIndex: "assignmentNames",
			key: "assignmentNames", // @ts-ignore
			render: (_, { assignmentNames }) => (
				<>
					{assignmentNames.map((assignmentName: string) => {
						return (
							<Tag
								style={{ opacity: premiumFeatureAccess.sequences === "hidden" ? 0.5 : 1 }}
								key={assignmentName}
							>
								{assignmentName}
							</Tag>
						)
					})}
				</>
			)
		},
		{
			title: "",
			key: "action",
			render: (_: string, record: AccessCodeTableRow) => (
				<EditOutlined
					style={{ color: "var(--secondary-color)" }}
					onClick={() => {
						setSelectedRow(record)
						setShowDrawer(true)
					}}
				/>
			)
		}
	].filter((item) => [...visibleColumns, "action"].includes(item.key))

	useEffect(() => {
		let filteredData = showDeactivatedCodes
			? accessCodes.filter((item) => !item.isActive)
			: accessCodes.filter((item) => item.isActive)
		if (searchInput) {
			filteredData = filteredData.filter(
				(item) =>
					item.accessCode.toLowerCase().includes(searchInput.toLowerCase()) ||
					(item.learningPathName &&
						item.learningPathName.toLowerCase().includes(searchInput.toLowerCase())) ||
					item.userGroupNames.join().toLowerCase().includes(searchInput.toLowerCase())
			)
		}
		setFilteredData(filteredData)
		const columns = ["accessCode", "userGroupNames", "action"]
		if (premiumFeatureAccess.learningPaths !== "hidden") {
			columns.push("learningPathName")
		}
		if (premiumFeatureAccess.sequences !== "hidden") {
			columns.push("assignmentNames")
		}
		setVisibleColumns(columns)
	}, [showDeactivatedCodes, searchInput])

	const formatDataForDownload = () => {
		const formattedData = filteredData.map((row: AccessCodeTableRow) => {
			return {
				"Access code": row.accessCode,
				Description: row.description,
				"User groups": row.userGroupNames,
				Assignments: row.assignmentNames,
				Status: row.status
			}
		})
		setDataToDownload(formattedData)
	}

	const csvDownloadRef = useRef<CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }>(null)
	useEffect(() => {
		if (csvDownloadRef.current && dataToDownload.length > 0) {
			setTimeout(() => {
				csvDownloadRef.current?.link?.click()
			}, 200)
		}
	}, [dataToDownload])

	return (
		<>
			<div className="row">
				<div
					className="col-sm-4"
					style={{
						paddingBottom: 20,
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						fontSize: 15
					}}
				>
					Filter:
					<Select
						onChange={() => setShowDeactivatedCodes(!showDeactivatedCodes)}
						value={showDeactivatedCodes}
						options={[
							{ value: false, label: "Active codes" },
							{ value: true, label: "Deactivated codes" }
						]}
						style={{ marginLeft: 10 }}
					/>
				</div>
				<div className="col-sm-4" style={{ paddingBottom: 20, display: "flex", justifyContent: "flex-start" }}>
					<Search placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)} />
				</div>
				<div className="col-sm-4" style={{ paddingBottom: 20, display: "flex", justifyContent: "flex-end" }}>
					<Space>
						<Button onClick={() => setShowColumnSelector(!showColumnSelector)} shape="round">
							Change columns
						</Button>
						<Button onClick={() => formatDataForDownload()} shape="round">
							Export
						</Button>
					</Space>
				</div>
				{showColumnSelector && (
					<div className="col-sm-12" style={{ paddingBottom: 20 }}>
						<Checkbox.Group
							options={columnOptions}
							value={visibleColumns}
							onChange={(val) => setVisibleColumns(val)}
						/>
					</div>
				)}
			</div>
			<Table
				columns={columns}
				dataSource={filteredData}
				expandable={{ defaultExpandedRowKeys: ["roles"], expandRowByClick: true, indentSize: 6 }}
			/>
			<Drawer
				title="Settings"
				placement="right"
				onClose={() => {
					setShowDrawer(false)
				}}
				open={showDrawer}
			>
				{selectedRow && (
					<AccessCodeSidePanel
						accessCodeData={selectedRow}
						closemodal={() => {
							setShowDrawer(false)
						}}
						refreshData={refreshData}
					/>
				)}
			</Drawer>
			<CSVLink
				ref={csvDownloadRef}
				data={dataToDownload}
				filename={`access_codes_${Date.now()}.csv`}
				style={{ display: "none" }}
				target="_blank"
			/>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsInfo: selectOrgUserGroupsInfo,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	userGroupColors: selectOrgUserGroupColorsMap,
	learningPathById: selectLearningPathById,
	isAdmin: selectIsUserAdmin
})

// @ts-ignore
export default connect(mapStateToProps)(AccessCodesTable)
