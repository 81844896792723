// DELETE MODULE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Alert, Typography } from "antd"

import { deleteModuleInCourse } from "../../../../redux/courses/courses.actions"
import { selectCourseId } from "../../../../redux/courses/courses.selectors"

import CbLoading from "../../../common/CbLoading"

const { Title } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
	padding: 30px;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const DeleteModule = ({ closemodal, course_id, module_card, deleteModuleInCourse }) => {
	const [loading, setLoading] = useState(false)

	const handleDeleteModule = () => {
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			deleteModuleInCourse(course_id, module_card.id)
			setLoading(false)
			closemodal()
		}, 2000)
	}

	return (
		<ContentContainer>
			<Title level={4}>Delete module</Title>
			{loading ? (
				<>
					<CbLoading padding="30px" />
				</>
			) : (
				<>
					<Alert
						message={
							"Once you delete this module you can't get it back again. Make sure you definitely want to do this!"
						}
						type="warning"
					/>
					<Divider height={"20px"} />
					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button type="primary" size="large" shape="round" onClick={handleDeleteModule}>
							Confirm delete
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	course_id: selectCourseId
})

const mapDispatchToProps = (dispatch) => ({
	deleteModuleInCourse: (course_id, module_id) => dispatch(deleteModuleInCourse(course_id, module_id))
})

PropTypes.ArchiveCourse = {
	closemodal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModule)
