// MAP OF USER LOCATIONS

import React, { useState } from "react"
import MapGL, { NavigationControl, Source, Layer } from "react-map-gl"
import styled from "styled-components"

const MapBoxContainer = styled.div`
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	height: calc(100vh - 430px);
	-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
`
const MapBoxNavi = styled.div`
	position: absolute;
	top: 8px;
	left: 8px;
`

const clusterLayer = {
	id: "clusters",
	type: "circle",
	filter: ["has", "point_count"],
	paint: {
		"circle-color": ["step", ["get", "point_count"], "#FE9923", 100, "#FFC500", 750, "#B9E13F"],
		"circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
	}
}

const clusterCountLayer = {
	id: "cluster-count",
	type: "symbol",
	filter: ["has", "point_count"],
	layout: {
		"text-field": "{point_count_abbreviated}",
		"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
		"text-size": 12
	}
}

const unclusteredPointLayer = {
	id: "unclustered-point",
	type: "circle",
	filter: ["!", ["has", "point_count"]],
	paint: {
		"circle-color": "#FE9923",
		"circle-radius": 4,
		"circle-stroke-width": 1,
		"circle-stroke-color": "#fff"
	}
}

const LocationsMap = ({ queryData }) => {
	const [viewport, setViewport] = useState({
		latitude: 34,
		longitude: 5,
		zoom: 1.5
	})

	// we need to encode data into a specific format https://geojson.org/
	let geoJsonData = {
		type: "FeatureCollection",
		features: []
	}

	queryData.forEach((item) => {
		geoJsonData["features"].push({
			type: "Feature",
			geometry: {
				type: "Point",
				coordinates: [parseFloat(item["lng"]), parseFloat(item["lat"])]
			},
			properties: {
				userId: item["userId"]
			}
		})
	})

	return (
		<MapBoxContainer>
			<MapGL
				{...viewport}
				onViewportChange={(viewport) => {
					setViewport(viewport)
				}}
				width="100%"
				height="100%"
				mapStyle="mapbox://styles/mapbox/light-v10"
				mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
			>
				<MapBoxNavi>
					<NavigationControl />
				</MapBoxNavi>
				<Source type="geojson" data={geoJsonData} cluster={true} clusterMaxZoom={5} clusterRadius={50}>
					<Layer {...clusterLayer} />
					<Layer {...clusterCountLayer} />
					<Layer {...unclusteredPointLayer} />
				</Source>
			</MapGL>
		</MapBoxContainer>
	)
}

export default LocationsMap
