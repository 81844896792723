import React from "react"
//import PropTypes from 'prop-types';
import styled, { keyframes } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faCheckCircle, faShare, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

// animation for loading...
const animateRotation = (primaryColor, secondaryColor) => keyframes`
  0%   {
    transform: rotate(0);
    border-top-color: ${primaryColor};
  }
  100% { 
    transform: rotate(360deg);
    border-top-color: ${secondaryColor};
  }
`

// Helper CSS functions
// ====================
const setBtnColor = (type, disabled) => {
	let color = "var(--secondary-color)"
	// cases for specified types
	if (type === "action") color = "var(--secondary-color)"
	else if (type === "cancel") color = "var(--mid-red)"
	else if (type === "outline") color = "var(--off-white)"
	else if (type === "standard") color = "var(--mid-orange)"
	// disabled case (also for loading)
	if (disabled === true) color = "var(--light-grey)"
	return color
}
const setTextColor = (type, disabled) => {
	// handle case with unknown type
	let color = "var(--off-white)"
	// cases for specified types
	if (type === "action") color = "var(--off-white)"
	else if (type === "cancel") color = "var(--off-white)"
	else if (type === "outline") color = "var(--secondary-color)"
	else if (type === "standard") color = "var(--off-white)"
	// disabled case (also for loading)
	if (disabled === true) color = "var(--mid-grey)"
	return color
}
const setBtnColorSelected = (type) => {
	let color = "var(--secondary-color)"
	// cases for specified type
	if (type === "action") color = "var(--secondary-color)"
	else if (type === "cancel") color = "var(--dark-red)"
	else if (type === "outline") color = "var(--secondary-color-pale)"
	else if (type === "standard") color = "var(--dark-orange)"
	return color
}

// Styled components
// =================
const StyledButton = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 32px;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	padding: 10px 14px;
	text-transform: uppercase;
	background-color: ${({ type, disabled }) => setBtnColor(type, disabled)};
	color: ${({ type, disabled }) => setTextColor(type, disabled)};
	border: ${({ type, disabled }) => (type === "outline" && !disabled ? "2px solid var(--secondary-color)" : "none")};
	:hover {
		background-color: ${({ type, disabled }) => setBtnColor(type, disabled)};
	}
	:focus {
		background-color: ${({ type }) => setBtnColorSelected(type)};
	}
`
const LeftButtonIcon = styled.span`
	line-height: 20px;
	flex: 1 1 16px;
	font-size: 16px;
	margin-right: 6px;
`
const RightButtonIcon = styled.span`
	line-height: 20px;
	flex: 1 1 16px;
	font-size: 16px;
	margin-left: 6px;
`
const ButtonText = styled.span`
	line-height: 20px;
	flex: 8 1 100%;
	font-size: 16px;
	font-weight: 600;
	padding-left: 30px;
	padding-right: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	justify-content: center;
`
const RotateDiv = styled.div`
	height: ${(props) => props.size};
	width: ${(props) => props.size};
	border: 2px solid rgba(150, 150, 150, 0.2);
	border-radius: 50%;
	border-top-color: ${(props) => props.primaryColor};
	animation: ${(props) => animateRotation(props.primaryColor, props.secondaryColor)} 1s 0s infinite ease-in-out
		alternate;
`

const LpButtonAsDivInner = (
	{ children, disabled, isLoading, type, width, height, leftIcon, rightIcon, ...props },
	ref
) => {
	return (
		<StyledButton
			ref={ref}
			disabled={disabled || isLoading}
			isLoading={isLoading}
			type={type}
			width={width}
			height={height}
			leftIcon={leftIcon}
			rightIcon={rightIcon}
			{...props}
		>
			{!!leftIcon || !!rightIcon || isLoading ? (
				<LeftButtonIcon>
					{leftIcon === "warning" && <FontAwesomeIcon icon={faExclamationCircle} color="var(--dark-red)" />}
					{leftIcon === "checked" && <FontAwesomeIcon icon={faCheckCircle} color="var(--dark-green)" />}
					{(!!rightIcon || isLoading) && null}
				</LeftButtonIcon>
			) : null}

			<ButtonText>{children}</ButtonText>

			{!!leftIcon || !!rightIcon || isLoading ? (
				<RightButtonIcon>
					{rightIcon === "link" && !isLoading && <FontAwesomeIcon icon={faExternalLinkAlt} color="white" />}
					{rightIcon === "share" && !isLoading && <FontAwesomeIcon icon={faShare} color="white" />}
					{isLoading && (
						<RotateDiv size="20px" primaryColor="white" secondaryColor="var(--secondary-color-pale)" />
					)}
					{!!leftIcon && null}
				</RightButtonIcon>
			) : null}
		</StyledButton>
	)
}

const LpButtonAsDiv = React.forwardRef(LpButtonAsDivInner)

export default LpButtonAsDiv
