import React from "react"
import { z } from "zod"
import { RegistrationStatus, User } from "learnink-common/lib/domains/userManagement/users/User.model"
import {
	Member,
	Operator,
	SearchFilter
} from "learnink-common/lib/domains/userManagement/userSearch/SearchFilter.model"
import { HeaderProps } from "./Header"

export type __User = User & {
	coursesStarted?: unknown
	coursesCompleted?: unknown
	actionList?: unknown
	certificateId: string
} //	TODO, this should probably not need to exist, we should make sure that the service has all the correct fields

export const ColumnLabel = z.enum([
	"Checkbox",
	"Notify",
	"Full name",
	"Status",
	"Referrer",
	"User groups",
	"Learning path",
	"First name",
	"Last name",
	"Last active",
	"Custom ID",
	"User ID",
	"Phone number",
	"Sex",
	"Country",
	"Region",
	"Age",
	"Notifications enabled",
	"Registered"
])

export type ColumnLabel = z.infer<typeof ColumnLabel>

export const FilterableMember = z.enum([
	Member.enum.full_name,
	Member.enum.registration_status,
	Member.enum.share_request_sender_ids,
	Member.enum.user_groups,
	Member.enum.learningpath_id,
	Member.enum.first_name,
	Member.enum.last_name,
	Member.enum.last_active,
	Member.enum.custom_id,
	Member.enum.user_id,
	Member.enum.phone_number,
	Member.enum.sex,
	Member.enum.country,
	Member.enum.region,
	Member.enum.age_bracket,
	Member.enum.app_downloaded,
	Member.enum.registered_at
])

export const COLUMN_LABEL_BY_MEMBER: { [key in FilterableMember]: ColumnLabel } = {
	full_name: "Full name",
	registration_status: "Status",
	share_request_sender_ids: "Referrer",
	user_groups: "User groups",
	learningpath_id: "Learning path",
	first_name: "First name",
	last_name: "Last name",
	last_active: "Last active",
	custom_id: "Custom ID",
	user_id: "User ID",
	phone_number: "Phone number",
	sex: "Sex",
	country: "Country",
	region: "Region",
	age_bracket: "Age",
	app_downloaded: "Notifications enabled",
	registered_at: "Registered",
}

export type FilterableMember = z.infer<typeof FilterableMember>

export type CellDisplay = string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> | null

export type UserGroupColours = Record<string, string>
export type UserGroupLabels = Record<string, string>
export type LearningPathById = Record<string, { internalName: string; color: string }>

export const REGISTRATION_STATUS_COLOURS: { [key in RegistrationStatus]: string | undefined } = {
	registered: "green",
	unregistered: "blue",
	archived: undefined
}

export type InputType = "datePicker" | "numberInput" | "stringInput" | "selectMultiple"

export interface WidthProperties {
	width?: number
	minWidth?: number
	maxWidth?: number
}

export interface HeaderColumn extends WidthProperties {
	Header: CellDisplay
	accessor: ColumnLabel
}

export interface ColumnDefinition extends WidthProperties {
	buildHeader: (props: HeaderProps) => CellDisplay
	buildRow: (user: __User, index: number) => CellDisplay
}

export interface Option {
	value: string | null
	label: string
}

/** This type describes the object that eventually gets sent to the server for filtering */
export type Filter = SearchFilter & {
	member: FilterableMember
	inputType: InputType
	valueLabels?: (string | number)[] // This field is intended for when the values aren't human readable
}

/** This type describes the object that gets manipulated in the filter UI */
export interface FilterOption {
	member: FilterableMember
	operators: Operator[]
	selectedOperator: Operator
	inputType: InputType
	options?: Option[]
	values?: Option[]
}

export const OPERATOR_LABEL: { [key in Operator]: string } = {
	empty: "has no value",
	notEmpty: "has a value",
	equals: "equals",
	notEquals: "does not equal",
	gt: "is greater than",
	lt: "is less than",
	in: "is in",
	notIn: "is not in",
	includesAll: "includes all",
	notIncludesAll: "does not include all",
	includesAny: "includes any",
	notIncludesAny: "does not include any",
	hasValue: "has a value",
	hasNoValue: "has no value",
	contains: "contains",
	notContains: "does not contain"
}

export type ModalType = "notification" | "certificate"

export interface UserIdsSelectedProps {
	allUsersSelected: boolean
	selectedUserIds: string[]
	unselectedUserIds: string[]
}

export const IGNORED_USER_GROUPS = ["registered", "admin", "moderators"]

