import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import * as S from "../../../styles/PageStyles"

import { userTableColumns } from "../../../management/userTable/UserTable.model"
import { selectOrgUserGroups } from "../../../../redux/org/org.selectors"
import { DefaultColumnFilter, SelectColumnFilter } from "../../../tables/tableFunctions"
import UserTableWrapper from "../../../management/userTable/UserTableWrapper"

const SurveysAnswersTablePanel = ({ questionIdsArr, surveyContent, queryData }) => {
	const questionColArr = surveyContent.filter((item) => questionIdsArr.includes(item.card_id))

	const questionColHeaders = questionColArr.map((x) => ({
		Header: x.content.message,
		accessor: x.card_id,
		Filter: x.content.survey_type === "multiple_choice" ? SelectColumnFilter : DefaultColumnFilter,
		width: 250
	}))

	const questionAnswers = queryData.filter((item) => questionIdsArr.includes(item.questionId))
	const uniqueIds = [...new Set(questionAnswers.map((item) => item.userId))]
	const tableData = []
	uniqueIds.forEach((userId) => {
		const userInfo = questionAnswers.filter((item) => item.userId === userId)[0]
		const row = {
			userId,
			customName: userInfo.customName,
			userGroupNames: userInfo.userGroupNames
		}
		questionColHeaders.forEach((question) => {
			row[question.accessor] =
				questionAnswers.filter((item) => item.questionId === question.accessor && item.userId === userId)
					.length > 0
					? questionAnswers.filter(
							(item) => item.questionId === question.accessor && item.userId === userId
					  )[0].surveyAnswerText
					: null
		})
		tableData.push(row)
	})

	return (
		<S.Card>
			<S.CardBody>
				<UserTableWrapper
					data={tableData}
					columns={[
						...userTableColumns.filter((item) =>
							["customName", "userGroupNames", "userId"].includes(item.id)
						),
						...questionColHeaders
					]}
					actions={["export"]}
					notifyButtonOn
					hiddenColumns={["userId"]}
				/>
			</S.CardBody>
		</S.Card>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(SurveysAnswersTablePanel)
