import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Space } from "antd"

import * as S from "../../styles/PageStyles"
import UserGroupsTags from "../../common/UserGroupsTags"

const ParagraphText = styled.p`
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 20px;
`
const BottomBtnsContainer = styled.div`
	display: flex;
	margin-top: 20px;
`

const CompleteImportUsers = ({
	userGroups,
	csvCheckResponse,
	userGroupUpdateBehaviour,
	handleImportRequest,
	setImportStage,
	setReturnToUsersScreen,
	userGroupsInImport
}) => {
	// used to handle csv file locally
	const handleSubmit = () => {
		handleImportRequest()
		setImportStage("summary")
	}

	return (
		<>
			<S.FormContainer>
				<S.FormTitle>Review and complete your import</S.FormTitle>

				<ParagraphText>
					<span style={{ fontWeight: 600 }}>{csvCheckResponse.cleanedData.validIndexList.length}</span>{" "}
					contacts will be added to your user List
				</ParagraphText>

				<ParagraphText>
					<span style={{ fontWeight: 600 }}>• Imported from:</span> csv file upload
				</ParagraphText>
				<ParagraphText>
					<span style={{ fontWeight: 600 }}>• User group update behaviour:</span> {userGroupUpdateBehaviour}
				</ParagraphText>
				{!userGroupsInImport && (
					<ParagraphText>
						<span style={{ fontWeight: 600 }}>• User Groups:</span>{" "}
						<UserGroupsTags selectedUserGroups={userGroups} />
					</ParagraphText>
				)}

				<BottomBtnsContainer>
					<Space>
						<Button shape="round" size="large" type="primary" onClick={handleSubmit}>
							Complete import
						</Button>
						<Button shape="round" size="large" onClick={() => setReturnToUsersScreen(true)}>
							Cancel import
						</Button>
					</Space>
				</BottomBtnsContainer>
			</S.FormContainer>
		</>
	)
}

CompleteImportUsers.propTypes = {
	userGroups: PropTypes.array.isRequired,
	csvCheckResponse: PropTypes.object.isRequired,
	userGroupUpdateBehaviour: PropTypes.string.isRequired,
	setImportStage: PropTypes.func.isRequired,
	setReturnToUsersScreen: PropTypes.func.isRequired
}

export default CompleteImportUsers
