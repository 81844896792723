// COLUMN FOR MODULE STAGES

import React, { Component } from "react"
import { Droppable } from "react-beautiful-dnd"
import { Alert } from "antd"
import styled from "styled-components"

import StageCardContainer from "../cards/StageCardContainer"

const CardsList = styled.div`
	padding: 8px;
	transition: background-color 0.2s ease;
	background-color: ${(props) => (props.isDraggingOver ? "var(--secondary-color-pale)" : "var(--off-white)")};
	flex-grow: 1;
	min-height: calc(100% - 44px);
`
const EmptyColumnNote = styled.div`
	display: flex;
	justify-content: center;
`

class CbStageColumn extends Component {
	componentDidUpdate = (prevProps) => {
		// Scroll to bottom of list if a new card has been added
		if (this.props.cards[this.props.cards.length - 2] === prevProps.cards[prevProps.cards.length - 1]) {
			this.scrollToBottom()
		}
	}

	scrollToBottom = () => {
		this.listEnd.scrollIntoView()
	}

	render() {
		const { droppableId, cards } = this.props
		return (
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<CardsList
						ref={provided.innerRef}
						{...provided.droppableProps}
						isDraggingOver={snapshot.isDraggingOver}
					>
						{cards.length === 0 &&
							droppableId !== "lesson_archive" &&
							droppableId !== "questions_archive" && (
								<EmptyColumnNote>
									<Alert
										type="info"
										message="Add your first card to this column with the ADD CARD button below"
									/>
								</EmptyColumnNote>
							)}
						{cards.map((card, index) => (
							<StageCardContainer key={card.id} card={card} index={index} col_id={droppableId} />
						))}
						{provided.placeholder}

						<div
							style={{ float: "left", clear: "both" }}
							ref={(el) => {
								this.listEnd = el
							}}
						/>
					</CardsList>
				)}
			</Droppable>
		)
	}
}

export default CbStageColumn
