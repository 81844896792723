import axios from "axios"
import { ResourcesActionTypes } from "./resources.types"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

// get all resources for org
export const getResourceListForOrg = (orgId) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.get(`/api/resources/${orgId}`)
		if (res.data) {
			dispatch({
				type: ResourcesActionTypes.SET_RESOURCE_LIST,
				payload: res.data
			})
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		console.log(err)
	}
}

// update resouce item
export const updateResourceItem = (orgId, resourceId, newResourceInfo) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.put(`/api/resources/${orgId}/resource/${resourceId}`, {
			info: newResourceInfo
		})
		if (res.data) {
			dispatch({
				type: ResourcesActionTypes.UPDATE_RESOURCE_ITEM,
				payload: res.data
			})
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		throw err
	}
}

// create new resource
export const createResourceItem = (orgId, newResourceInfo) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/resources/${orgId}/resource`, {
			info: newResourceInfo
		})
		if (res.data) {
			dispatch({
				type: ResourcesActionTypes.CREATE_NEW_RESOURCE,
				payload: res.data
			})
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		throw err
	}
}

// create new resource
export const deleteResourceItem = (orgId, resourceId) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.delete(`/api/resources/${orgId}/resource/${resourceId}`)
		console.log("API response DELETE /api/resources/:org_id/resource/:resource_id", res.data)
		if (res.data) {
			dispatch({
				type: ResourcesActionTypes.DELETE_RESOURCE,
				payload: res.data
			})
			const response = {
				status: "ok"
			}
			return response
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		throw err
	}
}

// clear resource list
export const clearResourcesList = () => async (dispatch) => {
	dispatch({
		type: ResourcesActionTypes.CLEAR_RESOURCE_LIST
	})
}
