import { SubscriptionActionTypes } from "./subscription.types"
import axios from "axios"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const setOrgSubscription = (org_id) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const orgSubscription = await axios.get(`/api/subscription/org/${org_id}`)
		dispatch({
			type: SubscriptionActionTypes.SET_ACTIVE_PLAN,
			payload: {
				...orgSubscription.data
			}
		})
	} catch (err) {
		console.log(err)
	}
}

export const setPlanList = () => async (dispatch) => {
	try {
		updateFirebaseToken()
		const planList = await axios.get(`/api/subscription/plans`)
		dispatch({
			type: SubscriptionActionTypes.SET_PLAN_LIST,
			payload: planList.data
		})
	} catch (err) {
		dispatch({
			type: SubscriptionActionTypes.SET_PLAN_LIST,
			payload: "error"
		})
	}
}

export const createCheckoutSession = (org_id, plan_id, coupon) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.post(`/api/subscription/create-checkout-session`, {
			org_id,
			plan_id,
			coupon
		})
		if (res && res.data && res.data.url) {
			return res.data.url
		} else {
			throw new Error("Error creating checkout session")
		}
	} catch (err) {
		console.log(err)
	}
}
