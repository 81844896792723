// DRAG AND DROP COMPONENT FOR COURSE BUILDER

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { DragDropContext } from "react-beautiful-dnd"
import { Progress, Typography, Alert } from "antd"

import CbCourseModulesColumn from "./columns/CbCourseModulesColumn"
import CbModuleStagesColumn from "./columns/CbModuleStagesColumn"
import CbCourseHelpColumn from "./columns/CbCourseHelpColumn"

import {
	selectCourse,
	selectObjectives,
	selectModuleId,
	selectCompletionData
} from "../../../redux/courses/courses.selectors"
import { updateCourseColumnsFromDrag, setPageParams } from "../../../redux/courses/courses.actions"

const { Text } = Typography

// allow room for head & foot and for x-scroll
const BaseContainer = styled.div`
	display: block;
	background-color: white;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
`
// create a fixed width container (example screen change for iPhone 5 with 320px screen)
const XaxisOverflowContainer = styled.div`
	display: flex;
	height: 100%;
	width: 996px;
	@media only screen and (max-width: 320px) {
		width: 888px;
	}
`
// create fixed column width here (example screen change for iPhone 5 with 320px screen)
const ColumnBaseContainer = styled.div`
	display: block;
	width: 330px;
	padding: 6px;
	height: calc(100% - 8px);
	@media only screen and (max-width: 320px) {
		width: 290px;
	}
`
// magic way to hack the droppable area issue, copied from card-wrapper class here https://github.com/markusenglund/react-kanban/blob/master/src/app/components/List/List.scss
// ⚠️👇 DO not change this container!! 👇⚠️
const YaxisOverflowColumnContainer = styled.div`
	height: 100%;
	border: 1px solid lightgrey;
	border-radius: 4px;
	width: 100%;
	margin: 0px 3px 6px 3px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--off-white);
	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(255, 255, 255, 0.2);
	}
`
// For Left hand column only
const LeftColumnContainer = styled.div`
  display: flex
  flex-direction: column;
  height: 100%;
  border: 0px solid red;
  border-radius: 4px;
  width: 100%;
  margin: 0px 3px 6px 3px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: none;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
`
const LeftColumnSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`
const WarningMsgWrapper = styled.div`
	flex: 1;
	height: 56px;
	margin: 6px 12px;
`

const CbCourseDragDropCanvas = ({
	course,
	completion_data,
	objectives,
	module_id,
	updateCourseColumnsFromDrag,
	setPageParams
}) => {
	// keep track of selected module on local state
	const selectModuleId = (id) => {
		if (id === module_id) {
			setPageParams({
				module_id: null
			})
		} else {
			setPageParams({
				module_id: id
			})
		}
	}

	const onDragEnd = (result) => {
		document.body.style.color = "inherit"
		document.body.style.backgroundColor = "inherit"
		updateCourseColumnsFromDrag(result, course, module_id)
	}

	// WARNING MESSAGES
	// ----------------
	const warningMessages = []

	if (completion_data.modules_count === 0) warningMessages.push("You must add at least one module to your course")
	if (completion_data.modules_without_stages > 0)
		warningMessages.push(`${completion_data.modules_without_stages} modules with no stages added`)
	if (completion_data.stage_lessons_unfinished > 0)
		warningMessages.push(`${completion_data.stage_lessons_unfinished} stages with an incomplete lesson`)
	if (completion_data.stage_questions_unfinished > 0)
		warningMessages.push(`${completion_data.stage_questions_unfinished} stages with incomplete questions`)

	// TODO: add Course navigator elements, I think this should be it's own
	// isolated component though that is shared across Stage Page and Course Page
	// and has a dedicated redux store object in course, i.e. course.navigation

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<BaseContainer>
				<XaxisOverflowContainer>
					<ColumnBaseContainer>
						<LeftColumnContainer>
							<LeftColumnSection>
								<WarningMsgWrapper>
									<Text type="secondary">Course is {completion_data.percent_complete}% complete</Text>
									<Progress percent={completion_data.percent_complete} showInfo={false} />
								</WarningMsgWrapper>
							</LeftColumnSection>
							<LeftColumnSection>
								{warningMessages.map((msg, index) => (
									<WarningMsgWrapper key={index}>
										<Alert type="error" message={msg} />
									</WarningMsgWrapper>
								))}
							</LeftColumnSection>
						</LeftColumnContainer>
					</ColumnBaseContainer>

					<ColumnBaseContainer>
						<YaxisOverflowColumnContainer>
							<CbCourseModulesColumn
								key="modules"
								droppableId="modules"
								title="Course Modules"
								cards={course.module_ids.map((id) => course.module_cards[id])}
								moduleIdSelected={module_id}
								selectModuleId={selectModuleId}
							/>
						</YaxisOverflowColumnContainer>
					</ColumnBaseContainer>

					{module_id && (
						<ColumnBaseContainer>
							<YaxisOverflowColumnContainer>
								<CbModuleStagesColumn
									key="stages"
									droppableId="stages"
									title="Module stages"
									cards={course.module_cards[module_id].stage_ids.map((id) => course.stage_cards[id])}
									moduleIdSelected={module_id}
								/>
							</YaxisOverflowColumnContainer>
						</ColumnBaseContainer>
					)}

					{!module_id && (
						<ColumnBaseContainer>
							<CbCourseHelpColumn />
						</ColumnBaseContainer>
					)}
				</XaxisOverflowContainer>
			</BaseContainer>
		</DragDropContext>
	)
}

const mapStateToProps = createStructuredSelector({
	objectives: selectObjectives,
	course: selectCourse,
	module_id: selectModuleId,
	completion_data: selectCompletionData
})

const mapDispatchToProps = (dispatch) => ({
	updateCourseColumnsFromDrag: (result, course, module_id) =>
		dispatch(updateCourseColumnsFromDrag(result, course, module_id)),
	setPageParams: (paramsObj) => dispatch(setPageParams(paramsObj))
})

// TODO: update proptypes to be more precise here!
CbCourseDragDropCanvas.propTypes = {
	objectives: PropTypes.shape(PropTypes.obj).isRequired,
	course: PropTypes.shape(PropTypes.obj).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CbCourseDragDropCanvas)
