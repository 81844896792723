import { Button, Tooltip } from "antd"
import { BellOutlined } from "@ant-design/icons"
import React from "react"
import * as S from "../../components/common/infiniteScrollTable/Table.styles"
import { __User } from "./UserSearch.model"

interface Props {
	user: __User
	openMessageModal: (user: __User) => void
}

export default function NotifyButton({ user, openMessageModal }: Props) {
	const isDisabled = !user.appDownloaded
	const tooltipTitle =
		"You can only send notifications to users who have downloaded the Learn.ink app and enabled notifications on their phone"

	function TooltipOrNone({ children }: { children: React.ReactNode }) {
		return isDisabled ? <Tooltip title={tooltipTitle}>{children}</Tooltip> : <>{children}</>
	}

	return (
		<S.FlexboxLeftAlignText>
			<TooltipOrNone>
				<Button
					onClick={() => openMessageModal(user)}
					shape="round"
					icon={<BellOutlined />}
					size="small"
					type="primary"
					disabled={isDisabled}
				>
					Notify
				</Button>
			</TooltipOrNone>
		</S.FlexboxLeftAlignText>
	)
}
