import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectLearningPaths } from "../../../redux/org/org.selectors"
import LearningPathNav from "../nav/LearningPathNav"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import LearningPathProgress from "../charts/learning-path/LearningPathProgress"
import useUrlQueryString from "../../hooks/useUrlQueryString"
import SurveysDash from "./SurveysDash"
import LearningPathActivity from "../charts/learning-path/LearningPathActivity"

interface Props {
	learningPaths: LearningPath[]
	view: "progress" | "surveys" | "activity"
}

const LearningPathDash = ({ learningPaths, view }: Props) => {
	const [learningPathId] = useUrlQueryString("id")
	const learningPath =
		learningPaths.filter((item) => item.id === learningPathId).length > 0
			? learningPaths.filter((item) => item.id === learningPathId)[0]
			: undefined

	if (!learningPath) {
		return <div>Error</div>
	}

	return (
		<div className="container-fluid">
			<LearningPathNav view={view} learningPath={learningPath} />
			{view === "progress" && <LearningPathProgress learningPath={learningPath} />}
			{view === "activity" && <LearningPathActivity learningPath={learningPath} />}
			{view === "surveys" && <SurveysDash type="learningPath" learningPath={learningPath} />}
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	learningPaths: selectLearningPaths
})

export default connect(mapStateToProps)(LearningPathDash)
