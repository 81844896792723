import React from "react"
import styled from "styled-components"

import UserTable from "./UserTable"

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 260px;
	width: 96%;
	overflow-x: scroll;
`

const Styles = styled.div`
	padding: 1rem;

	.table {
		display: inline-block;
		border-spacing: 0;
		border: 1px solid var(--light-grey);

		.tr {
			:nth-child(even) {
				background: #f6f6f6;
			}
			:hover {
				background: var(--secondary-color-pale);
			}
		}

		.th,
		.td {
			margin: 0;
		}
		.th {
			border-bottom: solid 1px var(--light-grey);
			padding-top: 10px;
		}
		.td {
			line-height: 50px;
			overflow-x: scroll;
			overflow-y: hidden;
			-ms-overflow-style: none;
			scrollbar-width: none;
			cursor: pointer;
			padding-left: 6px;
		}
		.td::-webkit-scrollbar {
			display: none;
		}
	}
`

const UserTableWrapper = ({
	data,
	columns,
	notifyButtonOn,
	actions,
	removeUsersFromList,
	hiddenColumns,
	refreshData,
	sidePanel
}) => {
	return (
		<MainContainer>
			<Styles>
				<UserTable
					data={data}
					columns={columns}
					notifyButtonOn={notifyButtonOn}
					removeUsersFromList={removeUsersFromList}
					actions={actions}
					hiddenColumns={hiddenColumns}
					refreshData={refreshData}
					sidePanel={sidePanel}
				/>
			</Styles>
		</MainContainer>
	)
}

export default UserTableWrapper
