import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography, Form, Space, Input, Select } from "antd"

import { folderColors } from "../../../analyse/common/colors"
import { selectOrgId } from "../../../../redux/org/org.selectors"
import { createCourseFolder } from "../../../../redux/courses/courses.actions"

const { Title } = Typography
const { Option } = Select

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
	padding: 30px 30px 10px 30px;
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`

const CreateFolder = ({ hideModal, orgId, parentId, createCourseFolder }) => {
	const handleSubmit = async (values) => {
		try {
			const { folder_name, folder_color } = values
			await createCourseFolder(orgId, {
				folder_name,
				course_folder_parent_id: parentId,
				folder_color
			})
			hideModal()
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<ContentContainer>
			<Title level={4}>Create a folder</Title>
			<Divider borderOnBottom={true} height="10px" />
			<Divider height="20px" />
			<Form
				autoComplete="off"
				onFinish={handleSubmit}
				layout="vertical"
				initialValues={{
					folder_color: folderColors[0]
				}}
			>
				<Form.Item
					label={<FormLabel>Folder name</FormLabel>}
					name="folder_name"
					rules={[
						{
							required: true,
							message: "You need to add a folder name"
						}
					]}
					tooltip="Make sure you use something descriptive!"
				>
					<Input placeholder="Enter folder name..." />
				</Form.Item>
				<Form.Item label={<FormLabel>Folder colour</FormLabel>} name="folder_color">
					<Select style={{ width: 100 }}>
						{folderColors.map((value, index) => (
							<Option value={value} key={index}>
								<span
									className="color-selector"
									style={{
										fontSize: 12,
										color: value,
										background: value,
										padding: "2px 10px 2px 10px"
									}}
								>
									color
								</span>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<ButtonContainer>
						<Space>
							<Button shape="round" size="large" onClick={hideModal}>
								Cancel
							</Button>
							<Button type="primary" shape="round" size="large" htmlType="submit">
								Create folder
							</Button>
						</Space>
					</ButtonContainer>
				</Form.Item>
			</Form>
		</ContentContainer>
	)
}

PropTypes.CreateFolder = {
	hideModal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	createCourseFolder: (org_id, folder_data) => dispatch(createCourseFolder(org_id, folder_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolder)
