export const CoursesActionTypes = {
	SET_PAGE_PARAMS: "SET_PAGE_PARAMS",
	SET_STAGE_DATA: "SET_STAGE_DATA",
	SET_OBJECTIVES_DATA: "SET_OBJECTIVES_DATA",
	STAGE_UPDATE_SAME_COLUMN: "STAGE_UPDATE_SAME_COLUMN",
	STAGE_UPDATE_NEW_COLUMN: "STAGE_UPDATE_NEW_COLUMN",
	UPDATE_LESSON_CARD_CONTENT: "UPDATE_LESSON_CARD_CONTENT",
	UPDATE_QUESTION_CARD_CONTENT: "UPDATE_QUESTION_CARD_CONTENT",
	CREATE_NEW_LESSON_CARD: "CREATE_NEW_LESSON_CARD",
	CREATE_NEW_QUESTION_CARD: "CREATE_NEW_QUESTION_CARD",
	DELETE_STAGE_CONTENT_CARD: "DELETE_STAGE_CONTENT_CARD",
	UPDATE_OBJECTIVE: "UPDATE_OBJECTIVE",
	CREATE_OBJECTIVE: "CREATE_OBJECTIVE",
	SWITCH_STAGE_OBJECTIVE: "SWITCH_STAGE_OBJECTIVE",
	SWITCH_STAGE_CARD_OBJECTIVE: "SWITCH_STAGE_CARD_OBJECTIVE",
	SET_COURSE_DATA: "SET_COURSE_DATA",
	COURSE_UPDATE_MODULES_COLUMN: "COURSE_UPDATE_MODULES_COLUMN",
	MODULE_UPDATE_STAGES_COLUMN: "MODULE_UPDATE_STAGES_COLUMN",
	UPDATE_COURSE_INFO: "UPDATE_COURSE_INFO",
	CREATE_NEW_MODULE_CARD: "CREATE_NEW_MODULE_CARD",
	DELETE_MODULE_CARD: "DELETE_MODULE_CARD",
	UPDATE_MODULE_CARD: "UPDATE_MODULE_CARD",
	CREATE_NEW_STAGE_CARD_IN_MODULE: "CREATE_NEW_STAGE_CARD_IN_MODULE",
	UPDATE_STAGE_CARD: "UPDATE_STAGE_CARD",
	DELETE_STAGE_CARD: "DELETE_STAGE_CARD",
	MOVE_STAGE_CARD_TO_NEW_MODULE: "MOVE_STAGE_CARD_TO_NEW_MODULE",
	CLEAR_COURSE_DATA: "CLEAR_COURSE_DATA",
	CLEAR_OBJECTIVES_DATA: "CLEAR_OBJECTIVES_DATA",
	CLEAR_STAGE_DATA: "CLEAR_STAGE_DATA",
	CLEAR_PAGE_PARAMS_DATA: "CLEAR_PAGE_PARAMS_DATA",
	UPDATE_CARD_EDITOR_SETTINGS: "UPDATE_CARD_EDITOR_SETTINGS"
}
