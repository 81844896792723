import React from "react"
import styled from "styled-components"
import { Typography, Button, Dropdown, Space, Menu } from "antd"
import { DownOutlined } from "@ant-design/icons"

const { Title } = Typography

const FixedContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${({ marginTop }) => (marginTop ? marginTop : "30px")};
	margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "30px")};
`
const FixedRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 590px) {
		flex-direction: column;
	}
`
const ExtraBtnsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 20px;
	@media (max-width: 590px) {
		flex-direction: column-reverse;
		margin-right: 0px;
	}
`
const ColumnCheckBoxesContainer = styled.div`
	display: flex;
	margin-top: 20px;
	width: 100%;
	flex-wrap: wrap;
`
const ColumnCheckBoxesAllToggle = styled.button`
	padding: 6px;
	font-size: 14px;
	color: var(--dark-grey);
	font-weight: 600;
`
const ColumnCheckBoxContainer = styled.div`
	padding: 6px;
	font-size: 14px;
	color: var(--mid-grey);
`
const SelectAndSearchContainer = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 590px) {
		flex-direction: column-reverse;
	}
`

const QuestionsTableFunctions = ({
	toggleColFilters,
	showColFilters,
	setAllFilters,
	filters,
	handleDownload,
	selectedRowIds,
	allColumns,
	setHiddenColumns
}) => {
	const menu = (
		<Menu
			items={[
				{
					key: "export",
					label: <div onClick={handleDownload}>Export data</div>
				}
			]}
		/>
	)

	return (
		<FixedContainer marginTop="0px">
			{/* Top part includes reg status summary and global search.... */}

			<FixedRow>
				<SelectAndSearchContainer>
					{/* Selected user count... */}
					{Object.keys(selectedRowIds).length > 0 && (
						<Title level={5} style={{ color: "var(--secondary-color)", paddingLeft: 5 }}>
							{Object.keys(selectedRowIds).length} questions selected
						</Title>
					)}
				</SelectAndSearchContainer>

				<ExtraBtnsContainer>
					<Space>
						<Button onClick={toggleColFilters}>Change columns</Button>
						{filters && filters.length > 0 && (
							<Button onClick={() => setAllFilters([])}>Reset filters</Button>
						)}
						{Object.keys(selectedRowIds).length > 0 && (
							<Dropdown overlay={menu} placement="bottomRight">
								<Button type="primary" shape="round">
									<Space>
										Actions
										<DownOutlined />
									</Space>
								</Button>
							</Dropdown>
						)}
					</Space>
				</ExtraBtnsContainer>
			</FixedRow>

			{/* Checkboxes to handle hiding/showing columns (hidden unless filters toggled on)... */}
			<FixedRow>
				{showColFilters && (
					<ColumnCheckBoxesContainer>
						<ColumnCheckBoxesAllToggle
							onClick={() => {
								// if any column has been made invisible then show all
								if (allColumns.filter((column) => !column.isVisible).length > 0) {
									setHiddenColumns([]) // function shows all columns when provided empty array
								}
								// if all columns are selected hide all columns except selection, edit and userId fields
								else {
									setHiddenColumns(
										allColumns
											.filter(
												(column) =>
													column.id !== "selection" &&
													column.id !== "edit" &&
													column.id !== "userId"
											)
											.map((column) => column.id)
									)
								}
							}}
						>
							{allColumns.filter((column) => !column.isVisible).length > 0
								? "Toggle All On"
								: "Toggle All Off"}
						</ColumnCheckBoxesAllToggle>

						{allColumns
							.filter(
								(column) => column.id !== "selection" && column.id !== "edit" && column.id !== "userId"
							)
							.map((column) => (
								<ColumnCheckBoxContainer key={column.id}>
									<label>
										<input type="checkbox" {...column.getToggleHiddenProps()} /> {column.Header}
									</label>
								</ColumnCheckBoxContainer>
							))}
					</ColumnCheckBoxesContainer>
				)}
			</FixedRow>
		</FixedContainer>
	)
}

export default QuestionsTableFunctions
