import React, { useState, useEffect } from "react"
import { Redirect, Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { auth } from "../../firebase/firebase.util"
import axios from "axios"
import { Alert } from "antd"

import LpTextInput from "../../components/common/LpTextInput"
import LpButton from "../../components/common/LpButton"
import LpButtonAsDiv from "../../components/common/LpButtonAsDiv"

import { selectfirebaseAuthInfo, selectUserOrgKeyArray } from "../../redux/user/user.selectors"
import { setUserInfo } from "../../redux/user/user.actions"

import CbLoading from "../../components/common/CbLoading"

const LoadingContainer = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`
const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 120px;
	@media (max-width: 850px) {
		padding: 40px;
	}
`
const FormTitle = styled.div`
	width: 100%;
	text-align: left;
	color: ${(props) => (props.color ? props.color : "black")};
	display: block;
	font-size: 32px;
	font-weight: 800;
	line-height: 1.2;
`
const SubTitle = styled.div`
	width: 100%;
	text-align: left;
	margin-top: 16px;
	margin-bottom: 16px;
	color: var(--dark-grey);
	display: block;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2;
`
const ErrorBox = styled.div`
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;
`

const VerifyEmail = ({
	//userAuthStatus,
	userOrgKeyArray,
	//setUserInfo,
	firebaseAuthInfo
}) => {
	const [pauseFirebaseAuthAttempts, setPauseFirebaseAuthAttempts] = useState(false) // if user email isn't stored or is wrong...
	const [storedEmail, setStoredEmail] = useState(window.localStorage.getItem("emailForSignIn"))
	const [errorMsg, setErrorMsg] = useState(null)
	const [invalidLink, setInvalidLink] = useState(false)

	useEffect(() => {
		if (!storedEmail) {
			setPauseFirebaseAuthAttempts(true)
		}
	}, [storedEmail])

	const handleEmailChange = (text) => {
		setErrorMsg(null)
		setStoredEmail(text)
	}
	const handleButtonSubmit = (e) => {
		e.preventDefault()
		setPauseFirebaseAuthAttempts(false)
	}

	// Listens for case where user hits the page using the link from email
	if (pauseFirebaseAuthAttempts === false && auth.isSignInWithEmailLink(window.location.href)) {
		// Get the email if available.
		//let storedEmail = window.localStorage.getItem('emailForSignIn');

		// User opened the link on a different device. To prevent session fixation attacks, ask the
		// user to provide the associated email again. For example:
		if (!storedEmail) {
			setPauseFirebaseAuthAttempts(true)
		} else {
			auth.signInWithEmailLink(storedEmail, window.location.href)
				.then(() => {
					// Clear email from storage.
					window.localStorage.removeItem("emailForSignIn")
				})
				.catch(function (err) {
					console.log(err)
					if (err.code === "auth/invalid-email") {
						setPauseFirebaseAuthAttempts(true)
					}
					if (err.code === "auth/invalid-action-code") {
						setPauseFirebaseAuthAttempts(true)
						setInvalidLink(true)
					}
				})
		}
	}

	// listens for firebase auth token... triggered in App.ts
	if (pauseFirebaseAuthAttempts === false && firebaseAuthInfo && firebaseAuthInfo.auth_token) {
		let instance = axios.create({
			baseUrl: "" // probably best to refer to as API_URL in future
		})
		// set an instance of axios with token in auth header
		instance.defaults.headers.common["Authorization"] = `Bearer ${firebaseAuthInfo.auth_token}`

		instance
			.post("/api/user/create")
			.then(() => {
				// NOTE: we brute force a window refresh here because
				// the DB update may well return asynchronously after
				// we set user info from firebase auth loading App.ts
				// However, we want to setUserInfo in the same way there
				// each time

				window.location.reload()

				//setUserInfo({ ...res.data }); <-- used to setUserInfo here but was creating bugs!
			})
			.catch((err) => {
				console.log(err)
			})
	}

	// if user exists and has an org already send them to their org
	if (userOrgKeyArray.length > 0) return <Redirect to={`/org/${userOrgKeyArray[0]}`} />

	// else if user auth'd redirect them to create org
	if (firebaseAuthInfo && firebaseAuthInfo.auth_token && firebaseAuthInfo.emailVerified === true)
		return <Redirect to={"/create-org"} />

	if (invalidLink === true)
		return (
			<FormContainer>
				<FormTitle>Hmmmm... looks like this link might have expired</FormTitle>
				<SubTitle>Let's get you a fresh link now!</SubTitle>
				<div style={{ height: "40px" }} />
				<Link to={"/signup"}>
					<LpButtonAsDiv width="100%">Get New link</LpButtonAsDiv>
				</Link>
			</FormContainer>
		)

	if (pauseFirebaseAuthAttempts === true)
		return (
			<FormContainer>
				<FormTitle>Looks like you opened this link on a new device?</FormTitle>
				<SubTitle>For security reasons please re-enter the email address you signed in with below</SubTitle>
				<div style={{ height: "20px" }} />
				<LpTextInput labelText="Email" placeholderText="Enter email..." handleTextChange={handleEmailChange} />
				<div style={{ height: "40px" }} />
				<LpButton width="100%" onClick={handleButtonSubmit}>
					Confirm
				</LpButton>
				{errorMsg && (
					<ErrorBox>
						<Alert message={errorMsg} type="error" />
					</ErrorBox>
				)}
			</FormContainer>
		)

	// otherwise we show loading...
	return (
		<LoadingContainer>
			<CbLoading loadingText="Verifying" padding="30px" />
		</LoadingContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	//userAuthStatus: selectUserAuthStatus,
	userOrgKeyArray: selectUserOrgKeyArray,
	firebaseAuthInfo: selectfirebaseAuthInfo
})

const mapDispatchToProps = (dispatch) => ({
	setUserInfo: (userInfo) => dispatch(setUserInfo(userInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
