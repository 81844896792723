import axios from "axios"
import moment from "moment"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	// this will get the user's firebase token and fetch a new one if it has expired
	const user = auth.currentUser
	if (user) {
		const token = await user.getIdToken()
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
	}
}

const defaultStartDate = "2000-01-01"

export const formatDateRange = (dateRange: string) => {
	let startDate = null
	let endDate = null
	if (dateRange === "today") {
		startDate = moment().format("YYYY-MM-DD")
	} else if (dateRange === "yesterday") {
		startDate = moment().subtract(1, "d").format("YYYY-MM-DD")
		endDate = moment().format("YYYY-MM-DD")
	} else if (dateRange === "from 7 days ago to now") {
		startDate = moment().subtract(7, "d").format("YYYY-MM-DD")
	} else if (dateRange === "from 14 days ago to now") {
		startDate = moment().subtract(14, "d").format("YYYY-MM-DD")
	} else if (dateRange === "from 28 days ago to now") {
		startDate = moment().subtract(28, "d").format("YYYY-MM-DD")
	} else if (dateRange === "from 90 days ago to now") {
		startDate = moment().subtract(90, "d").format("YYYY-MM-DD")
	} else if (dateRange && dateRange !== "null" && dateRange !== "undefined" && dateRange !== "all time") {
		startDate = JSON.parse(dateRange)[0]
		endDate = moment(JSON.parse(dateRange)[1]).add(1, "d").format("YYYY-MM-DD")
	}
	return {
		startDate,
		endDate
	}
}

export const fetchAnalytics = async (
	api: string,
	orgId: string,
	route: string,
	dateRange: string,
	userGroups?: string,
	params?: string
) => {
	try {
		await updateFirebaseToken()
		const { startDate, endDate } = formatDateRange(dateRange)

		const startDateParams = startDate ? `startDate=${startDate}` : `startDate=${defaultStartDate}`
		const endDateParams = endDate ? `&endDate=${endDate}` : ``
		const userGroupParams = userGroups ? `&userGroups=${userGroups}` : ``
		const additionalParams = params ? params : ``

		const queryString = `/api/${api}/${orgId}/${route}?${startDateParams}${endDateParams}${userGroupParams}${additionalParams}`

		const response = await axios.get(queryString)
		if (response && response.data) {
			return response.data
		} else {
			throw new Error("Error getting data, please try again")
		}
	} catch (err) {
		console.log(err)
		throw new Error("Error getting data, please try again")
	}
}
