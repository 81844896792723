import React from "react"
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from "recharts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import moment from "moment"
import numeral from "numeral"

import { selectOrgUserGroups } from "../../../../redux/org/org.selectors"

import ChartWrapper from "../../common/ChartWrapper"
import GeNoItemsFound from "../../../common/GeNoItemsFound"

const numberFormatter = (item) => numeral(item).format("0,0")
const dateFormatter = (item) => moment(item).format("DD MMM")

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div
				style={{
					backgroundColor: "white",
					padding: 10,
					border: "1px solid rgb(204, 204, 204)",
					whiteSpace: "nowrap"
				}}
			>
				<p className="label">{`${dateFormatter(label)}`}</p>
				{payload.map((series, index) => (
					<p className="label" key={index}>
						<span style={{ color: series.stroke }}>{series.name}: </span>
						{series.value}
					</p>
				))}
			</div>
		)
	}

	return null
}

const EventActivityArea = ({ queryData, userGroupsArr, orgUserGroups }) => {
	return (
		<ChartWrapper
			title="Active users by day"
			tooltip="Shows the total number of active users by day over the timeframe"
		>
			<ResponsiveContainer width="100%" height={300}>
				{queryData.length > 0 ? (
					<LineChart data={queryData}>
						<XAxis dataKey="day" tickFormatter={dateFormatter} tickCount="2" minTickGap="1" />
						<YAxis tickFormatter={numberFormatter} />
						<CartesianGrid horizontalFill={["#e6e6e6"]} fillOpacity={0.2} />
						{userGroupsArr.map((userGroup, i) => (
							<Line
								key={userGroup}
								stackId="a"
								dataKey={userGroup}
								name={
									orgUserGroups[userGroup] ? orgUserGroups[userGroup].user_group_info.name : userGroup
								}
								stroke={orgUserGroups[userGroup].color}
								fill={orgUserGroups[userGroup].color}
							/>
						))}
						<Legend />
						<Tooltip content={<CustomTooltip />} />
					</LineChart>
				) : (
					<GeNoItemsFound
						titleText="No data"
						subtitleText="We couldn't find any data, try changing your date range and/or user group filter"
						icon={<FontAwesomeIcon icon={faExclamationCircle} />}
					/>
				)}
			</ResponsiveContainer>
		</ChartWrapper>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(EventActivityArea)
