import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Table, Space } from "antd"

import * as S from "../../styles/PageStyles"
import UserGroupsTags from "../../common/UserGroupsTags"

const FormContainer = styled.div`
	max-width: 90%;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	width: 100%;
`
const Subtitle = styled.p`
	font-size: 18px;
	font-weight: 500;
	color: black;
	margin-bottom: 20px;
`
const ErrorLogContainer = styled.div`
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
`
const ErrorLogMessages = styled.p`
	font-size: 14px;
	color: var(--dark-red);
	margin-left: 12px;
	margin-top: 8px;
`
const BottomBtnsContainer = styled.div`
	display: flex;
	margin-top: 30px;
`

const ReviewImportedUsers = ({
	setImportStage,
	csvCheckResponse,
	setLocalCsvFile,
	customIdsInImport,
	customNamesInImport,
	userGroupsInImport
}) => {
	const handleRetryImport = () => {
		setLocalCsvFile(null)
		setImportStage("import")
	}

	const dataSource = React.useMemo(
		() =>
			csvCheckResponse.cleanedData.data.map((item, index) => {
				return {
					key: index + 1,
					row: index + 1,
					phone_number: item["Phone number"],
					first_name: item["First name"],
					last_name: item["Last name"],
					custom_id: item["Custom id"],
					custom_name: item["Custom name"],
					user_groups: item["User groups"]
				}
			}),
		[csvCheckResponse]
	)

	const columns = [
		{
			title: "Row",
			dataIndex: "row",
			key: "row"
		},
		{
			title: "Phone number",
			dataIndex: "phone_number",
			key: "phone_number"
		},
		{
			title: "First name",
			dataIndex: "first_name",
			key: "first_name"
		},
		{
			title: "Last name",
			dataIndex: "last_name",
			key: "last_name"
		}
	]

	// only show user
	if (customIdsInImport === true)
		columns.push({
			title: "Custom id",
			dataIndex: "custom_id",
			key: "custom_id"
		})

	if (customNamesInImport === true)
		columns.push({
			title: "Custom name",
			dataIndex: "custom_name",
			key: "custom_name"
		})

	if (userGroupsInImport === true)
		columns.push({
			title: "User groups",
			dataIndex: "user_groups",
			key: "user_groups",
			render: (_, { user_groups }) => <UserGroupsTags selectedUserGroups={user_groups} />
		})

	let disableProceed = false
	if (csvCheckResponse.errData.criticalError) disableProceed = true
	if (csvCheckResponse.cleanedData.validIndexList.length === 0) disableProceed = true

	return (
		<React.Fragment>
			<FormContainer>
				{csvCheckResponse.errData.criticalError === true ? (
					<>
						<S.FormTitle>Error</S.FormTitle>
						<Subtitle style={{ color: "var(--dark-red)" }}>
							<FontAwesomeIcon icon={faExclamationCircle} /> {csvCheckResponse.errData.criticalErrorMsg}
						</Subtitle>
					</>
				) : (
					<>
						<S.FormTitle>Review before importing</S.FormTitle>

						<Subtitle>
							<span style={{ fontWeight: 700 }}>
								{csvCheckResponse.cleanedData.validIndexList.length}
							</span>{" "}
							users can be imported from this file
						</Subtitle>

						{csvCheckResponse.errData.errorLog.length > 0 && (
							<>
								<Divider height={"20px"} />
								<Subtitle style={{ color: "var(--dark-red)" }}>
									<span style={{ fontWeight: 700 }}>{csvCheckResponse.errData.errorLog.length}</span>{" "}
									issues detected
								</Subtitle>
								<Divider height={"6px"} />
								<ErrorLogContainer>
									{csvCheckResponse.errData.errorLog.map((err, index) => (
										<ErrorLogMessages key={index}>• {err}</ErrorLogMessages>
									))}
								</ErrorLogContainer>
							</>
						)}

						<Divider />
					</>
				)}

				<Table
					dataSource={dataSource}
					columns={columns}
					rowClassName={(record, index) =>
						!csvCheckResponse.cleanedData.validIndexList.includes(index) ? "invalidTableRow" : ""
					}
				/>

				<BottomBtnsContainer>
					<Space>
						<Button shape="round" size="large" onClick={handleRetryImport}>
							Retry import
						</Button>
						<Button
							type="primary"
							shape="round"
							size="large"
							disabled={disableProceed}
							onClick={() => setImportStage("tag")}
						>
							Next
						</Button>
					</Space>
				</BottomBtnsContainer>
			</FormContainer>
		</React.Fragment>
	)
}

ReviewImportedUsers.propTypes = {
	setImportStage: PropTypes.func.isRequired,
	setLocalCsvFile: PropTypes.func.isRequired,
	csvCheckResponse: PropTypes.object.isRequired,
	customIdsInImport: PropTypes.bool.isRequired,
	userGroupsInImport: PropTypes.bool.isRequired
}

export default ReviewImportedUsers
