// MODAL TO PUBLISH A COURSE
// need to finish this to add error handling and share links

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Alert, Form, Switch, Button, Space, Typography, Radio } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { selectCompletionData, selectCourse } from "../../../../redux/courses/courses.selectors"
import { publishCourse, createCoursePreview } from "../../../../redux/courses/courses.actions"

import CbLoading from "../../../common/CbLoading"
import UserGroupsSelector from "../../../common/UserGroupsSelector"
import TooltipIcon from "../../../common/TooltipIcon"
import ShareCourse from "../../courses/modals/ShareCourse"

const { Title } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 10px;
`
const ParagraphText = styled.p`
	color: black;
	font-size: 16px;
	padding: 8px 0px;
`
const OptionRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	padding-top: 4px;
	padding-bottom: 4px;
`
const SwitchText = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: black;
`

const PublishCourse = ({
	closemodal,
	orgId,
	course: { course_id, info, module_ids },
	completionData: { percent_complete },
	publishCourse,
	createCoursePreview
}) => {
	const challengesAvailable = module_ids.length > 1
	const [modalView, setModalView] = useState("confirm") // can be 'confirm', 'loading' 'share'
	const [useBaseline, setUseBaseline] = useState(
		info && info.publish_options ? info.publish_options.useBaseline : false
	)
	const [useChallenge, setUseChallenge] = useState(
		challengesAvailable ? (info && info.publish_options ? info.publish_options.useChallenge : false) : false
	)
	const [publishStatus, setPublishStatus] = useState("publish")
	const [previewUrl, setPreviewUrl] = useState(info.preview_url)
	const [publishUrl, setPublishUrl] = useState(info.publish_url)

	const updatePublishStatus = (e) => {
		setPublishStatus(e.target.value)
	}

	const handlePublish = async (values) => {
		try {
			setModalView("loading")
			const { user_groups } = values
			const publish_options = {
				useBaseline,
				useChallenge
			}
			if (publishStatus === "publish") {
				const view_groups =
					user_groups.length === 1 && user_groups[0] === "registered" ? ["public"] : user_groups
				const response = await publishCourse(orgId, course_id, publish_options, {
					view_groups,
					access_groups: user_groups
				})
				setPublishUrl(response.publish_url)
				setTimeout(() => {
					setModalView("share")
				}, 10000)
			} else {
				const response = await createCoursePreview(orgId, course_id, publish_options)
				setPreviewUrl(response.preview_url)
				setTimeout(() => {
					setModalView("share")
				}, 10000)
			}
		} catch (err) {
			setTimeout(() => {
				setModalView("error")
			}, 5000)
		}
	}

	if (percent_complete !== 100) {
		return (
			<ContentContainer>
				<Title level={4}>Publish course</Title>
				<Alert
					message={`Uh oh, it looks like your course is only ${percent_complete}% complete! Make sure you complete all stages of your course before publishing`}
					type="warning"
				/>
			</ContentContainer>
		)
	}

	if (modalView === "error") {
		return (
			<ContentContainer>
				<Title level={4}>Error</Title>
				<Alert
					message={`Sorry it looks like something went wrong. Please check your connection and try again`}
					type="warning"
				/>
			</ContentContainer>
		)
	}

	if (modalView === "confirm")
		return (
			<ContentContainer>
				<Form
					autoComplete="off"
					onFinish={handlePublish}
					layout="vertical"
					initialValues={{
						user_groups: info.user_groups.access_groups
					}}
				>
					<Form.Item name="publish_status" valuePropName="checked">
						<Radio.Group
							size="large"
							onChange={(e) => updatePublishStatus(e)}
							value={publishStatus}
							buttonStyle="solid"
						>
							<Radio.Button value="publish">Publish course</Radio.Button>
							<Radio.Button value="preview">Create preview</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Alert
						type="warning"
						message={
							publishStatus === "publish"
								? "If you select the publish option you will send all of your updates to your live app. Make sure you're ready to do this!"
								: "This will create a preview course link that you can use to test your course before publishing"
						}
					/>
					<Divider height={"15px"} />
					<OptionRow>
						<SwitchText>
							Add baseline quizzes{" "}
							<TooltipIcon tooltip="Switch this on to add baseline quizzes to all modules in your course" />
						</SwitchText>
						<Switch checked={useBaseline} onChange={(checked) => setUseBaseline(checked)} />
					</OptionRow>
					{challengesAvailable ? (
						<OptionRow>
							<SwitchText>
								Add challenges <TooltipIcon tooltip={"Add challenges to your course"} />
							</SwitchText>
							<Switch checked={useChallenge} onChange={(checked) => setUseChallenge(checked)} />
						</OptionRow>
					) : (
						<OptionRow>
							<SwitchText style={{ color: "var(--mid-grey)" }}>
								Add challenges{" "}
								<TooltipIcon
									tooltip={"You need at least 2 modules to your course to switch on challenges"}
								/>
							</SwitchText>
							<Switch checked={useChallenge} disabled={true} />
						</OptionRow>
					)}
					<Divider height={"15px"} />
					{publishStatus === "publish" && (
						<UserGroupsSelector
							tooltip="These are the groups that can access your course"
							labelFontSize="16px !important"
							required
						/>
					)}
					<Form.Item style={{ marginBottom: 0 }}>
						<ButtonContainer>
							<Space>
								<Button size="large" shape="round" onClick={closemodal}>
									Cancel
								</Button>
								<Button type="primary" htmlType="submit" size="large" shape="round">
									{publishStatus === "publish" ? "Publish course" : "Create preview"}
								</Button>
							</Space>
						</ButtonContainer>
					</Form.Item>
				</Form>
			</ContentContainer>
		)
	else if (modalView === "loading")
		return (
			<ContentContainer>
				<ParagraphText>This could take a few minutes, stay right there...</ParagraphText>
				<CbLoading
					loadingText={publishStatus === "publish" ? "Publishing course..." : "Creating preview..."}
					padding="30px"
				/>
			</ContentContainer>
		)
	else if (modalView === "share")
		return (
			<ContentContainer>
				<ShareCourse
					courseType={publishStatus}
					previewUrl={previewUrl}
					publishUrl={publishUrl}
					accessGroups={info.user_groups.access_groups}
				/>
			</ContentContainer>
		)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	course: selectCourse,
	completionData: selectCompletionData
})

const mapDispatchToProps = (dispatch) => ({
	publishCourse: (org_id, course_id, publish_options, user_groups) =>
		dispatch(publishCourse(org_id, course_id, publish_options, user_groups)),
	createCoursePreview: (org_id, course_id, publish_options) =>
		dispatch(createCoursePreview(org_id, course_id, publish_options))
})

PropTypes.PublishCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	course: PropTypes.object.isRequired,
	completionData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishCourse)
