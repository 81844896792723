// MODAL TO CREATE NEW LESSON CARD

import { Avatar } from "antd"
import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

import DemoVideo from "../cardTypeImages/defaultVideo.png"

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 22px;
`
const VideoContainer = styled.div`
	border-radius: 0px 10px 10px 10px;
	background-color: white;
	border: 1.5px solid dodgerblue;
	width: 80px;
	height: 70px;
	margin-left: 5px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
`

const PlayVideoButton = styled.button`
	background: white;
	border-radius: 999px;
	height: 30px;
	width: 30px;
`

const Video = ({ teacherImg }) => {
	return (
		<Container>
			<Avatar src={teacherImg} size={20} />
			<VideoContainer style={{ backgroundImage: `url("${DemoVideo}")` }}>
				<PlayVideoButton>
					<FontAwesomeIcon icon={faPlay} color="black" />
				</PlayVideoButton>
			</VideoContainer>
		</Container>
	)
}

export default Video
