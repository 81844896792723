import React from "react"
import styled from "styled-components"
import { PictureOutlined } from "@ant-design/icons"

import EditableTextBox from "../common/EditableTextBox"
import { ImageCard } from "learnink-common/lib/domains/cards/lessons/image/ImageCard.model"

const ImageContainer = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	min-height: 140px;
	height: auto;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.3s ease;
	:hover {
		opacity: 0.8;
	}
`
const Image = styled.img`
	width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
	border: 0;
`

interface Props {
	card: ImageCard
	updateLessonCard: (updateParams: ImageCard) => void
}

const ImageCardComponent = ({ card, updateLessonCard }: Props) => {
	const { from, image, message } = card
	const borderRadius = from === "teacher" ? "0px 18px 18px 18px" : "18px 0px 18px 18px"

	if (!image || image === "") {
		return (
			<ImageContainer
				style={{
					background: from === "teacher" ? "var(--secondary-color-pale)" : "var(--v-light-grey)",
					border: "1px solid var(--dark-grey)",
					borderRadius,
					fontSize: 60
				}}
			>
				<PictureOutlined />
			</ImageContainer>
		)
	}

	return (
		<>
			<ImageContainer
				style={{
					background: from === "teacher" ? "var(--chat-bubble-left)" : "var(--chat-bubble-right)",
					border:
						from === "teacher"
							? "1px solid var(--chat-bubble-left-border)"
							: "1px solid var(--chat-bubble-right-border)",
					borderRadius
				}}
			>
				<Image
					src={image}
					alt="card image"
					style={{
						borderRadius: from === "teacher" ? "0px 14px 14px 14px" : "14px 0px 14px 14px"
					}}
				/>
				<EditableTextBox
					type="image"
					from={from}
					initialText={message || ""}
					placeholderText="Enter caption..."
					setNewText={(val: string) => updateLessonCard({ ...card, message: val })}
				/>
			</ImageContainer>
		</>
	)
}

export default ImageCardComponent
