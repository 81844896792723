// SIDEBAR NAVIGATION FOR MAIN APP

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { Menu, Avatar, Typography, Button } from "antd"
import { RocketOutlined } from "@ant-design/icons"
import {
	UsergroupAddOutlined,
	EditOutlined,
	BarChartOutlined,
	SettingOutlined,
	QuestionCircleOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId, selectOrgName, selectOrgImg, selectPremiumFeatureAccess } from "../../redux/org/org.selectors"
import { selectIsUserAdmin } from "../../redux/user/user.selectors"
import { MenuItem, sideNavMenu } from "./SideNav.model"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { PremiumFeatureAccess } from "../../redux/org/org.model"

const { Title } = Typography

const SidebarContainer = styled.div<{ open: boolean }>`
	height: 100vh;
	width: 230px;
	background: white;
	top: 0;
	z-index: 120;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	align-items: flex-start;
	-webkit-box-pack: justify;
	justify-content: flex-start;
	box-shadow: rgba(34, 45, 57, 0.18) 1px 0 0 0 !important;
	position: fixed;
	transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
	transform: translateX(-1px);
	left: ${(props) => (props.open ? "0 !important" : "-230px")};
	overflow-y: scroll;
	@media screen and (min-width: 850px) {
		transform: translateX(0px);
		left: 0;
	}
`
const OrgRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding: 20px;
	border-bottom: solid 1px var(--light-grey);
	margin-bottom: 10px;
`
const SidebarOpenContainer = styled.div<{ open: boolean }>`
	display: ${(props) => (props.open ? "none" : "block")};
	position: fixed;
	left: 240px;
	top: 8px;
	z-index: 90;
	box-sizing: border-box;
	@media screen and (min-width: 850px) {
		display: none;
	}
`
const SidebarOpenButton = styled.button`
	-webkit-font-smoothing: antialiased;
	line-height: 1 !important;
	background-color: rgb(255, 255, 255);
	color: rgb(62, 72, 84);
	height: 30px;
	display: inline-block;
	box-shadow: rgb(213, 220, 227) 1px 0 0 inset, rgb(213, 220, 227) -1px 0px 0px inset,
		rgb(213, 220, 227) 0px 1px 0px inset, rgb(195, 204, 215) 0px -1px 0px inset,
		rgba(12, 16, 20, 0.1) 0px 1px 2px 0px;
	font-size: 12px;
	font-weight: 500;
	-webkit-appearance: none;
	box-sizing: border-box;
	cursor: pointer;
	outline-style: none;
	text-align: center;
	user-select: none;
	white-space: nowrap;
	margin: 0;
	padding: 0 16px;
	border-radius: 15px;
	border-style: none;
	text-decoration: none;
	transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
`
const SidebarCloseContainer = styled.div<{ open: boolean }>`
	display: ${(props) => (props.open ? "block" : "none")};
	position: absolute;
	top: 0;
	right: 0;
	box-sizing: border-box;
	padding: 10px;
`
const SidebarCloseButton = styled.div`
	cursor: pointer;
	text-align: center;
	font-size: 15px;
	line-height: 40px;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	border-radius: 99%;
	border: solid 1px var(--light-grey);
	text-decoration: none;
	color: black;
	:hover {
		background: var(--dark-grey);
		color: white;
		border: solid 1px var(--dark-grey);
	}
`
const NavLinkTag = styled.span`
	background-color: var(--mid-orange);
	color: white;
	font-size: 8px;
	border-radius: 4px;
	padding: 4px 6px;
	margin-left: 6px;
`
const ButtonContainer = styled.div`
	padding: 20px;
	width: 100%;
`
const Label = styled.span<{ accessibleToOrg: boolean }>`
	color: ${(props) => (props.accessibleToOrg ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.3)")};
`

interface Props {
	orgId: string
	orgName: string
	orgImage: string
	premiumFeatureAccess: PremiumFeatureAccess
	isAdmin: boolean
}

interface FormattedMenuItem {
	key: string
	icon?: any
	label: any
	children?: FormattedMenuItem[]
}

const menuIcons = {
	EditOutlined: <EditOutlined />,
	BarChartOutlined: <BarChartOutlined />,
	UsergroupAddOutlined: <UsergroupAddOutlined />,
	SettingOutlined: <SettingOutlined />
}

const isVisibleToOrg = (item: MenuItem, premiumFeatureAccess: PremiumFeatureAccess) => {
	if (!item.premiumId) {
		return true
	}
	// @ts-ignore
	if (item.premiumId && premiumFeatureAccess[item.premiumId] !== "hidden") {
		return true
	}
	return false
}

function transformMenu(item: MenuItem[], orgId: string, premiumFeatureAccess: PremiumFeatureAccess, isAdmin: boolean) {
	return item
		.filter((item) => isAdmin || isVisibleToOrg(item, premiumFeatureAccess))
		.map((item) => {
			const newItem: FormattedMenuItem = {
				key: item.key,
				icon: item.icon ? menuIcons[item.icon] : undefined,
				label: item.path ? (
					<Link to={`/org/${orgId}${item.path}`}>
						<Label accessibleToOrg={isVisibleToOrg(item, premiumFeatureAccess)}>{item.label}</Label>
						{item.beta && <NavLinkTag>BETA</NavLinkTag>}
					</Link>
				) : (
					item.label
				)
			}

			if (item.children && item.children.length > 0) {
				newItem.children = transformMenu(item.children, orgId, premiumFeatureAccess, isAdmin)
			}

			return newItem
		})
		.filter((item) => !item.children || item.children.length > 0)
}

const SideNav = ({ orgId, orgName, orgImage, premiumFeatureAccess, isAdmin }: Props) => {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	let location = useLocation()

	const urlSplit = location.pathname.split(orgId).length > 1 ? location.pathname.split(orgId)[1] : ""

	const items = [
		...transformMenu(sideNavMenu, orgId, premiumFeatureAccess, isAdmin),
		{
			key: "helpCentre",
			icon: <QuestionCircleOutlined />,
			label: (
				<a href="https://help.learn.ink" target="_blank" rel="noopener noreferrer">
					Help center
				</a>
			)
		}
	]

	return (
		<SidebarContainer open={sidebarOpen}>
			<SidebarCloseContainer open={sidebarOpen}>
				<SidebarCloseButton onClick={() => setSidebarOpen(false)}>
					<FontAwesomeIcon icon={faTimes as IconProp} />
				</SidebarCloseButton>
			</SidebarCloseContainer>

			<SidebarOpenContainer open={sidebarOpen}>
				<SidebarOpenButton onClick={() => setSidebarOpen(true)}>
					<FontAwesomeIcon icon={faBars as IconProp} />
				</SidebarOpenButton>
			</SidebarOpenContainer>

			<OrgRow>
				<Avatar src={orgImage} size="large" />
				<Title level={5} style={{ marginBottom: 0, marginLeft: 5 }}>
					{orgName}
				</Title>
			</OrgRow>
			<ButtonContainer>
				<a
					href={`${process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL}/${orgId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button shape="round" block icon={<RocketOutlined />}>
						Open app
					</Button>
				</a>
			</ButtonContainer>
			<Menu defaultSelectedKeys={[urlSplit]} mode="inline" items={items} />
		</SidebarContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgName: selectOrgName,
	orgImage: selectOrgImg,
	isAdmin: selectIsUserAdmin,
	premiumFeatureAccess: selectPremiumFeatureAccess
})

export default connect(mapStateToProps)(SideNav)
