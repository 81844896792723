import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Tag, Form, Select } from "antd"

import { selectOrgUserGroups } from "../../redux/org/org.selectors"

const FormLabel = styled.label`
	color: black;
	font-size: ${({ labelFontSize }) => (labelFontSize ? labelFontSize : "18px !important")};
	font-weight: 500;
	padding-right: 6px;
`

const UserGroupsSelector = ({
	orgUserGroups,
	labelFontSize,
	tooltip,
	disableRegistered,
	onChange,
	required,
	label
}) => {
	// need to provide list of ALL possible user groups to choose from
	// we use the orgUserGroups list from redux state...
	const groupOptions = Object.keys(orgUserGroups).map((userGroupId) => {
		return {
			value: userGroupId,
			label: orgUserGroups[userGroupId].user_group_info.name,
			isFixed: false
		}
	})

	const defaultOnChange = () => {
		return null
	}

	const tagRender = (props) => {
		const { label, value, onClose } = props
		const onPreventMouseDown = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		if (disableRegistered && value === "registered") {
			return null
		} else {
			return (
				<Tag
					color={orgUserGroups[value] ? orgUserGroups[value].color : "grey"}
					onMouseDown={onPreventMouseDown}
					onClose={onClose}
					style={{ marginRight: 3 }}
				>
					{label}
				</Tag>
			)
		}
	}

	return (
		<Form.Item
			label={<FormLabel labelFontSize={labelFontSize}>{label ? label : "User groups"}</FormLabel>}
			name="user_groups"
			rules={
				required
					? [
							{ required: true, message: "You need to add a user group" },
							{
								message:
									"You cannot add user groups alongside the Registered group. The Registered group automatically grants access to all registered users",
								validator: (_, value) => {
									if (value && value.includes("registered") && value.length > 1) {
										return Promise.reject()
									} else {
										return Promise.resolve()
									}
								}
							}
					  ]
					: [
							{
								message:
									"You cannot add user groups alongside the Registered group. The Registered group automatically grants access to all registered users",
								validator: (_, value) => {
									if (value && value.includes("registered") && value.length > 1) {
										return Promise.reject()
									} else {
										return Promise.resolve()
									}
								}
							}
					  ]
			}
			tooltip={tooltip ? tooltip : "Select which user groups you want to give access"}
			labelCol={{ span: 24 }}
		>
			<Select
				showSearch
				mode="multiple"
				showArrow
				style={{ width: "100%" }}
				options={disableRegistered ? groupOptions.filter((item) => item.value !== "registered") : groupOptions}
				optionFilterProp="children"
				filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
				tagRender={tagRender}
				onChange={onChange ? onChange : defaultOnChange}
			/>
		</Form.Item>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(UserGroupsSelector)
