import { OrgActionTypes } from "./org.types"

const INITIAL_STATE = {
	orgData: null,
	orgTeam: null
}

const orgReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OrgActionTypes.SET_ORG_DATA:
			return {
				...state,
				orgData: action.payload
			}
		case OrgActionTypes.CLEAR_ORG_DATA:
			return {
				...state,
				orgData: null
			}
		case OrgActionTypes.SET_ORG_INFO:
			return {
				...state,
				orgData: {
					...state.orgData,
					info: action.payload
				}
			}
		case OrgActionTypes.SET_ORG_TEAM:
			return {
				...state,
				orgTeam: action.payload
			}
		case OrgActionTypes.CREATE_NEW_COURSE:
			let new_course_cards = Object.assign({}, state.orgData.course_cards)
			new_course_cards[action.payload.course_id] = {
				course_id: action.payload.course_id,
				info: action.payload.info,
				course_folder_parent_id: action.payload.course_folder_parent_id
			}
			return {
				...state,
				orgData: {
					...state.orgData,
					course_ids: [action.payload.course_id, ...state.orgData.course_ids],
					course_cards: new_course_cards
				}
			}
		case OrgActionTypes.UPDATE_COURSE_CARD_IN_ORG:
			let updated_course_cards = Object.assign({}, state.orgData.course_cards)
			updated_course_cards[action.payload.course_id] = {
				course_id: action.payload.course_id,
				info: action.payload.info
			}
			return {
				...state,
				orgData: {
					...state.orgData,
					course_cards: updated_course_cards
				}
			}
		case OrgActionTypes.DELETE_COURSE_CARD_FOR_ORG:
			let del_course_cards = Object.assign({}, state.orgData.course_cards)
			delete del_course_cards[action.payload.course_id]
			return {
				...state,
				orgData: {
					...state.orgData,
					course_ids: state.orgData.course_ids.filter((x) => x !== action.payload.course_id),
					course_cards: del_course_cards
				}
			}
		case OrgActionTypes.ADD_IMAGE_TO_LIBRARY:
			return {
				...state,
				orgData: {
					...state.orgData,
					[action.payload.type]: [action.payload.imageId, ...state.orgData[action.payload.type]]
				}
			}
		case OrgActionTypes.SET_USER_GROUPS:
			return {
				...state,
				orgData: {
					...state.orgData,
					userGroups: action.payload
				}
			}
		case OrgActionTypes.SET_LEARNING_PATHS:
			return {
				...state,
				orgData: {
					...state.orgData,
					learningPaths: action.payload
				}
			}
		case OrgActionTypes.SET_SEQUENCES:
			return {
				...state,
				orgData: {
					...state.orgData,
					sequences: action.payload
				}
			}
		default:
			return state
	}
}

export default orgReducer
