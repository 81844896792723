// DASHBOARD SHOWING USER PROGRESS THROUGH A COURSE

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkSquareAlt, faChartBar } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff

import { selectOrgCourseCards } from "../../../redux/org/org.selectors"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import AnSelectItems from "../../common/AnSelectItems"
import CourseSubNav from "../nav/CourseSubNav"
import CourseJourneyQuery from "../charts/course-journey-dash/CourseJourneyQuery"

const CourseJourneyDash = ({ orgCourseCards }) => {
	const [courseId] = useUrlQueryString("courseId")
	const [dateRange] = useUrlQueryString("dateRange")

	if (orgCourseCards[courseId]) {
		return (
			<div className="container-fluid">
				<CourseSubNav courseId={courseId} />
				<CourseJourneyQuery courseId={courseId} dateRange={dateRange} />
			</div>
		)
	} else {
		return (
			<AnSelectItems
				icon={<FontAwesomeIcon icon={faExternalLinkSquareAlt} />}
				titleText={"Select a course from the dropdown above"}
				subtitleText={"Use the dropdown on the upper right to select a published course"}
				hasBorder={false}
				cardBodyBottomMargin={"20px"}
				cardMargin={"18px"}
			>
				<div
					style={{
						marginTop: "30px",
						fontSize: "300px",
						color: "var(--v-light-grey)"
					}}
				>
					<FontAwesomeIcon icon={faChartBar} />
				</div>
			</AnSelectItems>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(CourseJourneyDash)
