import React from "react"
import { Button, Dropdown, Empty, MenuProps, Typography } from "antd"
import styled from "styled-components"
// @ts-ignore
import { Droppable, Draggable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { selectLearningPaths } from "../../../redux/org/org.selectors"

import * as S from "../../styles/PageStyles"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import CourseDragDrop from "./CourseDragDrop"
import { Sequence } from "learnink-common/lib/domains/sequences/Sequence.model"

// @ts-ignore
import CertificateImg from "./certificate_incomplete.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faArrowsAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { SequenceContent } from "../../../pages/learningPaths/utils/SequenceContent"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import { ModalTypes } from "../../../pages/learningPaths/LearningPathBuilder"
import InfoBanner from "../../common/InfoBanner"
import EditableSetting from "../../common/EditableSetting"

const { Title } = Typography

const CardContainer = styled.div`
	width: 100%;
	background: white;
	border: solid 1px var(--light-grey);
	border-radius: 5px;
	position: relative;
	transition: 0.5s ease;
	background-color: #eef8fe;
	overflow: hidden;
`
const SequenceHeader = styled.div`
	width: 100%;
	padding: 10px 10px 10px 10px;
	border-bottom: solid 2px #cee3ef;
	position: relative;
	transition: 0.5s ease;
	background-size: cover !important;
	background: white;
`
const UnitTitle = styled.div`
	font-size: 12px;
	font-weight: bold;
	flex: 1;
	margin-left: 10px;
`
const SequenceTitle = styled.div`
	font-size: 17px;
	font-weight: bold;
`
const CertificateContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	position: relative;
	padding: 0px 0px 20px 0px;
`
const CertificateImage = styled.img`
	width: 100px;
	height: 40px;
	z-index: 2;
`
const CertificateCard = styled.div`
	width: 70%;
	padding: 16px;
	margin-top: -6%;
	border-radius: 20px;
	border: solid 2px #c5d9e6;
	z-index: 1;
	background-color: #dbedf5;
	color: #808d98;
	font-weight: bold;
	font-size: 13px;
	text-align: center;
`

interface Props {
	learningPath: LearningPath
	setSequenceIndex: React.Dispatch<React.SetStateAction<number | undefined>>
	setModalType: React.Dispatch<React.SetStateAction<ModalTypes>>
	updateSequence: (sequence: Sequence) => void
	learningPaths: LearningPath[]
}

const getListStyle = (isDraggingOver: boolean) => ({
	background: isDraggingOver ? "var(--v-light-grey)" : "var(--off-white)",
	width: "100%"
})

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
	border: isDragging ? "solid 2px var(--secondary-color)" : "solid 1px var(--light-grey)",
	...draggableStyle
})

const ContentColumnEdit = ({ learningPath, setSequenceIndex, updateSequence, setModalType, learningPaths }: Props) => {
	const deleteCourse = (courseIndex: number, sequenceIndex: number) => {
		const updatedSequence = new SequenceContent(learningPath.sequences[sequenceIndex]).removeCourseFromSequence(
			courseIndex
		)
		updateSequence(updatedSequence)
	}

	const items: MenuProps["items"] = [
		{
			key: "edit",
			label: <div onClick={() => setModalType("editSequence")}>Edit</div>
		},
		{
			key: "delete",
			label: <div onClick={() => setModalType("deleteSequence")}>Delete</div>
		}
	]

	return (
		<>
			{learningPath.sequences.length === 0 ? (
				<S.FlexBoxColumnCentered
					style={{
						padding: "5px 10px 10px 10px",
						justifyContent: "flex-start"
					}}
				>
					<InfoBanner
						id="learning_path_editor"
						buttonSize="small"
						videoId="learning_path_editor"
						text="Want a tour? Check out the help video"
					/>
					<Empty
						description={
							<>
								<Title level={5} style={{ color: "var(--mid-grey)", marginBottom: 4 }}>
									No sequences
								</Title>
								<span style={{ color: "var(--mid-grey)" }}>
									Press the plus button below to add your first sequence
								</span>
							</>
						}
						style={{ marginTop: 10 }}
					/>
					<Button
						onClick={() => {
							setModalType("addSequence")
							setSequenceIndex(0)
						}}
						style={{ marginTop: 20 }}
						type="primary"
						size="large"
						shape="round"
					>
						Add sequence
					</Button>
				</S.FlexBoxColumnCentered>
			) : (
				<div style={{ padding: 6 }}>
					<InfoBanner
						id="learning_path_editor"
						buttonSize="small"
						videoId="learning_path_editor"
						text="Want a tour? Check out the help video"
					/>
					<Droppable droppableId="sequenceCanvas" type="sequence">
						{(provided: any, snapshot: any) => (
							<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
								{learningPath.sequences.map((item: Sequence, index: number) => (
									<>
										<Draggable key={item.id} draggableId={item.id} index={index}>
											{(provided: any, snapshot: any) => (
												<div>
													<CardContainer
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														<SequenceHeader onClick={() => setSequenceIndex(index)}>
															<S.FlexBoxStartRow>
																<div {...provided.dragHandleProps}>
																	<FontAwesomeIcon
																		icon={faArrowsAlt as IconProp}
																		color="var(--mid-grey)"
																	/>
																</div>
																<UnitTitle>STAGE {index + 1}</UnitTitle>
																<Dropdown menu={{ items }} placement="bottomRight">
																	<FontAwesomeIcon
																		color="var(--mid-grey)"
																		icon={faEllipsisV as IconProp}
																	/>
																</Dropdown>
															</S.FlexBoxStartRow>
															<SequenceTitle>{item.displayName}</SequenceTitle>
														</SequenceHeader>
														<CourseDragDrop
															content={item.content}
															sequenceId={item.id}
															sequenceIndex={index}
															deleteCourse={deleteCourse}
														/>
														<CertificateContainer>
															<CertificateImage src={CertificateImg} />
															<CertificateCard>Certificate</CertificateCard>
														</CertificateContainer>
													</CardContainer>
													{provided.placeholder}
												</div>
											)}
										</Draggable>
										<div
											style={{
												width: "100%",
												display: "flex",
												justifyContent: "center",
												padding: 6
											}}
										>
											<S.AddButton
												onClick={() => {
													setModalType("addSequence")
													setSequenceIndex(index + 1)
												}}
											>
												+
											</S.AddButton>
										</div>
									</>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					{learningPath.onCompletion.nextLearningPathId && (
						<EditableSetting
							tagTitle="On completion move to:"
							tag={
								learningPaths.filter(
									(item) => item.id === learningPath.onCompletion?.nextLearningPathId
								)[0].internalName
							}
							tagColor={new LearningPathContent(
								learningPaths.filter(
									(item) => item.id === learningPath.onCompletion?.nextLearningPathId
								)[0]
							).getColor()}
							isEditable
							onClick={() => setModalType("editLearningPathOnCompletion")}
						/>
					)}
					{!learningPath.onCompletion.nextLearningPathId && (
						<EditableSetting
							tagTitle="On completion:"
							tag="Stay in learning path"
							key="onCompletion"
							isEditable
							onClick={() => setModalType("editLearningPathOnCompletion")}
						/>
					)}
				</div>
			)}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	learningPaths: selectLearningPaths
})

// @ts-ignore
export default connect(mapStateToProps)(ContentColumnEdit)
