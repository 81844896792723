import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

const ScrollContainer = styled.div`
	${"" /* These styles are suggested for the table fill all available space in its containing element */}
	display: block;
	${"" /* These styles are required for a horizontaly scrollable table overflow */}
	overflow: auto;
`
const TableContainer = styled.div`
	border-spacing: 0;
	border: 0px solid black;
	margin-bottom: 20px;
`
const TableHeader = styled.div`
	${"" /* These styles are required for a scrollable body to align with the header properly */}
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	font-weight: 600;
`
const TableHeadData = styled.div`
	margin: 6px 12px;
`
const TableResizer = styled.div`
	right: 0;
	width: 10px;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	${"" /* prevents from scrolling while dragging on touch devices */}
	touch-action: none;
	border-right: solid 2px var(--v-light-grey);
	transition: 0.2s ease;
	:hover {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color-highlight)" : "var(--secondary-color)")};
	}
	:focus {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color-highlight)" : "var(--secondary-color)")};
	}
`
const TableRow = styled.div`
	:last-child {
		border-bottom: 0;
	}
	border-bottom: 0px solid black;
	background: ${({ isStriped }) => (isStriped ? "#f6f6f6" : "white")};
`
const TableBody = styled.div`
	${"" /* These styles are required for a scrollable table body */}
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
`
const TableData = styled.div`
	margin: 0;
	padding: 0.25rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start !important;
	align-items: center !important;
	font-size: 14px;
	overflow: auto;

	background-color: ${({ paintCell }) => (!!paintCell ? paintCell : "auto")};

	${
		"" /* In this example we use an absolutely position resizer,
    so this is required. */
	}
	position: relative;

	:last-child {
		border-right: 0;
	}
`
const SortIconContainer = styled.span`
	margin-left: 6px;
`

const QuestionsSummaryTableView = ({
	headerProps,
	page,
	cellProps,
	getTableProps,
	headerGroups,
	getTableBodyProps,
	prepareRow
}) => {
	return (
		<ScrollContainer>
			{/* Creat the TABLE... */}
			<TableContainer {...getTableProps()}>
				{/* Column headers... */}
				{headerGroups.map((headerGroup) => (
					<TableRow {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<TableHeader {...column.getHeaderProps(headerProps)}>
								<TableHeadData {...column.getSortByToggleProps()}>
									{column.render("Header")}

									{/* Add a sort direction indicator */}
									<SortIconContainer>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<FontAwesomeIcon icon={faCaretUp} />
											) : (
												<FontAwesomeIcon icon={faCaretDown} />
											)
										) : (
											""
										)}
									</SortIconContainer>
								</TableHeadData>

								{/* Render the columns filter UI */}
								<>{column.canFilter ? column.render("Filter") : null}</>

								{/* Use column.getResizerProps to hook up the events correctly */}
								{column.canResize && (
									<TableResizer {...column.getResizerProps()} isResizing={column.isResizing} />
								)}
							</TableHeader>
						))}
					</TableRow>
				))}

				{/* Our table body data here... */}
				<TableBody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row)

						return (
							<React.Fragment key={i}>
								{/* Each row (striped)... */}
								<TableRow {...row.getRowProps()} isStriped={i % 2 === 0}>
									{row.cells.map((cell, i) => {
										// Handle different cell types...
										return (
											<TableData {...cell.getCellProps(cellProps)}>
												{cell.render("Cell")}
											</TableData>
										)
									})}
								</TableRow>
							</React.Fragment>
						)
					})}
				</TableBody>
			</TableContainer>
		</ScrollContainer>
	)
}

export default QuestionsSummaryTableView
