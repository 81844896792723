import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button, Form, Input, Select, Dropdown, Space } from "antd"
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons"

import { selectLearningPathById, selectRoleTypeUserGroups, selectSequenceList } from "../../../redux/org/org.selectors"
import UserGroupsSelector from "../../common/UserGroupsSelector"
import { selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

const { TextArea } = Input

const Divider = styled.div`
	height: 32px;
	width: 100%;
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
`
const FormLabel = styled.label`
	color: black;
	font-size: 16px !important;
	font-weight: 500;
	padding-right: 6px;
`
const ButtonContainerLarge = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`
const ButtonContainerSmall = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 20px;
`
const FormRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-content: center;
	align-items: center;
	border-top: solid 1px var(--light-grey);
	padding-top: 20px;
	position: relative;
`

const AccessCodeForm = ({
	sequenceList,
	learningPathById,
	roleUserGroups,
	handleSubmit,
	accessCodeData,
	submitAction,
	cancel,
	premiumFeatureAccess,
	isAdmin
}) => {
	const [pageState, setPageState] = useState("loading")
	const [actionList, setActionList] = useState([])
	const [initialValues, setInitialValues] = useState({})
	const [valuesUpdated, setValuesUpdated] = useState(false)
	const [form] = Form.useForm()

	console.log(actionList)

	const sequenceOptions = []
	sequenceList.forEach((sequence) => {
		if (sequence.isActive) {
			sequenceOptions.push({
				value: sequence.id,
				label: sequence.internalName
			})
		}
	})

	const learningPathOptions = []
	if (
		actionList.filter((item) => item.field === "role").length > 0 &&
		actionList.filter((item) => item.field === "role")[0].targetId
	) {
		const role = actionList.filter((item) => item.field === "role")[0].targetId
		const learningPathIds = roleUserGroups
			.filter((item) => item.id === role)[0]
			.learningPaths.map((item) => item.learningPathId)
		learningPathIds.forEach((learningPathId) => {
			if (learningPathById[learningPathId].status === "ACTIVE") {
				learningPathOptions.push({
					value: learningPathId,
					label: learningPathById[learningPathId].internalName
				})
			}
		})
	}

	const generateActionListValues = (actionList) => {
		const values = {}
		actionList.forEach((action, index) => {
			if (action.field === "userGroups") {
				values.user_groups = action.userGroups
			}
			if (action.field === "assignment") {
				values[`assignment_${index}_targetId`] = action.targetId
				values[`assignment_${index}_daysUntilStart`] = action.daysUntilStart
				values[`assignment_${index}_numDays`] = action.numDays
				values[`assignment_${index}_tasksOn`] = action.tasksOn
			}
			if (action.field === "learningPath") {
				values[`learningPathId`] = action.targetId
			}
			if (action.field === "role") {
				values["role"] = action.targetId
			}
		})
		return values
	}

	const onClick = (value) => {
		let newActions = []
		if (value.key === "assignment") {
			newActions = [{ field: "assignment", action: "add", type: "sequence", targetId: null }]
		}
		if (value.key === "learningPath") {
			newActions = [
				{ field: "role", action: "replace", targetId: null },
				{ field: "learningPath", action: "replace", targetId: null }
			]
		}
		if (value.key === "userGroups") {
			newActions = [{ field: "userGroups", action: "add" }]
		}
		const updatedActionList = [...actionList, ...newActions]
		setActionList(updatedActionList)
		form.setFieldsValue(generateActionListValues(updatedActionList))
	}

	const deleteAction = (index) => {
		const updatedActionList =
			actionList[index].field === "learningPath"
				? actionList.filter((item) => item.field !== "role").filter((_, i) => i !== index)
				: actionList.filter((_, i) => i !== index)
		setActionList(updatedActionList)
		form.setFieldsValue(generateActionListValues(updatedActionList))
		setValuesUpdated(true)
	}

	const updateActionField = (field, key, value) => {
		const updatedActions = actionList.map((action) => {
			if (action.field === field) {
				return { ...action, [key]: value }
			}
			return action
		})
		setActionList(updatedActions)
		setValuesUpdated(true)
	}

	const updateAssignmentActionField = (index, field, value) => {
		const newActionList = actionList
		newActionList[index][field] = value
		setActionList([...newActionList])
		setValuesUpdated(true)
	}

	const items = [
		{
			label: "Add to user groups",
			key: "userGroups",
			disabled: actionList.filter((item) => item.field === "userGroups").length > 0
		},
		{
			label: "Add to learning path",
			key: "learningPath",
			disabled:
				actionList.filter((item) => ["assignment", "learningPath"].includes(item.field)).length > 0 ||
				premiumFeatureAccess.learningPaths === "locked"
		},
		{
			label: "Add to sequence",
			key: "assignment",
			disabled:
				actionList.filter((item) => item.field === "assignment").length > 4 ||
				actionList.filter((item) => item.field === "learningPath").length > 0 ||
				premiumFeatureAccess.sequences === "locked"
		}
	]
		.filter((item) =>
			premiumFeatureAccess.learningPaths === "hidden" && !isAdmin ? item.key !== "learningPath" : item.key
		)
		.filter((item) =>
			premiumFeatureAccess.sequences === "hidden" && !isAdmin ? item.key !== "assignment" : item.key
		)

	const onFormSubmit = (values) => {
		const { description, isActive } = values
		const accessCodeData = {
			description,
			isActive,
			actionList
		}
		handleSubmit(accessCodeData)
	}

	useEffect(() => {
		setPageState("loading")
		if (accessCodeData) {
			const values = {
				description: accessCodeData.description || null,
				isActive: accessCodeData.isActive || null
			}
			const actionListValues = generateActionListValues(accessCodeData.actionList)
			setInitialValues({ ...values, ...actionListValues })
			setActionList(accessCodeData.actionList)
			setPageState("default")
		} else {
			setInitialValues({})
			setActionList([])
			setPageState("default")
		}
	}, [accessCodeData])

	if (pageState === "loading") {
		return null
	}

	return (
		<Form
			form={form}
			autoComplete="off"
			onFinish={onFormSubmit}
			layout="vertical"
			initialValues={initialValues}
			onValuesChange={() => setValuesUpdated(true)}
		>
			<Form.Item
				label={<FormLabel>Access code description</FormLabel>}
				name="description"
				rules={[
					{
						required: true,
						message: "Access code description is required"
					}
				]}
			>
				<TextArea autoSize placeholder="Enter short description..." />
			</Form.Item>
			<Form.Item
				label={<FormLabel>Status</FormLabel>}
				name="isActive"
				rules={[{ required: true, message: "Select active or deactivated" }]}
			>
				<Select
					showArrow={true}
					style={{ width: "100%" }}
					options={[
						{ value: true, label: "active" },
						{
							value: false,
							label: "deactivated"
						}
					]}
				/>
			</Form.Item>
			{actionList
				.filter((item) => item.field !== "role")
				.map((action, index) => (
					<FormRow key={index}>
						{action.field === "userGroups" && (
							<div style={{ flex: 1 }}>
								<UserGroupsSelector
									tooltip="When your user enters this access code they will be added to these user groups"
									labelFontSize="16px !important"
									label="Add to user groups"
									required
									onChange={(value) => updateActionField(action.field, "userGroups", value)}
								/>
							</div>
						)}
						{action.field === "learningPath" && (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									opacity: premiumFeatureAccess.learningPaths === "hidden" ? 0.6 : 1
								}}
							>
								<Form.Item
									label={<FormLabel>Set user role</FormLabel>}
									name="role"
									tooltip="Select which role you want this user to be assigned to"
									style={{ flex: 1 }}
									rules={[
										{
											required: true,
											message: "You need to select a role"
										}
									]}
								>
									<Select
										showArrow={true}
										style={{ width: "100%" }}
										options={roleUserGroups.map((item) => {
											return {
												value: item.id,
												label: item.name
											}
										})}
										onChange={(value) => updateActionField("role", "targetId", value)}
									/>
								</Form.Item>
								<Form.Item
									label={<FormLabel>Add to learning path</FormLabel>}
									name="learningPathId"
									tooltip="When your user enters this access code they will be added to this learning path"
									style={{ flex: 1 }}
									rules={[
										{
											required: true,
											message: "You need to add a learning path"
										}
									]}
								>
									<Select
										showArrow={true}
										style={{ width: "100%" }}
										options={learningPathOptions}
										onChange={(value) => updateActionField(action.field, "targetId", value)}
									/>
								</Form.Item>
							</div>
						)}
						{action.field === "assignment" && (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									opacity: premiumFeatureAccess.sequences === "hidden" ? 0.6 : 1
								}}
							>
								<Form.Item
									label={<FormLabel>Add to sequence</FormLabel>}
									name={`assignment_${index}_targetId`}
									tooltip="When your user enters this access code they will be added to this sequence"
									style={{ flex: 1 }}
									rules={[
										{
											required: true,
											message: "You need to add a sequence"
										},
										{
											message: "This sequence has already been selected",
											validator: (_, value) => {
												if (actionList.filter((item) => item.targetId === value).length > 1) {
													return Promise.reject()
												} else {
													return Promise.resolve()
												}
											}
										}
									]}
								>
									<Select
										showArrow={true}
										style={{ width: "100%" }}
										options={sequenceOptions}
										onChange={(value) => updateAssignmentActionField(index, "targetId", value)}
									/>
								</Form.Item>
								<div
									style={{
										flex: 1,
										display: "flex",
										flexDirection: "row",
										marginTop: -10
									}}
								>
									<Form.Item
										label="Start"
										name={`assignment_${index}_daysUntilStart`}
										tooltip=""
										style={{ flex: 1, marginRight: 5 }}
										rules={[{ required: true, message: "This field cannot be blank" }]}
									>
										<Select
											showArrow={true}
											onChange={(value) =>
												updateAssignmentActionField(index, "daysUntilStart", value)
											}
											options={[...Array(30).keys()].map((i) => {
												const obj = { value: i, label: `On day ${i + 1}` }
												return obj
											})}
										/>
									</Form.Item>
									<Form.Item
										label="Complete"
										name={`assignment_${index}_numDays`}
										tooltip=""
										style={{ flex: 1, marginLeft: 5 }}
										rules={[{ required: true, message: "This field cannot be blank" }]}
									>
										<Select
											showArrow={true}
											onChange={(value) => updateAssignmentActionField(index, "numDays", value)}
											options={[...Array(30).keys()].map((i) => {
												const obj = { value: i }
												if (i === 0) {
													obj.label = "The same day"
												}
												if (i === 1) {
													obj.label = "The next day"
												}
												if (i > 1) {
													obj.label = `In ${i} days`
												}
												return obj
											})}
										/>
									</Form.Item>
									<Form.Item
										label="Stagger progress"
										name={`assignment_${index}_tasksOn`}
										tooltip="Select true if you want to spread out the course modules over each day and lock content for future days"
										style={{ flex: 1, marginLeft: 5 }}
										rules={[{ required: true, message: "This field cannot be blank" }]}
									>
										<Select
											showArrow={true}
											onChange={(value) => updateAssignmentActionField(index, "tasksOn", value)}
											options={[
												{ value: true, label: "true" },
												{ value: false, label: "false" }
											]}
										/>
									</Form.Item>
								</div>
							</div>
						)}
						<Button
							type="circle"
							icon={<DeleteOutlined />}
							style={{ position: "absolute", top: 12, right: 6 }}
							size="small"
							onClick={() => deleteAction(index)}
						/>
					</FormRow>
				))}
			<Dropdown menu={{ items, onClick }} trigger={["click"]}>
				<Button type="dashed" style={{ width: "100%" }} icon={<PlusOutlined />}>
					Add action
				</Button>
			</Dropdown>
			<Divider borderOnBottom={true} />
			<Form.Item>
				{submitAction === "create" && (
					<ButtonContainerLarge>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							shape="round"
							block
							disabled={actionList.length === 0}
						>
							Create access code
						</Button>
					</ButtonContainerLarge>
				)}
				{submitAction === "update" && valuesUpdated && (
					<ButtonContainerSmall>
						<Space>
							<Button shape="round" onClick={cancel}>
								Cancel
							</Button>
							<Button type="primary" htmlType="submit" shape="round" disabled={actionList.length === 0}>
								Save changes
							</Button>
						</Space>
					</ButtonContainerSmall>
				)}
			</Form.Item>
		</Form>
	)
}

const mapStateToProps = createStructuredSelector({
	sequenceList: selectSequenceList,
	learningPathById: selectLearningPathById,
	roleUserGroups: selectRoleTypeUserGroups,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	isAdmin: selectIsUserAdmin
})

export default connect(mapStateToProps)(AccessCodeForm)
