import { combineReducers } from "redux"

import userReducer from "./user/user.reducer"
import orgReducer from "./org/org.reducer"
import uiReducer from "./ui/ui.reducer"
import coursesReducer from "./courses/courses.reducer"
import learnReducer from "./learn/learn.reducer"
import subscriptionReducer from "./subscription/subscription.reducer"
import managementReducer from "./management/management.reducer"
import resourcesReducer from "./resources/resources.reducer"
import templatesReducer from "./templates/templates.reducer"

const rootReducer = combineReducers({
	user: userReducer,
	org: orgReducer,
	courses: coursesReducer,
	resources: resourcesReducer,
	ui: uiReducer,
	learn: learnReducer,
	subscription: subscriptionReducer,
	management: managementReducer,
	templates: templatesReducer
})

export default rootReducer
