import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import "./styles/antd.css" // make sure this loaded before your core .css
import "./styles/index.css"
import App from "./App"

// For sentry error handling
/*
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  environment: 'development',
});
*/

if (process.env.REACT_APP_URL === "https://farmink-customer-v1-staging.herokuapp.com") {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN_URL,
		environment: "staging",
	})
} else if (process.env.REACT_APP_URL === "https://app.learn.ink") {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN_URL,
		environment: "production",
	})
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root"),
)
