import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button, Typography, Form, Input } from "antd"

import { appSignOut, updateBasicUserInfo } from "../../redux/user/user.actions"
import { selectUserInfoDetail } from "../../redux/user/user.selectors"
import * as S from "../../components/styles/PageStyles"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"

const { Title, Text } = Typography

const PageOuter = styled.div`
	width: 100vw;
	position: relative;
	box-sizing: border-box;
	@media screen and (min-width: 590px) {
		width: 100vw;
	}
	@media screen and (min-width: 850px) {
		width: calc(100vw - 280px);
	}
`
const PageInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const Divider = styled.div`
	padding: 0;
	margin: 0;
	margin-top: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid var(--light-grey);
`
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	align-items: center;
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 1;
	padding-right: 100px;
`
const ButtonBox = styled.div`
	width: 180px;
`
const FormLabel = styled.label`
	color: black;
	font-size: 18px !important;
	font-weight: 500;
	padding-right: 6px;
`

const MyAccount = ({ appSignOut, userInfoDetail, updateBasicUserInfo }) => {
	const [showSaveChanges, setShowSaveChanges] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleSubmit = async (values) => {
		try {
			setLoading(true)
			const { name } = values
			await updateBasicUserInfo({
				email: userInfoDetail.email,
				name,
				interest: userInfoDetail.interest
			})
			setTimeout(() => {
				setLoading(false)
				setShowSaveChanges(false)
			}, 500)
		} catch (err) {
			setLoading(false)
		}
	}

	const sendIntercomMessage = (message) => {
		window.Intercom("showNewMessage", message)
	}

	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton title={"My Account"} />
				<PageOuter>
					<PageInner>
						<Form
							size="large"
							autoComplete="off"
							onFinish={handleSubmit}
							initialValues={{ name: userInfoDetail.name }}
							layout="vertical"
							onValuesChange={() => setShowSaveChanges(true)}
						>
							<Row>
								<Column>
									<Form.Item
										label={<FormLabel>First name</FormLabel>}
										name="name"
										rules={[
											{
												required: true,
												message: "You can't leave this blank"
											},
											{
												max: 20,
												message: "Name must have maximum 20 characters"
											}
										]}
									>
										<Input placeholder="Your name..." style={{ maxWidth: 300 }} />
									</Form.Item>
								</Column>
								<ButtonBox>
									<Form.Item>
										<Button
											disabled={!showSaveChanges}
											htmlType="submit"
											size="large"
											shape="round"
											block
											loading={loading}
										>
											Save changes
										</Button>
									</Form.Item>
								</ButtonBox>
							</Row>
						</Form>

						<Divider />

						<Row>
							<Column>
								<Title level={4}>Sign out</Title>
								<Text>Press the button to sign out of your account</Text>
							</Column>
							<ButtonBox>
								<Button block shape="round" size="large" onClick={() => appSignOut()}>
									Sign out
								</Button>
							</ButtonBox>
						</Row>

						<Divider />

						<Row>
							<Column>
								<Title level={4}>Delete my account</Title>
								<Text>
									If you would like to delete your account including all of your personal data then
									please contact us. Once completed this action cannot be undone
								</Text>
							</Column>
							<ButtonBox>
								<Button
									block
									shape="round"
									size="large"
									onClick={() => sendIntercomMessage("Delete my account")}
								>
									Contact us
								</Button>
							</ButtonBox>
						</Row>

						<Divider />
					</PageInner>
				</PageOuter>
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	userInfoDetail: selectUserInfoDetail
})

const mapDispatchToProps = (dispatch) => ({
	appSignOut: () => dispatch(appSignOut()),
	updateBasicUserInfo: (newBasicUserInfo) => dispatch(updateBasicUserInfo(newBasicUserInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
