// TEMPLATES PAGE FILTER

import React, { useState, useEffect } from "react"
import styled from "styled-components"
import matchSorter from "match-sorter"
import { Input, Select, Tag } from "antd"

import { languageList } from "../course/sidepanels/languageList"

const { Search } = Input
const { CheckableTag } = Tag

const SubNavContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	box-sizing: border-box;
	width: 100%;
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: 100%;
	}
`
const SearchContainer = styled.div`
	flex: 1 1 100px;
	margin: 4px;
`
const FilterContainer = styled.div`
	flex: 1 1 100px;
	margin: 4px;
	display: none;
	@media screen and (min-width: 850px) {
		display: block;
	}
`
const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

const TemplatesSubNav = ({ courseTemplateList, setFilteredTemplates }) => {
	const [searchText, setSearchText] = useState("")
	const [statusFilter, setStatusFilter] = useState(["all"])
	const [languageFilter, setLanguageFilter] = useState(["all"])
	const [selectedTag, setSelectedTag] = useState("generic")

	// set search term based on user text input, e.g. "demo"
	const handleSearchTextChange = (event) => {
		setSearchText(event.target.value)
	}

	// if searchText or statusFilter updated trigger filter update...
	useEffect(() => {
		// create a course list with name for search and types for filter...
		let templateList = courseTemplateList.map((course) => {
			return {
				course_id: course.course_id,
				name: course.course_info.name,
				short_description: course.course_info.short_description,
				status: course.status,
				language: course.course_info.language,
				tags: course.template_info.tags
			}
		})

		// handle search from text...
		let resultsFromSearch = templateList.map((v) => v.course_id)
		if (searchText !== "") {
			let filteredFromSearchList = matchSorter(templateList, searchText, {
				keys: ["name", "short_description"]
			})
			resultsFromSearch = filteredFromSearchList.map((x) => x.course_id)
		}
		// handle filter from status multiselect...
		let resultsFromFilter = templateList
		if (!statusFilter.includes("all")) {
			resultsFromFilter = templateList.filter((x) => statusFilter.includes(x.status))
		}
		if (!languageFilter.includes("all")) {
			resultsFromFilter = resultsFromFilter.filter((x) => languageFilter.includes(x.language))
		}
		resultsFromFilter = resultsFromFilter.filter((x) => x.tags.includes(selectedTag))
		resultsFromFilter = resultsFromFilter.map((x) => x.course_id)

		const templateListToReturn = resultsFromSearch.filter((value) => resultsFromFilter.includes(value))

		setFilteredTemplates(courseTemplateList.filter((item) => templateListToReturn.includes(item.course_id)))
	}, [searchText, statusFilter, languageFilter, selectedTag, setFilteredTemplates, courseTemplateList])

	// options for our multiselect
	const filterOptions = [
		{ label: "All", value: "all" },
		{ label: "Free", value: "free" },
		{ label: "Unlocked", value: "unlocked" },
		{ label: "Locked", value: "locked" }
	]

	const courseLanguages = courseTemplateList.map((item) => item.course_info.language)
	const uniqueLanguages = [...new Set(courseLanguages)]

	const courseTags = courseTemplateList.map((item) => item.template_info.tags)
	let tagOptions = []
	courseTags.forEach((row) => {
		if (row) {
			tagOptions = [...tagOptions, ...row]
		}
	})
	let uniqueTags = [...new Set(tagOptions)]

	const languageOptions = [
		{ label: "All languages", value: "all" },
		...languageList.filter((item) => uniqueLanguages.includes(item.value))
	]

	return (
		<>
			<SubNavContainer>
				<SearchContainer>
					<InputWrapper>
						<Search
							placeholder="Search templates..."
							onChange={handleSearchTextChange}
							style={{ width: "100%" }}
						/>
					</InputWrapper>
				</SearchContainer>

				<FilterContainer>
					<Select
						showArrow={true}
						showSearch={false}
						style={{ width: "100%" }}
						defaultValue={"all"}
						options={filterOptions}
						onChange={(value) => setStatusFilter([value])}
						placeholder="Select courses..."
					/>
				</FilterContainer>

				<FilterContainer>
					<Select
						showArrow={true}
						showSearch={true}
						style={{ width: "100%" }}
						defaultValue={"all"}
						options={languageOptions}
						onChange={(language) => setLanguageFilter([language])}
						placeholder="Select language..."
						filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
					/>
				</FilterContainer>
			</SubNavContainer>
			<SubNavContainer>
				{uniqueTags.map((tag, index) => (
					<CheckableTag
						key={index}
						checked={selectedTag === tag}
						onChange={() => setSelectedTag(tag)}
						style={{ borderRadius: 14, fontSize: 15, padding: "5px 10px 5px 10px" }}
					>
						{tag}
					</CheckableTag>
				))}
			</SubNavContainer>
		</>
	)
}

export default TemplatesSubNav
