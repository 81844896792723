import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"

const AnswerContainer = styled.div`
	width: 48%;
	height: 90px;
	border-style: solid;
	border-width: 2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
`

interface Props {
	isTrue: boolean
	isCorrect: boolean
}

const BooleanAnswer = ({ isTrue, isCorrect }: Props) => {
	return (
		<AnswerContainer
			style={{
				background: isCorrect ? "var(--mid-green)" : "white",
				borderColor: isCorrect ? "var(--dark-green)" : "var(--light-grey)",
				borderRadius: 6
			}}
			key={isTrue ? "true" : "false"}
		>
			<FontAwesomeIcon
				icon={isTrue ? (faThumbsUp as IconProp) : (faThumbsDown as IconProp)}
				style={{ fontSize: 20, color: "var(--mid-grey)", marginBottom: 10 }}
			/>
			<div style={{ textTransform: "uppercase", fontWeight: "bold", color: "var(--mid-grey)" }}>
				{isTrue ? "True" : "False"}
			</div>
		</AnswerContainer>
	)
}

export default BooleanAnswer
