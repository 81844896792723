import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Button, Form, Input, Select } from "antd"
import { Link } from "react-router-dom"

import { selectOrgId, selectOrgUserGroups } from "../../../redux/org/org.selectors"
import * as S from "../../styles/PageStyles"

import FormModal from "../../common/FormModal"
import { ModalState } from "../../../util/model/PageStates"
import { INVALID_USER_GROUP_NAMES, UserGroupType } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { OrgUserGroups } from "../../../util/model/OrgSelectors"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"
import { selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { EMOJI_REGEX } from "learnink-common/lib/constants/constants"
import { PremiumFeatureAccess } from "../../../redux/org/org.model"

interface Props {
	orgUserGroups: OrgUserGroups
	handleSubmit: (values: any) => void
	modalState: ModalState
	closeModal: () => void
	type?: UserGroupType
	premiumFeatureAccess: PremiumFeatureAccess
	orgId: string
	isAdmin: boolean
}

const CreateUserGroup = ({
	orgUserGroups,
	handleSubmit,
	modalState,
	closeModal,
	type,
	premiumFeatureAccess,
	orgId,
	isAdmin
}: Props) => {
	const groupIds = Object.keys(orgUserGroups)
	let currentGroupNames: string[] = []
	groupIds.forEach((element) => {
		currentGroupNames.push(orgUserGroups[element].user_group_info.name.toLowerCase())
	})
	let reservedUserGroupNames = [...INVALID_USER_GROUP_NAMES, ...currentGroupNames]
	let showUserGroupType = false
	if (premiumFeatureAccess.learningPaths === "unlocked") {
		showUserGroupType = true
	}
	if (premiumFeatureAccess.learningPaths === "hidden" && isAdmin) {
		showUserGroupType = true
	}

	const formElements = (
		<>
			<Form.Item
				label={<S.FormLabel>Name</S.FormLabel>}
				name="name"
				tooltip="Add a short name to describe your group e.g. agents"
				rules={[
					{
						required: true,
						message: "User group name is required"
					},
					{
						max: 30,
						message: "Group name must have maximum 30 characters."
					},
					{
						message: "Cannot contain commas",
						validator: (_, value) => {
							if (value && value.includes(",")) {
								return Promise.reject()
							} else {
								return Promise.resolve()
							}
						}
					},
					{
						message: "Cannot contain emojis",
						validator: (_, value) => {
							if (value && EMOJI_REGEX.test(value)) {
								return Promise.reject()
							} else {
								return Promise.resolve()
							}
						}
					},
					{
						message: "This user group name already exists",
						validator: (_, value) => {
							if (value && reservedUserGroupNames.indexOf(value.toLowerCase()) >= 0) {
								return Promise.reject()
							} else {
								return Promise.resolve()
							}
						}
					}
				]}
			>
				<Input placeholder="e.g. Agent" />
			</Form.Item>
			<Form.Item
				label={<S.FormLabel>Description</S.FormLabel>}
				name="description"
				rules={[
					{
						required: true,
						message: "User group description is required"
					}
				]}
				tooltip="Add a short description to help you remember what this user group is for"
			>
				<Input placeholder="e.g. All sales agents " />
			</Form.Item>
			{showUserGroupType && (
				<Form.Item
					label={<S.FormLabel>Type</S.FormLabel>}
					name="type"
					rules={[
						{
							required: true,
							message: "User group type is required"
						}
					]}
					tooltip="Users can only be in one role but can have multiple tags"
					style={{
						display: type === "role" ? "none" : "block",
						opacity: premiumFeatureAccess.learningPaths === "hidden" ? 0.5 : 1
					}}
				>
					<Select
						options={[
							{ label: "Tag", value: "tag" },
							{ label: "Role", value: "role" }
						]}
						placeholder="Select type..."
					/>
				</Form.Item>
			)}
			{isAdmin && (
				<Link to={`/org/${orgId}/manage/user-groups/import`}>
					<Button size="small" style={{ opacity: 0.6 }}>
						Bulk import user groups
					</Button>
				</Link>
			)}
		</>
	)

	return (
		<FormModal
			title={type === "role" ? "Create role" : "Create user group"}
			isModalOpen={true}
			initialValues={type === "role" ? { type: "role" } : {}}
			closeModal={closeModal}
			modalState={modalState}
			handleSubmit={handleSubmit}
			formElements={formElements}
			submitButtonTitle="Create"
			size="large"
		/>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	isAdmin: selectIsUserAdmin
})

// @ts-ignore
export default connect(mapStateToProps)(CreateUserGroup)
