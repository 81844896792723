import { useState } from "react"

const useLpModal = () => {
	const [isShowingModal, setIsShowingModal] = useState(false)

	const toggleModal = () => {
		setIsShowingModal(!isShowingModal)
	}

	return {
		isShowingModal,
		toggleModal
	}
}

export default useLpModal
