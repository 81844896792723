import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const DropDownContainer = styled.div`
	position: relative;
	display: inline-block;
`
const DropDownButton = styled.div`
	color: var(--light-grey);
	background: none;
`
const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	margin: 0 auto;
	width: 100%;
	min-width: 336px;
	min-height: 580px;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: table;
`
const DropdownItemWrapper = styled.div`
	width: max-content;
	margin: 10px 8px;
	font-size: 14px;
	font-weight: 400;
	color: black;
	text-align: left;
	cursor: pointer;
	background-color: white;
	line-height: 1.4;
	:hover {
		color: var(--secondary-color) !important;
	}
	:focus {
		color: var(--secondary-color) !important;
	}
	:active {
		color: var(--secondary-color) !important;
	}
`
const DropDownContent = styled.div`
	position: absolute;
	background-color: white;
	min-width: 90px;
	border-radius: 0px;
	right: 0px;
	overflow: auto;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	z-index: 1000;
	color: black;
	padding: 8px 10px;
	line-height: 32px;
	text-decoration: none;
`

export function GeDropdown({ dropDownTrigger, dropdownElements }) {
	const [menuOpen, setMenuOpen] = useState(false)

	const openMenu = () => setMenuOpen(true)
	const closeMenu = () => setMenuOpen(false)

	return (
		<DropDownContainer>
			<DropDownButton onClick={openMenu}>{dropDownTrigger}</DropDownButton>

			{menuOpen && (
				<>
					<Overlay style={{ background: "none" }} onClick={closeMenu} />

					<DropDownContent>
						{dropdownElements.map((el, i) => {
							//console.log("el: ", el.props.closeonclick);
							return (
								<DropdownItemWrapper key={i} onClick={el.props.closeonclick && closeMenu}>
									{el}
								</DropdownItemWrapper>
							)
						})}
					</DropDownContent>
				</>
			)}
		</DropDownContainer>
	)
}

GeDropdown.propTypes = {
	dropDownTrigger: PropTypes.node.isRequired,
	dropdownElements: PropTypes.array.isRequired
}

GeDropdown.defaultProps = {
	dropDownTrigger: <div>Menu</div>,
	dropdownElements: []
}

export default GeDropdown
