import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"

import BasicTable from "../../common/BasicTable"

const UserGroupSummaryTable = ({ userGroupsArr, data, orgUserGroupsNames }) => {
	const tableData = []
	if (userGroupsArr.length > 0) {
		userGroupsArr.forEach((userGroup) => {
			const userGroupName = orgUserGroupsNames[userGroup]
			const filteredData = data.filter((item) => item.userGroups.includes(userGroup))
			const baselineScores = filteredData
				.filter((item) => item.baselineAccuracy !== null)
				.map((item) => item.baselineAccuracy)
			const endlineScores = filteredData
				.filter((item) => item.endlineAccuracy !== null)
				.map((item) => item.endlineAccuracy)
			const baselineAccuracy =
				baselineScores.length > 0
					? Math.round(baselineScores.reduce((partialSum, a) => partialSum + a, 0) / baselineScores.length)
					: "--"
			const endlineAccuracy =
				endlineScores.length > 0
					? Math.round(endlineScores.reduce((partialSum, a) => partialSum + a, 0) / endlineScores.length)
					: "--"
			tableData.push({
				userGroupName,
				numStartedCourse: filteredData.length,
				numCompletedCourse: filteredData.filter((item) => item.status === "complete").length,
				baselineAccuracy,
				endlineAccuracy,
				improvement:
					baselineScores.length > 0 && endlineScores.length > 0
						? Math.round(((endlineAccuracy - baselineAccuracy) * 100) / baselineAccuracy)
						: "--"
			})
		})
	} else {
		const baselineScores = data
			.filter((item) => item.baselineAccuracy !== null)
			.map((item) => item.baselineAccuracy)
		const endlineScores = data.filter((item) => item.endlineAccuracy !== null).map((item) => item.endlineAccuracy)
		const baselineAccuracy =
			baselineScores.length > 0
				? Math.round(baselineScores.reduce((partialSum, a) => partialSum + a, 0) / baselineScores.length)
				: "--"
		const endlineAccuracy =
			endlineScores.length > 0
				? Math.round(endlineScores.reduce((partialSum, a) => partialSum + a, 0) / endlineScores.length)
				: "--"
		tableData.push({
			userGroupName: orgUserGroupsNames["registered"],
			numStartedCourse: data.length,
			numCompletedCourse: data.filter((item) => item.status === "complete").length,
			baselineAccuracy,
			endlineAccuracy,
			improvement:
				baselineScores.length > 0 && endlineScores.length > 0
					? Math.round(((endlineAccuracy - baselineAccuracy) * 100) / baselineAccuracy)
					: "--"
		})
	}

	return (
		<div className="row" style={{ padding: 15 }}>
			<BasicTable
				columns={[
					{
						Header: "User group",
						accessor: "userGroupName"
					},
					{
						Header: "Num started",
						accessor: "numStartedCourse"
					},
					{
						Header: "Num completed",
						accessor: "numCompletedCourse"
					},
					{
						Header: "Baseline accuracy",
						accessor: "baselineAccuracy"
					},
					{
						Header: "Endline accuracy",
						accessor: "endlineAccuracy"
					},
					{
						Header: "% improvement",
						accessor: "improvement"
					}
				]}
				data={tableData}
			/>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsNames: selectOrgUserGroupNamesMap
})

export default connect(mapStateToProps)(UserGroupSummaryTable)
