import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import ResourcesTable from "./ResourcesTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookOpen } from "@fortawesome/free-solid-svg-icons"

import { selectOrgId } from "../../redux/org/org.selectors"
import { selectResourcesList } from "../../redux/resources/resources.selectors"

import GeNoItemsFound from "../common/GeNoItemsFound"
import CbLoading from "../common/CbLoading"

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 260px;
	@media (min-width: 590px) {
		width: ${({ smWidth }) => (smWidth ? smWidth : "520px")};
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: ${({ smWidth }) => (smWidth ? smWidth : "520px")};
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: ${({ laWidth }) => (laWidth ? laWidth : "780px")};
		max-width: 100%;
	}
`
const ListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`

/*
Note: it's worth studying the quickstart for the table libary we're using
https://github.com/tannerlinsley/react-table/blob/master/docs/quickstart.md
*/

const ResourcesTableQueryWrapper = ({ orgId, resourcesList }) => {
	const [resourcesTableData, setResourcesTableData] = useState([])
	const [loadingData, setLoadingData] = useState(true)

	useEffect(() => {
		setLoadingData(true)

		let tableFormattedData = resourcesList.map((item) => {
			let newItem = {}
			newItem.resource_id = item.resource_id
			newItem.title = item.info.title
			newItem.url = item.info.url
			newItem.image_url = item.info.image_url
			newItem.description = item.info.description
			newItem.type = item.info.fileType
			newItem.view_groups = item.view_groups
			newItem.access_groups = item.access_groups
			return newItem
		})
		// set our local state for table here
		setResourcesTableData(tableFormattedData)
		setLoadingData(false)
	}, [orgId, resourcesList])

	// DEFINE COLUMNS
	// --------------
	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "image_url",
				width: 50
			},
			{
				Header: "Title",
				accessor: "title" // accessor is the "key" in the data
			},
			{
				Header: "Type",
				accessor: "type",
				width: 100
			},
			{
				Header: "User groups",
				id: "user_groups",
				accessor: (data) => {
					return data.access_groups
				}
			}
		],
		[]
	)

	return (
		<MainContainer>
			{loadingData && <CbLoading size={40} />}
			{!loadingData && resourcesTableData.length > 0 && (
				<ResourcesTable data={resourcesTableData} columns={columns} />
			)}
			{!loadingData && resourcesTableData.length === 0 && (
				<ListInner>
					<GeNoItemsFound
						icon={<FontAwesomeIcon icon={faBookOpen} />}
						titleText={"No resources found"}
						subtitleText={"Press the button to start creating your first resource!"}
					/>
				</ListInner>
			)}
		</MainContainer>
	)
}

ResourcesTableQueryWrapper.propTypes = {
	orgId: PropTypes.string.isRequired,
	resourcesList: PropTypes.array.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	resourcesList: selectResourcesList
})

export default connect(mapStateToProps)(ResourcesTableQueryWrapper)
