import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroups } from "../../../../redux/org/org.selectors"

import SingleSurveyPanel from "./SingleSurveyPanel"
import SurveysAnswersTablePanel from "./SurveysAnswersTablePanel"

const SurveyQuestionSelector = ({ surveyContent, questionIdsArr, queryData, orgUserGroups }) => {
	queryData.forEach((row) => {
		row.userGroupNames = row.userGroups.map((item) =>
			orgUserGroups[item] ? orgUserGroups[item].user_group_info.name : null
		)
	})

	return (
		<>
			{questionIdsArr.map((questionId, index) => (
				<SingleSurveyPanel
					key={index}
					questionId={questionId}
					surveyContent={surveyContent}
					cardData={queryData.filter((item) => item.questionId === questionId)}
				/>
			))}

			<SurveysAnswersTablePanel
				questionIdsArr={questionIdsArr}
				queryData={queryData}
				surveyContent={surveyContent}
			/>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(SurveyQuestionSelector)
