import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { Typography, Button, Input, Form, Tag, Avatar, Space, Alert } from "antd"

import { selectOrgId, selectOrgUserGroupNamesMap } from "../../../redux/org/org.selectors"
import { updateUserOrgInfo } from "../../../redux/management/management.actions"

import UserGroupsSelector from "../../common/UserGroupsSelector"
import CbLoading from "../../common/CbLoading"
import { alphabetColors, statusColors } from "../../analyse/common/colors"

const { Title } = Typography

const ContentContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
`
const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding-bottom: 15px;
`
const ButtonsContainer = styled.div`
	margin-top: 20px;
	display: flex;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
`

const UserSidePanel = ({ orgId, userData, closemodal, userGroupsNamesMap, updateUserOrgInfo, refreshUsers }) => {
	const [showSaveChanges, setShowSaveChanges] = useState(false)
	const [loading, setLoading] = useState(false)
	const [reload, setReload] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const handleSaveChanges = async (values) => {
		try {
			setLoading(true)
			const { custom_name, custom_id, user_groups } = values
			const userInfo = {
				custom_name,
				custom_id,
				user_groups
			}
			//	This line updates the redux users in the old managed screen
			await updateUserOrgInfo(orgId, userData.userId, userInfo, userGroupsNamesMap)

			//	This line updates the api users in the new managed screen
			refreshUsers?.([userData.userId])
			setTimeout(() => {
				setLoading(false)
				closemodal()
			}, 1000)
		} catch (err) {
			const message =
				err && err.message
					? `Error: ${err.message}`
					: "Sorry we couldn't update this user, please check your connection and try again"
			setTimeout(() => {
				setLoading(false)
				setErrorMsg(message)
			}, 1000)
		}
	}

	useEffect(() => {
		setReload(true)
		setShowSaveChanges(false)
		setLoading(false)
		setErrorMsg(null)
		setTimeout(() => {
			setReload(false)
		}, 100)
	}, [userData])

	if (reload) {
		return null
	}

	if (loading) {
		return <CbLoading />
	}

	if (errorMsg) {
		return <Alert type="error" message={errorMsg} />
	}

	return (
		<>
			<ContentContainer>
				<HeaderRow>
					<Avatar
						style={{
							backgroundColor: alphabetColors[userData.customName.charAt(0).toLowerCase()]
						}}
					>
						{userData.customName.charAt(0).toUpperCase()}
					</Avatar>
					<Tag color={statusColors[userData.registrationStatus]}>{userData.registrationStatus}</Tag>
				</HeaderRow>
				<Title level={4} style={{ marginBottom: 5 }}>
					{userData.customName}
				</Title>
				{userData.lastActive && userData.lastActive !== "no data" && (
					<Title
						level={5}
						style={{
							fontWeight: 500,
							fontSize: 14,
							marginTop: 5,
							color: "var(--mid-grey)"
						}}
					>
						Last active: {moment(userData.lastActive).fromNow()}
					</Title>
				)}
				<Divider borderOnBottom={true} height={"14px"} />
				<Divider height={"24px"} />
				{loading ? (
					<>
						<CbLoading loadingText="Updating user..." padding="30px" />
						Please note: changes may take a few minutes to reflect in the user list
					</>
				) : (
					<>
						<Form
							size="small"
							autoComplete="off"
							initialValues={{
								custom_name: userData.customName,
								region: userData.region,
								country: userData.country,
								phone_number: userData.phoneNumber,
								user_id: userData.userId,
								custom_id: userData.customId,
								user_groups: userData.userGroups
							}}
							onValuesChange={() => setShowSaveChanges(true)}
							onFinish={handleSaveChanges}
						>
							<Form.Item
								label="Full name"
								name="custom_name"
								rules={[
									{
										required: true,
										message: "Your user needs to have a name"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item label="Phone number" name="phone_number">
								<Input disabled />
							</Form.Item>
							<Form.Item label="Region" name="region">
								<Input disabled />
							</Form.Item>
							<Form.Item label="Country" name="country">
								<Input disabled />
							</Form.Item>
							<Form.Item label="User id" name="user_id">
								<Input disabled />
							</Form.Item>
							<Form.Item label="Custom id" name="custom_id">
								<Input />
							</Form.Item>
							<UserGroupsSelector
								tooltip="Select user groups to add your user to"
								labelFontSize="14px !important"
								disableRegistered
							/>
							<Form.Item>
								<ButtonsContainer>
									<Space>
										{showSaveChanges && (
											<Button onClick={closemodal} shape="round" size="medium">
												Cancel
											</Button>
										)}
										<Button
											disabled={!showSaveChanges}
											type="primary"
											shape="round"
											size="medium"
											htmlType="submit"
										>
											Save changes
										</Button>
									</Space>
								</ButtonsContainer>
							</Form.Item>
						</Form>
					</>
				)}
			</ContentContainer>
		</>
	)
}

UserSidePanel.propTypes = {
	orgId: PropTypes.string.isRequired,
	userData: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired
	}).isRequired,
	closemodal: PropTypes.func.isRequired,
	refreshUsers: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userGroupsNamesMap: selectOrgUserGroupNamesMap
})

const mapDispatchToProps = (dispatch) => ({
	updateUserOrgInfo: (orgId, userId, userInfo, userGroupsNamesMap) =>
		dispatch(updateUserOrgInfo(orgId, userId, userInfo, userGroupsNamesMap))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSidePanel)
