import React, { useState } from "react"
import { Button, Space, Typography } from "antd"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"

import * as S from "../../styles/PageStyles"
import LearningPathContainer from "./LearningPathContainer"
import GeNoItemsFound from "../../common/GeNoItemsFound"
import { DatabaseOutlined } from "@ant-design/icons"

const { Title, Text } = Typography

interface Props {
	learningPaths: LearningPath[]
	setModalType: React.Dispatch<React.SetStateAction<"create" | "archive" | "unarchive" | "share" | "none">>
	setSelectedLearningPathId: React.Dispatch<React.SetStateAction<string | undefined>>
	roleId: string
}

const LearningPathSection = ({ learningPaths, setModalType, setSelectedLearningPathId, roleId }: Props) => {
	const [showInactive, setShowInactive] = useState<boolean>(false)

	return (
		<>
			<S.FlexBoxStartRow
				style={{
					borderBottom: "solid 1px var(--light-grey)",
					padding: 8,
					marginBottom: 20,
					justifyContent: "space-between",
					marginTop: 15
				}}
			>
				<div>
					<Title level={4} style={{ marginBottom: 4 }}>
						Learning paths
					</Title>
					<Text type="secondary">Create self-guided or in-person learning experiences</Text>
				</div>
				{learningPaths.filter((item) => item.isArchived).length > 0 && (
					<Button shape="round" onClick={() => setShowInactive(!showInactive)}>
						{showInactive ? "Hide archived" : "Show archived"}
					</Button>
				)}
			</S.FlexBoxStartRow>
			{learningPaths.length > 0 ? (
				<div style={{ display: "table" }}>
					{showInactive && (
						<>
							{learningPaths
								.filter((item) => item.isArchived)
								.map((item: LearningPath, index: number) => (
									<LearningPathContainer
										key={index}
										learningPath={item}
										setModalType={setModalType}
										setSelectedLearningPathId={setSelectedLearningPathId}
										roleId={roleId}
									/>
								))}
						</>
					)}
					{learningPaths
						.filter((item) => !item.isArchived)
						.map((item: LearningPath, index: number) => (
							<LearningPathContainer
								key={index}
								learningPath={item}
								setModalType={setModalType}
								setSelectedLearningPathId={setSelectedLearningPathId}
								roleId={roleId}
							/>
						))}
				</div>
			) : (
				<div style={{ marginBottom: 30 }}>
					<GeNoItemsFound
						icon={<DatabaseOutlined style={{ fontSize: 40 }} />}
						titleText="Create your first learning path"
						subtitleText="You haven't created any learning paths for this role. Press to get started"
					>
						<Space direction="vertical" align="center" style={{ marginTop: 20 }}>
							<Button type="primary" shape="round" size="large" onClick={() => setModalType("create")}>
								Create
							</Button>
						</Space>
					</GeNoItemsFound>
				</div>
			)}
		</>
	)
}

export default LearningPathSection
