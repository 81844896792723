import React from "react"
import moment from "moment"
import styled from "styled-components"
import { Typography, Avatar } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import * as S from "../../../../styles/PageStyles"

import { alphabetColors } from "../../../common/colors"
import GeNoItemsFound from "../../../../common/GeNoItemsFound"

const { Title, Text } = Typography

const ScrollContainer = styled.div`
	height: 350px;
	overflow-y: auto;
`

const Container = styled.div`
	height: 350px;
	text-align: center;
	padding: 10px;
`

const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	margin-bottom: 16px;
`

const EventHighlight = styled.span`
	color: ${({ type }) => (type === "started" ? "var(--mid-blue)" : "#a3d400")};
	font-weight: 600;
`

const SequenceLatestActivity = ({ data }) => {
	// remove rows from data which have no name
	const filteredData = data.filter((item) => item.customName !== null)
	const numEventsToShow = 20
	const lastTenStarted = filteredData.slice(0, numEventsToShow)
	let orderByCompleted = filteredData
		.filter((item) => item.completedAt !== null)
		.sort(function (a, b) {
			var keyA = new Date(a["completedAt"]),
				keyB = new Date(b["completedAt"])
			// Compare the 2 dates
			if (keyA > keyB) return -1
			if (keyA < keyB) return 1
			return 0
		})
	const lastTenCompleted = orderByCompleted.slice(0, numEventsToShow)
	let events = []
	for (var i = 0; i < numEventsToShow; i++) {
		if (lastTenStarted[i]) {
			events.push({
				name: lastTenStarted[i].customName,
				type: "started",
				timestamp: lastTenStarted[i].startedAt
			})
		}
		if (lastTenCompleted[i]) {
			events.push({
				name: lastTenCompleted[i].customName,
				type: "completed",
				timestamp: lastTenCompleted[i].completedAt
			})
		}
	}
	orderByCompleted = events.sort(function (a, b) {
		var keyA = new Date(a.timestamp),
			keyB = new Date(b.timestamp)
		// Compare the 2 dates
		if (keyA > keyB) return -1
		if (keyA < keyB) return 1
		return 0
	})
	let lastTenEvents = orderByCompleted.slice(0, numEventsToShow)

	return (
		<S.Card>
			<S.CardBody>
				<Title level={5}>Latest activity</Title>
				{lastTenEvents.length > 0 ? (
					<ScrollContainer>
						{lastTenEvents.map((item, index) => (
							<Row key={index}>
								<Avatar
									style={{
										backgroundColor: alphabetColors[item.name.charAt(0).toLowerCase()]
									}}
								>
									{item.name.charAt(0).toUpperCase()}
								</Avatar>
								<div style={{ paddingLeft: 8 }}>
									<Text>
										{item.name} <EventHighlight type={item.type}>{item.type}</EventHighlight> the
										sequence
									</Text>
									<p>
										<Text type="secondary">{moment(item.timestamp).fromNow()}</Text>
									</p>
								</div>
							</Row>
						))}
					</ScrollContainer>
				) : (
					<Container>
						<GeNoItemsFound
							titleText="No data"
							subtitleText="No user data found for this selection"
							icon={<FontAwesomeIcon icon={faExclamationCircle} />}
						/>
					</Container>
				)}
			</S.CardBody>
		</S.Card>
	)
}

export default SequenceLatestActivity
