import React, { useState } from "react"
import { Dropdown, MenuProps, Typography } from "antd"
import styled from "styled-components"
import { CloseOutlined } from "@ant-design/icons"
import HelpVideoButton, { VideoId } from "./HelpVideoButton"

const { Title, Paragraph } = Typography

const ContainerBox = styled.div`
	width: 100%;
	border: solid 1px #95e1a1;
	padding: 10px;
	padding-right: 30px;
	background: var(--secondary-color-pale);
	border-left: solid 5px var(--secondary-color);
	position: relative;
	display: flex;
	justify-content: flex-start;
	border-radius: 5px;
`
const CloseButton = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	color: var(--secondary-color);
	cursor: pointer;
	transition: 0.5s ease;
	:hover {
		color: #1eb236;
	}
`

interface Props {
	id: string
	title?: string
	text?: string
	videoId?: VideoId
	buttonSize?: "small" | "middle"
}

const InfoBanner = ({ id, title, text, videoId, buttonSize }: Props) => {
	const [isHidden, setIsHidden] = useState<boolean>(
		window.localStorage.getItem("hiddenBannerIds")?.includes(id) || false
	)
	if (isHidden) return <></>

	const hideForever = () => {
		const hiddenIdsFromLocalStorage = window.localStorage.getItem("hiddenBannerIds") || "[]"
		const hiddenIdsArray = JSON.parse(hiddenIdsFromLocalStorage)
		const updatedHiddenIds = [...new Set([...hiddenIdsArray, id])]
		window.localStorage.setItem("hiddenBannerIds", JSON.stringify(updatedHiddenIds))
		setIsHidden(true)
	}

	const items: MenuProps["items"] = [
		{
			key: "hide",
			label: <div onClick={() => setIsHidden(true)}>Hide for now</div>
		},
		{
			key: "hide_forever",
			label: <div onClick={hideForever}>Don't show again</div>
		}
	]

	return (
		<ContainerBox style={{ marginBottom: buttonSize === "small" ? 10 : 20 }}>
			{videoId && <HelpVideoButton videoId={videoId} buttonType="thumbnail" buttonSize={buttonSize} />}
			<div
				style={{
					height: "100%",
					paddingLeft: 16,
					paddingTop: 0
				}}
			>
				{title && (
					<Title level={5} style={{ color: "#1eb236", marginBottom: 0 }}>
						{title}
					</Title>
				)}
				{text && <Paragraph style={{ color: "#1eb236", marginBottom: 0 }}>{text}</Paragraph>}
			</div>
			<Dropdown menu={{ items }} placement="bottomRight">
				<CloseButton onClick={() => setIsHidden(true)}>
					<CloseOutlined />
				</CloseButton>
			</Dropdown>
		</ContainerBox>
	)
}

export default InfoBanner
