import { Button, Modal, Space, Upload, UploadProps } from "antd"
import React, { useEffect, useRef, useState } from "react"
import {
	AudioFilled,
	CaretRightFilled,
	CheckOutlined,
	RedoOutlined,
	SaveFilled,
	UploadOutlined
} from "@ant-design/icons"
import AudioRecorder from "./AudioRecorder"
import WaveSurfer from "wavesurfer.js"
import * as S from "./RecordAudioModal.styles"
import * as PS from "../../styles/PageStyles"
import { DEFAULT_WAVEFORM_OPTIONS } from "./RecordAudioModal.styles"
import { secondsToHHmm } from "learnink-common/lib/tools/tools"
import Routes from "../../../util/Routes"
import { useApi } from "../../../util/useApi"
import { createStructuredSelector } from "reselect"
import { selectOrgId } from "../../../redux/org/org.selectors"
import { selectCurrentStageId } from "../../../redux/courses/courses.selectors"
import { connect } from "react-redux"
import { selectUserInfo } from "../../../redux/user/user.selectors"
import { setQuestionState, setLessonCardState } from "../../../redux/courses/courses.actions"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { TeacherOrLearner } from "learnink-common/lib/domains/cards/lessons/BaseLessonCard.model"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { LessonCard } from "learnink-common/lib/domains/cards/lessons/LessonCard.model"
import { QuestionCard } from "learnink-common/lib/domains/cards/questions/QuestionCard.model"
import Title from "antd/lib/typography/Title"

type ModalState = "start" | "countdown" | "record" | "review"

interface RecordAudioModalProps {
	textId: string
	userInfo: { user_id: string }
	orgId: string
	stageId: string
	card: Card
	languageCode: LanguageCode
	textToRecord: string | undefined
	modalIsOpen: boolean
	setModalIsOpen: (modalIsOpen: boolean) => void
	setLessonCard: (updateParams: LessonCard) => void
	setQuestion: (updateParams: QuestionCard) => void
}

interface RecordAudioState {
	modalIsOpen: boolean
	setModalIsOpen: (modalIsOpen: boolean) => void
}

interface ProcessAudioParams {
	userId: string
	orgId: string
	stageId: string
	cardId: string
	languageCode: LanguageCode
	source: Blob
}

export const useRecordAudioProps = (): RecordAudioState => {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

	return { modalIsOpen, setModalIsOpen }
}

const RecordAudioModal = ({
	textId,
	userInfo,
	orgId,
	stageId,
	card,
	languageCode,
	textToRecord,
	modalIsOpen,
	setModalIsOpen,
	setLessonCard,
	setQuestion
}: RecordAudioModalProps) => {
	const SECOND_IN_MILLIS = 1000
	const [modalState, setModalState] = useState<ModalState>("start")
	const [blob, setBlob] = useState<Blob | undefined>(undefined)
	const [waveform, setWaveform] = useState<WaveSurfer | undefined>(undefined)

	const waveformRef = useRef<HTMLDivElement>(null)
	const audioRecorder = new AudioRecorder()
	const { setApiRequest: processAudioFile } = useApi<ProcessAudioParams, VoiceClip>()

	const closeModal = () => {
		void handle.reset()
		setModalIsOpen(false)
	}

	//	 TODO - fix once we have full card types
	const refreshCard = card.type !== "multipleChoiceQuestion" ? setLessonCard : setQuestion

	const updateVoiceClips = (voiceClips: VoiceClip[], voiceClip: VoiceClip) => {
		const updatedVoiceClips = [...voiceClips].filter((item) => item.languageCode !== languageCode)
		updatedVoiceClips.push(voiceClip)
		return updatedVoiceClips
	}

	const updateCardVoiceClip = (voiceClip: VoiceClip): Card => {
		const newCard = { ...card }
		//	Initialise card
		newCard.voiceClips = [...(newCard?.voiceClips || [])]

		// If the text ids match then we're updating the main part of the card
		if (newCard.textId === voiceClip.textId) {
			newCard.voiceClips = updateVoiceClips(newCard.voiceClips, voiceClip)
		}
		if (newCard.textId !== voiceClip.textId && newCard.type === "multipleChoiceSurvey") {
			newCard.surveyOptions = newCard.surveyOptions || []
			newCard.surveyOptions.forEach((option, index) => {
				if (option.textId === voiceClip.textId) {
					// @ts-ignore
					newCard.surveyOptions[index].voiceClips = updateVoiceClips(
						// @ts-ignore
						newCard.surveyOptions[index].voiceClips,
						voiceClip
					)
				}
			})
		}
		if (newCard.textId !== voiceClip.textId && newCard.type === "multipleChoiceQuestion") {
			newCard.answerOptions = newCard.answerOptions || []
			newCard.answerOptions.forEach((option, index) => {
				if (option.textId === voiceClip.textId) {
					// @ts-ignore
					newCard.answerOptions[index].voiceClips = updateVoiceClips(
						// @ts-ignore
						newCard.answerOptions[index].voiceClips,
						voiceClip
					)
				}
			})
		}
		return newCard
	}

	const handleProcessAudio = () => {
		const formData = getFormData()
		processAudioFile({
			route: Routes.processAudioFile,
			urlParams: { stageId },
			formData,
			onSuccess: (voiceClip) => {
				refreshCard(updateCardVoiceClip(voiceClip) as unknown as never)
				closeModal()
			},
			onError: console.log
		})
	}

	const getFormData = (): FormData => {
		if (!blob) throw new Error("source needs to be created first...")

		const formData = new FormData()
		formData.set("userId", userInfo?.user_id)
		formData.set("orgId", orgId)
		formData.set("stageId", stageId)
		formData.set("textId", textId)
		formData.set("languageCode", languageCode)
		formData.set("source", blob, "defaultFilename." + blob.type)

		return formData
	}

	const renderWaveform = () => {
		if (waveformRef.current && blob && !waveform) {
			const waveSurfer = WaveSurfer.create({ container: waveformRef.current, ...DEFAULT_WAVEFORM_OPTIONS })
			void waveSurfer.loadBlob(blob)
			setWaveform(waveSurfer)
		}
	}
	useEffect(renderWaveform, [modalIsOpen, waveformRef, blob, waveform])

	const recording = {
		start: () => audioRecorder.start().catch(console.log),
		stop: () => audioRecorder.stop().then(setBlob).catch(console.log),
		cancel: () => {
			setBlob(undefined)
			setWaveform(undefined)
			audioRecorder.cancel()
		}
	}

	const handle = {
		start: () => setModalState("countdown"),
		stop: () => {
			void recording.stop()
			setModalState("review")
		},
		reset: () => {
			recording.cancel()
			setModalState("start")
			if (waveform) {
				waveform.empty()
				waveform.destroy()
			}
		},
		play: async () => void waveform?.play(),
		save: handleProcessAudio
	}

	const Countdown = () => {
		const STARTING_COUNT = 3
		const [count, setCount] = useState(STARTING_COUNT)

		const countdown = () => {
			const timeout = setTimeout(() => {
				if (count > 1) setCount(count - 1)
				else setModalState("record")
			}, SECOND_IN_MILLIS)

			return () => clearTimeout(timeout)
		}

		useEffect(countdown, [count])

		return <S.Countdown>{count}</S.Countdown>
	}

	const Recording = () => {
		const MAX_RECORD_TIME_SECONDS = 60
		const [recordTimeSeconds, setRecordTimeSeconds] = useState(0)

		useEffect(() => void recording.start(), [])

		const incrementTime = () => {
			const timeout = setTimeout(() => {
				if (recordTimeSeconds < MAX_RECORD_TIME_SECONDS) {
					setRecordTimeSeconds(recordTimeSeconds + 1)
				} else {
					handle.stop()
				}
			}, SECOND_IN_MILLIS)

			return () => clearTimeout(timeout)
		}
		useEffect(incrementTime, [recordTimeSeconds])

		const props = {
			redDotIsHidden: recordTimeSeconds % 2 === 1,
			timeFormatted: secondsToHHmm(recordTimeSeconds)
		}

		return <S.Recording {...props} />
	}

	if (!textId) {
		return (
			<Modal open={modalIsOpen} title="Record an audio clip" onCancel={closeModal} footer={null}>
				Your cards need to be updated to use the record audio feature, please contact support
			</Modal>
		)
	}

	const is = (inState: ModalState) => modalState === inState

	const teacherOrLearner = (card as LessonCard)?.from || ("teacher" as TeacherOrLearner)

	const handleFileChange: UploadProps["onChange"] = (info) => {
		const file = info.file.originFileObj || info.file // Access the file object directly
		if (file instanceof File) {
			const reader = new FileReader()

			reader.onloadend = () => {
				// Create a blob from the result
				const audioBlob = new Blob([reader.result as ArrayBuffer], { type: file.type })
				setBlob(audioBlob)
				setModalState("review")
			}

			// Read the file as an ArrayBuffer
			reader.readAsArrayBuffer(file)
		}
	}

	const uploadProps: UploadProps = {
		accept: "audio/*", // Restrict file types to audio
		beforeUpload: () => false, // Disable automatic upload
		onChange: handleFileChange // Handle file change manually
	}

	return (
		<Modal open={modalIsOpen} centered onCancel={closeModal} footer={null}>
			<PS.FlexBoxColumnCentered style={{ minHeight: 260 }}>
				<PS.FlexBoxColumnCentered style={{ flex: 1, justifyContent: "flex-end", paddingBottom: 10 }}>
					<div ref={waveformRef} style={{ width: "100%" }} />
					{is("start") && <Title level={3}>Press to record an audio clip</Title>}
					{is("record") && <Recording />}
					{is("countdown") && <Countdown />}
					<S.CueCard {...{ textToRecord, teacherOrLearner }} />
				</PS.FlexBoxColumnCentered>

				<PS.FlexBoxRowCentered style={{ height: 100, paddingTop: 10 }}>
					{is("start") && (
						<S.HugeButton title="Start" icon={<AudioFilled style={S.largeIcon} />} onClick={handle.start} />
					)}
					{is("record") && (
						<S.HugeButton
							title="Finish"
							icon={<CheckOutlined style={S.largeIcon} />}
							onClick={handle.stop}
						/>
					)}
					{is("review") && (
						<Space>
							<S.HugeButton
								title="Playback"
								icon={<CaretRightFilled style={S.largeIcon} />}
								onClick={handle.play}
							/>
							<S.HugeButton
								title="Restart"
								icon={<RedoOutlined style={S.largeIcon} />}
								onClick={handle.reset}
							/>
							<S.HugeButton
								title="Save"
								icon={<SaveFilled style={S.largeIcon} />}
								onClick={handle.save}
							/>
						</Space>
					)}
				</PS.FlexBoxRowCentered>
				{is("start") && (
					<div style={{ position: "absolute", bottom: 14, right: 10 }}>
						<Upload {...uploadProps}>
							<Button icon={<UploadOutlined />} disabled={!textId} shape="round">
								Upload
							</Button>
						</Upload>
					</div>
				)}
			</PS.FlexBoxColumnCentered>
		</Modal>
	)
}

const mapStateToProps = createStructuredSelector({
	userInfo: selectUserInfo,
	orgId: selectOrgId,
	stageId: selectCurrentStageId
})

const mapDispatchToProps = (dispatch: any) => ({
	setQuestion: (card: QuestionCard) => dispatch(setQuestionState(card)),
	setLessonCard: (card: LessonCard) => dispatch(setLessonCardState(card))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecordAudioModal)
