import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { Tag, Drawer } from "antd"
import {
	useTable,
	useResizeColumns,
	useFlexLayout,
	useRowSelect,
	useGlobalFilter,
	useSortBy,
	usePagination
} from "react-table"
import CbLoading from "../common/CbLoading"
import { GlobalFilter, DefaultColumnFilter, fuzzyTextFilterFn } from "../tables/tableFunctions"
import TablePagination from "../tables/TablePagination"

import UserGroupsTags from "../common/UserGroupsTags"
import EditResource from "./EditResource"

const typeTagColor = {
	website: "cyan",
	video: "volcano",
	pdf: "purple",
	word: "blue",
	ppt: "red",
	gif: "lime",
	image: "green",
	audio: "magenta"
}

const ScrollContainer = styled.div`
	${"" /* These styles are suggested for the table fill all available space in its containing element */}
	display: block;
	${"" /* These styles are required for a horizontaly scrollable table overflow */}
	overflow: auto;
`
const TableContainer = styled.div`
	border-spacing: 0;
	border: 0px solid black;
	margin-bottom: 20px;
`
const TableHeader = styled.div`
	${"" /* These styles are required for a scrollable body to align with the header properly */}
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	font-weight: 600;
`
const TableHeadData = styled.div`
	margin: 6px 12px;
`
const TableResizer = styled.div`
	right: 0;
	width: 10px;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	${"" /* prevents from scrolling while dragging on touch devices */}
	touch-action: none;
	border-right: solid 2px var(--v-light-grey);
	transition: 0.2s ease;
	:hover {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color)" : "var(--secondary-color)")};
	}
	:focus {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color)" : "var(--secondary-color)")};
	}
`
const TableRow = styled.div`
	:last-child {
		border-bottom: 0;
	}
	border-bottom: 0px solid black;
	background: ${({ isStriped }) => (isStriped ? "#f6f6f6" : "white")};
	cursor: pointer;
	height: ${({ isHeader }) => (isHeader ? "auto" : "50px")};
	:hover {
		background: ${({ isHeader }) => (isHeader ? "white" : "var(--secondary-color-pale)")};
	}
`
const TableBody = styled.div`
	${"" /* These styles are required for a scrollable table body */}
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
`
const TableData = styled.div`
	margin: 0;
	padding: 0.25rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	overflow: auto;
	${
		"" /* In this example we use an absolutely position resizer,
    so this is required. */
	}
	position: relative;
	:last-child {
		border-right: 0;
	}
`
const SortIconContainer = styled.span`
	margin-left: 6px;
`
const FixedContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0px;
	margin-bottom: 24px;
`
const FixedRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 590px) {
		flex-direction: column;
	}
`
const GlobalSearchContainer = styled.div`
	flex: 1 100px;
	max-width: 400px;
	display: flex;
	margin: 0px;
	align-items: center;
	justify-content: center;
	border: 0px solid var(--v-light-grey);
	border-radius: 32px;
	height: 40px;
	padding: 8px;

	@media (max-width: 590px) {
		flex: 1 40px;
	}
`
const GroupIcon = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 99%;
	background: ${(props) => (props.image === undefined ? "var(--mid-green)" : "url('" + props.image + "')")};
	background-size: cover;
`

// Helper functions
// ================
// used for table resizing...
const headerProps = (props, { column }) => getStyles(props, column.align)
const cellProps = (props, { cell }) => getStyles(props, cell.column.align)
const getStyles = (props, align = "left") => [
	props,
	{
		style: {
			alignItems: align === "center" ? "center" : "flex-start",
			justifyContent: "center",
			display: "flex"
		}
	}
]
// Let the table remove the filter if the string is empty...
fuzzyTextFilterFn.autoRemove = (val) => !val

// Component
// =========
const ResourcesTable = ({ columns, data, loadingData }) => {
	const [showDrawer, setShowDrawer] = useState(false)
	const [selectedResource, setSelectedResource] = useState(null)

	const openDrawer = (resource) => {
		setSelectedResource(resource)
		setShowDrawer(true)
	}
	// Table options
	// -------------
	// filterTypes & defaultColumn are passed to useTable root hook as options, must be memoized...

	// "Allows overriding or adding additional filter types for columns to use"
	// docs: https://github.com/tannerlinsley/react-table/blob/master/docs/api/useFilters.md#table-options
	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn, // defined in tableFunctions.js file...
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			}
		}),
		[]
	)
	// "The default column object for every column passed to React Table"
	// docs: https://github.com/tannerlinsley/react-table/blob/master/docs/api/useTable.md#table-options
	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter, // defined in tableFunctions.js file...
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 250, // width is used for both the flex-basis and flex-grow
			maxWidth: 500 // maxWidth is only used as a limit for resizing
		}),
		[]
	)
	/*
    Implement useTable hook below...

    in it's most basic form this would look like: useTable({ columns, data })
    ...but the below uses a number of features from the react-table API
    
    read more in the docs: https://github.com/tannerlinsley/react-table/tree/master/docs/api
  */
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page which has only the rows for the active page
		// for pagination...
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		preGlobalFilteredRows,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // passed from the constant set above, must be memoized
			filterTypes, // passed from the constant set above, , must be memoized
			initialState: {
				pageSize: 100,
				hiddenColumns: ["image_url"]
			}
		},
		// core plugin hooks
		useGlobalFilter,
		useSortBy,
		usePagination,
		useResizeColumns,
		useRowSelect,
		// layout hooks
		useFlexLayout,
		// add special columns
		(hooks) => {
			hooks.allColumns.push((columns) => [
				// Edit Column
				{
					id: "edit",
					disableResizing: true,
					minWidth: 60,
					width: 60,
					maxWidth: 60,
					// nothing in header...
					Header: "",
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => <GroupIcon image={row.original.image_url} />
				},
				...columns
			])
			hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
				// afix the parent group of the selection button to not be resizable
				const selectionGroupHeader = headerGroups[0].headers[0]
				if (selectionGroupHeader) {
					selectionGroupHeader.canResize = false
				}
			})
		}
	)

	if (loadingData) return <CbLoading fullpage={false} />

	return (
		<>
			{/* Top part includes global search.... */}
			<FixedContainer>
				<FixedRow>
					<GlobalSearchContainer>
						<GlobalFilter
							preGlobalFilteredRows={preGlobalFilteredRows}
							globalFilter={globalFilter}
							setGlobalFilter={setGlobalFilter}
						/>
					</GlobalSearchContainer>
				</FixedRow>
			</FixedContainer>

			{/* For table and data itself... */}
			<ScrollContainer>
				{/* Creat the TABLE... */}
				<TableContainer {...getTableProps()}>
					{/* Our column headers here... */}
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()} isHeader>
							{headerGroup.headers.map((column) => (
								<TableHeader {...column.getHeaderProps(headerProps)}>
									<TableHeadData {...column.getSortByToggleProps()}>
										{column.render("Header")}

										{/* Add a sort direction indicator */}
										<SortIconContainer>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<FontAwesomeIcon icon={faCaretUp} />
												) : (
													<FontAwesomeIcon icon={faCaretDown} />
												)
											) : (
												""
											)}
										</SortIconContainer>
									</TableHeadData>

									{/* Use column.getResizerProps to hook up the events correctly */}
									{column.canResize && (
										<TableResizer {...column.getResizerProps()} isResizing={column.isResizing} />
									)}
								</TableHeader>
							))}
						</TableRow>
					))}

					{/* Our table body data here... */}
					<TableBody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row)

							return (
								<React.Fragment key={i}>
									<TableRow
										{...row.getRowProps()}
										isStriped={i % 2 === 0}
										onClick={() => openDrawer(row.original)}
									>
										{row.cells.map((cell) => {
											if (cell.column.id === "type") {
												return (
													<TableData {...cell.getCellProps(cellProps)}>
														<Tag
															color={
																typeTagColor[cell.value]
																	? typeTagColor[cell.value]
																	: "blue"
															}
														>
															{cell.render("Cell")}
														</Tag>
													</TableData>
												)
											} else if (cell.column.id === "user_groups") {
												return (
													<TableData {...cell.getCellProps(cellProps)}>
														<div style={{ width: "100%" }}>
															<UserGroupsTags selectedUserGroups={cell.value} />
														</div>
													</TableData>
												)
											} else {
												return (
													<TableData {...cell.getCellProps(cellProps)}>
														{cell.render("Cell")}
													</TableData>
												)
											}
										})}
									</TableRow>
								</React.Fragment>
							)
						})}
					</TableBody>
				</TableContainer>
				<Drawer
					title="Resource settings"
					placement="right"
					onClose={() => {
						setShowDrawer(false)
					}}
					open={showDrawer}
				>
					<EditResource
						closemodal={() => {
							setShowDrawer(false)
						}}
						resourceData={selectedResource}
					/>
				</Drawer>
			</ScrollContainer>

			<TablePagination
				gotoPage={gotoPage}
				canPreviousPage={canPreviousPage}
				previousPage={previousPage}
				nextPage={nextPage}
				canNextPage={canNextPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				setPageSize={setPageSize}
				pageOptions={pageOptions}
				pageSize={pageSize}
			/>
		</>
	)
}

export default ResourcesTable
