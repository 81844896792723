import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faKey } from "@fortawesome/free-solid-svg-icons"
import axios from "axios"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import {
	selectLearningPathById,
	selectOrgId,
	selectOrgUserGroupNamesMap,
	selectSequenceNameById
} from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import CbLoading from "../../components/common/CbLoading"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import AccessCodesTable, { AccessCodeTableRow } from "../../components/management/accessCodes/AccessCodesTable"
import { AccessCode } from "learnink-common/lib/domains/accessCodes/AccessCode.model"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { LearningPathById, OrgUserGroupNamesMap, SequenceNameById } from "../../util/model/OrgSelectors"
import { updateFirebaseToken } from "../../redux/org/org.actions"

interface Props {
	orgId: string
	learningPathById: LearningPathById
	orgUserGroupNamesMap: OrgUserGroupNamesMap
	sequenceNameById: SequenceNameById
}

const AccessCodes = ({ orgId, learningPathById, orgUserGroupNamesMap, sequenceNameById }: Props) => {
	const [queryData, setQueryData] = useState<AccessCodeTableRow[] | undefined>(undefined)
	const [pageState, setPageState] = useState("loading")

	const getOrgAccessCodes = async () => {
		try {
			await updateFirebaseToken()
			const { data } = await axios.get(`/api/accessCode/${orgId}`)
			const tableData = data.map((row: AccessCode) => {
				// @ts-ignore
				const userGroups: string[] =
					row.actionList && row.actionList.filter((item) => item.field === "userGroups").length > 0
						? row.actionList.filter((item) => item.field === "userGroups")[0].userGroups
						: []
				const role =
					row.actionList && row.actionList.filter((item) => item.field === "role").length > 0
						? row.actionList.filter((item) => item.field === "role")[0].targetId
						: undefined
				if (role && !userGroups.includes(role)) {
					userGroups.push(role)
				}
				const userGroupNames = userGroups
					.filter((item) => orgUserGroupNamesMap[item])
					.map((item) => orgUserGroupNamesMap[item])
				const learningPathId =
					row.actionList && row.actionList.filter((item) => item.field === "learningPath").length > 0
						? row.actionList.filter((item) => item.field === "learningPath")[0].targetId
						: undefined
				const learningPathName =
					learningPathId && learningPathById[learningPathId]
						? learningPathById[learningPathId].internalName
						: undefined
				const assignmentIds =
					row.actionList.filter((item) => item.field === "assignment").length > 0
						? row.actionList.filter((item) => item.field === "assignment").map((item) => item.targetId)
						: []
				const assignmentNames = assignmentIds
					.filter((item) => item && sequenceNameById[item])
					// @ts-ignore
					.map((item) => sequenceNameById[item])
				return {
					accessCode: row.accessCode,
					description: row.description,
					userGroups,
					userGroupNames,
					learningPathId,
					learningPathName,
					assignmentIds,
					assignmentNames,
					status: row.isActive ? "active" : "deactivated",
					isActive: row.isActive,
					actionList: row.actionList
				}
			})
			setQueryData(tableData)
			setPageState("default")
		} catch (err) {
			console.log(err)
			setPageState("error")
		}
	}

	const refreshData = async () => {
		setPageState("loading")
		getOrgAccessCodes()
	}

	useEffect(() => {
		getOrgAccessCodes()
	}, [])

	return (
		<React.Fragment>
			<S.Container>
				<DashboardHeaderButton
					title={"Access codes"}
					buttonTitle={"Create access code"}
					buttonLink={"/org/" + orgId + "/manage/access-codes/create"}
				/>
				<S.MainContainer>
					{pageState === "default" && queryData && (
						<>
							{queryData.length === 0 ? (
								<GeNoItemsFound
									icon={<FontAwesomeIcon icon={faKey as IconProp} />}
									titleText={"No access codes"}
									subtitleText={"You haven't created any access codes yet"}
								/>
							) : (
								<AccessCodesTable accessCodes={queryData} refreshData={refreshData} />
							)}
						</>
					)}
					{pageState === "loading" && <CbLoading />}
					{pageState === "error" && (
						<GeNoItemsFound
							titleText="Slow query"
							subtitleText="It looks like this query failed to load in time. Please check your connection"
							icon={<FontAwesomeIcon icon={faExclamationCircle as IconProp} />}
						/>
					)}
				</S.MainContainer>
			</S.Container>
		</React.Fragment>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPathById: selectLearningPathById,
	orgUserGroupNamesMap: selectOrgUserGroupNamesMap,
	sequenceNameById: selectSequenceNameById
})

// @ts-ignore
export default connect(mapStateToProps)(AccessCodes)
