// DASHBOARD SHOWING AGGREGATE DEMOGRAPHIC DATA

import React from "react"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff

import DemographicsSummaryTable from "../charts/demographics-dash/DemographicsSummaryTable"
import ActiveUserCharts from "../charts/demographics-dash/ActiveUserCharts"
import LocationsMap from "../common/LocationsMap"
import ChartQuery from "../common/ChartQuery"

const DemographicsDash = () => {
	return (
		<div className="container-fluid">
			<ChartQuery route="count/demographics">
				<ActiveUserCharts />
			</ChartQuery>
			<ChartQuery route="count/sex">
				<DemographicsSummaryTable />
			</ChartQuery>
			<div className="row">
				<div className="col-sm-12">
					<ChartQuery route="users/map">
						<LocationsMap />
					</ChartQuery>
				</div>
			</div>
		</div>
	)
}

export default DemographicsDash
