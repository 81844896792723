// DASHBOARD SUMMARISING USER PROGRESS ACROSS COURSES

import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { selectOrgCourseCards } from "../../../redux/org/org.selectors"

import CourseSummaryTable from "../charts/course-summary-dash/CourseSummaryTable"
import UserGroupSummaryTable from "../charts/course-summary-dash/UserGroupSummaryTable"
import GeNoItemsFound from "../../common/GeNoItemsFound"
import ChartQuery from "../common/ChartQuery"

const CourseSummaryDash = ({ orgCourseCards }) => {
	if (orgCourseCards) {
		return (
			<div className="container-fluid">
				<ChartQuery route="courses/user-group">
					<UserGroupSummaryTable />
				</ChartQuery>
				<ChartQuery route="courses/course">
					<CourseSummaryTable orgCourseCards={orgCourseCards} />
				</ChartQuery>
			</div>
		)
	} else
		return (
			<GeNoItemsFound
				titleText="No Data"
				subtitleText="It looks like you haven't created any courses yet"
				icon={<FontAwesomeIcon icon={faExclamationCircle} />}
			/>
		)
}

const mapStateToProps = createStructuredSelector({
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(CourseSummaryDash)
