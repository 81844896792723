// ADVANCED OPTIONS PAGE FOR COURSES

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography, Button, notification } from "antd"

import LpBackButton from "../../components/common/LpBackButton"
import GeTitle from "../../components/common/GeTitle"
import * as S from "../../components/styles/PageStyles"

const { Title, Text } = Typography

const InnerContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
`
const Divider = styled.div`
	height: 28px;
	width: 100%;
`
const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	align-items: center;
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
const ButtonBox = styled.div`
	width: 180px;
`

const CoursesAdvancedOptions = ({ match: { params } }) => {
	const org_id = params.org_id

	const openNotification = () => {
		notification.open({
			message: "Coming soon!",
			description: "This feature is not yet live, please contact info@farm.ink for more info..."
		})
	}

	return (
		<S.PageContainer>
			<S.BackContainer>
				<LpBackButton backlink={`/org/${org_id}/create/courses`} />
			</S.BackContainer>

			<InnerContainer>
				<GeTitle>Advanced courses options</GeTitle>
				<Divider />

				<Row style={{ marginBottom: 40 }}>
					<Column>
						<Title level={4}>Upload course from saved file</Title>
						<Text>Upload the course you downloaded to file</Text>
					</Column>
					<ButtonBox>
						<Button type="primary" block shape="round" size="large" onClick={openNotification}>
							Upload
						</Button>
					</ButtonBox>
				</Row>
			</InnerContainer>
		</S.PageContainer>
	)
}

CoursesAdvancedOptions.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			org_id: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

export default CoursesAdvancedOptions
