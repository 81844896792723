import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import Confetti from "react-dom-confetti"
import { Alert, Button } from "antd"

import DemoAppOrgCover from "../../components/common/DemoAppOrgCover"
import LpImageInput from "../../components/common/LpImageInput"
import LpTwoColorInput from "../../components/common/LpTwoColorInput"
import LpTextInput from "../../components/common/LpTextInput"
import { selectOrgId, selectOrgInfo } from "../../redux/org/org.selectors"
import { updateOrgInfo } from "../../redux/org/org.actions"

const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background: white;
	padding: 4px;
	@media (max-width: 590px) {
		flex-direction: column;
	}
`
const PhoneImageContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 44px 22px;
	@media (max-width: 590px) {
		flex: 1 100%;
		margin: 44px 0px;
	}
`
const FormContainer = styled.div`
	flex: 2;
	box-sizing: border-box;
	margin: 0 auto;
	-webkit-box-pack: start;
	justify-content: flex-start;
	margin: 44px 20px 8px 20px;
	display: flex;
	flex-direction: column;
	@media (max-width: 590px) {
		margin: 44px 0px;
	}
`
const ErrorBox = styled.div`
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;
`
const Divider = styled.div`
	height: 32px;
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ removeBottomMargin }) => (removeBottomMargin ? "0px" : "28px")};
`
const OrgNameChangeText = styled.button`
	text-align: left;
	color: var(--secondary-color);
	font-size: 14px;
	margin: 10px 0px 20px 0px;
	line-height: 20px;
	:hover {
		color: var(--secondary-color-highlight);
	}
`
const SubmitButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	@media (max-width: 590px) {
		justify-content: center;
	}
`

// Rgb <-> Hex helper functions
// ------------------------
// Helper functions, see https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb

// Rgb -> Hex
// ----------
// convert e.g (255,255,255) --> #ffffff
const rgbToHex = (r, g, b) => {
	//return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
	return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
// convert e.g rgb(255,255,255) --> [255, 255, 255]
const rgbStringToComponentArray = (rgbString) => {
	const x = rgbString.split("(")[1].split(")")[0] // e.g, "255,255,255"
	const rgbArray = x.split(",") // e.g. ["255", "255", "255"]
	return rgbArray
}
// convert e.g 'rgb(255,255,255)' --> '#ffffff'
const rgbStringToHex = (rgbString) => {
	const rgbArr = rgbStringToComponentArray(rgbString)
	const result = rgbToHex(parseInt(rgbArr[0]), parseInt(rgbArr[1]), parseInt(rgbArr[2]))
	return result
}

// Hex -> Rgb
// ----------
// e.g. #ffffff --> {r: 255, g: 255, b: 255}
const hexToRgbObj = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
		  }
		: null
}
// e.g. #ffffff --> 'rgb(255, 255, 255)'
const hexToRgbString = (hex) => {
	const rgbObj = hexToRgbObj(hex)
	return `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`
}

const confettiConfig = {
	angle: "42",
	spread: "184",
	startVelocity: "50",
	elementCount: "53",
	dragFriction: "0.19",
	duration: "1250",
	stagger: "2",
	width: "10px",
	height: "10px",
	perspective: "500px",
	colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
}

// Component Function
// ==================

const CustomiseYourApp = ({ orgId, orgInfo, updateOrgInfo }) => {
	// we pull all the intitial values off redux, but must conver
	let primary_color = rgbStringToHex(orgInfo.theme.primary) // e.g #ffffff
	let secondary_color = rgbStringToHex(orgInfo.theme.secondary) // e.g. #ffffff

	const [logoImageUrl, setLogoImageUrl] = useState(orgInfo.logo_image_url)
	const [coverImageUrl, setCoverImageUrl] = useState(orgInfo.cover_image_url)
	const [colorScheme, setColorScheme] = useState({
		primary_color: primary_color,
		secondary_color: secondary_color
	})
	const [showOrgNameInput, setShowOrgNameInput] = useState(false)
	const [orgName, setOrgName] = useState(orgInfo.org_name)
	const [errorMsg, setErrorMsg] = useState(null)
	const [dummyLoading, setDummyLoading] = useState(false)
	const [confetti, setConfetti] = useState(false)

	const handleLogoChange = (imageUrl) => {
		setLogoImageUrl(imageUrl)
		setErrorMsg(null)
	}

	const handleCoverChange = (imageUrl) => {
		setCoverImageUrl(imageUrl)
		setErrorMsg(null)
	}

	const handleColorChanges = (target, hex) => {
		setColorScheme({
			...colorScheme,
			[target]: hex
		})
		setErrorMsg(null)
	}

	const toggleOrgNameInput = () => {
		setShowOrgNameInput(!showOrgNameInput)
	}

	const handleOrgNameChange = (text) => {
		setOrgName(text)
		setErrorMsg(null)
		if (text.trim() === "") {
			setErrorMsg("you must add an organisation name")
		} else {
			setOrgName(text.trim())
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		// handle errors, i.e. no empty org name
		if (orgName.trim() === "") {
			setErrorMsg("you must add an organisation name")
		} else {
			const new_org_info = {
				...orgInfo, // copy old state first, then update fields
				logo_image_url: logoImageUrl,
				cover_image_url: coverImageUrl,
				theme: {
					primary: hexToRgbString(colorScheme.primary_color), // convert hex back to rgb
					secondary: hexToRgbString(colorScheme.secondary_color) // convert hex back to rgb
				},
				org_name: orgName.trim()
			}

			await updateOrgInfo(orgId, new_org_info)

			// simulate loading here...
			setDummyLoading(true)
			setTimeout(() => {
				setConfetti(true)
				setDummyLoading(false)
			}, 2000)
		}
	}

	return (
		<MainContainer>
			<PhoneImageContainer>
				<DemoAppOrgCover
					logo_image_url={logoImageUrl}
					cover_image_url={coverImageUrl}
					org_name={orgName}
					primary_color={colorScheme.primary_color}
					secondary_color={colorScheme.secondary_color}
					screen_size="mini"
				/>
			</PhoneImageContainer>

			<FormContainer>
				<LpImageInput
					id="logo-upload"
					initialImageUrl={logoImageUrl}
					labelText="Upload logo"
					uploadImageMessage="A logo will help distinguish your app"
					thumbPreviewShape="square"
					setNewImage={handleLogoChange}
					clickableImage={true}
				/>

				<Divider borderOnBottom={true} />

				<LpImageInput
					id="cover-image-upload"
					initialImageUrl={coverImageUrl}
					labelText="Upload cover image"
					uploadImageMessage="Add a cover image to help stand out"
					thumbPreviewShape="largeRectangle"
					setNewImage={handleCoverChange}
					clickableImage={true}
				/>

				<Divider borderOnBottom={true} />

				<LpTwoColorInput
					initialColors={colorScheme}
					labelText="Pick your colors"
					uploadMessage="Press on the colors to change your primary and secondary colors"
					handleColorChanges={handleColorChanges}
				/>

				<Divider borderOnBottom={true} removeBottomMargin={!showOrgNameInput} />

				{!showOrgNameInput && (
					<React.Fragment>
						<OrgNameChangeText onClick={toggleOrgNameInput}>
							need to change your organisation name?
						</OrgNameChangeText>
					</React.Fragment>
				)}

				{showOrgNameInput && (
					<React.Fragment>
						<LpTextInput
							labelText="Your organisation name"
							placeholderText="Enter your organisation name..."
							handleTextChange={handleOrgNameChange}
							initialText={orgName}
						/>

						{errorMsg && (
							<ErrorBox>
								<Alert message={errorMsg} type="warning" />
							</ErrorBox>
						)}

						<Divider borderOnBottom={true} />
					</React.Fragment>
				)}

				<SubmitButtonContainer>
					<Confetti active={confetti} config={confettiConfig} />
					<Button
						size="large"
						shape="round"
						type="primary"
						onClick={handleSubmit}
						loading={dummyLoading}
						disabled={confetti}
					>
						{confetti ? "Nice work!" : "Save customisation"}
					</Button>
				</SubmitButtonContainer>
			</FormContainer>
		</MainContainer>
	)
}

CustomiseYourApp.propTypes = {
	orgId: PropTypes.string.isRequired,
	orgInfo: PropTypes.object.isRequired,
	updateOrgInfo: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgInfo: selectOrgInfo
})

const mapDispatchToProps = (dispatch) => ({
	updateOrgInfo: (org_id, new_org_info) => dispatch(updateOrgInfo(org_id, new_org_info))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseYourApp)
