// NAV BAR FOR LESSON AND QUESTION EDITOR VIEW

import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button } from "antd"

import { selectPageParams, selectStageItemsReady } from "../../../../redux/courses/courses.selectors"

import CbBackButton from "../../../common/CbBackButton"
import LpModal from "../../../common/LpModal"
import useLpModal from "../../../hooks/useLpModal"
import PreviewSkeleton from "../../../../pages/coursePreview/PreviewSkeleton"

const NavContainer = styled.div`
	position: fixed;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	background: white;
	box-shadow: 0px 1px 2px #42424291;
	z-index: 150;
	height: 44px;
	width: 100%;
	top: 0px;
	@media screen and (min-width: 850px) {
		width: calc(100% - 230px);
	}
`
const LeftPart = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-start;
	font-size: 16px;
	margin-left: 10px;
	@media screen and (min-width: 850px) {
		width: calc(100% - 230px);
	}
`
const TitleText = styled.h2`
	display: none;
	font-size: 18px;
	font-weight: 600;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-left: 10px;
	@media (min-width: 425px) {
		display: flex;
	}
`
const RightPart = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
	margin: 6px;
`
const ModalBox = styled.div`
	border-radius: 10px;
	background: var(--off-white);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 18px;
`
const PreviewContainer = styled.div`
	height: 725px;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;
`

const CbNav = ({ title, page_params, backLink, stageItemsReady }) => {
	const { isShowingModal, toggleModal } = useLpModal()

	// handle button disable for case of stage view only
	let disableButton = false
	if (stageItemsReady) {
		if (!!page_params.lesson_view && !stageItemsReady.lessonPreviewReady) {
			disableButton = true
		}
		if (!page_params.lesson_view && !stageItemsReady.questionsPreviewReady) {
			disableButton = true
		}
	}

	return (
		<>
			<NavContainer>
				<LeftPart>
					<CbBackButton backLink={backLink} />
					<TitleText>{title}</TitleText>
				</LeftPart>
				<RightPart>
					<Button
						type="primary"
						shape="round"
						onClick={toggleModal}
						disabled={disableButton}
						id="stage_preview_button"
					>
						Preview
					</Button>
				</RightPart>
			</NavContainer>

			<LpModal isShowingModal={isShowingModal} hideModal={toggleModal}>
				<ModalBox>
					<PreviewContainer>
						<PreviewSkeleton
							type={page_params.lesson_view === true ? "lesson" : "quiz"}
							org_id={page_params.org_id}
							stage_id={page_params.stage_id}
						/>
					</PreviewContainer>
				</ModalBox>
			</LpModal>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	page_params: selectPageParams,
	stageItemsReady: selectStageItemsReady
})

CbNav.propTypes = {
	title: PropTypes.string.isRequired,
	page_params: PropTypes.object.isRequired,
	backLink: PropTypes.string,
	history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withRouter(CbNav))
