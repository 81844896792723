import React from "react"
import styled from "styled-components"
import { Tag, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import * as S from "../styles/PageStyles"
import { LockOutlined } from "@ant-design/icons"

const { Title } = Typography

const EditableBox = styled.div<{ isEditable?: boolean }>`
	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	padding: 8px;
	flex: 1;
	border: solid 2px var(--v-light-grey);
	border-radius: 6px;
	overflow: hidden;
	margin-bottom: 15px;
	position: relative;
	transition: 0.5s ease;
	cursor: ${({ isEditable }) => (isEditable ? "pointer" : "auto")};
	:hover {
		border: ${({ isEditable }) =>
			isEditable ? "solid 2px var(--secondary-color)" : "solid 2px var(--v-light-grey)"};
	}
`
const IconContainer = styled.div`
	width: 32px;
	height: 32px;
	font-size: 20px;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	color: black;
`
const Bubble = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	border-radius: 999px;
`

interface Props {
	faIcon?: any
	icon?: string
	title?: string | number
	subtitle?: string
	tag?: string
	tagTitle?: string
	tagColor?: string
	isEditable?: boolean
	iconBubbleColor?: string
	onClick?: () => void
	component?: React.JSX.Element
}

const EditableSetting = ({
	faIcon,
	icon,
	iconBubbleColor,
	title,
	subtitle,
	tag,
	tagTitle,
	tagColor,
	isEditable,
	onClick,
	component
}: Props) => {
	const handleOnClick = () => {
		if (onClick && isEditable) {
			onClick()
		}
	}

	return (
		<EditableBox onClick={handleOnClick} isEditable={isEditable}>
			{iconBubbleColor && faIcon && (
				<IconContainer>
					{S.antdTagColors[iconBubbleColor] ? (
						<Bubble
							style={{
								backgroundColor: S.antdTagColors[iconBubbleColor].background,
								borderColor: S.antdTagColors[iconBubbleColor].borderColor,
								borderWidth: 1,
								borderStyle: "solid"
							}}
						>
							<FontAwesomeIcon
								icon={faIcon as IconProp}
								style={{ color: S.antdTagColors[iconBubbleColor].color, fontSize: 14 }}
							/>
						</Bubble>
					) : (
						<Bubble style={{ backgroundColor: iconBubbleColor }}>
							<FontAwesomeIcon icon={faIcon as IconProp} style={{ color: "white", fontSize: 14 }} />
						</Bubble>
					)}
				</IconContainer>
			)}
			{!iconBubbleColor && faIcon && (
				<IconContainer>
					<FontAwesomeIcon icon={faIcon as IconProp} style={{ color: "#565656", fontSize: 20 }} />
				</IconContainer>
			)}
			{icon && (
				<IconContainer>
					<img src={icon} width={20} />
				</IconContainer>
			)}
			<div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
				{title && (
					<Title
						level={5}
						style={{
							margin: 0,
							fontSize: 16,
							color: "#565656",
							lineHeight: 1.2
						}}
					>
						{title}
					</Title>
				)}
				{subtitle && (
					<div
						style={{
							color: "grey",
							fontSize: 13
						}}
					>
						{subtitle}
					</div>
				)}
				{tag && tagTitle && (
					<S.FlexBoxColumnCentered>
						<div
							style={{
								fontSize: 14,
								marginBottom: 4
							}}
						>
							{tagTitle}
						</div>
						<Tag color={tagColor}>{tag}</Tag>
					</S.FlexBoxColumnCentered>
				)}
				{component && component}
			</div>
			{!isEditable && <LockOutlined style={{ color: "var(--light-grey)" }} />}
		</EditableBox>
	)
}

export default EditableSetting
