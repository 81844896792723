// DASHBOARD SHOWING USER SURVEY DATA

import React, { useState, useEffect } from "react"
import { Select, Space, TreeSelect } from "antd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import "../bootstrap-grid.css"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import { selectOrgId, selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { fetchSurveyContent } from "./helperFunctions"

import CbLoading from "../../common/CbLoading"
import CourseSubNav from "../nav/CourseSubNav"
import ChartQuery from "../common/ChartQuery"
import ChartWrapper from "../common/ChartWrapper"
import SurveyQuestionSelector from "../charts/surveys-dash/SurveyQuestionSelector"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import { UUID } from "learnink-common/lib/types/types"
import { CourseCards } from "../../../util/model/OrgSelectors"

interface TreeData {
	value: string
	title: string
	selectable?: boolean
	children?: TreeData[]
}

interface SurveyContentItem {
	card_id: UUID
	content: any
	course_id: UUID
	course_name: string
	type: "survey"
}

interface Props {
	orgId: string
	type: "course" | "learningPath"
	orgCourseCards: CourseCards
	learningPath: LearningPath
}

const SurveysDash = ({ orgId, type, orgCourseCards, learningPath }: Props) => {
	const [pageState, setPageState] = useState<"loading" | "default" | "error">("loading")
	const [surveyContent, setSurveyContent] = useState<SurveyContentItem[]>([])
	const [courseId] = useUrlQueryString("courseId")
	const [questionIds, setQuestionIds] = useUrlQueryString("questionIds")
	// @ts-ignore
	const questionIdsArr = questionIds ? questionIds.split(",") : []
	// @ts-ignore
	const courseIdsArr: UUID[] = learningPath
		? new LearningPathContent(learningPath).getCourseIds()
		: courseId
		? [courseId]
		: []

	const handleQuestionSelect = (newValue: string[]) => {
		const questionIds = newValue.length > 0 ? newValue.join(",") : undefined
		// @ts-ignore
		setQuestionIds(questionIds)
	}

	const treeifyCourses = () => {
		const treeData: TreeData[] = []
		courseIdsArr.forEach((courseId) => {
			treeData.push({
				value: courseId,
				title: orgCourseCards[courseId].info.name,
				selectable: false,
				children: surveyContent
					.filter((item) => item.course_id === courseId)
					.map((item) => {
						return {
							value: item.card_id,
							title: item.content.message
						}
					})
			})
		})
		return treeData
	}

	useEffect(() => {
		const getSurveyContent = async (courseArr: string[], orgId: string) => {
			const data = await fetchSurveyContent(courseArr, orgId, orgCourseCards)
			if (data) {
				console.log(data)
				setSurveyContent(data)
				setPageState("default")
			} else {
				setPageState("error")
			}
		}
		if (type === "course" && courseIdsArr.length > 0) {
			getSurveyContent(courseIdsArr, orgId)
		}
		if (type === "learningPath" && courseIdsArr.length > 0) {
			getSurveyContent(courseIdsArr, orgId)
		}
	}, [courseId, learningPath, orgId, orgCourseCards])

	if (courseIdsArr.length === 0) return <></>

	if (pageState === "loading") {
		return <CbLoading size={40} />
	}

	if (learningPath) {
		return (
			<>
				<div className="row">
					<div className="col-sm-12" style={{ paddingTop: 15 }}>
						<ChartWrapper
							tooltip="Select the survey questions you want to inspect"
							title="Select survey questions"
						>
							<Space
								direction="vertical"
								style={{
									width: "100%"
								}}
							>
								<TreeSelect
									treeLine
									multiple
									showSearch
									style={{ width: "100%" }}
									value={questionIdsArr}
									dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
									placeholder="Select survey questions..."
									allowClear
									onChange={handleQuestionSelect}
									treeData={treeifyCourses()}
									maxTagCount="responsive"
									maxTagTextLength={20}
								/>
							</Space>
						</ChartWrapper>
					</div>
				</div>
				{questionIdsArr.length > 0 && (
					<ChartQuery
						route="survey-question/answers"
						params={`&courseId=${courseId}&questionIds=${questionIds}`}
					>
						{/* @ts-ignore */}
						<SurveyQuestionSelector surveyContent={surveyContent} questionIdsArr={questionIdsArr} />
					</ChartQuery>
				)}
			</>
		)
	}

	return (
		<div className="container-fluid">
			<CourseSubNav courseId={courseId} />
			<div className="row">
				<div className="col-sm-12" style={{ paddingTop: 15 }}>
					<ChartWrapper
						tooltip="Select the survey questions you want to inspect"
						title="Select survey questions"
					>
						<Space
							direction="vertical"
							style={{
								width: "100%"
							}}
						>
							<Select
								mode="multiple"
								style={{ width: "100%" }}
								value={questionIdsArr}
								options={surveyContent.map((item) => {
									return {
										value: item.card_id,
										label: item.content.message
									}
								})}
								onChange={handleQuestionSelect}
								placeholder="Select survey questions..."
								maxTagCount="responsive"
								maxTagTextLength={20}
							/>
						</Space>
					</ChartWrapper>
				</div>
			</div>
			{questionIdsArr.length > 0 && (
				<ChartQuery route="survey-question/answers" params={`&courseId=${courseId}&questionIds=${questionIds}`}>
					{/* @ts-ignore */}
					<SurveyQuestionSelector surveyContent={surveyContent} questionIdsArr={questionIdsArr} />
				</ChartQuery>
			)}
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(SurveysDash)
