import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ChromePicker, SketchPicker } from "react-color"

const Swatch = styled.div`
	padding: 5px;
	background: "#fff";
	border-radius: 1px;
	boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
`
const Color = styled.div`
	border-radius: ${({ shape }) => (shape === "square" ? "10px" : "100%")};
	border: 1px solid var(--light-grey);
	width: 60px;
	height: 60px;
	background: ${({ hex }) => (hex ? hex : "#fff")};
`
const PopOver = styled.div`
	position: absolute;
	right: 5px;
	top: 40px;
	z-index: 2;
`
// background CSS below gives optional background change of colour...
const Cover = styled.div`
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba(145, 145, 145, 0);
`

const LpColorPicker = ({ initialHex, callbackColorChange, shape, pickerType, disabled, style }) => {
	const [pickerOpen, setPickerOpen] = useState(false)
	const [hex, setHex] = useState(initialHex)

	const handleClick = () => {
		if (!disabled) setPickerOpen(!pickerOpen)
	}
	const handleClose = () => {
		setPickerOpen(false)
		callbackColorChange(hex)
	}
	const handleChangeComplete = (color) => {
		setHex(color.hex)
	}

	return (
		<>
			<Swatch onClick={handleClick}>
				<Color hex={hex} shape={shape} style={style} />
			</Swatch>
			<div style={{ position: "relative" }}>
				{pickerOpen && (
					<PopOver>
						<Cover onClick={handleClose} />
						{pickerType === "chrome" && (
							<ChromePicker color={hex} onChangeComplete={handleChangeComplete} disableAlpha />
						)}
						{pickerType === "sketch" && (
							<SketchPicker color={hex} onChangeComplete={handleChangeComplete} disableAlpha />
						)}
					</PopOver>
				)}
			</div>
		</>
	)
}

LpColorPicker.propTypes = {
	initialHex: PropTypes.string,
	callbackColorChange: PropTypes.func.isRequired,
	shape: PropTypes.string,
	pickerType: PropTypes.string,
	disabled: PropTypes.bool
}

LpColorPicker.defaultProps = {
	shape: "square",
	pickerType: "chrome",
	initialHex: "#00F813",
	disabled: false
}

export default LpColorPicker

/*
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChromePicker, SketchPicker } from "react-color";

const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
const Swatch = styled.div`
  padding: 5px;
  background: '#fff';
  border-radius: 1px;
  boxShadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
`;
const Color = styled.div`
  border-radius: ${({ shape }) => (shape === "square" ? "10px" : "100%")};
  border: 1px solid var(--light-grey);
  width: 60px;
  height: 60px;
  position: relative;
  background: ${({ hex }) => (hex ? hex : "#fff")};
`;
const PopOver = styled.div`
  position: absolute;
  right: 5px;
  top: 40px;
  z-index: 2;
`;
// background CSS below gives optional background change of colour...
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(145, 145, 145, 0);
`;

const LpColorPicker = ({
  initialHex,
  callbackColorChange,
  shape,
  pickerType
}) => {

  const [pickerOpen, setPickerOpen] = useState(false);
  const [hex, setHex] = useState(initialHex);

  const handleClick = () => setPickerOpen(!pickerOpen);
  const handleClose = () => {
    setPickerOpen(false);
    callbackColorChange(hex);
  };

  const handleChangeComplete = (color) => {
    setHex(color.hex);
  };

  return (
    <Box>
      <Swatch onClick={handleClick}>
        <Color hex={hex} shape={shape}>
          {pickerOpen && (
            <PopOver>
              <Cover onClick={handleClose} />
              {pickerType === "chrome" && (
                <ChromePicker
                  color={hex}
                  onChangeComplete={handleChangeComplete}
                  disableAlpha
                />
              )}
              {pickerType === "sketch" && (
                <SketchPicker
                  color={hex}
                  onChangeComplete={handleChangeComplete}
                  disableAlpha
                />
              )}
            </PopOver>
          )}
        </Color>
      </Swatch>
    </Box>
  );
};

LpColorPicker.propTypes = {
  initialHex: PropTypes.string,
  callbackColorChange: PropTypes.func.isRequired,
  shape: PropTypes.string,
  pickerType: PropTypes.string,
};

LpColorPicker.defaultProps = {
  shape: "square",
  pickerType: "chrome",
  initialHex: "#00F813",
};

export default LpColorPicker;

*/
