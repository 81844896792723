import React from "react"
import { Cell, Tooltip, ResponsiveContainer, Legend, PieChart, Pie } from "recharts"
import { Empty } from "antd"
import ChartWrapper from "./ChartWrapper"
import * as S from "../../styles/PageStyles"

interface DataItem {
	x: string
	count: number
	color: string
}

interface Props {
	data: DataItem[]
	title: string
	tooltip: string
	showLegend?: boolean
	height: number
	noDataElement?: React.Component
}

const BasePieChart = ({ data, title, tooltip, showLegend, height, noDataElement }: Props) => {
	return (
		<ChartWrapper tooltip={tooltip} title={title}>
			<ResponsiveContainer width="100%" height={height}>
				{data.length > 0 ? (
					<PieChart>
						<Pie
							isAnimationActive={false}
							data={data}
							nameKey="x"
							dataKey="count"
							fill="#8884d8"
							innerRadius={44}
							outerRadius={80}
							paddingAngle={1}
						>
							{data.map((e, index) => (
								<Cell key={index} fill={e.color} />
							))}
						</Pie>
						{showLegend && <Legend />}
						<Tooltip />
					</PieChart>
				) : (
					<>
						{noDataElement ? (
							{ noDataElement }
						) : (
							<S.FlexBoxColumnCentered>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
							</S.FlexBoxColumnCentered>
						)}
					</>
				)}
			</ResponsiveContainer>
		</ChartWrapper>
	)
}

export default BasePieChart
