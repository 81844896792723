import axios from "axios"
import { TemplatesActionTypes } from "./templates.types"
import { auth } from "../../firebase/firebase.util"

const updateFirebaseToken = async () => {
	const user = auth.currentUser
	// this will get the user's firebase token and fetch a new one if it has expired
	const token = await user.getIdToken()
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

// get all resources for org
export const getCourseTemplateList = (orgId) => async (dispatch) => {
	try {
		updateFirebaseToken()
		const res = await axios.get(`/api/courses/${orgId}/templates`)
		if (res.data) {
			dispatch({
				type: TemplatesActionTypes.SET_COURSE_TEMPLATE_LIST,
				payload: res.data
			})
		} else {
			console.log("didn't get res.data...")
		}
	} catch (err) {
		console.log(err)
	}
}
