import React from "react"
import { Space } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import RecordAudioButton from "./RecordAudioButton"
import * as S from "../../styles/PageStyles"
import { VoiceClipType, VoiceProfile } from "./VoicePanel"
import { VoiceClip } from "learnink-common/lib/domains/voiceClips/VoiceClip.model"
import PlayAudioButton from "./PlayAudioButton"
import { getCurrentVoiceClipType } from "./useUpdateVoiceClipType"
import { Card } from "learnink-common/lib/domains/cards/Card.model"

interface Props {
	textId: string | undefined
	description?: string
	voiceClip: VoiceClip | undefined
	voiceClipType: VoiceClipType | undefined
	card: Card
	languageCode: LanguageCode
	voiceProfileDefaults: VoiceProfile[] | undefined
}

const AudioFileInput = ({
	textId,
	description,
	voiceClip,
	voiceClipType,
	card,
	languageCode,
	voiceProfileDefaults
}: Props) => {
	const savedVoiceClipType = getCurrentVoiceClipType(voiceProfileDefaults, voiceClip)
	return (
		<>
			{description && (
				<Paragraph style={{ marginBottom: 6, marginTop: 4 }} ellipsis>
					{description}
				</Paragraph>
			)}
			<S.FlexBoxStartRow style={{ paddingBottom: 4 }}>
				<Space>
					<>
						{voiceClipType === "custom" && (
							<RecordAudioButton
								textId={textId}
								card={card}
								languageCode={languageCode}
								description={description}
								voiceClip={voiceClip}
							/>
						)}
						{voiceClip && voiceClipType === savedVoiceClipType && (
							<PlayAudioButton fileUrl={voiceClip.fileUrl} />
						)}
					</>
				</Space>
			</S.FlexBoxStartRow>
		</>
	)
}

export default AudioFileInput
