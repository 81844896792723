import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { CardModel } from "../../../../../util/model/Cards"
import { Card } from "learnink-common/lib/domains/cards/Card.model"
import { LanguageCode } from "learnink-common/lib/constants/language/LanguageCode"
import { SoundFilled } from "@ant-design/icons"
import { languageColors } from "../../../../styles/PageStyles"

export const AudioTag = styled.div`
	padding: 2px 5px;
	border-radius: 2px;
	font-size: 10px;
	color: white;
	height: 20px;
	margin-right: 4px;
`

interface Props {
	card: Card
	languageCode: LanguageCode
}

const ReadAloudButton = ({ card, languageCode }: Props) => {
	const [currentAudioFile, setCurrentAudioFile] = useState<string | undefined>(undefined)
	const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined)
	const [audioPlaying, setAudioPlaying] = useState<boolean>(false)
	const voiceClipStatus = new CardModel(card).getVoiceClipButtonStatus(languageCode)

	useEffect(() => {
		if (currentAudioFile) {
			const newAudio = new Audio(currentAudioFile)
			setAudio(newAudio)
		}
	}, [currentAudioFile])

	useEffect(() => {
		if (voiceClipStatus.voiceClip !== currentAudioFile) {
			setCurrentAudioFile(voiceClipStatus.voiceClip)
		}
	}, [voiceClipStatus])

	const toggleAudio = () => {
		if (currentAudioFile && audio) {
			if (audioPlaying) {
				audio.pause()
				audio.currentTime = 0
			} else {
				audio.play().catch((error) => console.error("Error playing audio:", error))
			}
			setAudioPlaying(!audioPlaying)
		}
	}

	if (voiceClipStatus.status !== "complete") {
		return <></>
	}

	return (
		<AudioTag style={{ background: languageColors(languageCode) }} onClick={toggleAudio}>
			<SoundFilled />
		</AudioTag>
	)
}

export default ReadAloudButton
