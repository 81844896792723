import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FixedSizeList } from "react-window"
import { IndeterminateCheckbox } from "../../tables/tableFunctions"
import { Button, Tooltip, Drawer, Tag, Space, Modal } from "antd"
import { BellOutlined, FileDoneOutlined } from "@ant-design/icons"
import styled from "styled-components"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import { statusColors } from "../../analyse/common/colors"
import { selectOrgUserGroupColorsMap, selectLearningPathById } from "../../../redux/org/org.selectors"
import scrollbarWidth from "../../../util/scrollbarWidth"
import * as S from "../../styles/PageStyles"

import LpModal from "../../common/LpModal"
import SendUserNotificationModal from "../userModals/SendUserNotificationModal"
import UserSidePanel from "./UserSidePanel"
import ActionsButton from "../userTable/ActionsButton"
import ColumnFilter from "../userTable/ColumnFilter"
import { useBlockLayout, useFilters, useResizeColumns, useRowSelect, useSortBy, useTable } from "react-table"
import _ from "lodash"

const TopRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 50px;
	align-content: center;
	align-items: center;
`

const TableResizer = styled.div`
	right: 0;
	width: 10px;
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 1;
	${"" /* prevents from scrolling while dragging on touch devices */}
	touch-action: none;
	border-right: solid 2px var(--v-light-grey);
	transition: 0.2s ease;
	:hover {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color-highlight)" : "var(--secondary-color)")};
	}
	:focus {
		border-right: solid 6px
			${({ isResizing }) => (isResizing ? "var(--secondary-color-highlight)" : "var(--secondary-color)")};
	}
`
const TableHeadData = styled.div`
	margin: 6px 12px;
`
const SortIconContainer = styled.span`
	margin-left: 6px;
`
const CertificateContainer = styled.div`
	width: 263px;
	height: 374px;
	background: ${({ imageUrlOnComponent }) =>
		imageUrlOnComponent !== "" ? `url(${imageUrlOnComponent}) no-repeat` : "var(--v-light-grey)"};
	background-size: cover;
	border: solid 1px grey;
`

const MultiTagDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 50px;
`

// Define a default UI for filtering
function DefaultColumnFilter() {
	return null
}

// Component
// =========
const UserTable = ({
	columns,
	data,
	notifyButtonOn,
	userGroupColors,
	learningPathById,
	removeUsersFromList,
	actions,
	hiddenColumns,
	refreshData,
	sidePanel,
	orgId
}) => {
	const [modalType, setModalType] = useState(undefined)
	const [showDrawer, setShowDrawer] = useState(false)
	const [selectedUser, setSelectedUser] = useState(null)
	const [showColFilters, setShowColFilters] = useState(false)
	const [fixedView] = useUrlQueryString("fixedView")

	const defaultColumn = React.useMemo(
		() => ({
			Filter: DefaultColumnFilter
		}),
		[]
	)

	const openMessageModal = (userData) => {
		if (userData.appDownloaded === true) {
			setSelectedUser(userData)
		} else {
			setSelectedUser(null)
		}
		setModalType("notification")
	}

	const openCertificateModal = (e, userData) => {
		e.stopPropagation()
		setSelectedUser(userData)
		setModalType("certificate")
	}

	const openDrawer = (userData) => {
		if (sidePanel) {
			setSelectedUser(userData)
			setShowDrawer(true)
		}
	}

	const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		totalColumnsWidth,
		prepareRow,
		allColumns,
		setHiddenColumns,
		setAllFilters,
		state: { selectedRowIds, filters }
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: {
				hiddenColumns
			}
		},
		useFilters,
		useSortBy,
		useBlockLayout,
		useResizeColumns,
		useRowSelect,
		(hooks) => {
			/*
        we add two extra columns here...
      */
			const notifyButton =
				notifyButtonOn && !fixedView
					? [
							{
								id: "action",
								disableResizing: true,
								minWidth: 100,
								width: 100,
								maxWidth: 100,
								// nothing in header...
								Header: "",
								Cell: ({ row }) => (
									<>
										{row.original.appDownloaded ? (
											<Button
												onClick={() => openMessageModal(row.original)}
												shape="round"
												icon={<BellOutlined />}
												size="small"
												type="primary"
											>
												Notify
											</Button>
										) : (
											<Tooltip title="You can only send notifications to users who have downloaded the Learn.ink app and enabled notifications on their phone">
												<Button
													onClick={() => openMessageModal(row.original)}
													shape="round"
													icon={<BellOutlined />}
													size="small"
													type="primary"
													disabled={true}
												>
													Notify
												</Button>
											</Tooltip>
										)}
									</>
								)
							}
					  ]
					: []
			const checkBoxColumn = !fixedView
				? [
						{
							id: "selection",
							disableResizing: true,
							minWidth: 35,
							width: 35,
							maxWidth: 35,
							align: "center",
							// The header can use the table's getToggleAllRowsSelectedProps method
							// to render a checkbox
							Header: ({ getToggleAllRowsSelectedProps }) => (
								<div
									style={{
										paddingLeft: 10
									}}
								>
									<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
								</div>
							),
							// The cell can use the individual row's getToggleRowSelectedProps method
							// to the render a checkbox
							Cell: ({ row }) => (
								<div
									style={{
										paddingLeft: 10
									}}
								>
									<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
								</div>
							)
						}
				  ]
				: []

			hooks.allColumns.push((columns) => [...checkBoxColumn, ...notifyButton, ...columns])
		}
	)

	const RenderRow = React.useCallback(
		({ index, style }) => {
			const row = rows[index]
			prepareRow(row)

			const customCells = [
				"userGroupNames",
				"registrationStatus",
				"certificateId",
				"learningPathName",
				"status",
				"shareRequestSenders"
			]

			return (
				<div
					{...row.getRowProps({
						style
					})}
					className="tr"
				>
					{row.cells.map((cell) => {
						return (
							<div
								{...cell.getCellProps()}
								className="td"
								onClick={() =>
									cell.column.id === "selection" || cell.column.id === "action" || fixedView
										? null
										: openDrawer(row.original)
								}
							>
								{cell.column.id === "registrationStatus" && (
									<Tag color={statusColors[cell.value]}>{cell.value}</Tag>
								)}
								{cell.column.id === "learningPathName" && cell.value && (
									<Tag
										color={
											learningPathById[row.original.learningPathId]
												? learningPathById[row.original.learningPathId].color
												: "green"
										}
									>
										{cell.value}
									</Tag>
								)}

								{/* User groups cell... */}
								{cell.column.id === "userGroupNames" && (
									<MultiTagDiv>
										{cell.value.map((userGroupName, index) => (
											<Tag
												key={index}
												color={
													userGroupColors[userGroupName]
														? userGroupColors[userGroupName]
														: "var(--mid-orange)"
												}
											>
												{userGroupName}
											</Tag>
										))}
									</MultiTagDiv>
								)}

								{cell.column.id === "status" && (
									<>
										<Tag color={statusColors[cell.value]}>{cell.render("Cell")}</Tag>
										{row.original.certificateId && (
											<Button
												onClick={(e) => openCertificateModal(e, row.original)}
												shape="circle"
												size="small"
												icon={<FileDoneOutlined />}
											/>
										)}
									</>
								)}

								{cell.column.id === "shareRequestSenders" && cell.value && (
									<MultiTagDiv>
										{_.uniqBy(cell.value, (sender) => sender.id).map((sender, idx) => (
											<Tag key={idx}>{sender.name}</Tag>
										))}
									</MultiTagDiv>
								)}

								{/* Everything else... */}
								{!customCells.includes(cell.column.id) && <>{cell.render("Cell")}</>}
							</div>
						)
					})}
				</div>
			)
		},
		[prepareRow, rows, selectedRowIds, userGroupColors]
	)

	// Render the UI for your table
	return (
		<>
			<TopRow>
				{Object.keys(selectedRowIds).length === 0 ? (
					<div>Showing {rows.length} users</div>
				) : (
					<div style={{ color: "var(--secondary-color)", fontWeight: "bold" }}>
						{Object.keys(selectedRowIds).length} users selected
					</div>
				)}
				<Space>
					{filters.length > 0 && <Button onClick={() => setAllFilters([])}>Reset filters</Button>}
					{!fixedView && <Button onClick={() => setShowColFilters(!showColFilters)}>Change columns</Button>}
					{actions && !fixedView && (
						<ActionsButton
							selectedRowIds={selectedRowIds}
							data={data}
							removeUsersFromList={removeUsersFromList}
							actions={actions}
							refreshData={refreshData}
						/>
					)}
				</Space>
			</TopRow>
			{showColFilters && <ColumnFilter allColumns={allColumns} setHiddenColumns={setHiddenColumns} />}
			<div {...getTableProps()} className="table">
				<div>
					{headerGroups.map((headerGroup) => (
						<div {...headerGroup.getHeaderGroupProps()} className="tr">
							{headerGroup.headers.map((column) => (
								<div {...column.getHeaderProps()} className="th">
									<TableHeadData {...column.getSortByToggleProps()}>
										{column.render("Header")}

										{/* Add a sort direction indicator */}
										<SortIconContainer>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<FontAwesomeIcon icon={faCaretUp} />
												) : (
													<FontAwesomeIcon icon={faCaretDown} />
												)
											) : (
												""
											)}
										</SortIconContainer>
									</TableHeadData>
									<div>
										{column.canFilter ? column.render("Filter") : null}
										{/* Use column.getResizerProps to hook up the events correctly */}
										{column.canResize && (
											<TableResizer
												{...column.getResizerProps()}
												isResizing={column.isResizing}
											/>
										)}
									</div>
								</div>
							))}
						</div>
					))}
				</div>

				<div {...getTableBodyProps()}>
					<FixedSizeList
						height={1000}
						itemCount={rows.length}
						itemSize={50}
						width={totalColumnsWidth + scrollBarSize}
						className="autoScaleHeight"
					>
						{RenderRow}
					</FixedSizeList>
				</div>
			</div>
			{modalType === "notification" && (
				<LpModal isShowingModal={true} hideModal={() => setModalType(undefined)} maxWidth="600px">
					<SendUserNotificationModal
						usersData={[selectedUser]}
						cancelModal={() => setModalType(undefined)}
						getUsers={() => {}}
					/>
				</LpModal>
			)}
			{modalType === "certificate" && (
				<Modal
					centered
					onCancel={() => setModalType(undefined)}
					open={true}
					footer={[
						<Button
							key="open"
							target="_blank"
							href={`https://certificates.learn.ink/${selectedUser.certificateId}?org=${orgId}`}
						>
							Open link
						</Button>,
						<Button
							key="download"
							href={`https://certificates.learn.ink/${selectedUser.certificateId}.pdf`}
						>
							Download PDF
						</Button>,
						<Button
							key="link"
							target="_blank"
							href={`https://wa.me/?text=https://certificates.learn.ink/${selectedUser.certificateId}?org=${orgId}`}
						>
							Share on WhatsApp
						</Button>
					]}
				>
					<S.FlexBoxColumnCentered>
						<CertificateContainer
							imageUrlOnComponent={`"https://certificates.learn.ink/${selectedUser.certificateId}.png"`}
						/>
					</S.FlexBoxColumnCentered>
				</Modal>
			)}
			<Drawer
				title="User settings"
				placement="right"
				onClose={() => {
					setShowDrawer(false)
				}}
				open={showDrawer}
			>
				<UserSidePanel
					closemodal={() => {
						setShowDrawer(false)
					}}
					userData={selectedUser}
				/>
			</Drawer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	userGroupColors: selectOrgUserGroupColorsMap,
	learningPathById: selectLearningPathById
})

export default connect(mapStateToProps)(UserTable)
