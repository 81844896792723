// SIDE PANEL TO EDIT USER GROUP

import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Alert, Form, Input, Button, Space, Collapse, Typography, Tag, Select } from "antd"
import * as S from "../../styles/PageStyles"

import { selectOrgId, selectOrgUserGroups, selectPremiumFeatureAccess } from "../../../redux/org/org.selectors"
import { deleteUserGroup, updateUserGroup, regenerateUserGroupCode } from "../../../redux/management/management.actions"

import CbLoading from "../../common/CbLoading"
import LpTextInput from "../../common/LpTextInput"
import CbLinkCopyButton from "../../common/CbLinkCopyButton"
import { CreateOrUpdateParams } from "learnink-common/lib/domains/userGroups/UserGroupParams.model"
import { INVALID_USER_GROUP_NAMES, UserGroupType } from "learnink-common/lib/domains/userGroups/UserGroup.model"
import { UserGroupInfo } from "./UserGroupsTable"
import { OrgUserGroups } from "../../../util/model/OrgSelectors"
import { EMOJI_REGEX } from "learnink-common/lib/constants/constants"
import { PremiumFeatureAccess } from "../../../redux/org/org.model"
import { selectIsUserAdmin } from "../../../redux/user/user.selectors"

const { TextArea } = Input
const { Panel } = Collapse
const { Text } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ErrorBox = styled.div`
	display: flex;
	padding: 0px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
`
const DeleteGroupText = styled.button`
	color: red;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
`
const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
`
const LinkContainer = styled.div`
	display: block;
	padding: 20px 0px 0px 0px;
`
const SmallButtonsContainer = styled.div`
	display: flex;
	padding: 10px 0px;
	flex-direction: row;
	justify-content: center;
	opacity: 1;
`

interface Props {
	orgId: string
	userGroupData: UserGroupInfo
	orgUserGroups: OrgUserGroups
	deleteUserGroup: (orgId: string, userGroupId: string) => void
	updateUserGroup: (orgId: string, userGroupId: string, updateParams: CreateOrUpdateParams) => void
	regenerateUserGroupCode: (orgId: string, userGroupId: string) => string
	closemodal: () => void
	premiumFeatureAccess: PremiumFeatureAccess
	isAdmin: boolean
}

const UserGroupSidePanel = ({
	orgId,
	userGroupData,
	orgUserGroups,
	deleteUserGroup,
	updateUserGroup,
	regenerateUserGroupCode,
	closemodal,
	premiumFeatureAccess,
	isAdmin
}: Props) => {
	// set initial state based on parent...
	const [panelView, setPanelView] = useState<"main" | "delete" | "code">("main")
	const userGroupId = userGroupData.id
	const [loading, setLoading] = useState<boolean>(false)
	const [deleteConfirmText, setDeleteConfirmText] = useState<string | undefined>(undefined)
	const [delErrorMsg, setDelErrorMsg] = useState<string | undefined>(undefined)
	const [reload, setReload] = useState<boolean>(false)
	const [showSaveChanges, setShowSaveChanges] = useState<boolean>(false)
	const [accessCode, setAccessCode] = useState<string>(userGroupData.accessCode)

	const groupIds = Object.keys(orgUserGroups)
	let currentGroupNames: string[] = []
	groupIds.forEach((element) => {
		if (element !== userGroupId) {
			currentGroupNames.push(orgUserGroups[element].user_group_info.name)
		}
	})
	let reservedUserGroupNames = [...INVALID_USER_GROUP_NAMES, ...currentGroupNames]

	const saveUserGroups = (values: { name: string; description: string; type: UserGroupType }) => {
		const { name, description, type } = values
		setLoading(true)
		const updateParams: CreateOrUpdateParams = {
			orgId: orgId,
			name: name.trim(),
			description: description,
			type: type || userGroupData.type,
			accessCode: userGroupData.accessCode,
			learningPaths: userGroupData.learningPaths
		}
		updateUserGroup(orgId, userGroupId, updateParams)

		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)
			closemodal()
		}, 3000)
	}

	const setDeleteView = () => {
		// don't let user see the delete view unless they have disassociated all users and items
		// from the user group...
		let delErrMsg = ""

		if (userGroupData.userCount > 0) {
			delErrMsg = delErrMsg.concat(`- ${userGroupData.userCount} users assigned\n`)
		}
		if (userGroupData.courseCount > 0) {
			delErrMsg = delErrMsg.concat(`- ${userGroupData.courseCount} courses tagged\n`)
		}
		if (userGroupData.resourceCount > 0) {
			delErrMsg = delErrMsg.concat(`- ${userGroupData.resourceCount} resources tagged \n`)
		}
		if (userGroupData.accessCodes.length > 0) {
			delErrMsg = delErrMsg.concat(`- ${userGroupData.accessCodes.length} access codes assigned\n`)
		}
		// do check based on string length...
		if (delErrMsg.length === 0) {
			setPanelView("delete")
		} else {
			delErrMsg =
				`NOTE: you must remove all items and users associated with this user group before deletion...\n\n`.concat(
					delErrMsg
				)
			setDelErrorMsg(delErrMsg)
		}
	}

	const handleUserGroupDelete = () => {
		// WARNING! this isn't something you want usere to do lightly
		deleteUserGroup(orgId, userGroupId)
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			// this isn't super elegant, but we want to force everything to be in sync
			// with new world where user group no longer exists...
			window.location.reload()
		}, 3000)
	}

	const handleRegenerateCode = async () => {
		setLoading(true)
		const accessCode = regenerateUserGroupCode(orgId, userGroupId)
		setAccessCode(accessCode)
		setTimeout(() => {
			setPanelView("main")
			setLoading(false)
		}, 2000)
	}

	useEffect(() => {
		setReload(true)
		setPanelView("main")
		setShowSaveChanges(false)
		setDeleteConfirmText(undefined)
		setDelErrorMsg(undefined)
		setAccessCode(userGroupData.accessCode)
		setTimeout(() => {
			setReload(false)
		}, 100)
	}, [userGroupData])

	if (reload) {
		return <></>
	}

	return (
		<>
			<ContentContainer>
				{panelView === "main" && (
					<>
						{loading ? (
							<>
								<CbLoading loadingText="Updating user group..." padding="30px" />
								Please note: changes may take a few minutes to reflect in the admin console...
							</>
						) : (
							<>
								<Form
									autoComplete="off"
									onFinish={saveUserGroups}
									layout="vertical"
									initialValues={userGroupData}
									onValuesChange={() => setShowSaveChanges(true)}
								>
									<Form.Item
										label={<S.FormLabel>Name</S.FormLabel>}
										name="name"
										rules={[
											{
												required: true,
												message: "Group name is required"
											},
											{
												max: 30,
												message: "Group name must have maximum 30 characters."
											},
											{
												message: "Cannot contain commas",
												validator: (_, value) => {
													if (value && value.includes(",")) {
														return Promise.reject()
													} else {
														return Promise.resolve()
													}
												}
											},
											{
												message: "Cannot contain emojis",
												validator: (_, value) => {
													if (value && EMOJI_REGEX.test(value)) {
														return Promise.reject()
													} else {
														return Promise.resolve()
													}
												}
											},
											{
												message: "This name already exists",
												validator: (_, value) => {
													// handle case and white space issues here
													if (
														value &&
														reservedUserGroupNames
															.map((e) => e.toLowerCase())
															.indexOf(value.toLowerCase().trim()) >= 0
													) {
														return Promise.reject()
													} else {
														return Promise.resolve()
													}
												}
											}
										]}
									>
										<Input
											disabled={INVALID_USER_GROUP_NAMES.includes(userGroupId.toLowerCase())}
											placeholder="Enter name..."
											showCount
										/>
									</Form.Item>
									<Form.Item
										label={<S.FormLabel>Description</S.FormLabel>}
										name="description"
										rules={[
											{
												required: true,
												message: "User group description is required"
											}
										]}
									>
										<TextArea
											disabled={INVALID_USER_GROUP_NAMES.includes(userGroupId.toLowerCase())}
											autoSize
											placeholder="Enter short description..."
										/>
									</Form.Item>
									{premiumFeatureAccess.learningPaths === "hidden" && !isAdmin ? (
										<></>
									) : (
										<Form.Item
											label={<S.FormLabel>Type</S.FormLabel>}
											name="type"
											rules={[
												{
													required: true,
													message: "User group type is required"
												}
											]}
											style={{
												opacity: premiumFeatureAccess.learningPaths === "hidden" ? 0.5 : 1
											}}
										>
											<Select
												options={[
													{ label: "Tag", value: "tag" },
													{ label: "Role", value: "role" }
												]}
												placeholder="Select type..."
											/>
										</Form.Item>
									)}
									<Form.Item label={<S.FormLabel>id</S.FormLabel>} name="id">
										<Input disabled={true} />
									</Form.Item>
									{showSaveChanges && (
										<Form.Item>
											<ButtonContainer>
												<Space>
													<Button shape="round" onClick={closemodal}>
														Cancel
													</Button>
													<Button type="primary" htmlType="submit" shape="round">
														Save changes
													</Button>
												</Space>
											</ButtonContainer>
										</Form.Item>
									)}
								</Form>
								<S.Divider borderOnBottom />
								<S.Divider />
								{accessCode && (
									<>
										<S.FormLabel>Share link</S.FormLabel>
										<Text style={{ marginTop: 6 }}>
											If a user presses on the link below it will automatically add them to this
											user group
										</Text>
										<LinkContainer>
											{/* @ts-ignore */}
											<CbLinkCopyButton
												contentToCopy={`${process.env.REACT_APP_USER_FRONTEND_NETLIFY_URL}/${orgId}?access=${userGroupData.accessCode}&org=${orgId}`}
												borderRadius="0px"
												withLinkButton={false}
												showHelperMsg={false}
												color="var(--light-grey)"
											/>

											<SmallButtonsContainer>
												<Space>
													<Button shape="round" onClick={() => setPanelView("code")}>
														Regenerate link
													</Button>
												</Space>
											</SmallButtonsContainer>
										</LinkContainer>
										<S.Divider height="16px" borderOnBottom />
										<S.Divider />
									</>
								)}
								<Collapse ghost style={{ marginLeft: -12, marginRight: -12 }}>
									<Panel header={`${userGroupData.courseCount} courses`} key="1">
										{userGroupData.courseIds.length === 0 ? (
											<Text type="secondary">No courses</Text>
										) : (
											<>
												{userGroupData.courseNames.map((name, index) => (
													<div style={{ marginBottom: 6 }} key={index}>
														<Link
															to={`/org/${orgId}/create/course/${userGroupData.courseIds[index]}`}
														>
															{name}
														</Link>
													</div>
												))}
											</>
										)}
									</Panel>
								</Collapse>
								<Collapse ghost style={{ marginLeft: -12, marginRight: -12 }}>
									<Panel header={`${userGroupData.resourceCount} resources`} key="2">
										{userGroupData.resourceNames.length === 0 ? (
											<Text type="secondary">No resources</Text>
										) : (
											<>
												{userGroupData.resourceNames.map((name) => (
													<div style={{ marginBottom: 6 }} key={name}>
														{name}
													</div>
												))}
											</>
										)}
									</Panel>
								</Collapse>
								<Collapse ghost style={{ marginLeft: -12, marginRight: -12 }}>
									<Panel header={`${userGroupData.accessCodes.length} access codes`} key="3">
										{userGroupData.accessCodes.length === 0 ? (
											<Text type="secondary">No access codes</Text>
										) : (
											<>
												{userGroupData.accessCodes.map((item, index) => (
													<Tag key={index}>{item}</Tag>
												))}
											</>
										)}
									</Panel>
								</Collapse>
								<S.Divider />
								{["admin", "moderators", "registered"].indexOf(userGroupId) === -1 && (
									<>
										<DeleteGroupText onClick={setDeleteView}>
											Need to delete this user group?
										</DeleteGroupText>
										{delErrorMsg && (
											<ErrorBox>
												<Alert message={delErrorMsg} type="warning" />
											</ErrorBox>
										)}
									</>
								)}
							</>
						)}
					</>
				)}

				{panelView === "delete" && (
					<>
						{loading ? (
							<>
								<CbLoading loadingText="Deleting user group..." padding="30px" />
								Please note: we will refresh the page after this operation...
							</>
						) : (
							<>
								<LpTextInput
									labelText="Group name"
									initialText={userGroupData.name}
									disabled={true}
									labelFontSize="16px"
								/>

								<S.Divider />

								<LpTextInput
									labelText="Type the name of user group shown above to delete"
									handleTextChange={(value) => setDeleteConfirmText(value)}
									labelFontSize="16px"
								/>

								{deleteConfirmText === userGroupData.name ? (
									<ErrorBox>
										<Alert
											message={"WARNING: This action cannot be reversed, proceed with caution!"}
											type="warning"
										/>
									</ErrorBox>
								) : (
									<></>
								)}
								<S.Divider />
								<ButtonContainer>
									<Space>
										<Button shape="round" onClick={() => setPanelView("main")}>
											Cancel
										</Button>
										<Button
											shape="round"
											disabled={deleteConfirmText !== userGroupData.name}
											onClick={handleUserGroupDelete}
										>
											Confirm delete
										</Button>
									</Space>
								</ButtonContainer>
							</>
						)}
					</>
				)}

				{panelView === "code" && (
					<>
						{loading ? (
							<>
								<CbLoading loadingText="Regenerating code..." padding="30px" />
							</>
						) : (
							<>
								<Alert
									message={
										"If you regenerate this link then the previous one will no longer work. Make sure you're ready to do this!"
									}
									type="warning"
								/>
								<S.Divider />
								<ButtonContainer>
									<Space>
										<Button shape="round" onClick={() => setPanelView("main")}>
											Cancel
										</Button>
										<Button type="primary" shape="round" onClick={handleRegenerateCode}>
											Regenerate
										</Button>
									</Space>
								</ButtonContainer>
							</>
						)}
					</>
				)}
			</ContentContainer>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups,
	premiumFeatureAccess: selectPremiumFeatureAccess,
	isAdmin: selectIsUserAdmin
})

const mapDispatchToProps = (dispatch: any) => ({
	deleteUserGroup: (orgId: string, userGroupId: string) => dispatch(deleteUserGroup(orgId, userGroupId)),
	updateUserGroup: (orgId: string, userGroupId: string, updateParams: CreateOrUpdateParams) =>
		dispatch(updateUserGroup(orgId, userGroupId, updateParams)),
	regenerateUserGroupCode: (orgId: string, userGroupId: string) =>
		dispatch(regenerateUserGroupCode(orgId, userGroupId))
})

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(UserGroupSidePanel)
