import { useLocation, useHistory } from "react-router-dom"
import qs from "query-string"

const setQueryStringValue = (key, value, location, history) => {
	const values = qs.parse(location.search)
	const newQsValue = qs.stringify({
		...values,
		[key]: value
	})
	const newurl = window.location.pathname + `?` + newQsValue
	history.push(newurl)
}

// Hook
const useUrlQueryString = (key) => {
	const location = useLocation()
	let history = useHistory()

	const values = qs.parse(location.search) // e.g { time: "7", tags: "admin"}
	const qsValue = values[key] // e.g. ...?time=7 gives "7"

	const setQueryString = (val) => {
		setQueryStringValue(key, val, location, history) // update URL query for given key-val pair, e.g key: "time", value: "7"
	}

	return [qsValue, setQueryString]
}

export default useUrlQueryString
