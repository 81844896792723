import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { BarChartOutlined, CarryOutOutlined, FormOutlined } from "@ant-design/icons"
import { Menu, Tag } from "antd"
import styled from "styled-components"
import { useLocation, Link } from "react-router-dom"

import useUrlQueryString from "../../hooks/useUrlQueryString"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import { selectIsBetaTester } from "../../../redux/user/user.selectors"

const SubNav = styled.div`
	width: 100%;
	background: transparent;
	display: flex;
	flex-direction: column;
`

const switchLastPathInDir = (pathname: string, newDir: string) => {
	let pathArr = pathname.split("/")
	pathArr.pop()
	return pathArr.join("/") + "/" + newDir
}

interface Props {
	view: "progress" | "surveys" | "activity"
	learningPath: LearningPath
	isBetaTester: boolean
}

const LearningPathNav = ({ view, learningPath, isBetaTester }: Props) => {
	const { pathname, search } = useLocation()
	const userGroups = search.split("userGroups=", 2)[1]
		? `&userGroups=${search.split("userGroups=", 2)[1].split("&", 1)[0]}`
		: ""
	const dateRange = search.split("dateRange=", 2)[1]
		? `&dateRange=${search.split("dateRange=", 2)[1].split("&", 1)[0]}`
		: ""
	const [fixedView] = useUrlQueryString("fixedView")
	const fixedViewParams = fixedView ? `&fixedView=true` : ``
	const defaultSearchParams = `?id=${learningPath.id}${userGroups}${dateRange}${fixedViewParams}`

	const items = [
		{
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "progress"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Progress
				</Link>
			),
			key: "progress",
			icon: <BarChartOutlined />
		},
		{
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "surveys"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Survey answers
				</Link>
			),
			key: "surveys",
			icon: <FormOutlined />
		}
	]

	if (isBetaTester) {
		items.push({
			label: (
				<Link
					to={{
						pathname: switchLastPathInDir(pathname, "activity"),
						search: defaultSearchParams
					}}
					style={{ lineHeight: 2.2 }}
				>
					Activity
				</Link>
			),
			key: "activity",
			icon: <CarryOutOutlined />
		})
	}
	return (
		<>
			<div className="row" style={{ padding: "10px 25px 10px 25px" }}>
				<h1 style={{ fontSize: 28, fontWeight: 600, paddingLeft: 4, marginBottom: 4 }}>
					{learningPath.internalName}
				</h1>
				<span style={{ paddingTop: 11, paddingLeft: 14 }}>
					{<Tag color="var(--dark-grey)">{new LearningPathContent(learningPath).getLengthInDays()} day</Tag>}
				</span>
			</div>
			<SubNav>
				<Menu
					//@ts-ignore
					selectedKeys={[view]}
					mode="horizontal"
					items={items}
					style={{
						background: "transparent",
						fontSize: 18
					}}
				/>
			</SubNav>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	isBetaTester: selectIsBetaTester
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathNav)
