// DASHBOARD SHOWING QUIZ QUESTION ANSWERS

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import useUrlQueryString from "../../hooks/useUrlQueryString"
import ModuleQuestionSelectPanel from "../charts/questions-dash/ModuleQuestionSelectPanel"
import CourseSubNav from "../nav/CourseSubNav"
import QuestionsDashInsights from "../charts/questions-dash/QuestionsDashInsights"
import AnSelectItems from "../../common/AnSelectItems"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkSquareAlt, faChartBar } from "@fortawesome/free-solid-svg-icons"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff
import { selectOrgId, selectOrgCourseCards } from "../../../redux/org/org.selectors"
import { fetchQuestionContent } from "./helperFunctions"
import CbLoading from "../../common/CbLoading"
import ChartQuery from "../common/ChartQuery"

const QuestionsDash = ({ orgId, orgCourseCards }) => {
	// pull key params from the URL query string
	const [pageState, setPageState] = useState("loading")
	const [moduleOptions, setModuleOptions] = useState([])
	const [questionContent, setQuestionContent] = useState([])
	const [courseId] = useUrlQueryString("courseId")
	const [moduleIds, setModuleIds] = useUrlQueryString("moduleIds")
	let modulesArr = moduleIds ? moduleIds.split(",") : []

	// make sure module ids are cleared if course is changed
	useEffect(() => {
		if (moduleIds !== null) {
			setModuleIds(null)
		}
	}, [courseId])

	useEffect(() => {
		const getQuestionContent = async (courseArr, orgId) => {
			const data = await fetchQuestionContent(courseArr, orgId, orgCourseCards)
			if (data && data.modulesFormatted) {
				setModuleOptions(data.modulesFormatted)
				setQuestionContent(data.questionData)
				setPageState("complete")
			} else {
				setPageState("error")
			}
		}
		getQuestionContent([courseId], orgId)
	}, [courseId, orgId, orgCourseCards])

	if (!courseId)
		return (
			<AnSelectItems
				icon={<FontAwesomeIcon icon={faExternalLinkSquareAlt} />}
				titleText={"Select a course from the dropdown above"}
				subtitleText={"Use the dropdown on the upper right to select a published course"}
				hasBorder={false}
				cardBodyBottomMargin={"20px"}
				cardMargin={"18px"}
			>
				<div
					style={{
						marginTop: "30px",
						fontSize: "300px",
						color: "var(--v-light-grey)"
					}}
				>
					<FontAwesomeIcon icon={faChartBar} />
				</div>
			</AnSelectItems>
		)

	if (pageState === "loading") {
		return <CbLoading size={40} />
	}

	return (
		<div className="container-fluid">
			<CourseSubNav courseId={courseId} />

			<div className="row">
				<div className="col-sm-12" style={{ paddingTop: 15 }}>
					<ModuleQuestionSelectPanel
						moduleOptions={moduleOptions}
						title="Select course modules"
						tooltip="Select the modules from the courses you want to further inspect"
					/>
				</div>
			</div>

			{modulesArr.length > 0 && (
				<ChartQuery
					route="quiz-question/results"
					params={`&courseId=${courseId}&questionIds=${questionContent
						.filter((item) => modulesArr.includes(item.moduleId))
						.map((item) => item.card_id)}`}
				>
					<QuestionsDashInsights
						filteredQuestionContent={questionContent.filter((item) => modulesArr.includes(item.moduleId))}
						orgId={orgId}
					/>
				</ChartQuery>
			)}
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgCourseCards: selectOrgCourseCards
})

export default connect(mapStateToProps)(QuestionsDash)
