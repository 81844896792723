import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Spin } from "antd"

const LoadingText = styled.h1`
	font-size: 18px;
	font-weight: 500;
	color: var(--secondary-color);
	margin-top: 20px;
`
const Container = styled.div`
	height: ${(props) => (props.fullpage === true ? "100vh" : "100%")};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${(props) => props.padding};
`

export default function CbLoading({ loadingText, padding, size, fullpage }) {
	return (
		<Container padding={padding} fullpage={fullpage}>
			<Spin
				indicator={
					<LoadingOutlined
						style={{
							fontSize: size ? size : 60,
							color: "var(--secondary-color)"
						}}
						spin
					/>
				}
			/>
			{loadingText && <LoadingText className="logo-animation">{loadingText}</LoadingText>}
		</Container>
	)
}

CbLoading.propTypes = {
	loadingText: PropTypes.string,
	fullpage: PropTypes.bool
}

CbLoading.defaultProps = {
	fullpage: false,
	primaryColor: "var(--secondary-color)",
	secondaryColor: "var(--secondary-color-highlight)"
}
