import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import { getUserListForOrg } from "../../redux/management/management.actions"
import { selectUserList } from "../../redux/management/management.selectors"
import { selectLearningPathById, selectOrgId, selectOrgUserGroups } from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

import UserListFilters from "../../components/management/userTable/UserListFilters"
import CbLoading from "../../components/common/CbLoading"
import GeNoItemsFound from "../../components/common/GeNoItemsFound"
import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"

const ManageUsers = ({ orgId, userList, orgUserGroups, getUserListForOrg, learningPathById }) => {
	const [pageState, setPageState] = useState("loading")

	const getUserList = async () => {
		try {
			await getUserListForOrg(orgId, orgUserGroups, learningPathById)
			setPageState("default")
		} catch (err) {
			setPageState("error")
		}
	}

	useEffect(() => {
		// check if userlist exists in redux state, if not then get it
		if (userList === null) {
			getUserList()
		} else {
			setPageState("default")
		}
	}, [])

	return (
		<S.Container>
			<DashboardHeaderButton
				title={"Users"}
				smWidth="96%"
				laWidth="96%"
				buttonTitle="Add users"
				dropDown
				menu={[
					{
						key: "import",
						label: <Link to={`/org/${orgId}/manage/import-users`}>Import users</Link>
					}
				]}
			/>
			{pageState === "default" && <UserListFilters userList={userList} />}
			{pageState === "error" && (
				<GeNoItemsFound
					titleText="Slow query"
					subtitleText="It looks like this query failed to load in time. Please check your connection"
					icon={<FontAwesomeIcon icon={faExclamationCircle} />}
				/>
			)}
			{pageState === "loading" && <CbLoading />}
		</S.Container>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	userList: selectUserList,
	orgUserGroups: selectOrgUserGroups,
	learningPathById: selectLearningPathById
})

const mapDispatchToProps = (dispatch) => ({
	getUserListForOrg: (orgId, orgUserGroups, learningPathById) =>
		dispatch(getUserListForOrg(orgId, orgUserGroups, learningPathById))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
