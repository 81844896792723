import { SubscriptionActionTypes } from "./subscription.types"

const INITIAL_STATE = {
	activePlan: {},
	selectedPlan: null,
	planList: []
}

const subscriptionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SubscriptionActionTypes.SET_ACTIVE_PLAN:
			return {
				...state,
				activePlan: action.payload
			}
		case SubscriptionActionTypes.SET_SELECTED_PLAN:
			return {
				...state,
				selectedPlan: action.payload
			}
		case SubscriptionActionTypes.SET_PLAN_LIST:
			return {
				...state,
				planList: action.payload
			}
		default:
			return state
	}
}

export default subscriptionReducer
