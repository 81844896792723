// SHARE COURSE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Alert, Tag, Select, Typography, Radio } from "antd"

import { selectOrgId, selectOrgUserGroups } from "../../../../redux/org/org.selectors"

import CbLinkCopyButton from "../../../common/CbLinkCopyButton"
import TooltipIcon from "../../../common/TooltipIcon"

const { Title } = Typography

const Container = styled.div`
	width: 500px;
	min-height: 160px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const ShareCourse = ({ courseType, previewUrl, publishUrl, accessGroups, orgId, orgUserGroups }) => {
	// set options for multi-select
	const accessGroupOptions = accessGroups.map((userGroupId) => {
		return {
			value: userGroupId,
			label: orgUserGroups[userGroupId].user_group_info.name,
			isFixed: false,
			isDisabled: false
		}
	})

	const hideUserGroupSelect = accessGroupOptions.length === 1 && accessGroupOptions[0].value === "registered" // hide if the only option is 'registered'

	const [shareState, setShareState] = useState(courseType)
	const [sharingUrl, setSharingUrl] = useState(publishUrl)

	const handleGroupsChange = (newValues) => {
		let access_code_string = "" // should create string of form e.g. SDHDFJG&DFHGFJFG
		if (newValues) {
			newValues.forEach((newVal, index) => {
				if (orgUserGroups[newVal] && orgUserGroups[newVal].access_code !== null) {
					if (index === 0) {
						access_code_string = access_code_string.concat(`${orgUserGroups[newVal].access_code}`) // ensure first code doesn't have '+' in front
					} else {
						access_code_string = access_code_string.concat(`+${orgUserGroups[newVal].access_code}`) // later codes use '+' in front
					}
				}
			})
		}

		if (!!access_code_string) {
			setSharingUrl(`${publishUrl}?access=${access_code_string}&org=${orgId}`) // e.g. ?access=AJDJKE+JKSBE&org=my-org
		} else {
			setSharingUrl(publishUrl)
		}
	}

	const tagRender = (props) => {
		const { label, value, closable, onClose } = props
		const onPreventMouseDown = (event) => {
			event.preventDefault()
			event.stopPropagation()
		}
		return (
			<Tag
				color={orgUserGroups[value].color}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		)
	}

	return (
		<Container>
			<Radio.Group
				size="large"
				onChange={(e) => setShareState(e.target.value)}
				value={shareState}
				buttonStyle="solid"
			>
				<Radio.Button value="publish">Share published</Radio.Button>
				<Radio.Button value="preview">Share preview</Radio.Button>
			</Radio.Group>

			<Divider height={"30px"} />

			{shareState === "publish" && (
				<>
					<Title level={4}>Share published course</Title>
					{publishUrl && publishUrl !== "" ? (
						<>
							<CbLinkCopyButton
								width="100%"
								contentToCopy={sharingUrl}
								targetUrl={sharingUrl}
								borderRadius="0px"
								withLinkButton={true}
								showHelperMsg={false}
								color="var(--secondary-color)"
							/>

							<Divider height={"30px"} />

							{hideUserGroupSelect ? null : (
								<>
									<Title level={5}>
										Add user group access in link{" "}
										<TooltipIcon
											tooltip={
												"Your course can only be accessed by the groups in this dropdown list. If you select a group from the dropdown list then your share link will be updated with an access code that will automatically add this user to the group"
											}
										/>
									</Title>
									<Select
										size="large"
										mode="multiple"
										showArrow={true}
										style={{ width: "100%" }}
										options={accessGroupOptions}
										tagRender={tagRender}
										onChange={(value) => handleGroupsChange(value)}
									/>
								</>
							)}

							{sharingUrl !== publishUrl && (
								<>
									<Divider height={"15px"} />
									<Alert
										message={
											"NOTE: you updated the course sharing link so it will automatically add people to the user groups selected when they click on it"
										}
									/>
								</>
							)}
						</>
					) : (
						<Alert
							type="warning"
							message="It looks like you haven't published your course yet! You need to create published version of your course before you can share it!"
						/>
					)}
				</>
			)}
			{shareState === "preview" && (
				<>
					<Title level={4}>Share preview course</Title>
					<CbLinkCopyButton
						width="100%"
						contentToCopy={previewUrl}
						targetUrl={previewUrl}
						borderRadius="0px"
						withLinkButton={true}
						showHelperMsg={false}
						color="var(--secondary-color)"
					/>

					<Divider height={"20px"} />

					<Alert message="Share the preview version with people you would like early feedback from. It can only be accessed via the link you share" />

					<Divider height={"24px"} />
				</>
			)}
		</Container>
	)
}

ShareCourse.propTypes = {
	courseType: PropTypes.string.isRequired,
	previewUrl: PropTypes.string,
	publishUrl: PropTypes.string,
	orgId: PropTypes.string.isRequired,
	accessGroups: PropTypes.array.isRequired,
	orgUserGroups: PropTypes.object.isRequired
}

ShareCourse.defaultProps = {
	closemodal: () => console.log("dummy closemodal func")
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(ShareCourse)
