// STANDARD TEMPLATE CARD

import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Typography, Tag } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { ClockCircleOutlined, UnlockFilled, LockFilled } from "@ant-design/icons"

import { selectOrgId } from "../../../redux/org/org.selectors"

import LpModal from "../../common/LpModal"
import CbCourseCardImage from "../../common/CbCourseCardImage"
import AddTemplateCourse from "./modals/AddTemplateCourse"

const { Text, Title, Paragraph } = Typography

const CourseContentContainer = styled.div`
	width: 100%;
	height: 145px;
	border-bottom: solid 1px var(--v-light-grey);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
const CourseContentTextContainer = styled.div`
	flex: 1;
	height: 150px;
	width: 200px;
	padding: 6px;
	padding-right: 10px;
	padding-top: 15px;
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
const CourseCardFooter = styled.div`
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
`
const HeaderMenuButton = styled.button`
	background: none;
	margin: 10px 15px;
	font-size: 22px;
	color: var(--light-grey);
`
const CourseCardContainer = styled.div`
	height: 200px;
	width: 100%;
	background: white;
	display: block;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
	border-radius: 8px;
	margin-bottom: 20px;
	position: relative;
`
const CardOverlayButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99%;
	width: 70px;
	height: 70px;
	text-transform: uppercase;
	background-color: var(--secondary-color-highlight);
	color: white;
	font-size: 24px;
	font-weight: 600;
	overflow: hidden;
	cursor: pointer;
	text-overflow: ellipsis;
	transition: 0.5s ease;
	:hover {
		background-color: var(--secondary-color);
	}
`
const CardOverlayButtonsContainer = styled.div`
	flex: 1;
	display: flex;
	opacity: 0;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	padding-left: 16px;
	padding-right: 16px;
`
const CardOverlayContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	transition: 0.5s ease;
	background-color: rgba(0, 0, 0, 0);
	:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
	:hover ${CardOverlayButtonsContainer} {
		opacity: 1;
	}
	:hover ${HeaderMenuButton} {
		color: white;
	}
`
const StatusTag = styled.div`
	width: auto;
	font-size: 13px;
	border-radius: 5px;
	line-height: 30px;
	padding: 0px 6px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	border-width: 1px;
	border-style: solid;
`
const TimerRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
`

const TemplateCourseCard = ({ orgId, course }) => {
	const [showModal, setShowModal] = useState(false)
	// pull out key values
	const { course_id, course_info, template_info, status } = course
	const { name, short_description, target_learner, language, image } = course_info
	const { timeToComplete, labels } = template_info

	const info = {
		name,
		image,
		preview_url: null,
		publish_url: null,
		target_learner,
		short_description,
		user_groups: {
			view_groups: ["public"],
			access_groups: ["registered"]
		},
		language
	}

	return (
		<>
			<CourseCardContainer>
				<CardOverlayContainer>
					<CardOverlayButtonsContainer>
						<CardOverlayButton onClick={() => setShowModal(true)}>
							<FontAwesomeIcon icon={faPlus} />
						</CardOverlayButton>
					</CardOverlayButtonsContainer>
				</CardOverlayContainer>

				{/* This is normal visible card */}
				<CourseContentContainer>
					<CbCourseCardImage backgroundColor={"var(--dark-jade)"} image={image} />

					<CourseContentTextContainer>
						<Title level={5} ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
							{name}
						</Title>
						<Paragraph type="secondary" ellipsis={{ rows: 2 }} style={{ marginBottom: 8 }}>
							{short_description}
						</Paragraph>
						<Paragraph>
							{labels &&
								labels.map((label, index) => (
									<Tag key={index} color="#f3b616">
										{label}
									</Tag>
								))}
						</Paragraph>
					</CourseContentTextContainer>
				</CourseContentContainer>

				<CourseCardFooter>
					{status === "free" && (
						<StatusTag style={{ borderColor: "var(--secondary-color)", color: "var(--secondary-color)" }}>
							{status}
						</StatusTag>
					)}
					{status === "locked" && (
						<StatusTag style={{ borderColor: "var(--mid-grey)", color: "var(--mid-grey)" }}>
							<LockFilled style={{ marginRight: 5 }} />
							{status}
						</StatusTag>
					)}
					{status === "unlocked" && (
						<StatusTag style={{ borderColor: "var(--secondary-color)", color: "var(--secondary-color)" }}>
							<UnlockFilled style={{ marginRight: 5 }} />
							{status}
						</StatusTag>
					)}
					{timeToComplete && (
						<TimerRow>
							<ClockCircleOutlined />
							<Text type="secondary" style={{ marginLeft: 6 }}>
								{timeToComplete}
							</Text>
						</TimerRow>
					)}
				</CourseCardFooter>
			</CourseCardContainer>
			<LpModal isShowingModal={showModal} hideModal={() => setShowModal(!showModal)}>
				<AddTemplateCourse
					courseId={course_id}
					orgId={orgId}
					courseInfo={info}
					templateInfo={template_info}
					status={status}
				/>
			</LpModal>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

PropTypes.CourseCard = {
	orgId: PropTypes.string.isRequired,
	course: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(TemplateCourseCard)
