import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Button, Dropdown, MenuProps, Space, Tag, Typography } from "antd"
import { LearningPath } from "learnink-common/lib/domains/learningPaths/LearningPath.model"
import { EditOutlined, LineChartOutlined, ShareAltOutlined } from "@ant-design/icons"
import { selectLearningPathById, selectOrgId } from "../../../redux/org/org.selectors"
import { LearningPathContent } from "../../../pages/learningPaths/utils/LearningPathContent"
import * as S from "../../styles/PageStyles"
import { LearningPathById } from "../../../util/model/OrgSelectors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

const { Text } = Typography

const CardContainer = styled.div`
	position: relative;
	float: left;
	width: 100%;
	height: 190px;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	margin-right: 20px;
	margin-bottom: 20px;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 1px 2px 2px var(--light-grey);
	border: solid 1px var(--light-grey);
	transition: 0.5s ease;
	@media (min-width: 850px) {
		width: 240px;
	}
	@media (min-width: 1110px) {
		width: 240px;
	}
`
const Header = styled.div`
	width: 100%;
	padding: 10px 10px 10px 10px;
	text-align: center;
`
const CardOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
	transition: 0.5s ease;
	opacity: 0;
	${CardContainer}:hover & {
		opacity: 1;
	}
`
const DropdownButton = styled.div`
	width: 35px;
	height: 25px;
	font-size: 18px;
	color: white;
	position: absolute;
	text-align: center;
	top: 10px;
	right: 0px;
	cursor: pointer;
`

interface Props {
	learningPath: LearningPath
	orgId: string
	learningPathById: LearningPathById
	setModalType: React.Dispatch<React.SetStateAction<"create" | "archive" | "unarchive" | "share" | "none">>
	setSelectedLearningPathId: React.Dispatch<React.SetStateAction<string | undefined>>
	roleId: string
}

const LearningPathContainer = ({
	learningPath,
	orgId,
	learningPathById,
	setModalType,
	setSelectedLearningPathId,
	roleId
}: Props) => {
	const items: MenuProps["items"] = learningPath.isArchived
		? [
				{
					key: "unarchive",
					label: (
						<div
							onClick={() => {
								setSelectedLearningPathId(learningPath.id)
								setModalType("unarchive")
							}}
						>
							Unarchive
						</div>
					)
				}
		  ]
		: [
				{
					key: "archive",
					label: (
						<div
							onClick={() => {
								setSelectedLearningPathId(learningPath.id)
								setModalType("archive")
							}}
						>
							Archive
						</div>
					)
				}
		  ]

	return (
		<CardContainer>
			<Header
				style={{
					background: new LearningPathContent(learningPath).getColor()
				}}
			>
				<Text
					style={{
						textTransform: "uppercase",
						fontWeight: "bold",
						color: "white"
					}}
				>
					{learningPath.internalName}
				</Text>
			</Header>
			<div style={{ flex: 1, padding: 10 }}>
				{learningPath.sequences.length === 0 && <Tag>No content</Tag>}
				{learningPath.sequences.length > 0 && (
					<Tag color={new LearningPathContent(learningPath).getColor()} key={0}>
						{learningPath.sequences[0].internalName}
					</Tag>
				)}
				{learningPath.sequences.length > 1 && (
					<Tag color={new LearningPathContent(learningPath).getColor()} key={0}>
						{learningPath.sequences[1].internalName}
					</Tag>
				)}
				{learningPath.sequences.length > 2 && (
					<Tag color={new LearningPathContent(learningPath).getColor()} key={0}>
						+{learningPath.sequences.length - 2}
					</Tag>
				)}
			</div>
			<S.FlexBoxColumnCentered style={{ width: "100%", height: 58, borderTop: "solid 1px var(--light-grey)" }}>
				{learningPath.onCompletion.nextLearningPathId &&
					learningPathById[learningPath.onCompletion.nextLearningPathId] && (
						<>
							On completion move to:{" "}
							<Tag color={learningPathById[learningPath.onCompletion.nextLearningPathId].color}>
								{learningPathById[learningPath.onCompletion.nextLearningPathId].internalName}
							</Tag>
						</>
					)}
				{!learningPath.onCompletion.nextLearningPathId && (
					<>
						On completion: <Tag>Stay in learning path</Tag>
					</>
				)}
			</S.FlexBoxColumnCentered>
			<CardOverlay>
				<DropdownButton>
					<Dropdown menu={{ items }} placement="bottomRight">
						<FontAwesomeIcon icon={faEllipsisV as IconProp} />
					</Dropdown>
				</DropdownButton>
				<Space align="center" size="large">
					<Link to={`/org/${orgId}/create/learning-path/${learningPath.id}?userGroupId=${roleId}`}>
						<Button
							shape="circle"
							icon={<EditOutlined />}
							style={{ width: 50, height: 50 }}
							type="primary"
						/>
					</Link>
					{learningPath.sequences.length > 0 && (
						<Button
							shape="circle"
							icon={<ShareAltOutlined />}
							style={{ width: 50, height: 50 }}
							type="primary"
							onClick={() => {
								setSelectedLearningPathId(learningPath.id)
								setModalType("share")
							}}
						/>
					)}
					<Link to={`/org/${orgId}/analyse/learning-paths/progress?id=${learningPath.id}`}>
						<Button
							shape="circle"
							icon={<LineChartOutlined />}
							style={{ width: 50, height: 50 }}
							type="primary"
						/>
					</Link>
				</Space>
			</CardOverlay>
		</CardContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	learningPathById: selectLearningPathById
})

// @ts-ignore
export default connect(mapStateToProps)(LearningPathContainer)
