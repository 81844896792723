import axios from "axios"

export const fetchQuestionContent = async (courseArr, orgId, orgCourseCards) => {
	let modulesFormatted = []
	let questionData = []
	try {
		await Promise.all(
			courseArr.map((item) =>
				axios
					.get(process.env.REACT_APP_S3_COURSE_BUCKET_URL + orgId + "/courses/" + item + ".json", {
						headers: { Authorization: "" }
					})
					.then((response) => {
						let courseContent = response.data
						if (courseContent) {
							for (var i = 0; i < courseContent.modules.length; i++) {
								let moduleId = courseContent.modules[i].module_id
								let moduleStageIds = courseContent.modules[i].module_stage_ids
								for (var j = 0; j < moduleStageIds.length; j++) {
									let moduleStageId = moduleStageIds[j]
									if (
										courseContent.module_stages.filter((item) => item.stage_id === moduleStageId)
											.length > 0
									) {
										let questionCardIds = courseContent.module_stages.filter(
											(item) => item.stage_id === moduleStageId
										)[0].question_card_ids
										for (var k = 0; k < questionCardIds.length; k++) {
											let cardId = questionCardIds[k]
											if (
												courseContent.question_cards.filter((item) => item.card_id === cardId)
													.length > 0
											) {
												let questionCard = courseContent.question_cards.filter(
													(item) => item.card_id === cardId
												)[0]
												questionCard.moduleId = moduleId
												questionData.push(questionCard)
											}
										}
									}
								}
							}
							let courseName = orgCourseCards[item].info.name
							let modules = courseContent.stages.filter((item) => item.type === "module")
							for (var n = 0; n < modules.length; n++) {
								let index = n + 1
								let moduleData = {
									value: modules[n].module_id,
									label: index + ". " + modules[n].info.name + " [" + courseName + "]"
								}
								modulesFormatted.push(moduleData)
							}
						}
					})
			)
		)
		return {
			modulesFormatted,
			questionData
		}
	} catch (err) {
		console.log(err)
		return null
	}
}

export const fetchSurveyContent = async (courseArr, orgId, orgCourseCards) => {
	const surveyData = []
	try {
		await Promise.all(
			courseArr.map((item) =>
				axios
					.get(process.env.REACT_APP_S3_COURSE_BUCKET_URL + orgId + "/courses/" + item + ".json", {
						headers: { Authorization: "" }
					})
					.then((response) => {
						let courseContent = response.data
						if (courseContent) {
							let courseName = orgCourseCards[item].info.name
							for (var i = 0; i < courseContent.lesson_cards.length; i++) {
								if (courseContent.lesson_cards[i].type === "survey") {
									courseContent.lesson_cards[i].course_name = courseName
									courseContent.lesson_cards[i].course_id = item
									surveyData.push(courseContent.lesson_cards[i])
								}
							}
						}
					})
			)
		)
		return surveyData
	} catch (err) {
		console.log(err)
		return null
	}
}
