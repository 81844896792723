import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Redirect } from "react-router-dom"
import PropTypes from "prop-types"
import styled from "styled-components"
import axios from "axios"

import { selectOrgId } from "../../redux/org/org.selectors"
import * as S from "../../components/styles/PageStyles"

import LpBackButton from "../../components/common/LpBackButton"
import CbLoading from "../../components/common/CbLoading"
import AccessCodeForm from "../../components/management/accessCodes/AccessCodeForm"

const LoadingContainer = styled.div`
	display: flex;
	height: 100vh;
	width: 100%;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`

const CreateAccessCodeForm = ({ orgId }) => {
	const [dummyLoading, setDummyLoading] = useState(false)
	const [redirect, setRedirect] = useState(false)

	const handleSubmit = async (accessCodeData) => {
		try {
			await axios.post(`/api/accessCode/${orgId}`, accessCodeData)
			setRedirect(true)
		} catch (err) {
			setTimeout(() => {
				setDummyLoading(false)
			}, 2000)
		}
	}

	if (redirect) return <Redirect to={`/org/${orgId}/manage/access-codes`} />

	if (dummyLoading === true)
		return (
			<LoadingContainer>
				<CbLoading loadingText="Creating access code..." padding="30px" />
			</LoadingContainer>
		)

	return (
		<React.Fragment>
			<S.PageContainer>
				<S.BackContainer>
					<LpBackButton backlink={`/org/${orgId}/manage/access-codes`} />
				</S.BackContainer>

				<S.FormContainer>
					<S.FormTitle>Create access code</S.FormTitle>
					<AccessCodeForm handleSubmit={handleSubmit} submitAction="create" />
				</S.FormContainer>
			</S.PageContainer>
		</React.Fragment>
	)
}

CreateAccessCodeForm.propTypes = {
	orgId: PropTypes.string.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

export default connect(mapStateToProps)(CreateAccessCodeForm)
