import React from "react"
import { Modal, Alert } from "antd"

interface Props {
	title: string
	message: string
	isModalOpen: boolean
	type?: "error" | "warning"
	closeModal: () => void
}

const AlertModal = ({ title, message, type, isModalOpen, closeModal }: Props) => {
	return (
		<Modal title={title} open={isModalOpen} onCancel={closeModal} onOk={closeModal} centered>
			<Alert message={message} type={type || "warning"} />
		</Modal>
	)
}

export default AlertModal
