import React from "react"
import styled from "styled-components"
import "./Loading.css"

const ModalLayout = styled.div`
	display: table;
	height: 100%;
	z-index: 5;
`

const ModalContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	text-align: center;
`

function Loading() {
	return (
		<ModalLayout className="FixedToTop">
			<ModalContainer>
				<svg className="logo-animation fill-blue" viewBox="244 288.4 36 34">
					<path d="M261.9 288.6c-9.6 0-17.4 6.4-17.4 14.2 0 4.8 3 9.1 7.5 11.7.2.7.2 1.4.1 2.2-.3 1.8-1 3.4-2 4.9-.1.1-.2.3-.3.5.2-.1.3-.1.3-.1 1.7-.7 3.2-1.5 4.7-2.6 1.1-.8 2.1-1.8 3-2.8 1.3.3 2.7.4 4.2.4 9.6 0 17.4-6.4 17.4-14.2 0-7.9-7.8-14.2-17.5-14.2m-8.2 19c0 .1 0 .2-.1.2-.1.1-.2.1-.2.1h-2.6c-.1 0-.2 0-.2-.1-.1-.1-.1-.2-.1-.2v-8.4c0-.1 0-.2.1-.2.1-.1.2-.1.2-.1h2.6c.1 0 .2 0 .2.1s.1.2.1.2v8.4zm10.4 0c0 .1 0 .2-.1.2-.1.1-.2.1-.2.1h-2.1c-.2 0-.4-.1-.5-.3l-2.2-3.3v3.3c0 .1 0 .2-.1.2-.1.1-.2.1-.2.1h-2.4c-.1 0-.2 0-.2-.1-.1-.1-.1-.2-.1-.2v-8.4c0-.1 0-.2.1-.2.1-.1.2-.1.2-.1h2.1c.2 0 .4.1.5.3l2.2 3.6v-3.5c0-.1 0-.2.1-.2s.2-.1.2-.1h2.4c.1 0 .2 0 .2.1s.1.2.1.2v8.3zm10.5.2c-.1.1-.1.1-.2.1h-3c-.3 0-.4-.1-.5-.3l-1.6-2.8v2.8c0 .1 0 .2-.1.2-.1.1-.2.1-.2.1h-2.5c-.1 0-.2 0-.2-.1-.1-.1-.1-.2-.1-.2v-8.4c0-.1 0-.2.1-.2.1-.1.2-.1.2-.1h2.5c.1 0 .2 0 .2.1.1.1.1.2.1.2v2.6l1.5-2.6c.1-.2.3-.3.5-.3h2.9c.1 0 .1 0 .2.1s.1.1.1.2v.2l-2.3 3.9 2.6 4.3v.2c0 .1-.1 0-.2 0" />
				</svg>
			</ModalContainer>
		</ModalLayout>
	)
}

export default Loading
