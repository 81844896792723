import React from "react"
import styled from "styled-components"
import ChartWrapper from "../../common/ChartWrapper"
import { CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from "recharts"
import SingleValueChart from "../../common/SingleValueChart"

const OuterContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 20px;
	align-items: flex-start;
`
const ValueContainer = styled.div`
	margin-right: 40px;
	min-width: 140px;
`
const ChartContainer = styled.div`
	width: 100%;
	height: 100%;
`

const SingleSurveyChartAndTotal = ({ cardData, surveyContent }) => {
	const data = []
	if (surveyContent.content.response_array) {
		surveyContent.content.response_array.forEach((element) => {
			data.push({
				text: element.answer_text,
				answerCount: cardData.filter((item) => item.surveyAnswerId === element.survey_ans_id).length
			})
		})
	}

	return (
		<OuterContainer>
			<ValueContainer>
				<ChartWrapper title="Responses" tooltip="The total number of responses for this survey question">
					<SingleValueChart>{cardData.length}</SingleValueChart>
				</ChartWrapper>
			</ValueContainer>

			<ChartContainer>
				{surveyContent.content.survey_type === "multiple_choice" ? (
					<ChartWrapper
						title="Survey Response Choices"
						tooltip="A chart showing breakdown of responses across the multiple choices given"
					>
						<ResponsiveContainer width="99%" height={240}>
							<BarChart
								margin={{
									top: 10,
									right: 30,
									left: 10,
									bottom: 10
								}}
								data={data}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="text" />
								<YAxis />
								<Tooltip />
								<Bar dataKey="answerCount" fill="var(--secondary-color)" />
							</BarChart>
						</ResponsiveContainer>
					</ChartWrapper>
				) : null}
			</ChartContainer>
		</OuterContainer>
	)
}

export default SingleSurveyChartAndTotal
