import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"

import rootReducer from "./root-reducer"

// setup redux dev tools in development env only
const middleware = ["production", "staging"].includes(process.env.NODE_ENV)
	? applyMiddleware(thunk)
	: compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export const store = createStore(rootReducer, middleware)

export default store
