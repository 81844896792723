import { Upload, Button } from "antd"
import styled from "styled-components"
import ImgCrop from "antd-img-crop"
import { UploadOutlined } from "@ant-design/icons"
import { useState } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgData } from "../../../../redux/org/org.selectors"
import { addImageToLibrary } from "../../../../redux/org/org.actions"
import { updateLessonCard, updateQuestion } from "../../../../redux/courses/courses.actions"
import { selectStage } from "../../../../redux/courses/courses.selectors"
import PanelContainer from "./common/PanelContainer"
import { CallOutCard } from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"
import { ImageCard } from "learnink-common/lib/domains/cards/lessons/image/ImageCard.model"
import { MultipleChoiceQuestionCard } from "learnink-common/lib/domains/cards/questions/multipleChoiceQuestion/MultipleChoiceQuestionCard.model"

const LibraryContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
`
const ImageWrapper = styled.div`
	padding: 4px;
`
const ImageContainer = styled.div`
	width: 100%;
	height: 100%;
	background-size: cover;
	border: solid 1px var(--light-grey);
	cursor: pointer;
	:hover {
		opacity: 0.6;
	}
	transition: 0.3s;
`

const imageWidth = {
	images: "480",
	icons: "80"
}

const thumbnailSize = {
	images: 96,
	icons: 56
}

interface Props {
	card: ImageCard | CallOutCard | MultipleChoiceQuestionCard
	type: "icons" | "images"
	updateLessonCard: (updateParams: ImageCard | CallOutCard) => void
	updateQuestion: (updateParams: MultipleChoiceQuestionCard) => void
	stage: any
	addImageToLibrary: any
	orgData: any
}

const ImageUploads = ({ type, card, orgData, stage, addImageToLibrary, updateLessonCard, updateQuestion }: Props) => {
	const [fileList, setFileList] = useState([])

	const uploadImage = async (file: any, onSuccess?: any, onError?: any) => {
		const formData = new FormData()
		formData.set("image_quality", "80")
		formData.set("image_width", imageWidth[type])
		formData.set("orgId", orgData.org_id)
		formData.set("folderName", type)
		formData.append("image", file)

		try {
			const response = await axios.post("/api/data/image", formData)
			if (onSuccess) {
				onSuccess("Ok")
			}
			if (response && response.data && response.data.image) {
				await addImageToLibrary(type, response.data.image.split("/").pop())
			}
		} catch (err) {
			if (onError) {
				onError({ err })
			}
		}
	}

	const checkCanCropOrUploadDirectly = async (file: any) => {
		// Skip cropping modal when the image is a gif as it won't work
		if (file.type === "image/gif") {
			await uploadImage(file)
			return false
		}
	}

	const handleUpload = async (options: { onSuccess: any; onError: any; file: any }) => {
		const { onSuccess, onError, file } = options
		await uploadImage(file, onSuccess, onError)
	}

	// @ts-ignore
	const onChange = ({ fileList: newFileList }) => {
		if (newFileList && newFileList[0].status === "done") {
			setFileList([])
		} else {
			setFileList(newFileList)
		}
	}

	const updateImage = (value: string) => {
		if (card.type === "callOut") {
			let newElements = card.elements || []
			delete newElements[stage.editor.imageIndex].iconId
			delete newElements[stage.editor.imageIndex].imgUrl
			delete newElements[stage.editor.imageIndex].emoji
			newElements[stage.editor.imageIndex].imgUrl = value
			updateLessonCard({ ...card, elements: newElements })
		} else if (card.type === "image") {
			updateLessonCard({ ...card, image: value })
		} else if (card.type === "multipleChoiceQuestion") {
			updateQuestion({ ...card, image: value })
		}
	}

	return (
		<PanelContainer title={`My ${type}`}>
			<ImgCrop rotationSlider aspectSlider beforeCrop={checkCanCropOrUploadDirectly}>
				<Upload
					accept=".png,.jpg,.jpeg,.gif"
					// @ts-ignore
					customRequest={handleUpload}
					fileList={fileList}
					onChange={onChange}
				>
					<Button id="editor_image_upload_button" icon={<UploadOutlined />} style={{ margin: 4 }}>
						Upload
					</Button>
				</Upload>
			</ImgCrop>
			<LibraryContainer>
				{/* @ts-ignore */}
				{orgData[type].map((item, index) => (
					<ImageWrapper
						key={index}
						style={{ width: thumbnailSize[type], height: thumbnailSize[type] }}
						id={`editor_image_uploads_item_${index}`}
					>
						<ImageContainer
							style={{
								backgroundImage: `url("${process.env.REACT_APP_S3_COURSE_BUCKET_URL}${orgData.org_id}/${type}/${item}")`,
								borderColor:
									// @ts-ignore
									card.image ===
									`${process.env.REACT_APP_S3_COURSE_BUCKET_URL}${orgData.org_id}/${type}/${item}`
										? "var(--secondary-color)"
										: "var(--light-grey)"
							}}
							onClick={() =>
								updateImage(
									`${process.env.REACT_APP_S3_COURSE_BUCKET_URL}${orgData.org_id}/${type}/${item}`
								)
							}
						/>
					</ImageWrapper>
				))}
			</LibraryContainer>
		</PanelContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	orgData: selectOrgData,
	stage: selectStage
})

const mapDispatchToProps = (dispatch: any) => ({
	addImageToLibrary: (type: "icons" | "images", imageId: string) => dispatch(addImageToLibrary(type, imageId)),
	updateLessonCard: (updateParams: ImageCard | CallOutCard) => dispatch(updateLessonCard(updateParams)),
	updateQuestion: (updatedQuestion: MultipleChoiceQuestionCard) => dispatch(updateQuestion(updatedQuestion))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploads)
