import React, { useState } from "react"
import { Redirect, Link } from "react-router-dom"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { Button, Typography, Alert } from "antd"

import { auth } from "../../firebase/firebase.util"
import { selectUserAuthStatus } from "../../redux/user/user.selectors"

import LpTextInput from "../../components/common/LpTextInput"
import AuthContainer from "./AuthContainer"

const { Title } = Typography

const SubTitle = styled.div`
	width: 100%;
	text-align: left;
	margin-top: 16px;
	margin-bottom: 16px;
	color: var(--dark-grey);
	display: block;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2;
`
const SubTitleBtn = styled.button`
	color: var(--secondary-color);
	font-size: 18px;
	font-weight: 600;
	vertical-align: unset !important;
`
const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`
const LinkContainer = styled.div`
	width: 100%;
	font-size: 16px;
	padding-top: 20px;
	a {
		color: var(--secondary-color);
		font-weight: 500;
	}
`

const SignUp = ({ userAuthStatus: { isAuthenticated, hasVerifiedEmail } }) => {
	const [email, setEmail] = useState("")
	const [sentEmail, setSendEmail] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const handleEmailChange = (text) => {
		setEmail(text.trim())
		setErrorMsg(null)
	}

	const handleEnterAction = () => {
		sendSignInLink()
	}

	const handleRetry = () => {
		setSendEmail(false)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		sendSignInLink()
	}

	// Below adapted from firebase auth example from docs: https://firebase.google.com/docs/auth/web/email-link-auth

	const sendSignInLink = () => {
		if (!email) setErrorMsg("you must add an email")
		//else if ( !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) ) setErrorMsg('you must add a valid email,\ne.g. joe@blogs.com');
		else {
			var actionCodeSettings = {
				// URL you want to redirect back to. The domain (www.example.com) for this URL
				// must be whitelisted in the Firebase Console.
				url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT, // Here we redirect back to this same page.
				handleCodeInApp: true // This must be true.
			}
			auth.sendSignInLinkToEmail(email, actionCodeSettings)
				.then(() => {
					window.localStorage.setItem("emailForSignIn", email)
					setSendEmail(true)
				})
				.catch((err) => {
					console.log(err)
					setErrorMsg(err.message)
				})
		}

		// Note: the url above will redirect the user to /verifyemail
		// which uses the remainder of the firebase example code to
		// listen for the email link and log the user in via firebase auth
		// using SDK
	}

	if (hasVerifiedEmail === true) return <Redirect to={"/"} />

	if (isAuthenticated === true) return <h4>Please check your email and verify your account</h4>

	return (
		<AuthContainer sentEmail={sentEmail}>
			{!sentEmail ? (
				<React.Fragment>
					<Title level={2} style={{ width: "100%" }}>
						First of all enter your email address
					</Title>
					<Title level={5} style={{ width: "100%" }}>
						We just need this to send you confirmation!
					</Title>
					<LpTextInput
						autoFocus={true}
						type="email"
						placeholderText="Enter your email"
						handleTextChange={handleEmailChange}
						handleEnterAction={handleEnterAction}
					/>
					{errorMsg && (
						<Alert message="This email is badly formatted" type="error" style={{ marginTop: 20 }} />
					)}
					<ButtonContainer>
						<Button type="primary" size="large" shape="round" block onClick={handleSubmit}>
							Confirm
						</Button>
					</ButtonContainer>
					<LinkContainer>
						Already have an account? <Link to={"/signin"}>Sign in</Link>
					</LinkContainer>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Title level={2} style={{ width: "100%" }}>
						So what now? A magic link got sent to: {email}
					</Title>
					<Title level={5} style={{ width: "100%" }}>
						Please check your email and use the sign-in link there
					</Title>
					<SubTitle>
						Email not sent? <SubTitleBtn onClick={handleRetry}>Retry here</SubTitleBtn>
					</SubTitle>
				</React.Fragment>
			)}
		</AuthContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	userAuthStatus: selectUserAuthStatus
})

export default connect(mapStateToProps)(SignUp)
