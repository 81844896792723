import React, { useState, useMemo, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons"
import { CSVLink } from "react-csv"
import {
	useTable,
	useResizeColumns,
	useFlexLayout,
	useRowSelect,
	useFilters,
	useGlobalFilter,
	useSortBy,
	usePagination
} from "react-table"

import * as S from "../../../../styles/PageStyles"

import GeNoItemsFound from "../../../../common/GeNoItemsFound"
import QuestionsSummaryTableView from "./QuestionsSummaryTableView"
import QuestionsTableFunctions from "./QuestionsTableFunctions"
import { IndeterminateCheckbox, DefaultColumnFilter, fuzzyTextFilterFn } from "../../../../tables/tableFunctions"
import TablePagination from "../../../../tables/TablePagination"
import SingleQuestionPanel from "../SingleQuestionPanel"
import AnSelectItems from "../../../../common/AnSelectItems"
import TooltipIcon from "../../../../common/TooltipIcon"

// Helper functions
// ================
// used for table resizing...

const headerProps = (props, { column }) => getStyles(props, column.align)

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = "left") => [
	props,
	{
		style: {
			alignItems: align === "center" ? "center" : "flex-start",
			justifyContent: "center",
			display: "flex"
		}
	}
]
// Let the table remove the filter if the string is empty...
fuzzyTextFilterFn.autoRemove = (val) => !val

// Component
// =========
const QuestionsSummaryTableWithPanels = ({ columns, data, queryData, tooltip, filteredQuestionContent }) => {
	// stuff for rendering question card examples
	const [questionIds, setQuestionIds] = useState([])

	// stuff for column filters
	// -----
	const [showColFilters, setShowColFilters] = useState(false)
	const toggleColFilters = () => setShowColFilters(!showColFilters)

	const [dataToDownload, setDataToDownload] = useState([])
	const csvDownloadRef = useRef(null) // used for refering to CSVLink component below...

	useEffect(() => {
		if (dataToDownload.length > 0) {
			setTimeout(() => {
				csvDownloadRef.current.link.click()
			}, 200)
		}
	}, [dataToDownload])

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn, // defined in tableFunctions.js file...
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			}
		}),
		[]
	)

	const defaultColumn = useMemo(
		() => ({
			Filter: DefaultColumnFilter, // defined in tableFunctions.js file...
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 100, // width is used for both the flex-basis and flex-grow
			maxWidth: 500 // maxWidth is only used as a limit for resizing
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page which has only the rows for the active page
		// below used mainly for pagination...
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// below used mainly for filter functions...
		allColumns,
		setHiddenColumns,
		state: { pageIndex, pageSize, selectedRowIds, globalFilter, filters },
		preGlobalFilteredRows,
		setGlobalFilter,
		setAllFilters,
		selectedFlatRows
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // passed from the constant set above, must be memoized
			filterTypes, // passed from the constant set above, , must be memoized
			initialState: {
				hiddenColumns: ["questionId", "numBaselineCorrect", "numEndlineCorrect"]
			}
		},
		// core plugin hooks
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useResizeColumns,
		useRowSelect,
		// layout hooks
		useFlexLayout,
		// add special columns
		(hooks) => {
			/*
        we add two extra columns here...
        the 'selection' column allows user to select row of data
        the 'edit' column provides a button to access user details
      */
			hooks.allColumns.push((columns) => [
				// Selection Column
				{
					id: "selection",
					disableResizing: true,
					minWidth: 35,
					width: 35,
					maxWidth: 35,
					align: "center",
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center"
							}}
						>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					)
				},
				...columns
			])
			hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
				// afix the parent group of the selection button to not be resizable
				const selectionGroupHeader = headerGroups[0].headers[0]
				if (selectionGroupHeader) {
					selectionGroupHeader.canResize = false
				}
			})
		}
	)

	// effect to control state of question ids, helps to render single question panel
	useEffect(() => {
		const selectedIds = selectedFlatRows.map((d) => d.original.questionId)
		setQuestionIds(selectedIds)
	}, [selectedFlatRows])

	// Download selected data function
	// -------------------------------
	// note: needs to use destructured selectedRowIds, selectedFlatRows
	// from the useTable hook, so define after hook has been declared...

	const handleDownload = () => {
		// get data from selectedFlatRows...
		const selectedRows = selectedFlatRows.map((d) => d.original)
		// process data into format for download...
		const data_to_download = []

		selectedRows.forEach((row) => {
			let record_to_download = {}
			columns.forEach((col) => {
				record_to_download[col.Header] = row[col.accessor]
			})
			data_to_download.push(record_to_download)
		})
		setDataToDownload(data_to_download)
	}

	return (
		<>
			<S.Card>
				<S.CardBody>
					<S.CardInfoIcon>
						<TooltipIcon tooltip={tooltip} placement="bottomRight" />
					</S.CardInfoIcon>

					{data.length === 0 && (
						<GeNoItemsFound
							icon={<FontAwesomeIcon icon={faUser} />}
							titleText={"No data"}
							subtitleText={""}
						/>
					)}
					{data.length > 0 && (
						<>
							{/* Global search and filter functions at table top... */}
							<QuestionsTableFunctions
								preGlobalFilteredRows={preGlobalFilteredRows}
								globalFilter={globalFilter}
								setGlobalFilter={setGlobalFilter}
								toggleColFilters={toggleColFilters}
								showColFilters={showColFilters}
								setAllFilters={setAllFilters}
								filters={filters}
								selectedRowIds={selectedRowIds}
								allColumns={allColumns}
								setHiddenColumns={setHiddenColumns}
								cellProps={cellProps}
								data={data}
								handleDownload={handleDownload}
							/>

							{/* hidden for data download... */}
							<CSVLink
								ref={csvDownloadRef}
								data={dataToDownload}
								filename={`question_data_download_${Date.now()}.csv`}
								style={{ display: "none" }}
								target="_blank"
							/>

							{/* For table and data itself... */}
							<QuestionsSummaryTableView
								headerProps={headerProps}
								page={page}
								cellProps={cellProps}
								getTableProps={getTableProps}
								headerGroups={headerGroups}
								getTableBodyProps={getTableBodyProps}
								prepareRow={prepareRow}
							/>

							{/* For pagination under table... */}
							<TablePagination
								gotoPage={gotoPage}
								canPreviousPage={canPreviousPage}
								previousPage={previousPage}
								nextPage={nextPage}
								canNextPage={canNextPage}
								pageCount={pageCount}
								pageIndex={pageIndex}
								setPageSize={setPageSize}
								pageOptions={pageOptions}
								pageSize={pageSize}
							/>
						</>
					)}
				</S.CardBody>
			</S.Card>

			{questionIds.length > 0 ? (
				<>
					{questionIds.map((id, index) => (
						<SingleQuestionPanel
							key={index}
							singleQuestionContent={filteredQuestionContent.filter((item) => item.card_id === id)[0]}
							accuracyData={data.filter((item) => item.questionId === id)[0]}
							questionData={queryData.filter((item) => item.questionId === id)}
						/>
					))}
				</>
			) : (
				<AnSelectItems
					icon={<FontAwesomeIcon icon={faLongArrowAltUp} />}
					titleText={"Select questions from the table above"}
					subtitleText={"Use the checkboxes in the table to select test questions"}
					hasBorder={false}
					cardBodyBottomMargin={"20px"}
				/>
			)}
		</>
	)
}

export default QuestionsSummaryTableWithPanels
