import React from "react"
import styled from "styled-components"
import { DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { Button, Typography, Select, InputNumber, Space } from "antd"

const { Text } = Typography

const PaginationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 700px;
	align-content: center;
	align-items: center;
`
const PaginationButtonsBox = styled.div`
	display: flex;
`

const TablePagination = ({
	gotoPage,
	canPreviousPage,
	previousPage,
	nextPage,
	canNextPage,
	pageCount,
	pageIndex,
	setPageSize,
	pageOptions,
	pageSize
}) => {
	return (
		<PaginationContainer>
			<PaginationButtonsBox>
				<Space>
					<Button
						shape="round"
						size="small"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
						icon={<DoubleLeftOutlined />}
					/>
					<Button
						shape="round"
						size="small"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
						icon={<LeftOutlined />}
					/>
					<Button
						shape="round"
						size="small"
						onClick={() => nextPage()}
						disabled={!canNextPage}
						icon={<RightOutlined />}
					/>
					<Button
						shape="round"
						size="small"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
						icon={<DoubleRightOutlined />}
					/>
				</Space>
			</PaginationButtonsBox>
			<Text type="secondary">
				Page {pageIndex + 1} of {pageOptions.length}
			</Text>
			<Text type="secondary">
				Go to page:
				<InputNumber
					size="small"
					defaultValue={pageIndex + 1}
					onChange={(value) => {
						const page = value ? Number(value) - 1 : 0
						gotoPage(page)
					}}
					style={{ marginLeft: 6 }}
				/>
			</Text>
			<Select
				size="small"
				showArrow={true}
				showSearch={false}
				defaultValue={pageSize}
				options={[
					{ value: 5, label: "Show 5" },
					{ value: 10, label: "Show 10" },
					{ value: 30, label: "Show 30" },
					{ value: 100, label: "Show 100" }
				]}
				onChange={(value) => setPageSize(value)}
			/>
		</PaginationContainer>
	)
}

export default TablePagination
