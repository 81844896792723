import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import styled from "styled-components"
import { createStructuredSelector } from "reselect"
import { Button, Typography, Alert } from "antd"

import { sendPasswordResetEmail } from "../../firebase/firebase.util"

import { selectIsUserAuthenticated } from "../../redux/user/user.selectors"

import LpTextInput from "../../components/common/LpTextInput"
import AuthContainer from "./AuthContainer"

const { Title } = Typography

const ButtonContainer = styled.div`
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20%;
	padding-right: 20%;
	min-width: 200px;
`
const InputContainer = styled.div`
	text-align: left;
	margin-top: 20px;
	width: 100%;
`

const PasswordReset = ({ isUserAuthenticated }) => {
	const [email, setEmail] = useState("")
	const [sentEmail, setSentEmail] = useState(false)
	const [success, setSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const handleEmailChange = (text) => {
		setEmail(text.trim())
		setErrorMsg(null)
	}

	const handleEmailEnterAction = (e) => {
		ResetPassword()
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		ResetPassword()
	}

	const ResetPassword = () => {
		sendPasswordResetEmail(email)
			.then((res) => {
				setSentEmail(true)
				setSuccess(true)
				setSuccessMessage("A password reset link has been sent to " + email)
				setEmail("")
			})
			.catch((err) => {
				setErrorMsg(err)
				setEmail("")
			})
	}

	if (isUserAuthenticated === true) return <Redirect to={"/"} />

	return (
		<AuthContainer sentEmail={sentEmail}>
			{success === false ? (
				<Title level={2} style={{ width: "100%" }}>
					Reset password
				</Title>
			) : (
				<Title level={2} style={{ width: "100%" }}>
					Check your email
				</Title>
			)}
			{success === true ? (
				<Title level={5} style={{ width: "100%" }}>
					{successMessage}
				</Title>
			) : (
				<InputContainer>
					<LpTextInput
						autoFocus={true}
						inputType="email"
						labelText="Email address"
						placeholderText="Email"
						handleTextChange={handleEmailChange}
						handleEnterAction={handleEmailEnterAction}
					/>
				</InputContainer>
			)}
			{errorMsg && <Alert message={errorMsg} type="error" style={{ marginTop: 20 }} />}
			{success === false && (
				<ButtonContainer>
					<Button type="primary" size="large" shape="round" block onClick={handleSubmit}>
						Reset password
					</Button>
				</ButtonContainer>
			)}
		</AuthContainer>
	)
}

const mapStateToProps = createStructuredSelector({
	isUserAuthenticated: selectIsUserAuthenticated
})

export default connect(mapStateToProps)(PasswordReset)
