import { PremiumFeature } from "../../redux/org/org.model"

export interface MenuItem {
	key: string
	label: string
	premiumId?: PremiumFeature
	icon?: "EditOutlined" | "BarChartOutlined" | "UsergroupAddOutlined" | "SettingOutlined"
	path?: string
	children?: MenuItem[]
	beta?: boolean
}

export const sideNavMenu: MenuItem[] = [
	{
		key: "create",
		label: "Create",
		icon: "EditOutlined",
		children: [
			{
				key: "createCourses",
				path: "/create/courses",
				label: "Courses"
			},
			{
				key: "createResources",
				path: "/create/resources",
				label: "Resources"
			},
			{
				key: "createSequences",
				path: "/create/sequences",
				label: "Sequences",
				premiumId: "sequences",
				beta: true
			},
			{
				key: "createLearningPaths",
				path: "/create/learning-paths",
				label: "Learning paths",
				premiumId: "learningPaths",
				beta: true
			}
		]
	},
	{
		key: "analyse",
		label: "Analyse",
		icon: "BarChartOutlined",
		children: [
			{
				key: "analyseActivity",
				path: "/analyse/activity",
				label: "Activity"
			},
			{
				key: "analyseDemographics",
				path: "/analyse/demographics",
				label: "Demographics"
			},
			{
				key: "analyseCourses",
				path: "/analyse/courses",
				label: "Courses"
			},
			{
				key: "analyseSequences",
				path: "/analyse/sequences",
				label: "Sequences",
				premiumId: "sequences",
				beta: true
			},
			{
				key: "analyseLearningPaths",
				path: "/analyse/learning-paths",
				label: "Learning paths",
				premiumId: "learningPaths",
				beta: true
			}
		]
	},
	{
		key: "manage",
		label: "Manage",
		icon: "UsergroupAddOutlined",
		children: [
			{
				key: "manageUsers",
				path: `/manage/users`,
				label: "Users"
			},
			{
				key: "manageUserGroups",
				path: "/manage/user-groups",
				label: "User groups"
			},
			{
				key: "manageAccessCodes",
				path: "/manage/access-codes",
				label: "Access codes"
			}
		]
	},
	{
		key: "settings",
		label: "Settings",
		icon: "SettingOutlined",
		children: [
			{
				key: "team",
				path: "/team",
				label: "Team"
			},
			{
				key: "orgSettings",
				path: "/settings",
				label: "Org settings"
			},
			{
				key: "account",
				path: "/account",
				label: "Account settings"
			},
			{
				key: "subscription",
				path: "/subscription",
				label: "Subscription"
			},
			{
				key: "developers",
				label: "Developers",
				children: [
					{
						key: "developersWebhook",
						path: "/developers/webhook",
						label: "Webhook",
						premiumId: "webhook"
					}
				]
			},
			{
				key: "switchOrg",
				path: "/switch-org",
				label: "Switch org"
			}
		]
	}
]
