// DRAGGABLE MODULE CARD

import React, { useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography, Tooltip, Dropdown, Menu } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAlt, faEllipsisV, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { MIN_LESSON_CARDS, MIN_QUESTION_CARDS } from "../../../../config/course.config"

import { selectCourseStageCards } from "../../../../redux/courses/courses.selectors"
import { updateModuleInfo } from "../../../../redux/courses/courses.actions"

import DeleteModule from "../modals/DeleteModule"
import CopyModuleToCourse from "../modals/CopyModuleToCourse"
import LpModal from "../../../common/LpModal"

const { Paragraph } = Typography

const handleMainCardBorder = (isDragging, isComplete, isSelected) => {
	let border = "1px solid var(--light-grey);"
	if (isSelected) {
		if (isComplete) {
			border = "2px solid var(--secondary-color);"
		} else {
			border = "2px solid var(--dark-red);"
		}
	}
	if (isDragging) {
		border = "1px solid var(--secondary-color);"
	}
	return border
}
const MainCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: var(--dark-grey);
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border: ${(props) => handleMainCardBorder(props.isDragging, props.isComplete, props.isSelected)}
  box-shadow: ${(props) =>
		props.isDragging
			? "none"
			: props.isComplete
			? "0px 2px 4px var(--v-light-grey);"
			: "0px 2px 4px var(--v-light-grey);"}
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
		props.isDragging ? "var(--secondary-color-pale)" : props.isComplete ? "white" : "var(--light-red)"}
`
// Header items styling
const CardHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	align-content: center;
	align-items: center;
`
const HeaderHandle = styled.div`
	background-color: none;
`
const HeaderTitle = styled.div`
	flex: 1;
	padding: 1px 4px;
	display: flex;
	justify-content: space-between;
`
const HeaderMenuButton = styled.button`
	background: none;
	padding-left: 20px;
	padding-right: 3px;
	color: var(--light-grey);
`
// Contains different card content types
const UnderHeaderContainer = styled.div`
	width: 100%;
	padding: 5px;
	display: flex;
`
// for module card inner UI
const ModuleCardContainer = styled.div`
	height: 50px;
	width: 100%;
	background: #fff;
	border-radius: 50px;
	border: solid 1px #d8d8d8;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.22);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
`
const ModuleCardImgContainer = styled.div`
	height: 36px;
	width: 36px;
	border-radius: 99%;
	margin: 0px 6px;
	background: url("${(props) => props.image}");
	background-size: cover;
`
const ModuleCardTitleContainer = styled.div`
	color: #000;
	max-height: 40px;
	flex: 1;
	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
`

const CbModuleCard = ({ card, index, isSelected, selectModuleId, stage_cards, updateModuleInfo }) => {
	const [showDuplicateModal, setShowDuplicateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const dropDown = (
		<Menu
			items={[
				{
					key: "duplicate_to_course",
					label: <div onClick={() => setShowDuplicateModal(true)}>Duplicate to course</div>
				},
				{
					key: "delete",
					label: <div onClick={() => setShowDeleteModal(true)}>Delete</div>
				}
			]}
		/>
	)

	const handleEditName = (updatedText) => {
		if (updatedText.trim().length > 0) {
			let new_info = Object.assign({}, card.info)
			new_info.name = updatedText
			updateModuleInfo(card.id, new_info)
		}
	}

	let isComplete = true
	if (card.stage_ids.length === 0) isComplete = false
	card.stage_ids.forEach((id) => {
		if (stage_cards[id].lesson_card_ids.length < MIN_LESSON_CARDS) isComplete = false
		if (stage_cards[id].question_card_ids.length < MIN_QUESTION_CARDS) isComplete = false
	})

	return (
		<>
			<Draggable draggableId={card.id} index={index}>
				{(provided, snapshot) => (
					<MainCardContainer
						{...provided.draggableProps}
						ref={provided.innerRef}
						isDragging={snapshot.isDragging}
						isComplete={isComplete}
						isSelected={isSelected}
						onClick={() => selectModuleId(card.id)}
					>
						<CardHeader>
							<HeaderHandle {...provided.dragHandleProps}>
								<FontAwesomeIcon icon={faArrowsAlt} color="var(--light-grey)" />
							</HeaderHandle>

							<HeaderTitle></HeaderTitle>

							{!isComplete ? (
								<Tooltip title="There are incomplete stages in this module">
									<FontAwesomeIcon icon={faExclamationCircle} color="var(--dark-red)" />
								</Tooltip>
							) : null}

							<Dropdown overlay={dropDown} placement="bottomRight">
								<HeaderMenuButton>
									<FontAwesomeIcon icon={faEllipsisV} />
								</HeaderMenuButton>
							</Dropdown>
						</CardHeader>

						<UnderHeaderContainer>
							<ModuleCardContainer>
								<ModuleCardImgContainer image={card.info.image}></ModuleCardImgContainer>
								<ModuleCardTitleContainer>
									<Paragraph
										editable={{
											onChange: (value) => handleEditName(value),
											enterIcon: null
										}}
										style={{
											lineHeight: 1.2,
											margin: 0
										}}
										ellipsis={{ rows: 2 }}
									>
										{card.info.name}
									</Paragraph>
								</ModuleCardTitleContainer>
							</ModuleCardContainer>
						</UnderHeaderContainer>
					</MainCardContainer>
				)}
			</Draggable>
			<LpModal isShowingModal={showDuplicateModal} hideModal={() => setShowDuplicateModal(!showDuplicateModal)}>
				<CopyModuleToCourse closemodal={() => setShowDuplicateModal(false)} module_card={card} />
			</LpModal>
			<LpModal isShowingModal={showDeleteModal} hideModal={() => setShowDeleteModal(!showDeleteModal)}>
				<DeleteModule closemodal={() => setShowDeleteModal(false)} module_card={card} />
			</LpModal>
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	stage_cards: selectCourseStageCards
})

const mapDispatchToProps = (dispatch) => ({
	updateModuleInfo: (module_id, new_info) => dispatch(updateModuleInfo(module_id, new_info))
})

CbModuleCard.propTypes = {
	card: PropTypes.shape(PropTypes.obj).isRequired,
	index: PropTypes.number.isRequired,
	isSelected: PropTypes.bool.isRequired,
	selectModuleId: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CbModuleCard)
