// MODAL TO CREATE NEW LESSON CARD

import { Avatar } from "antd"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 27px;
`
const TextBox = styled.div`
	border-radius: 0px 10px 10px 10px;
	background-color: white;
	border: 1.5px solid dodgerblue;
	width: 90px;
	margin-left: 5px;
	font-size: 10px;
	line-height: 13px;
`
const Button = styled.div`
	width: 100%;
	background-color: dodgerblue;
	padding: 5px;
	color: white;
	border-radius: 0px 0px 8px 8px;
	text-align: center;
`

const Url = ({ teacherImg }) => {
	return (
		<Container>
			<Avatar src={teacherImg} size={20} />
			<TextBox>
				<div style={{ padding: 7 }}>Add a button with a link</div>
				<Button>Button</Button>
			</TextBox>
		</Container>
	)
}

export default Url
