// DASHBOARD FOR ACTIVITY TAB

import React from "react"
import "../bootstrap-grid.css" // using bootstrap grid here for good mobile responsive stuff

import UserGroupSummaryTable from "../charts/activity-dash/UserGroupSummaryTable"
import EventActivityArea from "../charts/activity-dash/EventActivityArea"
import ChartQuery from "../common/ChartQuery"

const ActivityDash = () => {
	return (
		<div className="container-fluid">
			<ChartQuery route="count">
				<UserGroupSummaryTable />
			</ChartQuery>
			<div className="row">
				<div className="col-sm-12">
					<ChartQuery route="count/day">
						<EventActivityArea />
					</ChartQuery>
				</div>
			</div>
		</div>
	)
}

export default ActivityDash
