import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { selectOrgUserGroupNamesMap } from "../../../../redux/org/org.selectors"

import BasicTable from "../../common/BasicTable"
import ChartWrapper from "../../common/ChartWrapper"
import SingleValueChart from "../../common/SingleValueChart"
import SingleValueWrapper from "../../common/SingleValueWrapper"

const UserGroupSummaryTable = ({ userGroupsArr, queryData, orgUserGroupsNames }) => {
	if (userGroupsArr.length > 1) {
		const tableData = []
		userGroupsArr.forEach((userGroup) => {
			const userGroupName = orgUserGroupsNames[userGroup]
			if (queryData.filter((item) => item.userGroup === userGroup).length > 0) {
				const userGroupData = queryData.filter((item) => item.userGroup === userGroup)[0]
				tableData.push({
					userGroupName,
					numCoursesStarted: userGroupData.numCoursesStarted,
					numCoursesCompleted: userGroupData.numCoursesCompleted,
					baselineAccuracy: userGroupData.baselineAccuracy ? userGroupData.baselineAccuracy : "--",
					endlineAccuracy: userGroupData.endlineAccuracy ? userGroupData.endlineAccuracy : "--",
					improvement: userGroupData.baselineAccuracy
						? Math.round(
								((userGroupData.endlineAccuracy - userGroupData.baselineAccuracy) * 100) /
									userGroupData.baselineAccuracy
						  )
						: "--"
				})
			} else {
				tableData.push({
					userGroupName,
					numCoursesStarted: 0,
					numCoursesCompleted: 0,
					baselineAccuracy: "--",
					endlineAccuracy: "--",
					improvement: "--"
				})
			}
		})
		return (
			<div className="row" style={{ padding: 15 }}>
				<BasicTable
					columns={[
						{
							Header: "User group",
							accessor: "userGroupName"
						},
						{
							Header: "Num started",
							accessor: "numCoursesStarted"
						},
						{
							Header: "Num completed",
							accessor: "numCoursesCompleted"
						},
						{
							Header: "Baseline accuracy",
							accessor: "baselineAccuracy"
						},
						{
							Header: "Endline accuracy",
							accessor: "endlineAccuracy"
						},
						{
							Header: "% improvement",
							accessor: "improvement"
						}
					]}
					data={tableData}
				/>
			</div>
		)
	} else {
		return (
			<>
				<div className="row">
					<div className="col-sm-4">
						<ChartWrapper
							tooltip={
								<span>
									The total number of <b>courses started</b> in the timeframe selected
								</span>
							}
							title={"Num courses started"}
						>
							<SingleValueChart>
								{queryData.length > 0 ? queryData[0].numCoursesStarted : 0}
							</SingleValueChart>
						</ChartWrapper>
					</div>
					<div className="col-sm-4">
						<ChartWrapper
							tooltip={
								<span>The total number of courses started in the timeframe that are now completed</span>
							}
							title={"Num courses completed"}
						>
							<SingleValueChart>
								{queryData.length > 0 ? queryData[0].numCoursesCompleted : 0}
							</SingleValueChart>
						</ChartWrapper>
					</div>
					<div className="col-sm-4">
						<SingleValueWrapper
							value={
								queryData.length > 0 && queryData[0].endlineAccuracy
									? queryData[0].endlineAccuracy + "%"
									: "--%"
							}
							tooltip={
								<span>
									Shows the average accuracy across all <b>endline questions</b> answered for all
									courses within the timeframe selected (<b>N=</b> the total number of endline
									questions answered by all users){" "}
								</span>
							}
							title={"Average endline accuracy"}
							sampleSizeValue={
								queryData.length > 0 && queryData[0].numEndlineQuestionsAnswered
									? queryData[0].numEndlineQuestionsAnswered
									: 0
							}
							courseHasData={true}
						/>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = createStructuredSelector({
	orgUserGroupsNames: selectOrgUserGroupNamesMap
})

export default connect(mapStateToProps)(UserGroupSummaryTable)
