// MODAL FOR TRANSLATING A COURSE

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography, Select } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { translateCourseWithId } from "../../../../redux/courses/courses.actions"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"

const { Text } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const languageOptions = [
	{ value: "es", label: "Spanish" },
	{ value: "fr", label: "French" },
	{ value: "sw", label: "Kiswahili" },
	{ value: "am", label: "Amharic" }
]

const TranslateCourse = ({ closemodal, courseId, orgId, translateCourseWithId, triggerRedirect }) => {
	const [loading, setLoading] = useState(false)
	const [selectedLanguage, setSelectedLanguage] = useState(null)

	const handleTranslateAction = () => {
		setLoading(true)
		// dummmy loading below...

		// handle update here
		translateCourseWithId(orgId, courseId, selectedLanguage)

		setTimeout(() => {
			setLoading(false)
			if (triggerRedirect) {
				triggerRedirect()
			} else {
				closemodal()
			}
		}, 8000)
	}

	return (
		<ContentContainer>
			<GeTitle>Translate course</GeTitle>
			{loading ? (
				<>
					<CbLoading loadingText="Translating..." padding="30px" />
				</>
			) : (
				<>
					<Text>Please select which language you would like to translate your course into</Text>

					<Divider />

					<Select
						size="large"
						options={languageOptions}
						isSearchable={false}
						onChange={(value) => setSelectedLanguage(value)}
						placeholder="Select language..."
					/>

					<Divider />

					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button
							type="primary"
							size="large"
							shape="round"
							onClick={handleTranslateAction}
							disabled={!selectedLanguage}
						>
							Translate course
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

PropTypes.TranslateCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	translateCourseWithId: PropTypes.func.isRequired,
	triggerRedirect: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	translateCourseWithId: (org_id, course_id, selected_language) =>
		dispatch(translateCourseWithId(org_id, course_id, selected_language))
})

export default connect(mapStateToProps, mapDispatchToProps)(TranslateCourse)
