import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Button, Table, Space } from "antd"

import * as S from "../../styles/PageStyles"

const FormContainer = styled.div`
	max-width: 90%;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	padding-bottom: 48px;
	display: flex;
	padding-top: 48px;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "32px")};
	width: 100%;
`
const Subtitle = styled.p`
	font-size: 18px;
	font-weight: 500;
	color: black;
	margin-bottom: 20px;
`
const ErrorLogContainer = styled.div`
	max-height: 140px;
	overflow-y: auto;
	overflow-x: hidden;
`
const ErrorLogMessages = styled.p`
	font-size: 14px;
	color: var(--dark-red);
	margin-left: 12px;
	margin-top: 8px;
`
const BottomBtnsContainer = styled.div`
	display: flex;
	margin-top: 30px;
`

const ReviewImportedUserGroups = ({ setImportStage, csvCheckResponse, setLocalCsvFile, handleImportRequest }) => {
	const handleRetryImport = () => {
		setLocalCsvFile(null)
		setImportStage("import")
	}

	const dataSource = React.useMemo(
		() =>
			csvCheckResponse.cleanedData.data.map((item, index) => {
				return {
					key: index + 1,
					row: index + 1,
					user_group_name: item["Group name"],
					user_group_description: item["Group description"]
				}
			}),
		[csvCheckResponse]
	)

	const columns = [
		{
			title: "Row",
			dataIndex: "row",
			key: "row"
		},
		{
			title: "Group name",
			dataIndex: "user_group_name",
			key: "user_group_name"
		},
		{
			title: "Group description",
			dataIndex: "user_group_description",
			key: "user_group_description"
		}
	]

	let disableProceed = false
	if (csvCheckResponse.errData.criticalError) disableProceed = true
	if (csvCheckResponse.cleanedData.validIndexList.length === 0) disableProceed = true

	return (
		<React.Fragment>
			<FormContainer>
				{csvCheckResponse.errData.criticalError === true ? (
					<>
						<S.FormTitle>Error</S.FormTitle>
						<Subtitle style={{ color: "var(--dark-red)" }}>
							<FontAwesomeIcon icon={faExclamationCircle} /> {csvCheckResponse.errData.criticalErrorMsg}
						</Subtitle>
					</>
				) : (
					<>
						<S.FormTitle>Review before importing</S.FormTitle>

						<Subtitle>
							<span style={{ fontWeight: 700 }}>
								{csvCheckResponse.cleanedData.validIndexList.length}
							</span>{" "}
							user groups can be imported from this file
						</Subtitle>

						{csvCheckResponse.errData.errorLog.length > 0 && (
							<>
								<Divider height={"20px"} />
								<Subtitle style={{ color: "var(--dark-red)" }}>
									<span style={{ fontWeight: 700 }}>{csvCheckResponse.errData.errorLog.length}</span>{" "}
									issues detected
								</Subtitle>
								<Divider height={"6px"} />
								<ErrorLogContainer>
									{csvCheckResponse.errData.errorLog.map((err, index) => (
										<ErrorLogMessages key={index}>• {err}</ErrorLogMessages>
									))}
								</ErrorLogContainer>
							</>
						)}

						<Divider />
					</>
				)}

				<Table
					dataSource={dataSource}
					columns={columns}
					rowClassName={(record, index) =>
						!csvCheckResponse.cleanedData.validIndexList.includes(index) ? "invalidTableRow" : ""
					}
				/>

				<BottomBtnsContainer>
					<Space>
						<Button shape="round" size="large" onClick={handleRetryImport}>
							Retry import
						</Button>
						<Button
							type="primary"
							shape="round"
							size="large"
							disabled={disableProceed}
							onClick={handleImportRequest}
						>
							Confirm import
						</Button>
					</Space>
				</BottomBtnsContainer>
			</FormContainer>
		</React.Fragment>
	)
}

ReviewImportedUserGroups.propTypes = {
	setImportStage: PropTypes.func.isRequired,
	setLocalCsvFile: PropTypes.func.isRequired,
	csvCheckResponse: PropTypes.object.isRequired
}

export default ReviewImportedUserGroups
