// COMPONENT TO PLAY VIDEOS BASED ON VIDEO TYPE

import React from "react"
import ReactPlayer from "react-player"

const VideoPlayer = ({ videoType, videoId }) => {
	let videoLocation = null
	if (videoType === "youtube") {
		videoLocation = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=1&modestbranding=1`
	}
	if (videoType === "vimeo") {
		videoLocation = `https://player.vimeo.com/video/${videoId}`
	}
	if (videoType === "mux") {
		videoLocation = `https://stream.mux.com/${videoId}.m3u8`
	}
	if (videoLocation === null) {
		return null
	} else {
		return <ReactPlayer url={videoLocation} width="100%" height="100%" controls />
	}
}

export default VideoPlayer
