// COURSE BUILDER CALL OUT CARD

import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Background from "./CallOutCardElements/elements/Background"
import Logo from "./CallOutCardElements/elements/Logo"
import CardLayout from "./CallOutCardElements/Layout"
import CbLoading from "../../../../common/CbLoading"
import { CallOutCard } from "learnink-common/lib/domains/cards/lessons/callOut/CallOutCard.model"

const Frame = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	border-radius: 15px;
	overflow: hidden;
`

const multiElementHeight = {
	2: 1,
	3: 1.23,
	4: 1.38
}

interface Props {
	card: CallOutCard
	updateLessonCard: (updateParams: CallOutCard) => void
}

const CallOutCardComponent = ({ card, updateLessonCard }: Props) => {
	const [cardState, setCardState] = useState<"loading" | undefined>(undefined)

	useEffect(() => {
		setCardState("loading")
		setTimeout(() => {
			setCardState(undefined)
		}, 300)
	}, [card.iconSize])

	const width = 230
	const numElements = (card.elements || []).length
	const height =
		// @ts-ignore
		card.layout === "multiElementHorizontal" && multiElementHeight[numElements]
			? // @ts-ignore
			  multiElementHeight[numElements] * width
			: width

	const handleUpdateText = (text: string, index: number) => {
		setCardState("loading")
		let newElements = card.elements || []
		newElements[index].text = text
		updateLessonCard({
			...card,
			elements: newElements
		})
		setTimeout(() => {
			setCardState(undefined)
		}, 300)
	}

	return (
		<>
			<Frame style={{ width, height }}>
				<Background
					backgroundStyle={card.backgroundStyle}
					primaryColor={card.primaryColor}
					secondaryColor={card.secondaryColor}
					width={width}
					height={height}
				/>
				{cardState === "loading" ? (
					<CbLoading />
				) : (
					<CardLayout
						cardId={card.id}
						shape={card.shape}
						iconPlacement={card.iconPlacement}
						iconSize={card.iconSize}
						elements={card.elements}
						layout={card.layout}
						width={width}
						height={height}
						editable
						handleUpdateText={handleUpdateText}
					/>
				)}
				{card.logoPlacement && <Logo logoPlacement={card.logoPlacement} width={width} />}
			</Frame>
		</>
	)
}

export default CallOutCardComponent
