import React, { useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import CbCourseTopNav from "../../components/courseBuilder/course/topnav/CbCourseTopNav"
import CbCourseDragDropBase from "../../components/courseBuilder/course/CbCourseDragDropBase"
import CbLoading from "../../components/common/CbLoading"

import { setCoursePageData, clearCoursePageData } from "../../redux/courses/courses.actions"
import { selectCourse, selectObjectives } from "../../redux/courses/courses.selectors"
import { selectOrg } from "../../redux/org/org.selectors"

const Layout = styled.div`
	position: relative;
	top: 0px;
`
const Content = styled.div`
	border: 1px hidden white;
	margin-top: 44px;
	height: calc(100vh - 44px);
`
// this container is designed to handle both mobile and desktop views
const Container = styled.div`
	@media (min-width: 425px) {
		width: 100%;
	}
	@media (min-width: 768px) {
		-webkit-box-align: center;
		align-items: center;
		flex-direction: column;
		-webkit-box-pack: start;
		justify-content: flex-start;
		width: 100%;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 0px;
		box-sizing: border-box;
		display: flex;
	}
`

const CoursePage = ({
	match: {
		params: { org_id, course_id }
	},
	setCoursePageData,
	clearCoursePageData,
	objectives,
	course,
	org
}) => {
	// loading data here...
	useEffect(() => {
		setCoursePageData(course_id, org_id)
		// cleanup
		return () => {
			clearCoursePageData()
		}
	}, [course_id, org_id, setCoursePageData, clearCoursePageData])

	if (
		!(
			objectives &&
			course &&
			org &&
			org.orgData &&
			course.course_id &&
			course.info &&
			course.objective_ids &&
			course.objective_ids &&
			course.module_ids &&
			course.module_cards &&
			course.stage_cards
		)
	)
		return <CbLoading fullpage={true} />

	return (
		<Layout>
			<CbCourseTopNav courseInfo={course.info} />
			<Container>
				<Content>
					<CbCourseDragDropBase />
				</Content>
			</Container>
		</Layout>
	)
}

const mapStateToProps = createStructuredSelector({
	objectives: selectObjectives,
	course: selectCourse,
	org: selectOrg
})

const mapDispatchToProps = (dispatch) => ({
	setCoursePageData: (course_id, org_id) => dispatch(setCoursePageData(course_id, org_id)),
	clearCoursePageData: () => dispatch(clearCoursePageData())
})

export default connect(mapStateToProps, mapDispatchToProps)(CoursePage)
