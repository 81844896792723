// COMPONENT TO SHOW WHEN USER DOESN'T HAVE AN ACTIVE SUBSCRIPTION

import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Typography, Button } from "antd"
import { RocketOutlined } from "@ant-design/icons"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import * as S from "../../components/styles/PageStyles"

import { selectOrgId } from "../../redux/org/org.selectors"
import { selectSubscription } from "../../redux/subscription/subscription.selectors"
import { createCheckoutSession } from "../../redux/subscription/subscription.actions"

import DashboardHeaderButton from "../../components/elements/DashboardHeaderButton"
import CbLoading from "../../components/common/CbLoading"

const CourseListInner = styled.div`
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	box-sizing: border-box;
	width: calc(100% - 48px);
	min-width: 260px;
	@media (min-width: 590px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 850px) {
		width: 520px;
		max-width: 100%;
	}
	@media (min-width: 1110px) {
		width: 780px;
		max-width: calc(100% - 48px);
	}
`
const CourseListEmpty = styled.div`
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 20px);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 48px;
	padding-bottom: 48px;
	margin-top: 0px;
	box-sizing: border-box;
	border-width: 1px;
	border-style: dashed;
	border-image: initial;
	border-color: rgb(213, 220, 227);
	border-radius: 4px;
	flex: 1 1 0%;
	background: white;
	text-align: center;
`
const IconText = styled.h1`
	color: var(--secondary-color);
	font-size: 48px;
	line-height: 52px;
	padding: 0px 20px 10px 20px;
`
const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding-top: 20px;
`

const { Title, Text } = Typography

// THESE ARE THE POSSIBLE SUBSCRIPTION STATUSES THAT WILL SEE THIS SCREEN
// non_stripe_subscription_canceled
// non_stripe_subscription_expired
// stripe_subscription_canceled
// stripe_subscription_incomplete_expired
// no_subscription

const AccountLocked = ({ orgId, subscription, createCheckoutSession }) => {
	const handleContactUsButton = () => {
		window.Intercom("showNewMessage")
	}

	const handleSelectPlan = async (planId) => {
		try {
			const checkoutSession = await createCheckoutSession(orgId, planId, null)
			if (checkoutSession) {
				window.location.assign(checkoutSession)
			}
		} catch (err) {
			console.log(err)
		}
	}

	if (subscription && subscription.activePlan && subscription.activePlan.status) {
		return (
			<S.Container>
				<DashboardHeaderButton title="" />
				<CourseListInner>
					<CourseListEmpty>
						<IconText>
							<RocketOutlined />
						</IconText>
						<Title level={4}>Subscribe for access</Title>
						<Text type="secondary">
							You don't have an active Learn.ink subscription. Press the subscribe button to check out our
							subscription plans
						</Text>
						<ButtonBox>
							{subscription.activePlan.plan_id ? (
								<Button
									type="primary"
									size="large"
									shape="round"
									style={{ marginRight: 6 }}
									onClick={() => handleSelectPlan(subscription.activePlan.plan_id)}
								>
									Subscribe
								</Button>
							) : (
								<Link to={`/org/${orgId}/plans`}>
									<Button type="primary" size="large" shape="round" style={{ marginRight: 6 }}>
										Subscribe
									</Button>
								</Link>
							)}
							<Button
								shape="round"
								size="large"
								style={{ marginLeft: 6 }}
								onClick={() => handleContactUsButton()}
							>
								Contact us
							</Button>
						</ButtonBox>
					</CourseListEmpty>
				</CourseListInner>
			</S.Container>
		)
	} else {
		return <CbLoading fullpage={true} />
	}
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId,
	subscription: selectSubscription
})

const mapDispatchToProps = (dispatch) => ({
	createCheckoutSession: (orgId, planId, coupon) => dispatch(createCheckoutSession(orgId, planId, coupon))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountLocked)
