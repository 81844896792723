// DOWNLOAD COURSE MODAL

import React, { useState } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography, Alert } from "antd"

import { selectOrgId } from "../../../../redux/org/org.selectors"
import { downloadCourseWithId } from "../../../../redux/courses/courses.actions"

import GeTitle from "../../../common/GeTitle"
import CbLoading from "../../../common/CbLoading"

const { Text } = Typography

const ContentContainer = styled.div`
	max-width: 520px;
	width: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	flex-direction: column;
	-webkit-box-pack: start;
	justify-content: flex-start;
	display: flex;
`
const ButtonsContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: center;
`
const Divider = styled.div`
	height: ${({ height }) => (height ? height : "12px")};
	border-bottom: ${({ borderOnBottom }) => (borderOnBottom ? "solid 1px var(--light-grey)" : null)};
	width: 100%;
	margin-bottom: ${({ addBottomMargin }) => (addBottomMargin ? "28px" : "0px")};
`

const DownloadCourse = ({ closemodal, downloadCourseWithId, orgId, courseId }) => {
	const [loading, setLoading] = useState(false)

	// this ammounts to setting access_groups to "archive" AND publishing an update to course IF it's published...
	const handleDownloadCourse = () => {
		setLoading(true)
		// dummmy loading below...
		setTimeout(() => {
			setLoading(false)
			// handle update here
			downloadCourseWithId(orgId, courseId)

			closemodal()
		}, 3000)
	}

	return (
		<ContentContainer>
			<GeTitle>Download course</GeTitle>
			{loading ? (
				<>
					<CbLoading loadingText="Creating download..." padding="30px" />
				</>
			) : (
				<>
					<Text>
						You can save a version of your course to file and upload as a new course at a later date.
					</Text>
					<Divider />
					<Alert
						message={
							"NOTE: this is a beta feature and our system only allows you to create one new course from a single saved file, after which the saved file cannot be used again. You mighty want to save multiple file backups."
						}
						type="warning"
					/>
					<Divider />
					<ButtonsContainer>
						<Button size="large" shape="round" onClick={closemodal}>
							Cancel
						</Button>
						<div style={{ width: "20px" }} />
						<Button type="primary" size="large" shape="round" onClick={handleDownloadCourse}>
							Download course
						</Button>
					</ButtonsContainer>
				</>
			)}
		</ContentContainer>
	)
}

PropTypes.DownloadCourse = {
	closemodal: PropTypes.func.isRequired,
	orgId: PropTypes.string.isRequired,
	courseId: PropTypes.string.isRequired,
	downloadCourseWithId: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
	orgId: selectOrgId
})

const mapDispatchToProps = (dispatch) => ({
	downloadCourseWithId: (org_id, course_id) => dispatch(downloadCourseWithId(org_id, course_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCourse)
