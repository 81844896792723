import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Tag } from "antd"

import { selectOrgUserGroups } from "../../redux/org/org.selectors"

const UserGroupsTags = ({ selectedUserGroups, limit, orgUserGroups, invisibleGroups }) => {
	const numToDisplay = limit ? limit : 10
	let filteredUserGroups = invisibleGroups
		? selectedUserGroups.filter((item) => !invisibleGroups.includes(item))
		: selectedUserGroups
	filteredUserGroups = filteredUserGroups.filter((item) => orgUserGroups[item])
	const displayedUserGroups =
		filteredUserGroups && filteredUserGroups.length > 0 ? filteredUserGroups.slice(0, numToDisplay) : []
	const numAdditionalUserGroups =
		filteredUserGroups && filteredUserGroups.length > numToDisplay ? filteredUserGroups.length - numToDisplay : 0

	return (
		<>
			{displayedUserGroups.map((user_group, index) => (
				<Tag
					key={index}
					color={
						orgUserGroups && orgUserGroups[user_group] && orgUserGroups[user_group].color
							? orgUserGroups[user_group].color
							: "var(--mid-orange)"
					}
				>
					{orgUserGroups[user_group].user_group_info.name}
				</Tag>
			))}
			{numAdditionalUserGroups > 0 && <Tag>+{numAdditionalUserGroups}</Tag>}
		</>
	)
}

const mapStateToProps = createStructuredSelector({
	orgUserGroups: selectOrgUserGroups
})

export default connect(mapStateToProps)(UserGroupsTags)
